import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {WebSocketContext} from './WebSocketContext';
import {authSelector} from '../store/auth';
import {useSelector} from '../store/rootReducer';
import {Client} from '@stomp/stompjs';

type WebSocketClientProviderProps = {
  enabled?: boolean;
};

const WebSocketClientProvider: React.FC<PropsWithChildren<WebSocketClientProviderProps>> = ({
  children,
  enabled = true,
}) => {
  const shouldConnect =
    useSelector((state) => !!authSelector(state).loggedInUser || !!authSelector(state).loggedOutUser) && enabled;

  const [connected, setConnected] = useState(false);

  const client = useMemo(() => {
    const client = new Client({
      brokerURL: `${window.location.protocol.replace('http', 'ws')}//${window.location.host}/ws`,
    });

    return client;
  }, []);

  useEffect(() => {
    if (client) {
      client.onConnect = function () {
        setConnected(true);
      };

      client.onDisconnect = function () {
        setConnected(false);
      };

      if (shouldConnect) {
        client.activate();
      } else {
        client.deactivate();
      }
    }
  }, [client, shouldConnect]);

  const contextValue = useMemo(() => ({client, connected}), [client, connected]);

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};

export default WebSocketClientProvider;
