import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {versionControlDiffSelectionSelector} from '../../../../../store/versionControl';
import {modelHasValidBitbucketMetadata, useBitbucketHistoryLoader} from '../../../../../store/bitbucket';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {
  modelFileItemHistorySelector,
  ModelFileTags,
  useModelFile,
  useModelFileItemHistoryLoaderWithParams,
} from '../../../../../store/modelFile';
import MlmpHistoryChart, {MlmpHistory} from '../../../../core/HistoryChart/MlmpHistoryChart';
import {ModelCard} from '../ModelCard/ModelCard';
import {nodeHasTag} from '../../../../../store/modelFile/utils';
import {AsyncActionPhase} from '../../../../../store/asyncUtilsTypes';
import {ModelItemActionDTO} from 'hemwb-api';
import {useUserList} from '../../../../../store/user';
import WarningTextWithTitle from '../../../../core/WarningText/WarningTextWithTitle';
import GitHistoryChart from '../../../../core/HistoryChart/GitHistoryChart';
import {useModel} from '../../../../../store/model';
import {blankModel} from '../../../modelUpload/types';

type ModelVersionControlHistoryProps = {};

enum HistoryType {
  BITBUCKET,
  MLMP,
  NONE,
}

const mlmpHistorySort = (a: ModelItemActionDTO, b: ModelItemActionDTO) => {
  if (a.timestamp! > b.timestamp!) {
    return -1;
  } else if (a.timestamp! < b.timestamp!) {
    return 1;
  }
  return 0;
};

const ModelVersionControlHistory: React.FC<ModelVersionControlHistoryProps> = () => {
  const dispatch = useDispatch();
  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);
  const [modelUuid] = versionDiffSelection;
  const model = useModel(modelUuid);
  const {files} = useModelFile(model);
  const excelFiles = useMemo(() => {
    if (files) {
      return files.filter((file) => nodeHasTag(file, ModelFileTags.MODEL_FILE_EXCEL));
    }
    return null;
  }, [files]);

  const validMetadata = () => {
    if (model !== null) {
      return modelHasValidBitbucketMetadata(model) ? HistoryType.BITBUCKET : isExcelFiles();
    }
  };

  const isExcelFiles = () => {
    return !!excelFiles ? HistoryType.MLMP : HistoryType.NONE;
  };

  const historyType = !model ? HistoryType.NONE : validMetadata();

  const {data: bitbucketHistory, load: loadBitbucketHistory} = useBitbucketHistoryLoader(model || blankModel);
  const loadMlmpItemHistory = useModelFileItemHistoryLoaderWithParams();
  const mlmpHistories = useSelector(modelFileItemHistorySelector);
  const userList = useUserList();

  const mlmpHistory = useMemo(() => {
    return files
      ?.filter((file) => nodeHasTag(file, ModelFileTags.MODEL_FILE_EXCEL))
      .reduce((acc, file) => {
        const history = mlmpHistories[`${modelUuid}-${file.uuid}`];

        if (history && history.state === AsyncActionPhase.SUCCESS) {
          return acc.concat(
            history.value!.map((h) => {
              const user = userList?.find((u) => u.id === h.isid);
              return {...h, file, user};
            }),
          );
        }
        return acc;
      }, ([] as any) as MlmpHistory[])
      .sort(mlmpHistorySort);
  }, [files, mlmpHistories, userList, modelUuid]);

  //load mlmp histories
  useEffect(() => {
    if (historyType === HistoryType.MLMP && files) {
      files
        .filter((file) => nodeHasTag(file, ModelFileTags.MODEL_FILE_EXCEL))
        .forEach((file) => loadMlmpItemHistory([modelUuid, file.uuid]));
    }
  }, [files, historyType, modelUuid, loadMlmpItemHistory]);

  useEffect(() => {
    // @ts-ignore
    if (historyType === HistoryType.BITBUCKET) {
      loadBitbucketHistory();
    } else if (historyType === HistoryType.MLMP) {
      // files.forEa
    }
  }, [dispatch, historyType, loadBitbucketHistory, loadMlmpItemHistory, files]);

  return (
    <>
      <br />
      <ModelCard model={model} />
      <br />
      <br />
      <br />
      {historyType === HistoryType.BITBUCKET && (
        <SuspenseNull>{bitbucketHistory && <GitHistoryChart history={bitbucketHistory} />}</SuspenseNull>
      )}
      {historyType === HistoryType.MLMP && (
        <SuspenseNull>{mlmpHistory && <MlmpHistoryChart history={mlmpHistory} />}</SuspenseNull>
      )}
      {historyType === HistoryType.NONE && (
        <>
          <WarningTextWithTitle title="No commits found" text="There is no execution file" />
        </>
      )}
    </>
  );
};

export default ModelVersionControlHistory;
