import {ExecutionStatus, ScenarioStatus} from 'hemwb-api';

const scenarioExecutionStatusMap: {[key in ExecutionStatus | ScenarioStatus]: string} = {
  [ScenarioStatus.DRAFT]: 'Not Executed',
  [ExecutionStatus.NOTSTARTED]: 'Not started',
  [ExecutionStatus.INQUEUE]: 'In queue',
  [ExecutionStatus.INPROCESS]: 'In progress',
  [ExecutionStatus.POSTPROCESSING]: 'Post processing',
  [ExecutionStatus.SUSPENDED]: 'Suspended',
  [ExecutionStatus.COMPLETED]: 'Completed',
  [ExecutionStatus.CANCELLED]: 'Cancelled',
  [ExecutionStatus.FAILED]: 'Failed',
  [ScenarioStatus.ACCEPTED]: 'Accepted',
  [ScenarioStatus.SUPERSEDED]: 'Superseded',
};

export const formatScenarioExecutionStatusValue = (status: ScenarioStatus | ExecutionStatus): string => {
  return scenarioExecutionStatusMap[status];
};
