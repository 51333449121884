import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isModelTheorem, useModelListBasedOnUserType} from '../../../../store/model';
import {ModelDTO} from 'hemwb-api';
import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_STRING_OPTIONS,
} from '../../../core/TableWithSortAndPagination';
import DefaultPage from '../../../layout/common/DefaultPage';
import SideBar from '../../../layout/common/SideBar';
import {Button, CircularProgress, Container, Grid, Tab, Tabs} from '@material-ui/core';
import styles from './ModelList.module.scss';
import {modelListGridId, useStoredGridState} from '../../../../store/grid';
import {filterModels, ModelFilter, ModelFilterState} from '../filter';
import {Search} from '../../../core/Search/Search';
import {ModelActionMenu} from '../common/ModelActionMenu/ModelActionMenu';
import {tid} from '../../../../testUtils';
import {fullName} from '../../../../store/user/utils';
import ModelOverview from '../common/ModelOverview/ModelOverview';
import {CloudServices} from '@carbon/pictograms-react';
import {isExternalUser} from '../../../../permissions/usePermissions';
import PageHeaderTitle from '../../../core/Header/PageHeader/PageHeaderTitle';
import {useAuth} from '../../../../store/auth';
import ModelOverviewTH from '../common/ModelOverview/ModelOverviewTH';
import DynamicView from '../dynamicView/DynamicView';

const columns: Column<ModelDTO>[] = [
  {id: 'name', label: 'Model Name', sortable: true, value: (model) => model.name, classes: {th: styles.colNameHeading}},
  {
    id: 'modelType',
    label: 'Model Type',
    sortable: true,
    value: (model) => model.metadata.model_type,
    classes: {th: styles.colModelTypeHeading},
  },
  {
    id: 'createdBy',
    label: 'Creator',
    sortable: true,
    value: (model) => fullName(model.createdBy),
    classes: {th: styles.colCreatorHeading},
  },
  {
    id: 'createdOn',
    label: 'Creation Date',
    sortable: true,
    value: (model) => model.createdOn,
    display: (model) => model.createdOn.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
    classes: {th: styles.colDateHeading},
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    value: (model) => model.metadata.status,
    classes: {th: styles.colStatusHeading},
  },
];

const searchModels = (models: ModelDTO[], search: string) => {
  if (!search) {
    return models;
  }
  const lcSearch = search.toLocaleLowerCase();
  return models.filter(
    (model) =>
      model.name.toLocaleLowerCase().includes(lcSearch) ||
      Object.values(model.metadata).some((value) => !!value && value.toLocaleLowerCase().includes(lcSearch)) ||
      Object.values(model.createdBy).some(
        (value) => !!value && typeof value === 'string' && value.toLocaleLowerCase().includes(lcSearch),
      ),
  );
};

const searchMyModels = (models: ModelDTO[], loggedInUserId: string, search: string) => {
  if (!loggedInUserId) {
    return models;
  }

  const lcSearch = loggedInUserId.toLocaleLowerCase();
  const myModels = models.filter(
    (model) =>
      model.userAccess !== undefined ||
      null ||
      Object.values(model.createdBy).some(
        (value) => !!value && typeof value === 'string' && value.toLocaleLowerCase().includes(lcSearch),
      ),
  );

  if (search) {
    return searchModels(myModels, search);
  } else {
    return myModels;
  }
};

const ModelList: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<ModelDTO>();
  const [selectedDynamicModel, setSelectedDynamicModel] = useState<ModelDTO>();
  const [tab, setTab] = useState<string>('TableView');

  const gridState = useStoredGridState<ModelFilterState>(modelListGridId);

  const externalUser = isExternalUser();
  const list = useModelListBasedOnUserType(externalUser);
  const {auth} = useAuth();
  const user = auth.loggedInUser;

  const handleDynamicViewSelection = (model: ModelDTO) => {
    setSelectedDynamicModel(model);
  };

  const handleClickRow = (model: ModelDTO) => {
    if (gridState.expandedRowId === model.uuid) {
      gridState.setExpandedRowId(null);
    } else {
      gridState.setExpandedRowId(model.uuid);
    }
  };

  const displayMyModels = () => {
    if (gridState.viewMyModels) {
      gridState.setViewMyModels(false);
    } else {
      gridState.setViewMyModels(true);
    }
  };
  const handleFilterChange = useCallback(
    (filterState: ModelFilterState) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setFilter(filterState);
    },
    [gridState],
  );

  const handleSearchChange = useCallback(
    (search: string) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setSearch(search);
    },
    [gridState],
  );

  const filteredList = useMemo(() => {
    if (gridState.viewMyModels && user) {
      return list && searchMyModels(filterModels(list, gridState.filter), user.id, gridState.search);
    } else {
      return list && searchModels(filterModels(list, gridState.filter), gridState.search);
    }
  }, [gridState.viewMyModels, user, list, gridState.filter, gridState.search]);

  const overViewComp = (model: ModelDTO) => {
    return isModelTheorem(model) ? (
      <ModelOverviewTH model={list!.find((m) => m.uuid === model.uuid)!} expanded={false} />
    ) : (
      <ModelOverview model={list!.find((m) => m.uuid === model.uuid)!} expanded={false} />
    );
  };
  // when expandedRowId is set in store and selectedRow is not set in state, find the selectedRow in model list
  useEffect(() => {
    if (filteredList && gridState.expandedRowId) {
      setSelectedRow(filteredList.find((model) => model.uuid === gridState.expandedRowId));
    } else {
      setSelectedRow(undefined);
    }
  }, [filteredList, gridState.expandedRowId]);

  return (
    <>
      {tab === 'TableView' && !externalUser && (
        <SideBar theme="darkTheme">
          <div className={styles.searchPanel}>
            <ModelFilter models={list} filter={gridState.filter} onFilterChange={handleFilterChange} />
          </div>
        </SideBar>
      )}
      <DefaultPage>
        {!externalUser && (
          <Tabs
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            centered
            classes={{indicator: styles.indicator}}>
            <Tab value="TableView" label="Table View" />
            <Tab value="DynamicView" label="Dynamic View" />
          </Tabs>
        )}
        {tab === 'TableView' && (
          <>
            <Container maxWidth={false} className="pageHeader">
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <PageHeaderTitle
                  sm={12}
                  md={5}
                  xl={3}
                  title="Model entries"
                  label="Search, edit, and organize"
                  Icon={<CloudServices className="icon32" />}
                  IconNextToTitle
                />
                <Grid item sm={12} md={7} lg={6} xl={4} className="whiteTheme">
                  <Search value={gridState.search} onValueChange={handleSearchChange} />
                </Grid>
              </Grid>
            </Container>
            {!externalUser && (
              <div className="listPanel">
                <Button onClick={displayMyModels} variant="contained" color="primary" {...tid('btn', 'view-my-model')}>
                  {gridState.viewMyModels ? 'View All Model Entries' : 'View My Model Entries'}
                </Button>
              </div>
            )}

            {filteredList ? (
              <div className={styles.gridContainer}>
                <TableWithSortAndPagination
                  getRowId={(model) => model.uuid}
                  getExpandedRow={overViewComp}
                  isRowSelected={(model) => !!selectedRow && selectedRow.uuid === model.uuid}
                  rows={filteredList}
                  columns={columns}
                  onClickRow={handleClickRow}
                  gridState={gridState}
                  noResultsMessage="No models were found"
                />
              </div>
            ) : (
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexGrow: 1,
                }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
        {tab === 'DynamicView' && <DynamicView handleSelection={handleDynamicViewSelection} />}
      </DefaultPage>
      {tab === 'DynamicView' && (
        <ModelActionMenu model={selectedDynamicModel} onModelDelete={() => setSelectedDynamicModel(undefined)} />
      )}
      {tab === 'TableView' && <ModelActionMenu model={selectedRow} onModelDelete={() => setSelectedRow(undefined)} />}
    </>
  );
};

export default ModelList;
export const modelListColumns = columns;
