import {useCallback} from 'react';
import {downloadBlob, sanitizeFilename} from '../../../../../utils/download';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {trackInstanceParametersExported} from '../../../../../tracking/tracking';
import {useScenarioDetailLoaderWithParams} from '../../../../../store/scenario';

const useScenarioParametersExport = ({model, subModel, scenario}: Partial<ScenarioActionProps>) => {
  const detailLoader = useScenarioDetailLoaderWithParams();
  return useCallback(() => {
    if (model && scenario && subModel) {
      detailLoader([scenario.id]).then((scenarioDetail: any) => {
        const parameters = scenarioDetail.input.value ? JSON.parse(scenarioDetail.input.value) : {};
        const blob = new Blob([JSON.stringify(parameters, null, 4)], {type: 'application/json'});
        downloadBlob(blob, `${sanitizeFilename(scenario.name)}.json`);
        trackInstanceParametersExported(model, subModel, scenario);
      });
    }
  }, [model, subModel, scenario, detailLoader]);
};

export default useScenarioParametersExport;
