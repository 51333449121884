import React from 'react';
import {RadioButton16, CheckmarkOutline16, CircleSolid16} from '@carbon/icons-react';

type WizardStepIconProps = {
  active: boolean;
  completed: boolean;
  className?: string;
};

const WizardStepIcon: React.FC<WizardStepIconProps> = ({active, completed, className}) => {
  if (active) {
    return <CircleSolid16 className={className} />;
  }
  return completed ? <CheckmarkOutline16 className={className} /> : <RadioButton16 className={className} />;
};

export default WizardStepIcon;
