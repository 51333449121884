import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestList, requestUserExpertiseSetLevel} from './api';

export enum userExpertiseActions {
  UPDATE = 'UPDATE',
  MODEL_USER_TYPE = 'MODEL_USER_TYPE',
}

export type userExpertiseAction =
  | AsyncAction<
      userExpertiseActions.UPDATE,
      Await<ReturnType<typeof requestUserExpertiseSetLevel>>,
      Parameters<typeof requestUserExpertiseSetLevel>
    >
  | AsyncAction<
      userExpertiseActions.MODEL_USER_TYPE,
      Await<ReturnType<typeof requestList>>,
      Parameters<typeof requestList>
    >;
