/* eslint-disable @typescript-eslint/camelcase */
import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {styles} from './styles';
import {Tab, Tabs} from '@material-ui/core';
import {tid} from '../../../../../../testUtils';
import {ModelCard} from '../../ModelCard/ModelCard';
import {EditLineageMode} from './types';
import {Form, useForm} from '../../../../../core/Form';
import ParentSelection from './ParentSelection';
import RootSelection from './RootSelection';
import {
  buildFlatHierarchy,
  ModelFlatNode,
  replaceNodeInFlatHierarchy,
  useModelFlatHierarchy,
} from '../../../../../../store/modelLinks';
import {isModelCreating, modifyModelMetadata, useModels} from '../../../../../../store/model';
import {ModelDTO} from 'hemwb-api';
import {FormGroup} from 'react-reactive-form';
import {ModelTreeNode} from '../../../../../../store/modelFile';
import {diff} from 'deep-object-diff';
import SuspenseNull from '../../../../../core/Suspense/SuspenseNull';
import {validateModelVersion} from '../../../../../../store/model/validator';

export type ModelEditLineageProps = {
  model: ModelDTO;
  files: ModelTreeNode[];
  onSubmit: (model: ModelDTO) => void;
  onChange?: (model: ModelDTO) => void;
  buttons: (params: {disabled: boolean}) => string | ReactNode;
};

const ModelEditLineage: React.FC<ModelEditLineageProps> = ({model, files, onChange, onSubmit, buttons}) => {
  const {uuid} = model;
  const [node, setNode] = useState<ModelFlatNode<ModelDTO>>({
    model: {...model, metadata: {...model.metadata}} as ModelDTO,
    parentId: model.metadata.tree_parent_id || '',
    originalParentId: isModelCreating(model) ? '' : model.metadata.tree_parent_id || '',
  });
  const models = useModels();
  const [mode, setMode] = useState<EditLineageMode>(EditLineageMode.SELECT_PARENT);
  const [rootId, setRootId] = useState(model.uuid || model.metadata.tree_root_id || '');
  const hierarchy = useModelFlatHierarchy(rootId);
  const [descendantHierarchy, setDescendantHierarchy] = useState<ModelFlatNode[]>();
  const [workingHierarchy, setWorkingHierarchy] = useState<ModelFlatNode[]>([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  //set descendants on load
  useEffect(() => {
    if (!descendantHierarchy) {
      if (hierarchy) {
        const nodeFound = hierarchy.find((n) => n.model.uuid === uuid);
        if (nodeFound) {
          setDescendantHierarchy(
            buildFlatHierarchy(
              node.model,
              hierarchy.map((n) => n.model),
            ).filter((n) => n.model.uuid !== uuid),
          );
        }
      } else if (node && node.originalParentId === '') {
        setDescendantHierarchy([]);
      }
    }
  }, [uuid, node, hierarchy, descendantHierarchy]);

  useEffect(() => {
    if (hierarchy && descendantHierarchy) {
      if (rootId === node.model.metadata.tree_root_id && !hierarchy.some((n) => n.model.uuid === node.model.uuid)) {
        setWorkingHierarchy([...hierarchy, node, ...descendantHierarchy]);
      } else {
        const n = hierarchy.find((n) => n.model.uuid === uuid);
        n && setNode(n as ModelFlatNode<ModelDTO>);
        setWorkingHierarchy(hierarchy);
      }
    } else if (descendantHierarchy?.length === 0) {
      setWorkingHierarchy([node]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchy, descendantHierarchy, rootId]);

  const isModelInHierarchy = useMemo(() => {
    if (node.model.metadata.tree_root_id && !node.model.metadata.tree_parent_id && workingHierarchy.length > 1) {
      return false;
    }
    return workingHierarchy.some((n) => n.model.uuid === model.uuid);
  }, [workingHierarchy, model, node]);

  const displayTopModelCard = useMemo(() => {
    return mode === EditLineageMode.SELECT_ROOT || !isModelInHierarchy;
  }, [mode, isModelInHierarchy]);

  const handleMetadataChange = useCallback(
    (m: ModelDTO) => {
      const changed = diff(node.model.metadata, m.metadata);
      if (Object.keys(changed).length > 0) {
        const newNode = {
          ...node,
          model: m,
        };
        setWorkingHierarchy(replaceNodeInFlatHierarchy(workingHierarchy, newNode));
        setNode(newNode);
        onChange?.(newNode.model);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingHierarchy, node],
  );

  const handleParentSelect = useCallback(
    (newParentNode: ModelFlatNode<ModelDTO>) => {
      const isNewRoot = newParentNode.model.metadata.tree_root_id !== node.model.metadata.tree_root_id;
      const newNode = {
        ...node,
        parentId: newParentNode.model.uuid,
        model: modifyModelMetadata(node.model, {
          tree_root_id: newParentNode.model.metadata.tree_root_id,
          tree_parent_id: newParentNode.model.uuid,
        }),
      };

      setWorkingHierarchy([
        ...replaceNodeInFlatHierarchy(workingHierarchy, newNode),
        ...(isNewRoot ? descendantHierarchy! : []),
      ]);
      setNode(newNode);
      onChange?.(newNode.model);
    },
    [workingHierarchy, descendantHierarchy, node, onChange],
  );

  const handleNewHierarchy = useCallback(() => {
    const newNode = {
      ...node,
      parentId: '',
      model: modifyModelMetadata(node.model, {
        tree_root_id: node.model.uuid,
        tree_parent_id: '',
      }),
    };

    setWorkingHierarchy([newNode, ...descendantHierarchy!]);
    setNode(newNode);
    setMode(EditLineageMode.SELECT_PARENT);
    onChange?.(newNode.model);
  }, [node, descendantHierarchy, onChange]);

  const form = useForm(new FormGroup({}));

  const handleSubmit = useCallback(() => {
    setSubmitClicked(true);
    if (isModelInHierarchy && !validateModelVersion(node.model, models!)) {
      onSubmit(node.model);
    }
  }, [onSubmit, node, isModelInHierarchy, models]);

  if (!models) {
    return <SuspenseNull />;
  }

  return (
    <>
      <div className={styles.relativeWrapper}>
        <div className={styles.tabsContainer}>
          <Tabs
            value={mode}
            onChange={(event, newMode) => {
              setMode(newMode);
            }}
            indicatorColor="primary"
            textColor="inherit"
            className="tabs-secondary">
            <Tab value={EditLineageMode.SELECT_ROOT} label="Select Lineage" {...tid('tab-lineage')} />
            <Tab value={EditLineageMode.SELECT_PARENT} label="Link Model Entry" {...tid('tab-link-model')} />
          </Tabs>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.modelWrapper}>
            {displayTopModelCard && (
              <>
                <ModelCard model={model} />
                {submitClicked && !isModelInHierarchy && <p className="colorError">Model is not connected yet</p>}
              </>
            )}
          </div>
        </div>

        {mode === EditLineageMode.SELECT_PARENT && (
          <ParentSelection
            model={node.model}
            files={files}
            flatHierarchy={workingHierarchy}
            isModelInHierarchy={isModelInHierarchy}
            onSelect={handleParentSelect}
            onChange={handleMetadataChange}
            submitClicked={submitClicked}
          />
        )}
        {mode === EditLineageMode.SELECT_ROOT && (
          <RootSelection
            currentRootId={node.model.metadata.tree_root_id}
            model={node.model}
            onSelected={(rootId) => {
              setRootId(rootId);
              setMode(EditLineageMode.SELECT_PARENT);
            }}
            onNewHierarchy={handleNewHierarchy}
            onCancel={() => setMode(EditLineageMode.SELECT_PARENT)}
          />
        )}
      </div>
      <Form
        className=""
        FieldGroupProps={{strict: false}}
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              {buttons({
                disabled:
                  // modelAndValidity[1] &&
                  mode !== EditLineageMode.SELECT_PARENT,
              })}
            </>
          );
        }}
      />
    </>
  );
};

export default ModelEditLineage;
