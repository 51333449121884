import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {Save16} from '@carbon/icons-react';
import {useVisualizationAnalysisSaveSideBarModal} from './useVisualizationAnalysisSaveSideBarModal';
import {VisualizationSaveProps} from './VisualizationAnalysisSave';
import {canSaveAnalysis} from '../../../../../permissions/usePermissions';

const VisualizationAnalysisSaveActionMenuButton: React.FC<
  Partial<VisualizationSaveProps> & {scenarioInProgress?: boolean}
> = (props) => {
  const {ModalComponent, openModal} = useVisualizationAnalysisSaveSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_ANALYSIS)}
        // permission={canAddSubModel(props.model)}
        permission={canSaveAnalysis(props.model)}
        disabled={!props.scenario || props.scenarioInProgress}
        onClick={openModal}
        icon={<Save16 />}
        text={props.scenario?.name !== '' ? 'Update Analysis' : 'Save Analysis'}
      />
      {ModalComponent}
    </>
  );
};

export default VisualizationAnalysisSaveActionMenuButton;
