import React, {useCallback} from 'react';
import {Form, TextFieldControl, useForm} from '../../../core/Form';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {tid} from '../../../../testUtils';
import {useScenarioGroupIsNameUnique} from '../../../../store/scenarioGroup/hooks';
import {ExecutionSetupDTO, ModelDTO, ScenarioGroupDetailDTO} from 'hemwb-api';

type EditGroupLabelPopperContentProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  group: ScenarioGroupDetailDTO;
  onSubmit: (name: string) => void;
  handleClose: () => void;
  fieldLabel?: string;
};

const EditGroupLabelPopperContent: React.FC<EditGroupLabelPopperContentProps> = ({
  model,
  subModel,
  group,
  handleClose,
  onSubmit,
  fieldLabel = 'Group Label',
}) => {
  const isNameUnique = useScenarioGroupIsNameUnique(model.uuid);

  const validateNameUnique = async (control: AbstractControl) => {
    const name = control.value;
    return name && !(await isNameUnique(name, subModel.id, group.id)) ? {isUnique: true} : null;
  };

  const form = useForm(
    new FormGroup({
      name: new FormControl(group.name, [Validators.required, Validators.maxLength(20)], validateNameUnique),
    }),
  );

  const handleSubmit = useCallback(() => {
    onSubmit(form.value.name);
    handleClose();
  }, [form, onSubmit, handleClose]);

  return (
    <div style={{width: '35rem', padding: '1rem'}}>
      <Form
        className=""
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <TextFieldControl
                name="name"
                label={fieldLabel}
                TextFieldProps={{
                  autoFocus: true,
                  autoComplete: 'off',
                  inputProps: {
                    ...tid('input', 'value'),
                  },
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default EditGroupLabelPopperContent;
