import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {visualizationFreshStart} from '../../../../../store/visualization';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {Reset16} from '@carbon/icons-react';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';

type VisualizationFreshStartActionMenuButtonProps = {
  modelUuid: string;
  scenarioInProgress?: boolean;
};

const VisualizationFreshStartActionMenuButton: React.FC<VisualizationFreshStartActionMenuButtonProps> = ({
  modelUuid,
  scenarioInProgress = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = useCallback(() => {
    dispatch(visualizationFreshStart());
    history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid}));
  }, [dispatch, history, modelUuid]);

  return (
    <SideBarButtonItem
      {...tid(VISUALIZATION_ACTION_MENU_IDS.FRESH_START)}
      disabled={scenarioInProgress}
      onClick={handleClick}
      icon={<Reset16 />}
      text="Fresh Start"
    />
  );
};

export default VisualizationFreshStartActionMenuButton;
