import {useSideBarModal} from '../../../../../layout/common/useSideBarModal';
import React, {useMemo} from 'react';
import SideBarModal from '../../../../../layout/common/SideBarModal';
import UserDetails from './UserDetails';
import {ExUserRegistrationDetails} from 'hemwb-api';

export type UserActionMenuProps = {
  selectedUser: ExUserRegistrationDetails | null;
};

export const useUserDetailSideBarModal = (props: UserActionMenuProps) => {
  const {selectedUser} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal();

  const ModalComponent = useMemo(() => {
    return (
      <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
        <div style={{width: '50rem', paddingLeft: '3rem'}}>
          {!!buttonRef && <UserDetails selectedUser={selectedUser} />}
        </div>
      </SideBarModal>
    );
  }, [buttonRef, closeModal, selectedUser]);

  return {
    openModal,
    ModalComponent,
  };
};
