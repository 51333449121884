import React, {MutableRefObject, useCallback, useEffect, useState} from 'react';
import {useNavigationInterceptor} from '../../../hooks/useNavigationInterceptor';
import {DialogGeneric} from './DialogGeneric';
import {defaultSubmitButtonProps} from '../Buttons/SubmitButton';
import {PageFirst16} from '@carbon/icons-react';

export type SetEnabledInterceptor = (enabled: boolean) => void;

type DialogNavigationInterceptedProps = {
  isEnabled?: boolean;
  title?: string;
  text?: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  setEnabledInterceptorRef?: MutableRefObject<SetEnabledInterceptor | undefined>;
};

const DialogNavigationIntercepted: React.FC<DialogNavigationInterceptedProps> = ({
  isEnabled = true,
  title = 'Navigation intercepted',
  text = 'By leaving this page all changes will be lost. Do you want to continue?',
  onClickYes,
  onClickNo,
  setEnabledInterceptorRef,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {onNavigationIntercepted, goInterceptedLocation, setEnabled} = useNavigationInterceptor();

  useEffect(() => {
    if (setEnabledInterceptorRef) {
      setEnabledInterceptorRef.current = setEnabled;
    }
  }, [setEnabled, setEnabledInterceptorRef]);

  useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled, setEnabled]);

  // disable after unmounting
  useEffect(() => () => setEnabled(false), [setEnabled]);

  const handleClickYes = useCallback(() => {
    setIsOpen(false);
    onClickYes?.();
    goInterceptedLocation();
  }, [onClickYes, goInterceptedLocation]);

  const handleClickNo = useCallback(() => {
    setIsOpen(false);
    onClickNo?.();
  }, [onClickNo]);

  const handleOnNavigationIntercepted = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  onNavigationIntercepted(handleOnNavigationIntercepted);

  return (
    <DialogGeneric
      title={title}
      open={isOpen}
      buttons={[
        {onClick: handleClickYes, children: 'Yes', color: 'primary', size: 'large', variant: 'outlined'},
        {...defaultSubmitButtonProps, endIcon: <PageFirst16 />, onClick: handleClickNo, children: 'No'},
      ]}>
      {text}
    </DialogGeneric>
  );
};

export default DialogNavigationIntercepted;
