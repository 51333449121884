import React, {useCallback, useState} from 'react';
import {DialogGeneric} from '../../../../../core/dialog/DialogGeneric';
import {TextOrProgress} from '../../../../../core/textOrProgress/TextOrProgress';
import {tid} from '../../../../../../testUtils';
import {UserBaseDTO} from 'hemwb-api';
import {useMounted} from '../../../../../../hooks/useMounted';
import clsx from 'clsx';

export type UserSetActiveDialogProps = {
  user: UserBaseDTO;
  open: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<any> | void;
};

const UserSetActiveDialog: React.FC<UserSetActiveDialogProps> = ({user, open, onCancel, onSubmit}) => {
  const mounted = useMounted();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    (onSubmit() || Promise.resolve())
      .catch(() => null)
      .finally(() => {
        mounted.current && setIsSubmitting(false);
      });
  }, [onSubmit, mounted]);

  return (
    <DialogGeneric
      open={open}
      buttons={[
        {
          onClick: onCancel,
          disabled: isSubmitting,
          children: 'Cancel',
          color: 'default',
          variant: 'contained',
          ...tid('dialog-confirm', 'btn', 'cancel'),
        },
        {
          onClick: handleSubmit,
          disabled: isSubmitting,
          children: <TextOrProgress active={isSubmitting}>{user.active ? 'Deactivate' : 'Activate'}</TextOrProgress>,
          color: user.active ? 'default' : 'primary',
          className: clsx({redButton: user.active}),
          variant: 'contained',
          ...tid('dialog-confirm', 'btn', user.active ? 'deactivate' : 'activate'),
        },
      ]}
      title={user.active ? 'Confirm Deactivation' : 'Confirm Activation'}
      PaperProps={tid('dialog-confirm', user.active ? 'deactivation' : 'activation') as {}}>
      {user.active
        ? 'By deactivating the user, the user will immediately lose access to Model Repository but will NOT be removed as owner or viewer from any models.'
        : 'By re-activating the user, existing model ownerships and viewing rights will be restored.'}
    </DialogGeneric>
  );
};

export default UserSetActiveDialog;
