import {ModelDTO} from 'hemwb-api';
import React from 'react';
import {TextFieldControl, Form} from '../../../../core/Form';
import {FormGroup} from 'react-reactive-form';
import {renderModelOptionItem} from './renderModelOptionItem';
import {tid} from '../../../../../testUtils';
import {Grid} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import GridTitle from '../../../../core/Form/GridTitle';
import {useModelEligibleList} from '../../../../../store/model';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {PlayFilled16} from '@carbon/icons-react';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Routes} from '../../../../router/routes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {InputLabel, TextField} from '@material-ui/core';

type UserEnrollFormProps = {
  onSubmit: () => void;
  form: FormGroup;
};

export type UserEnrollFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  models: ModelDTO[];
  role: string;
};

export const UserEnrollForm: React.FC<UserEnrollFormProps> = ({onSubmit, form}) => {
  //const [values, setFieldValue] = useState<ModelDTO[]>([]);
  /* const form = useForm(
    formObjectRef?.current
      ? formObjectRef.current
      : new FormGroup({
          firstName: new FormControl(null, [Validators.required]),
          lastName: new FormControl(null, [Validators.required]),
          email: new FormControl(null, [Validators.required]),
          models: new FormControl([], [Validators.required]),
        }),
  ); */

  const modelList = useModelEligibleList();

  /* useEffect(() => {
    if (formObjectRef) {
      formObjectRef.current = form;
      formObjectRef.current.enable();
    }
  }, [formObjectRef, form]); */

  if (!modelList) {
    return <SuspenseNull />;
  }

  return (
    <Form
      group={form}
      onSubmit={() => onSubmit()}
      render={({valid}) => (
        <>
          <Grid container direction="column" alignItems="center" justify="space-between">
            <GridTitle>Generate Registration Code</GridTitle>
            <GridItem>
              <div>
                Enter the external user&apos;s information to generate a registration code for signing up the external
                user.
              </div>
            </GridItem>
            <GridItem>
              <TextFieldControl
                name="firstName"
                label="First Name"
                required
                TextFieldProps={{
                  placeholder: 'First Name',
                  id: 'firstname',
                  inputProps: tid('input-firstname'),
                }}
              />
            </GridItem>
            <GridItem>
              <TextFieldControl
                name="lastName"
                label="Last Name"
                required
                TextFieldProps={{
                  placeholder: 'Last Name',
                  id: 'lastname',
                  inputProps: tid('input-lastname'),
                }}
              />
            </GridItem>
            <GridItem>
              <TextFieldControl
                name="email"
                label="Email ID"
                required
                customErrorMessages={{
                  required: 'This field is required.',
                  pattern:
                    'You have entered email address ending with the Merck domain. Please enter an email address that does not have the Merck domain such as @merck.com or @msd.com.',
                }}
                TextFieldProps={{
                  placeholder: 'Enter Email ID',
                  id: 'email',
                  inputProps: tid('input-email'),
                }}
              />
            </GridItem>
            <GridItem></GridItem>
            <GridItem>
              <InputLabel shrink required={true}>
                Select models to assign to external user
              </InputLabel>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={modelList}
                disableCloseOnSelect
                size="small"
                value={form.value.models}
                getOptionSelected={(option, value) => option.uuid === value.uuid}
                getOptionLabel={(option) => option.name}
                renderOption={renderModelOptionItem}
                style={{width: '100%'}}
                renderInput={(params) => (
                  <TextField {...params} label="" fullWidth name="models" variant="filled" margin="dense" />
                )}
                onChange={(event, val) => {
                  if (val !== null) {
                    const items: ModelDTO[] = [];
                    val.map((v: ModelDTO) => items.push(v));
                    form.controls.models.setValue(items);
                  }
                }}
              />
            </GridItem>
          </Grid>

          <div className="buttonsContainer alignCenter">
            <CancelButton to={Routes.EXTERNALIZATION_LIST}></CancelButton>
            <SubmitButton disabled={!valid} endIcon={<PlayFilled16 />} {...tid('btn-enroll')}>
              Next
            </SubmitButton>
          </div>
        </>
      )}
    />
  );
};
