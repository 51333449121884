import {
  FindUsersRequest,
  UserDTO,
  UserType,
  ExternalUserRegistrationDTO,
  ExternalUserSelfRegistrationDTO,
  ExUserRegistrationDetails,
} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';

export enum UserActions {
  FETCH_LIST = 'USER/FETCH_LIST',
  FETCH_EXTERNAL_LIST = 'USER/FETCH_EXTERNAL_LIST',
  FETCH_DETAIL = 'USER/FETCH_DETAIL',
  SET_ACTIVE = 'USER/SET_ACTIVE',
  ENROLL = 'USER/ENROLL',
  EXTERNAL_REGISTER = 'USER/EXTERNAL_REGISTER',
  EXTERNAL_SELF_REGISTER = 'USER/EXTERNAL_SELF_REGISTER',
  SET_USER_TYPE = 'USER/SET_USER_TYPE',
  FIND = 'USER/FIND_USERS',
  ACTIVATE_EXTERNAL_USER = 'USER/ACTIVATE_EXTERNAL_USER',
  DEACTIVATE_EXTERNAL_USER = 'USER/DEACTIVATE_EXTERNAL_USER',
  REJECT_EXTERNAL_USER = 'USER/REJECT_EXTERNAL_USER',
}

export type UserAction =
  | AsyncAction<UserActions.FETCH_LIST, UserDTO[], []>
  | AsyncAction<UserActions.FETCH_EXTERNAL_LIST, ExUserRegistrationDetails[], []>
  | AsyncAction<UserActions.FETCH_DETAIL, UserDTO, [string]>
  | AsyncAction<UserActions.SET_ACTIVE, void, [string, boolean]>
  | AsyncAction<UserActions.ENROLL, UserDTO, [string, UserType]>
  | AsyncAction<UserActions.SET_USER_TYPE, void, [string, UserType]>
  | AsyncAction<UserActions.FIND, UserDTO[], [string, Partial<FindUsersRequest>]>
  | AsyncAction<UserActions.ACTIVATE_EXTERNAL_USER, void, [string]>
  | AsyncAction<UserActions.DEACTIVATE_EXTERNAL_USER, void, [string]>
  | AsyncAction<UserActions.REJECT_EXTERNAL_USER, void, [string, string]>
  | AsyncAction<UserActions.EXTERNAL_REGISTER, void, [ExternalUserRegistrationDTO]>
  | AsyncAction<UserActions.EXTERNAL_SELF_REGISTER, void, [ExternalUserSelfRegistrationDTO]>;
