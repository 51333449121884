import {List} from '@material-ui/core';
import React from 'react';
import {ExUserRegistrationDetails} from 'hemwb-api';
import {tid} from '../../../../../../testUtils';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {Email16, Repeat16, TaskView16, RowDelete16, CloseOutline16, CheckmarkOutline16} from '@carbon/icons-react';
import {useUserActionDialog} from '../UserActionDialog/useUserActionDialog';
import {useSelector} from 'react-redux';
import SideBarAnchorItem from '../../../../../layout/common/SideBarAnchorItem';
import {authLoggedInUserSelector} from '../../../../../../store/auth';
import {useSharedSideBarToggle} from '../../../../../../store/customSettings';
import SideBar from '../../../../../layout/common/SideBar';
import {Routes, getUrlRoute} from '../../../../../router/routes';
import SideBarLinkItem from '../../../../../layout/common/SideBarLinkItem';
import {useUserDetailSideBarModal} from './useUserDetailSideBarModal';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '19rem',
};

export type UserActionMenuProps = {
  selectedUser: ExUserRegistrationDetails | null;
  adminExternalizationPage: boolean;
};

export const UserActionMenu: React.FC<UserActionMenuProps> = ({selectedUser, adminExternalizationPage}) => {
  const loggedInUser = useSelector(authLoggedInUserSelector);
  const {openUserActionDialog, UserActionDialog} = useUserActionDialog();
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);
  const {ModalComponent, openModal} = useUserDetailSideBarModal({selectedUser});
  const disabled = !selectedUser || !loggedInUser || !selectedUser?.email;
  const link = selectedUser
    ? getUrlRoute(Routes.EXTERNALIZATION_ENROLL, {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
      })
    : Routes.EXTERNALIZATION_ENROLL;

  return (
    <>
      <SideBar position="right" {...sharedSidebar} {...tid('user-list-action-sidebar')}>
        <List dense component="nav">
          {!adminExternalizationPage ? (
            <>
              <SideBarLinkItem
                {...tid('btn', 'sidebar', 'chat')}
                disabled={disabled || selectedUser?.status === 'ACTIVE'}
                linkTo={link}
                icon={<Repeat16 />}
                text="Regenerate Code"
              />
              <SideBarAnchorItem
                {...tid('btn', 'sidebar', 'email')}
                disabled={disabled}
                href={disabled ? '#' : `mailto:${selectedUser?.email}`}
                icon={<Email16 />}
                text="Email User"
              />
            </>
          ) : (
            <>
              <SideBarButtonItem
                {...tid('btn', 'sidebar', 'activate')}
                disabled={
                  disabled || selectedUser?.status === 'ACTIVE' || selectedUser?.status === 'PENDING_REGISTRATION'
                }
                icon={<CheckmarkOutline16 />}
                text="Activate User"
                onClick={() => {
                  openUserActionDialog({user: selectedUser!, action: 'activate'});
                }}
              />
              <SideBarButtonItem
                {...tid('btn', 'sidebar', 'deactivate')}
                disabled={
                  disabled ||
                  selectedUser?.status === 'INACTIVE' ||
                  selectedUser?.status === 'PENDING_REGISTRATION' ||
                  selectedUser?.status === 'PENDING_REVIEW'
                }
                icon={<CloseOutline16 />}
                text="Deactivate User"
                onClick={() => {
                  openUserActionDialog({user: selectedUser!, action: 'deactivate'});
                }}
              />
              <SideBarButtonItem
                {...tid('btn', 'sidebar', 'reject')}
                disabled={
                  disabled ||
                  selectedUser?.status === 'INACTIVE' ||
                  selectedUser?.status === 'ACTIVE' ||
                  selectedUser?.status === 'PENDING_REGISTRATION'
                }
                icon={<RowDelete16 />}
                text="Reject Request"
                onClick={() => {
                  openUserActionDialog({user: selectedUser!, action: 'reject'});
                }}
              />
            </>
          )}
          <SideBarButtonItem
            {...tid('btn', 'sidebar', 'details')}
            disabled={disabled}
            icon={<TaskView16 />}
            text="View Details"
            onClick={openModal}
          />
          {ModalComponent}
        </List>
      </SideBar>
      {UserActionDialog}
    </>
  );
};
