import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {ModelDTO, UpdatableModelDTO} from 'hemwb-api';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {Form, FormRenderProps, TextFieldControl, useForm} from '../../../../../core/Form';
import {useDispatch} from 'react-redux';
import debounce from 'debounce-promise';
import {isModelCreating, modelNameExists, modifyModelMetadata, isModelExternal} from '../../../../../../store/model';
import {tid} from '../../../../../../testUtils';
import styles from './ModelEditSuffixForm.module.scss';
import {modelGenerateName} from '../../../../../../store/model/modelGenerateName';

export type ModelEditSuffixFormProps = {
  model: ModelDTO;
  onSubmit: (updatedModel: ModelDTO) => void;
  onChange?: (updatedModel: ModelDTO, valid: boolean) => void;
  buttons: (params: FormGroup & {submitting: boolean}) => string | ReactNode;
  issueList?: ReactNode;
};

const ModelEditSuffixForm: React.FC<ModelEditSuffixFormProps> = ({model, onSubmit, onChange, issueList, buttons}) => {
  const [name, setName] = useState(modelGenerateName(model));
  const dispatch = useDispatch();
  const externalModel = isModelExternal(model);
  const debouncedModelNameExists = debounce((modelCheckName: UpdatableModelDTO) => {
    return modelNameExists(dispatch, modelCheckName);
  }, 1000);

  const validateNameUnique = async (control: AbstractControl) => {
    const modelCheckName: UpdatableModelDTO = {metadata: {...model.metadata, suffix: control.value}};
    return (isModelCreating(model) || modelGenerateName(modelCheckName) !== model.name) &&
      (await debouncedModelNameExists(modelCheckName))
      ? {isNotUnique: true}
      : null;
  };

  const validateNameLength = (control: AbstractControl) => {
    const modelCheckName: UpdatableModelDTO = {metadata: {...model.metadata, suffix: control.value}};
    return modelGenerateName(modelCheckName).length > 255 ? {isNotValidLength: true} : null;
  };

  const defaultForm = useMemo(() => {
    return () =>
      new FormGroup({
        suffix: new FormControl(
          model.metadata.suffix || '',
          [Validators.maxLength(200), validateNameLength],
          validateNameUnique,
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm(defaultForm);

  useEffect(() => {
    form.controls.suffix.markAsTouched();
    if (externalModel) {
      form.disable();
    }
    const updateName = () => {
      const modelWithSuffix: UpdatableModelDTO = {
        metadata: {...model.metadata, suffix: form.controls.suffix.value || ''},
      };
      setName(modelGenerateName(modelWithSuffix));
      onChange?.(modifyModelMetadata(model, {suffix: form.controls.suffix.value || ''}), form.valid);
    };
    form.controls.suffix.valueChanges.subscribe(updateName);
    form.statusChanges.subscribe(updateName);
    return () => {
      form.controls.suffix.valueChanges.unsubscribe(updateName);
      form.statusChanges.unsubscribe(updateName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, model.metadata]);

  const handleSubmit = useCallback(() => {
    const metadata = {...model.metadata, suffix: form.controls.suffix.value || ''};
    return onSubmit({...model, name: modelGenerateName({metadata}), metadata});
  }, [form, onSubmit, model]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.generatedName}>
          <div className={styles.generatedNameLabel}>Generated Model Entry Name</div>
          {name}
        </div>
        <div className={styles.plusSign}>+</div>
        <Form
          className=""
          style={{minHeight: 100}}
          group={form}
          onSubmit={handleSubmit}
          {...tid('confirm-name-form')}
          render={(renderParams) => {
            return (
              <>
                <div className={styles.inputContainer}>
                  <TextFieldControl
                    name="suffix"
                    label="Your Custom Model Entry Name Suffix"
                    customErrorMessages={{
                      isNotUnique: 'Change Suffix in order to make your Model Entry Name unique.',
                      isNotValidLength: 'The Mode Entry name is too long, maximum length is 255 characters.',
                    }}
                    TextFieldProps={{
                      autoComplete: 'off',
                      inputProps: {
                        ...tid('input', 'suffix'),
                      },
                    }}
                  />
                </div>
                <br />
                <br />
                {buttons({...renderParams, valid: renderParams.valid} as FormRenderProps)}
              </>
            );
          }}
        />
      </div>
      {issueList}
    </>
  );
};

export default ModelEditSuffixForm;
