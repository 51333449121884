import React, {useCallback} from 'react';
import {DialogGeneric} from '../../../../../core/dialog/DialogGeneric';
import {Typography, Button} from '@material-ui/core';
import {tid} from '../../../../../../testUtils';
import {ExUserRegistrationDetails} from 'hemwb-api';
import clsx from 'clsx';
import {FormGroup, FormControl, Validators} from 'react-reactive-form';
import {Form, TextFieldControl, useForm} from '../../../../../core/Form';
import {RowDelete16, CheckmarkOutline16} from '@carbon/icons-react';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {activateExternalUser, deactivateExternalUser, rejectExternalUser} from '../../../../../../store/user';
import {useDispatch} from 'react-redux';

export type UserActionDialogProps = {
  user: ExUserRegistrationDetails;
  open: boolean;
  onCancel: () => void;
  action: string;
};

const UserActionDialog: React.FC<UserActionDialogProps> = ({user, open, onCancel, action}) => {
  const dispatch = useDispatch();
  const form = useForm(
    new FormGroup({
      reason: new FormControl(null, [Validators.required]),
    }),
  );

  const handleSubmit = useCallback(() => {
    if (action === 'activate') {
      return activateExternalUser(dispatch, user.email)
        .then(() => {
          dispatch(messageAdd('External user activate successfully', MessageTypes.SUCCESS));
          onCancel();
        })
        .catch(() => dispatch(messageAdd('Failed to activate external user', MessageTypes.ERROR)));
    } else if (action === 'deactivate') {
      return deactivateExternalUser(dispatch, user.email)
        .then(() => {
          dispatch(messageAdd('External user deactivate successfully', MessageTypes.SUCCESS));
          onCancel();
        })
        .catch(() => dispatch(messageAdd('Failed to deactivate external user', MessageTypes.ERROR)));
    } else {
      return rejectExternalUser(dispatch, user.email, form.controls.reason.value)
        .then(() => {
          dispatch(messageAdd('External user rejected successfully', MessageTypes.SUCCESS));
          onCancel();
        })
        .catch(() => dispatch(messageAdd('Failed to reject external user', MessageTypes.ERROR)));
    }
  }, [action, dispatch, user.email, onCancel, form.controls.reason.value]);

  const handleChildren = (action: string) => {
    if (action === 'activate') {
      return (
        <span>
          Activate User <CheckmarkOutline16 style={{marginLeft: '10px', marginBottom: '-3px'}} />
        </span>
      );
    } else if (action === 'deactivate') {
      return (
        <span>
          Deactivate User <CheckmarkOutline16 style={{marginLeft: '10px', marginBottom: '-3px'}} />
        </span>
      );
    } else {
      return (
        <span>
          Reject Request <RowDelete16 style={{marginLeft: '10px', marginBottom: '-3px'}} />
        </span>
      );
    }
  };

  const handleTitle = (action: string) => {
    if (action === 'activate') {
      return 'Confirm Activation';
    } else if (action === 'deactivate') {
      return 'Confirm Deactivation';
    } else {
      return 'Reject Access Request';
    }
  };

  const handleContent = (action: string) => {
    if (action === 'activate') {
      return (
        <span style={{fontSize: '14px', color: 'black'}}>
          {' '}
          By activating the user, then will gain access to the models that have been authorized by the Model Owner. If
          you have any concerns or issues, please reach out to the Model Owner for further assistance.
        </span>
      );
    } else if (action === 'deactivate') {
      return (
        <span style={{fontSize: '14px', color: 'black'}}>
          {' '}
          Deactivating a user will be remove their access to models authorized by the Model Owner. The Model Owner can
          submit a request to re-activate the user at any time. Reach out to the Model Owner for further assistance.
        </span>
      );
    } else {
      return (
        <>
          <TextFieldControl
            label="Reason of Rejection"
            name="reason"
            TextFieldProps={{
              inputProps: {
                ...tid('input', 'message'),
              },
            }}
          />

          <span style={{fontSize: '14px', color: 'black'}}>
            {' '}
            By rejecting access request for the user, the user profile will stay inactive. If you have any concerns or
            issue, please reach out to Model Owner for further assistance
          </span>
        </>
      );
    }
  };
  return (
    <DialogGeneric open={open} title={handleTitle(action)} PaperProps={tid('dialog-confirm', 'Reject-request') as {}}>
      <Form
        group={form}
        render={() => {
          return (
            <div>
              <Typography>
                <span style={{fontWeight: 'bold', color: 'black', fontSize: '14px'}}>First Name: </span>
                {user.firstName}
              </Typography>
              <Typography>
                <span style={{fontWeight: 'bold', color: 'black', fontSize: '14px'}}>Last Name: </span>
                {user.lastName}
              </Typography>
              <Typography>
                <span style={{fontWeight: 'bold', color: 'black', fontSize: '14px'}}>Email: </span>
                {user.email}
              </Typography>
              <Typography>
                <span style={{fontWeight: 'bold', color: 'black', fontSize: '14px'}}>Registration Code: </span>
                {user.registrationCode}
              </Typography>
              <Typography>
                <span style={{fontWeight: 'bold', color: 'black', fontSize: '14px'}}>
                  Model Owner who provided the registration code:{' '}
                </span>
                {user.createdBy}
              </Typography>
              <br />
              {handleContent(action)}
              <br />
              <div className="buttonsContainer">
                <br />
                <br />
                <Button
                  onClick={onCancel}
                  {...tid('dialog-notify-cancel', 'cancel')}
                  variant="outlined"
                  className={clsx({redButton: action === 'reject' || action === 'deactivate'})}
                  color="primary">
                  {' '}
                  Cancel{' '}
                </Button>
                <Button
                  {...tid('dialog-notify-submit', 'submit')}
                  variant="contained"
                  disabled={action === 'reject' && form.controls.reason.value === null}
                  className={clsx({redButton: action === 'reject' || action === 'deactivate'})}
                  color="primary"
                  onClick={handleSubmit}>
                  {handleChildren(action)}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </DialogGeneric>
  );
};

export default UserActionDialog;
