import React from 'react';
import {useModelMetadataOptionsUsage, useFileTagUsage} from '../../../../../../store/modelMetadata';
import {ModelMetadataOptionsDTOOptions, MetadataCountDTO} from 'hemwb-api';
import WarningText from '../../../../../core/WarningText/WarningText';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {getUrlRoute, Routes} from '../../../../../router/routes';
import {Link} from 'react-router-dom';

type UsageListProps = {
  property: keyof ModelMetadataOptionsDTOOptions;
  value: string;
  fileTagCount: MetadataCountDTO[] | undefined;
};

const UsageList: React.FC<UsageListProps> = ({property, value, fileTagCount}) => {
  const usageList = useModelMetadataOptionsUsage(property);
  const FileTagUsageList = useFileTagUsage(fileTagCount);

  const usages = usageList?.[value];
  const fileTagUsages = FileTagUsageList?.[value];

  const affectedModelList = !usages?.length ? fileTagUsages : usages;
  const affectedModels = !usages?.length ? fileTagUsages?.length : usages.length;

  return (
    <>
      <br />
      {affectedModels ? <WarningText text="List of model entries that will be affected." /> : <> </>}
      <br />
      <Table>
        <TableBody>
          {affectedModelList?.map((model) => (
            <TableRow key={model.uuid}>
              <TableCell>
                <Link
                  className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                  to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}>
                  {model.name}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default UsageList;
