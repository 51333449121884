import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../../../hooks/useMounted';
import {ModelDTO} from 'hemwb-api';
import {modelNameExists} from '../../../../../../store/model';
import ModelEditSuffixForm from './ModelEditSuffixForm';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import WarningText from '../../../../../core/WarningText/WarningText';
import {modelGenerateName} from '../../../../../../store/model/modelGenerateName';

export const useNameCollisionCheckDialog = () => {
  const dispatch = useDispatch();
  const mounted = useMounted();

  const [invalidModel, setInvalidModel] = useState<ModelDTO>();
  const promiseResolve = useRef<(model: ModelDTO) => void>();
  const promiseReject = useRef<() => void>();

  const checkFutureName = useCallback(
    (updatedModel: ModelDTO, model?: ModelDTO): Promise<ModelDTO> => {
      if (model && modelGenerateName(updatedModel) === modelGenerateName(model)) {
        return Promise.resolve(updatedModel);
      }
      return modelNameExists(dispatch, updatedModel).then((res) => {
        if (res) {
          setInvalidModel(updatedModel);

          return new Promise<ModelDTO>((resolve, reject) => {
            promiseResolve.current = resolve;
            promiseReject.current = reject;
          });
        }
        return Promise.resolve(updatedModel);
      });
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    (updatedModel: ModelDTO) => {
      mounted.current && setInvalidModel(undefined);
      promiseResolve.current?.(updatedModel);
    },
    [promiseResolve, mounted],
  );

  const handleCancel = useCallback(() => {
    promiseReject.current?.();
    mounted.current && setInvalidModel(undefined);
  }, [promiseReject, mounted]);

  const DialogComponent = useMemo(() => {
    if (!invalidModel) {
      return null;
    }

    return (
      <Dialog open={true}>
        <DialogTitle>Model entry&apos;s name collision</DialogTitle>
        <DialogContent>
          <WarningText
            text={
              "Name of model entry has to be unique. Note that model entry's name might change with modifying its metadata, version or name suffix."
            }
          />
          <br />
          <br />
          <ModelEditSuffixForm
            model={invalidModel}
            onSubmit={handleSubmit}
            buttons={({valid}) => (
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={handleCancel} />
                <SubmitButton {...tid('btn-update-suffix')} disabled={!valid}>
                  Update suffix
                </SubmitButton>
              </div>
            )}
          />
        </DialogContent>
      </Dialog>
    );
  }, [invalidModel, handleCancel, handleSubmit]);

  return {
    checkFutureName,
    DialogComponent,
  };
};
