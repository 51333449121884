import {BoxLayout, boxLayoutStyles as styles} from '../../layout/BoxLayout';
import React from 'react';

export const Error500Page: React.FC<{}> = () => (
  <BoxLayout>
    <div className={styles.heading}>
      <h2 className={styles.title}>
        <strong>Model Repository</strong> had encountered an exception
      </h2>
      <div className={styles.subtitle}>
        This may be because of an application issue or an issue with one of application dependencies.
        <br />
        <br />
        If the issue reappears, please report it.
      </div>
    </div>
  </BoxLayout>
);
