import {ModelMetadataActions} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {
  requestModelMetadataAddOption,
  requestModelMetadataDeleteOption,
  requestModelMetadataOptions,
  requestModelMetadataOptionsCount,
  requestModelMetadataOptionsUsage,
  requestModelMetadataUpdateOption,
} from './api';

const modelMetadataLoadOptionsActionsCreator = buildAsyncActionsCreator(ModelMetadataActions.REQUEST_OPTIONS);
export const modelMetadataLoadOptions = createAsyncActionExecutor(
  modelMetadataLoadOptionsActionsCreator,
  requestModelMetadataOptions,
);

const modelMetadataLoadOptionsUsageActionsCreator = buildAsyncActionsCreator(
  ModelMetadataActions.REQUEST_OPTIONS_USAGE,
);
export const modelMetadataLoadOptionsUsage = createAsyncActionExecutor(
  modelMetadataLoadOptionsUsageActionsCreator,
  requestModelMetadataOptionsUsage,
);

const modelMetadataAddOptionActionsCreator = buildAsyncActionsCreator(ModelMetadataActions.REQUEST_OPTION_ADD);
export const modelMetadataAddOption = createAsyncActionExecutor(
  modelMetadataAddOptionActionsCreator,
  requestModelMetadataAddOption,
);

const modelMetadataUpdateOptionActionsCreator = buildAsyncActionsCreator(ModelMetadataActions.REQUEST_OPTION_UPDATE);
export const modelMetadataUpdateOption = createAsyncActionExecutor(
  modelMetadataUpdateOptionActionsCreator,
  requestModelMetadataUpdateOption,
);

const modelMetadataDeleteOptionActionsCreator = buildAsyncActionsCreator(ModelMetadataActions.REQUEST_OPTION_DELETE);
export const modelMetadataDeleteOption = createAsyncActionExecutor(
  modelMetadataDeleteOptionActionsCreator,
  requestModelMetadataDeleteOption,
);

const modelMetadataLoadOptionsCountActionsCreator = buildAsyncActionsCreator(
  ModelMetadataActions.REQUEST_OPTIONS_COUNT,
);
export const modelMetadataLoadOptionsCount = createAsyncActionExecutor(
  modelMetadataLoadOptionsCountActionsCreator,
  requestModelMetadataOptionsCount,
);
