import {useLocalStorage} from '../../hooks/useLocalStorage';
import {useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {customSettingsInit, customSettingsUpdate} from './actions';
import {CustomSettings} from './types';
import {customSettingsSelector} from './selectors';
import {appInitializedSelector} from '../app';
import {useSelector} from '../rootReducer';

export const useCustomSettingsLocalStorage = () => {
  const customSettings = useSelector(customSettingsSelector);
  const appInitialized = useSelector(appInitializedSelector);
  const dispatch = useDispatch();
  const [storedCustomSettings, setStoredCustomSettings] = useLocalStorage<Partial<CustomSettings>>(
    'customSettings',
    {},
  );

  useEffect(() => {
    dispatch(customSettingsInit(storedCustomSettings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (appInitialized) {
      setStoredCustomSettings(customSettings);
    }
  }, [customSettings, setStoredCustomSettings, appInitialized]);
};

type Widths = {
  collapsed: string;
  expanded: string;
};

export const useSharedSideBarToggle = (widths: Widths) => {
  const isSidebarCollapsed = useSelector((state) => customSettingsSelector(state).isSidebarCollapsed);
  const dispatch = useDispatch();

  const toggleOpen = useCallback(() => {
    dispatch(
      customSettingsUpdate({
        isSidebarCollapsed: !isSidebarCollapsed,
      }),
    );
  }, [dispatch, isSidebarCollapsed]);

  const style = {
    width: isSidebarCollapsed ? widths.collapsed : widths.expanded,
  };

  return {
    toggleOpen,
    style,
    isOpen: !isSidebarCollapsed,
  };
};
