import React from 'react';
import {FieldControl, GroupProps} from 'react-reactive-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

type SingleCheckboxControlProps = GroupProps & {
  label?: React.ReactNode;
  disabled?: boolean;
};

export const SingleCheckboxControl: React.FC<SingleCheckboxControlProps> = ({label, ...FieldControlProps}) => {
  return (
    <FieldControl
      {...FieldControlProps}
      render={({handler}) => {
        const {value, disabled, ...restHandlers} = handler();
        return (
          <FormControlLabel
            control={
              //@ts-ignore
              <Checkbox
                checked={!!value}
                {...restHandlers}
                disabled={disabled || FieldControlProps.disabled}
                color="primary"
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};
