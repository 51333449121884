import React, {useState, useCallback, useEffect} from 'react';
import {DialogGeneric} from './DialogGeneric';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {defaultCancelButtonProps} from '../Buttons/CancelButton';
import {defaultSubmitButtonProps} from '../Buttons/SubmitButton';
import {tid} from '../../../testUtils';
import {FileInputAttributes} from '../../core/ModelInput/InputDefinition/types';
import FileTree from '../FileTree/FileTree';
import {Radio} from '@material-ui/core';
import {TreeNode} from '../../../store/modelFile';

type ControlsMap = {[key in keyof Required<FileInputAttributes>]: AbstractControl};

export type FileAsInputDialogProps = {
  open: boolean;
  handleCancel: () => void;
  onSubmit: (uuid: string | null) => void;
  files: TreeNode[] | undefined | null;
  formGroup: FormGroup & {controls: ControlsMap};
  isFileRemoved: boolean;
  onRemoveFileChange: (value: boolean) => void;
};
export const FileAsInputDialog: React.FC<FileAsInputDialogProps> = ({
  open,
  handleCancel,
  onSubmit,
  files,
  formGroup,
  isFileRemoved,
  onRemoveFileChange,
}) => {
  const [selection, setSelection] = useState<string>(formGroup.value.files || '');

  const handleChange = (uuid: string) => {
    setSelection((value) => (value === uuid ? '' : uuid));
  };

  useEffect(() => {
    if (isFileRemoved === true) {
      setSelection('');
      onRemoveFileChange(!isFileRemoved);
    }
  }, [isFileRemoved, onRemoveFileChange]);

  const renderButtons = useCallback(
    (node: TreeNode) => {
      const checked = Array.isArray(selection) ? selection.some((uuid) => uuid === node.uuid) : node.uuid === selection;
      if (node.folder) {
        return null;
      }
      return (
        <Radio
          key={node.uuid}
          color="primary"
          checked={checked}
          onChange={() => handleChange(node.uuid)}
          {...tid('Radio', node.uuid)}
        />
      );
    },
    [selection],
  );

  return (
    <DialogGeneric
      open={open}
      buttons={[
        {
          ...defaultCancelButtonProps,
          onClick: () => handleCancel(),
          children: 'Close',
          ...tid('btn', 'cancel', 'fileAsInputDialog'),
        },
        {
          ...defaultSubmitButtonProps,
          onClick: () => onSubmit(selection as string | null),
          disabled: Array.isArray(files) && files.length === 0 ? true : false,
          children: 'Select',
          ...tid('btn', 'submit', 'fileAsInputDialog'),
        },
      ]}
      title="Select file"
      childrenAsText={false}
      maxWidth="sm"
      fullWidth
      PaperProps={tid('fileAsInputDialog') as {}}>
      <div style={{minHeight: '30rem'}}>
        {files && files.length > 0 ? (
          <FileTree files={files} renderButtons={renderButtons} />
        ) : (
          <div style={{color: 'red', fontSize: '1.7rem', fontWeight: 'bold'}}>
            There are no files available for selection, please add help file in the model or if they are already
            available please assign the File Type as Help File.
          </div>
        )}
      </div>
    </DialogGeneric>
  );
};
