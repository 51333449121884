import {useContext, useEffect, useRef} from 'react';
import {WebSocketContext} from './WebSocketContext';
import {useDispatch} from 'react-redux';
import {StompSubscription} from '@stomp/stompjs';
import {messageAdd, MessageTypes} from '../store/message';
import {useCurrentUser} from '../store/auth';
import {notificationUpdatedList} from '../store/notifications';
import {NotificationDTOFromJSON} from 'hemwb-api';

export const useLiveNotificationsUpdates = () => {
  const {client, connected} = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const subscription = useRef<StompSubscription>();
  const loggedInUser = useCurrentUser();

  useEffect(() => {
    if (client?.connected && connected) {
      //
      //Sends message each time a new notification is created
      //
      subscription.current = client.subscribe(`/user/${loggedInUser.id}/queue/tourNotification`, (message) => {
        try {
          dispatch(notificationUpdatedList(NotificationDTOFromJSON(JSON.parse(message.body))));
        } catch (e) {
          dispatch(
            messageAdd(`Failed to parse WS response in /queue/tourNotification: ${message.body}`, MessageTypes.WARNING),
          );
        }
      });
    }

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [dispatch, client, connected, loggedInUser.id]);
};
