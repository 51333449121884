import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {useSubModelDeleteSideBarModal} from './useSubModelDeleteSideBarModal';
import {canDeleteSubModel} from '../../../../../permissions/usePermissions';
import {TrashCan16} from '@carbon/icons-react';

const SubModelDeleteActionMenuButton: React.FC<Partial<SubModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useSubModelDeleteSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        red
        {...tid(SUBMODEL_ACTION_MENU_IDS.DELETE)}
        permission={canDeleteSubModel(props.model, props.subModel)}
        onClick={openModal}
        icon={<TrashCan16 />}
        text="Delete"
      />
      {ModalComponent}
    </>
  );
};

export default SubModelDeleteActionMenuButton;
