import {
  ApiResponse,
  DynamicFiltersResponseDTO,
  GetModelsForDynamicFiltersRequest,
  ModelCloneDTO,
  ModelDetailDTO,
  ModelDTO,
  UpdatableModelDTO,
} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';
import {Action} from 'redux';

export const ModelStatusPublished = 'Published';
export const ModelStatusDraft = 'Draft';
export const ModelStatusRetired = 'Retired';
export const ModelStatusCreating = 'Creating';
export const ModelStatusDeleted = 'Deleted';

export enum ModelActions {
  FETCH_LIST = 'MODEL/FETCH_LIST',
  FETCH_ELIGIBLE_LIST = 'MODEL/FETCH_ELIGIBLE_LIST',
  FETCH_DYNAMIC_LIST = 'MODEL/FETCH_DYNAMIC_LIST',
  FETCH_DETAIL = 'MODEL/FETCH_DETAIL',
  CREATE = 'MODEL/CREATE',
  UPDATE = 'MODEL/UPDATE',
  UPDATE_CLEAR = 'MODEL/UPDATE_CLEAR',
  PUBLISH = 'MODEL/PUBLISH',
  RETIRE = 'MODEL/RETIRE',
  DOWNLOAD = 'MODEL/DOWNLOAD',
  NAME_EXISTS = 'MODEL/NAME_EXISTS',
  CLONE = 'MODEL/CLONE',
  CLONE_ALL = 'MODEL/CLONE_ALL',
  SHARE_EMAIL = 'MODEL/SHARE_EMAIL',
  DELETE_DRAFT = 'MODEL/DELETE_DRAFT',
  DOWNLOAD_ALL = 'MODEL/DOWNLOAD_ALL',
}

export type ModelAction =
  | AsyncAction<ModelActions.FETCH_LIST, ModelDTO[], []>
  | AsyncAction<ModelActions.FETCH_ELIGIBLE_LIST, ModelDTO[], []>
  | AsyncAction<ModelActions.FETCH_DYNAMIC_LIST, DynamicFiltersResponseDTO, [GetModelsForDynamicFiltersRequest]>
  | AsyncAction<ModelActions.FETCH_DETAIL, ModelDetailDTO, [string]>
  | AsyncAction<ModelActions.CREATE, ModelDTO, [UpdatableModelDTO]>
  | AsyncAction<ModelActions.UPDATE, ModelDetailDTO, [string, UpdatableModelDTO]>
  | Action<ModelActions.UPDATE_CLEAR>
  | AsyncAction<ModelActions.PUBLISH, void, [string]>
  | AsyncAction<ModelActions.RETIRE, void, [string, string]>
  | AsyncAction<ModelActions.DOWNLOAD, ApiResponse<Blob>, [string]>
  | AsyncAction<ModelActions.DOWNLOAD_ALL, ApiResponse<Blob>, [boolean]>
  | AsyncAction<ModelActions.NAME_EXISTS, boolean, [UpdatableModelDTO]>
  | AsyncAction<ModelActions.CLONE, ModelDTO, [string, ModelCloneDTO]>
  | AsyncAction<ModelActions.CLONE_ALL, ModelDTO, [string]>
  | AsyncAction<ModelActions.SHARE_EMAIL, void, [string, string[], string]>
  | AsyncAction<ModelActions.DELETE_DRAFT, void, [string]>;

export type ModelWithMetadata = Partial<ModelDTO> & {metadata: ModelDTO['metadata']};
export type RestrictedModelDTO = {uuid: string; metadata: ModelDTO['metadata']};
export type StoredModel = RestrictedModelDTO | ModelDTO | ModelDetailDTO;
