import React from 'react';
import {StringTypeProps} from '../types';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from 'react-reactive-form';
import {Grid} from '@material-ui/core';
import GridItem from '../../../Form/GridItem';
import {isFormArray, TextFieldControl, validateInteger} from '../../../Form';

type StringInputPrimitiveProps = {
  formGroup: FormGroup;
  index?: number;
};

export const StringInputPrimitive: React.FC<StringInputPrimitiveProps> = ({formGroup, index}) => {
  const {controls} = formGroup;
  const defaultValueControl = isFormArray(controls.defaultValue)
    ? index === undefined
      ? null
      : controls.defaultValue.controls[index]
    : controls.defaultValue;

  return (
    <Grid container direction="column">
      <GridItem>
        <TextFieldControl label="Length" control={controls.length} name="length" />
      </GridItem>
      {defaultValueControl && (
        <GridItem>
          <TextFieldControl label="Default Value" control={defaultValueControl} name="defaultValue" />
        </GridItem>
      )}
    </Grid>
  );
};

const validateLength: ValidatorFn = (control: AbstractControl) => {
  if (!control.parent || !control.value) {
    return null;
  }

  let maxLength = control.parent.get('length').value;
  if (!maxLength && control.parent.parent) {
    maxLength = control.parent.parent.get('length').value;
  }

  if (!maxLength) {
    return null;
  }

  return Validators.maxLength(maxLength)(control);
};

export const stringInputPrimitiveControlBuilder = (initialValues: StringTypeProps = {}) => {
  const controls = {
    length: new FormControl(initialValues.length ?? 255, [Validators.required, validateInteger]),
    defaultValue: new FormControl(initialValues.defaultValue, [validateLength]),
  };

  controls['length'].valueChanges.subscribe(() => {
    if (controls['length'].parent) {
      const defaultValueControl = controls['length'].parent.get('defaultValue');
      if (isFormArray(defaultValueControl)) {
        defaultValueControl.controls.forEach((c) => c.updateValueAndValidity());
      } else {
        defaultValueControl.updateValueAndValidity();
      }
    } else {
      controls['defaultValue'].updateValueAndValidity();
    }
  });

  return controls;
};
