import React from 'react';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {InputGenerator, InputType, RangeInputAttributes, rangeInputTypes} from '../types';
import {DropDownControl} from '../../../Form';
import GridItem from '../../../Form/GridItem';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {Grid} from '@material-ui/core';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';
import {getModelInputTypeLabel} from '../utils';

type ControlsMap = {[key in keyof Required<RangeInputAttributes>]: AbstractControl};

type RangeInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const RangeInput: React.FC<RangeInputProps> = ({formGroup}) => {
  const {controls} = formGroup;
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <DropDownControl
            label="Data Type"
            control={controls.dataType}
            name="dataType"
            options={Object.keys(rangeInputTypes)}
            getOptionLabel={getModelInputTypeLabel}
          />
        </GridItem>
        <GridItem />
        <GridItem>
          From
          <NumberInputPrimitive formGroup={controls.from as FormGroup} type={controls['dataType'].value} />
        </GridItem>
        <GridItem>
          To
          <NumberInputPrimitive formGroup={controls.to as FormGroup} type={controls['dataType'].value} />
        </GridItem>
      </Grid>
    </>
  );
};

export const rangeInputGenerator: InputGenerator<InputType.RANGE> = {
  type: InputType.RANGE,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      dataType: new FormControl(initialValues.dataType || InputType.INTEGER, [Validators.required]),
      from: new FormGroup(numberInputPrimitiveControlBuilder(initialValues.from)),
      to: new FormGroup(numberInputPrimitiveControlBuilder(initialValues.to)),
    } as ControlsMap);

    fg.controls.dataType.valueChanges.subscribe(() => {
      fg.controls.from.setValue({min: null, max: null, defaultValue: null});
      fg.controls.to.setValue({min: null, max: null, defaultValue: null});
    });

    return fg;
  },
  render: RangeInput,
};
