import React from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {tid} from '../../../../../testUtils';
import {Password16} from '@carbon/icons-react';
import {Button} from '@material-ui/core';
import {getUrlRoute, Routes} from '../../../../router/routes';

type ModelAccessConfirmationDialogProps = {
  requesterUserId: string | null;
  modelUuid: string;
  role: string;
  fileName: string | null;
  open: boolean;
  onCancel?: () => void;
};

const accessRoles = {
  VIEWER: 'Model Viewer',
  CALIBRATOR_MODELER: 'Calibration Modeler',
  SCENARIO_MODELER: 'Scenario Modeler',
  DISPLAY_VIEWER: 'Display User',
  OWNER: 'Model Owner',
};

const getAccessType = (role: string) => {
  // @ts-ignore
  return accessRoles[role];
};

const ModelAccessConfirmationDialog: React.FC<ModelAccessConfirmationDialogProps> = ({
  requesterUserId,
  modelUuid,
  role,
  fileName,
  open,
  onCancel,
}) => {
  const handleReload = () => {
    window.location.href = `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
      uuid: modelUuid,
    })}?tab=folder`;
    window.location.reload();
  };

  return (
    <DialogGeneric
      onHeaderCloseButtonClick={onCancel}
      open={open}
      style={{textAlign: 'center'}}
      title={
        <>
          <Password16 />
          &nbsp;Access Granted
        </>
      }
      PaperProps={tid('dialog-grant-access') as {}}
      childrenAsText={false}>
      {fileName ? (
        <>
          <div style={{padding: '10px', textAlign: 'left'}}>
            <b>{requesterUserId}</b> have been granted file access for
            <Button style={{textDecoration: 'underline', color: 'blue'}} onClick={handleReload}>
              {fileName}
            </Button>
          </div>
          <div style={{padding: '10px', textAlign: 'left'}}>
            You can update access permissions anytime by going to the Manage File Access.
          </div>
        </>
      ) : (
        <>
          <div style={{padding: '10px', textAlign: 'left'}}>
            <b>{requesterUserId}</b> has been added to the model as a <b>{getAccessType(role)}</b>
          </div>
          <div style={{padding: '10px', textAlign: 'left'}}>
            You can update mode access permissions anytime by going to the Model Access menu item.
          </div>
        </>
      )}
    </DialogGeneric>
  );
};

export default ModelAccessConfirmationDialog;
