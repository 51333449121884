import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../hooks/useMounted';
import {Routes, useMatchedRoute} from '../../../router/routes';
import DefaultPage from '../../../layout/common/DefaultPage';
import TableWithSortAndPagination, {TO_LOCALE_DATE_STRING_OPTIONS} from '../../../core/TableWithSortAndPagination';
import {ScenarioDTO, ScenarioListDTO, ScenarioOrderBy, ScenarioStatus} from 'hemwb-api';
import SubModelActionMenu from '../../subModel/common/SubModelActionMenu/SubModelActionMenu';
import {useModel} from '../../../../store/model';
import {typeToSection, useExecutionSetup} from '../../../../store/executionSetup';
import {GridState, Order, useGridState} from '../../../../store/grid';
import {useScenarioStatusUpdates} from '../../../../websocket/useScenarioStatusUpdates';
import {useGridLoader} from '../../../core/TableWithSortAndPagination/useGridLoader';
import {scenarioLoadList, scenarioSelector} from '../../../../store/scenario';
import {useFlashRow} from '../../../core/TableWithSortAndPagination/useFlashRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import {Button} from '@material-ui/core';
import {tid} from '../../../../testUtils';
import {useSelector} from '../../../../store/rootReducer';
import styles from './ScenarioList.module.scss';
import {useModelInput} from '../../../../store/modelInputs';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import {Search} from '../../../core/Search/Search';
import {getScenarioListGridColumns, getScenarioFilterStrategies} from './getScenarioListColumns';
import {ScenarioFilter} from './types';

const ScenarioList: React.FC = () => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const {modelUuid, subModelId} = useMatchedRoute(Routes.SCENARIO_LIST);

  useScenarioStatusUpdates();

  const model = useModel(modelUuid);
  const subModel = useExecutionSetup(Number(subModelId), modelUuid);
  const input = useModelInput(Number(subModelId));
  const section = typeToSection(subModel?.type);

  const [search, setSearchTextInstance] = useState('');

  const [selectedScenario, setSelectedScenario] = useState<ScenarioDTO>();
  const gridState = useGridState<ScenarioFilter>({
    orderBy: ScenarioOrderBy.CreatedOn,
    order: Order.DESC,
    filter: {
      status: [],
      name: [],
      createdBy: [],
      createdOnMonth: [],
    },
  });

  const loader = useCallback(
    (gState: GridState<ScenarioFilter>) => {
      if (!input) {
        return Promise.resolve({total: 0, list: []});
      }

      // Is IN_PROCESS here, but IN_PROGRESS on the backend
      let status, name, createdBy, createdOnMonth;
      if (gState.filter === null) {
        status = name = createdBy = createdOnMonth = undefined;
      } else {
        status = gState.filter.status
          .filter((s) => s && s !== 'all')
          .map((s) => (s === ScenarioStatus.INPROCESS ? 'IN_PROGRESS' : s));
        name = gState.filter.name.filter((s) => s && s !== 'all');
        createdBy = gState.filter.createdBy.filter((s) => s && s !== 'all');
        createdOnMonth = gState.filter.createdOnMonth.filter((s) => s && s !== 'all');
      }

      return scenarioLoadList(dispatch, {
        modelUuid: modelUuid,
        modelInputId: input.id,
        pageSize: gState.rowsPerPage,
        pageIndex: gState.page * gState.rowsPerPage,
        orderBy: ((gState.orderBy as any) as ScenarioOrderBy) || ScenarioOrderBy.CreatedOn,
        orderDesc: gState.order === Order.DESC,
        query: gState.search,
        view: true,
        // @ts-ignore
        status,
        name,
        createdBy,
        createdOnMonth,

        // query: gState.search,
        // scenarioType: isCalibration ? ScenarioType.CALIBRATION : ScenarioType.MAIN,
      });
    },
    [modelUuid, input, dispatch],
  );

  const searchScenarios = (scenarios: ScenarioDTO[], search: string | undefined) => {
    if (!search) {
      return scenarios;
    }
    const lcSearch = 'Not Executed'.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ? 'draft'
      : search.toLocaleLowerCase();

    return scenarios.filter(
      (scenario) =>
        scenario.name.toLocaleLowerCase().includes(lcSearch) ||
        scenario.createdBy?.firstName?.toLocaleLowerCase().includes(lcSearch) ||
        scenario.createdBy?.lastName?.toLocaleLowerCase().includes(lcSearch) ||
        scenario.createdBy?.id?.toLocaleLowerCase().includes(lcSearch) ||
        scenario.createdOn
          ?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)
          .toLocaleLowerCase()
          .includes(lcSearch) ||
        scenario.status.toLocaleLowerCase().includes(lcSearch),
    );
  };

  const [listData, setListData] = useState<ScenarioListDTO | null>();
  const [unfilteredListData, setUnfilteredListData] = useState<ScenarioListDTO | null>();
  const {loading, reload, data} = useGridLoader<ScenarioListDTO, ScenarioFilter>(gridState, loader);

  useEffect(() => {
    if (!unfilteredListData || (data && unfilteredListData && data.list.length > unfilteredListData.list.length)) {
      setUnfilteredListData(data);
    }
    setListData(data);
  }, [data, unfilteredListData]);

  const lastStatusUpdate = useSelector((state) => scenarioSelector(state).lastStatusUpdate);
  useEffect(() => {
    if (lastStatusUpdate && listData && listData.list.some((s) => s.id === lastStatusUpdate.id)) {
      setListData({
        ...listData,
        list: listData.list.map((s) => (s.id === lastStatusUpdate.id ? {...s, ...lastStatusUpdate} : s)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatusUpdate]);
  useFlashRow(lastStatusUpdate?.id);

  useEffect(() => {
    const newDataForSelectedScenario = listData?.list.find((s) => s.id === selectedScenario?.id);
    if (newDataForSelectedScenario) {
      setSelectedScenario(newDataForSelectedScenario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  const handleClickRow = useCallback(
    (scenario: ScenarioDTO) => {
      if (selectedScenario?.id === scenario.id) {
        setSelectedScenario(undefined);
      } else {
        setSelectedScenario(scenario);
      }
    },
    [selectedScenario, setSelectedScenario],
  );

  const handleScenariosChange = useCallback(() => {
    if (!mounted.current) {
      return;
    }

    if (data?.list.length === 1 && gridState.page > 0) {
      gridState.setPage(gridState.page - 1);
    } else {
      reload();
    }
    setSelectedScenario(undefined);
  }, [data, gridState, reload, setSelectedScenario, mounted]);

  return (
    <>
      <DefaultPage>
        <SuspenseNull>
          {model && subModel && input && (
            <>
              <SubModelSectionHeader model={model} section={section} subModel={subModel} title="Instance List" />
              <div className={styles.searchContainer}>
                <Search debounce={100} value={search} onValueChange={setSearchTextInstance} placeholder="Search" />
              </div>
              <div className="listPanel">
                {!!lastStatusUpdate && <div className={styles.newChangesText}>New changes in status</div>}
                <Button
                  onClick={() => reload()}
                  variant="contained"
                  color="default"
                  startIcon={<RefreshIcon />}
                  {...tid('btn', 'refresh-list')}>
                  Refresh
                </Button>
              </div>

              <TableWithSortAndPagination
                client={false}
                getRowId={(scenario) => scenario.id.toString()}
                rows={searchScenarios(listData ? listData.list : [], search)}
                total={listData?.total || 0}
                columns={getScenarioListGridColumns()}
                onClickRow={handleClickRow}
                isRowSelected={(scenario) => selectedScenario?.id === scenario.id}
                gridState={gridState}
                loading={loading}
                noResultsMessage="No scenario was found"
                filterStrategies={getScenarioFilterStrategies(unfilteredListData, reload, gridState)}
              />
            </>
          )}
        </SuspenseNull>
      </DefaultPage>
      <SubModelActionMenu
        model={model}
        subModel={subModel}
        section={section}
        scenario={selectedScenario}
        onSuccess={handleScenariosChange}
      />
    </>
  );
};

export default ScenarioList;
