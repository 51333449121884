import {ModelUuidAction} from '../types';

export enum VersionControlPage {
  LINEAGE,
  DIFF,
  HISTORY,
}

export enum VersionControlActions {
  TOGGLE_DIFF_SELECTION = 'VERSION_CONTROL/TOGGLE_DIFF_SELECTION',
  INIT = 'VERSION_CONTROL/INIT',
  PAGE = 'VERSION_CONTROL/PAGE',
}

export type VersionControlAction =
  | ModelUuidAction<VersionControlActions.TOGGLE_DIFF_SELECTION>
  | ModelUuidAction<VersionControlActions.INIT>
  | {type: VersionControlActions.PAGE; page: VersionControlPage};
