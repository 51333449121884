import {UpdatableModelDTO} from 'hemwb-api';
import {stringifyCountry} from './utils';

const versionValueDelimiter = (metadata: {[key: string]: string}) => {
  return metadata.version_release_of_model.includes('.')
    ? metadata.version_release_of_model
    : `${metadata.version_release_of_model}.0`;
};

export const modelGenerateName = (model: UpdatableModelDTO): string => {
  const {metadata} = model;

  const versionValue = metadata.version_release_of_model ? versionValueDelimiter(metadata) : null;

  const productValue = metadata.product ? metadata.product.split('|').sort().join(', ') : '';

  return [
    metadata.therapeutic_area,
    shrinkValue(metadata.disease),
    shrinkValue(productValue),
    metadata.model_type,
    stringifyCountry(model, ' – '),
    versionValue && `Version: ${versionValue}`,
    metadata.study_number && `${metadata.study_number}`,
    metadata.suffix,
  ]
    .filter(Boolean)
    .join(' - ');
};

const shrinkValue = (value: string): string => {
  return value && value.length > 50 ? value.substring(0, 44) + '...' : value;
};
