import React, {useCallback, useState} from 'react';
import {
  ModelMetadata,
  modelMetadataOptionsLabels,
  modelMetadataUpdateOption,
} from '../../../../../../store/modelMetadata';
import {useDispatch} from 'react-redux';
import {Edit32} from '@carbon/icons-react';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useMounted} from '../../../../../../hooks/useMounted';
import UsageList from './UsageList';
import UsageCountTitle from './UsageCountTitle';
import {trackModelMetadataOptionUpdated} from '../../../../../../tracking/tracking';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import UpdateOptionForm from './UpdateOptionForm';
import ActionHeader from '../../../../../core/Header/ActionHeader/ActionHeader';

const UpdateOption: React.FC<ManageMetadataActionProps> = ({
  property,
  value,
  onSuccess,
  onCancel,
  refreshOptionsCount,
  fileTagCount,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(
    (newValue: string, metadata?: ModelMetadata) => {
      setSubmitting(true);
      return modelMetadataUpdateOption(dispatch, property, value, newValue, metadata)
        .then(() => {
          dispatch(messageAdd(`The option has been updated`, MessageTypes.SUCCESS));
          trackModelMetadataOptionUpdated();
          onSuccess(newValue);
          if (refreshOptionsCount) refreshOptionsCount();
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    },
    [dispatch, onSuccess, property, value, mounted, refreshOptionsCount],
  );

  return (
    <>
      <ActionHeader label={`Update ${modelMetadataOptionsLabels[property]} Option`} Icon={<Edit32 />}>
        <UsageCountTitle property={property} value={value} fileTagCount={fileTagCount} />
      </ActionHeader>

      <UpdateOptionForm
        property={property}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        value={value}
        submitting={submitting}
      />

      <UsageList property={property} value={value} fileTagCount={fileTagCount} />
    </>
  );
};

export default UpdateOption;
