import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../../layout/common/useSideBarModal';
import ModelEditPermissionsSideBarModal from './ModelEditPermissionsSideBarModal';
import SideBarModal from '../../../../../layout/common/SideBarModal';
import {ModelActionProps} from '../../../../actionMenuTypes';

export const useModelEditPermissionsSideBarModal = (props: Partial<ModelActionProps>) => {
  const {model, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '80rem'}}>
            {!!buttonRef && (
              <ModelEditPermissionsSideBarModal model={model} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, handleSuccess, model]);

  return {
    openModal,
    ModalComponent,
  };
};
