import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {userExpertiseActions} from './types';
import {requestList, requestUserExpertiseSetLevel} from './api';

export const modelLoadListOptionsActionsCreator = buildAsyncActionsCreator(userExpertiseActions.UPDATE);
export const userExpertiseLoadOptionsSet = createAsyncActionExecutor(
  modelLoadListOptionsActionsCreator,
  requestUserExpertiseSetLevel,
);

export const modelLoadDetailActionsCreator = buildAsyncActionsCreator(userExpertiseActions.MODEL_USER_TYPE);
export const userExpertiseLoadList = createAsyncActionExecutor(modelLoadDetailActionsCreator, requestList);
