import React, {useState, useCallback, useEffect} from 'react';
import {UserEnrollFormValues} from './UserEnrollForm';
import {tid} from '../../../../../testUtils';
import {Grid, IconButton, Checkbox, FormControl, InputLabel, TextField} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './RolesTable.module.scss';
import GridItem from '../../../../core/Form/GridItem';
import GridTitle from '../../../../core/Form/GridTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {CaretLeft16} from '@carbon/icons-react';
import TableWithSortAndPagination, {Column} from '../../../../core/TableWithSortAndPagination';
import {Order, useGridState} from '../../../../../store/grid';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import RoleSelectionBox from './RoleSelectionBox';
import {hardCodedOptions} from './constants';
import {ExternalUserWithModel} from './types';
import {PreviewDialog} from './PreviewDialog';

type PropertySelection = {[uuid: string]: boolean};

type UserEnrollFormProps = {
  values: UserEnrollFormValues;
  handleCancel: () => void;
  handleDeleteRow: (model: ExternalUserWithModel) => void;
  selectedModels: ExternalUserWithModel[];
};

export const AddRolesForm: React.FC<UserEnrollFormProps> = ({
  values,
  handleCancel,
  selectedModels,
  handleDeleteRow,
}) => {
  const gridState = useGridState({orderBy: 'updatedOn', order: Order.ASC});
  const [selectAll, setSelectAll] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selected, setSelected] = useState<PropertySelection>({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [unSelectAll, setUnSelectAll] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isMRModelPresent = selectedModels.some((model) => model.platform === 'Model Repository');

  useEffect(() => {
    if (unSelectAll) {
      setSelectAll(false);
    }
  }, [unSelectAll]);

  const handleEnableSubmitButton = useCallback(() => {
    const checkRole = (obj: ExternalUserWithModel) => obj.role === '';
    setSubmitting(!selectedModels.some(checkRole));
  }, [selectedModels]);

  const handleSaveModelRole = useCallback(
    (role: string) => {
      for (const model of selectedModels) {
        if (model && selected[model.uuid]) {
          model.role = role;
          selected[model.uuid] = false;
        }
        setSelectedCount(0);
        setUnSelectAll(true);
        handleEnableSubmitButton();
      }
    },
    [selectedCount, setUnSelectAll], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const unSelectAllFiles = () => {
    for (const uuid in selected) {
      selected[uuid] = false;
    }
    setSelectedCount(0);
    setUnSelectAll(true);
  };

  const handlePreviewDialog = useCallback(() => {
    setOpenPreview(!openPreview);
  }, [openPreview]);

  const displayDropdown = (model: ExternalUserWithModel) => <DropDownList model={model} />;

  const DropDownList: React.FC<{model: ExternalUserWithModel}> = ({model}) => (
    <>
      <GridItem>
        <Autocomplete
          freeSolo
          data-testid={'model.role-' + model.uuid}
          style={{width: 300}}
          value={model.role}
          onChange={(event: any, newValue: string | null) => {
            if (newValue) {
              model.role = newValue;
            }
            handleEnableSubmitButton();
          }}
          getOptionLabel={(option) => option}
          renderOption={(option) => option}
          options={
            model.platform === 'WebModel 2.0'
              ? hardCodedOptions['modelRoles']
              : hardCodedOptions['modelRoles'].slice(0, 2)
          }
          renderInput={(params) => (
            <TextField
              name="modelRole"
              style={{width: 300}}
              placeholder="Select the Model Role"
              {...params}
              inputProps={{...params.inputProps, maxLength: 25}}
            />
          )}
        />
      </GridItem>
    </>
  );

  const displayCheckBox = (model: ExternalUserWithModel) => <CheckBox model={model} />;
  const CheckBox: React.FC<{model: ExternalUserWithModel}> = ({model}) => (
    <>
      <div>
        <div>
          <Checkbox
            key="selected"
            color="primary"
            checked={selected[model.uuid] || false}
            onChange={(event, checked) => {
              const isChecked = checked;
              setUnSelectAll(false);
              setSelected({...selected, [model.uuid]: isChecked});
              if (isChecked) {
                setSelectedCount(selectedCount + 1);
              } else {
                setSelectAll(false);
                setSelectedCount(Math.max(selectedCount - 1, 0));
              }
            }}
          />
        </div>
      </div>
    </>
  );
  const displayDeleteIcon = (model: ExternalUserWithModel) => <DeleteIcon model={model} />;
  const DeleteIcon: React.FC<{model: ExternalUserWithModel}> = ({model}) => (
    <>
      <div>
        <div className={styles.buttonSpacing}>
          <IconButton
            key={`delete-${model.uuid}`}
            aria-label="Delete"
            size="small"
            onClick={() => {
              handleDeleteRow(model);
            }}
            {...tid('btn', 'delete', model.uuid)}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
  const userGridColumns: Column<ExternalUserWithModel>[] = [
    {id: 'modelName', label: 'Model Name', sortable: true, value: (o) => o.name},
    {
      id: 'modelRole',
      label: '',
      sortable: false,
      customHeader: {
        heading: 'Select the Model Role',
        subHeading: 'Press down to select a suggestion, or being typing to the find role',
      },
      value: (o) => o.uuid,
      display: displayDropdown,
    },
    {
      id: 'selected',
      label: 'SelectAll',
      customHeader: {
        heading: (
          <div>
            {' '}
            SelectAll
            <Checkbox
              color="primary"
              checked={selectAll}
              onChange={(event, checked) => {
                setSelectAll(checked);
                for (const model of selectedModels) {
                  selected[model.uuid] = checked;
                }
                setSelectedCount(selectedModels.length);
                if (!checked) {
                  setSelectedCount(0);
                }
              }}
            />
          </div>
        ),
      },
      sortable: false,
      value: (o) => o.uuid,
      display: displayCheckBox,
    },
    {
      id: 'deleteButton',
      label: '',
      sortable: false,
      value: (o) => o.uuid,
      display: displayDeleteIcon,
    },
  ];

  return (
    <Grid container direction="column" alignItems="center" justify="space-between">
      <GridTitle>Generate Registration Code</GridTitle>
      <GridItem>
        <div>
          Enter the external user&apos;s information to generate a registration code for signing up the external user.
        </div>
      </GridItem>
      <GridItem>
        <FormControl fullWidth error={true} hiddenLabel={true} className="MuiFormControl-hem">
          <InputLabel shrink required={true}>
            First Name
          </InputLabel>
          <TextField label="" hiddenLabel={true} value={values.firstName} variant="filled" margin="dense" />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl fullWidth error={true} hiddenLabel={true} className="MuiFormControl-hem">
          <InputLabel shrink required={true}>
            Last Name
          </InputLabel>
          <TextField label="" hiddenLabel={true} value={values.lastName} variant="filled" margin="dense" />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl fullWidth error={true} hiddenLabel={true} className="MuiFormControl-hem">
          <InputLabel shrink required={true}>
            Email
          </InputLabel>
          <TextField label="" hiddenLabel={true} value={values.email} variant="filled" margin="dense" />
        </FormControl>
      </GridItem>
      <div style={{maxWidth: '70rem', width: '100%', marginBottom: '2rem', marginLeft: '41rem'}}>
        {selectedCount > 0 && (
          <RoleSelectionBox
            setUnSelectAll={setUnSelectAll}
            selectedModelsCount={selectedCount}
            onCancel={unSelectAllFiles}
            onClick={handleSaveModelRole}
            unSelectAll={unSelectAll}
            isMRModelPresent={isMRModelPresent}
          />
        )}
      </div>
      <GridItem>
        <TableWithSortAndPagination<ExternalUserWithModel>
          getRowId={(o) => o.uuid}
          // @ts-ignore
          rows={selectedModels}
          columns={userGridColumns}
          hidePagination={true}
          gridState={gridState}
        />
      </GridItem>
      <div className="buttonsContainer alignCenter">
        <CancelButton startIcon={<CaretLeft16 />} onClick={handleCancel}>
          Previous
        </CancelButton>
        <SubmitButton onClick={handlePreviewDialog} disabled={!submitting} {...tid('btn-add-role')}>
          Review and Generate Registration Invite
        </SubmitButton>
      </div>
      <PreviewDialog
        open={openPreview}
        values={values}
        selectedModels={selectedModels}
        onClose={() => setOpenPreview(false)}
      />
    </Grid>
  );
};
