import {IncidentListDTO, IncidentStatus} from 'hemwb-api';
import React from 'react';
import {Edit16, Email16} from '@carbon/icons-react';
import SideBarButtonItem from '../../../layout/common/SideBarButtonItem';
import SideBarAnchorItem from '../../../layout/common/SideBarAnchorItem';
import SideBar from '../../../layout/common/SideBar';
import {List} from '@material-ui/core';
import {useUpdateIncidentSideBarModal} from './UpdateIncident/useUpdateIncidentSideBarModal';
import {useSharedSideBarToggle} from '../../../../store/customSettings';
import {tid} from '../../../../testUtils';

export type IncidentActionMenuProps = {
  incident: IncidentListDTO | undefined;
  incidentId?: string;
  status?: string;
  issueSummary?: string;
  reportId?: number;
  comment?: string;
  onSuccess?: (status?: IncidentStatus) => void;
};

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '19rem',
};

export const IncidentActionMenu: React.FC<IncidentActionMenuProps> = ({
  incident,
  status,
  onSuccess,
  incidentId,
  issueSummary,
  reportId,
}) => {
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);
  const {ModalComponent, openModal} = useUpdateIncidentSideBarModal({
    incident,
    status,
    onSuccess,
    incidentId,
    issueSummary,
    reportId,
  });
  return (
    <SideBar position="right" {...sharedSidebar} {...tid('incident-list-action-sidebar')}>
      <List dense component="nav">
        <div>
          <SideBarButtonItem
            {...tid('btn', 'sidebar', 'edit')}
            icon={<Edit16 />}
            disabled={!incident}
            text="Edit Status"
            onClick={openModal}
          />
          {ModalComponent}
        </div>
        <SideBarAnchorItem
          {...tid('btn', 'sidebar', 'email')}
          icon={<Email16 />}
          disabled={!incident}
          text="Email User"
          href={`mailto:${incident?.createdBy.email}`}
        />
      </List>
    </SideBar>
  );
};
