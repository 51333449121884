import {FileRequestAccessApi} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const fileRequestAccessApi = new FileRequestAccessApi(configuration);

export const requestSendfileAccessRequested = (modelUuid: string, fileUuid: string, reason: string, fileName: string) =>
  fileRequestAccessApi.sendFileAccessRequested({
    modelUuid,
    fileUuid,
    reason,
    fileName,
  });
