import {RootState} from '../rootReducer';
import {userNotificationsSelector} from './selectors';
import {useReduxLoader} from '../useReduxLoader';
import {userNotificationsList} from './actions';

const userNotificationsSelectorBuilder = () => (state: RootState) => userNotificationsSelector(state).list || null;

export const useNotifications = (limit?: number) => {
  return useReduxLoader(userNotificationsSelectorBuilder, userNotificationsList, [limit]);
};
