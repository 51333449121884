import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {ModelAccessAction, ModelAccessActions} from './types';
import {OwnershipType} from 'hemwb-api';
import {requestList} from './api';
import {reduceAsyncAction} from '../asyncUtils';

type AsyncActionStateRequestList = AsyncActionState<
  Await<ReturnType<typeof requestList>>,
  Parameters<typeof requestList>
> & {timestamp: number};

export type ModelAccessState = {
  [OwnershipType.OWNER]: {
    [modelUuid: string]: AsyncActionStateRequestList;
  };
  [OwnershipType.CALIBRATORMODELER]: {
    [modelUuid: string]: AsyncActionStateRequestList;
  };
  [OwnershipType.SCENARIOMODELER]: {
    [modelUuid: string]: AsyncActionStateRequestList;
  };
  [OwnershipType.VESTLEAD]: {
    [modelUuid: string]: AsyncActionStateRequestList;
  };
};

const transformToOwnershipType = (ownershipString: string): OwnershipType | undefined => {
  switch (ownershipString) {
    case 'vestLead':
      return OwnershipType.VESTLEAD;
    case 'owners':
      return OwnershipType.OWNER;
    case 'calibratorModeler':
      return OwnershipType.CALIBRATORMODELER;
    case 'scenarioModeler':
      return OwnershipType.SCENARIOMODELER;
  }
};

export const modelAccessInitialState: ModelAccessState = {
  [OwnershipType.OWNER]: {},
  [OwnershipType.CALIBRATORMODELER]: {},
  [OwnershipType.SCENARIOMODELER]: {},
  [OwnershipType.VESTLEAD]: {},
};

const updateTypeInState = (state: ModelAccessState, modelUuid: string, type: string, value: any) => {
  if (state.hasOwnProperty(type) && state[type as keyof ModelAccessState].hasOwnProperty(modelUuid)) {
    return {
      ...state,
      [type]: {
        ...state[type as keyof ModelAccessState],
        [modelUuid]: {
          ...state[type as keyof ModelAccessState][modelUuid],
          timestamp: Date.now(),
          value,
        },
      },
    };
  }
  return state;
};

export const modelAccessReducer = (state = modelAccessInitialState, action: ModelAccessAction) => {
  switch (action.type) {
    case ModelAccessActions.REQUEST_LIST: {
      const [modelUuid, type] = action.params;

      return {
        ...state,
        [type]: {
          ...state[type],
          [modelUuid]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelAccessActions.UPDATE: {
      const [modelUuid, access] = action.params;
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return (Object.keys(access) as Array<keyof typeof access>).reduce((acc, type) => {
          const transformedType = transformToOwnershipType(type);
          if (!transformedType) {
            return acc;
          }
          if (transformedType === OwnershipType.VESTLEAD) {
            return updateTypeInState(acc, modelUuid, transformedType, [access[type]].filter(Boolean));
          }
          return updateTypeInState(acc, modelUuid, transformedType, access[type]);
        }, state);
      }
      return state;
    }
  }
  return state;
};
