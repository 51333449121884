import {getRouteByPathname, Routes} from './routes';

export const titles = {
  [Routes.ROOT]: '',
  [Routes.ADMIN]: '',

  [Routes.MODEL_UPLOAD]: 'Add Model Entry',

  [Routes.MODEL_LIST]: 'Model List',
  [Routes.MODEL_DETAIL]: 'Model Detail',

  [Routes.SUBMODEL_LIST]: 'SubModel List',

  [Routes.VISUALIZATION_STEP_1]: 'Visualization - Scenario selection',
  [Routes.VISUALIZATION_STEP_2]: 'Visualization - Reordering and setting',
  [Routes.VISUALIZATION_STEP_3]: 'Visualization - Dynamic data visualization',
  [Routes.VISUALIZATION_PARAMETERS_DISPLAY]: 'Visualization - Parameters display',

  [Routes.MODEL_INPUT_LIST]: 'Input Definition List',
  [Routes.MODEL_INPUT_EDITOR]: 'Input Definition Editor',

  [Routes.SCENARIO_LIST]: 'Scenario List',
  [Routes.SCENARIO_DETAIL]: 'Scenario Detail',
  [Routes.SCENARIO_PARAMETERS_EDITOR]: 'Scenario Parameters Editor',

  [Routes.SCENARIO_EXECUTION_QUEUE]: 'Scenario - Execution Queue',
  [Routes.EXTERNALIZATION_LIST]: 'Externalization',
  [Routes.EXTERNALIZATION_ENROLL]: 'External - User Enroll',

  [Routes.USER_LIST]: 'User List',
  [Routes.USER_ENROLL]: 'User Enroll',
  [Routes.USER_DETAIL]: 'User Detail',
  [Routes.USER_EDIT]: 'Edit User',

  [Routes.MANAGE_METADATA]: 'Manage Metadata',
  [Routes.MANAGE_METADATA_PROPERTY]: 'Manage Metadata',

  [Routes.INCIDENT_MANAGEMENT]: 'Incident Management',

  [Routes.ADMIN_EXTERNALIZATION_LIST]: 'External Users',

  [Routes.ALL_NOTIFICATIONS]: 'All Notification',

  [Routes.NOT_IMPLEMENTED]: '',
};

export const getTitleByPathname = (pathname: string): string => {
  const route = getRouteByPathname(pathname);
  // @ts-ignore
  const part = titles[route] || '';
  const pathName = `${part} - `;
  return `${part !== '' ? pathName : ''}Model Repository`;
};
