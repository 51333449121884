import {useSideBarModal} from '../../../../../layout/common/useSideBarModal';
import React, {useCallback, useMemo} from 'react';
import SideBarModal from '../../../../../layout/common/SideBarModal';
import AddOption from './AddOption';
import {ManageMetadataActionProps} from '../ActionMenuTypes';

export const useAddOptionSideBarModal = (props: Partial<ManageMetadataActionProps>) => {
  const {property, onSuccess, refreshOptionsCount} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(property);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      property && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <AddOption
                property={property}
                onSuccess={handleSuccess}
                onCancel={closeModal}
                refreshOptionsCount={refreshOptionsCount}
              />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, property, handleSuccess, closeModal, refreshOptionsCount]);

  return {
    openModal,
    ModalComponent,
  };
};
