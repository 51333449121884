import {IncidentStatus, ReportApi, IncidentDTO} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const reportApi = new ReportApi(configuration);

export const requestIncidentList = () => reportApi.getIncidentList();

export const requestIncidentDetail = (incidentId: string) => reportApi.getIncident({incidentId});

export const requestIncidentCreation = (reportId: number, issueDetail: string, userNotesText: string) =>
  reportApi.createIncident({
    incidentDTO: {
      reportId: reportId,
      stackTrace: issueDetail,
      status: IncidentStatus.OPEN,
      userNotes: userNotesText,
    },
  });

export const requestIncidentUpdate = (incidentId: string, incidentDTO: IncidentDTO) =>
  reportApi.updateIncident({incidentId, incidentDTO});
