import React, {Key, ReactNode} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import {DialogProps} from '@material-ui/core/Dialog';
import {ButtonProps} from '@material-ui/core/Button';
import {tid} from '../../../testUtils';
import CloseIcon from '@material-ui/icons/Close';
export type DialogGenericButton = ButtonProps & {
  key?: Key;
};

export type DialogGenericProps = Omit<DialogProps, 'title'> & {
  title?: string | ReactNode;
  buttons?: DialogGenericButton[];
  childrenAsText?: boolean;
  footerContent?: ReactNode;
  includesDialogActions?: boolean;
  onHeaderCloseButtonClick?: () => void;
};

export const DialogGeneric: React.FC<DialogGenericProps> = (props) => {
  const {
    title,
    children,
    buttons,
    onHeaderCloseButtonClick,
    childrenAsText = true,
    footerContent,
    includesDialogActions,
    ...dialogProps
  } = props;

  return (
    <Dialog {...dialogProps}>
      <DialogTitle style={{paddingLeft: onHeaderCloseButtonClick ? '3.56rem' : '2.4rem'}} {...tid('dialog-title')}>
        {title || 'Confirmation'}
        {onHeaderCloseButtonClick && (
          <IconButton
            style={{float: 'right'}}
            onClick={onHeaderCloseButtonClick}
            // key="close"
            size="small"
            color="inherit">
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {childrenAsText ? (
          <DialogContentText style={{overflowWrap: 'break-word'}}>{children}</DialogContentText>
        ) : (
          <>{children}</>
        )}
      </DialogContent>
      {!includesDialogActions && (
        <DialogActions>
          {footerContent && <div style={{marginRight: 'auto'}}>{footerContent}</div>}
          {buttons && buttons.map((button, index) => <Button key={button.key || index} {...button} />)}
        </DialogActions>
      )}
    </Dialog>
  );
};
