import React from 'react';
import DialogProgress from '../../../../../core/dialog/DialogProgress';
import {modelFileSelector} from '../../../../../../store/modelFile';
import {useSelector} from '../../../../../../store/rootReducer';

type UploadProgressModalProps = {
  total: number;
  processed: number;
};

const UploadProgressModal: React.FC<UploadProgressModalProps> = ({total, processed}) => {
  const uploaderState = useSelector((state) => modelFileSelector(state).uploader);

  let text = '';
  let title = '';

  if (uploaderState.isSubmitting) {
    title = `Processing files ${processed}/${total}`;
    if (uploaderState.nodeProcessing) {
      text = `${uploaderState.nodeProcessing.uploaded ? 'Deleting' : 'Uploading'} ${uploaderState.nodeProcessing.name}`;
    }
  }

  return <DialogProgress open={title !== ''} title={title} text={text} />;
};

export default UploadProgressModal;
