import {AbstractControl} from 'react-reactive-form';
import {Dispatch} from 'redux';
import {userFind} from '../../../../../../store/user';
import {fullName} from '../../../../../../store/user/utils';
import {UserBaseDTO} from 'hemwb-api';

export const validateModelStewardControl = (control: AbstractControl, dispatch: Dispatch) => {
  const valueIsArray = Array.isArray(control.value);
  const modelSteward: UserBaseDTO | null = valueIsArray ? control.value[0] : control.value;
  if (modelSteward && (modelSteward.id || modelSteward.email)) {
    return userFind(dispatch, (modelSteward.email || fullName(modelSteward))!, {
      limit: 20,
    }).then((users) => {
      const foundSteward = users.find((user) => user.id === modelSteward.id && user.email === modelSteward.email);
      if (!foundSteward) {
        return {invalidOption: true};
      } else if (modelSteward.id !== foundSteward.id) {
        control.setValue(valueIsArray ? [foundSteward] : foundSteward);
      }
      return null;
    });
  }
  return Promise.resolve(null);
};
