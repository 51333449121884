import React, {useCallback, useState} from 'react';
import {ModelMetadata, modelMetadataAddOption, modelMetadataOptionsLabels} from '../../../../../../store/modelMetadata';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useMounted} from '../../../../../../hooks/useMounted';
import {trackModelMetadataOptionAdded} from '../../../../../../tracking/tracking';
import {Add32} from '@carbon/icons-react';
import UpdateOptionForm from '../updateOption/UpdateOptionForm';
import ActionHeader from '../../../../../core/Header/ActionHeader/ActionHeader';
import {ManageMetadataActionProps} from '../ActionMenuTypes';

const AddOption: React.FC<Omit<ManageMetadataActionProps, 'value'>> = ({
  property,
  onSuccess,
  onCancel,
  refreshOptionsCount,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(
    (value: string, metadata?: ModelMetadata) => {
      setSubmitting(true);
      return modelMetadataAddOption(dispatch, property, value, metadata)
        .then(() => {
          dispatch(
            messageAdd(`${value} has been added into ${modelMetadataOptionsLabels[property]}`, MessageTypes.SUCCESS),
          );
          trackModelMetadataOptionAdded();
          onSuccess();
          if (refreshOptionsCount) refreshOptionsCount();
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    },
    [dispatch, onSuccess, property, mounted, refreshOptionsCount],
  );

  return (
    <>
      <ActionHeader label={`Add ${modelMetadataOptionsLabels[property]} Option`} Icon={<Add32 />}>
        This action won&apos;t affect any existing model entry
      </ActionHeader>

      <UpdateOptionForm property={property} submitting={submitting} onSubmit={handleSubmit} onCancel={onCancel} />
    </>
  );
};

export default AddOption;
