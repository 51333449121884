import React, {useEffect} from 'react';
import ModelUploadPageHeader from '../common/ModelUploadPageHeader';
import ModelEditLineage, {ModelEditLineageProps} from '../../model/common/ModelEdit/ModelEditLineage/ModelEditLineage';
import ModelUploadFooter from '../common/ModelUploadFooter';
import {trackLineageTabEvent} from '../../../../tracking/tracking';

type StepLineageProps = Omit<ModelEditLineageProps, 'buttons'>;

const renderButtons = (renderParams: any) => {
  return <ModelUploadFooter {...renderParams} />;
};

const StepLineage: React.FC<StepLineageProps> = (props) => {
  useEffect(() => {
    trackLineageTabEvent();
  }, []);

  return (
    <div>
      <ModelUploadPageHeader
        title="Connect your model entry"
        subTitle="First select model lineage for your new entry and then parent model."
      />
      <br />
      <br />
      <ModelEditLineage {...props} buttons={renderButtons} />
    </div>
  );
};

export default StepLineage;
