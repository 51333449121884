import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {requestContentList, requestMacroDiff, requestSheetDiff} from './api';
import {ExcelDiffActions} from './types';

const excelDiffLoadContentListActionsCreator = buildAsyncActionsCreator(ExcelDiffActions.REQUEST_CONTENT_LIST);
export const excelDiffLoadContentList = createAsyncActionExecutor(
  excelDiffLoadContentListActionsCreator,
  requestContentList,
  {
    showError: false,
  },
);

const excelDiffLoadSheetDiffActionsCreator = buildAsyncActionsCreator(ExcelDiffActions.REQUEST_SHEET_DIFF);
export const excelDiffLoadSheetDiff = createAsyncActionExecutor(
  excelDiffLoadSheetDiffActionsCreator,
  requestSheetDiff,
  {
    showError: false,
  },
);

const excelDiffLoadMacroDiffActionsCreator = buildAsyncActionsCreator(ExcelDiffActions.REQUEST_MACRO_DIFF);
export const excelDiffLoadMacroDiff = createAsyncActionExecutor(
  excelDiffLoadMacroDiffActionsCreator,
  requestMacroDiff,
  {
    showError: false,
  },
);

export const excelDiffClear = () => ({type: ExcelDiffActions.CLEAR});
