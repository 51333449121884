import React, {useMemo} from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {Save16} from '@carbon/icons-react';
import {useVisualizationAnalysisProgressSaveSideBarModal} from './useVisualizationAnalysisProgressSaveSideBarModal';
import {VisualizationAnalysisProgressSaveProps} from './VisualizationAnalysisProgressSave';
import {useSelector} from '../../../../../store/rootReducer';
import {visualizationSelector} from '../../../../../store/visualization';
import {ScenarioStatus} from 'hemwb-api';
import {canSaveAnalysis} from '../../../../../permissions/usePermissions';

const VisualizationAnalysisProgressSaveActionMenuButton: React.FC<
  Partial<VisualizationAnalysisProgressSaveProps> & {scenarioInProgress?: boolean}
> = (props) => {
  const {model, scenario, onSuccessRoute, scenarioInProgress = false} = props;

  const storedState = useSelector(visualizationSelector);
  const {selectedScenarios, subModel} = storedState.selection || {};

  const canSaveProgress = useMemo(() => {
    return (
      !!model &&
      subModel &&
      selectedScenarios &&
      (!scenario || scenario.status === ScenarioStatus.DRAFT) &&
      Object.values(selectedScenarios).filter(Boolean).length > 0
    );
  }, [model, subModel, selectedScenarios, scenario]);

  const extendedProps = useMemo(() => {
    return {
      model,
      scenario,
      onSuccessRoute,
      displaySubModelId: subModel?.displayExecutionSetupId,
    };
  }, [model, scenario, onSuccessRoute, subModel]);

  const {ModalComponent, openModal} = useVisualizationAnalysisProgressSaveSideBarModal(extendedProps);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_PROGRESS)}
        permission={canSaveAnalysis(props.model)}
        disabled={!canSaveProgress || scenarioInProgress}
        onClick={openModal}
        icon={<Save16 />}
        text="Save Progress"
      />
      {ModalComponent}
    </>
  );
};

export default VisualizationAnalysisProgressSaveActionMenuButton;
