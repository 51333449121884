export type ButtonWithPopperType = {
  id?: string;
  group: string;
};

export type AppComponentsState = {
  buttonWithPopper: {
    [group: string]: ButtonWithPopperType;
  };
};

export enum AppComponentsActions {
  BUTTON_WITH_POPPER_CLEAR = 'APP_COMPONENTS/BUTTON_WITH_POPPER_CLEAR',
  BUTTON_WITH_POPPER_SET = 'APP_COMPONENTS/BUTTON_WITH_POPPER_SET',
  BUTTON_WITH_POPPER_TOGGLE = 'APP_COMPONENTS/BUTTON_WITH_POPPER_TOGGLE',
}

export type AppComponentsButtonWithPopperClearAction = {
  type: AppComponentsActions.BUTTON_WITH_POPPER_CLEAR;
};

export type AppComponentsButtonWithPopperSetAction = {
  type: AppComponentsActions.BUTTON_WITH_POPPER_SET;
  button: ButtonWithPopperType;
};

export type AppComponentsButtonWithPopperToggleAction = {
  type: AppComponentsActions.BUTTON_WITH_POPPER_TOGGLE;
  button: ButtonWithPopperType;
};

export type AppComponentsAction =
  | AppComponentsButtonWithPopperClearAction
  | AppComponentsButtonWithPopperSetAction
  | AppComponentsButtonWithPopperToggleAction;
