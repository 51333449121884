import convert from 'xml-js';
import {parseDataType, parseValue} from '../typesUtility';
import {CalibrationScenarioInputs} from './types';
import {formatInput} from './formatInput';
import {InputAttributes} from '../InputDefinition/types';
import {associateFormValuesWithName} from './utils';
import {FormArray} from 'react-reactive-form';
import {generators} from './generators';

export const importFromXML = (xmlData: string, definitions: InputAttributes[]): CalibrationScenarioInputs => {
  const form = new FormArray(
    definitions.map((attr) => {
      return generators[attr.type].buildControl(attr as any, undefined);
    }),
  );

  const output: CalibrationScenarioInputs = associateFormValuesWithName(definitions, form.value);
  const parsed = convert.xml2js(xmlData, {compact: false});
  const parameters = parsed.elements[0].elements[2];

  parameters.elements.forEach((param: any) => {
    if (param?.attributes?.id) {
      const name = param.attributes.id;
      const dataType = parseDataType(param);
      output[name] = parseValue(param.elements?.[0]?.elements?.[0]?.elements?.[0]?.text, dataType?.type, param);
    }
  });

  return formatInput(definitions, output);
};
