import {ModelInputDetailDTO, ModelInputType, ScenarioDetailDTO} from 'hemwb-api';
import {scenarioFormGenerator} from './scenarioFormGenerator';
import {formatInput} from './formatInput';
import isEqual from 'lodash/isEqual';

export const isScenarioParametersValid = (scenario: ScenarioDetailDTO, input: ModelInputDetailDTO): boolean => {
  if (input.type === ModelInputType.JSONTEMPLATE) {
    const parsedInput = input.value ? JSON.parse(input.value) : {};
    const definitions = parsedInput.definitions || [];
    const parameters = scenario.input?.value ? JSON.parse(scenario.input.value) : {};
    const form = scenarioFormGenerator(definitions, parameters, false);

    const formattedParams = formatInput(definitions, parameters);

    return form.valid && isEqual(formattedParams, parameters);
  }

  return true;
};
