import {AbstractControl, FormArray, FormGroup} from 'react-reactive-form';
import {useEffect, useState} from 'react';
import DateFnsUtilsClass from '@date-io/date-fns';

const DateFnsUtils = new DateFnsUtilsClass();

export const useForm = <T, V>(
  newForm: FormGroup | (() => FormGroup),
  defaultValue?: T | null,
  valueToForm?: (value: T) => V,
): FormGroup => {
  const [form] = useState(newForm);

  useEffect(() => {
    defaultValue && form.setValue(valueToForm ? valueToForm(defaultValue) : defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, valueToForm]);

  return form;
};

export const hasErrors: (renderProps: Pick<AbstractControl, 'invalid' | 'submitted' | 'touched'>) => boolean = ({
  invalid,
  submitted,
  touched,
}) => (touched || submitted) && invalid;

const opaqueErrors = ({
  maxLength: true,
  isUnique: true,
  invalidOption: true,
} as any) as {[errorKey: string]: any};

export const getVisibleErrors = (renderProps: AbstractControl) => {
  const {touched, errors, submitted} = renderProps;
  return Object.keys(errors || {}).reduce((map, errorKey) => {
    if (touched || submitted || opaqueErrors[errorKey]) {
      map[errorKey] = errors[errorKey];
    }
    return map;
  }, ({} as any) as {[errorKey: string]: any});
};

const formatNumber = (num: any) => {
  if (num instanceof Date) {
    return DateFnsUtils.format(num, 'MM/dd/yyyy');
  }
  return num;
};

const checkForVisibleErrors = (visibleErrors: {[errorKey: string]: any}) => {
  if (Object.keys(visibleErrors).length === 0) {
    return;
  }
};

export const getErrorText = (
  visibleErrors: {[errorKey: string]: any},
  fieldName = 'Value',
  plural = false,
  customMessages: {[errorKey: string]: any} = {},
): string | undefined => {
  checkForVisibleErrors(visibleErrors);

  const keys = Object.keys(visibleErrors);
  if (keys.length > 0 && customMessages[keys[0]]) {
    return customMessages[keys[0]];
  }

  const field = fieldName.endsWith('*') ? fieldName.substring(0, fieldName.length - 1) : fieldName;
  return (
    (visibleErrors.required && `${field} ${plural ? 'are' : 'is'} required`) ||
    (visibleErrors.maxLength && `${field} ${plural ? 'are' : 'is'} too long`) ||
    (visibleErrors.email && 'Email format is incorrect') ||
    (visibleErrors.isUnique && `${field} ${plural ? 'are' : 'is'} not unique`) ||
    (visibleErrors.invalidOption && `${field} value is invalid`) ||
    (visibleErrors.min && `${field} must be greater then ${formatNumber(visibleErrors.min.min)}`) ||
    (visibleErrors.minOrEqual &&
      `${field} must be equal or greater then ${formatNumber(visibleErrors.minOrEqual.minOrEqual)}`) ||
    (visibleErrors.max && `${field} must be smaller then ${formatNumber(visibleErrors.max.max)}`) ||
    (visibleErrors.maxOrEqual &&
      `${field} must be equal or smaller then ${formatNumber(visibleErrors.maxOrEqual.maxOrEqual)}`) ||
    (visibleErrors.integer && `${field} must be integer`) ||
    (visibleErrors.number && `${field} must be a number`) ||
    (visibleErrors.format && `${field} ${plural ? 'are' : 'is'} in wrong format`)
  );
};

export const isFormArray = (control: AbstractControl): control is FormArray => {
  return control && Array.isArray((control as FormArray).controls);
};

export const isFormGroup = (control: AbstractControl): control is FormGroup => {
  return control && !!(control as FormGroup).controls && !Array.isArray((control as FormArray).controls);
};

export const listAllErrors = (control: AbstractControl, key = '') => {
  if (isFormArray(control)) {
    control.controls.forEach((c, index) => listAllErrors(c, key ? `${key}[${index}]` : `${index}`));
  } else if (isFormGroup(control)) {
    Object.keys(control.controls).forEach((k) => listAllErrors(control.controls[k], key ? `${key}-${k}` : k));
  } else if (control.errors) {
    Object.keys(control.errors).forEach((e) =>
      //eslint-disable-next-line
      console.log(
        'Key control: ' + key + ', keyError: ' + e + ', err value: ',
        control.errors[e] + ' control value: ' + control.value,
      ),
    );
  }
};

export const markAllAsTouched = (control: AbstractControl) => {
  if (isFormArray(control)) {
    control.controls.forEach((c) => markAllAsTouched(c));
  } else if (isFormGroup(control)) {
    Object.keys(control.controls).forEach((k) => markAllAsTouched(control.controls[k]));
  } else if (control) {
    control.markAsTouched();
  }
};
