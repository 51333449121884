import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import {SubModelActionProps} from '../../../actionMenuTypes';
import SubModelEdit from './SubModelEdit';

export const useSubModelEditSideBarModal = (props: Partial<SubModelActionProps>) => {
  const {model, subModel, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(subModel?.id);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      subModel && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <SubModelEdit model={model} subModel={subModel} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [model, subModel, buttonRef, closeModal, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};
