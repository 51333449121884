import React, {HTMLAttributes, MutableRefObject, useEffect, useRef, useState} from 'react';
import {JSONEditorOptions} from 'jsoneditor';
// @ts-ignore
import JSONEditorConstructor from 'jsoneditor/dist/jsoneditor-minimalist';
import 'jsoneditor/dist/jsoneditor.css';

import ace from 'brace';
import 'brace/ext/searchbox';
import 'brace/mode/json';
import {JSONEditorInstance} from './types';
import {CircularProgress} from '@material-ui/core';

type JSONEditorProps = {
  jsonEditorRef: MutableRefObject<JSONEditorInstance | undefined>;
  readOnly?: boolean;
  loading?: boolean;
  htmlElementProps?: HTMLAttributes<HTMLDivElement>;
  onInit?: () => void;
} & JSONEditorOptions;

const JSONEditor: React.FC<JSONEditorProps> = (props) => {
  const {jsonEditorRef, htmlElementProps, readOnly = false, loading = false, onInit, ...options} = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<JSONEditorInstance>();

  useEffect(() => {
    // @ts-ignore
    const jsonEditor = new JSONEditorConstructor(containerRef.current, {
      ace,
      mode: 'code',
      ...options,
    }) as JSONEditorInstance;

    setEditor(jsonEditor);

    jsonEditorRef.current = jsonEditor;
    return () => {
      jsonEditor && jsonEditor.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editor) {
      editor.aceEditor.setReadOnly(readOnly);
    }
  }, [editor, readOnly]);

  useEffect(() => {
    if (editor) {
      onInit?.();
    }
  }, [editor, onInit]);

  return (
    <div className="jsoneditor-react-wrapper relativeWrapper">
      <div className="jsoneditor-react-container" ref={containerRef} {...htmlElementProps} />
      {loading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
            }}>
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
};

export default JSONEditor;
