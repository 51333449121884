import React, {useCallback, useState} from 'react';
import DefaultPage from '../../layout/common/DefaultPage';
import TableWithSortAndPagination, {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../../core/TableWithSortAndPagination';
import {Order, useGridState} from '../../../store/grid';
import {ScenarioExecutionGroupFileDetailDTO, ScenarioExecutionOrderBy, ScenarioOrderBy} from 'hemwb-api';
import styles from './ScenarioExecutionQueue.module.scss';
import {fullName} from '../../../store/user/utils';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../router/routes';
import {scenarioExecutionSelector} from '../../../store/scenarioExecution';
import {useSelector} from '../../../store/rootReducer';
import {useExecutionStatusUpdate} from '../../../websocket/useExecutionStatusUpdate';
import {useFlashRow} from '../../core/TableWithSortAndPagination/useFlashRow';
import InstanceExecutionStatus from './InstanceExecutionStatus';

const RestrictedAccessText = () => <span className={styles.restricted}>Restricted access</span>;

const ScenarioDetailLink = (executionItem: ScenarioExecutionGroupFileDetailDTO) => {
  const {scenario, model} = executionItem.executions[0];
  return scenario && model ? (
    <div style={{marginLeft: '45px'}}>
      <Link
        to={getUrlRoute(Routes.SCENARIO_DETAIL, {
          modelUuid: model.uuid,
          scenarioId: scenario.id,
        })}>
        {scenario.name}
      </Link>
    </div>
  ) : (
    <div style={{marginLeft: '45px'}}>
      <RestrictedAccessText />
    </div>
  );
};

const ModelDetailLink = (executionItem: ScenarioExecutionGroupFileDetailDTO) => {
  const {model} = executionItem.executions[0];
  return model ? (
    <div style={{marginLeft: '12px'}}>
      <Link to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}>{model.name}</Link>
    </div>
  ) : (
    <div style={{marginLeft: '12px'}}>
      <RestrictedAccessText />
    </div>
  );
};

const ExecutedByText = (executionItem: ScenarioExecutionGroupFileDetailDTO) => {
  return executionItem.executions[0].createdBy ? (
    <div style={{marginLeft: '12px'}}>{fullName(executionItem.executions[0].createdBy)}</div>
  ) : (
    <div style={{marginLeft: '12px'}}>&nbsp;</div>
  );
};

const CreatedOnText = (executionItem: ScenarioExecutionGroupFileDetailDTO) => {
  return executionItem.executions[0].createdOn ? (
    <div style={{marginLeft: '12px'}}>
      {executionItem.executions[0].createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
    </div>
  ) : (
    <div style={{marginLeft: '12px'}}>&nbsp;</div>
  );
};

const columns: Column<ScenarioExecutionGroupFileDetailDTO>[] = [
  {
    id: ScenarioExecutionOrderBy.ScenarioName,
    label: '',
    value: (executionItem) => executionItem.executions[0].scenario?.name || '',
    display: ScenarioDetailLink,
    classes: {th: styles.colScenarioNameHeading},
  },
  {
    id: ScenarioExecutionOrderBy.Filename,
    label: '',
    value: (executionItem) => executionItem.executions[0].filename || '',
  },
  {
    id: 'modelName',
    label: '',
    value: (executionItem) => executionItem.executions[0].model?.name,
    display: ModelDetailLink,
    classes: {th: styles.colModelNameHeading},
  },
  {
    id: ScenarioExecutionOrderBy.ExecutedBy,
    label: '',
    value: (executionItem) =>
      executionItem.executions[0].createdBy ? fullName(executionItem.executions[0].createdBy) : '',
    display: ExecutedByText,
    classes: {th: styles.colUserHeading},
  },
  {
    id: ScenarioExecutionOrderBy.CreatedOn,
    label: '',
    value: (executionItem) => executionItem.executions[0].createdOn,
    display: CreatedOnText,
    classes: {th: styles.colDateHeading},
  },
  {
    id: ScenarioExecutionOrderBy.Status,
    label: '',
    value: (executionItem) => executionItem.executions[0].status,
    display: InstanceExecutionStatus,
    classes: {th: styles.colStatusHeading},
  },
];

export type ScenarioInstanceListProps = {
  scenarioExecutionInstance: ScenarioExecutionGroupFileDetailDTO[];
};

const ScenarioInstanceList: React.FC<ScenarioInstanceListProps> = ({scenarioExecutionInstance}) => {
  useExecutionStatusUpdate();
  const [selectedExecutionItem, setSelectedExecutionItem] = useState<ScenarioExecutionGroupFileDetailDTO>();

  const gridState = useGridState({orderBy: ScenarioOrderBy.CreatedOn, order: Order.DESC});

  const handleClickRow = useCallback(
    (executionItem: ScenarioExecutionGroupFileDetailDTO) => {
      if (executionItem?.executions[0].id === selectedExecutionItem?.executions[0].id) {
        setSelectedExecutionItem(undefined);
      } else {
        setSelectedExecutionItem(executionItem);
      }
    },
    [selectedExecutionItem, setSelectedExecutionItem],
  );

  const lastStatusUpdate = useSelector((state) => scenarioExecutionSelector(state).lastStatusUpdate);
  useFlashRow(lastStatusUpdate?.id);

  return (
    <DefaultPage style={{overflow: 'hidden'}}>
      <TableWithSortAndPagination
        client={false}
        hidePagination={true}
        getRowId={(executionItem) => executionItem.executions[0].id.toString()}
        rows={scenarioExecutionInstance || []}
        total={scenarioExecutionInstance?.length || 0}
        columns={columns}
        onClickRow={handleClickRow}
        isRowSelected={(executionItem) => executionItem?.executions[0].id === selectedExecutionItem?.executions[0].id}
        gridState={gridState}
        noResultsMessage="No executions were found"
      />
    </DefaultPage>
  );
};

export default ScenarioInstanceList;
