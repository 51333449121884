import React from 'react';
import {CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {DialogProps} from '@material-ui/core/Dialog';
import {tid} from '../../../testUtils';

export type DialogProgressProps = DialogProps & {title?: string; text?: string};

const DialogProgress: React.FC<DialogProgressProps> = (props) => {
  const {title, text, ...dialogProps} = props;
  return (
    <Dialog {...dialogProps}>
      {title && <DialogTitle {...tid('dialog-title')}>{title}</DialogTitle>}
      <DialogContent style={{textAlign: 'center'}}>
        <CircularProgress />
        {text && (
          <DialogContentText
            style={{overflowWrap: 'break-word'}}
            dangerouslySetInnerHTML={{__html: text}}></DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogProgress;
