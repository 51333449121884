import {ModelActions} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {
  requestModelClone,
  requestModelCloneAll,
  requestModelCreate,
  requestModelDeleteDraft,
  requestModelDetail,
  requestModelDownload,
  requestModelDownloadAll,
  requestModelList,
  requestEligibleModelList,
  requestModelListForDynamicFilers,
  requestModelNameExists,
  requestModelPublish,
  requestModelRetire,
  requestModelShareEmail,
  requestModelUpdate,
} from './api';

export const modelLoadListActionsCreator = buildAsyncActionsCreator(ModelActions.FETCH_LIST);
export const modelLoadList = createAsyncActionExecutor(modelLoadListActionsCreator, requestModelList);

export const modelLoadEligibleListActionsCreator = buildAsyncActionsCreator(ModelActions.FETCH_ELIGIBLE_LIST);
export const modelEligibleLoadList = createAsyncActionExecutor(
  modelLoadEligibleListActionsCreator,
  requestEligibleModelList,
);

export const modelLoadDynamicListActionsCreator = buildAsyncActionsCreator(ModelActions.FETCH_DYNAMIC_LIST);
export const modelLoadDynamic = createAsyncActionExecutor(
  modelLoadDynamicListActionsCreator,
  requestModelListForDynamicFilers,
);

export const modelLoadDetailActionsCreator = buildAsyncActionsCreator(ModelActions.FETCH_DETAIL);
export const modelLoadDetail = createAsyncActionExecutor(modelLoadDetailActionsCreator, requestModelDetail);

export const modelCreateActionsCreator = buildAsyncActionsCreator(ModelActions.CREATE);
export const modelCreate = createAsyncActionExecutor(modelCreateActionsCreator, requestModelCreate);

export const modelUpdateActionsCreator = buildAsyncActionsCreator(ModelActions.UPDATE);
export const modelUpdate = createAsyncActionExecutor(modelUpdateActionsCreator, requestModelUpdate);

export const modelPublishActionsCreator = buildAsyncActionsCreator(ModelActions.PUBLISH);
export const modelPublish = createAsyncActionExecutor(modelPublishActionsCreator, requestModelPublish);

export const modelRetireActionsCreator = buildAsyncActionsCreator(ModelActions.RETIRE);
export const modelRetire = createAsyncActionExecutor(modelRetireActionsCreator, requestModelRetire);

export const modelDownloadActionsCreator = buildAsyncActionsCreator(ModelActions.DOWNLOAD);
export const modelDownload = createAsyncActionExecutor(modelDownloadActionsCreator, requestModelDownload);

export const modelDownloadAllActionsCreator = buildAsyncActionsCreator(ModelActions.DOWNLOAD_ALL);
export const modelDownloadAll = createAsyncActionExecutor(modelDownloadAllActionsCreator, requestModelDownloadAll);

export const modelNameExistsActionsCreator = buildAsyncActionsCreator(ModelActions.NAME_EXISTS);
export const modelNameExists = createAsyncActionExecutor(modelNameExistsActionsCreator, requestModelNameExists);

export const modelCloneActionsCreator = buildAsyncActionsCreator(ModelActions.CLONE);
export const modelClone = createAsyncActionExecutor(modelCloneActionsCreator, requestModelClone);

export const modelCloneAllActionsCreator = buildAsyncActionsCreator(ModelActions.CLONE_ALL);
export const modelCloneAll = createAsyncActionExecutor(modelCloneAllActionsCreator, requestModelCloneAll);

export const modelShareEmailActionsCreator = buildAsyncActionsCreator(ModelActions.SHARE_EMAIL);
export const modelShareEmail = createAsyncActionExecutor(modelShareEmailActionsCreator, requestModelShareEmail);

export const modelDeleteDraftActionsCreator = buildAsyncActionsCreator(ModelActions.DELETE_DRAFT);
export const modelDeleteDraft = createAsyncActionExecutor(modelDeleteDraftActionsCreator, requestModelDeleteDraft);
