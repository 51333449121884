import {AuthAction, AuthActions} from '../auth/types';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {AppDataAction, AppDataActions} from '../appData/types';
import {AppAction, AppActions} from './types';
import {CustomSettingsAction, CustomSettingsActions} from '../customSettings/types';

export const appInitialState = {
  initializationParts: {
    currentUser: false,
    appSettings: false,
    customSettings: false,
  },
  initialized: false,
  zoom: 1,
};

export type AppState = typeof appInitialState;

export const isInitialized = (state: AppState['initializationParts']): boolean =>
  !Object.values(state).some((value) => !value);

const updateInitialization = (state: AppState, key: keyof AppState['initializationParts']): AppState => {
  const initializationParts = {
    ...state.initializationParts,
    [key]: true,
  };

  return {
    ...state,
    initializationParts,
    initialized: isInitialized(initializationParts),
  };
};

export const appReducer = (
  state = appInitialState,
  action: AuthAction | AppDataAction | AppAction | CustomSettingsAction,
): AppState => {
  switch (action.type) {
    case AuthActions.AUTH_GET_CURRENT_USER: {
      if (state.initialized || action.phase === AsyncActionPhase.START) {
        return state;
      }

      return updateInitialization(state, 'currentUser');
    }

    case AppDataActions.LOAD_SETTINGS: {
      if (state.initialized || action.phase !== AsyncActionPhase.SUCCESS) {
        return state;
      }

      return updateInitialization(state, 'appSettings');
    }

    case CustomSettingsActions.INIT: {
      return updateInitialization(state, 'customSettings');
    }

    case AppActions.SET_ZOOM: {
      return {
        ...state,
        zoom: action.zoom,
      };
    }
  }
  return state;
};
