import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {ModelFlatNode} from './types';
import {StoredModel} from '../model/types';

export const buildFlatHierarchy = (model: StoredModel, allModels: StoredModel[], output: ModelFlatNode[] = []) => {
  output.push({
    model,
    parentId: model.metadata.tree_parent_id || '',
    originalParentId: model.metadata.tree_parent_id || '',
  });

  allModels
    .filter((m) => m.metadata.tree_parent_id === model.uuid)
    .forEach((m) => buildFlatHierarchy(m, allModels, output));

  return output;
};

export const isValidLineageMetadata = (model: ModelDTO): boolean =>
  !!model.metadata.product && !!model.metadata.disease && !!model.metadata.therapeutic_area;

export const matchLineageMetadata = (m1: ModelDTO, m2: ModelDTO): boolean => {
  return (
    m1.metadata.product === m2.metadata.product &&
    m1.metadata.disease === m2.metadata.disease &&
    m1.metadata.therapeutic_area === m2.metadata.therapeutic_area
  );
};

export const calculateBranchSizeFromModelUuid = (
  flatHierarchy: ModelFlatNode[],
  uuid: string,
  doUuidSearch = true,
): number => {
  let countSelf = 0;
  if (doUuidSearch && flatHierarchy.some((n) => n.model.uuid === uuid)) {
    countSelf = 1;
  }

  if (doUuidSearch && countSelf === 0) {
    if (countSelf === 0) {
      return 0;
    }
    if (!uuid) {
      return 1;
    }
  }

  return (
    countSelf +
    flatHierarchy
      .filter((n) => n.parentId === uuid && n.model.uuid !== uuid && n.model.uuid !== '')
      .reduce((acc, val) => {
        return acc + 1 + calculateBranchSizeFromModelUuid(flatHierarchy, val.model.uuid, false);
      }, 0)
  );
};

export const replaceNodeInFlatHierarchy = (hierarchy: ModelFlatNode[], node: ModelFlatNode): ModelFlatNode[] => {
  const nodeIndexInHierarchy = hierarchy.findIndex((n) => n.model.uuid === node.model.uuid);
  if (nodeIndexInHierarchy === -1) {
    return [...hierarchy, node];
  }

  const hierarchyClone = [...hierarchy];
  hierarchyClone[nodeIndexInHierarchy] = node;
  return hierarchyClone;
};
