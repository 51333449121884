import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {
  ModelFileAction,
  ModelFileActions,
  ModelFileQueued,
  ModelFileTags,
  ModelFileUploaded,
  ModelTreeNode,
} from './types';
import {
  calculateFileName,
  nodeAddFileTag,
  nodeAddTag,
  nodeFileTagUpdateCancel,
  nodeRemoveTag,
  nodeSetTags,
  parseFileNameFromPath,
} from './utils';
import {AppDataAction, AppDataActions} from '../appData/types';
import {ModelItemActionDTO, ModelTreeTHItemAuditedDTO, UploadSettingsDTO, UserDTO} from 'hemwb-api';
import {BitbucketAction, BitbucketActions} from '../bitbucket/types';
import {ModelAction, ModelActions} from '../model/types';
import {requestModelItemHistory} from './api';
import {AuthAction, AuthActions} from '../auth/types';

type FetchedModelTree = AsyncActionState<ModelTreeNode[], [string]> & {timestamp: number};

type FetchedModelTreeTH = AsyncActionState<ModelTreeTHItemAuditedDTO, [string]> & {timestamp: number};

export const modelFileInitialState = {
  //eslint-disable-next-line
  fetched: {} as {[modelUuid: string]: FetchedModelTree},
  fetchedTH: {} as {[modelUuid: string]: FetchedModelTreeTH},
  uploader: {
    isSubmitting: false as boolean,
    isSuccess: null as null | boolean,
    modelUuid: null as null | string,
    nodes: [] as ModelTreeNode[],
    nodeProcessing: null as ModelTreeNode | null,
    fileTagProcessNodes: [] as string[],
  },

  config: {
    maxBytes: null as null | number,
    directoryMaxDepth: null as null | number,
    modelMathematicaRegExp: null as null | RegExp,
    modelExcelRegExp: null as null | RegExp,
    modelRRegExp: null as null | RegExp,
    modelPythonRegExp: null as null | RegExp,
    documentationRegExp: null as null | RegExp,
    allowedExtensionRegExp: null as null | RegExp,
    modelFileExtensions: {} as UploadSettingsDTO['modelFileExtensions'],
  },

  loggedInUserId: '' as UserDTO['id'],

  itemHistory: {} as {
    [modelUuidItemUuidKey: string]: AsyncActionState<
      ModelItemActionDTO[],
      Parameters<typeof requestModelItemHistory>
    > & {timestamp: number};
  },
};

export type ModelFileState = typeof modelFileInitialState;

const onNodeFailure = (state: ModelFileState, node: ModelTreeNode, tag: ModelFileTags): ModelFileState => {
  const errorNode = nodeAddTag(node, tag);

  return {
    ...state,
    uploader: {
      ...state.uploader,
      isSuccess: false,
      nodeProcessing: null,
      nodes: state.uploader.nodes.filter((n) => n !== node).concat([errorNode]),
    },
  };
};

export const modelFileReducer = (
  state = modelFileInitialState,
  action: ModelFileAction | AppDataAction | BitbucketAction | ModelAction | AuthAction,
): ModelFileState => {
  switch (action.type) {
    case AuthActions.AUTH_GET_CURRENT_USER: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const loggedInUserId = action.value;
        return {
          ...state,
          loggedInUserId: loggedInUserId.id,
        };
      }
      return state;
    }

    case AppDataActions.LOAD_SETTINGS: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const {upload} = action.value;
        return {
          ...state,
          config: {
            maxBytes: upload.maxBytes,
            directoryMaxDepth: upload.directoryMaxDepth,
            modelExcelRegExp: new RegExp(`\\.(${upload.modelFileExtensions.ExcelNative.join('|')})$`, 'i'),
            modelMathematicaRegExp: new RegExp(`\\.(${upload.modelFileExtensions.Mathematica.join('|')})$`, 'i'),
            modelRRegExp: new RegExp(`\\.(${upload.modelFileExtensions.R.join('|')})$`, 'i'),
            modelPythonRegExp: new RegExp(`\\.(${upload.modelFileExtensions.Python.join('|')})$`, 'i'),
            documentationRegExp: new RegExp(`\\.(${upload.documentationFileExtensions.join('|')})$`, 'i'),
            allowedExtensionRegExp: new RegExp(
              `\\.(${[
                ...upload.documentationFileExtensions,
                ...upload.modelFileExtensions.ExcelNative,
                ...upload.modelFileExtensions.Mathematica,
                ...upload.modelFileExtensions.R,
              ].join('|')})$`,
              'i',
            ),
            modelFileExtensions: upload.modelFileExtensions,
          },
        };
      }
      return state;
    }

    case ModelFileActions.REQUEST_ITEM_HISTORY: {
      const [modelUuid, itemUuid] = action.params;
      let clear = false;
      if (action.phase === AsyncActionPhase.START) {
        const histories = Object.values(state.itemHistory);
        if (histories.length > 0 && histories[0].params[0] !== modelUuid) {
          clear = true;
        }
      }

      return {
        ...state,
        itemHistory: {
          ...(clear ? modelFileInitialState.itemHistory : state.itemHistory),
          [`${modelUuid}-${itemUuid}`]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelFileActions.REQUEST_TREE: {
      const [modelUuid] = action.params;
      return {
        ...state,
        fetched: {
          ...state.fetched,
          [modelUuid]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelFileActions.REQUEST_TREE_TH: {
      const [modelUuid] = action.params;
      return {
        ...state,
        fetchedTH: {
          ...state.fetchedTH,
          [modelUuid]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelFileActions.CLEAR_UPLOADER:
      return {
        ...state,
        uploader: {
          isSubmitting: false,
          isSuccess: null,
          modelUuid: null,
          nodes: [],
          nodeProcessing: null,
          fileTagProcessNodes: [],
        },
      };

    case ModelFileActions.INIT_UPLOADER:
      return {
        ...state,
        uploader: {
          isSubmitting: false,
          isSuccess: null,
          modelUuid: action.modelUuid,
          nodes: action.nodes,
          nodeProcessing: null,
          fileTagProcessNodes: [],
        },
      };

    case ModelFileActions.SUBMIT_START:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          isSubmitting: true,
        },
      };

    case ModelFileActions.SUBMIT_ABORT: {
      const {modelUuid} = state.uploader;
      if (!modelUuid) {
        return state;
      }

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [modelUuid]: {
            ...state.fetched[modelUuid],
            ...(state.uploader.isSubmitting ? {timestamp: 0} : {}),
          },
        },
        uploader: {
          ...state.uploader,
          isSubmitting: false,
          isSuccess: false,
        },
      };
    }

    case ModelFileActions.SUBMIT_FINISH: {
      return {
        ...state,
        uploader: {
          ...state.uploader,
          fileTagProcessNodes: [],
          isSubmitting: false,
          isSuccess: state.uploader.isSuccess !== false,
        },
      };
    }

    case ModelFileActions.MERGE_UPLOADER: {
      const {modelUuid, isSuccess} = state.uploader;
      if (!modelUuid || !isSuccess) {
        return state;
      }

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [modelUuid]: {
            ...state.fetched[modelUuid],
            value: [
              ...state.uploader.nodes.filter(
                (node) => !node.folder || state.uploader.nodes.some((n) => !n.folder && n.path.startsWith(node.path)),
              ),
            ],
          },
        },
      };
    }

    case ModelFileActions.REQUEST_UPDATE: {
      const {itemUuid} = action.params[0];
      const oldNode = state.uploader.nodes.find((n) => n.uploaded && n.uuid === itemUuid) as
        | ModelFileUploaded
        | undefined;
      const newNode = state.uploader.nodes.find((n) => !n.uploaded && n.path === oldNode?.path) as
        | ModelFileQueued
        | undefined;

      if (!oldNode || !newNode) {
        return state;
      }

      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: newNode,
          },
        };
      }
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const mergedNode: ModelFileUploaded = nodeSetTags(
          {
            ...newNode,
            ...oldNode,
            tags: newNode.tags,
            new: true,
          },
          [],
          [ModelFileTags.OVERWRITE],
        );
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: null,
            nodes: state.uploader.nodes.filter((n) => n.path !== oldNode.path).concat([mergedNode]),
          },
        };
      }

      if (action.phase === AsyncActionPhase.FAILURE) {
        return onNodeFailure(state, newNode, ModelFileTags.UPLOAD_ERROR);
      }

      return state;
    }

    case ModelFileActions.REQUEST_UPLOAD: {
      const node = state.uploader.nodes.find((n) => !n.uploaded && !n.folder && n.file === action.params[0].file) as
        | ModelFileQueued
        | undefined;

      if (!node) {
        return state;
      }
      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: node,
          },
        };
      }

      if (action.phase === AsyncActionPhase.SUCCESS) {
        const tagsToAdd = [ModelFileTags.UPLOAD_SUCCESS];
        const uploadedNode: ModelFileUploaded = nodeSetTags(
          {
            ...node,
            uploaded: true,
            folder: false,
            mainFile: false,
            uuid: action.value,
            new: true,
            updatedBy: state.loggedInUserId,
            updatedOn: new Date(),
          },
          tagsToAdd,
          [ModelFileTags.OVERWRITE, ModelFileTags.UPLOAD_ERROR],
        );
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: null,
            nodes: state.uploader.nodes.filter((n) => n !== node).concat([uploadedNode]),
          },
        };
      }

      if (action.phase === AsyncActionPhase.FAILURE) {
        return onNodeFailure(state, node, ModelFileTags.UPLOAD_ERROR);
      }
      return state;
    }

    case ModelFileActions.REQUEST_DELETE: {
      const [, itemUuid] = action.params;
      const node = state.uploader.nodes.find(({uuid}) => uuid === itemUuid);
      if (!node) {
        return state;
      }

      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: node,
          },
        };
      }

      if (action.phase === AsyncActionPhase.SUCCESS) {
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodeProcessing: null,
            nodes: state.uploader.nodes.filter(({uuid}) => uuid !== itemUuid),
          },
        };
      }

      if (action.phase === AsyncActionPhase.FAILURE) {
        return onNodeFailure(state, node, ModelFileTags.DELETE_ERROR);
      }
      return state;
    }

    case ModelFileActions.ADD: {
      const {node} = action;
      if (state.uploader.modelUuid !== action.modelUuid) {
        return state;
      }
      let newNodes;
      const samePathNodes = state.uploader.nodes.filter(({path}) => path === node.path);
      if (samePathNodes.length > 0) {
        const samePathNodesUploaded = samePathNodes.filter(({uploaded}) => uploaded === true);
        const samePathNodesNotUploaded = samePathNodes.filter(({uploaded}) => uploaded === false);

        if (samePathNodesUploaded.length === 1) {
          const uploadedNode = samePathNodesUploaded[0] as ModelFileUploaded;
          newNodes = state.uploader.nodes.filter((n) => n.path !== node.path);
          newNodes.push(
            nodeAddTag(uploadedNode, ModelFileTags.OVERWRITE),
            nodeAddTag({...node, mainFile: uploadedNode.mainFile}, ModelFileTags.OVERWRITE),
          );
        } else if (samePathNodesNotUploaded.length > 0) {
          newNodes = state.uploader.nodes.filter((n) => n.path !== node.path);
          newNodes.push(node);
        } else {
          newNodes = [...state.uploader.nodes, node];
        }
      } else {
        newNodes = [...state.uploader.nodes, node];
      }

      if (newNodes) {
        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodes: newNodes,
          },
        };
      }
      return state;
    }

    case ModelFileActions.REMOVE: {
      const {node} = action;
      if (node.folder) {
        return state;
      }

      const samePathNodesUploaded = state.uploader.nodes.filter(
        ({path, uploaded}) => path === node.path && uploaded === true,
      );

      return {
        ...state,
        uploader: {
          ...state.uploader,
          nodes: state.uploader.nodes
            .filter(({path}) => path !== node.path)
            .concat(samePathNodesUploaded.map((node) => nodeRemoveTag(node, ModelFileTags.OVERWRITE))),
        },
      };
    }

    case ModelFileActions.ADD_FILE_TAG: {
      const {modelUuid, node} = action;

      return {
        ...state,
        uploader: {
          ...state.uploader,
          nodes: state.uploader.nodes.map((n) => (n === node ? nodeAddFileTag(n, modelUuid) : n)),
          fileTagProcessNodes: [...state.uploader.fileTagProcessNodes, node.uuid],
        },
      };
    }

    case ModelFileActions.UNMARK_FILE_TAG_CHANGE: {
      const {modelUuid, node} = action;

      if (state.uploader.fileTagProcessNodes.length > 0) {
        if (node.folder) {
          return state;
        }

        const index = state.uploader.fileTagProcessNodes.indexOf(node.uuid);
        if (index > -1) {
          const fetchedTree = state.fetched[modelUuid];
          const oldNode = fetchedTree.value?.filter((n) => n.uuid === node.uuid)[0];
          const oldFileTag = oldNode ? oldNode.fileTag : '';
          state.uploader.fileTagProcessNodes.splice(index, 1);
          return {
            ...state,
            uploader: {
              ...state.uploader,
              nodes: state.uploader.nodes.map((n) =>
                n.uuid === node.uuid ? nodeFileTagUpdateCancel(n, oldFileTag) : n,
              ),
              fileTagProcessNodes: [...state.uploader.fileTagProcessNodes],
            },
          };
        }
      }
      return state;
    }
    case ModelFileActions.MARK_DELETE: {
      const {node} = action;
      if (node.folder) {
        return state;
      }

      return {
        ...state,
        uploader: {
          ...state.uploader,
          nodes: state.uploader.nodes.map((n) => (n === node ? nodeAddTag(n, ModelFileTags.DELETE) : n)),
        },
      };
    }

    case ModelFileActions.UNMARK_DELETE: {
      const {node} = action;
      if (node.folder) {
        return state;
      }

      return {
        ...state,
        uploader: {
          ...state.uploader,
          nodes: state.uploader.nodes.map((n) => (n === node ? nodeRemoveTag(n, ModelFileTags.DELETE) : n)),
        },
      };
    }

    case BitbucketActions.REQUEST_SAVE_FILE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const uuid = action.value.replaceAll('"', '');
        const {path} = action.params[0];
        const nodesOnPath = state.uploader.nodes.filter((n) => n.path === path);

        if (nodesOnPath.some((n) => n.uploaded)) {
          return state;
        }

        if (nodesOnPath.length === 1) {
          return {
            ...state,
            uploader: {
              ...state.uploader,
              nodes: state.uploader.nodes.map((n) =>
                n === nodesOnPath[0] ? {...n, uploaded: true, uuid, mainFile: false} : n,
              ),
            },
          };
        }

        return {
          ...state,
          uploader: {
            ...state.uploader,
            nodes: [
              ...state.uploader.nodes,
              {
                uuid,
                uploaded: true,
                mainFile: false,
                folder: false,
                path,
                name: calculateFileName(parseFileNameFromPath(path)),
              },
            ],
          },
        };
      }

      return state;
    }

    case ModelActions.PUBLISH: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [modelUuid] = action.params;
        const newFetched = {
          ...state.fetched,
        };
        delete newFetched[modelUuid];
        return {
          ...state,
          uploader: modelFileInitialState.uploader,
          fetched: newFetched,
        };
      }

      return state;
    }
  }

  return state;
};
