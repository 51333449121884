import React, {useLayoutEffect, useRef} from 'react';
import {DiffDTOTagEnum, MacroDiffDTO} from 'hemwb-api';
import {formatLine, isRowMarkedChange} from './utils';
import styles from './SheetDiff.module.scss';
import clsx from 'clsx';
import {ChangeItem, ROWS_PER_PAGE, TabType} from './types';

type TextDiffProps = {
  diff: MacroDiffDTO;
  tab: TabType;
  markedChange?: ChangeItem;
  pageIndex: number;
};

const TextDiff: React.FC<TextDiffProps> = ({tab, diff, markedChange, pageIndex}) => {
  const markedRowRef = useRef<HTMLTableRowElement>(null);
  useLayoutEffect(() => {
    if (markedRowRef.current) {
      markedRowRef.current.focus();
    }
  }, [markedRowRef, markedChange]);

  let changeIndex = markedChange?.diff?.firstDiffIndexInPage || 0;

  const startingRowDefault = pageIndex * ROWS_PER_PAGE + 1;

  return (
    <table className={clsx(styles.container, styles.singlePane)}>
      <tbody>
        {diff.diffs.map((row, index) => {
          const rowWithChange = row.tag !== DiffDTOTagEnum.Equal;
          let rowWithMarkedChange = false;
          if (rowWithChange) {
            rowWithMarkedChange = isRowMarkedChange(changeIndex, tab, diff, markedChange);
            changeIndex++;
          }

          return (
            <tr
              key={index}
              {...(rowWithMarkedChange ? {ref: markedRowRef, tabIndex: 0} : {})}
              className={clsx({
                marked: rowWithMarkedChange,
                [styles.rowWithChange]: rowWithChange,
              })}>
              <th>{index + startingRowDefault}</th>
              <td dangerouslySetInnerHTML={{__html: formatLine(row.oldLine, 'old')}} />
              <td dangerouslySetInnerHTML={{__html: formatLine(row.newLine, 'new')}} />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TextDiff;
