import React, {useCallback, useMemo, useRef, useState} from 'react';
import {ExecutionSetupDTO, ExecutionSetupType, ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {ModelTreeNode, useModelFile, useModelFileUploader} from '../../../../../../store/modelFile';
import ModelEditFiles from './ModelEditFiles';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import SuspenseNull from '../../../../../core/Suspense/SuspenseNull';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import UploadProgressModal from './ProgressUploadModal';
import NotificationConfirmationDialog from './NotificationConfirmationDialog';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';
import {useExecutionSetupList} from '../../../../../../store/executionSetup';
import TableWithSortAndPagination from '../../../../../core/TableWithSortAndPagination';
import {Order, useGridState} from '../../../../../../store/grid';
import {subModelGridColumns} from '../../../../subModel/list/SubModelList';
import {Checkbox} from '@material-ui/core';
import {useToggleValues} from '../../../../../../hooks/useToggleValues';
import {BooleanHashMap} from '../../../../../../commonTypes';
import {scenarioOutdate} from '../../../../../../store/scenario';
import {isModelPneuInsights} from '../../../../../../store/model';
import {modelMetadataLoadOptions} from '../../../../../../store/modelMetadata';

const STEP_FILES = 1;
const STEP_SUBMODELS = 2;

const ModelEditFilesSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(STEP_FILES);
  const {files} = useModelFile(model);
  const list1 = useExecutionSetupList(model.uuid, ExecutionSetupType.CALIBRATION);
  const list2 = useExecutionSetupList(model.uuid, ExecutionSetupType.SCENARIO);
  const list = useMemo(() => {
    return list1 && list2 ? [...list1, ...list2] : null;
  }, [list1, list2]);
  const pneuInsightsModel = isModelPneuInsights(model);
  const gridState = useGridState({orderBy: 'name', order: Order.ASC});
  const {values, isSelected, toggle, replace} = useToggleValues();
  const isAllSelected = !!list && Object.keys(values).length === list.length;
  const selection = (es: ExecutionSetupDTO) => {
    return <Checkbox checked={isSelected(es.id)} size="small" onChange={() => toggle(es.id)} />;
  };
  const enhancedSubModelGridColumns: typeof subModelGridColumns = [
    {
      id: 'selected',
      // @ts-ignore
      label: (
        <Checkbox
          checked={isAllSelected}
          size="small"
          onChange={() =>
            replace(
              isAllSelected
                ? {}
                : (list || []).reduce((acc, es) => {
                    acc[es.id] = true;
                    return acc;
                  }, {} as BooleanHashMap),
            )
          }
        />
      ),
      display: selection,
    },
    ...subModelGridColumns,
  ];

  const {start, remainingOperationsCount, totalOperationsCount, unMarkFileTagUpdate} = useModelFileUploader(model);
  const updatedModelRef = useRef<ModelDTO>();
  const [openNotifyDialog, setOpenNotifyDialog] = useState<boolean>(false);

  const handleNotifyCancel = useCallback(() => {
    setOpenNotifyDialog(false);
    onSuccess?.();
  }, [onSuccess]);

  const handleSubmit = useCallback(async () => {
    try {
      await start(updatedModelRef.current);
      dispatch(messageAdd('Files have been updated', MessageTypes.SUCCESS));

      const outdatedSubModels = Object.keys(values);
      if (outdatedSubModels.length > 0) {
        scenarioOutdate(
          dispatch,
          outdatedSubModels.map((stringId) => Number(stringId)),
        );
      }
      // To Update the  store with newly created fileTags
      modelMetadataLoadOptions(dispatch);
      setOpenNotifyDialog(true);
    } catch (e) {
      dispatch(messageAdd('Failed to update Files.', MessageTypes.ERROR));
    }
  }, [dispatch, start, updatedModelRef, values]);

  const handleSubmitFiles = useCallback(
    (updatedModel: ModelDTO) => {
      updatedModelRef.current = updatedModel;
      if (!list || list.length === 0) {
        handleSubmit();
        return;
      }
      setStep(STEP_SUBMODELS);
    },
    [list, handleSubmit],
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      if (files) {
        files.forEach((node) => {
          if (!node.folder) unMarkFileTagUpdate(model.uuid, node as ModelTreeNode);
        });
      }
      onCancel();
    }
  }, [onCancel, unMarkFileTagUpdate, files, model.uuid]);

  const renderFilesFooterButtons = () => {
    return (
      <div className="buttonsContainer">
        <CancelButton onClick={handleCancel} {...tid(MODEL_ACTION_MENU_IDS.EDIT_FILES, 'cancel')} />
        <SubmitButton disabled={pneuInsightsModel} {...tid(MODEL_ACTION_MENU_IDS.EDIT_FILES, 'submit')}>
          Update files
        </SubmitButton>
      </div>
    );
  };

  return (
    <>
      <SuspenseNull>
        {step === STEP_FILES && files && (
          <ModelEditFiles
            model={model}
            files={files}
            createFlow={true}
            onSubmit={handleSubmitFiles}
            buttons={renderFilesFooterButtons}
          />
        )}
        {step === STEP_SUBMODELS && list && (
          <>
            <div className="headlineBlue">Upload model files from local</div>
            <p>
              Model files have been changed. Please, select which sub-model(s)
              <br />
              may have been affected by this change.
            </p>
            <br />
            <TableWithSortAndPagination<ExecutionSetupDTO>
              getRowId={(o) => o.id}
              rows={list || []}
              columns={enhancedSubModelGridColumns}
              // onClickRow={handleClickRow}
              // isRowSelected={(o) => o?.id === selected}
              gridState={gridState}
              loading={!list}
              noResultsMessage="No SubModel was found"
            />
            <div className="buttonsContainer" style={{marginTop: 'auto'}}>
              <br />
              <CancelButton onClick={onCancel} {...tid(MODEL_ACTION_MENU_IDS.EDIT_FILES, 'cancel')} />
              <SubmitButton onClick={handleSubmit} {...tid(MODEL_ACTION_MENU_IDS.EDIT_FILES, 'submit')}>
                Save & Proceed
              </SubmitButton>
            </div>
          </>
        )}
      </SuspenseNull>
      <UploadProgressModal total={totalOperationsCount} processed={totalOperationsCount - remainingOperationsCount} />
      <NotificationConfirmationDialog
        open={openNotifyDialog}
        title="Notify Model Users ?"
        text="You've modified files in the model entry.Do you want to notify users for this change ?"
        description="An email notification will be sent to Model Steward,Value and Implementation Lead, Model Viewers and other Model Owners who has permission for this model entry."
        label="file update"
        successMessage="Email notification of modified file sent to model users"
        uuid={model.uuid}
        type="New File"
        handleCancel={handleNotifyCancel}
      />
    </>
  );
};

export default ModelEditFilesSideBarModal;
