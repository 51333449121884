import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {Message, MessageTypes, messageAdd} from '../../../store/message';
import styles from './Snackbar.module.scss';
import clsx from 'clsx';
import {tid} from '../../../testUtils';
import {useToggleBoolean} from '../../../hooks/useToggleBoolean';
import {DialogGeneric} from '../../core/dialog/DialogGeneric';
import {WarningAlt} from '@carbon/icons-react/next';
import {Button, ButtonProps} from '@material-ui/core';
import CancelButton from '../../core/Buttons/CancelButton';
import {Form, TextFieldControl, useForm} from '../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../hooks/useMounted';
import SnackbarReportConfirmationDialog from './SnackbarReportConfirmationDialog';
import {requestIncidentCreation} from '../../../store/incident/api';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export type SnackbarProps = {
  message: Message;
  onClose: () => void;
  onHeightChange: (height: number) => void;
  style?: React.CSSProperties;
};

export const Snackbar: React.FC<SnackbarProps> = (props) => {
  const {message, onClose, style, onHeightChange} = props;
  const [reportSubmitting, setReportSubmitting] = useState(false);

  const [isOpen, toggleIsOpen] = useToggleBoolean(false);
  const [isSubmit, toggleIsSubmit] = useToggleBoolean(false);
  const dispatch = useDispatch();
  const mounted = useMounted();

  const form = useForm(
    new FormGroup({
      userNotesText: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
    }),
  );

  const handleReport = useCallback(() => {
    if (!form.valid) {
      return;
    }

    setReportSubmitting(true);
    const userNotesText = form.value.userNotesText;
    const reportId = message.reportId ? message.reportId : 0;
    const issueDetail = message.stackTrace ? message.stackTrace : '';

    requestIncidentCreation(reportId, issueDetail, userNotesText)
      .then(() => {
        toggleIsOpen();
        toggleIsSubmit();
      })
      .catch(() => {
        dispatch(messageAdd('Failed to report the issue', MessageTypes.ERROR));
      })
      .finally(() => {
        mounted.current && setReportSubmitting(false);
      });
  }, [dispatch, mounted, form, toggleIsOpen, toggleIsSubmit, message]);

  const Icon = variantIcon[message.type];
  useEffect(() => {
    if (message.duration) {
      setTimeout(() => {
        onClose();
      }, message.duration);
    }
  }, [message.duration, onClose]);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    onHeightChange(height);
  }, [height, onHeightChange]);

  const containerRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setHeight(Math.round(node.getBoundingClientRect().height) / 10);
    }
  }, []);

  return (
    <>
      <SnackbarContent
        ref={containerRef}
        aria-describedby="client-snackbar"
        className={clsx(styles[message.type], styles.container)}
        style={{...style, minHeight: height || 'auto'}}
        {...tid('message', message.type)}
        message={
          <span className={styles.message}>
            <Icon className={styles.icon} />
            <span>
              <span dangerouslySetInnerHTML={{__html: message.display}} />
              {message.reportId && (
                <span className={styles.stackTraceLink} onClick={toggleIsOpen}>
                  Report Issue <WarningAlt />
                </span>
              )}
            </span>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            {...tid('btn-dismiss-message', message.type)}>
            <CloseIcon />
          </IconButton>,
        ]}></SnackbarContent>
      {message.reportId && (
        <DialogGeneric
          title="Report the Issue"
          open={isOpen}
          hideBackdrop={false}
          onBackdropClick={toggleIsOpen}
          className={styles.stackTraceModal}>
          <Form
            group={form}
            onSubmit={handleReport}
            render={() => {
              return (
                <>
                  <b>ReportId:</b> {message.reportId}
                  <br />
                  <br />
                  <b>Error Time: </b> {new Date().toLocaleString()}
                  <br />
                  <br />
                  <div style={{width: '500px'}}>
                    <TextFieldControl
                      label={<strong>Briefly describe the error:</strong>}
                      name="userNotesText"
                      customErrorMessages={{
                        required: 'This field is required.',
                        maxLength: 'The summary is too long, maximum length is 500 characters.',
                      }}
                      TextFieldProps={{multiline: true, rows: 5}}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="buttonsContainer">
                    <br />
                    <br />
                    <CancelButton onClick={onClose} {...tid('dialog-report-issue', 'cancel')} />
                    <ReportButton active={reportSubmitting} {...tid('dialog-report-issue', 'submit')}>
                      Report Issue
                    </ReportButton>
                  </div>
                </>
              );
            }}
          />
        </DialogGeneric>
      )}
      <SnackbarReportConfirmationDialog open={isSubmit} onCancel={onClose} />
    </>
  );
};
export const defaultReportButtonProps: ButtonProps & {'data-testid': string} = {
  type: 'submit',
  variant: 'contained',
  size: 'large',
  color: 'primary',
  className: 'reportButton',
  endIcon: <WarningAlt />,
  children: 'Save',
  'data-testid': 'btn-submit',
};

type ReportButtonProps = PropsWithChildren<{active?: boolean} & ButtonProps>;
const ReportButton: React.FC<ReportButtonProps> = ({active, className, ...props}) => {
  const mergedClassName = clsx('reportButton', className);
  if (typeof active === 'boolean') {
    return (
      <Button
        {...defaultReportButtonProps}
        {...props}
        className={mergedClassName}
        disabled={props.disabled || active}
        {...active}
      />
    );
  }
  return <Button {...defaultReportButtonProps} {...props} className={mergedClassName} />;
};

export default Snackbar;
