import React, {ChangeEvent, useCallback, useEffect, useRef} from 'react';
import {InputAdornment, TextField, TextFieldProps} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {tid} from '../../../testUtils';
import {useDebounce} from '../../../hooks/useDebounce';
import {Search16} from '@carbon/icons-react';

export type SearchProps = Partial<TextFieldProps> & {
  value: string;
  onValueChange: (value: string) => any;
  debounce?: number;
  placeholder?: string;
};

export const Search: React.FC<SearchProps> = ({placeholder, value, onValueChange, debounce = 300, ...restProps}) => {
  const debouncedChange = useDebounce((newValue: string) => onValueChange(newValue), debounce);
  const textFieldRef = useRef<HTMLInputElement>();
  useEffect(() => {
    // only pass actual value changes, allow search state to be out of sync with app state during debounce
    if (textFieldRef.current) {
      textFieldRef.current.value = value;
    }
  }, [value, textFieldRef]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => debouncedChange(event.target.value), [
    debouncedChange,
  ]);

  return (
    <TextField
      inputRef={textFieldRef}
      onChange={handleChange}
      placeholder={placeholder ?? 'What are you looking for today?'}
      variant="filled"
      hiddenLabel
      margin="dense"
      fullWidth
      inputProps={{
        ...tid('input', 'fulltext-search'),
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search16 />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end" onClick={() => onValueChange('')} style={{cursor: 'pointer'}}>
            <ClearIcon {...tid('btn', 'fulltext-search', 'clear')} />
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
