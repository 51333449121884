import {ModelDTO} from 'hemwb-api';
import {ModelFilterState} from './types';

export const extractDistinctMetadata = (models: ModelDTO[] | null, field: string) =>
  Object.values(
    (models || [])
      .map((model) => (model.metadata ? model.metadata[field] : null))
      .filter((metadata): metadata is string => !!metadata)
      .reduce((map, metadata) => {
        (metadata?.split('|') || []).forEach((value) => {
          if (field === 'platform' && value === '') {
            value = 'Model Repository';
          }
          map[value] = value;
        });
        return map;
      }, ({} as any) as {[key: string]: string}),
  ).sort();

export const filterModels = (models: ModelDTO[], filterState: ModelFilterState | null): ModelDTO[] => {
  if (!filterState) {
    return models;
  }

  return models.filter((model) => {
    return (
      (filterState.modelTypes.length === 0 || filterState.modelTypes.includes(model.metadata.model_type)) &&
      (filterState.stewards.length === 0 ||
        filterState.stewards.some(
          (steward) =>
            model.metadata.name_of_model_steward === steward.name &&
            model.metadata.email_for_model_steward === steward.email,
        )) &&
      (filterState.creators.length === 0 ||
        filterState.creators.some((creator) => model.createdBy.id === creator.id)) &&
      (filterState.global.length === 0 ||
        (filterState.global.includes('global') && model.metadata.global === 'true') ||
        (filterState.global.includes('countryAdaptation') && !!model.metadata.country)) &&
      (filterState.countries.length === 0 || filterState.countries.includes(model.metadata.country)) &&
      (filterState.products.length === 0 ||
        filterState.products.some((v) => (model.metadata.product || '').includes(v))) &&
      (filterState.diseases.length === 0 || filterState.diseases.includes(model.metadata.disease)) &&
      (filterState.type.length === 0 || filterState.type.includes(model.metadata.type)) &&
      (filterState.therapeuticAreas.length === 0 ||
        filterState.therapeuticAreas.includes(model.metadata.therapeutic_area)) &&
      (filterState.platforms.length === 0 ||
        filterState.platforms.includes(
          model.metadata.platform === '' ? 'Model Repository' : model.metadata.platform,
        )) &&
      (filterState.status.length === 0 || filterState.status.includes(model.metadata.status))
    );
  });
};
