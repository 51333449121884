import React from 'react';
import SectionTitle from './SectionTitle';
import Error from './Error';
import Table, {TableExportProps} from './Table';
import Chart from './Chart';
import {VisualizationResultsElement, VisualizationResultsElements} from '../../../../../store/visualization';

type VisualizationElementProps = TableExportProps & {
  element: VisualizationResultsElement;
  id: string;
};

const VisualizationElement: React.FC<VisualizationElementProps> = ({element, ...restProps}) => {
  switch (element.element) {
    case VisualizationResultsElements.SECTION:
      return <SectionTitle {...element} />;
    case VisualizationResultsElements.ERROR:
      return <Error {...element} {...restProps} />;
    case VisualizationResultsElements.TABLE:
      return <Table {...element} {...restProps} />;
    case VisualizationResultsElements.CHART:
      return <Chart {...element} {...restProps} chart={element.options.chart} />;
  }
  return null;
};

export default React.memo(VisualizationElement);
