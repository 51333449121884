import {IncidentManagementActions} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {requestIncidentList, requestIncidentDetail, requestIncidentUpdate} from './api';

export const incidentLoadListActionsCreator = buildAsyncActionsCreator(IncidentManagementActions.FETCH_LIST);
export const incidentLoadList = createAsyncActionExecutor(incidentLoadListActionsCreator, requestIncidentList);

export const incidentLoadDetailActionsCreator = buildAsyncActionsCreator(IncidentManagementActions.FETCH_DETAIL);
export const incidentLoadDetail = createAsyncActionExecutor(incidentLoadDetailActionsCreator, requestIncidentDetail);

export const incidentLoadUpdateActionsCreator = buildAsyncActionsCreator(IncidentManagementActions.UPDATE);
export const incidentUpdate = createAsyncActionExecutor(incidentLoadUpdateActionsCreator, requestIncidentUpdate);
