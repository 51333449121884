import React, {useCallback} from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {Analytics16} from '@carbon/icons-react';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {ModelDTO} from 'hemwb-api';
import {canAccessVisualization} from '../../../../../permissions/usePermissions';
import {visualizationFreshStart} from '../../../../../store/visualization';
import {trackModelDetailTabEvent} from '../../../../../tracking/tracking';

type VisualizationCreateActionMenuLinkProps = {
  model?: ModelDTO;
};

const VisualizationCreateActionMenuLink: React.FC<VisualizationCreateActionMenuLinkProps> = ({model}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (model) {
      trackModelDetailTabEvent(model as ModelDTO);
    }
    dispatch(visualizationFreshStart());
    return model ? history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid: model.uuid})) : null;
  }, [dispatch, history, model]);

  return (
    <SideBarButtonItem
      {...tid(VISUALIZATION_ACTION_MENU_IDS.CREATE_VISUALIZATION)}
      permission={canAccessVisualization(model)}
      onClick={handleClick}
      icon={<Analytics16 />}
      text="Create Data Visualization"
    />
  );
};

export default VisualizationCreateActionMenuLink;
