import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SCENARIO_ACTION_MENU_IDS} from '../../../../../test/types';
import {useScenarioCloneSideBarModal} from './useScenarioCloneSideBarModal';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {canCloneInstance} from '../../../../../permissions/usePermissions';
import {Copy16} from '@carbon/icons-react';

const ScenarioCloneActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {ModalComponent, openModal} = useScenarioCloneSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SCENARIO_ACTION_MENU_IDS.CLONE)}
        permission={canCloneInstance(props.model, props.subModel, props.scenario)}
        onClick={openModal}
        icon={<Copy16 />}
        text="Clone"
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioCloneActionMenuButton;
