import React, {useCallback, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import ModelEditLineage from './ModelEditLineage';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import {modelLinksSetParent} from '../../../../../../store/modelLinks';
import {
  trackModelChangeLogFileSelected,
  trackModelChangeLogFileUnselected,
  trackModelLineageParentSelected,
} from '../../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useMounted} from '../../../../../../hooks/useMounted';
import {modelFileMarkChangelog, modelFileUnmarkChangelog, useModelFile} from '../../../../../../store/modelFile';
import SuspenseNull from '../../../../../core/Suspense/SuspenseNull';
import {isVersionSectionChanged} from './utils';
import {modelUpdate, isModelExternal} from '../../../../../../store/model';
import {useNameCollisionCheckDialog} from '../ModelEditSuffix/useNameCollisionCheckDialog';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';

const ModelEditLineageSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {files} = useModelFile(model);
  const {checkFutureName, DialogComponent: NameCollisionDialogComponent} = useNameCollisionCheckDialog();
  const externalModel = isModelExternal(model);
  const handleSubmit = useCallback(
    (updatedModel: ModelDTO) => {
      setSubmitting(true);

      return checkFutureName(updatedModel, model)
        .then((updatedModelWithSuffix) => {
          return modelLinksSetParent(
            dispatch,
            model.uuid,
            updatedModelWithSuffix.metadata.tree_parent_id || null,
            isVersionSectionChanged(updatedModel, model),
          )
            .then(() => {
              if (
                updatedModelWithSuffix.metadata.changelog_file === model.metadata.changelog_file ||
                (!updatedModelWithSuffix.metadata.changelog_file && !model.metadata.changelog_file)
              ) {
                return Promise.resolve();
              }
              if (updatedModelWithSuffix.metadata.changelog_file) {
                trackModelChangeLogFileSelected(updatedModelWithSuffix);
                return modelFileMarkChangelog(dispatch, model.uuid, updatedModel.metadata.changelog_file || '');
              }
              trackModelChangeLogFileUnselected(updatedModelWithSuffix);
              return modelFileUnmarkChangelog(dispatch, model.uuid, model.metadata.changelog_file || '');
            })
            .then(() => {
              if (isVersionSectionChanged(updatedModelWithSuffix, model)) {
                return modelUpdate(dispatch, model.uuid, updatedModelWithSuffix);
              }
              return Promise.resolve({});
            })

            .then(() => {
              trackModelLineageParentSelected(updatedModelWithSuffix);
              dispatch(messageAdd('Model Lineage updated', MessageTypes.SUCCESS));
              onSuccess?.();
            });
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    },
    [dispatch, mounted, onSuccess, model, checkFutureName],
  );

  const renderFooterButtons = (formObject: any) => {
    return (
      <div className="buttonsContainer">
        <CancelButton onClick={onCancel} disabled={submitting} {...tid(MODEL_ACTION_MENU_IDS.EDIT_LINEAGE, 'cancel')} />
        <SubmitButton
          active={submitting}
          disabled={formObject.disabled || externalModel}
          {...tid(MODEL_ACTION_MENU_IDS.EDIT_LINEAGE, 'submit')}>
          Update Lineage
        </SubmitButton>
      </div>
    );
  };
  return (
    <>
      {NameCollisionDialogComponent}
      <SuspenseNull>
        {files && (
          <ModelEditLineage model={model} files={files} onSubmit={handleSubmit} buttons={renderFooterButtons} />
        )}
      </SuspenseNull>
    </>
  );
};

export default ModelEditLineageSideBarModal;
