import {AuthenticationApi, UsersApi, LoginCredentialsDTO, RegistrationDetailsDTO, SecurityAPIRequest} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const authApi = new AuthenticationApi(configuration);
const userApi = new UsersApi(configuration);
export const requestLogin = (credentials: LoginCredentialsDTO) => authApi.login({loginCredentialsDTO: credentials});
export const requestRegister = (registrationDetails: RegistrationDetailsDTO) =>
  authApi.userRegistration({registrationDetailsDTO: registrationDetails});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const requestLogout = (flag: boolean | undefined) => authApi.logout();
export const requestCurrentUser = () => userApi.currentUser();
export const requestHEMWBAccess = () => authApi.sendHemwbAccessRequested();
export const requestSecurityAPI = (securityObj: SecurityAPIRequest) => authApi.securityAPI(securityObj);
