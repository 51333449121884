import React, {useCallback, useState} from 'react';
import {getUrlRoute, Routes, useMatchedRoute} from '../../../router/routes';
import {typeToSection, useExecutionSetup} from '../../../../store/executionSetup';
import {useModel} from '../../../../store/model';
import {modelInputsUpdate, useModelInput} from '../../../../store/modelInputs';
import {ModelInputType} from 'hemwb-api';
import ModelInputBuilder from './ModelInputBuilder';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import ModelInputJSONEditor from './ModelInputJSONEditor';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../store/message';
import {useMounted} from '../../../../hooks/useMounted';
import {useLastValidValue} from '../../../../hooks/useLastValidValue';
import {useHistory} from 'react-router';
import {trackSubModelInputUpdated} from '../../../../tracking/tracking';
import DefaultPage from '../../../layout/common/DefaultPage';

const ModelInputEditor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useMounted();
  const {modelUuid, subModelId} = useMatchedRoute(Routes.MODEL_INPUT_EDITOR);

  const model = useModel(modelUuid);
  const _subModel = useExecutionSetup(Number(subModelId), modelUuid);
  const subModel = useLastValidValue(_subModel);
  const _input = useModelInput(Number(subModelId));
  const input = useLastValidValue(_input);

  const [submitting, setSubmitting] = useState(false);

  const handleCancel = useCallback(() => {
    history.push(
      getUrlRoute(Routes.SUBMODEL_LIST, {
        modelUuid,
        section: typeToSection(subModel?.type),
      }),
    );
  }, [history, modelUuid, subModel]);

  const handleSubmit = useCallback(
    async (value: string) => {
      const updatedInput = {...input!, value};

      setSubmitting(true);
      try {
        await modelInputsUpdate(dispatch, subModel!.id, updatedInput.id, updatedInput);
        trackSubModelInputUpdated(model!, subModel!);
        dispatch(messageAdd('Input Definition has been updated', MessageTypes.SUCCESS));
      } catch (e) {
      } finally {
        mounted.current && setSubmitting(false);
      }
    },
    [dispatch, mounted, model, subModel, input],
  );

  if (!model || !subModel || !input) {
    return (
      <DefaultPage>
        <SuspenseNull />
      </DefaultPage>
    );
  }

  if (input?.type === ModelInputType.JSONTEMPLATE) {
    return (
      <ModelInputBuilder
        model={model}
        subModel={subModel}
        input={input}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    );
  }

  if (input?.type === ModelInputType.JSON) {
    return (
      <ModelInputJSONEditor
        model={model}
        subModel={subModel}
        input={input}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitting={submitting}
      />
    );
  }

  return null;
};

export default ModelInputEditor;
