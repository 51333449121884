import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestList, setList} from './api';

export enum ModelAccessActions {
  REQUEST_LIST = 'MODEL_ACCESS/REQUEST_LIST',
  UPDATE = 'MODEL_ACCESS/UPDATE',
}

export type ModelAccessAction =
  | AsyncAction<ModelAccessActions.REQUEST_LIST, Await<ReturnType<typeof requestList>>, Parameters<typeof requestList>>
  | AsyncAction<ModelAccessActions.UPDATE, Await<ReturnType<typeof setList>>, Parameters<typeof setList>>;
