import React from 'react';
import {ScenarioDTO, ScenarioListDTO, ScenarioStatus} from 'hemwb-api';
import {Chip} from '@material-ui/core';
import {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../../../core/TableWithSortAndPagination';
import {fullName} from '../../../../store/user/utils';
import ExecutionStatus from '../../scenarioExecutionQueue/ExecutionStatus';
import {formatScenarioExecutionStatusValue} from '../../../../store/scenarioExecution/utils';
import {GridModifier, GridState} from '../../../../store/grid';
import {ScenarioFilter} from './types';
import {Collaborate16} from '@carbon/icons-react';

const ScenarioName: React.FC<{scenario: ScenarioDTO}> = ({scenario}) => (
  <>
    {scenario.name}
    {scenario.outdated && <Chip size="small" label="Outdated" style={{marginLeft: '0.5rem', height: '1.5rem'}} />}
  </>
);

const ScenarioSharing: React.FC<{scenario: ScenarioDTO}> = ({scenario}) => (
  <>
    {scenario.shared && (
      <div>
        <Collaborate16 />
        <span>Shared</span>
      </div>
    )}
  </>
);
export const displayScenarioName = (scenario: ScenarioDTO) => <ScenarioName scenario={scenario} />;

export const displayScenarioSharing = (scenario: ScenarioDTO) => <ScenarioSharing scenario={scenario} />;

export const getScenarioListGridColumns = (): Column<ScenarioDTO>[] => [
  {
    id: 'name',
    label: 'Instance Name',
    sortable: true,
    value: (scenario) => scenario.name,
    display: displayScenarioName,
    // classes: {th: styles.colNameHeading},
  },
  {
    id: 'createdBy',
    label: 'Creator',
    sortable: true,
    value: (scenario) => (scenario?.createdBy ? fullName(scenario?.createdBy) : ''),
  },
  {
    id: 'createdOn',
    label: 'Creation Date',
    sortable: true,
    value: (scenario) => scenario.createdOn,
    display: (scenario) => scenario.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
    // classes: {th: styles.colDateHeading},
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    value: (scenario) => scenario.status,
    display: ExecutionStatus,
    // classes: {th: styles.colStatusHeading},
  },
  {
    id: 'sharing',
    label: 'Sharing',
    sortable: false,
    value: (scenario) => scenario.status,
    display: displayScenarioSharing,
    // classes: {th: styles.colStatusHeading},
  },
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getScenarioFilterStrategies = (
  listData: ScenarioListDTO | null | undefined,
  reload: () => void,
  gridState: GridState<ScenarioFilter> & GridModifier<ScenarioFilter>,
) => ({
  status: {
    options: Object.values(ScenarioStatus).sort(),
    optionLabel: (k: ScenarioStatus) => formatScenarioExecutionStatusValue(k),
    filter: gridState.filter ? gridState.filter.status : [],
    onSetFilter: (filteredOptions: ScenarioStatus[]) => {
      if (gridState.filter) {
        gridState.setFilter({...gridState.filter, status: filteredOptions});
      }
      reload();
    },
  },
  name: {
    options: listData?.list.map((item) => item.name) || [],
    optionLabel: (k: string) => k,
    filter: gridState.filter ? gridState.filter.name : [],
    onSetFilter: (filteredOptions: string[]) => {
      if (gridState.filter) {
        gridState.setFilter({...gridState.filter, name: filteredOptions});
      }
      reload();
    },
  },
  createdOn: {
    options: Array.from({length: 12}, (_, i) => (i + 1).toString()),
    optionLabel: (k: string) => monthNames[parseInt(k) - 1],
    filter: gridState.filter ? gridState.filter.createdOnMonth : [],
    onSetFilter: (filteredOptions: string[]) => {
      if (gridState.filter) {
        gridState.setFilter({...gridState.filter, createdOnMonth: filteredOptions});
      }
      reload();
    },
  },
  createdBy: {
    options: listData
      ? listData.list.map((item) => item.createdBy?.id).filter((item, pos, self) => item && self.indexOf(item) === pos)
      : [],
    optionLabel: (k: string) => k,
    filter: gridState.filter ? gridState.filter.createdBy : [],
    onSetFilter: (filteredOptions: string[]) => {
      if (gridState.filter) {
        gridState.setFilter({...gridState.filter, createdBy: filteredOptions});
      }
      reload();
    },
  },
});
