import {matchPath, useLocation, useParams, useRouteMatch} from 'react-router';

export enum SubModelSection {
  CALIBRATION = 'calibration',
  SCENARIO = 'scenario',
  DISPLAY = 'display',
}

export enum Routes {
  ROOT = '/',
  ADMIN = '/admin',

  MODEL_UPLOAD = '/model-upload',

  MODEL_LIST = '/model',
  MODEL_DETAIL = '/model/:uuid/detail',

  SUBMODEL_LIST = '/model/:modelUuid/:section/submodel-list',
  ALL_NOTIFICATIONS = '/notifications',

  VISUALIZATION_STEP_1 = '/model/:modelUuid/visualization/selection',
  VISUALIZATION_STEP_2 = '/model/:modelUuid/visualization/setting',
  VISUALIZATION_STEP_3 = '/model/:modelUuid/visualization/render/:scenarioId',
  VISUALIZATION_PARAMETERS_DISPLAY = '/model/:modelUuid/visualization/parameters-display/:scenarioId',

  MODEL_INPUT_LIST = '/model/:modelUuid/sub-model/:subModelId/input-definition-list',
  MODEL_INPUT_EDITOR = '/model/:modelUuid/sub-model/:subModelId/editor',

  SCENARIO_LIST = '/model/:modelUuid/sub-model/:subModelId/instance-list',
  SCENARIO_PARAMETERS_EDITOR = '/model/:modelUuid/instance/:scenarioId/editor',
  SCENARIO_DETAIL = '/model/:modelUuid/instance/:scenarioId/detail',

  SCENARIO_EXECUTION_QUEUE = '/scenario-execution-queue',

  EXTERNALIZATION = '/externalization',
  EXTERNALIZATION_LIST = '/externalization/list',
  EXTERNALIZATION_ENROLL = '/externalization/user/enroll',

  USER_LIST = '/admin/user',
  USER_ENROLL = '/admin/user/enroll',
  USER_DETAIL = '/admin/user/:id/detail',
  USER_EDIT = '/admin/user/:id/edit',

  MANAGE_METADATA_PROPERTY = '/admin/manage-metadata/:property',
  MANAGE_METADATA = '/admin/manage-metadata',

  INCIDENT_MANAGEMENT = '/admin/incident-management',

  ADMIN_EXTERNALIZATION_LIST = '/admin/externalization-user',

  REGISTRATION_REVIEW = '/admin/registration-review',

  USER_REGISTRATION = '/registration',

  LANDING_PAGE = '/landingPage',

  NOT_IMPLEMENTED = '/not-implemented',
}

type RouteWithParams =
  | {route: Routes.ROOT}
  | {route: Routes.ADMIN}
  | {route: Routes.MODEL_LIST}
  | {route: Routes.INCIDENT_MANAGEMENT}
  | {route: Routes.ALL_NOTIFICATIONS}
  | {
      route: Routes.MODEL_UPLOAD;
      uuid?: string;
    }
  | {
      route: Routes.MODEL_DETAIL;
      uuid: string;
    }
  | {
      route: Routes.SUBMODEL_LIST;
      modelUuid: string;
      section: SubModelSection;
    }
  | {
      route: Routes.VISUALIZATION_STEP_1;
      modelUuid: string;
      scenarioId?: number;
    }
  | {
      route: Routes.VISUALIZATION_STEP_2;
      modelUuid: string;
      scenarioId?: number;
    }
  | {
      route: Routes.VISUALIZATION_STEP_3;
      modelUuid: string;
      scenarioId: number;
    }
  | {
      route: Routes.VISUALIZATION_PARAMETERS_DISPLAY;
      modelUuid: string;
      scenarioId: number;
    }
  | {
      route: Routes.MODEL_INPUT_LIST;
      modelUuid: string;
      subModelId: number;
    }
  | {
      route: Routes.MODEL_INPUT_EDITOR;
      modelUuid: string;
      subModelId: number;
    }
  | {
      route: Routes.SCENARIO_LIST;
      modelUuid: string;
      subModelId: number;
    }
  | {
      route: Routes.SCENARIO_PARAMETERS_EDITOR;
      modelUuid: string;
      scenarioId: number;
    }
  | {
      route: Routes.SCENARIO_DETAIL;
      modelUuid: string;
      scenarioId: number;
    }
  | {
      route: Routes.SCENARIO_EXECUTION_QUEUE;
    }
  | {route: Routes.EXTERNALIZATION_LIST}
  | {route: Routes.EXTERNALIZATION_ENROLL}
  | {route: Routes.USER_LIST}
  | {route: Routes.USER_ENROLL}
  | {
      route: Routes.USER_DETAIL;
      id: string;
    }
  | {
      route: Routes.USER_EDIT;
      id: string;
    }
  | {route: Routes.MANAGE_METADATA}
  | {route: Routes.MANAGE_METADATA_PROPERTY; property: string}
  | {route: Routes.USER_REGISTRATION}
  | {route: Routes.LANDING_PAGE}
  | {route: Routes.NOT_IMPLEMENTED};

type Route = RouteWithParams['route'];
type ExcludeRouteField<A> = Pick<A, Exclude<keyof A, 'route'>>;
type ExtractRouteParameters<A, T> = ExcludeRouteField<Extract<A, {route: T}>>;
type ExtractSimpleRoute<A> = A extends any ? ({} extends ExcludeRouteField<A> ? A : never) : never;
type SimpleRouteType = ExtractSimpleRoute<RouteWithParams>['route'];

export type RouteParams<R> = ExtractRouteParameters<RouteWithParams, R>;

export const getAbsoluteUrl = (clientPath: string) => {
  const {origin, pathname} = window.location;
  return origin + pathname + '#' + clientPath;
};

export const getOrigin = () => {
  const {origin} = window.location;
  return origin;
};

export function getUrlRoute(route: SimpleRouteType): string;

export function getUrlRoute<R extends Route>(route: R, params: RouteParams<R>): string;

export function getUrlRoute(url: any, params?: any): string {
  if (!params) {
    return url;
  }

  const [pathParams, queryParams] = Object.keys(params).reduce(
    (acc, key) => {
      const targetParams = url.includes(`:${key}`) ? acc[0] : acc[1];
      targetParams[key] = params[key];
      return acc;
    },
    [{}, {}] as [{[key: string]: any}, {[key: string]: any}],
  );

  const queryString = new URLSearchParams(queryParams).toString();

  return (
    Object.keys(pathParams).reduce((acc, key) => {
      return acc.replace(`:${key}`, params[key]);
    }, url) + (queryString && `?${queryString}`)
  );
}

export const useQuery = <T extends Route>(): Partial<RouteParams<T>> => {
  const params = new URLSearchParams(useLocation().search);
  return (Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, ({} as any) as {[key: string]: string}) as any) as Partial<RouteParams<T>>;
};

export const useRoute = <T extends Route>(): RouteParams<T> => {
  return ({
    ...useParams<any>(),
    ...useQuery<T>(),
  } as any) as RouteParams<T>;
};

// When rendered in an already matched <Route> context, this yields same value
// as useRoute(). When used outside of <Route> context, this first matches the passed route
// and only then uses its params and any query params of location
export const useMatchedRoute = <T extends Route>(route: T): RouteParams<T> => {
  const match = useRouteMatch<any>(route);
  if (!match) {
    throw new Error(`route not matched: ${route}`);
  }
  return {
    ...match.params,
    ...useQuery<T>(),
  };
};

const reversedRoutes = Object.values(Routes).reverse();
export const getRouteByPathname = (pathname: string): Routes => {
  return reversedRoutes.find((route) => matchPath(pathname, route)) || Routes.ROOT;
};
