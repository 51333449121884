import {ScenarioApi, ScenarioGroupOrderBy, ScenarioGroupUpdateDTO} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const api = new ScenarioApi(configuration);

export const requestScenarioGroupList = (modelUuid: string) =>
  api.findScenarioGroups({
    modelUuid,
    orderBy: ScenarioGroupOrderBy.Name,
    orderDesc: false,
    pageIndex: 0,
    pageSize: 10000,
  });

export const requestScenarioGroupDetail = (scenarioGroupId: number) => api.getScenarioGroupDetail({scenarioGroupId});

export const requestScenarioGroupCreate = (
  modelUuid: string,
  executionSetupId: number,
  scenarioGroupUpdateDTO: ScenarioGroupUpdateDTO,
) => api.createScenarioGroup({executionSetupId, scenarioGroupUpdateDTO});

export const requestScenarioGroupUpdate = (scenarioGroupId: number, scenarioGroupUpdateDTO: ScenarioGroupUpdateDTO) =>
  api.updateScenarioGroup({scenarioGroupId, scenarioGroupUpdateDTO});

export const requestScenarioGroupDelete = (scenarioGroupId: number) => api.deleteScenarioGroup({scenarioGroupId});
