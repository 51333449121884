import React, {useRef} from 'react';
import {CommonInputAttributes, controlType, controlTypeAllowed, InputType, expertiseLevel} from '../types';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {
  Collapse,
  FormControl as FormControlMaterialUi,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import {DropDownControl, SingleCheckboxControl, TextFieldControl} from '../../../Form';
import GridItem from '../../../Form/GridItem';
import {
  getControlledByDescription,
  getExpertiseLevelLabel,
  getModelInputTypeLabel,
  getOptionKeys,
  getOptionLabel,
} from '../utils';
import {useToggleBoolean} from '../../../../../hooks/useToggleBoolean';
import {DialogGeneric} from '../../../dialog/DialogGeneric';
import {defaultCancelButtonProps} from '../../../Buttons/CancelButton';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {useToggleSelected} from '../../../../../hooks/useToggleSelected';

type ControlsMap = {[key in keyof Required<CommonInputAttributes & {type: InputType}>]: AbstractControl};

type SharedInputParametersProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

const SharedInputParameters: React.FC<SharedInputParametersProps> = ({formGroup}) => {
  const {controls} = formGroup;
  const {type, id, controlledBy} = formGroup.value;

  const controlledByInputRef = useRef<HTMLInputElement>();
  const [open, toggleOpen] = useToggleBoolean(false);

  const controlledByAvailableControls = (formGroup.parent as FormArray).controls.filter(
    (control) => !!control.value.controlType && control.value.id !== id,
  );
  const openedControlledByInputs = useToggleSelected(controlledBy ? [controlledBy.id] : []);
  return (
    <>
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <TextFieldControl label="Title" control={controls.title} name="title" />
        </GridItem>
        <GridItem>
          <TextFieldControl label="Variable Name" control={controls.name} name="name" />
        </GridItem>

        <GridItem>
          <TextFieldControl
            label="Question"
            control={controls.question}
            name="question"
            TextFieldProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </GridItem>

        <GridItem>
          <div style={{height: '1rem'}} />
          <SingleCheckboxControl name="visible" label="Visible" control={controls.visible} />
          <br />
          <SingleCheckboxControl name="required" label="Required" control={controls.required} />
          <br />
          <SingleCheckboxControl name="viewable" label="Viewable" control={controls.viewable} disabled />
        </GridItem>

        <GridItem>
          <DropDownControl
            label="Type"
            control={controls.type}
            name="type"
            options={Object.values(InputType)}
            getOptionLabel={getModelInputTypeLabel}
          />
        </GridItem>

        <GridItem>
          <DropDownControl
            label="Expertise Level"
            control={formGroup.get('expertiseLevel')}
            name="expertiseLevel"
            options={Object.values(expertiseLevel)}
            getOptionLabel={getExpertiseLevelLabel}
          />
        </GridItem>

        <GridItem>
          <br />
          <strong>Definition</strong>
        </GridItem>
        <GridItem />

        <GridItem>
          <DropDownControl
            label="Control Type"
            disabled={!(controlTypeAllowed as any)[type]}
            control={formGroup.get('controlType')}
            name="controlType"
            options={[null, ...Object.keys(controlType)]}
            getOptionLabel={(key: keyof typeof controlType) => controlType[key] || 'None'}
          />
        </GridItem>
        <GridItem>
          <div
            onClick={() => {
              if (controlledByAvailableControls.length > 0) {
                controlledByInputRef.current?.blur();
                toggleOpen();
              }
            }}>
            <FormControlMaterialUi fullWidth hiddenLabel={true} className="MuiFormControl-hem">
              <InputLabel shrink>Controlled by</InputLabel>
              <TextField
                label=""
                hiddenLabel={true}
                placeholder="No Control"
                value={getControlledByDescription(formGroup, controlledBy) || ''}
                variant="filled"
                margin="dense"
                disabled={controlledByAvailableControls.length === 0}
              />
            </FormControlMaterialUi>
          </div>

          <DialogGeneric
            childrenAsText={false}
            open={open}
            title="Controlled by"
            buttons={[
              {
                ...defaultCancelButtonProps,
                onClick: toggleOpen,
              },
            ]}>
            <List dense>
              <ListItem
                button
                selected={!controlledBy}
                onClick={() => {
                  formGroup.get('controlledBy').setValue(undefined);
                  toggleOpen();
                }}>
                <ListItemText primary="No Control" />
              </ListItem>
              {controlledByAvailableControls.map((control) => {
                const opened = openedControlledByInputs.isSelected(control.value.id);
                const options: any[] = getOptionKeys(control as FormGroup) || [];
                return (
                  <React.Fragment key={control.value.title}>
                    <ListItem button onClick={() => openedControlledByInputs.toggleSelected(control.value.id)}>
                      <ListItemText primary={control.value.title}></ListItemText>
                      {opened ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={opened} timeout="auto">
                      <List component="div" dense>
                        {options
                          .filter((option) => option !== null)
                          .map((option) => {
                            return (
                              <ListItem
                                button
                                selected={controlledBy?.id === control.value.id && controlledBy?.value === option}
                                key={String(option)}
                                onClick={() => {
                                  formGroup.get('controlledBy').setValue({id: control.value.id, value: option});
                                  toggleOpen();
                                }}>
                                <ListItemText
                                  primary={getOptionLabel(control as FormGroup, control.value.id, option)}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </List>
          </DialogGeneric>
        </GridItem>
      </Grid>
    </>
  );
};

export default SharedInputParameters;

export const sharedInputParametersControlBuilder = (
  initialValues: Partial<CommonInputAttributes & {type: InputType}> = {},
) => {
  return {
    id: new FormControl(initialValues.id, [Validators.required]),
    categoryId: new FormControl(initialValues.categoryId || '3', [Validators.required]),
    type: new FormControl(initialValues.type, [Validators.required]),
    name: new FormControl(initialValues.name, [Validators.required]),
    title: new FormControl(initialValues.title, [Validators.required]),
    question: new FormControl(initialValues.question, [Validators.required]),
    visible: new FormControl(initialValues.visible ?? true),
    required: new FormControl(initialValues.required ?? false),
    viewable: new FormControl(initialValues.viewable ?? true),
    controlType: new FormControl(initialValues.controlType ?? null),
    controlledBy: new FormControl(initialValues.controlledBy ?? null),
    expertiseLevel: new FormControl(initialValues.expertiseLevel ?? expertiseLevel['500']),
  } as ControlsMap;
};
