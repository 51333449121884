import React from 'react';
import {ListItem as MuiListItem} from '@material-ui/core';
import clsx from 'clsx';
import styles from './ListItem.module.scss';
import {PERMISSION, Permission} from '../../../../permissions/types';

export type ListItemCommonProps = {
  selectedLeftIndicator?: boolean;
  selectedIconIndicator?: boolean;
  selectedIndicatorColor?: string;
  permission?: Permission;
  component?: any;
  to?: string;
};

export type ListItemProps = ListItemCommonProps & React.ComponentProps<typeof MuiListItem>;

const ListItem = React.forwardRef<any, ListItemProps>((props, ref) => {
  const {
    disabled,
    permission,
    selectedLeftIndicator,
    selectedIconIndicator,
    selectedIndicatorColor,
    className,
    style,
    ...restProps
  } = props;

  if (permission === PERMISSION.FORBIDDEN) {
    return null;
  }

  return (
    <MuiListItem
      ref={ref}
      disabled={disabled || permission === PERMISSION.DISALLOWED}
      className={clsx(
        {
          [styles.selectedLeftIndicator]: selectedLeftIndicator,
          [styles.selectedIconIndicator]: selectedIconIndicator,
        },
        className,
      )}
      style={restProps.selected && selectedIndicatorColor ? {borderColor: selectedIndicatorColor, ...style} : style}
      {...restProps}
    />
  );
});

export default ListItem;

ListItem.displayName = 'ListItem';
