import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import VisualizationShareLink, {VisualizationShareLinkProps} from './VisualizationShareLink';

export const useVisualizationShareLinkSideBarModal = (props: Partial<VisualizationShareLinkProps>) => {
  const {model, subModel, scenario, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model, scenario);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      subModel &&
      scenario && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '80rem'}}>
            {!!buttonRef && (
              <VisualizationShareLink
                model={model}
                subModel={subModel}
                scenario={scenario}
                onCancel={closeModal}
                onSuccess={handleSuccess}
              />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, model, subModel, scenario, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};
