import React from 'react';
import {DialogGeneric} from './../core/dialog/DialogGeneric';
import {Checkbox, Button} from '@material-ui/core';
import {getOrigin} from '../router/routes';
import {useSettings} from '../../store/appData';

export type AgreementDialogProps = {
  title: string;
  open: boolean;
  handleCancel: () => void;
  LoginHomePage?: boolean;
};

const AgreementDialog: React.FC<AgreementDialogProps> = ({open, title, handleCancel, LoginHomePage}) => {
  const settings = useSettings();
  const handleClick = () => {
    if (settings && settings.features) {
      handleCancel();
      const authorizeURL = settings.features.accessAuthorizeEndPoint;
      const clientId = settings.features.clientId;
      window.location.replace(
        authorizeURL +
          '?response_type=code&client_id=' +
          clientId +
          '&scope=openid%20MLMP&redirect_uri=' +
          getOrigin() +
          '/api/v2/auth/login',
      );
    }
  };

  return (
    <DialogGeneric open={open} title={title} childrenAsText={false}>
      <>
        <div style={{maxWidth: '40rem', marginBottom: '3rem'}}>
          Model Repository is intended to help authorized parties external to Merck and MSD find, access, document, and
          analyze health economic models and their supporting files. This site is a scientific resource made available
          by the Biostatistical and Research Decision Sciences organization, a part of Merck and MSD&apos;s Research
          Laboratories.
          <br />
          Files contain certain proprietary or confidential methods, formulas, processes, techniques, and background
          technologies (collectively, “Material”) developed by Merck and MSD. Access, disclosure, download, use, and
          modifications of these materials must be in accordance with Merck and MSD&apos;s agreements with the external
          party.
        </div>
        <div style={{textAlign: 'center'}}>
          {LoginHomePage ? (
            <Button variant="contained" onClick={handleClick} style={{backgroundColor: '#00857c'}}>
              <strong style={{color: 'white'}}>I have read the agreement</strong>
            </Button>
          ) : (
            <div>
              <Checkbox color="primary" size="small" onChange={handleCancel} />
              <span style={{color: 'blue', textDecoration: 'underline'}}>Read the agreement</span>
            </div>
          )}
        </div>
      </>
    </DialogGeneric>
  );
};

export default AgreementDialog;
