import {Dispatch} from 'redux';
import {messageNetworkError} from './message/actions';
import {getUrlRoute, Routes} from '../components/router/routes';
import {History} from 'history';

import {
  AsyncAction,
  AsyncActionPhase,
  AsyncActionState,
  AsyncActionStateReduced,
  AsyncClearAction,
  AsyncFailureAction,
  AsyncStartAction,
  AsyncSuccessAction,
} from './asyncUtilsTypes';
import {AppAction} from './app/types';
import {AppDataAction} from './appData/types';
import {AuthAction} from './auth/types';
import {AuthExpirationAction} from './authExpiration/types';
import {CustomSettingsAction} from './customSettings/types';
import {GridAction} from './grid/types';
import {MessageAction} from './message/types';
import {ModelAction} from './model/types';
import {ModelAccessAction} from './modelAccess/types';
import {ModelFileAction} from './modelFile/types';
import {ModelLinksAction} from './modelLinks/types';
import {ModelMetadataAction} from './modelMetadata/types';
import {ModelViewersAction} from './modelViewers/types';
import {ModelDisplayViewersAction} from './modelDisplayViewers/types';
import {ScenarioAction} from './scenario/types';
import {ScenarioExecutionAction} from './scenarioExecution/types';
import {UserAction} from './user/types';
import {BitbucketAction} from './bitbucket/types';
import {VersionControlAction} from './versionControl/types';
import {ExcelDiffAction} from './excelDiff/types';
import {VeevaAction} from './veeva/types';
import {ModelStewardAction} from './modelSteward/types';
import {ExecutionSetupAction} from './executionSetup/types';
import {ModelInputAction} from './modelInputs/types';
import {VisualizationAction} from './visualization/types';
import {AppComponentsAction} from './appCompoments/types';
import {ScenarioGroupAction} from './scenarioGroup/types';
import {userExpertiseAction} from './userExpertise/types';
import {IncidentManagementAction} from './incident/types';
import {notificationAction} from './notifications/types';
import {GuidingTourAction} from './guidingTour/types';

export type AllAction =
  | AppAction
  | AppComponentsAction
  | AppDataAction
  | AuthAction
  | AuthExpirationAction
  | BitbucketAction
  | CustomSettingsAction
  | GridAction
  | MessageAction
  | IncidentManagementAction
  | ModelAction
  | ModelAccessAction
  | ModelFileAction
  | ModelInputAction
  | ModelLinksAction
  | ModelMetadataAction
  | ModelViewersAction
  | ModelDisplayViewersAction
  | ScenarioAction
  | ScenarioExecutionAction
  | ScenarioGroupAction
  | UserAction
  | VersionControlAction
  | VisualizationAction
  | ExcelDiffAction
  | ExecutionSetupAction
  | VeevaAction
  | userExpertiseAction
  | ModelStewardAction
  | notificationAction
  | GuidingTourAction;

export type AsyncActionValue<T extends AllAction['type']> = Extract<
  AllAction,
  {type: T; phase: AsyncActionPhase.SUCCESS}
>['value'];
export type AsyncActionParams<T extends AllAction['type']> = Extract<
  AllAction,
  {type: T; phase: AsyncActionPhase.SUCCESS}
>['params'];

export const buildInitialAsyncState = <V>(): AsyncActionState<V, any> => ({
  value: null,
  error: null,
  state: null,
  params: null,
});

export const buildInitialAsyncStateReduced = (): AsyncActionStateReduced<any> => ({
  error: null,
  state: null,
  params: null,
  value: false,
});

export const reduceAsyncAction = <V>(action: AsyncAction<any, V, any>): AsyncActionState<V, any> => {
  switch (action.phase) {
    case AsyncActionPhase.START:
      return {
        state: action.phase,
        value: null,
        error: null,
        params: action.params,
      };
    case AsyncActionPhase.SUCCESS:
      return {
        state: action.phase,
        value: action.value,
        error: null,
        params: action.params,
      };
    case AsyncActionPhase.FAILURE:
      return {
        state: action.phase,
        value: null,
        error: action.error,
        params: action.params,
      };
    case undefined:
      return buildInitialAsyncState<V>();
  }
};

export const reduceAsyncActionReduced = (
  action: AsyncAction<any, any, any>,
  addTimestamp = false,
): AsyncActionStateReduced<any> => {
  switch (action.phase) {
    case AsyncActionPhase.START:
      return {
        value: false,
        state: action.phase,
        error: null,
        params: action.params,
        ...(addTimestamp && {timestamp: Date.now()}),
      };
    case AsyncActionPhase.SUCCESS:
      return {
        value: true,
        state: action.phase,
        error: null,
        params: action.params,
        ...(addTimestamp && {timestamp: Date.now()}),
      };
    case AsyncActionPhase.FAILURE:
      return {
        value: false,
        state: action.phase,
        error: action.error,
        params: action.params,
        ...(addTimestamp && {timestamp: Date.now()}),
      };
    case undefined:
      return buildInitialAsyncStateReduced();
  }
};

export type AsyncActionCreator<T extends string, V, P> = {
  start: (params?: P) => AsyncStartAction<T, P>;
  success: (value: V, params?: P) => AsyncSuccessAction<T, V, P>;
  failure: (error: Response, params?: P) => AsyncFailureAction<T, P>;
  clear: (params?: P) => AsyncClearAction<T, P>;
};

export const isAuthErrorResponse = (response: Response): boolean => response.status === 401 || response.status === 403;

// export const buildAsyncActionsCreator = <T extends AllAction['type']>(type: T): AsyncActionCreator<T, any, any> => ({
export const buildAsyncActionsCreator = <T extends AllAction['type']>(
  type: T,
): AsyncActionCreator<T, AsyncActionValue<T>, AsyncActionParams<T>> => ({
  // @ts-ignore
  start: (params) => ({
    type,
    phase: AsyncActionPhase.START,
    params,
  }),
  // @ts-ignore
  success: (value, params) => ({
    type,
    phase: AsyncActionPhase.SUCCESS,
    value,
    params,
  }),
  // @ts-ignore
  failure: (error, params) => ({
    type,
    phase: AsyncActionPhase.FAILURE,
    error,
    params,
  }),
  // @ts-ignore
  clear: (params) => ({
    type,
    clear: true,
    params,
  }),
});

let logoutFunction: (dispatch: Dispatch) => any;
let history: History;

export const setLogoutFunction = (logoutFn: any) => {
  logoutFunction = logoutFn;
};

export const setHistory = (h: History) => {
  history = h;
};

export const createAsyncActionExecutor = <T extends string, V, P extends any[] = []>(
  asyncActionCreator: AsyncActionCreator<T, V, P>,
  startFunction: (...params: P) => Promise<V>,
  options?: {
    successFunction?: (dispatch: Dispatch, value: V, params: P) => void;
    failureFunction?: (dispatch: Dispatch, error: Response, params: P, originalFailureFunction: () => void) => void;
    handleAuthErrorEnabled?: boolean;
    showError?: boolean;
  },
) => {
  const executor = (dispatch: Dispatch, ...params: P) => {
    dispatch(asyncActionCreator.start(params));
    const {successFunction = false, failureFunction = false, handleAuthErrorEnabled = true, showError = true} =
      options || {};
    return startFunction(...params)
      .then((value: V) => {
        successFunction
          ? successFunction(dispatch, value, params)
          : dispatch(asyncActionCreator.success(value, params));
        return value;
      })
      .catch((e) => {
        if (e instanceof Response) {
          if (handleAuthErrorEnabled && isAuthErrorResponse(e)) {
            if (e.status === 403) {
              history && history.push(getUrlRoute(Routes.MODEL_LIST));
            } else {
              logoutFunction && logoutFunction(dispatch);
            }
          }
          if (showError) {
            e.json()
              .then((json) => dispatch(messageNetworkError(e, json)))
              .catch(() => dispatch(messageNetworkError(e)));
          }
        }

        failureFunction
          ? failureFunction(dispatch, e, params, () => dispatch(asyncActionCreator.failure(e, params)))
          : dispatch(asyncActionCreator.failure(e, params));
        throw e;
      }) as Promise<V>;
  };
  executor.clear = (dispatch: Dispatch, ...params: P) => {
    dispatch(asyncActionCreator.clear(params));
  };
  return executor;
};
