import React from 'react';
import {useExcelExport} from './useExcelExport';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {Upload16} from '@carbon/icons-react';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';

type VisualizationResultsExportExcelActionMenuButtonProps = {
  model?: ModelDTO;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDTO;
  scenarioInProgress: boolean;
};

const VisualizationResultsExportExcelActionMenuButton: React.FC<VisualizationResultsExportExcelActionMenuButtonProps> = ({
  model,
  subModel,
  scenario,
  scenarioInProgress,
}) => {
  const {exportExcel, exportExcelInProgress, ExportExcelComponent} = useExcelExport(model, subModel, scenario, true);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.EXPORT_RESULTS_XLSX)}
        disabled={!scenario || scenarioInProgress}
        onClick={() => exportExcel()}
        active={exportExcelInProgress}
        icon={<Upload16 />}
        text="Export Excel"
      />
      {ExportExcelComponent}
    </>
  );
};

export default VisualizationResultsExportExcelActionMenuButton;
