import {modelMetadataLoadOptions} from './actions';
import {useSimpleReduxLoader} from '../useReduxLoader';
import {modelMetadataOptionsSelector} from './selectors';
import {useMemo} from 'react';
import {ModelMetadataOptionsDTOOptions, MetadataCountDTO} from 'hemwb-api';
import {useModelList} from '../model';
import {isValidProperty} from './utils';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {modelMetadataOptionProperties, ModelMetadataOptionSimplifyDTO} from './types';

export const useModelMetadataOptions = () => {
  const fetchedOptions = useSimpleReduxLoader(modelMetadataOptionsSelector, modelMetadataLoadOptions);
  return fetchedOptions ? fetchedOptions.options : null;
};

export const useModelMetadataActiveOptions = () => {
  const options = useModelMetadataOptions();
  return useMemo(() => {
    if (options) {
      return (Object.keys(options) as Array<keyof ModelMetadataOptionSimplifyDTO>).reduce((acc, val) => {
        acc[val] = options[val].filter((o) => !o.retired).map((o) => o.value);
        return acc;
      }, ({} as any) as ModelMetadataOptionSimplifyDTO);
    }
    return options;
  }, [options]);
};

export const useModelMetadataAssociations = () => {
  const fetchedOptions = useSimpleReduxLoader(modelMetadataOptionsSelector, modelMetadataLoadOptions);
  return fetchedOptions ? fetchedOptions.associations : null;
};

export const useModelMetadataOptionsLowerCase = () => {
  const options = useModelMetadataOptions();
  return useMemo(() => {
    if (options) {
      return (Object.keys(options) as (keyof ModelMetadataOptionsDTOOptions)[]).reduce((acc, property) => {
        acc[property] = options[property].map((option) => ({...option, value: option.value.toLocaleLowerCase()}));
        return acc;
      }, ({} as any) as ModelMetadataOptionsDTOOptions);
    }
    return options;
  }, [options]);
};

export const useModelMetadataOptionsUsage = (property?: string) => {
  const options = useModelMetadataOptions();
  const list = useModelList();

  return useMemo(() => {
    if (options && list && property && isValidProperty(property, options)) {
      const output = options[property].reduce((acc, option) => {
        acc[option.value] = [];
        return acc;
      }, ({} as any) as {[key: string]: ModelDTO[]});

      list.forEach((model) => {
        const value = model.metadata[modelMetadataOptionProperties[property]];
        if (value && output[value]) {
          output[value].push(model);
        }
      });

      return output;
    }
    return null;
  }, [options, list, property]);
};

export const useFileTagUsage = (fileTagCount?: MetadataCountDTO[]) => {
  const list = useModelList();

  return useMemo(() => {
    if (fileTagCount && list) {
      const output = ({} as any) as {[key: string]: ModelDTO[]};

      fileTagCount?.forEach((option) => {
        output[option.value] = [];

        list.forEach((model) => {
          const value = model.uuid;
          if (value && option.modelUuids.includes(value)) {
            output[option.value].push(model);
          }
        });
      });
      return output;
    }
    return null;
  }, [list, fileTagCount]);
};
