import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Snackbar from './Snackbar';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import styles from './SnackbarContainer.module.scss';
import {messagesSelector, Message, messageRemove, messageUpdate} from '../../../store/message';

const SnackbarContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const messages = useSelector(messagesSelector);

  const handleClose = useCallback(
    (message: Message) => () => {
      dispatch(messageRemove(message));
    },
    [dispatch],
  );

  const handleHeightChange = useCallback(
    (message: Message) => (height: number) => {
      if (message.height !== height) {
        dispatch(messageUpdate({...message, height}));
      }
    },
    [dispatch],
  );

  const positions = messages?.reduce(
    (acc, val) => {
      acc.push(acc[acc.length - 1] + (val.height || 8) + 1);
      return acc;
    },
    [0],
  ) || [0];

  return (
    <div>
      <TransitionGroup className={styles.container}>
        {messages.map((message, index) => (
          <CSSTransition timeout={300} classNames="snackbar-list" key={message.key}>
            <div>
              <Snackbar
                message={message}
                onClose={handleClose(message)}
                onHeightChange={handleHeightChange(message)}
                style={{transform: `translate(0rem, ${positions[index]}rem)`}}
              />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

export default SnackbarContainer;
