import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {ModelFileActions, ModelFileNodeAction, ModelTreeNode} from './types';
import {
  requestDeleteFile,
  requestFileTree,
  requestFileTreeTH,
  requestModelItemDownload,
  requestModelHelpFileDownload,
  requestModelItemHistory,
  requestModelMarkChangeLog,
  requestModelUnmarkChangeLog,
  requestUpdateFile,
  requestUpdateFileTag,
  requestUploadFile,
} from './api';
import {messageAdd, MessageTypes} from '../message';

export const modelFileSubmitStart = () => ({type: ModelFileActions.SUBMIT_START});
export const modelFileSubmitFinish = () => ({type: ModelFileActions.SUBMIT_FINISH});
export const modelFileSubmitAbort = () => ({type: ModelFileActions.SUBMIT_ABORT});

export const modelFileLoadTreeActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_TREE);
export const modelFileLoadTree = createAsyncActionExecutor(modelFileLoadTreeActionsCreator, requestFileTree);

export const modelFileLoadTreeTHActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_TREE_TH);
export const modelFileLoadTHTree = createAsyncActionExecutor(modelFileLoadTreeTHActionsCreator, requestFileTreeTH);

export const modelFileUploadActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_UPLOAD);
export const modelFileUpload = createAsyncActionExecutor(modelFileUploadActionsCreator, requestUploadFile, {
  showError: false,
});

export const modelFileUpdateActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_UPDATE);
export const modelFileUpdate = createAsyncActionExecutor(modelFileUpdateActionsCreator, requestUpdateFile, {
  showError: false,
});

export const modelFileTagUpdateActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_FILE_TAG_UPDATE);
export const modelFileTagUpdate = createAsyncActionExecutor(modelFileTagUpdateActionsCreator, requestUpdateFileTag, {
  showError: false,
  successFunction: (dispatch) => {
    dispatch(messageAdd('File Types have been updated.', MessageTypes.SUCCESS));
  },
  failureFunction: (dispatch, error) => {
    if (error.status === 400) {
      dispatch(messageAdd('Retired File Type cannot be used again. Please add a new File Type.', MessageTypes.ERROR));
    } else {
      dispatch(messageAdd('Failed to update File Types.', MessageTypes.ERROR));
    }
  },
});

export const modelFileDeleteActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_DELETE);
export const modelFileDelete = createAsyncActionExecutor(modelFileDeleteActionsCreator, requestDeleteFile, {
  showError: false,
});

export const modelFileItemDownloadActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_ITEM_DOWNLOAD);
export const modelFileItemDownload = createAsyncActionExecutor(
  modelFileItemDownloadActionsCreator,
  requestModelItemDownload,
);

export const modelHelpFileDownloadActionsCreator = buildAsyncActionsCreator(
  ModelFileActions.REQUEST_HELP_FILE_DOWNLOAD,
);
export const modelHelpFileDownload = createAsyncActionExecutor(
  modelHelpFileDownloadActionsCreator,
  requestModelHelpFileDownload,
);

export const modelFileItemHistoryActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_ITEM_HISTORY);
export const modelFileItemHistory = createAsyncActionExecutor(
  modelFileItemHistoryActionsCreator,
  requestModelItemHistory,
  {
    showError: false,
  },
);

export const modelFileMarkChangeLogActionsCreator = buildAsyncActionsCreator(ModelFileActions.REQUEST_MARK_CHANGELOG);
export const modelFileMarkChangelog = createAsyncActionExecutor(
  modelFileMarkChangeLogActionsCreator,
  requestModelMarkChangeLog,
);

export const modelFileUnmarkChangeLogActionsCreator = buildAsyncActionsCreator(
  ModelFileActions.REQUEST_UNMARK_CHANGELOG,
);
export const modelFileUnmarkChangelog = createAsyncActionExecutor(
  modelFileUnmarkChangeLogActionsCreator,
  requestModelUnmarkChangeLog,
);

export const modelFileUploaderInit = (modelUuid: string, nodes: ModelTreeNode[]) => ({
  type: ModelFileActions.INIT_UPLOADER,
  nodes: nodes,
  modelUuid,
});

export const modelFileUploaderClear = (modelUuid: string) => ({
  type: ModelFileActions.CLEAR_UPLOADER,
  modelUuid,
});

export const modelFileUploaderMerge = (modelUuid: string, hasFiles: boolean) => ({
  type: ModelFileActions.MERGE_UPLOADER,
  modelUuid,
  hasFiles,
});

const modelFileNodeAction = <T extends ModelFileActions>(
  type: T,
  modelUuid: string,
  node: ModelTreeNode,
): ModelFileNodeAction<T> => ({type, modelUuid, node});

export const modelFileAdd = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.ADD, modelUuid, node);

export const modelFileMarkFileTag = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.ADD_FILE_TAG, modelUuid, node);

export const unMarkFileTagUpdate = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.UNMARK_FILE_TAG_CHANGE, modelUuid, node);

export const modelFileMarkDelete = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.MARK_DELETE, modelUuid, node);

export const modelFileUnmarkDelete = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.UNMARK_DELETE, modelUuid, node);

export const modelFileRemove = (modelUuid: string, node: ModelTreeNode) =>
  modelFileNodeAction(ModelFileActions.REMOVE, modelUuid, node);
