import React, {useCallback, useMemo, useState, useEffect} from 'react';
import AdminPage from '../common/AdminPage/AdminPage';
import {useIncidentList} from '../../../../store/incident';
import {incidentListGridId, useStoredGridState} from '../../../../store/grid';
import {IncidentListDTO} from 'hemwb-api';
import {Search} from '../../../core/Search/Search';
import AdminSpaceHeader from '../common/AdminSpaceHeader/AdminSpaceHeader';
import {ChatBot32} from '@carbon/icons-react';
import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_STRING_OPTIONS,
} from '../../../core/TableWithSortAndPagination';
import {CircularProgress} from '@material-ui/core';
import {fullName} from '../../../../store/user/utils';
import {IncidentActionMenu} from './IncidentActionMenu';
import IncidentOverview from './common/incidentOverview/IncidentOverview';

const columns: Column<IncidentListDTO>[] = [
  {id: 'incidentId', label: 'Incident Id', sortable: true, value: (incident) => incident.incidentId},
  {
    id: 'issueSummary',
    label: 'Incident Summary',
    sortable: true,
    value: (incident) => incident.issueSummary,
    display: (incident) => truncate(incident.issueSummary, 20),
  },
  {
    id: 'createdOn',
    label: 'Created On',
    sortable: true,
    value: (incident) => incident.createdOn,
    display: (incident) =>
      incident.createdOn ? incident.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS) : null,
  },
  {
    id: 'createdBy',
    label: 'Created By',
    sortable: true,
    value: (incident) => fullName(incident.createdBy),
  },
  {id: 'status', label: 'Status', sortable: true, display: (incident) => incident.status},
  {
    id: 'modifiedOn',
    label: 'Modified On',
    sortable: true,
    value: (incident) => incident.modifiedOn,
    display: (incident) =>
      incident.modifiedOn ? incident.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS) : null,
  },
  {
    id: 'modifiedBy',
    label: 'Modified By',
    sortable: true,
    value: (incident) => fullName(incident.modifiedBy),
    display: (incident) => (incident.modifiedBy ? fullName(incident.modifiedBy) : null),
  },
];

const truncate = (issueSummary: string, number: number) => {
  return issueSummary.length > number ? issueSummary.slice(0, number - 1) + ' ...' : issueSummary;
};

const searchIncidents = (incidents: IncidentListDTO[], search: string) => {
  if (!search) {
    return incidents;
  }
  const lcSearch = search.toLocaleLowerCase();
  return incidents.filter(
    (incident) =>
      incident.incidentId.toLocaleLowerCase().includes(lcSearch) ||
      incident.issueSummary.toLocaleLowerCase().includes(lcSearch) ||
      Object.values(incident.createdBy).some(
        (value) => !!value && typeof value === 'string' && value.toLocaleLowerCase().includes(lcSearch),
      ) ||
      (incident.modifiedBy !== null &&
        Object.values(incident.modifiedBy).some(
          (value) => !!value && typeof value === 'string' && value.toLocaleLowerCase().includes(lcSearch),
        )) ||
      incident.status.toLocaleLowerCase().includes(lcSearch),
  );
};

const IncidentList: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<IncidentListDTO>();
  const gridState = useStoredGridState(incidentListGridId);
  const list = useIncidentList();

  const filteredIncident = useMemo(() => {
    return list && searchIncidents(list, gridState.search);
  }, [list, gridState.search]);

  const handleSearchChange = useCallback(
    (search: string) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setSearch(search);
    },
    [gridState],
  );

  const handleClickRow = (incident: IncidentListDTO) => {
    if (gridState.expandedRowId === incident.incidentId) {
      gridState.setExpandedRowId(null);
    } else {
      gridState.setExpandedRowId(incident.incidentId);
    }
  };

  const expandIncidentDetails = (incident: IncidentListDTO) => {
    return <IncidentOverview incident={list!.find((m) => m.incidentId === incident.incidentId)!} />;
  };

  useEffect(() => {
    if (filteredIncident && gridState.expandedRowId) {
      setSelectedRow(filteredIncident.find((incident) => incident.incidentId === gridState.expandedRowId));
    } else {
      setSelectedRow(undefined);
    }
  }, [filteredIncident, gridState.expandedRowId]);

  return (
    <>
      <AdminPage style={{paddingLeft: '2rem'}}>
        <AdminSpaceHeader
          Search={<Search value={gridState.search} onValueChange={handleSearchChange} />}
          label="Analyze the incidents"
          Icon={<ChatBot32 />}
        />
        {filteredIncident ? (
          <TableWithSortAndPagination
            getRowId={(incident) => incident.incidentId}
            getExpandedRow={expandIncidentDetails}
            rows={filteredIncident}
            columns={columns}
            onClickRow={handleClickRow}
            isRowSelected={(incident) => !!selectedRow && selectedRow.incidentId === incident.incidentId}
            gridState={gridState}
            noResultsMessage="No users were found"
          />
        ) : (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexGrow: 1,
            }}>
            <CircularProgress />
          </div>
        )}
      </AdminPage>
      <IncidentActionMenu
        incident={selectedRow}
        incidentId={selectedRow?.incidentId}
        status={selectedRow?.status}
        issueSummary={selectedRow?.issueSummary}
        reportId={selectedRow?.reportId}
      />
    </>
  );
};

export default IncidentList;
