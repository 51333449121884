import {Button} from '@material-ui/core';
import React, {PropsWithChildren} from 'react';
import {ButtonProps} from '@material-ui/core/Button';
import {TextOrProgress} from './textOrProgress/TextOrProgress';

export type AsyncActionButtonProps = PropsWithChildren<{active: boolean} & ButtonProps>;

export const AsyncActionButton: React.FC<AsyncActionButtonProps> = ({children, active, disabled, ...buttonProps}) => (
  <Button {...buttonProps} disabled={disabled || active}>
    <TextOrProgress active={active}>{children}</TextOrProgress>
  </Button>
);
