import React, {CSSProperties, PropsWithChildren} from 'react';
import DefaultPage from '../../../../layout/common/DefaultPage';
import AdminNavBar from '../AdminNavBar/AdminNavBar';
import SecondaryHeader from '../../../../layout/common/SecondaryHeader';

const AdminPage: React.FC<PropsWithChildren<{style?: CSSProperties}>> = (props) => {
  return (
    <>
      <SecondaryHeader>
        <AdminNavBar />
      </SecondaryHeader>
      <DefaultPage {...props}></DefaultPage>
    </>
  );
};

export default AdminPage;
