import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {useScenarioDownloadOutput} from './useScenarioDownloadOutput';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {canViewInstanceResults} from '../../../../../permissions/usePermissions';
import {Download16} from '@carbon/icons-react';

const ScenarioDownloadOutputActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {downloadResults, downloadingResults} = useScenarioDownloadOutput(props);

  return (
    <SideBarButtonItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_DOWNLOAD_OUTPUT)}
      permission={canViewInstanceResults(props.model, props.subModel, props.scenario)}
      onClick={downloadResults}
      active={downloadingResults}
      icon={<Download16 />}
      text="Download Results"
    />
  );
};

export default ScenarioDownloadOutputActionMenuButton;
