import React from 'react';
import {IssueSeverity, ModelIssue, sortBySection, sortBySeverity} from '../../../../store/model/validator';
import clsx from 'clsx';
import {Button} from '@material-ui/core';

type ModelIssueListProps = {
  issues: ModelIssue[];
  setActiveStep?: (index: number) => void;
  errorLevel?: IssueSeverity;
};

const ModelIssueList: React.FC<ModelIssueListProps> = ({
  issues,
  setActiveStep,
  errorLevel = IssueSeverity.ERROR_CREATE,
}) => {
  if (issues.length === 0) {
    return null;
  }

  return (
    <>
      <div className="headline">List of issues</div>
      <ul>
        {issues
          .sort(sortBySeverity)
          .sort(sortBySection)
          .map((issue) => {
            return (
              <li key={issue.property}>
                <Button
                  className={clsx({
                    colorError: issue.severity >= errorLevel,
                    colorWarning: issue.severity < errorLevel,
                  })}
                  onClick={() => setActiveStep?.(issue.section)}>
                  {issue.message}
                </Button>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default ModelIssueList;
