import React, {useCallback, useMemo, useState} from 'react';
import {useExternalUserList} from '../../../../../store/user';
import {externalUserStatusText} from '../../../../../store/user/utils';
import {ExUserRegistrationDetails} from 'hemwb-api';
import TableWithSortAndPagination, {Column} from '../../../../core/TableWithSortAndPagination';
import {externalizationListGridId, useStoredGridState} from '../../../../../store/grid';
import {Button, CircularProgress} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../router/routes';
import {tid} from '../../../../../testUtils';
import {UserActionMenu} from '../common/UserActionMenu/UserActionMenu';
import {Search} from '../../../../core/Search/Search';
import {UserFollow16} from '@carbon/icons-react';
import Header from '../spaceHeader/Header';
import DefaultPage from '../../../../layout/common/DefaultPage';
import AdminSpaceHeader from '../../common/AdminSpaceHeader/AdminSpaceHeader';
import AdminPage from '../../common/AdminPage/AdminPage';
import {useLocation} from 'react-router-dom';

const emptyLastStringComparator = (a: string, b: string) => (!b ? 1 : !a ? -1 : b.localeCompare(a));
const buildUserComparator = (getter: (user: ExUserRegistrationDetails) => string) => (
  a: ExUserRegistrationDetails,
  b: ExUserRegistrationDetails,
) => emptyLastStringComparator(getter(a), getter(b));

const columns: Column<ExUserRegistrationDetails>[] = [
  {id: 'firstName', label: 'First Name', sortable: true, comparator: buildUserComparator((u) => u.firstName)},
  {id: 'lastName', label: 'Last Name', sortable: true, comparator: buildUserComparator((u) => u.lastName)},
  {id: 'email', label: 'Email', sortable: true, comparator: buildUserComparator((u) => u.email)},
  {id: 'active', label: 'Status', sortable: true, value: (u) => externalUserStatusText(u)},
];

const searchUsers = (users: ExUserRegistrationDetails[], search: string) => {
  if (!search) {
    return users;
  }
  const lcSearch = search.toLocaleLowerCase();
  return users.filter(
    (user) =>
      (user.email || '').toLocaleLowerCase().includes(lcSearch) ||
      (user.lastName || '').toLocaleLowerCase().includes(lcSearch) ||
      (user.firstName || '').toLocaleLowerCase().includes(lcSearch),
  );
};

const ExternalUserList: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<ExUserRegistrationDetails['id']>();
  const gridState = useStoredGridState(externalizationListGridId);
  const list = useExternalUserList();
  const {pathname} = useLocation();
  const adminExternalizationPage = pathname.startsWith('/admin/');
  const filteredList = useMemo(() => {
    return list && searchUsers(list, gridState.search);
  }, [list, gridState.search]);

  const handleSearchChange = useCallback(
    (search: string) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setSearch(search);
    },
    [gridState],
  );

  const handleClickRow = (user: ExUserRegistrationDetails) => {
    if (selectedUserId === user.id) {
      setSelectedUserId(undefined);
    } else {
      setSelectedUserId(user.id);
    }
  };

  const selectedUser = useMemo(() => {
    if (filteredList && selectedUserId) {
      return filteredList.find((user) => user.id === selectedUserId);
    } else {
      return null;
    }
  }, [filteredList, selectedUserId]);

  const externalUserList = () => {
    if (filteredList) {
      return (
        <TableWithSortAndPagination
          getRowId={(user) => user.id || ''}
          rows={filteredList}
          columns={columns}
          onClickRow={handleClickRow}
          isRowSelected={(user) => selectedUserId === user.id}
          gridState={gridState}
          noResultsMessage="No users were found"
        />
      );
    } else {
      return (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexGrow: 1,
          }}>
          <CircularProgress />
        </div>
      );
    }
  };

  return (
    <>
      {!adminExternalizationPage ? (
        <DefaultPage>
          <Header Search={<Search value={gridState.search} onValueChange={handleSearchChange} />} />

          <div className="listPanel">
            <Button
              component={Link}
              to={Routes.EXTERNALIZATION_ENROLL}
              variant="contained"
              color="primary"
              endIcon={<UserFollow16 />}
              {...tid('btn', 'enroll-user')}>
              Register New User
            </Button>
          </div>
          {externalUserList()}
        </DefaultPage>
      ) : (
        <AdminPage style={{paddingLeft: '2rem'}}>
          <AdminSpaceHeader
            label="Activate and deactivate users"
            Search={<Search value={gridState.search} onValueChange={handleSearchChange} />}
          />
          {externalUserList()}
        </AdminPage>
      )}
      <UserActionMenu
        selectedUser={selectedUser || null}
        adminExternalizationPage={adminExternalizationPage ? true : false}
      />
    </>
  );
};

export default ExternalUserList;
