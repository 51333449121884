import React from 'react';
import {AbstractControl, FormControl, FormGroup} from 'react-reactive-form';
import {BooleanInputAttributes, booleanOptions, InputGenerator, InputType} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {DropDownControl} from '../../../Form';
import GridItem from '../../../Form/GridItem';
import {getBooleanInputTypeLabel} from '../utils';

type ControlsMap = {[key in keyof Required<BooleanInputAttributes>]: AbstractControl};

type StringInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const StringInput: React.FC<StringInputProps> = ({formGroup}) => {
  const control = formGroup.get('defaultValue');
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <GridItem>
        <DropDownControl
          label="Default Value"
          control={control}
          name="defaultValue"
          options={booleanOptions}
          getOptionLabel={getBooleanInputTypeLabel}
        />
      </GridItem>
    </>
  );
};

export const booleanInputGenerator: InputGenerator<InputType.BOOLEAN> = {
  type: InputType.BOOLEAN,
  buildControl: (initialValues = {}) =>
    new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      defaultValue: new FormControl(initialValues.defaultValue ?? null),
    } as ControlsMap),
  render: StringInput,
};
