import {
  ExecutionSetupDTO,
  ExecutionSetupType,
  ExecutionSetupUpdateDTO,
  ModelInputDetailDTO,
  ModelInputType,
  ModelItemBaseDTO,
} from 'hemwb-api';

export const environmentOptionsByLanguage: {[language: string]: string[]} = {
  Mathematica: ['Mathematica 12'],
  Python: ['Python 3'],
};

export const getSubModelTypeLabel = (type: ExecutionSetupType) => `${type.toLowerCase()} sub-model`;

type SubModelFormValues = {
  name: string;
  // type: ExecutionSetupType;
  executionModels: Array<ModelItemBaseDTO>;
  environment: string;
  timeoutSeconds: number | null;
  calibration: ExecutionSetupDTO | null;
  display: ExecutionSetupDTO | null;
  inputType: ModelInputType | null;
  outputPathDir: string;
};

export const transformSubModelToValues = (
  subModel: ExecutionSetupDTO,
  input?: ModelInputDetailDTO,
  calibrations?: ExecutionSetupDTO[],
  displays?: ExecutionSetupDTO[],
): SubModelFormValues => ({
  name: subModel.name,
  // type: subModel.type || ExecutionSetupType.SCENARIO,
  executionModels: subModel.executionModels || [],
  environment: subModel.environment || '',
  calibration: calibrations?.find((c) => c.id === Number(subModel.executionSetupId)) || null,
  display: displays?.find((c) => c.id === Number(subModel.displayExecutionSetupId)) || null,
  timeoutSeconds:
    subModel.timeoutSeconds && subModel.timeoutSeconds > 0 ? Math.round(subModel.timeoutSeconds / 60) : null,
  inputType: input?.type || ModelInputType.JSONTEMPLATE,
  outputPathDir: subModel.outputPathDir || './',
});

const displayTypeCheck = (v: SubModelFormValues) => {
  if (v.display) {
    return typeof v.display === 'object' ? v.display.id : v.display;
  }
};

const calibrationTypeCheck = (v: SubModelFormValues) => {
  if (v.calibration) {
    return typeof v.calibration === 'object' ? v.calibration.id : v.calibration;
  }
};

export const mapValuesToSubModel = (v: SubModelFormValues): ExecutionSetupUpdateDTO => {
  const executionSetupId = v.calibration ? calibrationTypeCheck(v) : undefined;

  const displayExecutionSetupId = v.display ? displayTypeCheck(v) : undefined;

  return {
    name: v.name,
    items: (v.executionModels || []).map((f) => f.uuid),
    environment: v.environment,
    timeoutSeconds: Number(v.timeoutSeconds) * 60,
    executionSetupId: executionSetupId,
    displayExecutionSetupId: displayExecutionSetupId,
    outputPathDir: v.outputPathDir,
  };
};
