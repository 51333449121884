import React, {ReactNode} from 'react';
import {Warning_01 as Warning01} from '@carbon/pictograms-react';
import styles from './WarningText.module.scss';
import clsx from 'clsx';
import {Grid, GridProps} from '@material-ui/core';

export type WarningTextProps = GridProps & {
  text: ReactNode | string;
};

const WarningText: React.FC<WarningTextProps> = ({text, ...props}) => {
  return (
    <Grid container item wrap="nowrap" alignItems="center" direction="row" {...props}>
      <Grid item>
        <Warning01 className={clsx('icon32', styles.icon)} />
      </Grid>
      <Grid item>
        <div className={styles.text}>{text}</div>
      </Grid>
    </Grid>
  );
};

export default WarningText;
