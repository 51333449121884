import {userFind} from '../../../store/user';
import {FindUsersRequest, UserBaseDTO} from 'hemwb-api';
import {fullName} from '../../../store/user/utils';
import {AutocompleteControl, AutocompleteControlProps} from './AutocompleteControl';
import React, {useCallback} from 'react';
import {AutocompleteItem} from './AutocompleteItem';
import {tid} from '../../../testUtils';
import {useDispatch} from 'react-redux';

type AutocompleteUserControlProps = Partial<AutocompleteControlProps> &
  Pick<AutocompleteControlProps, 'label' | 'name'> & {
    userFindOptions?: Partial<FindUsersRequest>;
    modelUuid?: string;
    theoremModel?: boolean;
    fileUuid?: string;
    handleSecurityAPI?: () => void;
  };

const userFindOptionsDefault = {};

const AutocompleteUserControl: React.FC<AutocompleteUserControlProps> = (props) => {
  const {
    userFindOptions = userFindOptionsDefault,
    modelUuid,
    theoremModel,
    fileUuid,
    handleSecurityAPI,
    ...restProps
  } = props;
  const dispatch = useDispatch();
  const findUsersCallback = useCallback(
    (value: string) => {
      return userFind(dispatch, value.replace(/[<>]/g, ''), userFindOptions);
    },
    [dispatch, userFindOptions],
  );

  const renderUserItem = (user: UserBaseDTO, name: string, modelUuid: string, theoremModel: boolean) => (
    <AutocompleteItem
      handleSecurityAPI={handleSecurityAPI}
      theoremModel={theoremModel}
      modelUuid={modelUuid}
      fileUuid={fileUuid}
      isid={user.id}
      primary={fullName(user)}
      secondary={user.email || ''}
      {...tid('input-option', name, user.id)}
    />
  );

  return (
    <AutocompleteControl
      wait={1000}
      search={findUsersCallback}
      disableNoOptionsForEmptySearch
      disableDeletingTagsByKeyboard
      {...restProps}
      AutocompleteProps={{
        getOptionSelected: (option: UserBaseDTO, value: UserBaseDTO) => {
          return option.id === value.id;
        },
        getOptionLabel: (option: UserBaseDTO) => {
          const optionEmail = ` <${option.email}>`;
          return `${fullName(option)}${option.email ? optionEmail : ''}`;
        },
        renderOption: (option: UserBaseDTO) =>
          renderUserItem(option, props.name || '', modelUuid || '', theoremModel || false),
        disableOpenOnFocus: true,
        ...props.AutocompleteProps,
      }}
    />
  );
};

export default AutocompleteUserControl;
