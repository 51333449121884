import {ExecutionSetupType} from 'hemwb-api';
import {SubModelSection} from '../../components/router/routes';

const displayExecutionSectionSetup = (section: string) => {
  return section === 'display' ? ExecutionSetupType.DISPLAY : ExecutionSetupType.SCENARIO;
};
const displayExecutionTypeSetup = (type?: ExecutionSetupType) => {
  return type === ExecutionSetupType.DISPLAY ? SubModelSection.DISPLAY : SubModelSection.SCENARIO;
};

export const sectionToType = (section: string): ExecutionSetupType =>
  section === 'calibration' ? ExecutionSetupType.CALIBRATION : displayExecutionSectionSetup(section);
export const typeToSection = (type?: ExecutionSetupType): SubModelSection =>
  type === ExecutionSetupType.CALIBRATION ? SubModelSection.CALIBRATION : displayExecutionTypeSetup(type);
export const buildKey = (modelUuid: string, type?: ExecutionSetupType): string => `${modelUuid}-${type || ''}`;
