import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {ScenarioActions, ScenarioActionUpdateStatus} from './types';
import {
  requestScenarioCreate,
  requestScenarioDelete,
  requestScenarioDetail,
  requestScenarioLoadError,
  requestScenarioGetOutput,
  requestScenarioList,
  requestScenarioNameExists,
  requestScenarioStatusUpdate,
  requestScenarioUpdate,
  requestScenarioUpdateInputs,
  requestScenarioShare,
  requestScenarioUpdateLabel,
  requestScenarioOutdate,
  requestUserList,
  requestShareInstance,
  requestUpdateShareInstance,
} from './api';
import {ScenarioDTO} from 'hemwb-api';

const scenarioLoadDetailActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_DETAIL);
export const scenarioLoadDetail = createAsyncActionExecutor(scenarioLoadDetailActionsCreator, requestScenarioDetail);

const scenarioLoadListActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_LIST);
export const scenarioLoadList = createAsyncActionExecutor(scenarioLoadListActionsCreator, requestScenarioList);

const scenarioCreateActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_CREATE);
export const scenarioCreate = createAsyncActionExecutor(scenarioCreateActionsCreator, requestScenarioCreate);

const scenarioUpdateActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_UPDATE);
export const scenarioUpdate = createAsyncActionExecutor(scenarioUpdateActionsCreator, requestScenarioUpdate);

const scenarioUpdateLabelActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_UPDATE_LABEL);
export const scenarioUpdateLabel = createAsyncActionExecutor(
  scenarioUpdateLabelActionsCreator,
  requestScenarioUpdateLabel,
);

const scenarioUpdateInputsActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_UPDATE_INPUTS);
export const scenarioUpdateInputs = createAsyncActionExecutor(
  scenarioUpdateInputsActionsCreator,
  requestScenarioUpdateInputs,
);

export const scenarioUpdateStatus = (scenario: ScenarioDTO): ScenarioActionUpdateStatus => ({
  type: ScenarioActions.UPDATE_STATUS,
  scenario,
});

const scenarioLoadOutputsActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_OUTPUTS);
export const scenarioLoadOutputs = createAsyncActionExecutor(
  scenarioLoadOutputsActionsCreator,
  requestScenarioGetOutput,
);

const scenarioStatusUpdateActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_STATUS_UPDATE);
export const scenarioStatusUpdate = createAsyncActionExecutor(
  scenarioStatusUpdateActionsCreator,
  requestScenarioStatusUpdate,
);

const scenarioDeleteActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_DELETE);
export const scenarioDelete = createAsyncActionExecutor(scenarioDeleteActionsCreator, requestScenarioDelete);

const scenarioNameExistsActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_NAME_EXISTS);
export const scenarioNameExists = createAsyncActionExecutor(
  scenarioNameExistsActionsCreator,
  requestScenarioNameExists,
);

const scenarioLoadErrorActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_ERROR);
export const scenarioLoadError = createAsyncActionExecutor(scenarioLoadErrorActionsCreator, requestScenarioLoadError);

const scenarioShareActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_SHARE);
export const scenarioShare = createAsyncActionExecutor(scenarioShareActionsCreator, requestScenarioShare);

const scenarioOutdateActionsCreator = buildAsyncActionsCreator(ScenarioActions.REQUEST_OUTDATE);
export const scenarioOutdate = createAsyncActionExecutor(scenarioOutdateActionsCreator, requestScenarioOutdate);

const scenarioShareUserList = buildAsyncActionsCreator(ScenarioActions.REQUEST_USERS);
export const shareUsersList = createAsyncActionExecutor(scenarioShareUserList, requestUserList);

const shareScenarioCreator = buildAsyncActionsCreator(ScenarioActions.INSTANCE_SHARE);
export const shareInstance = createAsyncActionExecutor(shareScenarioCreator, requestShareInstance);

const updateShareScenarioCreator = buildAsyncActionsCreator(ScenarioActions.UPDATE_INSTANCE_SHARE);
export const updateShareInstance = createAsyncActionExecutor(updateShareScenarioCreator, requestUpdateShareInstance);
