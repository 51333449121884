import React, {useState, useEffect} from 'react';
import {Form, useForm, TextFieldControl} from '../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {tid} from '../../testUtils';
import {Checkbox} from '@material-ui/core';
import SubmitButton from '../core/Buttons/SubmitButton';
import AgreementDialog from './AgreementDialog';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import styles from './NewRegistrationPage.module.scss';
import clsx from 'clsx';
import {selfRegisterExternalUser} from '../../store/user';
import RegistrationConfirmationDialog from './RegistrationConfirmationDialog';
import SnackbarContainer from '../layout/common/SnackbarContainer';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../store/message';

export const RegistrationForm: React.FC = () => {
  const [openAgreementDialog, setOpenAgreementDialog] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const dispatch = useDispatch();
  const form = useForm(
    new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(?!.*@(merck|msd)\.com$).*$/),
        Validators.email,
      ]),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      code: new FormControl(null, Validators.required),
      checkbox: new FormControl(null, Validators.required),
    }),
  );
  const boxControl = form.get('checkbox');

  const handleCancel = () => {
    setOpenAgreementDialog(false);
    setIsEnable(true);
    setOpenConfirmationDialog(false);
  };

  useEffect(() => {
    if (isEnable) {
      boxControl.setValue(true);
    } else {
      boxControl.setValue(null);
    }
  }, [isEnable, boxControl]);

  const handleSubmit = () => {
    selfRegisterExternalUser(dispatch, {
      firstName: form.controls.firstName.value.trim(),
      lastName: form.controls.lastName.value.trim(),
      email: form.controls.email.value.toLowerCase(),
      registrationCode: form.controls.code.value.trim(),
    }).then(() => {
      dispatch(messageAdd('user registered successfully', MessageTypes.SUCCESS));
      setOpenConfirmationDialog(true);
    });
  };

  return (
    <>
      <div className={styles.registrationContainer}>
        <div className={styles.wrapper}>
          <span className={clsx('noPrint', styles.header)}>
            <LogoIcon style={{height: '1.5rem', width: '1.5rem', margin: '0 1rem'}} />
            <span style={{fontWeight: 'bold'}}> Model Repository </span>
          </span>
          <div style={{fontSize: '20px', textAlign: 'center', marginBottom: '2rem'}}>
            <b>Register in the Model Repository</b>
          </div>
          <Form
            group={form}
            onSubmit={handleSubmit}
            render={({submitting}) => (
              <>
                <div>
                  <div style={{width: '30rem', marginBottom: '0.5rem'}}>
                    <TextFieldControl
                      label="First Name"
                      name="firstName"
                      TextFieldProps={{
                        inputProps: {
                          ...tid('input', 'firstname'),
                        },
                      }}
                    />
                  </div>
                  <div style={{width: '30rem', marginBottom: '0.5rem'}}>
                    <TextFieldControl
                      label="Last Name"
                      name="lastName"
                      TextFieldProps={{
                        inputProps: {
                          ...tid('input', 'lastname'),
                        },
                      }}
                    />
                  </div>
                  <div style={{width: '30rem', marginBottom: '0.5rem'}}>
                    <TextFieldControl
                      label="Email Id"
                      name="email"
                      customErrorMessages={{
                        pattern:
                          'You have entered email address ending with the Merck domain. Please enter an email address that does not have the Merck domain such as @merck.com or @msd.com.',
                      }}
                      TextFieldProps={{
                        inputProps: {
                          ...tid('input', 'email'),
                        },
                      }}
                    />
                  </div>

                  <div style={{width: '30rem', marginBottom: '0.5rem'}}>
                    <TextFieldControl
                      label="Registration Code"
                      name="code"
                      TextFieldProps={{
                        inputProps: {
                          ...tid('input', 'code'),
                        },
                      }}
                    />
                  </div>

                  <div>
                    <Checkbox
                      style={{marginLeft: '-10px'}}
                      color="primary"
                      size="small"
                      onChange={() => setIsEnable(!isEnable)}
                      name="checkbox"
                      checked={isEnable}
                      disabled={!isEnable}
                    />
                    <span
                      style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={() => setOpenAgreementDialog(true)}>
                      Read the agreement
                    </span>
                  </div>
                </div>

                <div style={{textAlign: 'center', marginTop: '-1rem'}}>
                  <SubmitButton active={submitting} disabled={!form.valid} {...tid('btn-register')}>
                    Request Access to Model Repository
                  </SubmitButton>
                </div>
              </>
            )}
          />
        </div>
      </div>
      <AgreementDialog open={openAgreementDialog} title="Legal Agreement" handleCancel={handleCancel} />
      <RegistrationConfirmationDialog open={openConfirmationDialog} handleCancel={handleCancel} />
      <SnackbarContainer />
    </>
  );
};
