import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {useCurrentUser} from '../../../../../store/auth';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {fullNameWestern} from '../../../../../store/user/utils';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO, UserBaseDTO} from 'hemwb-api';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {tid} from '../../../../../testUtils';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {Avatar, Button, Chip, Grid, InputAdornment} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import AutocompleteUserControl from '../../../../core/Form/AutocompleteUserControl';
import CopyToClipboard from 'react-copy-to-clipboard';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Share16, Share32} from '@carbon/icons-react';
import {scenarioShare} from '../../../../../store/scenario';
import {trackInstanceVisualizationShared} from '../../../../../tracking/tracking';

export type VisualizationShareLinkProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  scenario: ScenarioDTO;
  onSuccess: () => void;
  onCancel: () => void;
};

const userFindOptions = {limit: 10, includeNpa: false, includeUnenrolled: true};
const defaultMessage = '{user} has invited you to access and explore analysis for: {name}, using the link below:';

const VisualizationShareLink: React.FC<VisualizationShareLinkProps> = ({
  model,
  subModel,
  scenario,
  onSuccess,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const url = `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}`;
  const currentUser = useCurrentUser();

  const form = useForm(
    new FormGroup({
      users: new FormControl([], Validators.required),
      message: new FormControl('', [Validators.required]),
    }),
    {
      link: url,
      users: [],
      message: defaultMessage.replace('{user}', fullNameWestern(currentUser)).replace('{name}', model.name),
    },
  );

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const handleDeleteUser = useCallback(
    (user: UserBaseDTO) => {
      form.controls.users.setValue(form.controls.users.value.filter((u: UserBaseDTO) => u.id !== user.id));
      form.controls.users.markAsTouched();
    },
    [form],
  );

  const handleSubmit = useCallback(() => {
    return scenarioShare(dispatch, scenario.id, {
      isids: form.controls.users.value.map((u: UserBaseDTO) => u.id),
      messageText: form.controls.message.value,
    }).then(() => {
      trackInstanceVisualizationShared(model, subModel, scenario);
      dispatch(messageAdd('Email has been sent', MessageTypes.SUCCESS));
      onSuccess?.();
    });
  }, [dispatch, model, subModel, scenario, form.controls.users.value, form.controls.message.value, onSuccess]);

  return (
    <Form
      FieldGroupProps={{strict: false}}
      group={form}
      onSubmit={handleSubmit}
      className=""
      {...tid(VISUALIZATION_ACTION_MENU_IDS.SHARE, 'form')}
      render={({valid, submitting}) => {
        return (
          <>
            <div style={{paddingLeft: '3rem'}}>
              <ActionHeader label="Share Analysis" Icon={<Share32 />}>
                {scenario.name}
              </ActionHeader>
            </div>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="space-between"
              style={{paddingLeft: '3rem'}}>
              <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                <GridItem>
                  <AutocompleteUserControl
                    name="users"
                    label="Send an email to selected users"
                    userFindOptions={userFindOptions}
                    AutocompleteProps={{
                      disableClearable: true,
                      multiple: true,
                      renderTags: () => null,
                      getOptionDisabled: (option) =>
                        form.controls.users.value?.some((u: UserBaseDTO) => u.id === option.id),
                    }}
                  />
                </GridItem>

                <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                  {form.controls.users.value.map((user: UserBaseDTO) => (
                    <Chip
                      key={user.id}
                      color="primary"
                      avatar={
                        <Avatar>
                          {user.firstName && user.firstName[0]}
                          {user.lastName && user.lastName[0]}
                          {!user.firstName && !user.lastName && user.id[0]}
                        </Avatar>
                      }
                      label={fullNameWestern(user)}
                      onDelete={() => handleDeleteUser(user)}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid container item direction="column" sm={12} md={6} style={{paddingLeft: '3rem'}}>
                <GridItem>
                  <TextFieldControl
                    label="Customize your email message"
                    name="message"
                    TextFieldProps={{
                      multiline: true,
                      rows: 5,
                      inputProps: {
                        ...tid('input', 'message'),
                      },
                    }}
                  />
                </GridItem>
              </Grid>
            </Grid>

            <div style={{paddingLeft: '3rem'}}>
              <TextFieldControl
                strict={false}
                label="Direct Link"
                name="link"
                disabled
                TextFieldProps={{
                  InputProps: {
                    style: {padding: 0},
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard text={url} onCopy={handleCopy}>
                          <Button color="primary" variant="contained" style={{color: 'white'}}>
                            {!copied ? 'Copy link' : 'Copied'}
                          </Button>
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
            <br />
            <br />
            <br />

            <div className="buttonsContainer">
              <CancelButton
                onClick={onCancel}
                disabled={submitting}
                {...tid(VISUALIZATION_ACTION_MENU_IDS.SHARE, 'cancel')}
              />
              <SubmitButton
                active={submitting}
                disabled={!valid}
                endIcon={<Share16 />}
                {...tid(VISUALIZATION_ACTION_MENU_IDS.SHARE, 'submit')}>
                Share Link
              </SubmitButton>
            </div>
          </>
        );
      }}
    />
  );
};

export default VisualizationShareLink;
