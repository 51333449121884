import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {CircularProgress, FormControl, InputAdornment, InputLabel, TextField} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField';
import {getErrorText, getVisibleErrors} from './utils';

type TextFieldControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label: React.ReactNode;
  TextFieldProps?: TextFieldProps;
  plural?: boolean;
  required?: boolean;
  hideAsterisk?: boolean;
  disabled?: boolean;
  customErrorMessages?: object;
};

export const TextFieldControl: React.FC<TextFieldControlProps> = ({
  label,
  TextFieldProps,
  plural,
  required,
  hideAsterisk,
  disabled = false,
  customErrorMessages = {},
  ...FieldControlProps
}) => (
  <FieldControl
    {...FieldControlProps}
    render={(renderProps) => {
      const {handler, pending} = renderProps;

      const visibleErrors = getVisibleErrors(renderProps);
      const hasVisibleErrors = Object.keys(visibleErrors).length > 0;
      const handlers = handler();

      return (
        <FormControl fullWidth error={hasVisibleErrors} hiddenLabel={true} className="MuiFormControl-hem">
          {!hideAsterisk && (
            <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
              {label}
            </InputLabel>
          )}

          <TextField
            label=""
            hiddenLabel={true}
            InputProps={{
              endAdornment: pending && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
            {...TextFieldProps}
            {...handlers}
            variant="filled"
            margin="dense"
            error={hasVisibleErrors}
            helperText={hasVisibleErrors && getErrorText(visibleErrors, String(label), plural, customErrorMessages)}
            disabled={disabled || handlers.disabled}
          />
        </FormControl>
      );
    }}
  />
);
