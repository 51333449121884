import React, {useEffect, useState} from 'react';
import styles from './DynamicView.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {dynamicSearchTypes} from '../../../../store/modelMetadata';
import {dynamicSearchGridId, useStoredGridState} from '../../../../store/grid';
import {DynamicFilterState} from '../filter';

type DynamicViewDropDownControllerProps = {
  label: string;
  options: string[];
  handleOnchange: (searchType: string, value: string) => void;
  handleRemoveOption: boolean;
};

const DynamicViewDropDownController: React.FC<DynamicViewDropDownControllerProps> = (props) => {
  const {label, options, handleOnchange, handleRemoveOption} = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const gridState = useStoredGridState<DynamicFilterState>(dynamicSearchGridId);
  const searchType = dynamicSearchTypes[label];
  const class1 = disabled ? styles.disable + ' ' + searchType : styles.dropDownWrapper + ' ' + searchType;

  useEffect(() => {
    if (handleRemoveOption) {
      setSelectedValue([]);
    }
    if (gridState.filter) {
      switch (searchType) {
        case 'therapeutic_area': {
          setDisabled(options.includes(gridState.filter?.therapeutic_area[0]));
          break;
        }
        case 'disease': {
          setDisabled(options.includes(gridState.filter?.disease[0]));
          break;
        }
        case 'products': {
          setDisabled(options.includes(gridState.filter?.product[0]));
          break;
        }
        case 'study_numbers': {
          setDisabled(options.includes(gridState.filter?.study_number[0]));
          break;
        }
        case 'global_adaptation': {
          setDisabled(options.includes(gridState.filter?.global[0]));
          break;
        }
        case 'countries': {
          setDisabled(options.includes(gridState.filter?.country[0]));
          break;
        }
        case 'model_types': {
          setDisabled(options.includes(gridState.filter?.model_type[0]));
          break;
        }
        case 'platform': {
          setDisabled(options.includes(gridState.filter?.platform[0]));
          break;
        }
      }
    }
  }, [handleRemoveOption, gridState.filter, searchType, options]);

  return (
    <>
      <div className={class1}>
        <Autocomplete
          disableClearable
          multiple
          value={selectedValue}
          data-testid={'dynamicView-' + label}
          className={styles.autoComplete}
          disabled={disabled}
          options={options}
          renderTags={() => null}
          onChange={(event: any, newValue: string[]) => {
            if (handleOnchange) {
              handleOnchange(searchType, newValue[0]);
              setSelectedValue(newValue);
              setDisabled(true);
            }
          }}
          renderInput={(params) => (
            <TextField
              name={'dynamicView-' + label + '-textFiled'}
              label={label}
              className={styles.autoComplete}
              {...params}
              inputProps={{...params.inputProps, maxLength: 25}}
            />
          )}
        />
      </div>
    </>
  );
};

export default DynamicViewDropDownController;
