import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_TH_STRING_OPTIONS,
  TO_UTC_DATE_STRING_OPTIONS,
} from '../TableWithSortAndPagination';
import React, {useCallback, useEffect, useState} from 'react';
import {FileRenderItem, FileTreeTHProps} from './types';
import {Order, useGridState} from '../../../store/grid';
import {ModelItemTHBaseDTO, UserType} from 'hemwb-api';
import {tid} from '../../../testUtils';
import SubmitButton from '../Buttons/SubmitButton';
import {Connect16, Development24, Locked16, Password16} from '@carbon/icons-react';
import clsx from 'clsx';
import {useCurrentUser} from '../../../store/auth';
import {Tooltip} from '@material-ui/core';

const Icon = Development24;
const FileNameTag: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    <Icon className="nodeIcon" />
    <span>{file.fileName}</span>
  </>
);

const FileOwnerTag: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    <span>
      {file.owner.lastname} {file.owner.firstname}
    </span>
  </>
);

const PrivateIcon: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    {file.isPrivate && (
      <Tooltip
        title={
          <div style={{fontSize: 12}}>
            <span>
              The privacy settings for files is handled in <br />
              THEOREM
            </span>
          </div>
        }
        placement="top"
        arrow>
        <span>
          <Locked16 />
        </span>
      </Tooltip>
    )}
  </>
);

export const displayFileName = (file: FileRenderItem) => <FileNameTag file={file} />;
export const displayFileOwner = (file: FileRenderItem) => <FileOwnerTag file={file} />;
export const displayPrivateIcon = (file: FileRenderItem) => <PrivateIcon file={file} />;

const filesTHGridColumns: Column<FileRenderItem>[] = [
  {
    id: 'asOfDate',
    label: 'THEOREM "As-of Date"',
    sortable: true,
    value: (es) => es.asOfDate,
    display: (es) => (es.asOfDate ? es.asOfDate.toLocaleDateString('en-US', TO_LOCALE_DATE_TH_STRING_OPTIONS) : ''),
  },
  {id: 'fileName', label: 'File Name', sortable: false, value: (o) => o.fileName, display: displayFileName},
  {
    id: ',createdOn',
    label: 'File Uploaded On',
    sortable: true,
    value: (es) => es.createdOn,
    display: (es) => (es.createdOn ? es.createdOn.toLocaleDateString('en-US', TO_UTC_DATE_STRING_OPTIONS) : ''),
  },
  {
    id: 'owner',
    label: 'File Owner',
    sortable: false,
    value: (es) => es.owner.firstname || '',
    display: displayFileOwner,
  },
  {
    id: 'comment',
    label: 'Comments',
    sortable: false,
    value: (es) => es.comment || '',
  },
  {
    id: 'fileTag',
    label: 'What type of file is this? ',
    sortable: false,
    value: (es) => es.fileTag || '',
  },
  {
    id: 'button',
    label: ' ',
    sortable: false,
    value: (es) => es.button || '',
  },
];

const filesTHGridColumnsWithPrivate: Column<FileRenderItem>[] = [
  filesTHGridColumns[0],
  filesTHGridColumns[1],
  filesTHGridColumns[2],
  filesTHGridColumns[3],
  {
    id: 'isPrivate',
    label: 'Private Files',
    sortable: false,
    value: (es) => es.isPrivate || '',
    display: displayPrivateIcon,
  },
  filesTHGridColumns[4],
  filesTHGridColumns[5],
  filesTHGridColumns[6],
];

const filesTHGridColumnsFileUpload: Column<FileRenderItem>[] = [
  filesTHGridColumns[0],
  filesTHGridColumns[1],
  filesTHGridColumns[2],
  filesTHGridColumns[3],
  {
    id: 'isPrivate',
    label: 'Private Files',
    sortable: false,
    value: (es) => es.isPrivate || '',
    display: displayPrivateIcon,
  },
  filesTHGridColumns[4],
  filesTHGridColumns[5],
];

function FileTreeTH(props: FileTreeTHProps) {
  const {files, externalModel, handleRequestDialog, handleManageDialog, handleOwnershipDialog} = props;
  const gridState = useGridState({orderBy: 'asOfDate', order: Order.ASC});
  const [displayedFiles, setDisplayedFiles] = useState<FileRenderItem[]>([]);
  const [displayPrivateColumn, setDisplayPrivateColumn] = useState<boolean>(false);
  const {id: currentUserId, userType: loggedInUserType} = useCurrentUser();

  const handleRequestAccessItem = useCallback(
    (uuid: string, fileName: string) => {
      if (handleRequestDialog) handleRequestDialog(uuid, fileName);
    },
    [handleRequestDialog],
  );

  const handleOwnershipItem = useCallback(
    (uuid: string, fileName: string) => {
      if (handleOwnershipDialog) handleOwnershipDialog(uuid, fileName);
    },
    [handleOwnershipDialog],
  );

  const handleManageAccessItem = useCallback(
    (uuid: string, fileName: string) => {
      if (handleManageDialog) handleManageDialog(uuid, fileName);
    },
    [handleManageDialog],
  );

  const requestButton = useCallback(
    (item: ModelItemTHBaseDTO) => {
      return (
        <SubmitButton
          key={`request-item-${item.uuid}`}
          size="small"
          variant="contained"
          className={clsx('whiteSmokeButton noBoxShadow')}
          endIcon={<Connect16 />}
          disabled={item.isPrivate}
          onClick={() => handleRequestAccessItem(item.uuid, item.fileName)}
          {...tid('request-file-access', item.fileName)}>
          Request Access
        </SubmitButton>
      );
    },
    [handleRequestAccessItem],
  );

  const manageAccessButton = useCallback(
    (item: ModelItemTHBaseDTO) => {
      return (
        <SubmitButton
          key={`manage-item-${item.uuid}`}
          size="small"
          variant="contained"
          className={clsx('whiteSmokeButton noBoxShadow')}
          endIcon={<Password16 />}
          disabled={item.isPrivate}
          onClick={() => handleManageAccessItem(item.uuid, item.fileName)}
          {...tid('manage-file-access', item.fileName)}>
          Manage File Access
        </SubmitButton>
      );
    },
    [handleManageAccessItem],
  );

  const manageOwnershipButton = useCallback(
    (item: ModelItemTHBaseDTO) => {
      return (
        <SubmitButton
          key={`manage-owner-item-${item.uuid}`}
          size="small"
          variant="contained"
          className={clsx('whiteSmokeButton noBoxShadow')}
          endIcon={<Password16 />}
          // disabled={item.isPrivate}
          onClick={() => handleOwnershipItem(item.uuid, item.fileName)}
          {...tid('manage-file-owner-access', item.fileName)}>
          Manage File Access
        </SubmitButton>
      );
    },
    [handleOwnershipItem],
  );

  useEffect(() => {
    const updatedRows = files.specification?.map((item) => {
      const fileItem = item as FileRenderItem;
      if (item.isPrivate) {
        setDisplayPrivateColumn(item.isPrivate);
      } else {
        // @ts-ignore
        if (UserType.SUPERUSER === loggedInUserType) {
          fileItem.button = manageOwnershipButton(item);
        } else if (item.owner.isid === currentUserId) {
          fileItem.button = manageAccessButton(item);
        } else if (!fileItem.userAccess && UserType.INTERNALUSER === loggedInUserType) {
          fileItem.button = requestButton(item);
        }
      }

      return fileItem;
    });
    setDisplayedFiles(updatedRows);
  }, [files, requestButton, manageAccessButton, currentUserId, manageOwnershipButton, loggedInUserType]);

  const displayEditFlow = () => {
    return props.editFlow ? filesTHGridColumnsFileUpload : filesTHGridColumns;
  };
  return (
    <>
      {files.specification?.length !== 0 && (
        <TableWithSortAndPagination<FileRenderItem>
          getRowId={(o) => o.uuid}
          externalModel={externalModel}
          // @ts-ignore
          rows={displayedFiles}
          columns={displayPrivateColumn ? filesTHGridColumnsWithPrivate : displayEditFlow()}
          hidePagination={true}
          gridState={gridState}
        />
      )}
    </>
  );
}

export default FileTreeTH;
