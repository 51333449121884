import React from 'react';
import {FormControl} from 'react-reactive-form';
import {InputAttributes, InputType, rangeInputTypes} from '../../InputDefinition/types';
import {InputGenerator} from '../types';
import InputHeader from './InputHeader';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';
import StaticInputValuePrimitive from '../primitives/StaticInputValuePrimitive';

type IntegerInputProps = {
  control: FormControl;
  definition: InputAttributes;
  disabled?: boolean;
};

const IntegerInput: React.FC<IntegerInputProps> = ({control, definition, disabled}) => {
  const type = definition.type;
  if (!rangeInputTypes.hasOwnProperty(type)) {
    return null;
  }

  return (
    <>
      <InputHeader definition={definition} />
      <NumberInputPrimitive
        type={definition.type as keyof typeof rangeInputTypes}
        control={control}
        disabled={disabled}
      />
    </>
  );
};

export const integerInputGenerator: InputGenerator<InputType.INTEGER> = {
  type: InputType.INTEGER,
  buildControl: (definition, initialValues) => {
    return numberInputPrimitiveControlBuilder(
      definition,
      initialValues === undefined ? definition.defaultValue : initialValues,
    );
  },
  render: IntegerInput,
  renderStatic: StaticInputValuePrimitive,
};
