import React from 'react';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {canEditModel} from '../../../../../../permissions/usePermissions';
import {useModelEditMetadataSideBarModal} from './useModelEditMetadataSideBarModal';
import {CategoryNewEach16} from '@carbon/icons-react';

const ModelEditMetadataActionMenuButton: React.FC<Partial<ModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelEditMetadataSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.EDIT_METADATA)}
        permission={canEditModel(props.model)}
        onClick={openModal}
        icon={<CategoryNewEach16 />}
        text="Metadata"
      />
      {ModalComponent}
    </>
  );
};

export default ModelEditMetadataActionMenuButton;
