import {ExecutionSetupDTO, ScenarioDTO} from 'hemwb-api';
import {BooleanHashMap} from '../../commonTypes';
import {Action} from 'redux';
import {ScenarioGridFilter} from '../../components/pages/visualization/step1/types';
import {ChartTabularData} from '@carbon/charts/interfaces/model';
import * as charts from '@carbon/charts-react';

export enum ChartTypes {
  AREA = 'AreaChart',
  STACKED_AREA = 'StackedAreaChart',
  GROUPED_BAR = 'GroupedBarChart',
  SIMPLE_BAR = 'SimpleBarChart',
  STACKED_BAR = 'StackedBarChart',
  BOXPLOT = 'BoxplotChart',
  BUBBLE = 'BubbleChart',
  BULLET = 'BulletChart',
  DONUT = 'DonutChart',
  GAUGE = 'GaugeChart',
  HISTOGRAM = 'HistogramChart',
  LINE = 'LineChart',
  LOLLIPOP = 'LollipopChart',
  PIE = 'PieChart',
  SCATTER = 'ScatterChart',
  METER = 'MeterChart',
  RADAR = 'RadarChart',
  COMBO = 'ComboChart',
  TREE = 'TreeChart',
  TREEMAP = 'TreemapChart',
  CIRCLE_PACK = 'CirclePackChart',
  WORD_CLOUD = 'WordCloudChart',
  ALLUVIAL = 'AlluvialChart',
}

// export enum CurveType {
//   LINEAR = 'curveLinear',
//   CURVE_NATURAL = 'curveNatural',
//   CURVE_MONOTONE_X = 'curveMonotoneX',
//   CURVE_MONOTONE_Y = 'curveMonotoneY',
// }

export type VisualizationResultsTableCellType = {
  row: number;
  column: number;
  value?: string | number;
  valuePrefix?: string;
  valuePostfix?: string;
  th?: boolean;
  align?: 'left' | 'center' | 'right';
  colSpan?: number;
  indent?: number;
};

export enum VisualizationResultsElements {
  SECTION = 'Section',
  TABLE = 'Table',
  CHART = 'Chart',
  ERROR = 'Error',
}

export type VisualizationResultsSectionElement = {
  element: VisualizationResultsElements.SECTION;
  title: string;
  text: string;
};

export type VisualizationResultsErrorElement = {
  element: VisualizationResultsElements.ERROR;
  title: string;
  text: string;
};

export type VisualizationResultsTableElement = {
  element: VisualizationResultsElements.TABLE;
  options: {
    title: string;
    footnotes?: string[];
    caption?: string;
    tableHeadTillRow?: number;
  };
  data: VisualizationResultsTableCellType[];
};

export type VisualizationResultsChartElement = {
  element: VisualizationResultsElements.CHART;
  options: any;
  data: ChartTabularData;
  chart: keyof typeof charts;
};

export type VisualizationResultsElement =
  | VisualizationResultsSectionElement
  | VisualizationResultsErrorElement
  | VisualizationResultsTableElement
  | VisualizationResultsChartElement;

export type VisualizationResultsOptions = {
  title?: string;
  chart?: {
    colors?: string[];
  };
};

export type VisualizationResults = {
  options?: VisualizationResultsOptions;
  elements: VisualizationResultsElement[];
};

type SelectionType = {
  subModel?: ExecutionSetupDTO;
  scenarios: ScenarioDTO[];
  selectedScenarios: BooleanHashMap;
};
type ParametersType = {
  parameters: object;
  valid: boolean;
  displaySubModelId: number;
};

type Step1StateType = {
  filter: ScenarioGridFilter;
  openedSubModel?: ExecutionSetupDTO;
};

export type VisualizationState = {
  modelUuid?: string;
  analyseId?: number;
  selection?: SelectionType;
  parameters?: ParametersType;
  results?: VisualizationResults;
  step1: Step1StateType;
  analyseInitialState?: {
    selection?: SelectionType;
    parameters?: ParametersType;
  };
  analyseDataChanged: boolean;
};

export enum VisualizationActions {
  INIT = 'VISUALIZATION/INIT',
  INIT_ANALYSIS = 'VISUALIZATION/INIT_ANALYSIS',
  INIT_ANALYSIS_DATA = 'VISUALIZATION/INIT_ANALYSIS_DATA',
  UNLOAD_ANALYSIS = 'VISUALIZATION/UNLOAD_ANALYSIS',
  TOGGLE_SELECTION = 'VISUALIZATION/TOGGLE_SELECTION',
  TOGGLE_SUB_MODEL = 'VISUALIZATION/TOGGLE_SUB_MODEL',
  SET_FILTER = 'VISUALIZATION/SET_FILTER',
  SORT_SCENARIOS = 'VISUALIZATION/SORT_SCENARIOS',
  SET_PARAMETERS = 'VISUALIZATION/SET_PARAMETERS',
  TEST_RESULTS = 'VISUALIZATION/TEST_RESULTS',
  FRESH_START = 'VISUALIZATION/FRESH_START',
  DISCARD_CHANGES = 'VISUALIZATION/DISCARD_CHANGES',
}

export type VisualizationInitAction = {type: VisualizationActions.INIT; modelUuid: string};
export type VisualizationInitAnalysis = {
  type: VisualizationActions.INIT_ANALYSIS;
  analyseId: number;
  // state: Partial<VisualizationState>;
};
export type VisualizationInitAnalysisData = {
  type: VisualizationActions.INIT_ANALYSIS_DATA;
  analyseId: number;
  state: Partial<VisualizationState>;
};
export type VisualizationUnloadAnalysis = {
  type: VisualizationActions.UNLOAD_ANALYSIS;
  analyseId: number;
};
export type VisualizationToggleSelectionAction = {
  type: VisualizationActions.TOGGLE_SELECTION;
  subModel: ExecutionSetupDTO;
  scenario: ScenarioDTO;
};
export type VisualizationToggleSubModelAction = {
  type: VisualizationActions.TOGGLE_SUB_MODEL;
  subModel: ExecutionSetupDTO;
};
export type VisualizationSetFilterAction = {
  type: VisualizationActions.SET_FILTER;
  filter: any;
};
export type VisualizationSortScenariosAction = {
  type: VisualizationActions.SORT_SCENARIOS;
  fromIndex: number;
  toIndex: number;
};
export type VisualizationSetParametersAction = {
  type: VisualizationActions.SET_PARAMETERS;
  parameters: object;
  valid: boolean;
  displaySubModelId: number;
};
export type VisualizationTestResults = {
  type: VisualizationActions.TEST_RESULTS;
  results: VisualizationResults;
};

export type VisualizationAction =
  | VisualizationInitAction
  | VisualizationInitAnalysis
  | VisualizationInitAnalysisData
  | VisualizationUnloadAnalysis
  | VisualizationToggleSelectionAction
  | VisualizationToggleSubModelAction
  | VisualizationSetFilterAction
  | VisualizationSortScenariosAction
  | VisualizationSetParametersAction
  | VisualizationTestResults
  | Action<VisualizationActions.FRESH_START>
  | Action<VisualizationActions.DISCARD_CHANGES>;
