import React, {SyntheticEvent} from 'react';
import styles from './ModelUploadFooter.module.scss';
import CancelButton from '../../../core/Buttons/CancelButton';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import {tid} from '../../../../testUtils';
import {getUrlRoute, Routes} from '../../../router/routes';
import {FormRenderProps} from '../../../core/Form';
import {Launch16} from '@carbon/icons-react';

type ModelUploadFooterProps = Partial<FormRenderProps> & {
  isSubmitting?: boolean;
  onClickSubmit?: (event: SyntheticEvent) => void;
  onClickSubmitAndPublish?: (event: SyntheticEvent) => void;
  canPublish?: boolean;
};

const ModelUploadFooter: React.FC<ModelUploadFooterProps> = ({
  isSubmitting = false,
  onClickSubmit,
  onClickSubmitAndPublish,
  canPublish = false,
  valid = true,
  disabled = false,
}) => {
  return (
    <>
      <div className={styles.spacer}></div>
      <div className={styles.container}>
        <div className={styles.buttonsContainer}>
          <CancelButton disabled={isSubmitting} to={getUrlRoute(Routes.MODEL_LIST)} />
          {onClickSubmitAndPublish ? (
            <>
              <SubmitButton active={isSubmitting} disabled={!valid} onClick={onClickSubmit}>
                Save as Draft
              </SubmitButton>

              <SubmitButton
                disabled={!canPublish || !valid}
                active={isSubmitting}
                onClick={onClickSubmitAndPublish}
                endIcon={<Launch16 />}
                {...tid('btn-save-and-publish')}>
                Save and Publish
              </SubmitButton>
            </>
          ) : (
            <>
              <SubmitButton active={isSubmitting} disabled={disabled} onClick={onClickSubmit}>
                Save & proceed
              </SubmitButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModelUploadFooter;
