import React, {useCallback, useState} from 'react';
import {useMounted} from '../../../../../hooks/useMounted';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {useDispatch} from 'react-redux';
import {trackInstanceCanceled} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {scenarioExecutionCancel} from '../../../../../store/scenarioExecution';
import {StopOutline16} from '@carbon/icons-react';

const ScenarioCancel: React.FC<ScenarioActionProps> = ({model, subModel, scenario, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(async () => {
    if (!scenario.lastGroupId) {
      dispatch(messageAdd('No active Instance execution to be canceled found.', MessageTypes.WARNING));
      return;
    }

    setSubmitting(true);

    try {
      await scenarioExecutionCancel(dispatch, scenario.lastGroupId);
      trackInstanceCanceled(model, subModel, scenario);
      dispatch(messageAdd('Execution cancellation request sent', MessageTypes.SUCCESS));
      onSuccess?.();
    } catch (e) {
      dispatch(messageAdd('Failed to cancel scenario execution', MessageTypes.ERROR));
    } finally {
      mounted.current && setSubmitting(false);
    }
  }, [dispatch, mounted, model, subModel, scenario, onSuccess]);

  return (
    <>
      <h2>Cancel Scenario</h2>
      Are you sure you want to cancel the selected scenario execution? After cancellation you can again edit and execute
      scenario.
      <br />
      <br />
      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_CANCEL, 'cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          active={submitting}
          endIcon={<StopOutline16 />}
          {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_CANCEL, 'submit')}>
          Cancel Execution
        </SubmitButton>
      </div>
    </>
  );
};

export default ScenarioCancel;
