import React, {useCallback, useEffect, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from 'react-reactive-form';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {requestSendModelAccessRequested} from '../../../../../store/model';
import {trackModelAccessRequested} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {useDispatch} from 'react-redux';
import {tid} from '../../../../../testUtils';
import RadioControl from '../../../../core/Form/RadioControl';
import {useMounted} from '../../../../../hooks/useMounted';

type ModelRequestAccessDialogProps = {
  model: ModelDTO;
  open: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const reasonOptions = [
  'To develop a local model and/or materials for external use (e.g., HTA submissions)',
  'To create materials for internal use (e.g., training resources)',
  'To inform a strategic policy, resource, or commercial decision',
  'Other',
];

const reasonTextValidator: ValidatorFn = (control: AbstractControl) => {
  return !control.value && control.parent && control.parent.get('reason').value === 'Other' ? {required: true} : null;
};

const ModelRequestAccessDialog: React.FC<ModelRequestAccessDialogProps> = ({model, open, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const form = useForm(
    new FormGroup({
      reason: new FormControl(null, Validators.required),
      reasonText: new FormControl(null, [reasonTextValidator]),
    }),
  );

  useEffect(() => {
    const updateReasonTextValidity = () => form.get('reasonText').updateValueAndValidity();
    form.get('reason').valueChanges.subscribe(updateReasonTextValidity);
    return () => {
      form.get('reason').valueChanges.unsubscribe(updateReasonTextValidity);
    };
  }, [form]);

  const handleSubmit = useCallback(() => {
    if (!form.valid) {
      return;
    }

    setSubmitting(true);
    const value = form.value;

    const reason = [value.reason, value.reasonText].filter(Boolean).join('<br />');

    requestSendModelAccessRequested(model.uuid, reason)
      .then(() => {
        trackModelAccessRequested(model, value.reason, value.reasonText);
        dispatch(
          messageAdd(
            'Model access request email has been sent. You will get an email notification once the model access is granted.',
            MessageTypes.SUCCESS,
          ),
        );
        onSuccess?.();
      })
      .catch(() => {
        dispatch(messageAdd('Failed to request access', MessageTypes.ERROR));
      })
      .finally(() => {
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, mounted, form, model, onSuccess]);

  return (
    <DialogGeneric
      open={open}
      title="Request Access"
      PaperProps={tid('dialog-request-access') as {}}
      childrenAsText={false}>
      <Form
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <RadioControl label="Reason" name="reason" options={reasonOptions} />
              <TextFieldControl
                label="Please share more details about your request."
                name="reasonText"
                customErrorMessages={{required: 'This field is required.'}}
                TextFieldProps={{multiline: true, rows: 3}}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} {...tid('dialog-request-access', 'cancel')} />
                <SubmitButton active={submitting} {...tid('dialog-request-access', 'submit')}>
                  Request Access
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </DialogGeneric>
  );
};

export default ModelRequestAccessDialog;
