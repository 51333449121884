import React, {useCallback, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {modelUpdate} from '../../../../../../store/model';
import {trackModelMetadataUpdated} from '../../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import ModelEditMetadataForm from './ModelEditMetadataForm';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import {useNameCollisionCheckDialog} from '../ModelEditSuffix/useNameCollisionCheckDialog';
import {useMounted} from '../../../../../../hooks/useMounted';
import {ModelActionProps} from '../../../../actionMenuTypes';

const ModelEditMetadataSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const {uuid} = model;
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);

  const {checkFutureName, DialogComponent: NameCollisionDialogComponent} = useNameCollisionCheckDialog();

  const trackMetadataChange = useCallback((updatedModel: ModelDTO) => {
    trackModelMetadataUpdated(updatedModel);
  }, []);

  const updateModel = useCallback(
    (updatedModel: ModelDTO) => {
      const savePromise = modelUpdate(dispatch, uuid, updatedModel);
      savePromise
        .then(() => {
          dispatch(messageAdd('Metadata saved', MessageTypes.SUCCESS));
        })
        .catch(() => null);
      return savePromise;
    },
    [dispatch, uuid],
  );

  const renderFooterButtons = (fromObject: any) => {
    return (
      <div className="buttonsContainer">
        <CancelButton onClick={onCancel} disabled={submitting} />
        <SubmitButton active={submitting} disabled={!fromObject.valid} {...tid('btn-update-metadata')}>
          Update Metadata
        </SubmitButton>
      </div>
    );
  };

  const handleSubmit = useCallback(
    (updatedModel: ModelDTO) => {
      setSubmitting(true);
      return checkFutureName(updatedModel, model)
        .then((updatedModelWithSuffix) => {
          return updateModel(updatedModelWithSuffix)
            .then((res) => {
              trackMetadataChange(updatedModelWithSuffix);
              return res;
            })
            .then(onSuccess);
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    },
    [updateModel, mounted, onSuccess, trackMetadataChange, checkFutureName, model],
  );

  return (
    <>
      <ModelEditMetadataForm isEdit={true} model={model} onSubmit={handleSubmit} buttons={renderFooterButtons} />
      {NameCollisionDialogComponent}
    </>
  );
};

export default ModelEditMetadataSideBarModal;
