import React from 'react';
import {useToggleBoolean} from '../../../../../hooks/useToggleBoolean';
import {SharedModelCardProps} from './types';
import {isModelDTO, StoredModel} from '../../../../../store/model';
import ModelCardRestricted from './ModelCardRestricted';
import {ModelDTO} from 'hemwb-api';
import ModelCardExpandedDynamicView from './ModelCardExpandedDynamicView';
import ModelCardCollapsedDynamicView from './ModelCardCollapsedDynamicView';

export type ModelCardDynamicViewProps = Omit<SharedModelCardProps, 'model'> & {
  expanded?: boolean;
  model: StoredModel | null;
  toggleVersioning: () => void;
};

export const ModelCardDynamicView: React.FC<ModelCardDynamicViewProps> = ({
  expanded,
  model,
  toggleVersioning,
  ...restProps
}) => {
  const [isExpanded, toggleIsExpanded] = useToggleBoolean(!!expanded);
  const isExpandable = expanded !== undefined;

  if (model && !isModelDTO(model)) {
    return <ModelCardRestricted />;
  }

  if (isExpanded) {
    return (
      <ModelCardExpandedDynamicView
        model={model as ModelDTO | null}
        toggleVersion={toggleVersioning}
        onClickCollapse={isExpandable ? toggleIsExpanded : undefined}
        {...restProps}
      />
    );
  }

  return (
    <ModelCardCollapsedDynamicView
      model={model as ModelDTO | null}
      onClickExpand={isExpandable ? toggleIsExpanded : undefined}
      {...restProps}
    />
  );
};
