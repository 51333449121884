import React, {ReactNode, SyntheticEvent, useMemo} from 'react';
import {Tooltip} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Permission} from '../../../permissions/types';
import ListItem from '../../core/materialui/ListItem/ListItem';

type SideBarButtonItemProps = {
  permission?: Permission;
  disabled?: boolean;
  icon?: React.ReactElement;
  text: string | ReactNode;
  onClick: (event: SyntheticEvent) => void;
  active?: boolean;
  style?: React.CSSProperties;
  className?: string;
  red?: boolean;
  disabledExternalModel?: boolean;
  disabledToolTipName?: string;
};

const SideBarButtonItem: React.FC<SideBarButtonItemProps> = ({
  disabledExternalModel,
  disabledToolTipName,
  active = false,
  icon,
  red = false,
  style,
  text,
  ...rest
}) => {
  const mergedStyles = useMemo(() => {
    return {
      color: red ? '#E9312F' : 'inherit',
      ...style,
    };
  }, [style, red]);

  return (
    <ListItem
      style={mergedStyles}
      // @ts-ignore
      button={!disabledExternalModel}
      component="div"
      selected={active}
      {...rest}
      disableGutters
      selectedLeftIndicator>
      {icon && (
        <Tooltip title={!disabledExternalModel ? text : disabledToolTipName}>
          <ListItemIcon>{icon}</ListItemIcon>
        </Tooltip>
      )}
      <ListItemText primary={text} style={{cursor: disabledExternalModel ? 'default' : 'inherit '}} />
    </ListItem>
  );
};

export default SideBarButtonItem;
