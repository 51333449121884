import React from 'react';
import {FormControl, FormGroup, ValidatorFn, Validators} from 'react-reactive-form';
import {validateNumberByDataType} from '../validators/validateNumberByDataType';
import {
  DateControl,
  isFormArray,
  TextFieldControl,
  validateHigherThan,
  validateInteger,
  validateLowerThan,
  validateNumber,
} from '../../../Form';
import {Grid} from '@material-ui/core';
import GridItem from '../../../Form/GridItem';
import {InputType, NumberTypeProps, rangeInputTypes, StringNumberTypeProps} from '../types';

type NumberInputPrimitiveProps = {
  formGroup: FormGroup;
  type: keyof typeof rangeInputTypes;
  index?: number;
};

export const NumberInputPrimitive: React.FC<NumberInputPrimitiveProps> = ({formGroup, type, index}) => {
  const {controls} = formGroup;

  const Component = type === InputType.DATE ? DateControl : TextFieldControl;
  const defaultValueControl = isFormArray(controls.defaultValue)
    ? index === undefined
      ? null
      : controls.defaultValue.controls[index]
    : controls.defaultValue;

  return (
    <Grid container direction="column">
      <GridItem>
        <Component label="Minimum" control={controls.min} name="min" />
      </GridItem>
      <GridItem>
        <Component label="Maximum" control={controls.max} name="max" />
      </GridItem>
      {defaultValueControl && (
        <GridItem>
          <Component label="Default Value" control={defaultValueControl} name="defaultValue" />
        </GridItem>
      )}
    </Grid>
  );
};

export const numberInputPrimitiveControlBuilder = (
  initialValues: NumberTypeProps | StringNumberTypeProps = {},
  dataType?: keyof typeof rangeInputTypes,
) => {
  let dataTypeValidator: ValidatorFn = () => null;
  if (dataType) {
    if (dataType === InputType.INTEGER) {
      dataTypeValidator = validateInteger;
    } else if (dataType === InputType.DATE) {
    } else {
      dataTypeValidator = validateNumber;
    }
  }

  const minControl = new FormControl(initialValues.min, [
    Validators.required,
    dataTypeValidator,
    validateNumberByDataType,
  ]);
  const maxControl = new FormControl(initialValues.max, [
    Validators.required,
    validateHigherThan(minControl, true),
    dataTypeValidator,
    validateNumberByDataType,
  ]);
  const defaultValueControl = new FormControl(initialValues.defaultValue, [
    Validators.required,
    validateHigherThan(minControl, true),
    validateLowerThan(maxControl, true),
    dataTypeValidator,
    validateNumberByDataType,
  ]);

  const controls = {
    min: minControl,
    max: maxControl,
    defaultValue: defaultValueControl,
  };

  controls['min'].valueChanges.subscribe(() => controls['max'].updateValueAndValidity());
  controls['min'].valueChanges.subscribe(() => controls['defaultValue'].updateValueAndValidity());
  controls['max'].valueChanges.subscribe(() => controls['defaultValue'].updateValueAndValidity());
  [controls['min'], controls['max']].forEach((c) => {
    if (c.parent) {
      const defaultValueControl = c.parent.get('defaultValue');
      if (isFormArray(defaultValueControl)) {
        defaultValueControl.controls.forEach((control) => control.updateValueAndValidity());
      } else {
        defaultValueControl.updateValueAndValidity();
      }
    } else {
      controls['defaultValue'].updateValueAndValidity();
    }
  });

  return controls;
};
