import React, {useCallback, useMemo, useState} from 'react';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import ScenarioForm, {ScenarioFormValues} from './ScenarioForm';
import {scenarioCreate, scenarioUpdateInputs} from '../../../../../store/scenario';
import {trackInstanceCreated} from '../../../../../tracking/tracking';
import {inputDefinitionFormGenerator} from '../../../../core/ModelInput/InputDefinition/inputDefinitionFormGenerator';
import {ModelInputType} from 'hemwb-api';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {useModelInput} from '../../../../../store/modelInputs';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {getScenarioDefaultParameters} from '../../../../core/ModelInput/ScenarioParameters/getScenarioDefaultParameters';

const ScenarioAdd: React.FC<SubModelActionProps> = ({model, subModel, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const input = useModelInput(subModel.id);

  const data = useMemo(() => {
    if (!input) {
      return [];
    }
    return input.type === ModelInputType.JSON ? [] : JSON.parse(input.value)?.definitions || [];
  }, [input]);

  const form = inputDefinitionFormGenerator(data);
  let validInput;

  if (input) {
    if (input.type === ModelInputType.JSON) {
      validInput = true;
    } else {
      validInput = form.valid;
    }
  }

  const handleSubmit = useCallback(
    async (values: ScenarioFormValues, value?: object) => {
      try {
        setSubmitting(true);
        const createdScenario = await scenarioCreate(dispatch, subModel.id, values);
        await scenarioUpdateInputs(dispatch, createdScenario.id, {
          modelInputId: input!.id,
          value: JSON.stringify(value || getScenarioDefaultParameters(input!) || {}),
          addNewInstance: true,
        });
        trackInstanceCreated(model, subModel, createdScenario);
        dispatch(messageAdd('Instance has been created', MessageTypes.SUCCESS));
        onSuccess?.();
      } catch (e) {
      } finally {
        mounted.current && setSubmitting(false);
      }
    },
    [dispatch, model, subModel, input, mounted, onSuccess],
  );

  if (!input) {
    return <SuspenseNull />;
  }
  return (
    <>
      <h2>Add Instance</h2>
      {validInput ? (
        <ScenarioForm
          model={model}
          subModel={subModel}
          input={input}
          submitting={submitting}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      ) : (
        <>
          <span className="colorError">Input Definition is not valid and needs to be fixed before proceeding.</span>
          <div className="buttonsContainer">
            <br />
            <br />
            <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ADD, 'cancel')} />
          </div>
        </>
      )}
    </>
  );
};

export default ScenarioAdd;
