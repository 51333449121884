import {ScenarioExecutionStatusUpdateDTO} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {
  requestScenarioExecutionCancel,
  requestScenarioExecutionCreate,
  requestScenarioExecutionExecute,
  requestScenarioExecutionList,
  requestScenarioGroupExecutionList,
} from './api';

export enum ScenarioExecutionActions {
  UPDATE_STATUS = 'SCENARIO_EXECUTION/UPDATE_STATUS',
  REQUEST_LIST = 'SCENARIO_EXECUTION/REQUEST_LIST',
  REQUEST_GROUP_LIST = 'SCENARIO_EXECUTION/REQUEST_GROUP_LIST',
  REQUEST_CREATE = 'SCENARIO_EXECUTION/REQUEST_CREATE',
  REQUEST_EXECUTE = 'SCENARIO_EXECUTION/REQUEST_EXECUTE',
  REQUEST_CANCEL = 'SCENARIO_EXECUTION/REQUEST_CANCEL',
}

export type ScenarioExecutionActionUpdateStatus = {
  type: ScenarioExecutionActions.UPDATE_STATUS;
  statusUpdate: ScenarioExecutionStatusUpdateDTO;
};

export type ScenarioExecutionAction =
  | ScenarioExecutionActionUpdateStatus
  | AsyncAction<
      ScenarioExecutionActions.REQUEST_LIST,
      Await<ReturnType<typeof requestScenarioExecutionList>>,
      Parameters<typeof requestScenarioExecutionList>
    >
  | AsyncAction<
      ScenarioExecutionActions.REQUEST_GROUP_LIST,
      Await<ReturnType<typeof requestScenarioGroupExecutionList>>,
      Parameters<typeof requestScenarioGroupExecutionList>
    >
  | AsyncAction<
      ScenarioExecutionActions.REQUEST_CREATE,
      Await<ReturnType<typeof requestScenarioExecutionCreate>>,
      Parameters<typeof requestScenarioExecutionCreate>
    >
  | AsyncAction<
      ScenarioExecutionActions.REQUEST_EXECUTE,
      Await<ReturnType<typeof requestScenarioExecutionExecute>>,
      Parameters<typeof requestScenarioExecutionExecute>
    >
  | AsyncAction<
      ScenarioExecutionActions.REQUEST_CANCEL,
      Await<ReturnType<typeof requestScenarioExecutionCancel>>,
      Parameters<typeof requestScenarioExecutionCancel>
    >;
