import {GridActions, GridState, GridStateUpdateActionCreator, Order} from './types';

const buildGridStateFieldUpdateActionCreator = <K extends keyof GridState, F = any>(gridId: string, field: K) => (
  value: GridState<F>[K],
) => ({
  type: GridActions.UPDATE_STATE as GridActions.UPDATE_STATE,
  gridId,
  update: {
    [field]: value,
  },
});

export const buildGridStateActionCreator = <F>(gridId: string): GridStateUpdateActionCreator<F> => ({
  page: buildGridStateFieldUpdateActionCreator(gridId, 'page'),
  order: buildGridStateFieldUpdateActionCreator(gridId, 'order'),
  orderBy: buildGridStateFieldUpdateActionCreator(gridId, 'orderBy'),
  rowsPerPage: buildGridStateFieldUpdateActionCreator(gridId, 'rowsPerPage'),
  expandedRowId: buildGridStateFieldUpdateActionCreator(gridId, 'expandedRowId'),
  filter: buildGridStateFieldUpdateActionCreator(gridId, 'filter'),
  search: buildGridStateFieldUpdateActionCreator(gridId, 'search'),
  viewMyModels: buildGridStateFieldUpdateActionCreator(gridId, 'viewMyModels'),
  dynamicPageViewMyModels: buildGridStateFieldUpdateActionCreator(gridId, 'dynamicPageViewMyModels'),
});

const defaultState: GridState = {
  order: Order.ASC,
  orderBy: null,
  expandedRowId: null,
  page: 0,
  rowsPerPage: 20,
  filter: null,
  search: '',
  viewMyModels: false,
  dynamicPageViewMyModels: false,
};

export const buildInitialGridState = (customDefaultState: Partial<GridState> = {}): GridState =>
  Object.assign({}, defaultState, customDefaultState);
