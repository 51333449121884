import {useEffect, useState} from 'react';

const defaultValidator = (value: any) => !!value;

export const useLastValidValue = <T extends any>(value: T, validator = defaultValidator) => {
  const [stored, setStored] = useState(value);
  useEffect(() => {
    if (validator(value)) {
      setStored(value);
    }
  }, [value, validator]);

  return stored;
};
