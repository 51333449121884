import React, {useCallback, useEffect, useState} from 'react';
import {
  useModelDetail,
  approveModelAccessRequest,
  isModelExternal,
  approveFileAccessRequest,
} from '../../../../store/model';
import {Routes, useMatchedRoute} from '../../../router/routes';
import DefaultPage from '../../../layout/common/DefaultPage';
import {ModelActionMenu} from '../common/ModelActionMenu/ModelActionMenu';
import styles from './ModelDetail.module.scss';
import {modelListColumns} from '../list/ModelList';
import TableWithSortAndPagination from '../../../core/TableWithSortAndPagination';
import {useGridState} from '../../../../store/grid';
import {Container} from '@material-ui/core';
import {CloudServices} from '@carbon/pictograms-react';
import PageHeaderTitle from '../../../core/Header/PageHeader/PageHeaderTitle';
import ModelOverview from '../common/ModelOverview/ModelOverview';
import ModelAccessConfirmationDialog from '../common/ModelRequestAccess/ModelAccessConfirmationDialog';
import {trackModelAccessGranted} from '../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../store/message';
import {useDispatch} from 'react-redux';
import DialogProgress from '../../../core/dialog/DialogProgress';
import ModelOverviewTH from '../common/ModelOverview/ModelOverviewTH';

const modelGridColumns = modelListColumns.map((column) => ({
  ...column,
  sortable: false,
}));

const ModelDetail: React.FC = () => {
  const dispatch = useDispatch();
  const {uuid} = useMatchedRoute(Routes.MODEL_DETAIL);
  const model = useModelDetail(uuid);
  const gridState = useGridState();
  const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
  const role = queryParams.get('role');
  const tab = queryParams.get('tab');
  const fileUuid = queryParams.get('file');
  const fileName = queryParams.get('fileName');
  const requesterUserId = queryParams.get('userId');
  const [open, setOpen] = useState(false);
  const [requested, setRequested] = useState(false);
  const [submitText, setSubmitText] = useState('');
  const externalModel = isModelExternal(model);
  const provideAccess = useCallback(() => {
    if (model && fileUuid && fileName && requesterUserId && !requested) {
      approveFileAccessRequest(model.uuid, fileUuid, requesterUserId, fileName)
        .then(() => {
          setSubmitText('');
          setOpen(true);
          setRequested(true);
        })
        .catch(() => {
          setSubmitText('');
          setRequested(true);
          dispatch(messageAdd('Failed to Grant File access', MessageTypes.ERROR));
        });
    }

    if (requesterUserId && role && model && !requested) {
      setSubmitText('Updating Access');
      approveModelAccessRequest(model.uuid, role, requesterUserId)
        .then(() => {
          trackModelAccessGranted(model);
          setSubmitText('');
          setOpen(true);
          setRequested(true);
        })
        .catch(() => {
          setSubmitText('');
          setRequested(true);
          dispatch(messageAdd('Failed to Grant Model access', MessageTypes.ERROR));
        });
    }
  }, [dispatch, requesterUserId, role, model, requested, fileUuid, fileName]);

  useEffect(() => {
    provideAccess();
  }, [provideAccess]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    setRequested(true);
  }, []);
  return (
    <>
      <DefaultPage style={{marginLeft: '6vw', overflow: 'hidden'}}>
        <div className={styles.modelDetail}>
          <Container maxWidth={false} className="pageHeader">
            <PageHeaderTitle
              title="Model Detail"
              label="Place for previewing and editing Model Files, Metadata and linkage"
              Icon={<CloudServices className="icon32" />}
              IconNextToTitle
            />
          </Container>
          {model && (
            <div className={styles.content}>
              <div className="listPanel">
                {/*  <Button
                  disabled={externalModel}
                  component={Link}
                  to={getUrlRoute(Routes.MODEL_UPLOAD, {uuid})}
                  variant="contained"
                  color="primary"
                  {...tid('btn', 'create-new-model')}>
                  Add Model Entry Based on this Metadata +
                </Button>*/}
              </div>
              <TableWithSortAndPagination
                getRowId={(modelRow) => modelRow.uuid}
                rows={[model]}
                columns={modelGridColumns}
                gridState={gridState}
                hidePagination
              />
              <br />
              {externalModel ? (
                <ModelOverviewTH model={model} selectedTab={tab ? tab : 'text'} expanded={true} />
              ) : (
                <ModelOverview model={model} selectedTab={tab ? tab : 'text'} expanded={true} />
              )}
            </div>
          )}
        </div>
      </DefaultPage>
      <DialogProgress open={!!submitText} title="Setting up your model permissions" text={submitText} />
      <ModelActionMenu model={model || undefined} />

      {model && (
        <ModelAccessConfirmationDialog
          requesterUserId={requesterUserId}
          modelUuid={model?.uuid}
          role={role ? role : ''}
          fileName={fileName}
          open={open}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ModelDetail;
