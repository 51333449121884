import {AbstractControl, FormArray, FormGroup} from 'react-reactive-form';
import {InputGenerator, InputType, MultiSelectInputAttributes} from '../types';
import {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {selectItemPrimitiveControlBuilder} from '../primitives/SelectItemPrimitive';
import {SingleSelectInput} from './SingleSelectInput';

type ControlsMap = {[key in keyof Required<MultiSelectInputAttributes>]: AbstractControl};

export const multiSelectInputGenerator: InputGenerator<InputType.MULTI_SELECT> = {
  type: InputType.MULTI_SELECT,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      options: new FormArray(
        (initialValues.options || []).map((attr) => new FormGroup(selectItemPrimitiveControlBuilder(attr, false))),
      ),
    } as ControlsMap);

    return fg;
  },
  render: SingleSelectInput,
};
