import React, {useCallback, useState} from 'react';
import {useMounted} from '../../../../../hooks/useMounted';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {scenarioDelete} from '../../../../../store/scenario';
import {useDispatch} from 'react-redux';
import {trackInstanceDeleted} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {TrashCan16} from '@carbon/icons-react';
import {ScenarioActionProps} from '../../../actionMenuTypes';

const ScenarioDelete: React.FC<ScenarioActionProps> = ({model, subModel, scenario, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      await scenarioDelete(dispatch, scenario.id);
      trackInstanceDeleted(model, subModel, scenario);
      dispatch(messageAdd('Scenario deleted', MessageTypes.SUCCESS));
      onSuccess?.();
    } catch (e) {
    } finally {
      mounted.current && setSubmitting(false);
    }
  }, [dispatch, mounted, model, subModel, onSuccess, scenario]);

  return (
    <>
      <h2 className="dialogTitle">Delete Scenario</h2>
      <p>Are you sure you want to delete the selected scenario? The deletion is permanent.</p>
      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton
          className="redButton"
          onClick={onCancel}
          {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_DELETE, 'cancel')}
        />
        <SubmitButton
          className="redButton"
          onClick={handleSubmit}
          active={submitting}
          endIcon={<TrashCan16 />}
          {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_DELETE, 'submit')}>
          Delete
        </SubmitButton>
      </div>
    </>
  );
};

export default ScenarioDelete;
