import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {BitbucketActions} from './types';
import {
  requestBranches,
  requestBrowse,
  requestDiff,
  requestHistory,
  requestProject,
  requestProjects,
  requestRepositories,
  requestRepository,
  requestSaveFile,
} from './api';

const bitbucketLoadProjectsActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_PROJECTS);
export const bitbucketLoadProjects = createAsyncActionExecutor(bitbucketLoadProjectsActionsCreator, requestProjects);

const bitbucketLoadProjectActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_PROJECT);
export const bitbucketLoadProject = createAsyncActionExecutor(bitbucketLoadProjectActionsCreator, requestProject);

const bitbucketLoadRepositoriesActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_REPOSITORIES);
export const bitbucketLoadRepositories = createAsyncActionExecutor(
  bitbucketLoadRepositoriesActionsCreator,
  requestRepositories,
);

const bitbucketLoadRepositoryActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_REPOSITORY);
export const bitbucketLoadRepository = createAsyncActionExecutor(
  bitbucketLoadRepositoryActionsCreator,
  requestRepository,
);

const bitbucketLoadBranchesActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_BRANCHES);
export const bitbucketLoadBranches = createAsyncActionExecutor(bitbucketLoadBranchesActionsCreator, requestBranches);

const bitbucketLoadBrowseActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_BROWSE);
export const bitbucketLoadBrowse = createAsyncActionExecutor(bitbucketLoadBrowseActionsCreator, requestBrowse);

const bitbucketLoadDiffActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_DIFF);
export const bitbucketLoadDiff = createAsyncActionExecutor(bitbucketLoadDiffActionsCreator, requestDiff);

const bitbucketLoadHistoryActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_HISTORY);
export const bitbucketLoadHistory = createAsyncActionExecutor(bitbucketLoadHistoryActionsCreator, requestHistory);

const bitbucketSaveFileActionsCreator = buildAsyncActionsCreator(BitbucketActions.REQUEST_SAVE_FILE);
export const bitbucketSaveFile = createAsyncActionExecutor(bitbucketSaveFileActionsCreator, requestSaveFile);
