import React, {useCallback} from 'react';
import {StopOutline16} from '@carbon/icons-react';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import {isScenarioRunning} from '../../../../../store/scenario';
import {scenarioExecutionCancel} from '../../../../../store/scenarioExecution';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {canSaveAnalysis} from '../../../../../permissions/usePermissions';

type VisualizationCancelActionMenuButtonProps = {
  scenario?: ScenarioDetailDTO;
  model?: ModelDTO;
};

const VisualizationCancelActionMenuButton: React.FC<VisualizationCancelActionMenuButtonProps> = ({model, scenario}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const disabled = !model || !scenario || !isScenarioRunning(scenario.status);

  const handleClick = useCallback(async () => {
    scenario!.lastGroupId && (await scenarioExecutionCancel(dispatch, scenario!.lastGroupId));
    history.push(getUrlRoute(Routes.VISUALIZATION_STEP_2, {modelUuid: model!.uuid, scenarioId: scenario!.id}));
  }, [dispatch, history, model, scenario]);

  return (
    <SideBarButtonItem
      red
      permission={canSaveAnalysis(model)}
      disabled={disabled}
      onClick={handleClick}
      icon={<StopOutline16 />}
      text="Cancel"
    />
  );
};

export default VisualizationCancelActionMenuButton;
