import React from 'react';
import {Tab as UITab, Tabs as UITabs} from '@material-ui/core';
import {Section, TabType, ViewMode} from './types';
import {isTabFromSection} from './utils';
import Tab from './Tab';

type TabsProps = {
  tabs: TabType[];
  tab?: TabType;
  onTabChange: (tab: TabType) => void;
  section: Section;
  onSectionChange: (section: Section) => void;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  showViewModeButtons: boolean;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  tab,
  onTabChange,
  section,
  onSectionChange,
  viewMode,
  setViewMode,
  showViewModeButtons,
}) => {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <UITabs
          value={section}
          indicatorColor="primary"
          textColor="inherit"
          className="tabs-secondary"
          onChange={(event: React.ChangeEvent<{}>, newTab) => onSectionChange(newTab)}>
          <UITab value={Section.OVERVIEW} label={Section.OVERVIEW} />
          <UITab value={Section.SHEETS} label={Section.SHEETS} />
          <UITab value={Section.MACROS} label={Section.MACROS} />
        </UITabs>

        {showViewModeButtons && (
          <UITabs
            value={viewMode}
            indicatorColor="primary"
            textColor="inherit"
            className="tabs-secondary"
            onChange={(event: React.ChangeEvent<{}>, newTab) => setViewMode(newTab)}>
            <UITab value={ViewMode.SINGLE_PANE} label={ViewMode.SINGLE_PANE} />
            <UITab value={ViewMode.DOUBLE_PANE} label={ViewMode.DOUBLE_PANE} />
          </UITabs>
        )}
      </div>
      <br />
      {section !== Section.OVERVIEW && (
        <UITabs
          variant="scrollable"
          value={tab && isTabFromSection(section)(tab) ? tab : false}
          onChange={(event: React.ChangeEvent<{}>, newTab: TabType) => onTabChange(newTab)}
          indicatorColor="primary"
          textColor="inherit"
          className="tabs-secondary small">
          {tabs.filter(isTabFromSection(section)).map((tab) => (
            <Tab key={`${tab.name}${tab.type}`} value={tab} />
          ))}
        </UITabs>
      )}
    </>
  );
};

export default Tabs;
