import React from 'react';
import {MultipleCheckboxFilterProps, OptionProps} from './types';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {OptionFilter, optionStyles} from './OptionFilter';
import {tid} from '../../../testUtils';
import xor from 'lodash/xor';

const CheckboxOption: React.FC<OptionProps<any>> = <T,>(props: OptionProps<T>) => {
  const {
    option,
    optionLabel,
    optionValue,
    optionValueMatcher,
    handler,
    getDataTestIdPostfixFromValue = (value: any) => value,
  } = props;
  const {value, onChange, type, ...otherHandlerProps} = handler();
  const newValue = optionValue(option);
  const selected = optionValueMatcher ? value.some((x: T) => optionValueMatcher(option, x)) : value.includes(newValue);
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...otherHandlerProps}
          value={true}
          checked={selected}
          onChange={() =>
            onChange(
              selected
                ? value.filter((existingValue: T) =>
                    optionValueMatcher ? !optionValueMatcher(existingValue, newValue) : existingValue !== newValue,
                  )
                : [...value, newValue],
            )
          }
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          classes={{root: optionStyles.checkboxRoot}}
          inputProps={tid('multiple-checkbox-option-checkbox', getDataTestIdPostfixFromValue(newValue)) as {}}
        />
      }
      label={<span>{optionLabel(option)}</span>}
      classes={{root: optionStyles.label, label: optionStyles.optionLabel}}
      {...tid('multiple-checkbox-option-label', getDataTestIdPostfixFromValue(newValue))}
    />
  );
};

export const MultipleCheckboxFilter: React.FC<MultipleCheckboxFilterProps<any>> = <T,>(
  props: MultipleCheckboxFilterProps<T>,
) => (
  <OptionFilter
    {...props}
    optionComponent={CheckboxOption}
    // @ts-ignore
    isEmpty={(value) => value.length === props.control.formState.length && !xor(props.control.formState, value).length}
    // @ts-ignore
    buildEmptyValue={() => props.control.formState}
    {...tid('multiple-checkbox-filter', props.label)}
  />
);
