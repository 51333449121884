import React, {useCallback, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../testUtils';
import {Error16} from '@carbon/icons-react';
import {UserBaseDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {requestUpdateShareFileAccess} from '../../../../../store/fileShareAccess/api';
import {messageAdd, MessageTypes} from '../../../../../store/message';

type ModelFileShareRemoveDialogProps = {
  open: boolean;
  user: UserBaseDTO;
  fileUuid: string;
  fileName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const ModelFileShareRemoveDialog: React.FC<ModelFileShareRemoveDialogProps> = ({
  open,
  user,
  fileUuid,
  fileName,
  onSuccess,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    requestUpdateShareFileAccess(fileUuid, user.id, 'INACTIVE').then(() => {
      onSuccess?.();
      dispatch(messageAdd('File Access removed for ' + user.firstName + ', ' + user.lastName, MessageTypes.SUCCESS));
    });
    setSubmitting(false);
  }, [onSuccess, dispatch, fileUuid, user]);

  return (
    <DialogGeneric open={open} title="" PaperProps={tid('dialog-request-access') as {}} childrenAsText={false}>
      <>
        <div>
          Do you want to stop sharing {fileName} with {user.firstName}, {user.lastName} ?
        </div>
        <div className="buttonsContainer">
          <br />
          <br />
          <CancelButton onClick={onCancel} {...tid('dialog-sharing-stop', 'Cancel')} />
          <SubmitButton
            endIcon={<Error16 />}
            onClick={handleSubmit}
            active={submitting}
            {...tid('dialog-sharing-stop', 'Stop Sharing')}>
            Stop Sharing
          </SubmitButton>
        </div>
      </>
    </DialogGeneric>
  );
};

export default ModelFileShareRemoveDialog;
