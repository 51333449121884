import React from 'react';
import clsx from 'clsx';
import {Card, CardHeader, IconButton} from '@material-ui/core';
import {getModelGlobalAsTextValue, isModelGlobal, isModelRetired} from '../../../../../store/model';
import styles from './ModelCardCollapsed.module.scss';
import {tid} from '../../../../../testUtils';
import {EuropeAfrica, Networking_03 as Networking03, ResetHybridCloud} from '@carbon/pictograms-react';
import {Maximize16} from '@carbon/icons-react';
import {SharedModelCardProps} from './types';
import ModelTextOverviewTile from '../ModelTextOverview/ModelTextOverviewTile';

type ModelCardCollapsedDynamicViewProps = SharedModelCardProps & {
  onClickExpand?: () => void;
};

const ModelCardCollapsedDynamicView: React.FC<ModelCardCollapsedDynamicViewProps> = ({
  onClickExpand,
  model,
  selectable = true,
  className,
  current = false,

  ...restProps
}) => {
  return (
    <Card
      {...restProps}
      className={clsx('card', styles.container, className, {
        [styles.notSelectable]: !selectable,
        [styles.retired]: isModelRetired(model),
        [styles.selectable]: !!restProps.onClick,
        [styles.current]: current,
      })}>
      <CardHeader
        disableTypography
        title={
          <>
            <div style={{padding: '10px'}}>
              {model && isModelGlobal(model) ? (
                <ModelTextOverviewTile
                  label=" "
                  value={getModelGlobalAsTextValue(model)}
                  Icon={<EuropeAfrica />}
                  className={styles.tileItem}
                />
              ) : (
                <ModelTextOverviewTile
                  label=" "
                  value={model?.metadata.country}
                  Icon={<EuropeAfrica />}
                  className={styles.tileItem}
                />
              )}
              <ModelTextOverviewTile
                label=" "
                value={model?.metadata.version_release_of_model}
                Icon={<ResetHybridCloud />}
                className={styles.tileItem}
              />
              <ModelTextOverviewTile
                label=""
                value={model?.metadata.version_log}
                Icon={<Networking03 />}
                className={styles.tileItem}
              />
            </div>
          </>
        }
        action={
          <>
            {onClickExpand && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  onClickExpand();
                }}
                {...tid('model-card-expand', model?.uuid || '')}>
                <Maximize16 />
              </IconButton>
            )}
          </>
        }
      />
    </Card>
  );
};

export default ModelCardCollapsedDynamicView;
