import {BitbucketApi, BrowseRepoRequest, GetDiffRequest, GetHistoryRequest, SaveFileContentRequest} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const bbApi = new BitbucketApi(configuration);

export const requestProjects = () => bbApi.getAllProjects({});
export const requestProject = (projectName: string) => bbApi.getProject({projectName});
export const requestRepositories = (projectName: string) => bbApi.getAllRepos({projectName});
export const requestRepository = (projectName: string, repoName: string) => bbApi.getRepo({projectName, repoName});
export const requestBranches = (projectName: string, repoName: string) => bbApi.getBranches({projectName, repoName});
export const requestBrowse = (param: BrowseRepoRequest) => bbApi.browseRepo(param);
export const requestDiff = (param: GetDiffRequest) => bbApi.getDiff(param);
export const requestHistory = (param: GetHistoryRequest) => bbApi.getHistory(param);
export const requestSaveFile = (param: SaveFileContentRequest) => bbApi.saveFileContent(param);
