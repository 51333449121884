import {Button, Link, MenuItem, Tab, Tabs, Typography, Menu} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import React, {MouseEvent, useMemo, useState, useCallback, useEffect} from 'react';
import styles from './NavBar.module.scss';
import {useAuth} from '../../../store/auth';
import {ReactComponent as IconLogo} from '../../../assets/icons/logo.svg';
import {getUrlRoute, Routes, getOrigin} from '../../router/routes';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {fullNameWestern} from '../../../store/user/utils';
import {TextOrProgress} from '../../core/textOrProgress/TextOrProgress';
import {useAboutDialog} from '../../core/AboutDialog/AboutDialog';
import {useSettings} from '../../../store/appData';
import {messageAdd, MessageTypes} from '../../../store/message';
import {useHistory} from 'react-router';
import {
  deleteNotificationAction,
  useNotifications,
  requestLatestNotifications,
  userNotificationsSelector,
  resetLatestNotificationFiled,
} from '../../../store/notifications';
import {useSelector} from '../../../store/rootReducer';
import {tid} from '../../../testUtils';
import {canCreateModel, canCreateExternalUser, isAdmin} from '../../../permissions/usePermissions';
import {NotificationDTO} from 'hemwb-api';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../../core/TableWithSortAndPagination';
import Tour from '../../pages/guidedTour/Tour';
import 'driver.js/dist/driver.css';
import {useLiveNotificationsUpdates} from '../../../websocket/useLiveNotificationsUpdates';

export const NavBar: React.FC = () => {
  useLiveNotificationsUpdates();
  const {logout, auth} = useAuth();
  const dispatch = useDispatch();
  const user = auth.loggedInUser;
  const [userMenuAnchor, setUserMenuAnchor] = useState<Element | null>(null);
  const [helpMenuAnchor, setHelpMenuAnchor] = useState<Element | null>(null);
  const [notificationMenuAnchor, setNotificationMenuAnchor] = useState<Element | null>(null);
  const [showTour, setShowTour] = useState(false);
  const [allNotificationList, setAllNotificationList] = useState<NotificationDTO[]>();
  const {pathname} = useLocation();
  const history = useHistory();
  const {openAboutDialog, AboutDialog} = useAboutDialog();
  const settings = useSettings();
  const documentation = settings?.documentation;
  const admin = isAdmin();
  const modelCreator = canCreateModel();
  const externalUserCreator = canCreateExternalUser();
  const tabLinks = useMemo(() => {
    return [
      ...(modelCreator ? [['Add Model Entry', Routes.MODEL_UPLOAD]] : []),
      ['Model Entries', Routes.MODEL_LIST],
      ['Execution Queue', Routes.SCENARIO_EXECUTION_QUEUE],
      ...(externalUserCreator ? [['Externalization', Routes.EXTERNALIZATION]] : []),
      ...(admin ? [['Admin Space', Routes.USER_LIST]] : []),
    ] as [string, Routes][];
  }, [admin, modelCreator, externalUserCreator]);
  const notificationList = useNotifications(3);
  const {latestNotification, visited} = useSelector((state) => userNotificationsSelector(state));
  const helpLinks: [string, string?][] = useMemo(
    () => [
      ['Help & Training', documentation?.trainingSpaceUrl],
      ['Request Support', documentation?.supportUrl],
      ['Provide Feedback', documentation?.feedbackUrl],
      ['Release Notes', documentation?.releaseNotesUrl],
    ],
    [documentation],
  );

  const handleUserMenuToggleClick = (event: MouseEvent) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleHelpMenuToggleClick = (event: MouseEvent) => {
    setHelpMenuAnchor(event.currentTarget);
  };

  const handleTourClick = useCallback(() => {
    window.location.replace(getOrigin() + '/#/model');
    setTimeout(() => {
      setShowTour(true);
    }, 1000);
  }, []);

  const handleTourComplete = () => {
    setShowTour(false);
  };

  const handleNotificationMenuToggleClick = (event: MouseEvent) => {
    setNotificationMenuAnchor(event.currentTarget);
    dispatch(resetLatestNotificationFiled());
  };

  const activeTabIndex = useMemo(() => {
    if (pathname.startsWith('/admin/')) {
      return 4;
    }
    return tabLinks.findIndex((tabLink) => pathname.startsWith(tabLink[1]));
  }, [pathname, tabLinks]);

  useEffect(() => {
    if (!allNotificationList) {
      requestLatestNotifications()
        .then((response) => {
          setAllNotificationList(response);
        })
        .catch(() => {
          dispatch(messageAdd('Failed to load the notification list', MessageTypes.ERROR));
        });
    }
  }, [dispatch, allNotificationList]);

  const handleShowAllButtonClick = useCallback(() => {
    setNotificationMenuAnchor(null);
    history.push(getUrlRoute(Routes.ALL_NOTIFICATIONS));
  }, [history]);

  const handleClearAll = useCallback(() => {
    const myElementArr = document.querySelectorAll(`.${styles.closeButton}`);
    const inActivateIds: number[] = [];
    myElementArr.forEach((element) => {
      const selectedItem = element.getAttribute('data-item');
      if (selectedItem && notificationList) {
        inActivateIds.push(parseInt(selectedItem));
      }
    });
    deleteNotificationAction(dispatch, inActivateIds);
  }, [dispatch, notificationList]);

  const handleItemClose = useCallback(
    (event: MouseEvent) => {
      const selectedItem = event.currentTarget.getAttribute('data-item');
      if (selectedItem && notificationList) {
        const selectedId = parseInt(selectedItem);
        deleteNotificationAction(dispatch, [selectedId]);
      }
    },
    [notificationList, dispatch],
  );

  const handleItemText = useCallback((item: NotificationDTO) => {
    if (item.notificationAction === 'NEW_MODEL_VERSION') {
      return 'has added a new model version';
    } else {
      return 'has modified files in the model';
    }
  }, []);

  const handleItemLink = useCallback((item: NotificationDTO) => {
    if (item.notificationAction === 'NEW_MODEL_VERSION') {
      return `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
        uuid: item.modelUuid,
      })}`;
    } else {
      return `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
        uuid: item.modelUuid,
      })}?tab=folder`;
    }
  }, []);

  return (
    <>
      <Tour showTour={showTour} onTourComplete={handleTourComplete} userType={user?.userType} />
      <Typography variant="h6">
        <Link component={RouterLink} to={Routes.MODEL_LIST} color="inherit" className={styles.title} {...tid('logo')}>
          <IconLogo className={styles.logo} />
          <strong>Model Repository</strong>
        </Link>
      </Typography>
      <Tabs
        value={activeTabIndex !== -1 ? activeTabIndex : false}
        classes={{indicator: styles.indicator}}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth">
        {tabLinks.map(([label, url]) => (
          <Tab
            {...tid('navbar', ...label.toLocaleLowerCase().split(' '))}
            key={url}
            label={label}
            component={RouterLink}
            to={url}
            classes={{root: styles.navButton}}
          />
        ))}
      </Tabs>
      <div>
        <>
          <Button
            {...tid('notification-menu-button')}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleNotificationMenuToggleClick}
            className={!!notificationMenuAnchor ? styles.notificationsButtonSelected : styles.notificationsButton}>
            <div>
              <NotificationsOutlinedIcon />
              {latestNotification || !visited ? <span className={styles.notificationIdentifier}></span> : null}
            </div>
          </Button>
          <Popover
            id="notification-menu"
            open={!!notificationMenuAnchor}
            anchorEl={notificationMenuAnchor}
            onClose={() => setNotificationMenuAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            {notificationList && (
              <div className={styles.notificationsMenu}>
                <div className={styles.notificationsHeader}>
                  <span>Notifications</span>
                  {notificationList.length > 0 ? (
                    <span className={styles.clearAll} onClick={handleClearAll}>
                      Clear All
                    </span>
                  ) : (
                    <span className={styles.rightElement}>Clear All</span>
                  )}
                </div>
                <div className={notificationList.length > 0 ? '' : styles.notificationsContainer}>
                  {notificationList.map((item: NotificationDTO) => {
                    return (
                      <div key={item.notificationId} className={styles.notificationItem}>
                        <div className={styles.leftPane}>
                          <>
                            <div style={{fontWeight: 'bold'}}>
                              {item.createdBy} {handleItemText(item)}{' '}
                            </div>
                            <div>{item.userNote}</div>
                            <div style={{marginBottom: '20px'}} onClick={() => setNotificationMenuAnchor(null)}>
                              <a style={{color: '#fff'}} href={handleItemLink(item)}>
                                Click here to review
                              </a>
                            </div>
                          </>
                          <div>{item.createdOn.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}</div>
                        </div>
                        <div className={styles.rightPane}>
                          <IconButton
                            className={styles.closeButton}
                            data-item={item.notificationId}
                            onClick={handleItemClose}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {!notificationList.length && (
                  <Typography className={styles.notificationsFooter}>No new notifications.</Typography>
                )}
                {allNotificationList ? (
                  <span className={styles.showAllButton} onClick={handleShowAllButtonClick}>
                    Show all notifications
                  </span>
                ) : (
                  <span className={styles.showAllGreyButton}>Show all notifications</span>
                )}
              </div>
            )}
          </Popover>
        </>
        {user && (
          <>
            <Button
              {...tid('user-menu-button')}
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={handleUserMenuToggleClick}
              className={styles.userMenuButton}>
              {fullNameWestern(user)} <ArrowDropDown />
            </Button>
            <Menu
              id="user-menu"
              anchorEl={userMenuAnchor}
              keepMounted
              open={!!userMenuAnchor}
              onClose={() => setUserMenuAnchor(null)}>
              <MenuItem dense={true} onClick={logout}>
                <TextOrProgress active={auth.isFetching}>Logout</TextOrProgress>
              </MenuItem>
            </Menu>
          </>
        )}
        <Button
          {...tid('help-menu-button')}
          aria-controls="help-menu"
          aria-haspopup="true"
          onClick={handleHelpMenuToggleClick}
          className={styles.helpMenuButton}>
          Help <ArrowDropDown />
        </Button>
        <Menu
          id="help-menu"
          anchorEl={helpMenuAnchor}
          keepMounted
          open={!!helpMenuAnchor}
          onClose={() => setHelpMenuAnchor(null)}>
          {user?.userType !== 'EXTERNAL_USER' && (
            <MenuItem
              dense={true}
              {...tid('help-menu-item', 'Guided Tour of MR ')}
              onClick={() => {
                setHelpMenuAnchor(null);
                handleTourClick();
              }}>
              Guided Tour of MR
            </MenuItem>
          )}
          {helpLinks.map(([title, url]) => (
            <MenuItem key={url} dense={true} component="a" href={url} target="_blank" {...tid('help-menu-item', title)}>
              {title}
            </MenuItem>
          ))}
          <MenuItem
            dense={true}
            component="a"
            href={settings?.privacyPolicyUrl}
            target="_blank"
            {...tid('help-menu-item', 'Privacy policy')}>
            Privacy policy
          </MenuItem>
          <MenuItem
            {...tid('help-menu-item', 'About')}
            dense={true}
            onClick={() => {
              setHelpMenuAnchor(null);
              openAboutDialog({});
            }}>
            About
          </MenuItem>
        </Menu>
      </div>
      {AboutDialog}
    </>
  );
};
