import React from 'react';
import {IncidentListDTO} from 'hemwb-api';
import {Grid, Typography} from '@material-ui/core';
import {fullName} from '../../../../../../store/user/utils';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../../../../../core/TableWithSortAndPagination';

type IncidentWorkOverviewProps = {
  incident: IncidentListDTO;
};

const IncidentWorkOverview: React.FC<IncidentWorkOverviewProps> = ({incident}) => {
  return (
    <Grid container spacing={0} direction="column">
      {incident.comments ? (
        <div style={{margin: '5rem 0 20rem 5rem'}}>
          <Typography style={{fontSize: '1.3rem'}}>
            <span style={{textDecoration: 'underline'}}>{`${fullName(incident.modifiedBy)}`}</span> added a comment -{' '}
            {incident.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
          </Typography>
          <br />
          <Typography style={{fontSize: '1.3rem'}}>{incident.comments}</Typography>
          <br />
          <Typography style={{fontSize: '1.3rem'}}>
            <span style={{textDecoration: 'underline'}}>{`${fullName(incident.modifiedBy)}`}</span> changed the status -{' '}
            {incident.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
          </Typography>
          <br />
          <Typography style={{fontSize: '1.3rem'}}>Status changes from Open to Closed</Typography>
          <br />
        </div>
      ) : (
        <div style={{minHeight: '40rem'}} />
      )}
    </Grid>
  );
};

export default IncidentWorkOverview;
