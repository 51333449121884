import {VEAPActivity} from 'hemwb-api';
import React from 'react';

export const renderVeapOptionItem = (option: VEAPActivity) => (
  <div style={{fontSize: 12}}>
    <span>
      <strong>{option.name.startsWith('MDA') ? 'REV/OPS' : 'VEAP'} ID: </strong>
      {option.name}
    </span>
    <br />
    <span>
      <strong>Title: </strong>
      {option.projectTitle}
    </span>
    <br />
    <span>
      <strong>Contact: </strong>
      {option.keyContact}
    </span>
    <br />
    <span>
      <strong>Indication: </strong>
      {option.primaryIndication}
    </span>
    <br />
    <span>
      <strong>Product: </strong>
      {option.productName}
    </span>
    <br />
    <span>
      <strong>Therapeutic Area: </strong>
      {option.therapeuticArea}
    </span>
    <br />
    <span>
      <strong>MK/V Number: </strong>
      {option.mkvNumber?.join(', ')}
    </span>
    <br />
  </div>
);
