import React from 'react';
import {useModelEditPermissionsSideBarModal} from './useModelEditPermissionsSideBarModal';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {canEditModel} from '../../../../../../permissions/usePermissions';
import {Password16} from '@carbon/icons-react';
import {getModelSteward, isModelExternal, useModelDetail} from '../../../../../../store/model';

const ModelEditPermissionsActionMenuButton: React.FC<Partial<ModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelEditPermissionsSideBarModal(props);
  const externalModel = isModelExternal(props.model);
  const modelDetails = useModelDetail(props.model?.uuid);
  const modelSteward = modelDetails ? getModelSteward(modelDetails) : null;
  const disabledExternalModel = (modelSteward === null || modelDetails?.vestLead === null) && externalModel;

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.EDIT_PERMISSIONS)}
        permission={canEditModel(props.model)}
        // @ts-ignore
        onClick={!disabledExternalModel ? openModal : undefined}
        disabled={disabledExternalModel}
        icon={<Password16 />}
        text="Model Access"
        disabledExternalModel={disabledExternalModel}
        disabledToolTipName="Missing VEST or VEAM Lead & Model Steward Info"
      />
      {ModalComponent}
    </>
  );
};

export default ModelEditPermissionsActionMenuButton;
