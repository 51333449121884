import React, {PropsWithChildren, ReactNode} from 'react';
import {Grid, Typography} from '@material-ui/core';
import styles from './GridTitle.module.scss';

type GridTitleProps = PropsWithChildren<React.ReactNode> & {
  heading?: string;
  headingLast?: boolean;
  Icon?: ReactNode;
};

const GridTitle: React.FC<GridTitleProps> = ({children, heading, headingLast = false, Icon}) => {
  return (
    <Grid item sm={12} className={styles.container}>
      {Icon && <div className={styles.icon}>{Icon}</div>}
      {heading && !headingLast && <Typography variant="body2">{heading}</Typography>}
      <Typography variant="h5">{children}</Typography>
      {heading && headingLast && <Typography variant="body2">{heading}</Typography>}
    </Grid>
  );
};

export default GridTitle;
