import {ModelDTO} from 'hemwb-api';

export const modelHasValidBitbucketMetadata = (model: ModelDTO, alsoCheckCommit = false): boolean => {
  return (
    !!model.metadata.bitbucket_project &&
    !!model.metadata.bitbucket_repository &&
    !!model.metadata.bitbucket_path &&
    (!!model.metadata.bitbucket_commit || !alsoCheckCommit)
  );
};

export const bitbucketMetadataChanged = (m1: ModelDTO, m2: ModelDTO, alsoCheckCommit = false) => {
  return (
    m1.metadata.bitbucket_project !== m2.metadata.bitbucket_project ||
    m1.metadata.bitbucket_repository !== m2.metadata.bitbucket_repository ||
    m1.metadata.bitbucket_path !== m2.metadata.bitbucket_path ||
    (m1.metadata.bitbucket_commit !== m2.metadata.bitbucket_commit && alsoCheckCommit)
  );
};
