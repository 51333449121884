import {AbstractControl, ValidatorFn} from 'react-reactive-form';
import {validateInteger, validateNumber} from '../../../Form';
import {InputType} from '../types';

export const validateNumberByDataType: ValidatorFn = (control: AbstractControl) => {
  if (!control.parent || !control.parent.parent || !control.value) {
    return null;
  }

  const dataTypeControl = control.parent.parent.get('dataType');

  if (dataTypeControl) {
    if (dataTypeControl.value === InputType.INTEGER) {
      return validateInteger(control);
    }
    if (dataTypeControl.value === InputType.DECIMAL) {
      return validateNumber(control);
    }
  }
  return null;
};
