import {ScenarioDTO} from 'hemwb-api';
import React from 'react';

export const renderCalibrationInstanceOptionItem = (option: ScenarioDTO) => (
  <div style={{fontSize: 12}}>
    <span>{option.name}</span>
    <br />
    <span>
      {option.createdBy?.firstName && option.createdBy?.lastName ? (
        <>
          {option.createdBy?.firstName},&nbsp;{option.createdBy?.lastName}
        </>
      ) : (
        <>{option.createdBy?.id}</>
      )}
    </span>
  </div>
);
