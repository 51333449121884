import {ExecutionSetupDTO, ExecutionSetupType, ScenarioDTO} from 'hemwb-api';
import {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../../../core/TableWithSortAndPagination';
import {fullName} from '../../../../store/user/utils';
import {displayScenarioName} from '../../scenario/list/getScenarioListColumns';

export const subModelColorByType = {
  [ExecutionSetupType.CALIBRATION]: '#5550E8',
  [ExecutionSetupType.SCENARIO]: '#01CFB2',
  [ExecutionSetupType.DISPLAY]: '#AAED00',
};
export const getSubModelColorHashCode = (subModel?: ExecutionSetupDTO): string => {
  return subModel && subModel.type ? subModelColorByType[subModel.type] : '#D8D8D8';
};

export const scenarioGridColumns: Column<ScenarioDTO>[] = [
  {
    id: 'checkbox',
    label: '',
    value: (scenario) => scenario.status,
  },
  {
    id: 'name',
    label: 'Scenario',
    sortable: true,
    value: (scenario) => scenario.name,
    display: displayScenarioName,
  },
  {
    id: 'label',
    label: 'Label',
    sortable: true,
    value: (scenario) => scenario.name,
  },
  {
    id: 'createdBy',
    label: 'Creator',
    sortable: true,
    value: (scenario) => (scenario?.createdBy ? fullName(scenario?.createdBy) : ''),
  },
  {
    id: 'createdOn',
    label: 'Created',
    sortable: true,
    value: (scenario) => scenario.createdOn,
    display: (scenario) => scenario.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
  },
  {
    id: 'modifiedOn',
    label: 'Modified',
    sortable: true,
    value: (scenario) => scenario.createdOn,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
    value: (scenario) => scenario.status,
  },
  {
    id: 'selected',
    label: '',
    value: (scenario) => scenario.status,
  },
];
