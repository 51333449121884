import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {ModelInputsActions} from './types';
import {
  requestModelInputsCreate,
  requestModelInputsDelete,
  requestModelInputsList,
  requestModelInputsUpdate,
} from './api';

const modelInputsLoadActionsCreator = buildAsyncActionsCreator(ModelInputsActions.LOAD);
export const modelInputsLoad = createAsyncActionExecutor(modelInputsLoadActionsCreator, requestModelInputsList);

const modelInputsCreateActionsCreator = buildAsyncActionsCreator(ModelInputsActions.CREATE);
export const modelInputsCreate = createAsyncActionExecutor(modelInputsCreateActionsCreator, requestModelInputsCreate);

const modelInputsUpdateActionsCreator = buildAsyncActionsCreator(ModelInputsActions.UPDATE);
export const modelInputsUpdate = createAsyncActionExecutor(modelInputsUpdateActionsCreator, requestModelInputsUpdate);

const modelInputsDeleteActionsCreator = buildAsyncActionsCreator(ModelInputsActions.DELETE);
export const modelInputsDelete = createAsyncActionExecutor(modelInputsDeleteActionsCreator, requestModelInputsDelete);
