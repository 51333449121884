import {buildInitialGridState} from './utils';
import {GridAction, GridActions, GridState, Order} from './types';
import {
  dynamicSearchGridId,
  modelListGridId,
  userListGridId,
  incidentListGridId,
  notificationsListGridId,
  externalizationListGridId,
  externalUserDetailGridId,
} from './gridIds';
import {AuthAction, AuthActions} from '../auth/types';
import {AsyncActionPhase} from '../asyncUtilsTypes';

export type GridStoreState = {[gridId: string]: GridState};

export const gridInitialState: GridStoreState = {
  [modelListGridId]: buildInitialGridState({
    orderBy: 'createdOn',
    order: Order.DESC,
    // @ts-ignore
    filter: {
      modelTypes: [],
      global: [],
      countries: [],
      stewards: [],
      creators: [],
      products: [],
      diseases: [],
      type: [],
      therapeuticAreas: [],
      platforms: [],
      status: ['Draft', 'Published'],
    },
  }),
  [dynamicSearchGridId]: buildInitialGridState({
    orderBy: 'createdOn',
    order: Order.DESC,
    // @ts-ignore
    filter: {
      model_type: [],
      global: [],
      country: [],
      product: [],
      disease: [],
      therapeutic_area: [],
      study_number: [],
      platform: [],
    },
  }),
  [userListGridId]: buildInitialGridState({
    orderBy: 'lastName',
    order: Order.ASC,
  }),
  [externalizationListGridId]: buildInitialGridState({
    orderBy: 'lastName',
    order: Order.ASC,
  }),
  [incidentListGridId]: buildInitialGridState({
    orderBy: 'createdOn',
    order: Order.DESC,
  }),
  [notificationsListGridId]: buildInitialGridState({
    rowsPerPage: 20,
  }),
  [externalUserDetailGridId]: buildInitialGridState({
    rowsPerPage: 20,
  }),
};

export const gridReducer = (state = gridInitialState, action: GridAction | AuthAction): GridStoreState => {
  switch (action.type) {
    case GridActions.UPDATE_STATE:
      return {
        ...state,
        [action.gridId]: {
          ...state[action.gridId],
          ...action.update,
        },
      };

    case GridActions.SET_STATE:
      return {
        ...state,
        [action.gridId]: {
          ...gridInitialState[action.gridId],
          ...action.state,
        },
      };

    case AuthActions.AUTH_LOGOUT:
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return gridInitialState;
      }
      return state;

    default:
      return state;
  }
};
