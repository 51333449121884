import React, {createRef, RefObject, SyntheticEvent, useRef} from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import styles from './SideBar.module.scss';
import {ThemeType} from '../../../styles';

type SideBarProps = {
  className?: string;
  position?: 'left' | 'right';
  isOpen?: boolean;
  toggleOpen?: (event: SyntheticEvent) => void;
  style?: React.CSSProperties;
  theme?: ThemeType;
};

const defaultRef = createRef<HTMLElement>();
export const SideBarRefContext = React.createContext<RefObject<HTMLElement>>(defaultRef);

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = (props) => {
  const {className, position = 'right', isOpen, toggleOpen, theme = 'whiteTheme', children, ...restProps} = props;
  const ref = useRef<HTMLElement>(null);
  return (
    <aside
      ref={ref}
      className={clsx(styles.sideBar, theme, className, {[styles.toggleOpen]: !!toggleOpen})}
      {...restProps}>
      <SideBarRefContext.Provider value={ref}>
        {toggleOpen && (
          <>
            <div className={styles.buttonToggle} onClick={toggleOpen}>
              {(position === 'right' && !isOpen) || (position === 'left' && isOpen) ? (
                <>
                  <ChevronLeftIcon style={{position: 'relative', left: '1rem', width: '1.8rem'}} />
                  <ChevronLeftIcon style={{position: 'relative', left: '-1.4rem', width: '1.8rem'}} />
                </>
              ) : (
                <>
                  <ChevronRightIcon style={{position: 'relative', left: '1rem', width: '1.8rem'}} />
                  <ChevronRightIcon style={{position: 'relative', left: '-1.4rem', width: '1.8rem'}} />
                </>
              )}
            </div>
          </>
        )}
        {children}
      </SideBarRefContext.Provider>
    </aside>
  );
};

export default SideBar;
