import {
  booleanOptions,
  CalibrationDTO,
  CommonInputAttributes,
  ControlledByType,
  expertiseLevel,
  InputAttributes,
  inputCategories,
  inputDisplayCategories,
  InputType,
  inputTypes,
  SelectItem,
  SelectorColumnType,
} from './types';
import {ValuesType} from 'utility-types';
import {ExecutionSetupType, ModelInputDetailDTO} from 'hemwb-api';
import {FormArray, FormGroup} from 'react-reactive-form';

export const getCommonInputAttributes = (attributes: CommonInputAttributes): CommonInputAttributes => {
  const {name, title, question, visible, required, viewable, id, categoryId} = attributes;
  return {name, title, question, visible, required, viewable, id, categoryId};
};

export const getModelInputTypeLabel = (type: InputType): string => {
  return (inputTypes as {[key: string]: string})[type] || type;
};
export const getExpertiseLevelLabel = (type: ValuesType<typeof expertiseLevel>): string => {
  return (expertiseLevel as {[key: string]: string})[type] || type;
};

export const getBooleanInputTypeLabel = (value: ValuesType<typeof booleanOptions>): string => {
  return value === true ? 'Yes' : value === false ? 'No' : 'None';
};

export const parseCalibrationDTO = (input: ModelInputDetailDTO): CalibrationDTO | undefined => {
  if (input.name !== 'calibrationRoot') {
    return undefined;
  }

  const {definitions} = JSON.parse(input.value || '{}');

  if (!definitions) {
    return undefined;
  }

  return {
    id: input.id,
    definitions,
  } as CalibrationDTO;
};

export const getNextInputId = (formDefinitions: FormArray): number => {
  const max = Math.max(...formDefinitions.value.map((d: any) => Number(d.id)));
  if (!Number.isFinite(max)) return 1;
  return max + 1 || 1;
};

export const getOptionKeys = (formGroup: FormGroup | InputAttributes) => {
  const {type, columns, options} = (formGroup as InputAttributes).id ? formGroup : (formGroup as FormGroup).value;

  // const {type, columns, options} = formGroup.value;
  if (type === InputType.BOOLEAN) {
    return booleanOptions;
  }
  if (type === InputType.SELECTOR) {
    return columns?.map((column: SelectorColumnType) => column.key);
  }

  return options?.map((option: SelectItem) => option?.key);
};

export const getOptionLabel = (formGroup: FormGroup, inputId: number, value: any) => {
  const examinedControl =
    formGroup.value.id === inputId
      ? formGroup
      : (formGroup.parent as FormArray).controls.find((c) => c.value.id === inputId);

  if (!examinedControl) {
    return undefined;
  }

  const {type} = examinedControl.value;

  if (type === InputType.BOOLEAN) {
    return getBooleanInputTypeLabel(value);
  }

  if (type === InputType.SINGLE_SELECT) {
    const option = (formGroup.get('options') as FormArray).controls.find((c) => c.value.key === value);
    return option?.value.text;
  }

  if (type === InputType.SELECTOR) {
    const option = (formGroup.get('columns') as FormArray).controls.find((c) => c.value.key === value);
    return option?.value.text;
  }
};

export const getControlledByDescription = (formGroup: FormGroup, controlledBy?: ControlledByType) => {
  if (!controlledBy) {
    return undefined;
  }

  const examinedControl =
    formGroup.value.id === controlledBy.id
      ? formGroup
      : (formGroup.parent as FormArray).controls.find((c) => c.value.id === controlledBy.id);

  if (!examinedControl) {
    return undefined;
  }

  return `${examinedControl.value.title}/${getOptionLabel(
    examinedControl as FormGroup,
    controlledBy.id,
    controlledBy.value,
  )}`;
};

export const getInputDefinitionCategories = (subModelType: ExecutionSetupType) => {
  if (subModelType === ExecutionSetupType.DISPLAY) {
    return inputDisplayCategories;
  }
  return inputCategories;
};
