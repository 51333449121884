import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {notificationActions, NotificationUpdateList, ResetLatestNotificationFiled} from './types';
import {requestLatestNotifications, deleteNotifications} from './api';
import {NotificationDTO} from 'hemwb-api';

export const loadNotificationsActionsCreator = buildAsyncActionsCreator(notificationActions.NOTIFICATIONS_LIST);
export const userNotificationsList = createAsyncActionExecutor(
  loadNotificationsActionsCreator,
  requestLatestNotifications,
);

export const deleteNotificationsActionsCreator = buildAsyncActionsCreator(notificationActions.REQUEST_DELETE);
export const deleteNotificationAction = createAsyncActionExecutor(
  deleteNotificationsActionsCreator,
  deleteNotifications,
);

export const notificationUpdatedList = (newNotification: NotificationDTO): NotificationUpdateList => ({
  type: notificationActions.UPDATE_LIST,
  newNotification,
});

export const resetLatestNotificationFiled = (): ResetLatestNotificationFiled => ({
  type: notificationActions.RESET_FIELD,
});
