import {ModelTreeNode} from '../../../../store/modelFile';
import {useTabs} from './useTabs';
import {WorkbookItemDTOTypeEnum} from 'hemwb-api';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ChangeItem, DiffWithTab, Section, TabType, ViewMode} from './types';
import {getSectionForTab} from './utils';
import {
  excelDiffSelector,
  useExcelDiffMacroLoaderWithParams,
  useExcelDiffSheetLoaderWithParams,
} from '../../../../store/excelDiff';
import {useDiffSelectorGetter} from './useDiffSelector';
import {useSelector} from 'react-redux';
import {AsyncActionPhase} from '../../../../store/asyncUtilsTypes';

export const useExcelDiff = (file1: ModelTreeNode, file2: ModelTreeNode) => {
  const tabs = useTabs(file1, file2);

  const abortRef = useRef(false);
  const [section, setSection] = useState<Section>(Section.OVERVIEW);
  const [tab, setTab] = useState<TabType>();
  const [pageIndex, setPageIndex] = useState(0);
  const [markedChange, setMarkedChange] = useState<ChangeItem>();
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(ViewMode.DOUBLE_PANE);

  const excelDiffStore = useSelector(excelDiffSelector);

  const tabsCountFetched = useMemo(() => {
    return [...Object.values(excelDiffStore.sheets), ...Object.values(excelDiffStore.macros)].filter(
      (d) =>
        d.params[0].itemUUID1 === file1.uuid &&
        d.params[0].itemUUID2 === file2.uuid &&
        d.state === AsyncActionPhase.SUCCESS,
    ).length;
  }, [excelDiffStore, file1, file2]);

  const fetchErrorList = useMemo(() => {
    return [...Object.values(excelDiffStore.sheets), ...Object.values(excelDiffStore.macros)].filter(
      (d) =>
        d.params[0].itemUUID1 === file1.uuid &&
        d.params[0].itemUUID2 === file2.uuid &&
        d.state === AsyncActionPhase.FAILURE,
    );
  }, [excelDiffStore, file1, file2]);

  const diffError = useMemo(() => {
    return fetchErrorList.some((i) => {
      const p = i.params[0];
      // @ts-ignore
      return tab && tab.name === p.name && tab.type === p.type;
    });
  }, [tab, fetchErrorList]);

  const sheetDiffLoader = useExcelDiffSheetLoaderWithParams();
  const macroDiffLoader = useExcelDiffMacroLoaderWithParams();

  const loadDiff = useCallback(
    (t: TabType, pIndex = 0) => {
      if (t.type === WorkbookItemDTOTypeEnum.SHEET) {
        return sheetDiffLoader([
          {
            itemId1: t.itemId1,
            itemId2: t.itemId2,
            itemUUID1: file1.uuid,
            itemUUID2: file2.uuid,
            pageIndex: pIndex,
          },
        ]);
      } else {
        return macroDiffLoader([
          {
            itemId1: t.itemId1,
            itemId2: t.itemId2,
            itemUUID1: file1.uuid,
            itemUUID2: file2.uuid,
            pageIndex: pIndex,
          },
        ]);
      }
    },
    [sheetDiffLoader, macroDiffLoader, file1, file2],
  );

  useEffect(() => {
    return () => {
      abortRef.current = true;
    };
  }, [abortRef]);

  useEffect(() => {
    if (tabs) {
      abortRef.current = false;
      setTab(undefined);
      setSection(Section.OVERVIEW);
    } else {
      setTab(undefined);
      setRequested(false);
      abortRef.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    if (tabs && !requested) {
      setRequested(true);
      setLoading(true);
      const promises = tabs.map((t) => {
        return () => loadDiff(t);
      });

      promises
        .reduce(
          (chain, currentTask: any) =>
            chain.then(() => {
              if (!currentTask) {
                return Promise.resolve();
              }
              if (abortRef.current) {
                return Promise.reject(new Error('Canceled'));
              } else {
                return currentTask().catch(() => null);
              }
            }),
          Promise.resolve(),
        )
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });
    } else if (tabs === false) {
      setLoading(false);
    }
  }, [tabs, requested, tab, loadDiff]);

  const getDiff = useDiffSelectorGetter();

  const diffsWithTab = useMemo(() => {
    return (tabs || []).reduce((acc, t) => {
      const diff = getDiff(t);
      if (diff) {
        acc.push({diff, tab: t});
      }
      return acc;
    }, ([] as any) as DiffWithTab[]);
  }, [getDiff, tabs]);

  useEffect(() => {
    if (markedChange) {
      setTab(markedChange.tab);
      setPageIndex(markedChange.pageIndex);
    }
  }, [markedChange, setTab]);

  useEffect(() => {
    if (markedChange && !markedChange.diff) {
      loadDiff(markedChange.tab, markedChange.pageIndex);
      const d = getDiff(markedChange.tab, markedChange.pageIndex);
      if (d) {
        setMarkedChange({
          ...markedChange,
          diff: d,
        });
      }
    }
  }, [markedChange, getDiff, loadDiff]);

  const changeTab = useCallback((t: TabType) => {
    setTab(t);
    setPageIndex(0);
  }, []);

  useEffect(() => {
    if (tab) {
      setSection(getSectionForTab(tab));
    }
  }, [tab, setSection]);

  useEffect(() => {
    if (section === Section.OVERVIEW) {
      setTab(undefined);
    }
  }, [section]);

  return {
    tabs,
    tab,
    changeTab,
    setTab,
    section,
    setSection,
    diffsWithTab,
    pageIndex,
    setPageIndex,
    viewMode,
    setViewMode,
    fetchErrorList,
    diffError,
    markedChange,
    setMarkedChange,
    loading,
    tabsCountFetched,
    tabsCountTotal: tabs ? tabs.length : undefined,
  };
};
