import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ModelRetire from './ModelRetire';
import {ModelActionProps} from '../../../actionMenuTypes';

export const useModelRetireSideBarModal = (props: Partial<ModelActionProps>) => {
  const {model, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model && (
        <SideBarModal
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}
          height={450}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && <ModelRetire model={model} onSuccess={handleSuccess} onCancel={closeModal} />}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, handleSuccess, closeModal]);

  return {
    openModal,
    ModalComponent,
  };
};
