import React, {ReactNode} from 'react';
import WarningText, {WarningTextProps} from './WarningText';
import {Grid} from '@material-ui/core';

type WarningTextWithTitleProps = WarningTextProps & {
  title: ReactNode | string;
};

const WarningTextWithTitle: React.FC<WarningTextWithTitleProps> = ({title, text, ...props}) => {
  return (
    <Grid container item wrap="nowrap" alignItems="flex-start" direction="column" {...props}>
      <Grid item>
        <div className="headlineBlue">{title}</div>
      </Grid>
      <WarningText text={text} />
    </Grid>
  );
};

export default WarningTextWithTitle;
