import React, {useCallback, useMemo, useRef, useState} from 'react';
import {
  VisualizationResults,
  VisualizationResultsChartElement,
  VisualizationResultsElements,
  VisualizationResultsErrorElement,
  VisualizationResultsSectionElement,
  VisualizationResultsTableElement,
} from '../../../../../store/visualization';
import {useTableRenderer} from './useTableRenderer';
import {chartToPNG} from './chartToPNG';
import PptxGenJS from '../../../../../PptxGenJS';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ScenarioDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import {isScenarioFinished, requestScenarioGetOutputVisualization} from '../../../../../store/scenario';
import {useDispatch} from 'react-redux';
import {defaultCancelButtonProps} from '../../../../core/Buttons/CancelButton';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../../../../core/TableWithSortAndPagination';
import IMGBASE64 from '../../../../../assets/MR_logo_DARK.png';
import {processResults} from '../../step3/processResults';

// library is in higher TS version 4.1 then App 3.9
// eslint-disable-next-line
const {default: pptxgen} = require('pptxgenjs');

// test of dom-to-image like libraries on mocked response data
// dom-to-image-more-patch': 30.8s, chart's labels are a little odd

// dom-to-image-improved: 34.5s, chart's labels are a little odd
// dom-to-image-more-v2: 120.7s, OK
// dom-to-image-more: 125.3s, ok
// deyihu-dom-to-image': 34s, chart's labels are a little odd
// venkat4541_dom-to-image: 40.4s
// dom-to-image-more-updated doesnt work
// @insomnia-dev/dom-to-image: 152s
// @yzfe/dom-to-image 36.2s, chart's labels are a little odd

//html-to-image s fontEmbedCSS 77s, charts are ok, for tables dont work Contain feature
//html-to-image bez fontEmbedCSS 84.9s, charts are ok, for tables dont work Contain feature
//html-to-image s  skipFonts: true,preferredFontFormat: 'woff2', fontEmbedCSS, 48s, charts are ok, for tables dont work Contain feature
//html-to-image-fast 51s, charts are ok, for tables dont work Contain feature
//@snappify_html-to-image 49.5s, charts are ok, for tables dont work Contain feature

const PAGE_WIDTH = 13.4;
const PAGE_HEIGHT = 7.5;
const HEADER_BAR_HEIGHT = 0.7;

let sectionTitle: string | undefined;

enum Status {
  IDDLE,
  DOWNLOADING,
  EXPORTING,
}

export const usePptExport = () => {
  const {getTableImageData, TableComponent} = useTableRenderer();
  const [completed, setCompleted] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState<Status>(Status.IDDLE);
  const abortRef = useRef(false);
  const dispatch = useDispatch();

  const exportPPT = useCallback(
    async (results: VisualizationResults) => {
      const {elements} = results;
      abortRef.current = false;
      setCompleted(0);
      setTotal(elements.length);
      setStatus(Status.EXPORTING);

      sectionTitle = undefined;

      const pptx = new pptxgen() as PptxGenJS;
      pptx.defineLayout({name: 'TST', width: PAGE_WIDTH, height: PAGE_HEIGHT});
      pptx.layout = 'TST';

      pptx.defineSlideMaster({
        title: 'MASTER_SLIDE',
        background: {fill: 'FFFFFF'},
        margin: [1, 0.25, 1.0, 0.25],
        slideNumber: {
          x: 0,
          y: '95%',
          w: '100%',
          color: '000000',
          fontFace: 'Invention',
          fontSize: 12,
          align: 'center',
        },
        objects: [
          {rect: {x: 0.0, y: 0, w: '100%', h: HEADER_BAR_HEIGHT, fill: '081120'}},
          {image: {x: 0.16, y: 0.1, w: 0.5, h: 0.5, data: IMGBASE64}},
          {
            text: {
              text: 'Model Repository',
              options: {
                x: 0.75,
                y: 0,
                w: 3,
                h: HEADER_BAR_HEIGHT,
                align: 'left',
                valign: pptx.AlignV.middle,
                color: 'FFFFFF',
                fontSize: 12,
                bold: false,
                fontFace: 'Invention',
              },
            },
          },
          {
            text: {
              text: 'Results Display',
              options: {
                x: 0,
                y: 0,
                w: '100%',
                h: HEADER_BAR_HEIGHT,
                align: 'center',
                valign: pptx.AlignV.middle,
                color: 'FFFFFF',
                fontSize: 16,
                bold: true,
                fontFace: 'Invention',
              },
            },
          },
          {
            text: {
              text: new Date().toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
              options: {
                x: 10.2,
                y: 0,
                w: 3,
                h: HEADER_BAR_HEIGHT,
                align: 'right',
                valign: pptx.AlignV.middle,
                color: 'FFFFFF',
                fontSize: 12,
                bold: true,
                fontFace: 'Invention',
              },
            },
          },
        ],
      });

      const promises = elements
        .map((element) => {
          switch (element.element) {
            case VisualizationResultsElements.SECTION: {
              return () => handleAddSection(pptx, element);
            }

            case VisualizationResultsElements.ERROR: {
              return () => handleAddError(pptx, element);
            }

            case VisualizationResultsElements.CHART: {
              return () => handleAddChart(pptx, element);
            }

            case VisualizationResultsElements.TABLE: {
              return () => handleAddTable(pptx, element, getTableImageData);
            }
          }
          return null;
        })
        .filter(Boolean) as (() => Promise<void>)[];

      if (promises.length > 0) {
        return promises
          .reduce(
            (chain, currentTask) =>
              chain.then(() => {
                if (abortRef.current) {
                  return Promise.reject(new Error('Canceled'));
                }

                return currentTask().then(() => {
                  setCompleted((c) => c + 1);
                });
              }),
            Promise.resolve(),
          )
          .then(() => {
            return pptx.writeFile({fileName: 'visualizationResults.pptx'});
          })
          .catch((error: Error) => {
            messageAdd(error.message, MessageTypes.ERROR);
          })
          .finally(() => {
            setTotal(0);
            setStatus(Status.IDDLE);
          });
      }

      return Promise.reject('Nothing to export.');
    },
    [getTableImageData],
  );

  const downloadResultsAndExportPPT = useCallback(
    async (scenario: ScenarioDTO) => {
      if (!isScenarioFinished(scenario.status)) {
        return Promise.reject(new Error('Can not fetch results from unfinished scenario.'));
      }

      abortRef.current = false;
      setStatus(Status.DOWNLOADING);

      try {
        const results = await requestScenarioGetOutputVisualization(scenario.id);
        if (!abortRef.current) {
          return await exportPPT(processResults(results));
        }
        return Promise.reject('Canceled');
      } catch (e) {
        dispatch(messageAdd(e, MessageTypes.ERROR));
      }
    },
    [dispatch, exportPPT, abortRef],
  );

  const abortPPTExport = useCallback(() => {
    setTotal(0);
    setStatus(Status.IDDLE);
    abortRef.current = true;
  }, [abortRef]);

  const ExportPPTComponent = useMemo(() => {
    return status !== Status.IDDLE ? (
      <>
        <DialogGeneric
          open={true}
          title="Please wait"
          buttons={[
            {
              ...defaultCancelButtonProps,
              onClick: abortPPTExport,
              ...tid('dialog', 'btn', 'cancel'),
            },
          ]}>
          {status === Status.EXPORTING && total > 0 && (
            <>
              Creating and saving your presentation...{' '}
              <span style={{width: 40, display: 'inline-block', textAlign: 'right'}}>
                {Math.round((completed / total) * 100)}%
              </span>
            </>
          )}
          {status === Status.DOWNLOADING && 'Downloading results...'}
        </DialogGeneric>
        {TableComponent}
      </>
    ) : null;
  }, [TableComponent, total, completed, status, abortPPTExport]);

  return {
    abortPPTExport,
    exportPPT,
    downloadResultsAndExportPPT,
    ExportPPTComponent,
  };
};

const handleAddSection = async (pptx: PptxGenJS, element: VisualizationResultsSectionElement) => {
  sectionTitle = element.title;

  pptx.addSection({title: sectionTitle});
  const slide = pptx.addSlide({sectionTitle, masterName: 'MASTER_SLIDE'});

  slide.addText(
    [
      {
        text: element.title,
        options: {
          align: 'center',
          color: '000000',
          fontSize: 20,
          bold: true,
          fontFace: 'Invention',
        },
      },

      {
        text: '\n\n' + element.text,
        options: {
          align: 'center',
          color: '000000',
          fontSize: 12,
          fontFace: 'Invention',
        },
      },
    ],
    {
      x: 0,
      y: HEADER_BAR_HEIGHT,
      w: '100%',
      h: 7.5 - HEADER_BAR_HEIGHT,
      margin: 1,
    },
  );

  return Promise.resolve();
};

const handleAddError = async (pptx: PptxGenJS, element: VisualizationResultsErrorElement) => {
  const slide = pptx.addSlide({sectionTitle, masterName: 'MASTER_SLIDE'});

  slide.addText(
    [
      {
        text: element.title,
        options: {
          align: 'center',
          color: 'D1303E',
          fontSize: 16,
          bold: true,
          fontFace: 'Invention',
        },
      },

      {
        text: '\n\n' + element.text,
        options: {
          align: 'center',
          color: 'D1303E',
          fontSize: 12,
          fontFace: 'Invention',
        },
      },
    ],
    {
      x: 0,
      y: HEADER_BAR_HEIGHT,
      w: '100%',
      h: 7.5 - HEADER_BAR_HEIGHT,
      margin: 1,
    },
  );

  return Promise.resolve();
};

const handleAddChart = async (pptx: PptxGenJS, element: VisualizationResultsChartElement) => {
  const slide = pptx.addSlide({sectionTitle, masterName: 'MASTER_SLIDE'});
  const imgData = await chartToPNG(element);

  slide.addImage({
    x: 0.5,
    y: 1,
    w: PAGE_WIDTH - 1,
    h: PAGE_HEIGHT - 1 - 0.5,
    data: imgData,
    sizing: {
      type: 'contain',
      w: PAGE_WIDTH - 1,
      h: PAGE_HEIGHT - 1 - 0.5,
    },
  });

  return Promise.resolve();
};

const handleAddTable = async (pptx: PptxGenJS, element: VisualizationResultsTableElement, getTableImageData: any) => {
  const slide = pptx.addSlide({sectionTitle, masterName: 'MASTER_SLIDE'});
  const imgData = await getTableImageData(element);
  const {width, height} = await getImageSizeByURI(imgData);

  const maxWidth = PAGE_WIDTH - 1;
  const maxHeight = PAGE_HEIGHT - 1 - 0.5;

  const fitRatio = maxWidth / maxHeight;
  const imageRatio = width / height;

  if (imageRatio >= fitRatio) {
    slide.addImage({
      x: 0.5,
      y: 1,
      w: maxWidth,
      h: maxWidth / imageRatio,
      data: imgData,
    });
  } else {
    const w = maxHeight * imageRatio;
    slide.addImage({
      x: 0.5 + (maxWidth - w) / 2,
      y: 1,
      w,
      h: maxHeight,
      data: imgData,
    });
  }

  return Promise.resolve();
};

export const getImageSizeByURI = async (dataURI: string): Promise<{width: number; height: number}> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const {width, height} = img;
      if (width && height) {
        return resolve({width, height});
      }
      return reject('Image load failed. No valid dimensions.');
    };

    img.src = dataURI;
  });
};
