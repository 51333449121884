import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {ModelFileAuditedDTOWithButton} from '../../../../../core/FileTree/types';
import GridItem from '../../../../../core/Form/GridItem';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {ModelFileUploaded} from '../../../../../../store/modelFile';
import TextField from '@material-ui/core/TextField';
import {useModelMetadataActiveOptions} from '../../../../../../store/modelMetadata';

type FileTagSelectionBoxProps = {
  selectedFileCount: number;
  onClick: (fileTag: string) => void;
  onCancel: () => void;
  handleFileTag?: (node: ModelFileUploaded, fileTag: string) => void;
  file?: ModelFileAuditedDTOWithButton;
  setUnSelectAll?: any;
  unSelectAll?: boolean;
};

const FileTagSelectionBox: React.FC<Partial<FileTagSelectionBoxProps>> = (props) => {
  const {selectedFileCount, onClick, onCancel, file, setUnSelectAll} = props;
  const [selectedOptions, setSelectedOptions] = React.useState(file?.fileTag);
  const [isDialogOpen, setDialogOpen] = useState(true);
  const options = useModelMetadataActiveOptions();
  const filter = createFilterOptions();
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

  useEffect(() => {
    setUnSelectAll(false);
  }, [setUnSelectAll]);

  const handleSaveFileType = () => {
    if (onClick && selectedOptions) {
      onClick(selectedOptions);
    }
    setDialogOpen(false);
  };
  const handleOnCancel = () => {
    setUnSelectAll(true);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      {isDialogOpen && (
        <div style={{border: '1px solid #F7F7F7', backgroundColor: '#F7F7F7', padding: '4px', display: 'inline-block'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              style={{fontSize: '20px', padding: '5px 5px', borderRadius: '20px', maxWidth: '180px'}}
              onClick={handleOnCancel}>
              {selectedFileCount} selected files
              <CancelIcon />
            </Button>
            <div style={{marginLeft: '28px', marginRight: '28px'}}>
              <h3>What type of file is this ?</h3>
              <span>Press down to choose a suggestion, or start typing to find and create your own file type</span>
              <GridItem>
                <Autocomplete
                  disableClearable
                  data-testid={'file.fileTag-' + file?.fileTag}
                  style={{width: 300}}
                  value={selectedOptions || null}
                  onChange={(event: any, newValue: string | null) => {
                    if (newValue) {
                      const replacedValue = newValue.replace(' (Create New)', '');
                      setSelectedOptions(replacedValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push(`${params.inputValue} (Create New)`);
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(option) => option}
                  options={options?.fileTag || []}
                  renderInput={(params) => (
                    <TextField
                      name="fileTag"
                      style={{width: 300}}
                      {...params}
                      inputProps={{...params.inputProps, maxLength: 25}}
                      onKeyDown={(event) => {
                        if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              </GridItem>
            </div>
            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{fontSize: '20px', padding: '5px 5px', maxWidth: '180px'}}
              disabled={!selectedOptions}
              onClick={handleSaveFileType}>
              Save File Type
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileTagSelectionBox;
