import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ScenarioActionProps} from '../../actionMenuTypes';
import SubModelActionMenu from '../../subModel/common/SubModelActionMenu/SubModelActionMenu';
import DefaultPage from '../../../layout/common/DefaultPage';
import ScenarioParameters, {
  ScenarioParametersRefType,
} from '../../../core/ModelInput/ScenarioParameters/ScenarioParameters';
import SideBar from '../../../layout/common/SideBar';
import {ScenarioParametersSubmitType} from './ScenarioParametersJSONEditor';
import {CalibrationScenarioInputs} from '../../../core/ModelInput/ScenarioParameters/types';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import {typeToSection} from '../../../../store/executionSetup';
import CancelButton from '../../../core/Buttons/CancelButton';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import styles from './ScenarioParametersBuilder.module.scss';
import {useAuth} from '../../../../store/auth';
import {Routes, useMatchedRoute} from '../../../router/routes';
import {useUserExpertise} from '../../../../store/userExpertise';

const ScenarioParametersBuilder: React.FC<Omit<ScenarioActionProps, 'scenario'> & ScenarioParametersSubmitType> = ({
  model,
  subModel,
  input,
  scenario,
  submitting,
  onSubmit,
  onCancel,
}) => {
  const inputListPortalRef = useRef<HTMLDivElement>(null);
  const section = typeToSection(subModel.type);

  const definitions = useMemo(() => {
    const parsed = input.value ? JSON.parse(input.value) : {};
    return parsed.definitions || [];
  }, [input]);

  const values = useMemo(() => {
    return JSON.parse(scenario.input.value || '{}');
  }, [scenario]);

  const handleSubmit = useCallback(
    (value: CalibrationScenarioInputs) => {
      onSubmit?.(JSON.stringify(value));
    },
    [onSubmit],
  );

  const scenarioParametersRef = useRef<ScenarioParametersRefType>();

  const {auth} = useAuth();
  const user = auth.loggedInUser;
  const userid = user ? user.id : '';
  const {modelUuid} = useMatchedRoute(Routes.SCENARIO_PARAMETERS_EDITOR);
  const [expertiseLevel, setExpertiseLevel] = useState<number>();

  const expertiseLevelObject = useUserExpertise(modelUuid, userid);

  useEffect(() => {
    setExpertiseLevel(Number(expertiseLevelObject?.expertiseTypeLevel?.split('-')[0]));
  }, [expertiseLevelObject]);

  return (
    <>
      <SideBar
        position="left"
        className="leftSelection"
        style={{boxShadow: '5px 0px 4px rgba(0,0,0,0.3)', position: 'relative', zIndex: 2}}>
        <h3 style={{paddingLeft: 15}}>Parameters</h3>
        <div ref={inputListPortalRef} />
      </SideBar>
      <DefaultPage style={{position: 'relative'}}>
        <div className={styles.container}>
          <SubModelSectionHeader
            model={model}
            section={section}
            subModel={subModel}
            scenario={scenario}
            title={"Instance's parameters editor"}
          />
          <div className={styles.parametersWrapper}>
            <ScenarioParameters
              expertiseLevel={expertiseLevel ? expertiseLevel : 500}
              subModelType={subModel.type!}
              ref={scenarioParametersRef}
              key={input.value}
              definitions={definitions}
              values={values}
              onSubmit={onSubmit ? handleSubmit : undefined}
              submitting={submitting}
              inputListPortalRef={inputListPortalRef}
              modelUuid={modelUuid}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className="buttonsContainer">
            <CancelButton onClick={onCancel} />
            <SubmitButton active={submitting} onClick={() => scenarioParametersRef.current?.submit()} />
          </div>
        </div>
      </DefaultPage>
      <SubModelActionMenu model={model} section={section} subModel={subModel} scenario={scenario} />
    </>
  );
};

export default ScenarioParametersBuilder;
