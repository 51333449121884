import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import DefaultLayout from '../layout/DefaultLayout';

import ModelList from '../pages/model/list/ModelList';
import ModelDetail from '../pages/model/detail/ModelDetail';

import UserDetail from '../pages/admin/user/detail/UserDetail';
import UserList from '../pages/admin/user/list/UserList';
import ExternalUserList from '../pages/admin/externalization/list/UserList';
import UserEdit from '../pages/admin/user/edit/UserEdit';
import {Routes} from './routes';
import UserEnroll from '../pages/admin/user/enroll/UserEnroll';
import ExternalUserEnroll from '../pages/admin/externalization/enroll/ExternalUserEnroll';
import {Error404Page} from '../pages/error/Error404Page';
import ScenarioExecutionQueue from '../pages/scenarioExecutionQueue/ScenarioExecutionQueue';
import IncidentList from '../pages/admin/incidentManagement/IncidentList';
import ManageMetadataList from '../pages/admin/manageMetadata/list/List';
import ModelUploadWrapper from '../pages/modelUpload/ModelUploadWrapper';
import SubModelList from '../pages/subModel/list/SubModelList';
import ModelInputEditor from '../pages/modelInput/editor/ModelInputEditor';
import ModelInputList from '../pages/modelInput/list/ModelInputList';
import ScenarioList from '../pages/scenario/list/ScenarioList';
import AllNotifications from '../pages/Notifications/AllNotifications';
import ScenarioParametersEditor from '../pages/scenario/editor/ScenarioParametersEditor';
import ScenarioDetailLazy from '../pages/scenario/detail/ScenarioDetailLazy';
import VisualizationStep1 from '../pages/visualization/step1/VisualizationStep1';
import VisualizationStep2 from '../pages/visualization/step2/VisualizationStep2';
import VisualizationStep3 from '../pages/visualization/step3/VisualizationStep3';
import VisualizationParametersDisplay from '../pages/visualization/parametersDisplay/VisualizationParametersDisplay';
import {HomePage} from '../auth/HomePage';
import LoginHomePage from '../auth/LoginHomePage';
import RegistrationReview from '../pages/admin/user/registration/RegistrationReview';

const Router: React.FC = () => {
  return (
    <Switch>
      {/* uploader pages */}
      <DefaultLayout path={Routes.MODEL_UPLOAD} component={ModelUploadWrapper} theme="whiteTheme" />

      {/* scenario pages */}
      <DefaultLayout path={Routes.SCENARIO_PARAMETERS_EDITOR} component={ScenarioParametersEditor} theme="greyTheme" />
      <DefaultLayout path={Routes.SCENARIO_LIST} component={ScenarioList} theme="greyTheme" />
      <DefaultLayout path={Routes.SCENARIO_DETAIL} component={ScenarioDetailLazy} theme="greyTheme" />

      {/* modelInput pages */}
      <DefaultLayout path={Routes.MODEL_INPUT_EDITOR} component={ModelInputEditor} theme="greyTheme" />
      <DefaultLayout path={Routes.MODEL_INPUT_LIST} component={ModelInputList} theme="greyTheme" />

      {/* sub-model pages */}
      <DefaultLayout path={Routes.SUBMODEL_LIST} component={SubModelList} theme="greyTheme" />

      {/* visualization pages */}
      <DefaultLayout path={Routes.VISUALIZATION_STEP_1} component={VisualizationStep1} theme="whiteTheme" />
      <DefaultLayout path={Routes.VISUALIZATION_STEP_2} component={VisualizationStep2} theme="whiteTheme" />
      <DefaultLayout path={Routes.VISUALIZATION_STEP_3} component={VisualizationStep3} theme="whiteTheme" />
      <DefaultLayout
        path={Routes.VISUALIZATION_PARAMETERS_DISPLAY}
        component={VisualizationParametersDisplay}
        theme="whiteTheme"
      />

      {/* model scenario pages */}
      <DefaultLayout path={Routes.SCENARIO_EXECUTION_QUEUE} component={ScenarioExecutionQueue} theme="darkGreyTheme" />

      {/* externalization pages */}
      <DefaultLayout path={Routes.EXTERNALIZATION_LIST} component={ExternalUserList} theme="greyTheme" />
      <DefaultLayout path={Routes.EXTERNALIZATION_ENROLL} component={ExternalUserEnroll} theme="greyTheme" />
      <Route path={Routes.USER_REGISTRATION} component={HomePage} />
      <Route path={Routes.LANDING_PAGE} component={LoginHomePage} />

      {/* model pages */}
      <DefaultLayout path={Routes.MODEL_DETAIL} component={ModelDetail} theme="greyTheme" />
      <DefaultLayout path={Routes.MODEL_LIST} component={ModelList} theme="greyTheme" />

      {/* admin section */}
      <DefaultLayout path={Routes.USER_DETAIL} exact component={UserDetail} theme="darkTheme" />
      <DefaultLayout path={Routes.USER_EDIT} component={UserEdit} theme="darkTheme" />
      <DefaultLayout path={Routes.USER_ENROLL} component={UserEnroll} theme="darkTheme" />
      <DefaultLayout path={Routes.USER_LIST} component={UserList} theme="darkTheme" />

      <DefaultLayout path={Routes.MANAGE_METADATA_PROPERTY} component={ManageMetadataList} theme="darkTheme" />
      <DefaultLayout path={Routes.MANAGE_METADATA} component={ManageMetadataList} theme="darkTheme" />

      <DefaultLayout path={Routes.INCIDENT_MANAGEMENT} component={IncidentList} theme="darkTheme" />

      <DefaultLayout path={Routes.ADMIN_EXTERNALIZATION_LIST} component={ExternalUserList} theme="darkTheme" />

      <DefaultLayout path={Routes.REGISTRATION_REVIEW} component={RegistrationReview} theme="greyTheme" />
      {/* All notifications page */}
      <DefaultLayout path={Routes.ALL_NOTIFICATIONS} component={AllNotifications} theme="darkTheme" />

      <Redirect from={Routes.ADMIN} to={Routes.USER_LIST} />
      <Redirect from={Routes.EXTERNALIZATION} to={Routes.EXTERNALIZATION_LIST} />

      <Redirect from={Routes.ROOT} to={Routes.MODEL_LIST} exact />
      <Route path={Routes.ROOT} component={Error404Page} />
    </Switch>
  );
};

export default Router;
