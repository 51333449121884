import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from '../../store/rootReducer';
import {authExpirationSelector} from '../../store/authExpiration';
import {DialogGeneric} from '../core/dialog/DialogGeneric';
import {authLogout, loadCurrentUser} from '../../store/auth';
import {useDispatch} from 'react-redux';

export const COUNTDOWN = 60000;

const SessionWillExpire: React.FC = () => {
  const dispatch = useDispatch();
  const expiration = useSelector((state) => authExpirationSelector(state).expiration);

  const calculateSecondsLeft = useCallback(() => {
    const sLeft = Math.round((expiration - Date.now()) / 1000);
    return sLeft > 0 ? sLeft : 0;
  }, [expiration]);

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(calculateSecondsLeft());

  //count down
  useEffect(() => {
    const timer = setTimeout(() => {
      setSecondsLeft(calculateSecondsLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [isDisplayed, secondsLeft, expiration, calculateSecondsLeft]);

  //watch expiration to show up
  useEffect(() => {
    if (expiration > Date.now()) {
      if (expiration - COUNTDOWN <= Date.now()) {
        setSecondsLeft(calculateSecondsLeft());
        setIsDisplayed(true);
      } else {
        setIsDisplayed(false);
        const timer = setTimeout(() => {
          setIsDisplayed(true);
          setSecondsLeft(COUNTDOWN / 1000);
        }, expiration - COUNTDOWN - Date.now());

        return () => {
          setIsDisplayed(false);
          clearTimeout(timer);
        };
      }
    }
  }, [expiration, calculateSecondsLeft]);

  const handleClickContinue = useCallback(() => {
    loadCurrentUser(dispatch);
  }, [dispatch]);

  const handleClickLogout = useCallback(() => {
    authLogout(dispatch, undefined);
  }, [dispatch]);

  if (!isDisplayed) {
    return null;
  }

  return (
    <DialogGeneric
      open={true}
      title="Your Session is about to Expire!"
      buttons={[
        {
          key: 'logout',
          onClick: handleClickLogout,
          children: 'Logout',
        },
        {
          key: 'continue',
          onClick: handleClickContinue,
          color: 'primary',
          children: 'Continue',
          variant: 'contained',
        },
      ]}>
      Your Session is about to Expire.
      <br />
      You will be logged out in {secondsLeft} second{secondsLeft === 1 ? '' : 's'}.
    </DialogGeneric>
  );
};

export default SessionWillExpire;
