import React, {useCallback, useMemo, useState} from 'react';
import DialogConfirmation, {DialogConfirmationProps} from './DialogConfirmation';

type Props = Omit<DialogConfirmationProps, 'open'>;

export const useDialogConfirmation = (props: Props) => {
  const [openProps, setOpenProps] = useState<Props>();

  const mergedProps = {...props, ...openProps};

  const {onClickOk, onClickYes, onClickNo, ...restProps} = mergedProps;

  const handlers = useMemo(() => {
    if (onClickYes) {
      return {
        onClickYes: () => {
          setOpenProps(undefined);
          onClickYes();
        },
        onClickNo: () => {
          setOpenProps(undefined);
          onClickNo?.();
        },
      };
    }

    return {
      onClickOk: () => {
        setOpenProps(undefined);
        onClickOk?.();
      },
    };

    // return
  }, [onClickYes, onClickNo, onClickOk]);

  const open = useCallback((p: Props = {}) => {
    setOpenProps(p);
  }, []);

  const Component = useMemo(() => {
    return <DialogConfirmation {...restProps} {...handlers} open={!!openProps} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlers, openProps]);

  return {Component, open};
};
