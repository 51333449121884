import {BitbucketAction, BitbucketActions} from './types';
import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {BrowseRepoRequest, GetHistoryRequest, GitBrowseDTO, GitCommitDTO, GitProjectDTO, GitRepoDTO} from 'hemwb-api';

export type BitbucketState = {
  projects: AsyncActionState<GitProjectDTO[], []>;
  repositories: {[projectName: string]: AsyncActionState<GitRepoDTO[], [string]>};
  browse: {[path: string]: AsyncActionState<GitBrowseDTO, [BrowseRepoRequest]>};
  history: {[projectRepositoryKey: string]: AsyncActionState<GitCommitDTO[], [GetHistoryRequest]>};
};

export const bitbucketInitialState: BitbucketState = {
  projects: buildInitialAsyncState(),
  repositories: {},
  history: {},
  browse: {},
};

type Key = {
  [key: string]: string;
};

export const bitbucketReducer = (state = bitbucketInitialState, action: BitbucketAction): BitbucketState => {
  switch (action.type) {
    case BitbucketActions.REQUEST_PROJECTS: {
      return {
        ...state,
        projects: reduceAsyncAction(action),
      };
    }

    case BitbucketActions.REQUEST_REPOSITORIES: {
      const [projectName] = action.params;
      return {
        ...state,
        repositories: {
          ...state.repositories,
          [projectName]: reduceAsyncAction(action),
        },
      };
    }

    case BitbucketActions.REQUEST_HISTORY: {
      const {projectName, repoName} = action.params[0];
      return {
        ...state,
        history: {
          ...state.history,
          [`${projectName}-${repoName}`]: reduceAsyncAction(action),
        },
      };
    }

    case BitbucketActions.REQUEST_BROWSE: {
      const {projectName, repoName, path} = action.params[0];
      let clearBrowsed = false;
      if (action.phase === AsyncActionPhase.START) {
        const browsed = Object.values(state.browse);
        if (
          browsed.length > 0 &&
          (browsed[0].params[0].projectName !== projectName || browsed[0].params[0].repoName !== repoName)
        ) {
          clearBrowsed = true;
        }
      }

      return {
        ...state,
        browse: {
          ...(clearBrowsed ? bitbucketInitialState.browse : state.browse),
          [path || '']: reduceAsyncAction(action),
        },
      };
    }
  }
  return state;
};
