import React, {useCallback, useState} from 'react';
import {useMounted} from '../../../../../hooks/useMounted';
import {useDispatch} from 'react-redux';
import {modelRetire} from '../../../../../store/model';
import {trackModelRetired} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {Power16, Power32} from '@carbon/icons-react';
import {tid} from '../../../../../testUtils';
import CheckboxControl from '../../../../core/Form/CheckboxControl';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {ModelActionProps} from '../../../actionMenuTypes';

const ModelRetire: React.FC<ModelActionProps> = ({model, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [valid, setValid] = useState(false);
  const mounted = useMounted();

  const form = useForm(
    new FormGroup({
      reason: new FormControl(null, [Validators.required]),
      agree: new FormControl([], [Validators.required]),
    }),
  );

  const onChange = useCallback(() => {
    setValid(form.valid);
  }, [form]);

  form.valueChanges.subscribe(onChange);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    modelRetire(dispatch, model.uuid, form.controls.reason.value)
      .then(() => {
        trackModelRetired(model);
        dispatch(messageAdd('Model has been retired.', MessageTypes.SUCCESS));
        onSuccess?.();
      })
      .catch(() => {
        dispatch(messageAdd('Failed to retire model', MessageTypes.ERROR));
      })
      .finally(() => {
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, model, form.controls.reason.value, onSuccess, mounted]);

  return (
    <>
      <ActionHeader label="Confirm Retirement" Icon={<Power32 />}>
        This operation can&apos;t be undone
      </ActionHeader>

      <Form
        group={form}
        render={() => {
          return (
            <>
              <TextFieldControl
                name="reason"
                label="Reason"
                required
                TextFieldProps={{
                  inputProps: tid('input-reason'),
                }}
              />
              <CheckboxControl
                name="agree"
                layout="checkboxTop"
                options={['confirm']}
                getOptionLabel={() =>
                  'I confirm that all files in this model entry will be retired and will not be available for users of Model Repository and third parties using Model Repository entries. It will be visible only to model owners, flagged as retired.'
                }
                customErrorMessages={{required: 'You have to check confirmation checkbox'}}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} className="redButton" {...tid('dialog-retire-btn-cancel')} />
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  disabled={!valid}
                  endIcon={<Power16 />}
                  className="redButton"
                  {...tid('dialog-retire-btn-submit')}>
                  Retire Model
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default ModelRetire;
