import {UserDTO, UserType} from 'hemwb-api';
import React from 'react';
import {Form, optionValidator, useForm, DropDownControl} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {fullName, getUserTypeName, userTypes} from '../../../../../store/user/utils';
import {Grid} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import {Routes} from '../../../../router/routes';
import GridTitle from '../../../../core/Form/GridTitle';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';

type UserEditFormProps = {
  user: UserDTO;
  onSubmit: (values: UserEditFormValues) => void;
};

export type UserEditFormValues = {
  userType: UserType;
};

export const UserEditForm: React.FC<UserEditFormProps> = ({user, onSubmit}) => {
  const form = useForm(
    new FormGroup({
      userType: new FormControl(null, [Validators.required, optionValidator(userTypes)]),
    }),
    {
      userType: user.userType,
    },
  );

  return (
    <Form
      group={form}
      onSubmit={() => onSubmit(form.value)}
      render={({submitting}) => (
        <>
          <Grid container direction="column" alignItems="flex-start" justify="space-between" style={{width: '50%'}}>
            <GridTitle heading={fullName(user)}>Edit user type</GridTitle>

            <GridItem>
              <DropDownControl
                name="userType"
                label="User Type"
                required
                options={userTypes.slice(0, 2)}
                getOptionLabel={(option: UserType) => getUserTypeName(option)}
              />
            </GridItem>
          </Grid>

          <div className="buttonsContainer alignLeft">
            <CancelButton disabled={submitting} to={Routes.USER_LIST}></CancelButton>
            <SubmitButton active={submitting}></SubmitButton>
          </div>
        </>
      )}
    />
  );
};
