import React, {useCallback, useMemo, useRef, useState} from 'react';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';
import {requestScenarioGetOutputVisualizationExcelRaw} from '../../../../../store/scenario';
import {trackInstanceVisualizationExportedExcel} from '../../../../../tracking/tracking';
import {downloadBlob} from '../../../../../utils/download';
import {defaultCancelButtonProps} from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';

export const useExcelExport = (
  model?: ModelDTO | null,
  subModel?: ExecutionSetupDTO,
  scenario?: ScenarioDTO | null,
  showProgressDialog = false,
) => {
  const [inProgress, setInProgress] = useState(false);
  const abortRef = useRef(false);

  const abortExcelExport = useCallback(() => {
    abortRef.current = true;
    setInProgress(false);
  }, [abortRef]);

  const exportExcel = useCallback(
    (elementIndex?: number) => {
      abortRef.current = false;
      if (model && subModel && scenario) {
        setInProgress(true);
        requestScenarioGetOutputVisualizationExcelRaw(scenario.id, elementIndex)
          .then((response) => {
            response.value().then((blob) => {
              trackInstanceVisualizationExportedExcel(model, subModel, scenario);
              const name = response.raw.headers
                .get('content-disposition')
                ?.split('filename=')[1]
                ?.toString()
                .replaceAll('"', '');
              !abortRef.current && downloadBlob(blob, name);
            });
          })
          .finally(() => setInProgress(false));
      }
    },
    [model, subModel, scenario, abortRef],
  );

  const ExportExcelComponent = useMemo(() => {
    return inProgress && showProgressDialog ? (
      <DialogGeneric
        open={true}
        title="Please wait"
        buttons={[
          {
            ...defaultCancelButtonProps,
            onClick: abortExcelExport,
            ...tid('dialog', 'btn', 'cancel'),
          },
        ]}>
        Creating and saving your Excel file...
      </DialogGeneric>
    ) : null;
  }, [inProgress, showProgressDialog, abortExcelExport]);

  return {exportExcel, exportExcelInProgress: inProgress, ExportExcelComponent};
};
