import React from 'react';
import {ModelMetadataOptionsDTOOptions, MetadataCountDTO} from 'hemwb-api';
import {useModelMetadataOptionsUsage, useFileTagUsage} from '../../../../../../store/modelMetadata';

type UsageCountTitleProps = {
  property: keyof ModelMetadataOptionsDTOOptions;
  value: string;
  fileTagCount: MetadataCountDTO[] | undefined;
};

const UsageCountTitle: React.FC<UsageCountTitleProps> = ({property, value, fileTagCount}) => {
  const usageList = useModelMetadataOptionsUsage(property);
  const FileTagUsageList = useFileTagUsage(fileTagCount);
  const usages = usageList?.[value];
  const fileTagUsages = FileTagUsageList?.[value];
  const affectedModels = !usages?.length ? fileTagUsages?.length : usages.length;

  if (affectedModels === 0) {
    return <>This operation won&apos;t affect any model entry.</>;
  }

  return <>{`This operation will affect ${affectedModels} model entr${affectedModels === 1 ? 'y' : 'ies'}`}</>;
};

export default UsageCountTitle;
