import React, {useCallback, useState} from 'react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../../hooks/useMounted';
import {scenarioStatusUpdate} from '../../../../../store/scenario';
import {ScenarioStatus} from 'hemwb-api';
import {trackInstanceCalibrationAccepted} from '../../../../../tracking/tracking';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Done} from '@material-ui/icons';

const ScenarioAccept: React.FC<ScenarioActionProps> = ({model, subModel, scenario, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      await scenarioStatusUpdate(dispatch, scenario.id, ScenarioStatus.ACCEPTED);
      trackInstanceCalibrationAccepted(model, subModel, scenario);
      onSuccess?.();
    } catch (e) {
    } finally {
      mounted.current && setSubmitting(false);
    }
  }, [dispatch, mounted, model, subModel, scenario, onSuccess]);

  return (
    <>
      <h2>Accept Instance</h2>
      <p>Are you sure you want to accept this instance? Previously accepted one will be superseded.</p>

      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ACCEPT, 'cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          active={submitting}
          endIcon={<Done />}
          {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ACCEPT, 'submit')}>
          Accept
        </SubmitButton>
      </div>
    </>
  );
};

export default ScenarioAccept;
