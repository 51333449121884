import {useReduxLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {modelInputsListSelectorBuilder} from './selectors';
import {modelInputsCreate, modelInputsDelete, modelInputsLoad} from './actions';
import {useCallback, useEffect, useMemo} from 'react';
import {Dispatch} from 'redux';
import {useDispatch} from 'react-redux';
import {ModelInputPagingResponseDTO, ModelInputType} from 'hemwb-api';

const modelInputsLoadIgnoreNull = (dispatch: Dispatch, executionSetupId?: number) => {
  return executionSetupId ? modelInputsLoad(dispatch, executionSetupId) : Promise.resolve();
};
modelInputsLoadIgnoreNull.clear = modelInputsLoad.clear;

export const useModelInputs = (executionSetupId?: number) => {
  return useReduxLoader(modelInputsListSelectorBuilder, modelInputsLoadIgnoreNull, [executionSetupId]);
};

export const useModelInput = (executionSetupId?: number) => {
  const dispatch = useDispatch();
  const list = useModelInputs(executionSetupId);

  useEffect(() => {
    if (executionSetupId && list && list.list) {
      if (list.list.length === 0) {
        // create a default input definition
        modelInputsCreate(dispatch, executionSetupId, {name: '', type: ModelInputType.JSON, value: '{}'});
      } else if (list.list.length > 1) {
        // remove useless input definitions
        list.list.forEach((input, index) => {
          index > 1 && modelInputsDelete(dispatch, executionSetupId, input.id);
        });
      }
    }
  }, [dispatch, list, executionSetupId]);

  return useMemo(() => {
    return list?.list?.[0];
  }, [list]);
};

export const useModelInputsLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(modelInputsListSelectorBuilder, modelInputsLoadIgnoreNull);
};

export const useModelInputLoaderWithParams = () => {
  const dispatch = useDispatch();
  const loadList = useModelInputsLoaderWithParams();

  // @ts-ignore
  return useCallback(
    async (executionSetupId) => {
      const list = (await loadList(executionSetupId)) as ModelInputPagingResponseDTO;
      if (list && list.list) {
        if (list.list.length === 0) {
          // create a default input definition
          return modelInputsCreate(dispatch, executionSetupId, {name: '', type: ModelInputType.JSON, value: '{}'});
        } else if (list.list.length > 1) {
          // remove useless input definitions
          list.list.forEach((input, index) => {
            index > 1 && modelInputsDelete(dispatch, executionSetupId, input.id);
          });
        }

        if (list.list.length > 0) {
          return Promise.resolve(list.list[0]);
        }
      }

      // return (load as any) as (executionSetupId: number) => Promise<ModelInputDetailDTO>;
    },
    [dispatch, loadList],
  );
};
