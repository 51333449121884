import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './VisualizationStep2.module.scss';
import builderStyles from '../../scenario/editor/ScenarioParametersBuilder.module.scss';
import sidebarStyles from '../step1/LeftSideBar.module.scss';
import DefaultPage from '../../../layout/common/DefaultPage';
import {useDispatch} from 'react-redux';
import {getUrlRoute, Routes, useMatchedRoute} from '../../../router/routes';
import {
  isScenarioRunning,
  scenarioCreate,
  scenarioUpdate,
  scenarioUpdateInputs,
  useScenarioDetail,
} from '../../../../store/scenario';
import {useHistory} from 'react-router';
import {useModelInput} from '../../../../store/modelInputs';
import {getSubModelColorHashCode, scenarioGridColumns} from '../common/constants';
import InstanceTableCard from '../common/InstanceTableCard';
import ScenarioParameters, {
  ScenarioParametersRefType,
} from '../../../core/ModelInput/ScenarioParameters/ScenarioParameters';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import SideBar from '../../../layout/common/SideBar';
import {Analytics16} from '@carbon/icons-react';
import {CalibrationScenarioInputs} from '../../../core/ModelInput/ScenarioParameters/types';

import {DragDropContext, Draggable, DraggableProvided, Droppable} from 'react-beautiful-dnd';
import VisualizationStepper, {VisualizationSteps} from '../common/VisualizationStepper';
import {useSelector} from '../../../../store/rootReducer';
import {
  visualizationDiscardChanges,
  visualizationInit,
  visualizationSelector,
  visualizationSetParameters,
  visualizationSortScenarios,
  visualizationToggleSelection,
} from '../../../../store/visualization';
import {scenarioExecutionCreate, scenarioExecutionExecute} from '../../../../store/scenarioExecution';
import {messageAdd, MessageTypes} from '../../../../store/message';
import {FormGroup} from 'react-reactive-form';
import {associateFormValuesWithName} from '../../../core/ModelInput/ScenarioParameters/utils';
import VisualizationStep2ActionMenu from './VisualizationStep2ActionMenu';
import {useModel} from '../../../../store/model';
import debounce from 'debounce-promise';
import {useAnalysisLoader} from '../common/useAnalysisLoader';
import CancelButton from '../../../core/Buttons/CancelButton';
import {ExecutionSetupType} from 'hemwb-api';
import {stringifyScenarioParameters} from '../../../core/ModelInput/ScenarioParameters/inputs/stringifyScenarioParameters';
import {useDialogConfirmation} from '../../../core/dialog/useDialogConfirmation';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {trackInstanceExecuted, trackInstanceExecutionCreated} from '../../../../tracking/tracking';
import {useAuth} from '../../../../store/auth';
import {useUserExpertise} from '../../../../store/userExpertise';
import {trackSettingParametersTabEvent} from '../../../../tracking/tracking';

const values = {};

const VisualizationStep2: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {modelUuid, scenarioId: _scenarioId} = useMatchedRoute(Routes.VISUALIZATION_STEP_2);
  const scenarioId = Number(_scenarioId);
  const model = useModel(modelUuid);
  const scenarioDetail = useScenarioDetail(scenarioId);
  const formObjectRef = useRef<FormGroup>();
  const [timestamp, setTimestamp] = useState(Date.now());

  const scenarioInProgress = isScenarioRunning(scenarioDetail?.status);
  const {auth} = useAuth();
  const user = auth.loggedInUser;
  const userid = user ? user.id : '';
  const [expertiseLevel, setExpertiseLevel] = useState<number>();

  useAnalysisLoader(modelUuid, scenarioId);

  useEffect(() => {
    dispatch(visualizationInit(modelUuid));
  }, [dispatch, modelUuid]);

  const storedState = useSelector(visualizationSelector);
  const {selectedScenarios = {}, scenarios, subModel} = storedState.selection || {};
  const parameters =
    storedState.parameters?.displaySubModelId === subModel?.displayExecutionSetupId
      ? storedState.parameters?.parameters || values
      : values;

  useEffect(() => {
    if (!subModel && !scenarioId) {
      history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid}));
    }
  }, [history, subModel, scenarioId, modelUuid]);

  const scenarioColor = getSubModelColorHashCode(subModel);
  const input = useModelInput(subModel?.displayExecutionSetupId);
  const definitions = useMemo(() => {
    const parsed = input?.value ? JSON.parse(input.value) : {};
    return parsed.definitions || [];
  }, [input]);

  const [submitting, setSubmitting] = useState(false);

  const dispatchSetParameters = useCallback(() => {
    dispatch(
      visualizationSetParameters(
        associateFormValuesWithName(definitions, formObjectRef?.current?.value.parameters),
        formObjectRef.current!.valid,
        subModel!.displayExecutionSetupId!,
      ),
    );
  }, [dispatch, definitions, subModel]);

  useEffect(() => {
    const formObject = formObjectRef.current;
    if (formObject && subModel && definitions) {
      const handleChange = debounce(() => {
        dispatchSetParameters();
      }, 200);

      handleChange();
      formObject.valueChanges.subscribe(handleChange);
      return () => {
        formObject.valueChanges.unsubscribe(handleChange);
      };
    }
  }, [timestamp, subModel, definitions, formObjectRef, dispatchSetParameters]);

  const handleDiscardChanges = useCallback(() => {
    dispatch(visualizationDiscardChanges());
    setTimestamp(Date.now());
  }, [dispatch]);

  // eslint-disable-next-line
  const handleSubmit = useCallback(
    async (value: CalibrationScenarioInputs) => {
      if (model) {
        trackSettingParametersTabEvent(model);
      }

      if (!subModel || !input) {
        return;
      }
      dispatchSetParameters();
      setSubmitting(true);

      const displayScenarios = scenarios!
        .filter((s) => selectedScenarios[s.id])
        .map((s, index) => ({
          id: s.id,
          label: s.label || s.name,
          order: index + 1,
        }));

      try {
        let newScenario;
        if (scenarioDetail) {
          newScenario = await scenarioUpdate(dispatch, scenarioDetail.id, {
            name: scenarioDetail.name,
            displayScenarios,
          });
        } else {
          newScenario = await scenarioCreate(dispatch, subModel.displayExecutionSetupId!, {
            name: `${
              subModel.type === ExecutionSetupType.CALIBRATION ? 'Calibration' : 'Scenario'
            } Analysis ${Date.now()}`,
            hidden: true,
            displayScenarios,
          });
        }

        await scenarioUpdateInputs(dispatch, newScenario.id, {
          modelInputId: input!.id,
          value: stringifyScenarioParameters(input!, value),
        });

        const group = await scenarioExecutionCreate(dispatch, newScenario.id);
        trackInstanceExecutionCreated(model!, subModel, newScenario);

        if (!group.groupId) {
          dispatch(messageAdd('Failed to create an execution', MessageTypes.ERROR));
          setSubmitting(false);
        } else {
          await scenarioExecutionExecute(dispatch, group.groupId);
          trackInstanceExecuted(model!, subModel, newScenario);
          history.push(
            getUrlRoute(Routes.VISUALIZATION_STEP_3, {
              modelUuid,
              scenarioId: newScenario.id,
            }),
          );
        }
      } catch (e) {
        dispatch(messageAdd('Failed to create an execution', MessageTypes.ERROR));
        setSubmitting(false);
      }
    },
    [
      history,
      dispatch,
      scenarioDetail,
      model,
      modelUuid,
      scenarios,
      selectedScenarios,
      subModel,
      input,
      dispatchSetParameters,
    ],
  );

  const inputListPortalRef = useRef<HTMLDivElement>(null);
  const scenarioParametersRef = useRef<ScenarioParametersRefType>();

  const [isDragOccurring, setIsDragOccurring] = useState(false);
  const onBeforeDragStart = useCallback(() => {
    setIsDragOccurring(true);
  }, []);
  const onDragEnd = useCallback(
    (result) => {
      setIsDragOccurring(false);
      if (!result.source || !result.destination || result.source.index === result.destination.index) {
        return;
      }
      !scenarioInProgress && dispatch(visualizationSortScenarios(result.source.index, result.destination.index));
    },
    [dispatch, scenarioInProgress],
  );

  const {Component: InvalidParametersDialog, open: openInvalidParametersDialog} = useDialogConfirmation({
    title: 'Parameters are not valid',
    text: 'Parameters have to fixed before you can execute visualization.',
  });

  const expertiseLevelObject = useUserExpertise(modelUuid, userid);

  useEffect(() => {
    setExpertiseLevel(Number(expertiseLevelObject?.expertiseTypeLevel?.split('-')[0]));
  }, [expertiseLevelObject]);

  if (!model) {
    return (
      <DefaultPage>
        <SuspenseNull />
      </DefaultPage>
    );
  }

  // @ts-ignore
  return (
    <>
      <SideBar position="left" className={sidebarStyles.container} style={{width: '24rem'}}>
        <h3 style={{paddingLeft: 15}}>Parameters</h3>
        <div ref={inputListPortalRef} />
      </SideBar>
      <DefaultPage style={{position: 'relative'}}>
        <div className={styles.container}>
          <VisualizationStepper step={VisualizationSteps.STEP_2} />
          <div className={styles.contentContainer}>
            <div className="lightweightTheme">
              {scenarios && (
                <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
                  <table
                    className="MuiTable-root MuiTable-stickyHeader"
                    style={{borderSpacing: 0, width: '100%', tableLayout: 'auto'}}>
                    <thead className="MuiTableHead-root">
                      <tr className="MuiTableRow-root MuiTableRow-head">
                        {scenarioGridColumns.map((c, i) => (
                          <th
                            key={i}
                            className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall MuiTableCell-stickyHeader">
                            <span className="MuiButtonBase-root MuiTableSortLabel-root TableWithSortAndPagination_headerLabel__2XHcY">
                              {c.label}
                            </span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <Droppable droppableId="table">
                      {(droppableProvided) => (
                        <tbody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                          className="MuiTableBody-root">
                          {scenarios.map((s, index) => (
                            <React.Fragment key={s.id}>
                              <Draggable draggableId={String(s.id)} index={index}>
                                {(provided: DraggableProvided) => {
                                  const checked = selectedScenarios[s.id];
                                  return (
                                    // @ts-ignore
                                    <InstanceTableCard
                                      scenario={s}
                                      model={model}
                                      subModel={subModel!}
                                      color={checked ? scenarioColor : undefined}
                                      checked={checked}
                                      onCheckedChange={(scenario) =>
                                        dispatch(visualizationToggleSelection(subModel!, scenario))
                                      }
                                      ref={provided.innerRef}
                                      provided={provided}
                                      isDragOccurring={isDragOccurring}
                                      scenarioInProgress={scenarioInProgress}
                                    />
                                  );
                                }}
                              </Draggable>
                            </React.Fragment>
                          ))}
                          {droppableProvided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              )}
            </div>

            {input?.value && (
              <div className={styles.parametersWrapper}>
                <ScenarioParameters
                  expertiseLevel={expertiseLevel ? expertiseLevel : 500}
                  key={timestamp}
                  subModelType={ExecutionSetupType.DISPLAY}
                  ref={scenarioParametersRef}
                  // key={input?.value || ''}
                  definitions={definitions}
                  values={parameters}
                  onSubmit={handleSubmit}
                  submitting={submitting}
                  inputListPortalRef={inputListPortalRef}
                  formObjectRef={formObjectRef}
                  renderStatic={scenarioInProgress}
                  modelUuid={modelUuid}
                />
              </div>
            )}
            <div className={builderStyles.footer}>
              <div className="buttonsContainer">
                <CancelButton disabled={!storedState.analyseDataChanged} onClick={handleDiscardChanges}>
                  Discard Changes
                </CancelButton>
                <SubmitButton
                  endIcon={<Analytics16 />}
                  // disabled={!formObjectRef.current?.valid}
                  active={submitting || scenarioInProgress}
                  onClick={() =>
                    formObjectRef.current?.valid
                      ? scenarioParametersRef.current?.submit()
                      : openInvalidParametersDialog()
                  }>
                  Create Data Visualization
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </DefaultPage>

      <VisualizationStep2ActionMenu
        modelUuid={modelUuid}
        model={model || undefined}
        scenario={scenarioDetail || undefined}
        scenarioInProgress={scenarioInProgress}
      />

      {InvalidParametersDialog}
    </>
  );
};

export default VisualizationStep2;
