import React from 'react';
import {ScenarioActionProps} from '../../actionMenuTypes';
import {useLocation} from 'react-router';
import {getUrlRoute, Routes} from '../../../router/routes';
import SideBarLinkItem from '../../../layout/common/SideBarLinkItem';
import {tid} from '../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../test/types';
import {canEditInstance} from '../../../../permissions/usePermissions';
import {RequestQuote16} from '@carbon/icons-react';

const ScenarioParametersEditorActionMenuLink: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const location = useLocation();
  const link =
    props.model && props.scenario
      ? getUrlRoute(Routes.SCENARIO_PARAMETERS_EDITOR, {
          modelUuid: props.model.uuid,
          scenarioId: props.scenario.id,
        })
      : '#';

  return (
    <SideBarLinkItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_EDITOR)}
      selected={location.pathname === link}
      permission={canEditInstance(props.model, props.subModel, props.scenario)}
      linkTo={link}
      icon={<RequestQuote16 />}
      text="Editor"
    />
  );
};

export default ScenarioParametersEditorActionMenuLink;
