import {ModelLinksApi} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const modelLinksApi = new ModelLinksApi(configuration);

export const requestModelHierarchy = (modelUuid: string) => modelLinksApi.getModelHierarchy({modelUuid});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const requestSetModelParent = (modelUuid: string, parentUuid: string | null, remainStore?: boolean) =>
  modelLinksApi.setModelParent({modelUuid, body: parentUuid || undefined});

// eslint-disable-next-line @typescript-eslint/camelcase
export const requestModelHierarchyRootsByMetadata = (product: string, disease: string, therapeutic_area: string) =>
  // eslint-disable-next-line @typescript-eslint/camelcase
  modelLinksApi.findModelHierarchyRootsByMetadata({requestBody: {product, disease, therapeutic_area}});
