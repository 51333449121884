import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import VisualizationAnalysisProgressSave, {
  VisualizationAnalysisProgressSaveProps,
} from './VisualizationAnalysisProgressSave';

export const useVisualizationAnalysisProgressSaveSideBarModal = (
  props: Partial<VisualizationAnalysisProgressSaveProps>,
) => {
  const {model, displaySubModelId, scenario, onSuccess, onSuccessRoute} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model, displaySubModelId, scenario);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      displaySubModelId && (
        <SideBarModal
          height={450}
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <VisualizationAnalysisProgressSave
                model={model}
                displaySubModelId={displaySubModelId}
                scenario={scenario}
                onCancel={closeModal}
                onSuccess={handleSuccess}
                onSuccessRoute={onSuccessRoute}
              />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, model, scenario, displaySubModelId, onSuccessRoute, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};
