import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {ScenarioDetailDTO, ScenarioDTO, ScenarioListDTO} from 'hemwb-api';
import {ScenarioAction, ScenarioActions} from './types';
import {Await} from '../types';
import {requestScenarioDetail, requestScenarioList, requestScenarioLoadError} from './api';

export type ScenarioState = {
  detail: {
    [id: number]: AsyncActionState<
      Await<ReturnType<typeof requestScenarioDetail>>,
      Parameters<typeof requestScenarioDetail>
    > & {timestamp: number};
  };
  error: {
    [id: number]: AsyncActionState<
      Await<ReturnType<typeof requestScenarioLoadError>>,
      Parameters<typeof requestScenarioLoadError>
    >;
  };
  list: AsyncActionState<Await<ReturnType<typeof requestScenarioList>>, Parameters<typeof requestScenarioList>>;
  lastStatusUpdate: ScenarioDTO | null;
};

export const scenarioInitialState: ScenarioState = {
  detail: {},
  error: {},
  list: buildInitialAsyncState<ScenarioListDTO>(),
  lastStatusUpdate: null,
};

const updateScenario = (state: ScenarioState, scenarioId: number, data: Partial<ScenarioDetailDTO>): ScenarioState => {
  let updatedDetail, updatedList;

  if (state.detail[scenarioId]) {
    const newValue = {...state.detail[scenarioId].value, ...data} as ScenarioDetailDTO;
    updatedDetail = {
      ...state.detail,
      [scenarioId]: {
        ...state.detail[scenarioId],
        value: newValue,
      },
    };
  }

  if (state.list.value && state.list.value.list.some((s) => s.id === scenarioId)) {
    updatedList = {
      ...state.list,
      value: {
        ...state.list.value,
        list: state.list.value.list.map((s) => (s.id === scenarioId ? {...s, ...data} : s)),
      },
    };
  }

  if (updatedDetail || updatedList) {
    return {
      ...state,
      detail: updatedDetail || state.detail,
      list: updatedList || state.list,
    };
  }

  return state;
};

export const scenarioReducer = (state = scenarioInitialState, action: ScenarioAction): ScenarioState => {
  switch (action.type) {
    case ScenarioActions.REQUEST_DETAIL: {
      const [scenarioID] = action.params;
      return {
        ...state,
        detail: {
          ...state.detail,
          [scenarioID]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ScenarioActions.REQUEST_ERROR: {
      const [scenarioID] = action.params;
      return {
        ...state,
        error: {
          ...state.error,
          [scenarioID]: reduceAsyncAction(action),
        },
      };
    }

    case ScenarioActions.REQUEST_UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [scenarioId, data] = action.params;
        return updateScenario(state, scenarioId, data);
      }

      return state;
    }

    case ScenarioActions.REQUEST_UPDATE_LABEL: {
      if (action.phase === AsyncActionPhase.START) {
        const [scenarioId, data] = action.params;
        return updateScenario(state, scenarioId, data);
      }

      return state;
    }

    case ScenarioActions.REQUEST_UPDATE_INPUTS: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [scenarioID] = action.params;
        const newDetail = {...state.detail};
        delete newDetail[scenarioID];
        return {
          ...state,
          detail: newDetail,
        };
      }
      return state;
    }

    case ScenarioActions.REQUEST_LIST: {
      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          list: {...reduceAsyncAction(action), value: state.list.value},
        };
      }

      if (action.phase !== AsyncActionPhase.SUCCESS) {
        return {
          ...state,
          list: reduceAsyncAction(action),
          lastStatusUpdate: null,
        };
      }
      const newDetail = {...state.detail};
      action.value.list.forEach((scenario) => {
        if (newDetail[scenario.id] && newDetail[scenario.id].state === AsyncActionPhase.SUCCESS) {
          newDetail[scenario.id] = {
            ...newDetail[scenario.id],
            value: {
              ...newDetail[scenario.id].value,
              ...scenario,
            } as ScenarioDetailDTO,
          };
        }
      });
      return {...state, list: reduceAsyncAction(action), lastStatusUpdate: null, detail: newDetail};
    }

    case ScenarioActions.UPDATE_STATUS: {
      const {scenario} = action;
      return {
        ...updateScenario(state, scenario.id, scenario),
        lastStatusUpdate: scenario,
      };
    }

    default:
      return state;
  }
};
