import React from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ModelDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import {Password24} from '@carbon/icons-react';
import ModelFileManageAccessTemplate from './ModelFileManageAccessTemplate';

type ModelFileManageAccessDialogProps = {
  model: ModelDTO;
  open: boolean;
  fileUuid: string;
  fileName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const ModelFileManageAccessDialog: React.FC<ModelFileManageAccessDialogProps> = ({
  model,
  open,
  onSuccess,
  onCancel,
  fileUuid,
  fileName,
}) => {
  return (
    <DialogGeneric
      open={open}
      title={
        <>
          <div style={{paddingLeft: '1rem'}}>
            <div style={{position: 'absolute', left: '18px', top: '26px'}}>
              <Password24 />
            </div>
            <div style={{fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px'}}>File Access</div>
            <div style={{fontSize: '10px', paddingLeft: '10px'}}>{fileName}</div>
          </div>
        </>
      }
      PaperProps={tid('dialog-request-file-access') as {}}
      childrenAsText={false}>
      <ModelFileManageAccessTemplate
        model={model}
        fileUuid={fileUuid}
        fileName={fileName}
        onSuccess={onSuccess}
        onCancel={onCancel}
        displayInDialog={true}
      />
    </DialogGeneric>
  );
};

export default ModelFileManageAccessDialog;
