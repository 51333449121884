import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {Wikis20} from '@carbon/icons-react';
import CardNode, {CardNodeColumn, CardNodeSubtitle} from '../../../core/carbonui/CardNode';

type ModelCardProps = {
  model: ModelDTO;
};

const ModelCard: React.FC<ModelCardProps> = ({model}) => {
  return (
    <div className="cardNode">
      <CardNode color="red" theme="greyTheme">
        <CardNodeColumn>
          <Wikis20 />
        </CardNodeColumn>
        <CardNodeColumn>
          <CardNodeSubtitle>{model.name}</CardNodeSubtitle>
        </CardNodeColumn>
      </CardNode>
    </div>
  );
};

export default ModelCard;
