import React, {useCallback} from 'react';
import {Routes, useMatchedRoute} from '../../../router/routes';
import {useModel} from '../../../../store/model';
import {useModelInputs} from '../../../../store/modelInputs';
import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_STRING_OPTIONS,
} from '../../../core/TableWithSortAndPagination';
import {ModelInputDetailDTO} from 'hemwb-api';
import {Order, useGridState} from '../../../../store/grid';
import {useLastValidValue} from '../../../../hooks/useLastValidValue';
import {useToggleSelected} from '../../../../hooks/useToggleSelected';
import DefaultPage from '../../../layout/common/DefaultPage';
import SubModelActionMenu from '../../subModel/common/SubModelActionMenu/SubModelActionMenu';
import {typeToSection, useExecutionSetup} from '../../../../store/executionSetup';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';

const columns: Column<ModelInputDetailDTO>[] = [
  {id: 'name', label: 'Name', sortable: true, value: (i) => i.name},
  {
    id: 'createdOn',
    label: 'Created',
    sortable: true,
    value: (es) => es.createdOn,
    display: (es) => es.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
  },
  {
    id: 'updatedOn',
    label: 'Modified',
    sortable: true,
    value: (es) => es.modifiedOn,
    display: (es) => es.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS) || '',
  },
];

const ModelInputList: React.FC = () => {
  const {modelUuid, subModelId} = useMatchedRoute(Routes.MODEL_INPUT_LIST);
  const model = useModel(modelUuid);
  const subModel = useExecutionSetup(Number(subModelId), modelUuid);
  const list = useModelInputs(Number(subModelId))?.list;
  const section = typeToSection(subModel?.type);

  const gridState = useGridState({orderBy: 'name', order: Order.ASC});
  const storedList = useLastValidValue(list);

  const {selected, toggleSelected} = useToggleSelected();
  const handleClickRow = useCallback(
    (row: ModelInputDetailDTO) => {
      toggleSelected(row.id);
    },
    [toggleSelected],
  );

  return (
    <>
      <DefaultPage>
        <SuspenseNull>
          {model && subModel && (
            <>
              <SubModelSectionHeader
                model={model}
                section={section}
                subModel={subModel}
                title="Input Definition List"
              />
              <TableWithSortAndPagination<ModelInputDetailDTO>
                getRowId={(o) => o.id}
                rows={storedList || []}
                columns={columns}
                onClickRow={handleClickRow}
                isRowSelected={(o) => o?.id === selected}
                gridState={gridState}
                loading={!list}
                noResultsMessage="No Input Definition was found"
              />
            </>
          )}
        </SuspenseNull>
      </DefaultPage>
      <SubModelActionMenu model={model} subModel={subModel} section={section} />
    </>
  );
};

export default ModelInputList;
