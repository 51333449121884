import {ScenarioGroupDetailDTO} from 'hemwb-api';

export type InstanceGroupAssociation = {[id: number]: ScenarioGroupDetailDTO};

export enum ScenarioGridFilter {
  ALL_INSTANCES,
  ALL_MY_INSTANCES,
  COMPLETED_SCENARIOS,
  SAVED_ANALYSES,
}
