import React from 'react';
import {List} from '@material-ui/core';
import ListItem from '../../../core/materialui/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {ScenarioActionProps} from '../../actionMenuTypes';
import {
  canAcceptInstance,
  canCancelInstanceExecution,
  canCloneInstance,
  canDeleteInstance,
  canExecuteInstance,
  canSupersedeInstance,
  canUnacceptInstance,
} from '../../../../permissions/usePermissions';
import ButtonWithPopper from '../../../core/ButtonWithPopper/ButtonWithPopper';
import {useScenarioDeleteDialog} from '../../scenario/common/ScenarioDelete/useScenarioDeleteDialog';
import {useScenarioCancelDialog} from '../../scenario/common/ScenarioCancel/useScenarioCancelDialog';
import {useScenarioExecuteDialog} from '../../scenario/common/ScenarioExecute/useScenarioExecuteDialog';
import {useScenarioAcceptDialog} from '../../scenario/common/ScenarioAccept/useScenarioAcceptDialog';
import {useScenarioUnacceptDialog} from '../../scenario/common/ScenarioUnaccept/useScenarioUnacceptDialog';
import {useScenarioSupersedeDialog} from '../../scenario/common/ScenarioSupersede/useScenarioSupersedeDialog';
import {useScenarioCloneDialog} from '../../scenario/common/ScenarioClone/useScenarioCloneDialog';
import useScenarioParametersExport from '../../scenario/common/ScenarioParametersExport/useScenarioParametersExport';
import styles from '../../../../components/core/ButtonWithPopper/ButtonWithPopper.module.scss';
import {useScenarioDetailDialog} from '../../scenario/detail/useScenarioDetailDialog';
import {ExecutionSetupType} from 'hemwb-api';

const InstanceActionPopperContent: React.FC<ScenarioActionProps> = (props) => {
  const {model, subModel, scenario} = props;
  const {Component: DeleteInstanceComponent, open: openDeleteInstance} = useScenarioDeleteDialog();
  const {Component: ExecuteInstanceComponent, open: openExecuteInstance} = useScenarioExecuteDialog();
  const {Component: CancelInstanceComponent, open: openCancelInstance} = useScenarioCancelDialog();
  const {Component: AcceptInstanceComponent, open: openAcceptInstance} = useScenarioAcceptDialog();
  const {Component: UnacceptInstanceComponent, open: openUnacceptInstance} = useScenarioUnacceptDialog();
  const {Component: SupersedeInstanceComponent, open: openSupersedeInstance} = useScenarioSupersedeDialog();
  const {Component: CloneInstanceComponent, open: openCloneInstance} = useScenarioCloneDialog();
  const {Component: DetailInstanceComponent, open: openDetailInstance} = useScenarioDetailDialog();
  const download = useScenarioParametersExport({model, subModel, scenario});

  return (
    <>
      <ButtonWithPopper
        popperButtonSize="small"
        group="instanceActions"
        placement="bottom-end"
        renderPopperContent={({handleClose}) => (
          <List dense component="nav" className={styles.actionlist}>
            <ListItem
              button
              disableGutters
              onClick={() => {
                handleClose();
                openDetailInstance(props);
              }}>
              <ListItemText primary="View Details" />
            </ListItem>
            <ListItem
              button
              permission={canCloneInstance(props.model, props.subModel, props.scenario)}
              disableGutters
              onClick={() => {
                handleClose();
                openCloneInstance({...props, sayCopyInsteadOfClone: true});
              }}>
              <ListItemText primary="Copy" />
            </ListItem>
            <ListItem
              button
              disableGutters
              onClick={() => {
                handleClose();
                download();
              }}>
              <ListItemText primary="Export" />
            </ListItem>
            <ListItem
              button
              permission={canExecuteInstance(props.model, props.subModel, props.scenario)}
              disableGutters
              onClick={() => {
                handleClose();
                openExecuteInstance(props);
              }}>
              <ListItemText primary="Execute" />
            </ListItem>
            <ListItem
              button
              permission={canCancelInstanceExecution(props.model, props.subModel, props.scenario)}
              disableGutters
              onClick={() => {
                handleClose();
                openCancelInstance(props);
              }}>
              <ListItemText primary="Cancel Execution" />
            </ListItem>
            {subModel.type === ExecutionSetupType.CALIBRATION && (
              <>
                <ListItem
                  button
                  permission={canAcceptInstance(props.model, props.subModel, props.scenario)}
                  disableGutters
                  onClick={() => {
                    handleClose();
                    openAcceptInstance(props);
                  }}>
                  <ListItemText primary="Accept" />
                </ListItem>
                <ListItem
                  button
                  permission={canUnacceptInstance(props.model, props.subModel, props.scenario)}
                  disableGutters
                  onClick={() => {
                    handleClose();
                    openUnacceptInstance(props);
                  }}>
                  <ListItemText primary="Unaccept" />
                </ListItem>
                <ListItem
                  button
                  permission={canSupersedeInstance(props.model, props.subModel, props.scenario)}
                  disableGutters
                  onClick={() => {
                    handleClose();
                    openSupersedeInstance(props);
                  }}>
                  <ListItemText primary="Supersede" />
                </ListItem>
              </>
            )}
            <ListItem
              divider
              button
              className={styles.redButton}
              permission={canDeleteInstance(props.model, props.subModel, props.scenario)}
              disableGutters
              onClick={() => {
                handleClose();
                openDeleteInstance(props);
              }}>
              <ListItemText primary="Delete Instance" />
            </ListItem>
          </List>
        )}
        theme="expandedButton"
      />
      {DeleteInstanceComponent}
      {ExecuteInstanceComponent}
      {CancelInstanceComponent}
      {AcceptInstanceComponent}
      {UnacceptInstanceComponent}
      {SupersedeInstanceComponent}
      {CloneInstanceComponent}
      {DetailInstanceComponent}
    </>
  );
};

export default InstanceActionPopperContent;
