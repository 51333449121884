import {useHistory} from 'react-router';
import {useCallback, useEffect, useRef} from 'react';
import {LocationState, UnregisterCallback} from 'history';

export type InterceptedLocation = LocationState | 'BACK';
export type Listener = () => void;

export const useNavigationInterceptor = () => {
  const history = useHistory();
  const locationRef = useRef(history.location);
  const isEnabledRef = useRef(false);
  const listenerRef = useRef<Listener>();
  const interceptedLocationRef = useRef<InterceptedLocation>();
  const removeBlockRef = useRef<UnregisterCallback>();

  const setEnabled = useCallback(
    (isEnabled: boolean) => {
      isEnabledRef.current = isEnabled;
      if (isEnabled && !removeBlockRef.current) {
        removeBlockRef.current = history.block((location: LocationState) => {
          if (location.pathname === history.location.pathname && location.search === history.location.search) {
            interceptedLocationRef.current = 'BACK';
          } else {
            interceptedLocationRef.current = location;
          }
          listenerRef.current && listenerRef.current();
          return false;
        });
      } else if (!isEnabled && removeBlockRef.current) {
        removeBlockRef.current();
      }
    },
    [history],
  );

  const getEnabled = useCallback(() => {
    return isEnabledRef.current;
  }, []);

  const goInterceptedLocation = useCallback(() => {
    removeBlockRef.current && removeBlockRef.current();
    if (interceptedLocationRef.current === 'BACK') {
      isEnabledRef.current = false;
      history.go(-1);
    } else if (interceptedLocationRef.current) {
      history.push(interceptedLocationRef.current);
    }
  }, [history]);

  useEffect(() => {
    const removeListener = history.listen((newLocation, action) => {
      if (action === 'POP') {
        if (isEnabledRef.current) {
          interceptedLocationRef.current = 'BACK';
          listenerRef.current && listenerRef.current();
          history.go(1);
        } else if (
          newLocation.pathname === locationRef.current.pathname &&
          newLocation.search === locationRef.current.search
        ) {
          history.go(-1);
        }
      }
    });

    history.push({
      pathname: history.location.pathname,
      search: `${history.location.search}`,
      hash: history.location.hash === '#block' ? '' : 'block',
    });
    return () => {
      setTimeout(() => {
        removeListener();
      });
    };
  }, [history]);

  return {
    onNavigationIntercepted: (listener: Listener) => {
      listenerRef.current = listener;
    },
    goInterceptedLocation,
    setEnabled,
    getEnabled,
  };
};
