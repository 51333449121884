import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {FormControl, Select, InputLabel, MenuItem, SelectProps, FormHelperText} from '@material-ui/core';
import {getErrorText, getVisibleErrors} from './utils';
import {tid} from '../../../testUtils';
import clsx from 'clsx';

type DropDownControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label: React.ReactNode;
  SelectProps?: SelectProps;
  plural?: boolean;
  required?: boolean;
  disabled?: boolean;
  options: any[];
  getOptionLabel?: (option: any) => string;
};

export const DropDownControl: React.FC<DropDownControlProps> = ({
  label,
  SelectProps,
  plural,
  required,
  disabled = false,
  options,
  getOptionLabel = (option) => option,
  ...FieldControlProps
}) => {
  return (
    <FieldControl
      {...FieldControlProps}
      render={(renderProps) => {
        const {handler} = renderProps;
        const visibleErrors = getVisibleErrors(renderProps);
        const hasVisibleErrors = Object.keys(visibleErrors).length > 0;
        const handlers = handler();
        return (
          <FormControl fullWidth error={hasVisibleErrors} hiddenLabel={true} className="MuiFormControl-hem">
            <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
              {label}
            </InputLabel>
            <Select
              {...tid('input', FieldControlProps.name!)}
              margin="dense"
              variant="filled"
              className={clsx(SelectProps?.className, {'Mui-disabled': disabled || handlers.disabled})}
              {...handlers}
              inputProps={{
                ...tid('input-field', FieldControlProps.name!),
                disabled: disabled || handlers.disabled,
              }}
              {...SelectProps}
              style={{marginTop: '2.2rem'}}>
              {options?.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  {...tid('option', FieldControlProps.name!, getOptionLabel ? getOptionLabel(option) : option)}>
                  {getOptionLabel ? getOptionLabel(option) : option}
                </MenuItem>
              ))}
            </Select>
            {hasVisibleErrors && <FormHelperText>{getErrorText(visibleErrors, String(label), plural)}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
