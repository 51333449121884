import React from 'react';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {InputGenerator, InputType, LabelInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {TextFieldControl} from '../../../Form';
import GridItem from '../../../Form/GridItem';

type ControlsMap = {[key in keyof Required<LabelInputAttributes>]: AbstractControl};

type StringInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const LabelInput: React.FC<StringInputProps> = ({formGroup}) => {
  const defaultValueControl = formGroup.get('defaultValue');
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <GridItem>
        <TextFieldControl label="Default Value" control={defaultValueControl} name="defaultValue" />
      </GridItem>
    </>
  );
};

export const labelInputGenerator: InputGenerator<InputType.LABEL> = {
  type: InputType.LABEL,
  buildControl: (initialValues = {}) =>
    new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      defaultValue: new FormControl(initialValues.defaultValue ?? null, Validators.required),
    } as ControlsMap),
  render: LabelInput,
};
