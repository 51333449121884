import {Dispatch} from 'redux';
import {scenarioGroupLoadList} from './actions';
import {useReduxLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {RootState} from '../rootReducer';
import {scenarioGroupSelector} from './selectors';
import {useCallback} from 'react';
import {ScenarioGroupListDTO} from 'hemwb-api';

const listSelectorBuilder = (params: [string]) => (state: RootState) =>
  scenarioGroupSelector(state).list[params[0] || ''] || null;

const scenarioGroupLoadListIgnoreNull = (dispatch: Dispatch, modelUuid: string | null | undefined) => {
  return modelUuid ? scenarioGroupLoadList(dispatch, modelUuid) : Promise.resolve();
};
scenarioGroupLoadListIgnoreNull.clear = scenarioGroupLoadList.clear;

export const useScenarioGroups = (modelUuid?: string | null) => {
  return useReduxLoader(listSelectorBuilder, scenarioGroupLoadListIgnoreNull, modelUuid);
};

export const useScenarioGroupsLoaderWithParams = (modelUuid?: string | null) => {
  // @ts-ignore
  return useReduxManualLoaderWithParams(listSelectorBuilder, scenarioGroupLoadListIgnoreNull, modelUuid);
};

export const useScenarioGroupIsNameUnique = (modelUuid: string) => {
  const load = useScenarioGroupsLoaderWithParams();

  return useCallback(
    async (_name: string, executionSetupId: number, scenarioGroupId?: number) => {
      const name = _name.toLocaleLowerCase();
      const groupList = await load([modelUuid]);
      if (groupList) {
        const groups = (groupList as ScenarioGroupListDTO).list;
        return (
          groups.filter((g) => {
            if (scenarioGroupId && g.id === scenarioGroupId) {
              return false;
            }

            return g.executionSetupId === executionSetupId && g.name.toLocaleLowerCase() === name;
          }).length === 0
        );
      }
    },
    [modelUuid, load],
  );
};
