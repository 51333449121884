import React from 'react';
import {IncidentListDTO} from 'hemwb-api';
import {Grid} from '@material-ui/core';
import IncidentTextOverviewTile from './IncidentTextOverviewTile';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../../../../../core/TableWithSortAndPagination';
import {fullName} from '../../../../../../store/user/utils';
import styles from './IncidentTextOverviewTile.module.scss';

type IncidentTextOverviewProps = {
  incident: IncidentListDTO;
};

const IncidentTextOverview: React.FC<IncidentTextOverviewProps> = ({incident}) => {
  return (
    <Grid container spacing={0} direction="column" style={{margin: '5rem 0 5rem 5rem'}}>
      <IncidentTextOverviewTile label="Incident Summary" value={incident.issueSummary} inline={true} />
      <IncidentTextOverviewTile label="User Notes" value={incident.userNotes} inline={false} />
      <IncidentTextOverviewTile label="Incident Details" value={incident.stackTrace ?? ''} inline={false} />
      <IncidentTextOverviewTile
        label="Incident Time"
        value={incident.incidentTime?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
        inline={true}
      />
      <IncidentTextOverviewTile
        label="Created By"
        value={fullName(incident.createdBy)}
        inline={true}
        className={styles.underlined}
      />
    </Grid>
  );
};

export default IncidentTextOverview;
