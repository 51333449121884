import React from 'react';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import {MANAGE_METADATA_MENU_IDS} from '../../../../../../test/types';
import {Power16} from '@carbon/icons-react';
import {useRetireOptionSideBarModal} from './useRetireOptionSideBarModal';
import {canManageMetadata} from '../../../../../../permissions/usePermissions';

const RetireOptionActionMenuButton: React.FC<Partial<ManageMetadataActionProps>> = (props) => {
  const {ModalComponent, openModal} = useRetireOptionSideBarModal(props);
  return (
    <>
      <SideBarButtonItem
        red
        icon={<Power16 />}
        text="Retire Option"
        permission={canManageMetadata(props.property)}
        disabled={!props.value || props.retired}
        onClick={openModal}
        {...tid(MANAGE_METADATA_MENU_IDS.RETIRE)}
      />
      {ModalComponent}
    </>
  );
};

export default RetireOptionActionMenuButton;
