import React from 'react';
import {defaultCancelButtonProps} from '../../../core/Buttons/CancelButton';
import {tid} from '../../../../testUtils';
import {defaultSubmitButtonProps} from '../../../core/Buttons/SubmitButton';
import {TrashCan16} from '@carbon/icons-react';
import clsx from 'clsx';

export const deleteGroupDialogConfiguration = {
  title: 'Delete Group',
  children: 'Are you sure you want to delete this group?',
  buttons: [
    {
      ...defaultCancelButtonProps,
      className: 'redButton',
      children: 'No',
      ...tid('btn', 'delete-group', 'no'),
    },
    {
      ...defaultSubmitButtonProps,
      children: 'Yes',
      className: clsx(defaultSubmitButtonProps.className, 'redButton'),
      endIcon: <TrashCan16 />,
      ...tid('btn', 'delete-group', 'yes'),
    },
  ],
};
