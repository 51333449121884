import {useEffect} from 'react';

export const useFlashRow = (id?: string | number) => {
  useEffect(() => {
    if (id) {
      const rowElement = document.querySelector(`tr[data-testid='grid-row-${id}']`);
      if (rowElement) {
        rowElement.classList.remove('flashedRow');
        rowElement.classList.add('flashRow');
        setTimeout(() => {
          rowElement.classList.add('flashedRow');
          rowElement.classList.remove('flashRow');
        }, 100);
      }
    }
  }, [id]);
};
