import React, {useCallback} from 'react';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';
import {fullName} from '../../../../store/user/utils';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../../../core/TableWithSortAndPagination';
import styles from './InstanceTableCard.module.scss';
import {DraggableProvided} from 'react-beautiful-dnd';
import {InstanceTableCardCell} from './InstanceTableCardCell';
import {isScenarioFinished, scenarioUpdateLabel, scenarioUpdateStatus} from '../../../../store/scenario';
import Checkbox from '@material-ui/core/Checkbox';
import {formatScenarioExecutionStatusValue} from '../../../../store/scenarioExecution/utils';
import {ScenarioGridFilter} from '../step1/types';
import {Button} from '@material-ui/core';
import {Edit16} from '@carbon/icons-react';
import ButtonWithPopper from '../../../core/ButtonWithPopper/ButtonWithPopper';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import EditScenarioLabelPopperContent from '../step1/EditScenarioLabelPopperContent';
import InstanceActionPopperContent from '../step1/InstanceActionPopperContent';

type InstanceTableCardProps = {
  scenarioGridRef: any;
  scenario: ScenarioDTO;
  color?: string;
  checked: boolean;
  onCheckedChange: (scenario: ScenarioDTO) => void;
  provided?: DraggableProvided;
  isDragOccurring?: boolean;
  filter?: ScenarioGridFilter;
  onAnalysisClick?: (scenario: ScenarioDTO) => void;
  scenarioInProgress?: boolean;
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
};

const InstanceTableCard = React.forwardRef<HTMLTableRowElement, InstanceTableCardProps>(
  (
    {
      scenarioGridRef,
      scenario,
      color,
      checked,
      onCheckedChange,
      provided,
      isDragOccurring = false,
      filter,
      scenarioInProgress,
      onAnalysisClick,
      model,
      subModel,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const handleChange = useCallback(() => {
      onCheckedChange(scenario);
    }, [scenario, onCheckedChange]);

    const handleRowClick = useCallback(() => {
      if (filter === ScenarioGridFilter.SAVED_ANALYSES && onAnalysisClick) {
        onAnalysisClick(scenario);
      }
    }, [filter, scenario, onAnalysisClick]);
    const handleLabelChanged = useCallback(
      (label: string) => {
        scenarioUpdateLabel(dispatch, scenario.id, {label});
        // @ts-ignore
        dispatch(scenarioUpdateStatus({id: scenario.id, label}));
      },
      [dispatch, scenario],
    );

    return (
      <>
        {/*<tr className={styles.cardSpacer}></tr>*/}
        {/*// @ts-ignore*/}
        <tr
          onClick={handleRowClick}
          className={clsx(styles.row, {[styles.clickable]: !!handleRowClick})}
          ref={ref}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
          draggable={true}
          onDragStart={(e) => e.dataTransfer.setData('scenarioId', scenario.id.toString())}>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            {filter !== ScenarioGridFilter.SAVED_ANALYSES && isScenarioFinished(scenario.status) && (
              <Checkbox
                checked={checked}
                disabled={scenarioInProgress}
                onChange={handleChange}
                color="secondary"
                size="small"
              />
            )}
          </InstanceTableCardCell>
          <InstanceTableCardCell
            data-owners={`Owners: ${scenario.createdBy && fullName(scenario.createdBy)}`}
            isDragOccurring={isDragOccurring}
            // @ts-ignore
            style={{'--mark-color': color || 'transparent'}}>
            {scenario.name}
          </InstanceTableCardCell>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            <div onClick={(e) => e.stopPropagation()}>
              {scenario.label}
              {scenario.label && <br />}
              <ButtonWithPopper
                placement="bottom-start"
                renderButton={(props) => (
                  <Button {...props} variant="outlined" size="small" startIcon={<Edit16 />}>
                    Edit Label
                  </Button>
                )}
                renderPopperContent={({handleClose}) => (
                  <EditScenarioLabelPopperContent
                    name={scenario.label || ''}
                    handleClose={handleClose}
                    onSubmit={handleLabelChanged}
                  />
                )}
              />
            </div>
          </InstanceTableCardCell>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            {scenario.createdBy && fullName(scenario.createdBy)}
          </InstanceTableCardCell>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            {scenario.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
          </InstanceTableCardCell>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            {scenario.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}
          </InstanceTableCardCell>
          <InstanceTableCardCell isDragOccurring={isDragOccurring}>
            {formatScenarioExecutionStatusValue(scenario.status)}
          </InstanceTableCardCell>

          <InstanceTableCardCell style={{padding: 0}} isDragOccurring={isDragOccurring}>
            <InstanceActionPopperContent
              onSuccess={scenarioGridRef?.current?.reload}
              model={model}
              subModel={subModel}
              scenario={scenario}
            />
          </InstanceTableCardCell>
        </tr>
        {/*<tr className={styles.row}>*/}
        {/*  <td colSpan={7} style={{borderLeftColor: color || 'transparent'}}>*/}
        {/*    Owners: {scenario.createdBy && fullName(scenario.createdBy)}*/}
        {/*  </td>*/}
        {/*</tr>*/}

        {/*<tr className={styles.cardSpacer}></tr>*/}
      </>
    );
  },
);

// function areEqual(p: InstanceTableCardProps, n: InstanceTableCardProps): boolean {
//   return p.scenario === n.scenario;
// }
//
// export default React.memo(InstanceTableCard, areEqual);
export default React.memo(InstanceTableCard);

InstanceTableCard.displayName = 'InstanceTableCard';
