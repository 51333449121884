import React from 'react';
import {ChangeItem, DiffWithTab, TabType} from './types';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {getSectionForTab} from './utils';
import WarningTextWithTitle from '../../WarningText/WarningTextWithTitle';
import {GetMacroDiffRequest, GetSheetDiffRequest, MacroDiffDTO, SheetDiffDTO} from 'hemwb-api';
import {AsyncActionState} from '../../../../store/asyncUtilsTypes';

type OverviewProps = {
  diffsWithTab: DiffWithTab[];
  fetchErrorList: (
    | AsyncActionState<SheetDiffDTO, [GetSheetDiffRequest]>
    | AsyncActionState<MacroDiffDTO, [GetMacroDiffRequest]>
  )[];
  setMarkedChange: (change: ChangeItem) => void;
  loading: boolean;
  diffsCountFetched: number;
  diffsCountTotal: number | undefined;
};

const getTabKey = (tab: TabType) => `${getSectionForTab(tab)} / ${tab.name}`;

const Overview: React.FC<OverviewProps> = ({
  diffsWithTab,
  fetchErrorList = [],
  setMarkedChange,
  loading,
  diffsCountTotal,
  diffsCountFetched,
}) => {
  const diffsWithChange = diffsWithTab.filter((d) => d.diff.diffCount > 0);

  return (
    <>
      <div className="headline">Changes overview</div>
      {diffsWithChange.length > 0 ? (
        <ul>
          {diffsWithChange.map((diff) => {
            const key = getTabKey(diff.tab);
            return (
              <li key={key}>
                <Button
                  onClick={() =>
                    setMarkedChange({
                      ...diff,
                      pageIndex: diff.diff.firstDiffPageIndex,
                      diffIndex: 1,
                    })
                  }>
                  {key} ({diff.diff.diffCount} change{diff.diff.diffCount > 1 ? 's' : ''})
                </Button>
              </li>
            );
          })}
        </ul>
      ) : (
        <div>No changes found {loading && <>yet</>}</div>
      )}

      {fetchErrorList.length > 0 && (
        <WarningTextWithTitle
          text="This might be due to files are still being processed on our server. Please try again later."
          title="Failed to fetch some diffs"
        />
      )}

      {loading && (
        <>
          {diffsCountTotal && (
            <Typography>
              Tabs compared: {diffsCountFetched}/{diffsCountTotal}
            </Typography>
          )}
          <CircularProgress />
        </>
      )}
    </>
  );
};

export default Overview;
