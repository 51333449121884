import {AsyncAction, AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {
  requestScenarioGroupCreate,
  requestScenarioGroupDelete,
  requestScenarioGroupDetail,
  requestScenarioGroupList,
  requestScenarioGroupUpdate,
} from './api';

export type ScenarioGroupState = {
  list: {
    [modelUuid: string]: AsyncActionState<
      Await<ReturnType<typeof requestScenarioGroupList>>,
      Parameters<typeof requestScenarioGroupList>
    >;
  };
};

export enum ScenarioGroupActions {
  LIST = 'SCENARIO_GROUP/LIST',
  DETAIL = 'SCENARIO_GROUP/DETAIL',
  CREATE = 'SCENARIO_GROUP/CREATE',
  UPDATE = 'SCENARIO_GROUP/UPDATE',
  DELETE = 'SCENARIO_GROUP/DELETE',
}

export type ScenarioGroupAction =
  | AsyncAction<
      ScenarioGroupActions.DETAIL,
      Await<ReturnType<typeof requestScenarioGroupDetail>>,
      Parameters<typeof requestScenarioGroupDetail>
    >
  | AsyncAction<
      ScenarioGroupActions.CREATE,
      Await<ReturnType<typeof requestScenarioGroupCreate>>,
      Parameters<typeof requestScenarioGroupCreate>
    >
  | AsyncAction<
      ScenarioGroupActions.UPDATE,
      Await<ReturnType<typeof requestScenarioGroupUpdate>>,
      Parameters<typeof requestScenarioGroupUpdate>
    >
  | AsyncAction<
      ScenarioGroupActions.DELETE,
      Await<ReturnType<typeof requestScenarioGroupDelete>>,
      Parameters<typeof requestScenarioGroupDelete>
    >
  | AsyncAction<
      ScenarioGroupActions.LIST,
      Await<ReturnType<typeof requestScenarioGroupList>>,
      Parameters<typeof requestScenarioGroupList>
    >;
