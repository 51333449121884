import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {ModelMetadataOptionsDTO} from 'hemwb-api';
import {ModelMetadataAction, ModelMetadataActions} from './types';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';

export type ModelMetadataState = {
  options: AsyncActionState<ModelMetadataOptionsDTO, []>;
};

export const modelMetadataInitialState: ModelMetadataState = {
  options: buildInitialAsyncState(),
};

const replaceOptionsInState = (state: ModelMetadataState, newOptions: ModelMetadataOptionsDTO): ModelMetadataState => {
  return {
    ...state,
    options: {
      ...state.options,
      value: newOptions,
    },
  };
};

export const modelMetadataReducer = (
  state = modelMetadataInitialState,
  action: ModelMetadataAction,
): ModelMetadataState => {
  switch (action.type) {
    case ModelMetadataActions.REQUEST_OPTIONS: {
      const options = reduceAsyncAction(action);
      const newState = {
        ...state,
        options,
      };

      if (action.phase === AsyncActionPhase.SUCCESS) {
        return replaceOptionsInState(newState, options.value!);
      }

      return newState;
    }

    case ModelMetadataActions.REQUEST_OPTION_ADD:
    case ModelMetadataActions.REQUEST_OPTION_UPDATE:
    case ModelMetadataActions.REQUEST_OPTION_DELETE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return modelMetadataInitialState;
      }

      return state;
    }
  }

  return state;
};
