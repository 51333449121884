import {RootState} from '../rootReducer';
import {userExpertiseSelector} from './selectors';
import {useReduxLoader} from '../useReduxLoader';
import {userExpertiseLoadList} from './actions';

const userExpertiseSelectorBuilder = (params: [string, string]) => (state: RootState) =>
  userExpertiseSelector(state).list[`${params[0]}-${params[1]}`] || null;

export const useUserExpertise = (modelUuid: string, userId: string) => {
  return useReduxLoader(userExpertiseSelectorBuilder, userExpertiseLoadList, [modelUuid, userId]);
};
