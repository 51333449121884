import {RootState, useSelector} from './rootReducer';
import {createAsyncActionExecutor} from './asyncUtils';
import {AsyncActionPhase, AsyncActionState} from './asyncUtilsTypes';
import {useDispatch, useStore} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';
import isEqual from 'lodash/isEqual';

const shouldRefresh = (state: any, invalidateAfterMilliseconds: number) =>
  typeof state.timestamp === 'number' && state.timestamp < Date.now() - invalidateAfterMilliseconds;

// params are passed by loader call
// useCase: you need dynamically trigger load with dynamic parameters
// no particular data selection can be returned
export const useReduxManualLoaderWithParams = <V, P extends any[]>(
  selector: (params: P) => (state: RootState) => AsyncActionState<V, P> | null,
  loadExecutor: ReturnType<typeof createAsyncActionExecutor>,
  invalidateAfterMilliseconds = 600000,
) => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();

  const load = useCallback(
    (params) => {
      const state = selector(params)(store.getState());

      if (
        !state ||
        state.state === null ||
        (state.state === AsyncActionPhase.SUCCESS && !isEqual(params, state.params)) ||
        shouldRefresh(state, invalidateAfterMilliseconds)
      ) {
        return loadExecutor(dispatch, ...params);
      } else if (state) {
        return Promise.resolve(state.value);
      } else {
        return Promise.reject();
      }
    },
    [dispatch, selector, store, loadExecutor, invalidateAfterMilliseconds],
  );

  return load;
};

// params are passed by hook call
// useCase: you know params in advance
export const useReduxManualLoader = <V, P extends any[]>(
  selector: (params: P) => (state: RootState) => AsyncActionState<V, P> | null,
  loadExecutor: ReturnType<typeof createAsyncActionExecutor>,
  parameter: any,
  invalidateAfterMilliseconds = 600000,
) => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const parameterName = (Array.isArray(parameter) ? parameter : [parameter]) as P;
  const [params, setParams] = useState<P>(parameterName);
  useEffect(() => {
    const newParams = parameterName;
    if (!isEqual(newParams, params)) {
      setParams(newParams);
    }
  }, [parameter, params, parameterName]);

  const state: AsyncActionState<V, P> | null = useSelector(selector(params));

  useEffect(() => {
    return () => {
      const currentState = selector(params)(store.getState());
      if (currentState && currentState.state === AsyncActionPhase.FAILURE && isEqual(params, currentState.params)) {
        loadExecutor.clear(dispatch, ...params);
      }
    };
  }, [dispatch, store, params, loadExecutor, selector]);

  const isValid = state && state.value && isEqual(params, state.params);

  const load = useCallback(() => {
    if (
      !state ||
      state.state === null ||
      (state.state === AsyncActionPhase.SUCCESS && !isEqual(params, state.params)) ||
      shouldRefresh(state, invalidateAfterMilliseconds)
    ) {
      loadExecutor(dispatch, ...params);
    }
  }, [state, params, loadExecutor, dispatch, invalidateAfterMilliseconds]);

  return {load, data: isValid ? state?.value : null};
};

export const useReduxLoader = <V, P extends any[]>(
  selector: (params: P) => (state: RootState) => AsyncActionState<V, P> | null,
  loadExecutor: ReturnType<typeof createAsyncActionExecutor>,
  parameter: any,
  invalidateAfterMilliseconds = 600000,
) => {
  const {load, data} = useReduxManualLoader(selector, loadExecutor, parameter, invalidateAfterMilliseconds);

  load();

  return data;
};

export const useSimpleReduxLoader = <V>(
  selector: (state: RootState) => AsyncActionState<V, any> | null,
  loadExecutor: ReturnType<typeof createAsyncActionExecutor>,
  invalidateAfterMilliseconds = 600000,
) => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();

  const state: AsyncActionState<V, any> | null = useSelector(selector);

  useEffect(() => {
    return () => {
      const currentState = selector(store.getState());
      if (currentState && currentState.state === AsyncActionPhase.FAILURE) {
        loadExecutor.clear(dispatch);
      }
    };
  }, [dispatch, store, loadExecutor, selector, invalidateAfterMilliseconds]);

  const isValid = state && state.value;

  if (!state || state.state === null || shouldRefresh(state, invalidateAfterMilliseconds)) {
    loadExecutor(dispatch);
  }

  return isValid ? state?.value : null;
};
