import {AsyncAction} from '../asyncUtilsTypes';
import {GitBranchDTO, GitBrowseDTO, GitCommitDTO, GitDiffSummaryDTO, GitProjectDTO, GitRepoDTO} from 'hemwb-api';
import {requestBranches, requestBrowse, requestDiff, requestHistory, requestSaveFile} from './api';

export enum BitbucketActions {
  REQUEST_PROJECTS = 'BITBUCKET/REQUEST_PROJECTS',
  REQUEST_PROJECT = 'BITBUCKET/REQUEST_PROJECT',
  REQUEST_REPOSITORIES = 'BITBUCKET/REQUEST_REPOSITORIES',
  REQUEST_REPOSITORY = 'BITBUCKET/REQUEST_REPOSITORY',
  REQUEST_BRANCHES = 'BITBUCKET/REQUEST_BRANCHES',
  REQUEST_BROWSE = 'BITBUCKET/REQUEST_BROWSE',
  REQUEST_DIFF = 'BITBUCKET/REQUEST_DIFF',
  REQUEST_HISTORY = 'BITBUCKET/REQUEST_HISTORY',
  REQUEST_SAVE_FILE = 'BITBUCKET/REQUEST_SAVE_FILE',
}

export type BitbucketAction =
  | AsyncAction<BitbucketActions.REQUEST_PROJECTS, GitProjectDTO[], []>
  | AsyncAction<BitbucketActions.REQUEST_PROJECT, GitProjectDTO, [string]>
  | AsyncAction<BitbucketActions.REQUEST_REPOSITORIES, GitRepoDTO[], [string]>
  | AsyncAction<BitbucketActions.REQUEST_REPOSITORY, GitRepoDTO, [string, string]>
  | AsyncAction<BitbucketActions.REQUEST_BRANCHES, GitBranchDTO[], Parameters<typeof requestBranches>>
  | AsyncAction<BitbucketActions.REQUEST_BROWSE, GitBrowseDTO, Parameters<typeof requestBrowse>>
  | AsyncAction<BitbucketActions.REQUEST_DIFF, GitDiffSummaryDTO, Parameters<typeof requestDiff>>
  | AsyncAction<BitbucketActions.REQUEST_HISTORY, GitCommitDTO[], Parameters<typeof requestHistory>>
  | AsyncAction<BitbucketActions.REQUEST_SAVE_FILE, string, Parameters<typeof requestSaveFile>>;
