import React, {useCallback} from 'react';
import {ExecutionSetupDTO, ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import {Upload16} from '@carbon/icons-react';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {usePptExport} from './usePptExport';
import {VisualizationResults} from '../../../../../store/visualization';
import {trackInstanceVisualizationExportedPPT} from '../../../../../tracking/tracking';

type VisualizationResultsExportPPTProps = {
  scenarioInProgress: boolean;
  model?: ModelDTO;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDetailDTO;
  results?: VisualizationResults;
};

const VisualizationResultsExportPPTActionMenuButton: React.FC<VisualizationResultsExportPPTProps> = ({
  scenarioInProgress,
  model,
  subModel,
  scenario,
  results,
}) => {
  const {downloadResultsAndExportPPT, exportPPT, ExportPPTComponent} = usePptExport();

  const handleClick = useCallback(() => {
    if (scenarioInProgress) {
      return;
    }

    if (results) {
      model && subModel && trackInstanceVisualizationExportedPPT(model, subModel, {id: 0, name: 'test_visualization'});
      return exportPPT(results);
    } else if (scenario) {
      model && subModel && trackInstanceVisualizationExportedPPT(model, subModel, scenario);
      return downloadResultsAndExportPPT(scenario);
    }
  }, [model, subModel, scenario, results, scenarioInProgress, exportPPT, downloadResultsAndExportPPT]);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.EXPORT_RESULTS_PPT)}
        disabled={(!scenario || scenarioInProgress) && !results?.elements.length}
        onClick={handleClick}
        icon={<Upload16 />}
        text="Export PPT"
      />
      {ExportPPTComponent}
    </>
  );
};

export default VisualizationResultsExportPPTActionMenuButton;
