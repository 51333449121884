import {
  ArraySpecificInputAttributes,
  BooleanSpecificInputAttributes,
  CommonInputAttributes,
  DateSpecificInputAttributes,
  DecimalSpecificInputAttributes,
  InputAttributes,
  InputType,
  IntegerSpecificInputAttributes,
  LabelSpecificInputAttributes,
  NumberTypeProps,
  rangeInputTypes,
  RangeSpecificInputAttributes,
  RealNumberSpecificInputAttributes,
  SelectItem,
  SelectorSpecificInputAttributes,
  StringNumberTypeProps,
  StringSpecificInputAttributes,
} from './types';
import {
  convertToBoolean,
  convertToDateStringFormat,
  convertToDecimalFormat,
  convertToFloat,
  convertToInteger,
  convertToString,
} from './convertor';

export const formatInput = <T extends InputAttributes>(input: T): T => {
  switch (input.type) {
    case InputType.STRING:
      return formatCommonInput(formatStringInput(input as any));
    case InputType.INTEGER:
    case InputType.REAL_NUMBER:
    case InputType.DECIMAL:
    case InputType.DATE:
      const formatter = getNumericInputFormatterByType(input.type);
      //@ts-ignore
      return formatCommonInput(formatter(input as any));
    case InputType.BOOLEAN:
      return formatCommonInput(formatBooleanInput(input as any));
    case InputType.SINGLE_SELECT:
    case InputType.MULTI_SELECT:
      return formatCommonInput(formatSelectInput(input as any));
    case InputType.RANGE:
      return formatCommonInput(formatRangeInput(input as any));
    case InputType.ARRAY:
      return formatCommonInput(formatArrayInput(input as any));
    case InputType.LABEL:
      return formatCommonInput(formatLabelInput(input as any));
    case InputType.SELECTOR:
      return formatCommonInput(formatSelectorInput(input as any));
  }

  throw new Error(`Formatting failed. Unsupported type: "${input.type}"`);
};

const formatCommonInput = <T extends CommonInputAttributes>(input: T): T => {
  const i = {...input};
  i.id = convertToInteger(i.id, i);
  i.categoryId = convertToString(i.categoryId, i);
  i.title = convertToString(i.title, i);
  i.question = convertToString(i.question, i);
  i.name = convertToString(i.name, i);
  if (i.visible !== undefined) {
    i.visible = convertToBoolean(i.visible, i);
  }
  if (i.required !== undefined) {
    i.required = convertToBoolean(i.required, i);
  }
  if (i.viewable !== undefined) {
    i.viewable = convertToBoolean(i.viewable, i);
  }

  return i;
};

const formatStringInput = <T extends StringSpecificInputAttributes>(input: T): T => {
  const i = clearNullishDefaultValue({...input});
  i.length = i.length ? convertToInteger(i.length, i) : 255;

  if (i.defaultValue) {
    i.defaultValue = convertToString(i.defaultValue, i);
  }

  return i;
};

const formatLabelInput = <T extends LabelSpecificInputAttributes>(input: T): T => {
  const i = clearNullishDefaultValue({...input});

  if (i.defaultValue) {
    i.defaultValue = convertToString(i.defaultValue, i);
  }

  return i;
};

const formatIntegerInput = <T extends IntegerSpecificInputAttributes>(input: T): T => {
  const i = clearNullishNumericAttributes({...input});

  if (i.min) {
    i.min = convertToInteger(i.min, i);
  }

  if (i.max) {
    i.max = convertToInteger(i.max, i);
  }

  if (i.defaultValue) {
    i.defaultValue = convertToInteger(i.defaultValue, i);
  }

  return i;
};

const formatRealNumberInput = <T extends RealNumberSpecificInputAttributes>(input: T): T => {
  const i = clearNullishNumericAttributes({...input});

  if (i.min) {
    i.min = convertToFloat(i.min, i);
  }

  if (i.max) {
    i.max = convertToFloat(i.max, i);
  }

  if (i.defaultValue) {
    i.defaultValue = convertToFloat(i.defaultValue, i);
  }

  return i;
};

const formatDecimalInput = <T extends DecimalSpecificInputAttributes>(input: T): T => {
  const i = clearNullishNumericAttributes({...input});

  if (i.min) {
    i.min = convertToDecimalFormat(i.min, i);
  }

  if (i.max) {
    i.max = convertToDecimalFormat(i.max, i);
  }

  if (i.defaultValue) {
    i.defaultValue = convertToDecimalFormat(i.defaultValue, i);
  }

  return i;
};

const formatDateInput = <T extends DateSpecificInputAttributes>(input: T): T => {
  const i = clearNullishNumericAttributes({...input});

  if (i.min) {
    i.min = convertToDateStringFormat(i.min, i);
  }

  if (i.max) {
    i.max = convertToDateStringFormat(i.max, i);
  }

  if (i.defaultValue) {
    i.defaultValue = convertToDateStringFormat(i.defaultValue, i);
  }

  return i;
};

const formatBooleanInput = <T extends BooleanSpecificInputAttributes>(input: T): T => {
  const i = clearNullishDefaultValue({...input});

  if (i.defaultValue) {
    i.defaultValue = convertToBoolean(i.defaultValue, i);
  }

  return i;
};

const formatSelectInput = <T extends {options: SelectItem[]}>(input: T): T => input;

const formatRangeInput = <T extends RangeSpecificInputAttributes>(input: T): T => {
  const i = {...input};
  const formatter = getNumericInputFormatterByType(i.dataType);

  if (isNullOrUndefinedRepresentation(i.from)) {
    delete i.from;
  } else {
    // @ts-ignore
    i.from = formatter(i.from as any);
  }

  if (isNullOrUndefinedRepresentation(i.to)) {
    delete i.to;
  } else {
    // @ts-ignore
    i.to = formatter(i.to as any);
  }

  return i;
};

const formatArrayInput = <T extends ArraySpecificInputAttributes>(input: T): T => {
  const i = {...input};

  if (isNullOrUndefinedRepresentation(i.rowDescription)) {
    delete i.rowDescription;
  } else if (Array.isArray(i.rowDescription)) {
    i.rowDescription = convertToString(i.rowDescription);
  }

  if (Array.isArray(i.rows)) {
    i.rows = i.rows.map((r: any) => convertToString(r));
  } else if (isNullOrUndefinedRepresentation(i.rows)) {
    i.rows = [];
  } else {
    throw new Error(`Failed to parse rows from "Array" in context of ${i}`);
  }

  if (Array.isArray(i.columns)) {
    i.columns = i.columns.map((c: any) => {
      if (c.dataType === InputType.STRING) {
        return {
          ...c,
          ...formatStringInput({...c, defaultValue: undefined}),
          defaultValue: c.defaultValue.map((d: any) =>
            isNullOrUndefinedRepresentation(d) ? null : convertToString(d),
          ),
        };
      } else {
        const formatter = getNumericInputFormatterByType(c.dataType);
        return {
          ...c,
          //@ts-ignore
          ...formatter({...c, defaultValue: undefined}),
          //@ts-ignore
          defaultValue: c.defaultValue.map((d: any) => formatter({defaultValue: d}).defaultValue),
        };
      }
    });
  } else if (isNullOrUndefinedRepresentation(i.rows)) {
    i.columns = [];
  } else {
    throw new Error(`Failed to parse columns from "Array" in context of ${i}`);
  }

  return i;
};

const formatSelectorInput = <T extends SelectorSpecificInputAttributes>(input: T): T => {
  return {
    ...input,
    type: InputType.SELECTOR,
    columns: input.columns.map((c) => {
      return {
        key: convertToString(c.key),
        text: convertToString(c.text),
        cellText: c.cellText?.map((t) => convertToString(t)),
      };
    }),
    rows: input.rows.map((r) => convertToString(r)),
  };
};

//
// HELPERS
//

const getNumericInputFormatterByType = (type: keyof typeof rangeInputTypes, context?: any) => {
  switch (type) {
    case InputType.INTEGER:
      return formatIntegerInput;
    case InputType.REAL_NUMBER:
      return formatRealNumberInput;
    case InputType.DECIMAL:
      return formatDecimalInput;
    case InputType.DATE:
      return formatDateInput;
  }

  throw new Error(`Failed to parse dataType from "${type}"${context ? ` in context of ${context}` : ''}`);
};

const isNullRepresentation = (value: any): boolean => {
  if (value === null) {
    return true;
  }
  if (typeof value === 'string') {
    const valueLower = value.toLowerCase();
    if (valueLower === 'null') {
      return true;
    }
  }

  return false;
};
export const isNullOrUndefinedRepresentation = (value: any): boolean =>
  value === undefined || isNullRepresentation(value);

const clearNullishNumericAttributes = <T extends NumberTypeProps | StringNumberTypeProps>(i: T): T => {
  if (isNullOrUndefinedRepresentation(i.min) || i.min === '') {
    delete i.min;
  }

  if (isNullOrUndefinedRepresentation(i.max) || i.max === '') {
    delete i.max;
  }

  if (isNullOrUndefinedRepresentation(i.defaultValue) || i.defaultValue === '') {
    delete i.defaultValue;
  }

  return i;
};
const clearNullishDefaultValue = <T extends {defaultValue?: any}>(i: T): T => {
  if (isNullOrUndefinedRepresentation(i.defaultValue) || i.defaultValue === '') {
    delete i.defaultValue;
  }

  return i;
};
