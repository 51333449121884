import React, {useState} from 'react';
import {
  Button,
  Collapse,
  FormControl as FormControlComponent,
  FormGroup as FormGroupComponent,
  FormLabel,
  InputAdornment,
  TextField,
  Box,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ClearIcon from '@material-ui/icons/Clear';
import {FieldControl} from 'react-reactive-form';
import {OptionFilterProps, OptionWrapperProps} from './types';
import styles from './OptionFilter.module.scss';
import {tid} from '../../../testUtils';
export const optionStyles = styles;

const showSearchThreshold = 10;
const showMoreThresholdSoft = 10;
const showMoreThresholdHard = 12;

const DefaultOptionWrapper: React.FC<OptionWrapperProps> = ({children}) => <>{children}</>;

export const OptionFilter: React.FC<OptionFilterProps<any>> = <T,>(props: OptionFilterProps<T>) => {
  const {
    label: filterLabel,
    control,
    options,
    optionLabel = (o: T) => o,
    optionValue = (o: T) => o,
    optionLabelMatcher = (o: T, query: string) => {
      if (!query) {
        return true;
      }
      const label = optionLabel(o);
      return typeof label === 'string' && label.toLocaleLowerCase().includes(query.toLocaleLowerCase());
    },
    optionValueMatcher,
    optionKey = optionValue,
    collapsed = false,
    onToggleCollapsed = () => {},
    optionComponent: OptionComponent,
    optionWrapperComponent: OptionWrapperComponent = DefaultOptionWrapper,
    isEmpty = (value: any) => !value,
    buildEmptyValue = () => null,
    getDataTestIdPostfixFromValue = (value) => value,
    noCollapse,
    allSelectable,
    allSelected,
    overrideShowSearch,
    ...fieldsetProps
  } = props;

  const [search, setSearch] = useState('');
  const [listExpanded, setListExpanded] = useState(false);

  if (options.length <= 1 && props.label !== 'Platform') {
    return <></>;
  }

  const showSearch = overrideShowSearch !== undefined ? overrideShowSearch : options.length > showSearchThreshold;
  const hasFilter = !isEmpty(control.value);
  const clearFilter = (event: React.BaseSyntheticEvent) => {
    control.setValue(buildEmptyValue());
    event.stopPropagation();
  };

  return (
    <FormControlComponent component="fieldset" className={styles.fieldset} {...(fieldsetProps as any)}>
      {noCollapse || (
        <FormLabel
          component="legend"
          className={styles.collapseToggle}
          onClick={onToggleCollapsed}
          {...tid('option-filter-collapse-toggle')}>
          {filterLabel}
          <div className={styles.collapseToggleIcon}>
            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          {hasFilter && (
            <div className={styles.clearFilterButton} onClick={clearFilter} {...tid('option-filter-clear')}>
              <ClearIcon />
            </div>
          )}
        </FormLabel>
      )}
      <Collapse in={!collapsed}>
        <FormGroupComponent>
          <FieldControl
            control={control}
            strict={false}
            render={({handler}) => {
              const filteredOptions = options.filter((option) => optionLabelMatcher(option, search));
              const showAll = filteredOptions.length <= showMoreThresholdHard;
              return (
                <>
                  {showSearch && (
                    <div>
                      <TextField
                        placeholder="Search"
                        margin="dense"
                        hiddenLabel
                        variant="filled"
                        fullWidth
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyDown={(event) => event.stopPropagation()}
                        InputProps={{
                          endAdornment: search && (
                            <InputAdornment
                              position="end"
                              onClick={() => setSearch('')}
                              style={{cursor: 'pointer'}}
                              {...tid('option-filter-search-clear')}>
                              <ClearIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={tid('option-filter-search')}
                      />
                    </div>
                  )}
                  {allSelectable && (
                    <OptionComponent
                      option={filteredOptions[0]}
                      optionLabel={() => '(Select All)'}
                      optionValue={() => 'all'}
                      {...{
                        handler,
                        optionValueMatcher,
                        getDataTestIdPostfixFromValue,
                      }}
                    />
                  )}
                  <Box>
                    <OptionWrapperComponent handler={handler}>
                      {filteredOptions
                        .slice(0, showAll || listExpanded ? undefined : showMoreThresholdSoft)
                        .map((option) => (
                          <>
                            <OptionComponent
                              key={(optionKey || optionValue)(option)}
                              {...{
                                handler,
                                option,
                                optionLabel,
                                optionValue,
                                optionValueMatcher,
                                getDataTestIdPostfixFromValue,
                              }}
                            />
                            <br />
                          </>
                        ))}
                    </OptionWrapperComponent>
                  </Box>
                  {!showAll &&
                    (listExpanded ? (
                      <Button size="small" onClick={() => setListExpanded(false)} {...tid('option-filter-show-less')}>
                        Show Less
                      </Button>
                    ) : (
                      <Button size="small" onClick={() => setListExpanded(true)} {...tid('option-filter-show-more')}>
                        Show {filteredOptions.length - showMoreThresholdSoft} More
                      </Button>
                    ))}
                </>
              );
            }}
          />
        </FormGroupComponent>
      </Collapse>
    </FormControlComponent>
  );
};
