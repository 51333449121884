import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import useScenarioEditSideBarModal from './useScenarioEditSideBarModal';
import {canEditInstance} from '../../../../../permissions/usePermissions';
import {Edit16} from '@carbon/icons-react';

const ScenarioEditActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {ModalComponent, openModal} = useScenarioEditSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_EDIT)}
        permission={canEditInstance(props.model, props.subModel, props.scenario)}
        onClick={openModal}
        icon={<Edit16 />}
        text="Edit"
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioEditActionMenuButton;
