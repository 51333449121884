import React, {useCallback, useMemo, useRef, useState} from 'react';
import {
  VisualizationResults,
  VisualizationResultsChartElement,
  VisualizationResultsElements,
  VisualizationResultsErrorElement,
  VisualizationResultsSectionElement,
  VisualizationResultsTableElement,
} from '../../../../../store/visualization';
import {useTableRenderer} from '../VisualizationResultsExportPPT/useTableRenderer';
import {chartToPNG} from '../VisualizationResultsExportPPT/chartToPNG';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ScenarioDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import {isScenarioFinished, requestScenarioGetOutputVisualization} from '../../../../../store/scenario';
import {useDispatch} from 'react-redux';
import {defaultCancelButtonProps} from '../../../../core/Buttons/CancelButton';
import {jsPDF} from 'jspdf';
import IMGBASE64 from '../../../../../assets/MR_logo_DARK.png';

//@ts-ignore
import fontPathBold from '../../../../../assets/Invention_W_Bd.ttf';
//@ts-ignore
import fontPathRegular from '../../../../../assets/Invention_W_Rg.ttf';
import {processResults} from '../../step3/processResults';

let FONT_NORMAL: string;
let FONT_BOLD: string;

const HEADER_BAR_HEIGHT = 1.8;

enum Status {
  IDDLE,
  DOWNLOADING,
  EXPORTING,
}

export const usePdfExport = () => {
  const {getTableImageData, TableComponent} = useTableRenderer();
  const [completed, setCompleted] = useState(0);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState<Status>(Status.IDDLE);
  const abortRef = useRef(false);
  const dispatch = useDispatch();
  const exportPDF = useCallback(
    async (results: VisualizationResults) => {
      const {elements} = results;
      abortRef.current = false;
      setCompleted(0);
      setTotal(elements.length);
      setStatus(Status.EXPORTING);

      if (!FONT_NORMAL) {
        FONT_NORMAL = await fontPathToBase64(fontPathRegular);
      }
      if (!FONT_BOLD) {
        FONT_BOLD = await fontPathToBase64(fontPathBold);
      }

      const pdf = new jsPDF({orientation: 'l', unit: 'cm', format: 'a4', compress: true, putOnlyUsedFonts: true});
      const pdfWidth = pdf.internal.pageSize.width;
      const pdfHeight = pdf.internal.pageSize.height;

      //add Invention font
      pdf.addFileToVFS('Invention-bold.woff', FONT_BOLD).addFont('Invention-bold.woff', 'Invention', 'bold', 'bold');
      pdf.addFileToVFS('Invention-normal.woff', FONT_NORMAL).addFont('Invention-normal.woff', 'Invention', 'normal');
      pdf.setFont('Invention', 'normal', 'normal');

      const pageLayout = () => {
        const pagesCount = elements.length;

        //remove first autogenerated blank page
        pdf.deletePage(1);

        //Date today
        const dateStamp = new Date();
        const date = dateStamp.getUTCDate();
        const month = dateStamp.toLocaleString('default', {month: 'short'});
        const year = dateStamp.getUTCFullYear();

        for (let i = 1; i <= pagesCount; i++) {
          // HEADER
          pdf
            .setFont('Invention', 'bold', 'bold')
            .setPage(i)
            .setFontSize(12)
            .setFillColor(0, 0, 0)
            .rect(0, 0, pdf.internal.pageSize.width, HEADER_BAR_HEIGHT, 'F')
            .setTextColor(255, 255, 255)
            .text(String(date + '-' + month + '-' + year), pdf.internal.pageSize.width - 0.45, HEADER_BAR_HEIGHT / 2, {
              align: 'right',
              baseline: 'middle',
            })
            .text('Model Repository', 1.64, HEADER_BAR_HEIGHT / 2, {
              baseline: 'middle',
            })
            .addImage(IMGBASE64, 'png', 0.45, 0.5, 0.8, 0.8)
            .setFontSize(14)
            .text('Results Display', pdf.internal.pageSize.width / 2, HEADER_BAR_HEIGHT / 2, {
              align: 'center',
              baseline: 'middle',
            })

            //FOOTER
            .setFont('Invention', 'normal', 'normal')
            .setTextColor(0, 0, 0)
            .setFontSize(12)
            .text(String(i) + '/' + String(pagesCount), pdfWidth / 2, pdfHeight - 0.6, {
              align: 'center',
              baseline: 'middle',
            });
        }
      };

      const promises = elements
        .map((element) => {
          switch (element.element) {
            case VisualizationResultsElements.SECTION: {
              return () => handleAddSection(pdf, element, pdfWidth, pdfHeight);
            }
            case VisualizationResultsElements.ERROR: {
              return () => handleAddError(pdf, element, pdfWidth, pdfHeight);
            }
            case VisualizationResultsElements.CHART: {
              return () => handleAddChart(pdf, element, pdfWidth, pdfHeight);
            }
            case VisualizationResultsElements.TABLE: {
              return () => handleAddTable(pdf, element, getTableImageData, pdfWidth, pdfHeight);
            }
          }
          return null;
        })
        .filter(Boolean) as (() => Promise<void>)[];

      if (promises.length > 0) {
        return promises
          .reduce(
            (chain, currentTask) =>
              chain.then(() => {
                if (abortRef.current) {
                  return Promise.reject(new Error('Canceled'));
                }

                return currentTask().then(() => {
                  setCompleted((c) => c + 1);
                });
              }),
            Promise.resolve(),
          )
          .then(() => {
            pageLayout();
            pdf.save('visualizationResults.pdf');
          })
          .catch((error: Error) => {
            messageAdd(error.message, MessageTypes.ERROR);
          })
          .finally(() => {
            setTotal(0);
            setStatus(Status.IDDLE);
          });
      }

      return Promise.reject('Nothing to export.');
    },
    [getTableImageData],
  );

  const downloadResultsAndExportPDF = useCallback(
    async (scenario: ScenarioDTO) => {
      if (!isScenarioFinished(scenario.status)) {
        return Promise.reject(new Error('Can not fetch results from unfinished scenario.'));
      }

      abortRef.current = false;
      setStatus(Status.DOWNLOADING);

      try {
        const results = await requestScenarioGetOutputVisualization(scenario.id);
        if (!abortRef.current) {
          return await exportPDF(processResults(results));
        }
        return Promise.reject('Canceled');
      } catch (e) {
        dispatch(messageAdd(e, MessageTypes.ERROR));
      }
    },
    [dispatch, exportPDF, abortRef],
  );

  const abortPDFExport = useCallback(() => {
    setTotal(0);
    setStatus(Status.IDDLE);
    abortRef.current = true;
  }, [abortRef]);

  const ExportPDFComponent = useMemo(() => {
    return status !== Status.IDDLE ? (
      <>
        <DialogGeneric
          open={true}
          title="Please wait"
          buttons={[
            {
              ...defaultCancelButtonProps,
              onClick: abortPDFExport,
              ...tid('dialog', 'btn', 'cancel'),
            },
          ]}>
          {status === Status.EXPORTING && total > 0 && (
            <>
              Creating and saving your PDF file...{' '}
              <span style={{width: 40, display: 'inline-block', textAlign: 'right'}}>
                {Math.round((completed / total) * 100)}%
              </span>
            </>
          )}
          {status === Status.DOWNLOADING && 'Downloading results...'}
        </DialogGeneric>
        {TableComponent}
      </>
    ) : null;
  }, [TableComponent, total, completed, status, abortPDFExport]);

  return {
    abortPDFExport,
    exportPDF,
    downloadResultsAndExportPDF,
    ExportPDFComponent,
  };
};

const handleAddSection = async (
  pdf: jsPDF,
  element: VisualizationResultsSectionElement,
  pdfWidth: number,
  pdfHeight: number,
) => {
  pdf
    .addPage()
    //title
    .setFontSize(20)
    .setFont('Invention', 'bold', 'bold')
    .text(element.title, pdfWidth / 2, pdfHeight / 2, {align: 'center', baseline: 'middle'})

    //text
    .setFontSize(12)
    .setFont('Invention', 'normal', 'normal')
    .text(element.text, pdfWidth / 2, pdfHeight / 2 + 1.2, {align: 'center', baseline: 'middle'});

  return Promise.resolve();
};

const handleAddError = async (
  pdf: jsPDF,
  element: VisualizationResultsErrorElement,
  pdfWidth: number,
  pdfHeight: number,
) => {
  pdf
    .addPage()
    //title
    .setFontSize(16)
    .setFont('Invention', 'bold', 'bold')
    .setTextColor(209, 48, 62)
    .text(element.title, pdfWidth / 2, pdfHeight / 2, {align: 'center', baseline: 'middle'})

    //text
    .setFontSize(12)
    .setFont('Invention', 'normal', 'normal')
    .text(element.text, pdfWidth / 2, pdfHeight / 2 + 1.2, {align: 'center', baseline: 'middle'})
    .setTextColor(0, 0, 0);

  return Promise.resolve();
};

const handleAddChart = async (
  pdf: jsPDF,
  element: VisualizationResultsChartElement,
  pdfWidth: number,
  pdfHeight: number,
) => {
  const imgData = await chartToPNG(element);
  pdf.addPage().addImage(imgData, 'PNG', 1, 2.8, pdfWidth * 0.9, pdfHeight * 0.7);

  return Promise.resolve();
};

const handleAddTable = async (
  pdf: jsPDF,
  element: VisualizationResultsTableElement,
  getTableImageData: any,
  pdfWidth: number,
  pdfHeight: number,
) => {
  pdf.addPage();
  const imgData = await getTableImageData(element);
  const {width, height} = await getImageSizeByURI(imgData);

  const maxWidth = pdfWidth - 2;
  const maxHeight = pdfHeight - 2.5 - 1;

  const fitRatio = maxWidth / maxHeight;
  const imageRatio = width / height;
  if (imageRatio >= fitRatio) {
    pdf.addImage(imgData, 'PNG', 1, 2.5, maxWidth, maxWidth / imageRatio);
  } else {
    const w = maxHeight * imageRatio;
    pdf.addImage(imgData, 'PNG', 1, 2.5, w, maxHeight);
  }

  return Promise.resolve();
};

export const getImageSizeByURI = async (dataURI: string): Promise<{width: number; height: number}> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const {width, height} = img;
      if (width && height) {
        return resolve({width, height});
      }
      return reject('Image load failed. No valid dimensions.');
    };

    img.src = dataURI;
  });
};

const fontPathToBase64 = async (fontPath: string): Promise<string> => {
  const blob = await fetch(fontPath).then((response) => response.blob());
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      if (reader.result) {
        const dataUrl = reader.result;
        return resolve(dataUrl.toString().split(',')[1]);
      }
      return reject('Fetching font has failed.');
    };
    reader.readAsDataURL(blob);
  });
};

// const fontPathToBase64_ = async (fontPath: string): Promise<string> => {
//   const fontBytes = await fetch(fontPath).then((res) => res.arrayBuffer());
//   // @ts-ignore
//   return btoa(String.fromCharCode.apply(null, new Uint8Array(fontBytes)));
// };
