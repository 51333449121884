import {Divider, IconButton, List} from '@material-ui/core';
import React, {useEffect} from 'react';
import {ModelDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import styles from './ModelActionMenu.module.scss';
import {isModelDTO, isModelWeb, stringifyCountry, useModelDetail, isModelExternal} from '../../../../../store/model';
import {useDispatch} from 'react-redux';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {getUrlRoute, Routes, SubModelSection} from '../../../../router/routes';
import SideBar from '../../../../layout/common/SideBar';
import {useSharedSideBarToggle} from '../../../../../store/customSettings';
import {
  versionControlDiffSelectionSelector,
  versionControlInit,
  versionControlPage,
  VersionControlPage,
  versionControlToggleDiffSelection,
} from '../../../../../store/versionControl';
import clsx from 'clsx';
import {useSelector} from '../../../../../store/rootReducer';
import {useModelFlatHierarchy} from '../../../../../store/modelLinks';
// @ts-ignore
import getScrollbarWidth from 'react-custom-scrollbars/lib/utils/getScrollbarWidth';
import {Link} from 'react-router-dom';
import ModelDeleteDraftSideBarModalMenuButton from '../ModelDeleteDraftSideBarModal/ModelDeleteDraftSideBarModalMenuButton';
import ModelShareSideBarModalMenuButton from '../ModelShareSideBarModal/ModelShareSideBarModalMenuButton';
import ModelDownloadSideBarModalMenuButton from '../ModelDownloadAllSideBarModal/ModelDownloadSideBarModalMenuButton';
import PublishModelActionMenuButton from '../ModelPublish/PublishModelActionMenuButton';
import ModelRetireActionMenuButton from '../ModelRetire/ModelRetireActionMenuButton';
import ModelDownloadActionMenuButton from '../ModelDownload/ModelDownloadActionMenuButton';
import SubModelListActionMenuLink from '../../../subModel/common/SubModelActionMenu/SubModelListActionMenuLink';
import ModelCloneActionMenuButton from '../ModelCloneSideBarModal/ModelCloneActionMenuButton';
import ModelEditPermissionsActionMenuButton from '../ModelEdit/ModelEditPermissons/ModelEditPermissionsActionMenuButton';
import ModelEditLineageActionMenuButton from '../ModelEdit/ModelEditLineage/ModelEditLineageActionMenuButton';
import ModelEditMetadataActionMenuButton from '../ModelEdit/ModelEditMetadata/ModelEditMetadataActionMenuButton';
import ModelEditFilesActionMenuButton from '../ModelEdit/ModelEditFiles/ModelEditFilesActionMenuButton';
import ModelEditSuffixActionMenuButton from '../ModelEdit/ModelEditSuffix/ModelEditSuffixActionMenuButton';
import {canEditModel, canViewModel, PERMISSION} from '../../../../../permissions/usePermissions';
import {Compare16, Edit16, RecentlyViewed16, Run16, Version16, Launch16} from '@carbon/icons-react';
import VisualizationCreateActionMenuLink from '../../../visualization/common/VisualizationCreateActionMenuLink/VisualizationCreateActionMenuLink';

const sidebarWidths = {
  collapsed: `calc(4rem + ${getScrollbarWidth()}px)`,
  expanded: `calc(22rem + ${getScrollbarWidth()}px)`,
};

type ModelActionMenuProps = {
  model: ModelDTO;
  onModelDelete?: () => void;
  onSuccess?: () => void;
};

export const ModelActionMenu: React.FC<Partial<ModelActionMenuProps>> = ({model, onModelDelete}) => {
  const dispatch = useDispatch();

  const modelDetail = useModelDetail(model) || undefined;
  const modelOrUndefined = model || undefined;
  const {uuid} = model || {};

  useEffect(() => {
    if (uuid) {
      dispatch(versionControlInit(uuid));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uuid]);

  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);
  const flatHierarchy = useModelFlatHierarchy(model?.uuid);
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);

  return (
    <SideBar
      position="right"
      {...sharedSidebar}
      {...tid('model-list-action-sidebar')}
      className={styles.modelActionMenu}>
      <List dense component="nav">
        {webModel && (
          <>
            <div className={styles.title} style={{marginTop: 0}}>
              <Run16 />
              Execution
            </div>
            <SubModelListActionMenuLink model={modelOrUndefined} section={SubModelSection.SCENARIO} foreignContext />
            <SubModelListActionMenuLink model={modelOrUndefined} section={SubModelSection.CALIBRATION} foreignContext />
            <SubModelListActionMenuLink model={modelOrUndefined} section={SubModelSection.DISPLAY} foreignContext />
            <VisualizationCreateActionMenuLink model={modelOrUndefined} />
          </>
        )}

        {canEditModel() !== PERMISSION.FORBIDDEN && (
          <>
            <div className={styles.title}>
              <Edit16 />
              Editing
            </div>

            <ModelEditSuffixActionMenuButton model={model} />
            <ModelEditFilesActionMenuButton model={model} />
            <ModelEditMetadataActionMenuButton model={model} />
            <ModelEditLineageActionMenuButton model={model} />
            <ModelEditPermissionsActionMenuButton model={model} />

            <Divider />
          </>
        )}
        <ModelCloneActionMenuButton model={model} />

        <PublishModelActionMenuButton model={modelDetail} />

        <ModelDownloadActionMenuButton model={modelDetail} fromSideBar={true} />

        <ModelDownloadSideBarModalMenuButton />

        <ModelShareSideBarModalMenuButton model={model} />

        {canViewModel() !== PERMISSION.FORBIDDEN && (
          <>
            <div className={styles.title}>
              <Version16 />
              Version Tracking
            </div>
            {(flatHierarchy || [])
              .filter((n) => isModelDTO(n.model))
              .map((n) => (
                <SideBarButtonItem
                  key={n.model.uuid}
                  {...tid('btn', 'version', n.model.uuid)}
                  onClick={() => dispatch(versionControlToggleDiffSelection(n.model.uuid))}
                  className={clsx(styles.toggleDiffButton, {
                    [styles.current]: n.model.uuid === model?.uuid,
                    [styles.selected]: versionDiffSelection.some((modelUuid) => modelUuid === n.model.uuid),
                  })}
                  text={
                    <>
                      {[stringifyCountry(n.model), n.model.metadata.version_release_of_model]
                        .filter(Boolean)
                        .join(' | ')}
                      &nbsp;
                      {n.model.uuid !== model?.uuid && (
                        <IconButton
                          size="small"
                          component={Link}
                          to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: n.model.uuid || ''})}
                          {...tid('model-card-detail')}>
                          <Launch16 />
                        </IconButton>
                      )}
                    </>
                  }
                />
              ))}
            <SideBarButtonItem
              {...tid('btn', 'version-compare')}
              permission={canViewModel(model)}
              disabled={versionDiffSelection.length !== 2 || externalModel}
              onClick={() => dispatch(versionControlPage(VersionControlPage.DIFF))}
              icon={<Compare16 />}
              text="Compare Model Entries"
            />
            <SideBarButtonItem
              {...tid('btn', 'version-history')}
              permission={canViewModel(model)}
              disabled={!model || versionDiffSelection.length < 1 || externalModel}
              onClick={() => dispatch(versionControlPage(VersionControlPage.HISTORY))}
              icon={<RecentlyViewed16 />}
              text="See History"
            />

            <Divider />

            <ModelDeleteDraftSideBarModalMenuButton model={model} onSuccess={onModelDelete} />

            <ModelRetireActionMenuButton model={model} />
          </>
        )}
      </List>
    </SideBar>
  );
};
