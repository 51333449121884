import {AsyncAction} from '../asyncUtilsTypes';
import {ApplicationSettingsDTO, VersionInfoDTO} from 'hemwb-api';

export enum AppActions {
  LOAD_SETTINGS = 'APP/LOAD_SETTINGS',
  LOAD_VERSION = 'APP/LOAD_VERSION',
  SET_ZOOM = 'APP/SET_ZOOM',
}

export type AppActionSetZoom = {
  type: AppActions.SET_ZOOM;
  zoom: number;
};

export type AppAction =
  | AsyncAction<AppActions.LOAD_SETTINGS, ApplicationSettingsDTO, void>
  | AsyncAction<AppActions.LOAD_VERSION, VersionInfoDTO, void>
  | AppActionSetZoom;
