import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Error500Page} from './Error500Page';
import {AccessDeniedPage} from './AccessDeniedPage';
import {Error404Page} from './Error404Page';
import UnauthorisedPage from './UnauthorisedPage';

export const ErrorRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/error/500" component={Error500Page} />
      <Route path="/error/access-denied" component={AccessDeniedPage} />
      <Route path="/error/unauthorised" component={UnauthorisedPage} />
      <Route path="/error" component={Error404Page} />
    </Switch>
  );
};
