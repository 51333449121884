import {useCallback, useState} from 'react';
import {requestScenarioGetOutputRaw} from '../../../../../store/scenario';
import {trackInstanceResultsDownloaded} from '../../../../../tracking/tracking';
import {downloadBlob} from '../../../../../utils/download';
import {ScenarioActionProps} from '../../../actionMenuTypes';

export const useScenarioDownloadOutput = (props: Partial<ScenarioActionProps>) => {
  const {model, subModel, scenario} = props;
  const [downloadingResults, setDownloadingResults] = useState(false);

  const downloadResults = useCallback(() => {
    if (scenario && model && subModel) {
      setDownloadingResults(true);
      requestScenarioGetOutputRaw(scenario.id)
        .then((response) => {
          response.value().then((blob) => {
            trackInstanceResultsDownloaded(model, subModel, scenario);
            const name = response.raw.headers
              .get('content-disposition')
              ?.split('filename=')[1]
              ?.toString()
              .replaceAll('"', '');
            downloadBlob(blob, name);
          });
        })
        .finally(() => setDownloadingResults(false));
    }
  }, [model, subModel, scenario]);

  return {downloadResults, downloadingResults};
};
