import React, {CSSProperties} from 'react';

type TableCellProps = {
  children: any;
  isDragOccurring: boolean;
  style?: CSSProperties;
  className?: string;
};

type TableCellSnapshot = {
  width: number;
  height: number;
};

export class InstanceTableCardCell extends React.Component<TableCellProps> {
  private ref: any;

  getSnapshotBeforeUpdate(prevProps: TableCellProps): TableCellSnapshot | null {
    if (!this.ref) {
      return null;
    }

    const isDragStarting: boolean = this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting) {
      return null;
    }

    const {width, height} = this.ref.getBoundingClientRect();

    return {
      width,
      height,
    };
  }

  componentDidUpdate(prevProps: TableCellProps, prevState: TableCellProps, snapshot: TableCellSnapshot | null) {
    const ref = this.ref;
    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  setRef = (ref: HTMLElement | null) => {
    this.ref = ref;
  };

  render() {
    const {children, isDragOccurring, ...restProps} = this.props;
    return (
      <td ref={this.setRef} {...restProps}>
        {children}
      </td>
    );
  }
}
