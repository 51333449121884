import {RootState} from '../rootReducer';
import {useReduxLoader} from '../useReduxLoader';
import {guidingTourLoadDetail} from './actions';
import {guidingTourSelector} from './selectors';

const userGuidingTourSelectorBuilder = (params: [string, string]) => (state: RootState) =>
  guidingTourSelector(state).detail[`${params[0]}-${params[1]}`] || null;

export const useGuidingTour = (type: string, code: string) => {
  return useReduxLoader(userGuidingTourSelectorBuilder, guidingTourLoadDetail, [type, code]);
};
