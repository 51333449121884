import {IncidentListDTO, IncidentDTO} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';

export enum IncidentManagementActions {
  FETCH_LIST = 'INCIDENT/FETCH_LIST',
  FETCH_DETAIL = 'INCIDENT/FETCH_DETAIL',
  UPDATE = 'INCIDENT/UPDATE',
}

export type IncidentManagementAction =
  | AsyncAction<IncidentManagementActions.FETCH_LIST, IncidentListDTO[], []>
  | AsyncAction<IncidentManagementActions.FETCH_DETAIL, IncidentListDTO, [string]>
  | AsyncAction<IncidentManagementActions.UPDATE, IncidentListDTO, [string, IncidentDTO]>;

export type StoredIncident = IncidentListDTO;
