import React, {DragEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useExecutionSetupList} from '../../../../store/executionSetup';
import {getUrlRoute, Routes, useMatchedRoute} from '../../../router/routes';
import {useModel} from '../../../../store/model';
import ModelCard from '../common/ModelCard';
import styles from './VisualizationStep1.module.scss';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import DefaultPage from '../../../layout/common/DefaultPage';
import SubModelCard from '../common/SubModelCard';
import {Search} from '../../../core/Search/Search';
import {ExecutionSetupType, ScenarioDTO, ScenarioGroupDetailDTO} from 'hemwb-api';
import ScenarioCurve from '../common/ScenarioCurve';
import {getSubModelColorHashCode} from '../common/constants';
import {StringHashMap} from '../../../../commonTypes';
import ScenarioGrid from './ScenarioGrid';
import LeftSideBar from './LeftSideBar';
import VisualizationStep1ActionMenu from './VisualizationStep1ActionMenu';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../store/message';
import ModelCurve from '../common/ModelCurve';
import {useHistory} from 'react-router';
import VisualizationStepper, {VisualizationSteps} from '../common/VisualizationStepper';
import SubModelFolderCard from '../common/SubModelFolderCard';
import {useSelector} from '../../../../store/rootReducer';
import {
  visualizationDiscardChanges,
  visualizationInit,
  visualizationSelector,
  visualizationSetFilter,
  visualizationToggleSelection,
  visualizationToggleSubModel,
  visualizationUnloadAnalysis,
} from '../../../../store/visualization';
import InstanceTableCard from '../common/InstanceTableCard';
import {InstanceGroupAssociation, ScenarioGridFilter} from './types';
import {Button} from '@material-ui/core';
import {Edit16} from '@carbon/icons-react';
import ButtonWithPopper from '../../../core/ButtonWithPopper/ButtonWithPopper';
import EditGroupLabelPopperContent from './EditGroupLabelPopperContent';
import SubModelActionPopperContent from './SubModelActionPopperContent';
import {useToggleValues} from '../../../../hooks/useToggleValues';
import {useAnalysisLoader} from '../common/useAnalysisLoader';
import {isScenarioRunning, useScenarioDetail} from '../../../../store/scenario';
import builderStyles from '../../scenario/editor/ScenarioParametersBuilder.module.scss';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import CancelButton from '../../../core/Buttons/CancelButton';
import {useScenarioGroups} from '../../../../store/scenarioGroup/hooks';
import {scenarioGroupDelete, scenarioGroupUpdate} from '../../../../store/scenarioGroup';
import {useScenarioStatusUpdates} from '../../../../websocket/useScenarioStatusUpdates';
import {useVisualizationScenarioGroupAddDialog} from '../common/VisualizationScenarioGroupAdd/useVisualizationScenarioGroupAddDialog';
import {useScenarioAddDialog} from '../../scenario/common/ScenarioAdd/useScenarioAddDialog';
import {canAddInstance} from '../../../../permissions/usePermissions';
import {useDialogGenericPromise} from '../../../core/dialog/useDialogGenericPromise';
import {deleteGroupDialogConfiguration} from './constants';
import {trackScenarioSelectionTabEvent} from '../../../../tracking/tracking';

const defaultGroups: ScenarioGroupDetailDTO[] = [];

const VisualizationStep1: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useScenarioStatusUpdates();
  const {modelUuid, scenarioId: _scenarioId} = useMatchedRoute(Routes.VISUALIZATION_STEP_1);
  const scenarioId = Number(_scenarioId);
  const model = useModel(modelUuid);
  const scenarioDetail = useScenarioDetail(scenarioId);
  const groupList = useScenarioGroups(modelUuid);
  const groups = groupList?.list || defaultGroups;
  const groupListRef = useRef(groupList);
  let groupId: number;

  const scenarioInProgress = isScenarioRunning(scenarioDetail?.status);

  useEffect(() => {
    groupListRef.current = groupList;
  }, [groups, groupList]);

  useAnalysisLoader(modelUuid, scenarioId);

  const storedState = useSelector(visualizationSelector);
  const {filter, openedSubModel} = storedState.step1;
  const {selectedScenarios = {}} = storedState.selection || {};
  const markedScenarios =
    filter === ScenarioGridFilter.SAVED_ANALYSES && scenarioId ? {[scenarioId]: true} : selectedScenarios;

  const [instances, setInstances] = useState<ScenarioDTO[]>();
  const [searchTextSubModel, setSearchTextSubModel] = useState('');
  const [searchTextInstance, setSearchTextInstanceCb] = useState('');
  const setSearchTextInstance = useCallback((s: string) => {
    setSearchTextInstanceCb(s.toLowerCase());
  }, []);
  const instanceGroupAssociation = useMemo(() => {
    if (!instances || !groups) {
      return {};
    }
    return instances.reduce((acc, instance) => {
      const group = groups.find((g) => g.scenarioIds.includes(instance.id));
      if (group) {
        acc[instance.id] = group;
      }
      return acc;
    }, ({} as any) as InstanceGroupAssociation);
  }, [instances, groups]);

  const {
    toggle: toggleSelectedGroup,
    values: selectedGroups,
    replace: replaceSelectedGroups,
    isSelected: isGroupSelected,
  } = useToggleValues();

  useEffect(() => {
    if (openedSubModel) {
      replaceSelectedGroups(groups.filter((g) => g.executionSetupId === openedSubModel.id).map((g) => g.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedSubModel, replaceSelectedGroups]);

  useEffect(() => {
    dispatch(visualizationInit(modelUuid));
  }, [dispatch, modelUuid]);

  const list1 = useExecutionSetupList(modelUuid, ExecutionSetupType.CALIBRATION);
  const list2 = useExecutionSetupList(modelUuid, ExecutionSetupType.SCENARIO);
  const subModelList = useMemo(() => {
    return [...(list1 || []), ...(list2 || [])]
      .filter((s) => s.displayExecutionSetupId)
      .filter((s) => s.name.toLowerCase().includes(searchTextSubModel));
  }, [list1, list2, searchTextSubModel]);

  const canvasRef = useRef(null);
  const subModelsRef = useRef<any>({});
  const groupsRef = useRef<any>({});
  const scenariosRef = useRef<any>({});

  const subModelColorHashMap = useMemo(() => {
    return subModelList.reduce((acc, s) => {
      acc[s.id] = getSubModelColorHashCode(s);
      return acc;
    }, ({} as any) as StringHashMap);
  }, [subModelList]);

  const [forceRenderCounter, setForceRenderCounter] = useState(0);
  const forceRender = useCallback(() => {
    setForceRenderCounter((f) => f + 1);
  }, []);
  useLayoutEffect(() => {
    forceRender();
  }, [searchTextSubModel, instanceGroupAssociation, selectedGroups, forceRender]);
  const handleClickDataVisualization = useCallback(() => {
    if (Object.keys(selectedScenarios).length === 0) {
      dispatch(messageAdd(`Unable to proceed. No scenario has been selected.`, MessageTypes.WARNING));
    } else {
      history.push(getUrlRoute(Routes.VISUALIZATION_STEP_2, {modelUuid}));
    }
    if (model) {
      trackScenarioSelectionTabEvent(model);
    }
  }, [dispatch, history, modelUuid, model, selectedScenarios]);

  const handleDiscardChanges = useCallback(() => {
    dispatch(visualizationDiscardChanges());
  }, [dispatch]);

  const handleDrop = useCallback(
    (groupId: number) => (e: DragEvent<HTMLLIElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const data = e.dataTransfer?.getData('scenarioId');
      const scenarioId = data ? parseInt(data) : undefined;
      const gs = groupListRef.current?.list;
      if (scenarioId && gs) {
        const prevGroupIndex = gs.findIndex((g) => g.scenarioIds.includes(scenarioId));
        const newGroupIndex = groupId ? gs.findIndex((g) => g.id === groupId) : -1;

        if (prevGroupIndex === newGroupIndex || !scenarioId) {
          return gs;
        }

        const gsClone = [...gs];

        if (prevGroupIndex !== -1) {
          const {id, name} = gs[prevGroupIndex];

          scenarioGroupUpdate(dispatch, id, {
            name,
            scenarioIds: gsClone[prevGroupIndex].scenarioIds.filter((sId) => sId !== scenarioId),
          });
        }

        if (newGroupIndex !== -1) {
          const {id, name} = gs[newGroupIndex];

          scenarioGroupUpdate(dispatch, id, {
            name,
            scenarioIds: [...gsClone[newGroupIndex].scenarioIds, scenarioId],
          });
        }

        return gsClone;
      }
    },
    [dispatch],
  );

  const handleDragOver = useCallback((e: DragEvent<HTMLLIElement>) => {
    e.preventDefault();
  }, []);

  const handleAnalysisCardClick = useCallback(
    (scenario: ScenarioDTO) => {
      if (scenario.id === scenarioId) {
        dispatch(visualizationUnloadAnalysis(scenario.id));
        history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid}));
      } else {
        history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid, scenarioId: scenario.id}));
      }
    },
    [history, dispatch, modelUuid, scenarioId],
  );

  const {
    open: openScenarioGroupAddDialog,
    Component: ScenarioGroupAddDialog,
  } = useVisualizationScenarioGroupAddDialog();
  const scenarioGridRef = useRef<any>();
  const {open: openInstanceAddDialog, Component: InstanceAddDialog} = useScenarioAddDialog(scenarioGridRef);

  const {DialogComponent: DeleteGroupDialog, openDialog: openDeleteGroupDialog} = useDialogGenericPromise(
    deleteGroupDialogConfiguration,
  );

  if (!model || !list1 || !list2) {
    return (
      <DefaultPage>
        <SuspenseNull />
      </DefaultPage>
    );
  }
  return (
    <>
      <LeftSideBar filterTab={filter} setFilterTab={(filter) => dispatch(visualizationSetFilter(filter))} />
      <DefaultPage style={{position: 'relative'}}>
        <div className={styles.container}>
          <VisualizationStepper step={VisualizationSteps.STEP_1} />
          <div className={styles.pageContainer}>
            <div className={styles.modelColumnContainer}>
              <ModelCard model={model} />

              <div className={styles.searchContainer}>
                <Search
                  debounce={100}
                  value={searchTextSubModel}
                  onValueChange={setSearchTextSubModel}
                  placeholder="Search"
                />
                <ModelCurve />
              </div>

              <div className={styles.subModelList}>
                <div className="fileTree">
                  <ol>
                    {subModelList.map((s) => {
                      return (
                        <li
                          key={s.id}
                          onDrop={handleDrop(0)}
                          onDragOver={handleDragOver}
                          style={{position: 'relative'}}>
                          <SubModelCard
                            isSelected={openedSubModel?.id === s.id}
                            onClick={() => dispatch(visualizationToggleSubModel(s))}
                            subModel={s}
                            ref={(el) => {
                              subModelsRef.current[s.id] = el;
                            }}
                          />
                          <ButtonWithPopper
                            popperButtonSize="small"
                            group="subModelActions"
                            placement="bottom-end"
                            renderPopperContent={({handleClose}) => (
                              <SubModelActionPopperContent
                                permission={canAddInstance(model, s)}
                                onClickCreateGroup={() => {
                                  handleClose();
                                  openScenarioGroupAddDialog({
                                    model,
                                    subModel: s,
                                  });
                                  // scenarioGroupCreate(dispatch, s.id, {name: 'New Group', scenarioIds: []});
                                }}
                                onClickAddInstance={() => {
                                  handleClose();
                                  openInstanceAddDialog({
                                    model,
                                    subModel: s,
                                  });
                                }}
                              />
                            )}
                            style={{position: 'absolute', left: '22.5rem', top: '2rem'}}
                            theme="expandedButton"
                          />
                          <ol>
                            {groups
                              .filter((g) => openedSubModel?.id === s.id && g.executionSetupId === s.id)
                              .map((g) => {
                                return (
                                  <li key={g.id} onDrop={handleDrop(g.id)} onDragOver={handleDragOver}>
                                    <SubModelFolderCard
                                      subModel={s}
                                      group={g}
                                      isSelected={isGroupSelected(g.id)}
                                      onClick={() => {
                                        toggleSelectedGroup(g.id);
                                      }}
                                      onClickUnlink={(event: React.MouseEvent<any>) => {
                                        event.stopPropagation();
                                        openDeleteGroupDialog().then((buttonIndexClicked) => {
                                          if (buttonIndexClicked === 1) {
                                            scenarioGroupDelete(dispatch, g.id);
                                          }
                                        });
                                      }}
                                      ref={(el) => {
                                        groupsRef.current[g.id] = el;
                                      }}
                                    />
                                  </li>
                                );
                              })}
                          </ol>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
            <svg style={{width: 60, minWidth: 60}} ref={canvasRef}>
              {instances &&
                openedSubModel &&
                [...instances.filter((i) => !markedScenarios[i.id]), ...instances.filter((i) => markedScenarios[i.id])]
                  .filter((i) => {
                    const group = instanceGroupAssociation[i.id];
                    if (!group) {
                      return true;
                    }
                    return isGroupSelected(group.id);
                  })
                  .map((i) => {
                    return (
                      <ScenarioCurve
                        key={`${searchTextSubModel}${i.id}${forceRenderCounter}}`}
                        canvasRef={canvasRef}
                        scenariosRef={scenariosRef}
                        subModelsRef={subModelsRef}
                        groupsRef={groupsRef}
                        instanceGroupAssociation={instanceGroupAssociation}
                        // @ts-ignore
                        // subModelId={i.executionSetupId}
                        subModelId={openedSubModel.id}
                        scenarioId={i.id}
                        // @ts-ignore
                        color={markedScenarios[i.id] && openedSubModel && subModelColorHashMap[openedSubModel.id]}
                      />
                    );
                  })}
            </svg>
            {openedSubModel && (
              <div className={styles.scenarioContainer}>
                <div className={styles.searchContainer}>
                  <Search
                    debounce={100}
                    value={searchTextInstance}
                    onValueChange={setSearchTextInstance}
                    placeholder="Search"
                  />
                </div>

                <ScenarioGrid
                  ref={scenarioGridRef}
                  model={model}
                  subModel={openedSubModel}
                  filter={filter}
                  search={searchTextInstance}
                  groups={groups}
                  onRender={setInstances}
                  rowRenderer={(scenario) => {
                    const checked = markedScenarios[scenario.id] || false;
                    const I = (
                      <InstanceTableCard
                        ref={(el) => {
                          scenariosRef.current[scenario.id] = el;
                        }}
                        scenarioGridRef={scenarioGridRef}
                        model={model}
                        subModel={openedSubModel}
                        key={scenario.id}
                        scenario={scenario}
                        color={checked ? subModelColorHashMap[openedSubModel.id] : undefined}
                        checked={checked}
                        onCheckedChange={() => dispatch(visualizationToggleSelection(openedSubModel, scenario))}
                        filter={filter}
                        onAnalysisClick={handleAnalysisCardClick}
                        scenarioInProgress={scenarioInProgress}
                      />
                    );

                    const g = instanceGroupAssociation[scenario.id];
                    const renderGroupHeader = groupId !== g?.id;
                    groupId = g?.id;

                    if (g && !isGroupSelected(g.id)) {
                      return null;
                    }

                    if (g && renderGroupHeader) {
                      return (
                        <>
                          <tr>
                            <td style={{height: 60}} colSpan={7}>
                              <span className={styles.groupTitle}>{g.name}&nbsp;</span>{' '}
                              <ButtonWithPopper
                                placement="bottom-start"
                                renderButton={(props) => (
                                  <Button
                                    style={{fontWeight: 600}}
                                    {...props}
                                    variant="outlined"
                                    size="small"
                                    startIcon={<Edit16 style={{width: '1.8rem', height: '1.8rem'}} />}>
                                    Edit Group Label
                                  </Button>
                                )}
                                renderPopperContent={({handleClose}) => (
                                  <EditGroupLabelPopperContent
                                    model={model}
                                    subModel={openedSubModel!}
                                    group={g}
                                    handleClose={handleClose}
                                    // onSubmit={() => undefined}
                                    onSubmit={(name: string) => {
                                      handleClose();
                                      scenarioGroupUpdate(dispatch, g.id, {...g, name});
                                    }}
                                  />
                                )}
                              />
                            </td>
                          </tr>
                          {I}
                        </>
                      );
                    }

                    return I;
                  }}
                />
              </div>
            )}
          </div>

          <div className={builderStyles.footer} style={{zIndex: 1300}}>
            <div className="buttonsContainer">
              <CancelButton
                disabled={!storedState.analyseDataChanged || scenarioInProgress}
                onClick={handleDiscardChanges}>
                Discard Changes
              </CancelButton>
              <SubmitButton active={scenarioInProgress} onClick={handleClickDataVisualization}>
                Save & Proceed
              </SubmitButton>
            </div>
          </div>
        </div>
      </DefaultPage>

      <VisualizationStep1ActionMenu
        modelUuid={modelUuid}
        onClickCreateVisualization={handleClickDataVisualization}
        model={model}
        scenario={scenarioDetail || undefined}
        scenarioInProgress={scenarioInProgress}
      />
      {ScenarioGroupAddDialog}
      {InstanceAddDialog}
      {DeleteGroupDialog}
    </>
  );
};

export default VisualizationStep1;
