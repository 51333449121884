import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch, useSelector} from 'react-redux';
import {ModelFlatNode, useModelFlatHierarchy} from '../../../../../store/modelLinks';
import {
  versionControlDiffSelectionSelector,
  versionControlToggleDiffSelection,
} from '../../../../../store/versionControl';
import {Tree, TreeNode} from 'react-organizational-chart';
import {ModelCard} from '../ModelCard/ModelCard';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import styles from './ModelVersionControl.module.scss';
import {isModelDTO} from '../../../../../store/model';

type ModelVersionControlLineageProps = {
  model: ModelDTO;
};

const primarySelectionStyle = {
  border: '2px solid #0062ff',
  // boxShadow: '0 4px 20px 0 #0062ff80',
};
const secondarySelectionStyle = {
  border: '2px solid #366fff',
  // boxShadow: '0 4px 20px 0 #366fff80',
};
const noSelectionStyle = {};

const ModelVersionControlLineage: React.FC<ModelVersionControlLineageProps> = ({model}) => {
  const dispatch = useDispatch();
  const flatHierarchy = useModelFlatHierarchy(model.uuid);
  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);

  const renderChild = (node: ModelFlatNode) => {
    if (!isModelDTO(node.model)) {
      return null;
    }

    const treeSecondaryStyle = () => {
      return node.model.uuid === versionDiffSelection[1] ? secondarySelectionStyle : noSelectionStyle;
    };

    return (
      <TreeNode
        key={node.model.uuid}
        label={
          <ModelCard
            style={node.model.uuid === versionDiffSelection[0] ? primarySelectionStyle : treeSecondaryStyle()}
            model={node.model}
            onClick={() => dispatch(versionControlToggleDiffSelection(node.model.uuid))}
            expanded={node.model.uuid === model.uuid}
          />
        }>
        {flatHierarchy!.filter((n) => n.parentId === node.model.uuid)?.map(renderChild)}
      </TreeNode>
    );
  };

  const flatHierarchySecondaryStyle = () => {
    if (flatHierarchy)
      return flatHierarchy[0].model.uuid === versionDiffSelection[1] ? secondarySelectionStyle : noSelectionStyle;
  };

  return (
    <SuspenseNull>
      {flatHierarchy && flatHierarchy[0] && (
        <>
          <div className={styles.container}>
            <Tree
              lineWidth="3px"
              lineBorderRadius="10px"
              lineColor="#0062ff"
              label={
                <ModelCard
                  style={
                    flatHierarchy[0].model.uuid === versionDiffSelection[0]
                      ? primarySelectionStyle
                      : flatHierarchySecondaryStyle()
                  }
                  model={flatHierarchy[0].model}
                  onClick={() =>
                    isModelDTO(flatHierarchy[0].model)
                      ? dispatch(versionControlToggleDiffSelection(flatHierarchy[0].model.uuid))
                      : null
                  }
                  expanded={flatHierarchy[0].model.uuid === model.uuid}
                />
              }>
              {flatHierarchy.filter((n) => n.parentId === flatHierarchy[0].model.uuid).map(renderChild)}
            </Tree>
          </div>
        </>
      )}
    </SuspenseNull>
  );
};

export default ModelVersionControlLineage;
