import {markAllAsTouched} from '../../Form';
import {FormArray, FormGroup} from 'react-reactive-form';
import {generators} from './generators';
import {InputAttributes, InputType} from '../InputDefinition/types';
import {CalibrationScenarioInputs} from './types';

export const scenarioFormGenerator = (
  definitions: InputAttributes[] = [],
  values: CalibrationScenarioInputs = {},
  useFormDefaults = true,
) => {
  return new FormGroup({
    parameters: new FormArray(
      (useFormDefaults ? definitions : definitions.map((d) => removeDefaultValue(d))).map((attr) => {
        const control = generators[attr.type].buildControl(attr as any, (values || {})[attr.name]);
        markAllAsTouched(control);
        return control;
      }),
    ),
  });
};

const removeDefaultValue = (definition: InputAttributes): InputAttributes => {
  switch (definition.type) {
    case InputType.ARRAY: {
      return {
        ...definition,
        // @ts-ignore
        columns: definition.columns.map(({defaultValue, ...c}) => ({...c, defaultValue: defaultValue.map(() => null)})),
      };
    }
    case InputType.SINGLE_SELECT:
    case InputType.MULTI_SELECT: {
      return {
        ...definition,
        options: definition.options.map((o) => ({...o, selected: false})),
      };
    }
  }

  const definitionClone = {...definition};

  //@ts-ignore
  delete definitionClone['defaultValue'];
  return definitionClone;
};
