import React, {ReactNode} from 'react';
import styles from './ModelCardExpanded.module.scss';
import {IconButton, Tooltip} from '@material-ui/core';
import {getModelSteward, isModelRetired} from '../../../../../store/model';
import {tid} from '../../../../../testUtils';
import {Minimize16, Launch16, Email16, DecisionTree16} from '@carbon/icons-react';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../../../router/routes';
import clsx from 'clsx';
import {SharedModelCardProps} from './types';
import {
  CloudServices,
  EuropeAfrica,
  FinancialGain,
  MedicalCharts,
  Microorganisms,
  Networking_03 as Networking03,
  NetworkServices,
  ResetHybridCloud,
} from '@carbon/pictograms-react';
import ModelDownloadActionMenuButton from '../ModelDownload/ModelDownloadActionMenuButton';
import ModelOverviewRequestAccess from '../ModelOverview/ModelOverviewRequestAccess';
import ModelTextOverviewTile from '../ModelTextOverview/ModelTextOverviewTile';
import {getModelLanguageAndExecutionEnvironment} from '../ModelTextOverview/utils';
import {ModelDetailDTO, UserModelAccess, UserType} from 'hemwb-api';

type ModelCardExpandedDynamicViewProps = SharedModelCardProps & {
  onClickCollapse?: () => void;
  Content?: ReactNode;
  hideLeftSection?: boolean;
  toggleVersion: () => void;
};

const ModelCardExpandedDynamicView: React.FC<ModelCardExpandedDynamicViewProps> = ({
  hideDetailLink = false,
  onClickCollapse,
  model,
  selectable = true,
  className,
  current = false,
  Content,
  hideLeftSection = false,
  toggleVersion,
  ...restProps
}) => {
  const modelSteward = model ? getModelSteward(model) : null;

  return (
    <div
      className={clsx('card', styles.container, className, {
        [styles.notSelectable]: !selectable,
        [styles.retired]: isModelRetired(model),
        [styles.selectable]: !!restProps.onClick,
        [styles.current]: current,
      })}
      {...restProps}>
      <div className={styles.wrapper}>
        {!hideLeftSection && model && (
          <div className={styles.leftSectionDynamic}>
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.study_number}
              Icon={<MedicalCharts />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.product}
              Icon={<Microorganisms />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.model_type}
              Icon={<FinancialGain />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={getModelLanguageAndExecutionEnvironment(model.metadata)}
              Icon={<NetworkServices />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.country}
              Icon={<EuropeAfrica />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.status}
              Icon={<CloudServices />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=" "
              value={model.metadata.version_release_of_model}
              Icon={<ResetHybridCloud />}
              className={styles.tileItem}
            />
            <ModelTextOverviewTile
              label=""
              value={model.metadata.version_log}
              Icon={<Networking03 />}
              className={styles.tileItem}
            />
          </div>
        )}
        <div className={styles.rightSectionDynamic}>
          <div className={styles.dynamicActionContainer}>
            {onClickCollapse && (
              <div style={{position: 'relative', top: '5px'}}>
                <Tooltip title="Minimize">
                  <IconButton
                    size="small"
                    className="icon32"
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickCollapse();
                    }}
                    {...tid('model-card-collapse', model?.uuid || '')}>
                    <span>
                      <Minimize16 />
                    </span>
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!hideDetailLink && model?.uuid && (
              <div style={{position: 'relative', top: '25px'}}>
                <Tooltip title="More Details">
                  <IconButton
                    className="icon32"
                    size="small"
                    component={Link}
                    to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}
                    {...tid('model-card-detail', model.uuid)}>
                    <Launch16 />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {model && (
              <div style={{position: 'relative', top: '50px'}}>
                <Tooltip title="Show Versions">
                  <IconButton
                    aria-label="Model Tree"
                    className="icon32"
                    size="small"
                    onClick={toggleVersion}
                    {...tid('model-tree-button', model.uuid)}>
                    <DecisionTree16 />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {model?.uuid && modelSteward && (
              <div style={{position: 'relative', top: '75px'}}>
                <Tooltip title={`mailto:${modelSteward.email}`}>
                  <IconButton
                    aria-label="Compose Email"
                    size="small"
                    className="icon32"
                    href={`mailto:${modelSteward.email}`}
                    {...tid('model-card-email', model.uuid)}>
                    <Email16 />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {model &&
              model.userAccess &&
              [
                UserModelAccess.OWNER,
                UserModelAccess.STEWARD,
                UserType.SUPERUSER,
                UserModelAccess.VIEWER,
                UserModelAccess.VESTLEAD,
              ].includes(model.userAccess) && (
                <Tooltip title="Download">
                  <div style={{position: 'relative', top: '100px'}}>
                    <ModelDownloadActionMenuButton model={model as ModelDetailDTO} fromDynamicSearch={true} />
                  </div>
                </Tooltip>
              )}
            {model && (
              <Tooltip title="Request Access">
                <div style={{position: 'relative', top: '100px'}}>
                  <ModelOverviewRequestAccess model={model} fromDynamicSearch={true} />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelCardExpandedDynamicView;
