/* eslint-disable @typescript-eslint/camelcase */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import DefaultPage from '../../layout/common/DefaultPage';
import ModelUploadHeader from './ModelUploadHeader';
import StepFiles from './StepFiles/StepFiles';
import StepMetadata from './StepMetada/StepMetadata';
import StepLineage from './StepLineage/StepLineage';
import StepPermissions from './StepPermissions/StepPermissions';
import StepNameConfirmation from './StepNameConfirmation/StepNameConfirmation';
import {
  modelFileMarkChangelog,
  modelFileSelector,
  modelFileUploaderClear,
  ModelTreeNode,
  useModelFileUploader,
} from '../../../store/modelFile';
import {blankModel, MODEL_UPLOAD_STEPS, ModelUploadFlow} from './types';
import {ModelEditPermissionsFormData} from '../model/common/ModelEdit/ModelEditPermissons/ModelEditPermissionsForm';
import {useCurrentUser} from '../../../store/auth';
import {ModelDTO} from 'hemwb-api';
import DialogProgress from '../../core/dialog/DialogProgress';
import {useDispatch, useStore} from 'react-redux';
import {
  getModelSteward,
  isModelPublished,
  isStaticMetadataValid,
  modelCreate,
  modelPublish,
  useModelDetail,
  useModels,
} from '../../../store/model';
import {trackModelCreated, trackModelPublished} from '../../../tracking/tracking';
import {gridStateSet, modelListGridId} from '../../../store/grid';
import {getUrlRoute, Routes, useRoute} from '../../router/routes';
import {useHistory} from 'react-router';
import {useMounted} from '../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../store/message';
import {useVisited} from '../../../hooks/useVisited';
import SuspenseNull from '../../core/Suspense/SuspenseNull';
import {mapValuesToModel, transformModelToValues} from '../model/common/ModelEdit/ModelEditMetadata/utils';
import {modelLinksSetParent} from '../../../store/modelLinks';
import {ModelUploadContext} from './ModelUploadContext';
import {StepStatus} from '../../core/materialui/Stepper';
import {getStepStatus} from './utils';
import {validateModel} from '../../../store/model/validator';
import DialogNavigationIntercepted, {SetEnabledInterceptor} from '../../core/dialog/DialogNavigationIntercepted';
import {modelStewardSet} from '../../../store/modelSteward';
import {modelAccessUpdateList} from '../../../store/modelAccess';
import {modelGenerateName} from '../../../store/model/modelGenerateName';
import {userExpertiseLoadOptionsSet} from '../../../store/userExpertise';
import {hardCodedOptions} from '../model/common/ModelEdit/ModelEditMetadata/constants';
import {modelMetadataLoadOptions} from '../../../store/modelMetadata';

const ModelUpload: React.FC = () => {
  const {uuid} = useRoute<Routes.MODEL_UPLOAD>();
  const detail = useModelDetail(uuid);
  const models = useModels();

  const [activeStep, setActiveStep] = useState(MODEL_UPLOAD_STEPS.FILES);
  const [flow] = useState(!!uuid ? ModelUploadFlow.BASED_ON_METADATA : ModelUploadFlow.MANUAL_ENTRY);

  const [initialized, setInitialized] = useState(!uuid);
  const [showErrorsOnStep, setShowErrorsOnStep] = useState<number | undefined>();
  const wasVisited = useVisited(activeStep);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useMounted();
  const setEnabledInterceptorRef = useRef<SetEnabledInterceptor>();
  const scrollRef = useRef<HTMLDivElement>();

  const [submitText, setSubmitText] = useState('');
  const [files, setFilesState] = useState<ModelTreeNode[]>([]);
  const [model, setModelState] = useState<ModelDTO>(blankModel);
  const [suffixValidity, setSuffixValidity] = useState(false);
  const [permissions, setPermissions] = useState<
    Pick<
      ModelEditPermissionsFormData,
      'owners' | 'viewers' | 'displayViewers' | 'calibrationModelers' | 'scenarioModelers' | 'vestLead'
    >
  >({
    owners: [currentUser],
    calibrationModelers: [],
    scenarioModelers: [],
    viewers: [],
    displayViewers: [],
    vestLead: undefined,
  });

  const issues = useMemo(() => {
    if (models) {
      return validateModel(model, models, files, permissions.owners, permissions.vestLead);
    }
    return [];
  }, [model, models, files, permissions]);

  const {start: saveFiles} = useModelFileUploader(model, files);
  const store = useStore();

  const setModel = useCallback((newModel: ModelDTO) => {
    setModelState({...newModel, name: modelGenerateName(newModel)});
  }, []);

  const contextValue = useMemo(() => ({flow, issues, model, onModelChange: setModel}), [flow, issues, model, setModel]);

  const setFiles = useCallback((fs: ModelTreeNode[]) => {
    setFilesState(fs);
  }, []);

  useEffect(() => {
    if (files.length) {
      setEnabledInterceptorRef.current?.(true);
    }
  }, [files]);

  useEffect(() => {
    if (activeStep !== MODEL_UPLOAD_STEPS.FILES) {
      setEnabledInterceptorRef.current?.(true);
    }
  }, [activeStep]);

  useEffect(() => {
    if (detail) {
      const metadata = {...detail.metadata};
      delete metadata['version_log'];
      const newModel = mapValuesToModel(transformModelToValues({...detail, metadata}), blankModel);
      newModel.metadata.tree_root_id = detail.metadata.tree_root_id;
      if (isModelPublished(detail)) {
        newModel.metadata.tree_parent_id = detail.uuid;
      }
      setModel(newModel);
      setInitialized(true);
    }
  }, [detail, setModel]);

  useEffect(() => {
    return () => {
      dispatch(modelFileUploaderClear(''));
    };
  }, [dispatch]);

  const setActiveStepAndShowErrors = useCallback((index: number) => {
    setShowErrorsOnStep(index);
    setActiveStep(index);
  }, []);

  const save = useCallback(
    async (updatedModel: ModelDTO, publish: boolean) => {
      try {
        setSubmitText('Creating model entry');
        const createdModel = await modelCreate(dispatch, {
          metadata: updatedModel.metadata,
        });
        const {uuid} = createdModel;
        dispatch(gridStateSet(modelListGridId, {expandedRowId: uuid}));

        try {
          // @ts-ignore
          const expertiseLevel = hardCodedOptions['expertiseLevel'][3];
          await userExpertiseLoadOptionsSet(dispatch, uuid, {
            userId: permissions.owners[0].id,
            expertiseTypeLevel: expertiseLevel,
          });
        } catch (e) {
          dispatch(messageAdd('Failed to save expertise level', MessageTypes.WARNING));
        }
        const {tree_parent_id} = updatedModel.metadata;
        if (tree_parent_id) {
          setSubmitText('Saving lineage');
          await modelLinksSetParent(dispatch, uuid, tree_parent_id, true);
        }

        setSubmitText('Saving permissions');

        const modelSteward = getModelSteward(updatedModel);
        if (modelSteward) {
          await modelStewardSet(dispatch, uuid, modelSteward);
        }

        await modelAccessUpdateList(dispatch, uuid, {
          owners: permissions.owners,
          vestLead: permissions.vestLead,
          calibratorModeler: permissions.calibrationModelers,
          scenarioModeler: permissions.scenarioModelers,
          viewers: permissions.viewers,
          displayViewers: permissions.displayViewers,
        });

        setSubmitText('Saving files');
        try {
          await saveFiles({...updatedModel, uuid});
        } catch (e) {
          dispatch(messageAdd('Failed to save files', MessageTypes.WARNING));
        }
        // To Update the store with newly created fileTags
        modelMetadataLoadOptions(dispatch);
        //SET CHANGELOG FILE
        if (model.metadata.changelog_file) {
          //need to recalculate itemUuid
          const originalNode = files.find((n) => n.uuid === model.metadata.changelog_file);
          if (originalNode) {
            const newNode = modelFileSelector(store.getState()).uploader.nodes.find(
              (n) => n.path === originalNode.path,
            );
            if (newNode) {
              setSubmitText('Saving changelog file');
              await modelFileMarkChangelog(dispatch, uuid, newNode.uuid);
            }
          }
        }

        if (publish) {
          await modelPublish(dispatch, uuid);
          trackModelPublished(createdModel);
          dispatch(messageAdd('Model created and published', MessageTypes.SUCCESS));
        } else {
          trackModelCreated(createdModel);
          dispatch(messageAdd('Model created', MessageTypes.SUCCESS));
        }
        setEnabledInterceptorRef.current?.(false);
        history.push(getUrlRoute(Routes.MODEL_DETAIL, {uuid}));
      } catch (e) {
        mounted.current && setSubmitText('');
      }
    },
    [dispatch, history, mounted, model, permissions, saveFiles, files, store],
  );

  const getStepStatusCb = useCallback(
    (step: number): StepStatus => {
      return getStepStatus(step, wasVisited(step), issues, suffixValidity);
    },
    [wasVisited, issues, suffixValidity],
  );

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [activeStep, scrollRef]);

  if (!initialized || !models) {
    return (
      <DefaultPage>
        <SuspenseNull />
      </DefaultPage>
    );
  }

  return (
    <DefaultPage ref={scrollRef}>
      <DialogNavigationIntercepted isEnabled={false} setEnabledInterceptorRef={setEnabledInterceptorRef} />

      <ModelUploadContext.Provider value={contextValue}>
        <ModelUploadHeader activeStep={activeStep} setActiveStep={setActiveStep} getStepStatus={getStepStatusCb} />
        {activeStep === MODEL_UPLOAD_STEPS.FILES && (
          <StepFiles
            model={model}
            files={files}
            onChange={(newModel, newFiles) => {
              setModel(newModel);
              setFiles(newFiles);
            }}
            onSubmit={(newModel, newFiles) => {
              setModel(newModel);
              setFiles(newFiles);
              setActiveStep(MODEL_UPLOAD_STEPS.METADATA);
            }}
          />
        )}
        {activeStep === MODEL_UPLOAD_STEPS.METADATA && (
          <StepMetadata
            model={model}
            files={files}
            showErrors={showErrorsOnStep === MODEL_UPLOAD_STEPS.METADATA}
            onChange={setModel}
            onSubmit={(newModel) => {
              setModel(newModel);
              setActiveStep(MODEL_UPLOAD_STEPS.LINEAGE);
            }}
          />
        )}
        {activeStep === MODEL_UPLOAD_STEPS.LINEAGE && (
          <StepLineage
            model={model}
            files={files}
            onChange={setModel}
            onSubmit={(updatedModel: ModelDTO) => {
              setModel(updatedModel);
              setActiveStep(MODEL_UPLOAD_STEPS.PERMISSIONS);
            }}
          />
        )}
        {activeStep === MODEL_UPLOAD_STEPS.PERMISSIONS && (
          <StepPermissions
            permissions={{...permissions, model}}
            onChange={({model, ...newPermissions}) => {
              setPermissions(newPermissions);
              setModel(model);
            }}
            onSubmit={({model, ...newPermissions}) => {
              setPermissions(newPermissions);
              setModel(model);
              setActiveStep(MODEL_UPLOAD_STEPS.NAME_CONFIRMATION);
            }}
          />
        )}
        {activeStep === MODEL_UPLOAD_STEPS.NAME_CONFIRMATION && (
          <StepNameConfirmation
            model={model}
            setActiveStep={setActiveStepAndShowErrors}
            canPublish={isStaticMetadataValid(model) && files.length > 0}
            issues={issues}
            onChange={(updatedModel: ModelDTO, validSuffix: boolean) => {
              setSuffixValidity(validSuffix);
              setModel(updatedModel);
            }}
            onSubmit={(updatedModel: ModelDTO, publish: boolean) => {
              return save(updatedModel, publish);
            }}
          />
        )}
        <DialogProgress open={!!submitText} title="Setting up your new model entry" text={submitText} />
      </ModelUploadContext.Provider>
    </DefaultPage>
  );
};

export default ModelUpload;
