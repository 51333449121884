import React, {useEffect} from 'react';
import {RouteProps} from 'react-router-dom';
import {NavBar} from './common/NavBar';
import styles from './DefaultLayout.module.scss';
import SideBar from './common/SideBar';
import {List, ListItem, Tooltip} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomResetIcon from '@material-ui/icons/AspectRatio';
import SnackbarContainer from './common/SnackbarContainer';
import {Route} from 'react-router';
import clsx from 'clsx';
import {ThemeType} from '../../styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {useZoom} from '../../hooks/useZoom';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {useDispatch} from 'react-redux';
import {appSetZoom} from '../../store/app';

export type LayoutProps = RouteProps & {
  component: React.FC<any>;
  theme?: ThemeType;
  className?: string;
};

type LeftSidebarItemProps = {
  text: string;
  icon: JSX.Element;
  disabled?: boolean;
  onClick: () => void;
};

const LeftSidebarItem: React.FC<LeftSidebarItemProps> = ({text, icon, ...listItemProps}) => (
  <ListItem button disableGutters {...listItemProps}>
    <Tooltip title={text}>
      <ListItemIcon>{icon}</ListItemIcon>
    </Tooltip>
  </ListItem>
);

const DefaultLayout: React.FC<LayoutProps> = ({component, theme = 'whiteTheme', className, ...restProps}) => {
  const dispatch = useDispatch();
  const Component = component;
  const [storedZoom, setStoredZoom] = useLocalStorage('zoom', 1);
  const {zoom, zoomIn, zoomOut, zoomReset, isDefault, isMin, isMax} = useZoom(storedZoom, 1, 0.1, 0.7, 1.1);
  useEffect(() => {
    setStoredZoom(zoom);
    dispatch(appSetZoom(zoom));
  }, [zoom, setStoredZoom, dispatch]);

  return (
    <Route
      {...restProps}
      render={(matchProps) => (
        <div className={clsx('layoutWrapper', styles.wrapper, className)}>
          <header className={clsx('noPrint', styles.header)}>
            <NavBar />
          </header>

          <div className={clsx(styles.container, theme)}>
            <SideBar className={clsx('noPrint', styles.leftSideBar)} theme="darkTheme">
              <List dense component="nav">
                <LeftSidebarItem text="Zoom in" disabled={isMax} onClick={zoomIn} icon={<ZoomInIcon />} />
                <LeftSidebarItem text="Zoom out" disabled={isMin} onClick={zoomOut} icon={<ZoomOutIcon />} />
                <LeftSidebarItem text="Reset zoom" disabled={isDefault} onClick={zoomReset} icon={<ZoomResetIcon />} />
              </List>
            </SideBar>
            <div className={styles.container} style={{flexDirection: 'column', height: 'auto'}}>
              <div id="secondaryNav-portal"></div>
              <div className={styles.container}>
                <Component {...matchProps} />
              </div>
            </div>
          </div>
          <SnackbarContainer />
        </div>
      )}
    />
  );
};

export default DefaultLayout;
