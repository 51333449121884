import React from 'react';
import {isModelPublished, useModel} from '../../../../../store/model';
import styles from './ScenarioOutdateWarning.module.scss';

export const ScenarioOutdateWarning: React.FC<{modelUuid: string}> = ({modelUuid}) => {
  const model = useModel(modelUuid);
  return model && !isModelPublished(model) ? (
    <span className={styles.warning}>
      Model is not published. Any changes to model files after it is executed will mark this scenario as outdated.
    </span>
  ) : null;
};
