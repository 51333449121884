import React, {useCallback, useMemo, useState} from 'react';
import {Checkbox} from '@material-ui/core';
import {tid} from '../../../testUtils';
import FileTree from '../FileTree/FileTree';
import {TreeNode} from '../../../store/modelFile';
import {defaultCancelButtonProps} from '../Buttons/CancelButton';
import {defaultSubmitButtonProps} from '../Buttons/SubmitButton';
import {DialogGeneric} from '../dialog/DialogGeneric';

type FilePickerDialogProps = {
  title?: string;
  files: TreeNode[];
  selectedIds?: string | string[];
  open: boolean;
  onClose: () => void;
  onSubmit: (file: FilePickerDialogProps['selectedIds']) => void;
};

const FilePickerDialog: React.FC<FilePickerDialogProps> = ({title, files, selectedIds, open, onClose, onSubmit}) => {
  const [selection, setSelection] = useState(selectedIds);

  const toggleSelectedFile = useCallback(
    (node) => {
      if (Array.isArray(selection)) {
        if (selection.includes(node.uuid)) {
          setSelection(selection.filter((uuid) => uuid !== node.uuid));
        } else {
          setSelection([...selection, node.uuid]);
        }
      } else if (node.uuid === selection) {
        setSelection(undefined);
      } else {
        setSelection(node.uuid);
      }
    },
    [selection],
  );

  const renderButtons = useCallback(
    (node: TreeNode) => {
      if (node.folder) {
        return null;
      }
      const checked = Array.isArray(selection) ? selection.some((uuid) => uuid === node.uuid) : node.uuid === selection;

      return [<Checkbox key={node.uuid} color="primary" checked={checked} {...tid('checkbox-option', node.uuid)} />];
    },
    [selection],
  );

  return (
    <DialogGeneric
      open={open}
      buttons={[
        {
          ...defaultCancelButtonProps,
          onClick: () => onClose(),
          children: 'Close',
          ...tid('btn', 'cancel', 'filePicker'),
        },
        {
          ...defaultSubmitButtonProps,
          // endIcon: <DownloadIcon />,
          children: 'OK',
          onClick: () => onSubmit(selection),
          ...tid('btn', 'submit', 'filePicker'),
        },
      ]}
      title={title || 'Select file'}
      childrenAsText={false}
      maxWidth="sm"
      fullWidth
      PaperProps={tid('dialog-file-picker') as {}}>
      <FileTree files={files} renderButtons={renderButtons} onRowClick={(node) => toggleSelectedFile(node)} />
    </DialogGeneric>
  );
};

export const useFilePickerDialog = (
  onSubmit: (file: FilePickerDialogProps['selectedIds']) => void,
  files: TreeNode[],
  selectedIds?: FilePickerDialogProps['selectedIds'],
  title?: string,
) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const DialogComponent = useMemo(() => {
    return isOpen ? (
      <FilePickerDialog
        files={files}
        selectedIds={selectedIds}
        title={title}
        onSubmit={(selection) => {
          onSubmit(selection);
          close();
        }}
        onClose={close}
        open={isOpen}
      />
    ) : null;
  }, [close, isOpen, files, selectedIds, title, onSubmit]);

  return {
    open,
    DialogComponent,
  };
};
