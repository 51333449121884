import React from 'react';
import {Box32, CheckmarkOutline32, Queued32, Save32} from '@carbon/icons-react';
import ListItemBigButton from '../../../core/materialui/ListItem/ListItemBigButton';
import SideBar from '../../../layout/common/SideBar';
import {List} from '@material-ui/core';
import styles from './LeftSideBar.module.scss';
import {ScenarioGridFilter} from './types';

type LeftSideBarProps = {
  filterTab: ScenarioGridFilter;
  setFilterTab: (filterTab: ScenarioGridFilter) => void;
};

const LeftSideBar: React.FC<LeftSideBarProps> = ({filterTab, setFilterTab}) => {
  return (
    <SideBar position="left" className={styles.container}>
      <span className={styles.showOnly}>Show Only</span>
      <List component="nav">
        <ListItemBigButton
          text="All Instances"
          icon={<Box32 />}
          selectedIndicatorColor="#01CFB2"
          selected={filterTab === ScenarioGridFilter.ALL_INSTANCES}
          onClick={() => setFilterTab(ScenarioGridFilter.ALL_INSTANCES)}
        />
        <ListItemBigButton
          text="All My Instances"
          icon={<Queued32 />}
          selectedIndicatorColor="#01CFB2"
          selected={filterTab === ScenarioGridFilter.ALL_MY_INSTANCES}
          onClick={() => setFilterTab(ScenarioGridFilter.ALL_MY_INSTANCES)}
        />
        <ListItemBigButton
          text="Completed Scenarios"
          icon={<CheckmarkOutline32 />}
          selectedIndicatorColor="#01CFB2"
          selected={filterTab === ScenarioGridFilter.COMPLETED_SCENARIOS}
          onClick={() => setFilterTab(ScenarioGridFilter.COMPLETED_SCENARIOS)}
        />
        <ListItemBigButton
          text="Saved Analyses"
          icon={<Save32 />}
          selectedIndicatorColor="#01CFB2"
          selected={filterTab === ScenarioGridFilter.SAVED_ANALYSES}
          onClick={() => setFilterTab(ScenarioGridFilter.SAVED_ANALYSES)}
        />
      </List>
    </SideBar>
  );
};

export default LeftSideBar;
