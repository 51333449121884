import {ModelInputDTO, ModelInputType} from 'hemwb-api';

type ModelInputFormValues = {
  name: string;
  type: ModelInputType;
};

export const transformModelInputToValues = (i: ModelInputDTO): ModelInputFormValues => ({
  name: i.name,
  type: i.type,
});

export const mapValuesToModelInput = (v: ModelInputFormValues, i?: ModelInputDTO): ModelInputDTO => ({
  value: '{}',
  ...i,
  name: v.name,
  type: v.type,
});

export const getModelInputTypeLabel = (type: ModelInputType) => {
  if (type === ModelInputType.JSONTEMPLATE) {
    return 'Parameters Form Interface';
  }

  if (type === ModelInputType.JSON) {
    return 'JSON Editor Interface';
  }

  return '';
};
