import React from 'react';
import {ModelItemActionDTO, UserBaseDTO} from 'hemwb-api';
import {fullNameWestern} from '../../../store/user/utils';
import {ModelTreeNode} from '../../../store/modelFile';
import TableWithSortAndPagination, {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../TableWithSortAndPagination';
import {Order, useGridState} from '../../../store/grid';

export type MlmpHistory = ModelItemActionDTO & {file: ModelTreeNode; user?: UserBaseDTO};

type MlmpHistoryChartProps = {
  history: MlmpHistory[];
};

const columns: Column<MlmpHistory>[] = [
  {
    id: 'fileName',
    label: 'File Name',
    sortable: true,
    value: (commit) => commit.file.path,
    display: (commit) => commit.file.name,
  },
  {
    id: 'action',
    label: 'Action',
    value: (commit) => commit.action,
  },
  {
    id: 'author',
    label: 'Author',
    sortable: true,
    value: (commit) => commit.isid,
    display: (commit) => (commit.user ? fullNameWestern(commit.user) : commit.isid),
  },

  {
    id: 'timestamp',
    label: 'Timestamp',
    sortable: true,
    value: (commit) => commit.timestamp,
    display: (commit) => commit.timestamp?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
  },
];

const MlmpHistoryChart: React.FC<MlmpHistoryChartProps> = ({history}) => {
  const gridState = useGridState({orderBy: 'timestamp', order: Order.DESC});

  return (
    <>
      <div className="headlineBlue">History</div>
      <TableWithSortAndPagination<MlmpHistory>
        columns={columns}
        rows={history}
        getRowId={(commit) => `${commit.file.path}${commit.versionNumber}`}
        gridState={gridState}
      />
    </>
  );
};

export default MlmpHistoryChart;
