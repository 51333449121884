import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import GridItem from '../../../../core/Form/GridItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ModelFileUploaded} from '../../../../../store/modelFile';
import TextField from '@material-ui/core/TextField';
import {hardCodedOptions} from './constants';
import {ExternalUserWithModel} from './types';

type RoleSelectionBoxProps = {
  selectedModelsCount: number;
  onClick?: (modelRole: string) => void;
  onCancel: () => void;
  handleFileTag?: (node: ModelFileUploaded, fileTag: string) => void;
  model?: ExternalUserWithModel;
  setUnSelectAll?: any;
  unSelectAll?: boolean;
  isMRModelPresent: boolean;
};

const RoleSelectionBox: React.FC<Partial<RoleSelectionBoxProps>> = (props) => {
  const {selectedModelsCount, onClick, onCancel, model, setUnSelectAll, isMRModelPresent} = props;
  const [selectedOptions, setSelectedOptions] = React.useState(model?.role);
  const [isDialogOpen, setDialogOpen] = useState(true);

  useEffect(() => {
    setUnSelectAll(false);
  }, [setUnSelectAll]);

  const handleSaveFileType = () => {
    if (onClick && selectedOptions) {
      onClick(selectedOptions);
    }
    setDialogOpen(false);
  };
  const handleOnCancel = () => {
    setUnSelectAll(true);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      {isDialogOpen && (
        <div style={{border: '1px solid #F7F7F7', backgroundColor: '#F7F7F7', padding: '4px', display: 'inline-block'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              style={{fontSize: '20px', padding: '5px 5px', borderRadius: '20px', maxWidth: '180px'}}
              onClick={handleOnCancel}>
              {selectedModelsCount} selected files
              <CancelIcon />
            </Button>
            <div style={{marginLeft: '24px', marginRight: '24px'}}>
              <h4>Select the Model Role</h4>
              <span>Press down to select a suggestion, or being typing to the find role</span>
              <GridItem>
                <Autocomplete
                  disableClearable
                  data-testid={'role-' + model?.role}
                  style={{width: 300}}
                  value={selectedOptions || null}
                  onChange={(event: any, newValue: string | null) => {
                    if (newValue) {
                      setSelectedOptions(newValue);
                    }
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  renderOption={(option) => option}
                  options={
                    isMRModelPresent ? hardCodedOptions['modelRoles'].slice(0, 2) : hardCodedOptions['modelRoles']
                  }
                  renderInput={(params) => (
                    <TextField
                      name="role"
                      style={{width: 300}}
                      {...params}
                      inputProps={{...params.inputProps, maxLength: 25}}
                    />
                  )}
                />
              </GridItem>
            </div>
            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{fontSize: '20px', padding: '5px 5px', maxWidth: '180px'}}
              disabled={!selectedOptions}
              onClick={handleSaveFileType}>
              Assign Model Role
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RoleSelectionBox;
