import {configuration} from '../getBackendConfiguration';
import {ExpertiseUsersApi} from 'hemwb-api/dist/apis/ExpertiseUsersApi';
import {ModelUserExpertiseDTO} from 'hemwb-api';

const expertiseUsersApi = new ExpertiseUsersApi(configuration);
export const requestList = (modelUuid: string, userId: string) =>
  expertiseUsersApi.getUserExpertiseLevel({
    modelUuid,
    userId,
  });
export const requestUserExpertiseSetLevel = (modelUuid: string, modelUserExpertiseDTO: ModelUserExpertiseDTO) =>
  expertiseUsersApi.setUserExpertise({
    modelUuid,
    modelUserExpertiseDTO,
  });
