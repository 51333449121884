import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {UserModelAccess, UserType} from 'hemwb-api';
import {ModelStatusCreating} from '../../../store/model';

export const MODEL_UPLOAD_STEPS = {
  FILES: 0,
  METADATA: 1,
  LINEAGE: 2,
  PERMISSIONS: 3,
  NAME_CONFIRMATION: 4,
};

export const modelUploadSteps = ['Files', 'Metadata', 'Lineage', 'Permissions', 'Name Confirmation'];

export const blankModel: ModelDTO = {
  uuid: '',
  name: 'Creating new model',
  createdOn: new Date(),
  createdBy: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    active: true,
    country: '',
    department: '',
    divisionName: '',
    userType: UserType.INTERNALUSER,
  },
  metadata: {
    status: ModelStatusCreating,
  },
  userAccess: UserModelAccess.OWNER,
};

export enum ModelUploadFlow {
  BASED_ON_METADATA,
  MANUAL_ENTRY,
}
