import React, {useCallback, useState} from 'react';
import {useModelFile} from '../../../../../store/modelFile';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {executionSetupUpdate, typeToSection} from '../../../../../store/executionSetup';
import {ExecutionSetupUpdateDTO, ModelInputDetailDTO, ModelInputDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {SubModelActionProps} from '../../../actionMenuTypes';
import SubModelForm from '../SubModelAdd/SubModelForm';
import {useMounted} from '../../../../../hooks/useMounted';
import {trackSubModelUpdated} from '../../../../../tracking/tracking';
import {modelInputsUpdate, useModelInput} from '../../../../../store/modelInputs';
import {useDialogGenericPromise} from '../../../../core/dialog/useDialogGenericPromise';
import {defaultCancelButtonProps} from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {defaultSubmitButtonProps} from '../../../../core/Buttons/SubmitButton';
import {scenarioOutdate} from '../../../../../store/scenario';

const SubModelEdit: React.FC<SubModelActionProps> = ({model, subModel, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const {files} = useModelFile(model);
  const input = useModelInput(subModel.id);
  const [submitting, setSubmitting] = useState(false);

  const {DialogComponent, openDialog} = useDialogGenericPromise({
    title: 'Outdating instances',
    children: 'The sub-model has been changed. Would you like to mark its previous instances as outdated?',
    buttons: [
      {
        ...defaultCancelButtonProps,
        children: 'Proceed without outdating',
        ...tid('btn', 'outdating-instances', 'no'),
      },
      {
        ...defaultSubmitButtonProps,
        children: 'Outdate instances',
        ...tid('btn', 'outdating-instances', 'yes'),
      },
    ],
  });

  const handleSubmit = useCallback(
    async (updatedSubModel: ExecutionSetupUpdateDTO, inputDefinition: ModelInputDTO) => {
      if (!updatedSubModel || !subModel.id) {
        return;
      }

      const buttonIndex = await openDialog();

      setSubmitting(true);
      try {
        if (inputDefinition.value) {
          await modelInputsUpdate(dispatch, subModel.id, (inputDefinition as ModelInputDetailDTO).id, inputDefinition);
        }

        const updatedSubModelDetail = await executionSetupUpdate(dispatch, subModel.id, updatedSubModel);

        if (buttonIndex === 1) {
          await scenarioOutdate(dispatch, [subModel.id]);
        }
        trackSubModelUpdated(model, updatedSubModelDetail);
        dispatch(messageAdd('Sub-model has been updated', MessageTypes.SUCCESS));
        mounted.current && setSubmitting(false);
        onSuccess?.();
      } catch (e) {
        setSubmitting(false);
      }
    },
    [dispatch, mounted, model, subModel, onSuccess, openDialog],
  );

  return (
    <SuspenseNull>
      {files && (
        <>
          <h2>Edit Sub-model</h2>

          <SubModelForm
            model={model}
            subModel={subModel}
            input={input}
            section={typeToSection(subModel.type)}
            files={files}
            submitting={submitting}
            onSubmit={handleSubmit}
            onCancel={onCancel}
          />

          {DialogComponent}
        </>
      )}
    </SuspenseNull>
  );
};

export default SubModelEdit;
