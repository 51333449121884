import React from 'react';
import {ExecutionSetupDTO} from 'hemwb-api';
import {CircularProgress} from '@material-ui/core';

type VisualizationInProgressProps = {
  scenarioInProgress: boolean;
  fetchingResults?: boolean;
  displaySubModel?: ExecutionSetupDTO;
};

const VisualizationInProgress: React.FC<VisualizationInProgressProps> = ({
  scenarioInProgress,
  fetchingResults,
  displaySubModel,
}) => {
  if (scenarioInProgress) {
    return (
      <>
        <p>
          Your visualization is being processed. This operation may take{' '}
          {displaySubModel ? Math.round(displaySubModel.timeoutSeconds / 60) : 'few'} minutes.
        </p>
        <CircularProgress />
      </>
    );
  }

  if (fetchingResults) {
    return (
      <>
        <p>Downloading results. This operation may take few seconds.</p>
        <CircularProgress />
      </>
    );
  }

  return null;
};

export default VisualizationInProgress;
