import {useReduxLoader, useReduxManualLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {RootState} from '../rootReducer';
import {scenarioSelector} from './selectors';
import {scenarioLoadDetail, scenarioLoadError} from './actions';
import {Dispatch} from 'redux';
import {ScenarioDTO, ScenarioStatus} from 'hemwb-api';

const detailSelectorBuilder = (params: [number]) => (state: RootState) =>
  scenarioSelector(state).detail[params[0] || -1] || null;

const scenarioLoadDetailIgnoreNull = (dispatch: Dispatch, scenarioId: number | null | undefined) => {
  return scenarioId ? scenarioLoadDetail(dispatch, Number(scenarioId)) : Promise.resolve();
};
scenarioLoadDetailIgnoreNull.clear = scenarioLoadDetail.clear;

export const useScenarioDetail = (scenarioId: number | string | null | undefined) => {
  return useReduxLoader(detailSelectorBuilder, scenarioLoadDetailIgnoreNull, Number(scenarioId));
};

export const useScenarioDetailLoader = (scenarioId: number | string) => {
  return useReduxManualLoader(detailSelectorBuilder, scenarioLoadDetail, Number(scenarioId));
};

export const useScenarioDetailLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(detailSelectorBuilder, scenarioLoadDetail);
};

const errorSelectorBuilder = (params: [number]) => (state: RootState) =>
  scenarioSelector(state).error[params[0] || -1] || null;

const scenarioLoadErrorIgnoreNull = (dispatch: Dispatch, scenarioId: number | null) => {
  return scenarioId ? scenarioLoadError(dispatch, scenarioId) : Promise.resolve();
};
scenarioLoadErrorIgnoreNull.clear = scenarioLoadError.clear;

export const useScenarioError = (scenario?: ScenarioDTO | null) => {
  const scenarioId = scenario?.status === ScenarioStatus.FAILED ? scenario.id : null;
  return useReduxLoader(errorSelectorBuilder, scenarioLoadErrorIgnoreNull, scenarioId);
};

// type CreateType = {
//   executionSetupId: number,
//   scenarioUpdateDTO: ScenarioUpdateDTO,
// }
// type UpdateType = {
//   scenarioId: number
//   scenarioUpdateDTO?: ScenarioUpdateDTO,
// }
// type ScenarioPersistInputType = {
//
// }
//
// export const useScenarioPersist = () => {
//   const dispatch = useDispatch()
//
//   return useCallback( async(scenarioData: CreateType|UpdateType) => {
//
//   }, [])
// }
