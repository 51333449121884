import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import {Box, Button, IconButton, Menu, MenuItem} from '@material-ui/core';
import {tid} from '../../../testUtils';
import styles from './TableFilterLabel.module.scss';
import {
  ArrowDown16,
  ArrowUp16,
  CheckmarkOutline20,
  Close20,
  CaretDown20,
  Filter20,
  SortAscending20,
  SortDescending20,
} from '@carbon/icons-react';
import {TableColumnFilterProps} from './types';
import {Order} from '../../../store/grid';
import {AllFilters, MultipleCheckboxFilter} from '../filter';
import {useForm} from '../Form';
import {FormControl, FormGroup} from 'react-reactive-form';

function TableFilterLabel<R>(props: TableColumnFilterProps<R>) {
  const {column, onSetOrder, onSetOrderBy, order, orderBy, filterStrategy} = props;
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<Element | null>(null);

  const [filterState, setFilterState] = useState<Array<R | AllFilters>>([]);
  const [sortState, setSortState] = useState<Order | null>(null);

  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleFilterMenuToggleClick = (event: MouseEvent) => {
    if (filterMenuAnchor) {
      return;
    }
    if (orderBy === null || orderBy !== column.id) {
      setSortState(null);
    } else {
      setSortState(order);
    }
    // @ts-ignore
    setFilterState(filterStrategy.filter);
    setSelectAll(filterStrategy.filter.includes('all'));
    setFilterMenuAnchor(event.currentTarget);
  };

  const handleSortAsc = () => {
    if (sortState !== Order.ASC) setSortState(Order.ASC);
  };
  const handleSortDesc = () => {
    if (sortState !== Order.DESC) setSortState(Order.DESC);
  };
  const handleCancelSort = () => setSortState(null);
  const handleCancelFilter = () => {
    setFilterState([]);
    form.setValue({control: []});
  };

  const handleCancel = () => setFilterMenuAnchor(null);

  const handleApply = () => {
    if (sortState !== null) {
      onSetOrder(sortState);
      onSetOrderBy(column.id);
    }
    setFilterMenuAnchor(null);
    setAppliedFilter(filterState.length > 0);
    filterStrategy.onSetFilter(filterState);
  };

  const form = useForm(
    new FormGroup({
      control: new FormControl([]),
    }),
  );

  const handleFilterChange = useCallback(
    (newState: {[k: string]: Array<R | AllFilters>}) => {
      const hadAllChecked = filterState.includes('all');
      const hasAllChecked = newState.control.includes('all');

      if (!selectAll && !hadAllChecked && hasAllChecked) {
        const values = (filterStrategy.options as (R | AllFilters)[]).concat('all');
        setSelectAll(true);
        setFilterState(values);
      } else if (selectAll && hadAllChecked && !hasAllChecked) {
        setSelectAll(false);
        setFilterState([]);
      } else {
        setFilterState(newState.control);
      }
    },
    [filterState, filterStrategy.options, selectAll],
  );

  useEffect(() => {
    form.setValue({control: filterState}, {emitEvent: true});
    form.valueChanges.subscribe(handleFilterChange);
    return () => form.valueChanges.unsubscribe(handleFilterChange);
  }, [form, filterState, handleFilterChange]);

  const renderToggleButtonIcon = () => {
    if (orderBy === null || orderBy !== column.id) {
      return appliedFilter ? <Filter20 /> : <CaretDown20 />;
    }
    switch (order) {
      case Order.ASC:
        return appliedFilter ? (
          <div className={styles.doubleIconWrapper}>
            &nbsp;&nbsp;
            <Filter20 className={styles.doubleIconLeft} />
            <ArrowUp16 className={styles.doubleIconRight} />
          </div>
        ) : (
          <SortAscending20 />
        );
      case Order.DESC:
        return appliedFilter ? (
          <div className={styles.doubleIconWrapper}>
            &nbsp;&nbsp;
            <Filter20 className={styles.doubleIconLeft} />
            <ArrowDown16 className={styles.doubleIconRight} />
          </div>
        ) : (
          <SortDescending20 />
        );
    }
  };

  return (
    <>
      <Button
        {...tid('filter-menu-button')}
        aria-controls="filter-menu"
        aria-haspopup="true"
        className={styles.headerLabel}
        onClick={handleFilterMenuToggleClick}>
        {column.label}&nbsp;&nbsp;{renderToggleButtonIcon()}
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={filterMenuAnchor}
        keepMounted
        open={!!filterMenuAnchor}
        onClose={handleCancel}
        anchorOrigin={{horizontal: 'right', vertical: 'center'}}>
        <MenuItem
          divider
          onClick={handleSortAsc}
          selected={sortState === Order.ASC}
          disableRipple={sortState === Order.ASC}>
          <Box style={{flex: 1}}>
            <SortAscending20 />
            &nbsp;&nbsp;&nbsp;&nbsp;Sorting A to Z
          </Box>

          {sortState === Order.ASC && (
            <IconButton size="small" onClick={handleCancelSort}>
              <Close20 />
            </IconButton>
          )}
        </MenuItem>

        <MenuItem
          divider
          onClick={handleSortDesc}
          selected={sortState === Order.DESC}
          disableRipple={sortState === Order.DESC}>
          <Box style={{flex: 1}}>
            <SortDescending20 />
            &nbsp;&nbsp;&nbsp;&nbsp;Sorting Z to A
          </Box>

          {sortState === Order.DESC && (
            <IconButton size="small" onClick={handleCancelSort}>
              <Close20 />
            </IconButton>
          )}
        </MenuItem>

        <MenuItem
          style={filterState.length === 0 ? {pointerEvents: 'none'} : undefined}
          selected={filterState.length > 0}
          disableRipple>
          <Box style={{flex: 1}}>
            <Filter20 />
            &nbsp;&nbsp;&nbsp;&nbsp; Filters
          </Box>

          {filterState.length > 0 && (
            <IconButton size="small" onClick={handleCancelFilter}>
              <Close20 />
            </IconButton>
          )}
        </MenuItem>

        <Box className={styles.filterCheckboxes}>
          <MultipleCheckboxFilter
            label={column.label}
            optionLabel={filterStrategy.optionLabel}
            options={filterStrategy.options}
            control={form.get('control')}
            noCollapse
            allSelectable
            allSelected={filterState.includes('all')}
            overrideShowSearch
          />
        </Box>
        <Box className={styles.buttonBox}>
          <Button className={styles.button} color="primary" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className={styles.button} color="primary" variant="contained" onClick={handleApply}>
            Apply&nbsp;&nbsp;
            <CheckmarkOutline20 />{' '}
          </Button>
        </Box>
      </Menu>
    </>
  );
}

export default TableFilterLabel;
