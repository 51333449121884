import React from 'react';
import {ModelDTO} from 'hemwb-api';
import ModelVersionControlLineage from './ModelVersionControlLineage';
import {useSelector} from 'react-redux';
import {
  versionControlDiffSelectionSelector,
  VersionControlPage,
  versionControlPageSelector,
} from '../../../../../store/versionControl';
import ModelVersionControlDiff from './ModelVersionControlDiff';
import ModelVersionControlHistory from './ModelVersionControlHistory';

type ModelVersionControlProps = {
  model: ModelDTO;
};

const ModelVersionControl: React.FC<ModelVersionControlProps> = ({model}) => {
  const page = useSelector(versionControlPageSelector);
  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);

  if (page === VersionControlPage.DIFF && versionDiffSelection.length === 2) {
    return <ModelVersionControlDiff />;
  }

  if (page === VersionControlPage.HISTORY && versionDiffSelection.length > 0) {
    return <ModelVersionControlHistory />;
  }

  return <ModelVersionControlLineage model={model} />;
};

export default ModelVersionControl;
