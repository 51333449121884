import React from 'react';
import CardNode, {CardNodeColumn, CardNodeSubtitle} from '../../../core/carbonui/CardNode';
import {Portfolio20, Unlink20} from '@carbon/icons-react';
import {ExecutionSetupDTO, ScenarioGroupDetailDTO} from 'hemwb-api';
import {getSubModelColorHashCode} from './constants';

type SubModelFolderCardProps = {
  subModel: ExecutionSetupDTO;
  group: ScenarioGroupDetailDTO;
  isSelected: boolean;
  onClick: () => void;
  onClickUnlink: (event: React.MouseEvent<any>) => void;
};

const SubModelFolderCard = React.forwardRef<HTMLDivElement, SubModelFolderCardProps>(
  ({subModel, group, isSelected, onClick, onClickUnlink}, ref) => {
    return (
      <div className="folderNode treeNode" ref={ref} onClick={onClick}>
        <CardNode color={getSubModelColorHashCode(subModel)} theme="whiteTheme" fullBorder={isSelected}>
          <CardNodeColumn>
            <Portfolio20 />
          </CardNodeColumn>
          <CardNodeColumn>
            <CardNodeSubtitle>{group.name}</CardNodeSubtitle>
          </CardNodeColumn>
          <CardNodeColumn farsideColumn>
            <div style={{cursor: 'pointer'}} title="Delete Group">
              <Unlink20 onClick={onClickUnlink} />
            </div>
          </CardNodeColumn>
        </CardNode>
      </div>
    );
  },
);

export default SubModelFolderCard;

SubModelFolderCard.displayName = 'SubModelFolderCard';
