import React from 'react';
import ModelUpload from './ModelUpload';
import {Routes, useRoute} from '../../router/routes';

const ModelUploadWrapper: React.FC = () => {
  const {uuid} = useRoute<Routes.MODEL_UPLOAD>();
  return <ModelUpload key={uuid || ''} />;
};

export default ModelUploadWrapper;
