/* eslint-disable @typescript-eslint/camelcase */
import {
  ModelAction,
  ModelActions,
  ModelStatusDeleted,
  ModelStatusPublished,
  ModelStatusRetired,
  StoredModel,
} from './types';
import {buildInitialAsyncState, buildInitialAsyncStateReduced, reduceAsyncActionReduced} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionStateReduced} from '../asyncUtilsTypes';
import {
  ModelDetailDTO,
  ModelDTO,
  ModelMetadataOptionsDTO,
  ModelMetadataOptionsDTOOptions,
  ModelNodeDTO,
} from 'hemwb-api';
import {ModelMetadataAction, ModelMetadataActions, modelMetadataOptionProperties} from '../modelMetadata/types';
import {ModelFileAction, ModelFileActions} from '../modelFile/types';
import {ModelLinksAction, ModelLinksActions} from '../modelLinks/types';
import nanoid from 'nanoid';
import {buildFlatHierarchy} from '../modelLinks/utils';
import {modifyModelMetadata} from './utils';
import {ModelStewardAction, ModelStewardActions} from '../modelSteward';
import {fullName} from '../user/utils';
import {ModelAccessAction, ModelAccessActions} from '../modelAccess/types';

type FetchedDetail = AsyncActionStateReduced<[string]>;
type StoredModelWithTimestamp = StoredModel & {timestamp: number};

export const modelInitialState = {
  detail: {} as {[modelUuid: string]: FetchedDetail},
  list: buildInitialAsyncStateReduced(),
  models: {} as {[modelUuid: string]: StoredModelWithTimestamp},
  eligibleList: buildInitialAsyncStateReduced(),
  eligibleModels: {} as {[modelUuid: string]: StoredModelWithTimestamp},
  externalUserModelsList: buildInitialAsyncStateReduced(),
  externalUserModels: {} as {[modelUuid: string]: StoredModelWithTimestamp},
  metadataOptions: buildInitialAsyncState<ModelMetadataOptionsDTO>(),
};

export type ModelState = typeof modelInitialState;

const updateModelInState = (state: ModelState, uuid: string, parts: Partial<ModelDetailDTO>): ModelState => {
  const model = state.models[uuid];

  if (!model) {
    throw new Error("Model is not in store and can't be updated");
  }

  return {
    ...state,
    models: {
      ...state.models,
      [uuid]: {
        ...model,
        ...parts,
        metadata: {
          ...model.metadata,
          ...parts.metadata,
        },
      },
    },
  };
};

const insertModelInState = (state: ModelState, model: ModelDTO): ModelState => {
  return {
    ...state,
    models: {
      ...state.models,
      [model.uuid]: {...model, timestamp: Date.now()},
    },
  };
};

const updateOrInsertMultipleModels = (state: ModelState, models: StoredModel[]): ModelState['models'] => {
  return {
    ...state.models,
    ...models.reduce((acc, model) => {
      const {uuid} = model;
      acc[uuid] = {
        ...state.models[uuid],
        ...model,
        timestamp: Date.now(),
        metadata: {...state.models[uuid]?.metadata, ...model.metadata},
      };
      return acc;
    }, ({} as any) as ModelState['models']),
  };
};

const updateOrInsertExternalUserMultipleModels = (
  state: ModelState,
  externalUserModels: StoredModel[],
): ModelState['externalUserModels'] => {
  return {
    ...state.externalUserModels,
    ...externalUserModels.reduce((acc, model) => {
      const {uuid} = model;
      acc[uuid] = {
        ...state.externalUserModels[uuid],
        ...model,
        timestamp: Date.now(),
        metadata: {...state.externalUserModels[uuid]?.metadata, ...model.metadata},
      };
      return acc;
    }, ({} as any) as ModelState['externalUserModels']),
  };
};

const updateOrInsertEligibleMultipleModels = (
  state: ModelState,
  eligibleModels: StoredModel[],
): ModelState['eligibleModels'] => {
  return {
    ...state.eligibleModels,
    ...eligibleModels.reduce((acc, model) => {
      const {uuid} = model;
      acc[uuid] = {
        ...state.eligibleModels[uuid],
        ...model,
        timestamp: Date.now(),
        metadata: {...state.eligibleModels[uuid]?.metadata, ...model.metadata},
      };
      return acc;
    }, ({} as any) as ModelState['eligibleModels']),
  };
};

export const modelReducer = (
  state = modelInitialState,
  action:
    | ModelAction
    | ModelFileAction
    | ModelMetadataAction
    | ModelAccessAction
    | ModelStewardAction
    | ModelLinksAction,
): ModelState => {
  switch (action.type) {
    case ModelActions.FETCH_LIST: {
      return {
        ...state,
        list: reduceAsyncActionReduced(action),
        models:
          action.phase !== AsyncActionPhase.SUCCESS ? state.models : updateOrInsertMultipleModels(state, action.value),
        externalUserModelsList: reduceAsyncActionReduced(action),
        externalUserModels:
          action.phase !== AsyncActionPhase.SUCCESS
            ? state.models
            : updateOrInsertExternalUserMultipleModels(state, action.value),
      };
    }
    case ModelActions.FETCH_ELIGIBLE_LIST: {
      return {
        ...state,
        eligibleList: reduceAsyncActionReduced(action),
        eligibleModels:
          action.phase !== AsyncActionPhase.SUCCESS
            ? state.eligibleModels
            : updateOrInsertEligibleMultipleModels(state, action.value),
      };
    }

    case ModelActions.FETCH_DETAIL: {
      const [modelUuid] = action.params;
      return {
        ...(action.phase === AsyncActionPhase.SUCCESS ? insertModelInState(state, action.value) : state),
        detail: {
          ...state.detail,
          [modelUuid]: reduceAsyncActionReduced(action),
        },
      };
    }

    case ModelActions.CREATE:
    case ModelActions.CLONE:
    case ModelActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return insertModelInState(state, action.value);
      }

      return state;
    }

    case ModelActions.DELETE_DRAFT: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid] = action.params;
        return updateModelInState(state, uuid, {
          name: undefined,
          metadata: {status: ModelStatusDeleted},
        });
      }

      return state;
    }

    case ModelActions.PUBLISH:
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid] = action.params;
        return updateModelInState(state, uuid, {
          metadata: {status: ModelStatusPublished},
        });
      }

      return state;

    case ModelActions.RETIRE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid] = action.params;
        return updateModelInState(state, uuid, {metadata: {status: ModelStatusRetired}});
      }

      return state;
    }

    case ModelFileActions.MERGE_UPLOADER: {
      const {modelUuid, hasFiles} = action;
      return updateModelInState(state, modelUuid, {hasFiles});
    }

    case ModelFileActions.REQUEST_MARK_CHANGELOG: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid, itemUuid] = action.params;
        return updateModelInState(state, uuid, {metadata: {changelog_file: itemUuid}});
      }

      return state;
    }

    case ModelFileActions.REQUEST_UNMARK_CHANGELOG: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid] = action.params;
        return updateModelInState(state, uuid, {metadata: {changelog_file: ''}});
      }

      return state;
    }

    case ModelAccessActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid, access] = action.params;
        const {owners} = access;

        return updateModelInState(state, uuid, {owners});
      }

      return state;
    }

    case ModelStewardActions.SET: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [uuid, modelSteward] = action.params;
        return updateModelInState(state, uuid, {
          metadata: {
            isid_of_model_steward: modelSteward.id,
            name_of_model_steward: fullName(modelSteward),
            email_for_model_steward: modelSteward.email,
          },
        });
      }

      return state;
    }

    case ModelLinksActions.FETCH_HIERARCHY: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const flatModels = flatten(action.value);
        const modelDTO = flatModels.find((m: any) => m.name) as ModelDTO;
        if (modelDTO && modelDTO.metadata.tree_root_id) {
          flatModels.forEach((m) => {
            m.metadata.tree_root_id = modelDTO.metadata.tree_root_id;
          });
        }

        return {
          ...state,
          models: updateOrInsertMultipleModels(state, flatModels),
        };
      }
      return state;
    }

    case ModelLinksActions.SET_PARENT: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        //set new parent_id and set new tree_root_id for self and all descendants
        const [uuid, parentUuid] = action.params;
        const model = state.models[uuid];
        const parentModel = parentUuid ? state.models[parentUuid] : null;
        if (typeof model !== 'undefined' && typeof parentModel !== 'undefined') {
          const tree_root_id = parentModel ? parentModel.metadata.tree_root_id : model.uuid;
          return {
            ...state,
            models: {
              ...state.models,
              ...buildFlatHierarchy(model, Object.values(state.models)).reduce((acc, node) => {
                const modelUuid = node.model.uuid;
                acc[modelUuid] = modifyModelMetadata(state.models[modelUuid], {
                  tree_root_id,
                  ...(modelUuid === uuid && {tree_parent_id: parentUuid || ''}),
                });

                return acc;
              }, ({} as any) as ModelState['models']),
            },
          };
        }
      }
      return state;
    }

    case ModelMetadataActions.REQUEST_OPTION_UPDATE:
    case ModelMetadataActions.REQUEST_OPTION_DELETE: {
      const [property, value] = action.params as [keyof ModelMetadataOptionsDTOOptions, string];
      if (
        action.phase === AsyncActionPhase.SUCCESS &&
        Object.values(state.models).some((model) => model.metadata[modelMetadataOptionProperties[property]] === value)
      ) {
        return modelInitialState;
      }
      return state;
    }

    default:
      return state;
  }
};

const flatten = (node: ModelNodeDTO, parentUuid = '', flatModels: StoredModel[] = []): StoredModel[] => {
  let model: StoredModel;

  if (node.node) {
    model = node.node;
  } else {
    let uuid: string;
    const notRestrictedChildNode = node.children?.find((n) => n.node);
    if (notRestrictedChildNode) {
      uuid = notRestrictedChildNode.node!.metadata.tree_parent_id;
    } else {
      uuid = nanoid();
    }

    model = {
      uuid,
      metadata: {
        tree_parent_id: parentUuid,
      },
    };
  }

  flatModels.push(model);
  if (node.children) {
    node.children.forEach((n) => flatten(n, model.uuid, flatModels));
  }

  return flatModels;
};
