import React from 'react';
import {FormControl} from 'react-reactive-form';
import {InputAttributes} from '../../InputDefinition/types';
import InputHeader from '../inputs/InputHeader';

type StaticInputValuePrimitiveProps = {
  control: FormControl;
  definition: InputAttributes;
};

const StaticInputValuePrimitive: React.FC<StaticInputValuePrimitiveProps> = ({control, definition}) => {
  return (
    <>
      <InputHeader definition={definition} />
      {control.value}
    </>
  );
};

export default StaticInputValuePrimitive;
