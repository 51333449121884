import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {SubModelActionProps} from '../../../actionMenuTypes';
import useModelInputExport from './useModelInputExport';
import {canExportInputDefinition} from '../../../../../permissions/usePermissions';
import {Upload16} from '@carbon/icons-react';

const ModelInputEditActionMenuButton: React.FC<Partial<SubModelActionProps>> = (props) => {
  const download = useModelInputExport(props);

  return (
    <SideBarButtonItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.INPUT_DEFINITION_EXPORT)}
      permission={canExportInputDefinition(props.model, props.subModel)}
      onClick={download}
      icon={<Upload16 />}
      text="Export"
    />
  );
};

export default ModelInputEditActionMenuButton;
