import React, {CSSProperties, forwardRef} from 'react';
import styles from './DefaultPage.module.scss';
import clsx from 'clsx';

const DefaultPage: React.FC<React.PropsWithChildren<{style?: CSSProperties; className?: string}>> = (props, ref) => (
  <div className={clsx(styles.sectionWrapper, 'defaultPage', props.className)} style={props.style}>
    <section className={styles.section} ref={ref}>
      {props.children}
    </section>
  </div>
);

export default forwardRef(DefaultPage);
