import React, {useCallback, useEffect, useRef, useState} from 'react';
import SubModelActionMenu from '../../subModel/common/SubModelActionMenu/SubModelActionMenu';
import DefaultPage from '../../../layout/common/DefaultPage';
import {JSONEditorInstance} from '../../../core/JSONEditor/types';
import JSONEditor from '../../../core/JSONEditor/JSONEditor';
import {SubModelActionProps} from '../../actionMenuTypes';
import CancelButton from '../../../core/Buttons/CancelButton';
import ModelTextOverviewTile from '../../model/common/ModelTextOverview/ModelTextOverviewTile';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import {Path} from '@carbon/pictograms-react';
import {Grid} from '@material-ui/core';
import FileInputButton from '../../../core/Buttons/FileInputButton';
import {useMounted} from '../../../../hooks/useMounted';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {typeToSection} from '../../../../store/executionSetup';
import {ModelInputDetailDTO} from 'hemwb-api';

export type ModelInputJSONEditorSubmitType = {
  input: ModelInputDetailDTO;
  onSubmit: (defaultValue: string) => void;
  submitting: boolean;
};

const ModelInputJSONEditor: React.FC<SubModelActionProps & ModelInputJSONEditorSubmitType> = ({
  model,
  subModel,
  input,
  onSubmit,
  submitting,
  onCancel,
}) => {
  const mounted = useMounted();
  const [isValid, setIsValid] = useState(false);
  const editorRef = useRef<JSONEditorInstance>();
  const handleEditorError = useCallback((errors) => {
    setIsValid(errors.length === 0);
  }, []);
  const handleValidate = useCallback((json: any) => {
    const errors = [];
    if (Array.isArray(json)) {
      errors.push({path: [], message: 'Input Data must not be an Array.'});
    }
    return errors;
  }, []);
  const section = typeToSection(subModel.type);

  useEffect(() => {
    if (input && mounted.current) {
      editorRef.current?.updateText(input?.value || '{}');
      editorRef.current?.format();
    }
  }, [input, input.id, mounted]);

  const handleAddFile = useCallback(
    (files: FileList) => {
      if (files) {
        Array.from(files).forEach((file: File) => {
          const reader = new FileReader();
          reader.onload = () => {
            // @ts-ignore
            editorRef.current?.updateText(reader.result);
          };
          reader.readAsBinaryString(file);
        });
      }
    },
    [editorRef],
  );

  const handleSubmit = useCallback(async () => {
    try {
      onSubmit(JSON.stringify(editorRef.current!.get()));
    } catch (e) {}
  }, [editorRef, onSubmit]);

  return (
    <>
      <DefaultPage>
        <SuspenseNull>
          {model && subModel && input && (
            <>
              <SubModelSectionHeader
                model={model}
                section={section}
                subModel={subModel}
                title="Input Definition Editor"
              />

              <div style={{maxWidth: '100rem', margin: '0 auto'}}>
                <Grid container direction="column" alignItems="flex-end" justify="flex-start">
                  <Grid item md={9} sm={12} container>
                    <ModelTextOverviewTile label="Upload or copy paste" Icon={<Path />} value="Input Data" />
                  </Grid>
                  <Grid item container direction="row" alignItems="flex-start" justify="space-between">
                    <Grid item md={3} sm={12}>
                      <Grid item sm={12}>
                        <>
                          <p>
                            <strong>Import File</strong>
                          </p>
                          <p>Only .json files.</p>
                          <FileInputButton label="Add file" onChange={handleAddFile} accept=".json" />
                        </>
                      </Grid>
                    </Grid>
                    <Grid item md={9} sm={12}>
                      <Grid item sm={12}>
                        <div style={{margin: '2rem 0'}}>
                          <JSONEditor
                            onValidationError={handleEditorError}
                            onValidate={handleValidate}
                            jsonEditorRef={editorRef}
                            readOnly={false}
                            loading={false}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={9} sm={12} container justify="flex-end">
                    <div className="buttonsContainer">
                      <CancelButton disabled={submitting} onClick={onCancel} />
                      <SubmitButton disabled={!isValid} active={submitting} onClick={handleSubmit} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </SuspenseNull>
      </DefaultPage>
      <SubModelActionMenu model={model} section={section} subModel={subModel} />
    </>
  );
};

export default ModelInputJSONEditor;
