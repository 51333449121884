import React, {useCallback, useState} from 'react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {scenarioUpdate, scenarioUpdateInputs, useScenarioDetail} from '../../../../../store/scenario';
import ScenarioForm from '../ScenarioAdd/ScenarioForm';
import {trackInstanceUpdated} from '../../../../../tracking/tracking';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useModelInput} from '../../../../../store/modelInputs';
import {stringifyScenarioParameters} from '../../../../core/ModelInput/ScenarioParameters/inputs/stringifyScenarioParameters';

const ScenarioEdit: React.FC<ScenarioActionProps> = ({model, subModel, scenario, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);

  const input = useModelInput(subModel.id);
  const scenarioDetail = useScenarioDetail(scenario.id);

  const handleSubmit = useCallback(
    async (updatedScenario: any, value?: object) => {
      setSubmitting(true);
      try {
        await scenarioUpdate(dispatch, scenario.id, {...scenario, ...updatedScenario});
        if (value) {
          await scenarioUpdateInputs(dispatch, scenario.id, {
            modelInputId: 0,
            ...scenarioDetail?.input,
            value: stringifyScenarioParameters(input!, value),
          });
        }
        trackInstanceUpdated(model, subModel, scenario);
        dispatch(messageAdd('Instance has been updated', MessageTypes.SUCCESS));
        mounted.current && setSubmitting(false);
        onSuccess?.();
      } catch (e) {
        setSubmitting(false);
      }
    },
    [dispatch, model, subModel, scenario, input, scenarioDetail, mounted, onSuccess],
  );

  if (!input || !scenarioDetail) {
    return <SuspenseNull />;
  }

  return (
    <>
      <h2>Edit Instance</h2>
      <ScenarioForm
        model={model}
        subModel={subModel}
        input={input}
        scenario={scenarioDetail}
        submitting={submitting}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

export default ScenarioEdit;
