import {RootState} from '../rootReducer';
import {BrowseRepoRequest, GetHistoryRequest} from 'hemwb-api';

export const bitbucketSelector = (state: RootState) => state.bitbucket;

export const bitbucketProjectsSelector = (state: RootState) => state.bitbucket.projects;

export const bitbucketRepositoriesSelector = (state: RootState) => state.bitbucket.repositories;
export const bitbucketRepositoriesSelectorBuilder = (params: [string | undefined]) => (state: RootState) =>
  bitbucketRepositoriesSelector(state)[params[0] || ''] || null;

export const bitbucketBrowseSelector = (state: RootState) => state.bitbucket.browse;
export const bitbucketBrowseSelectorBuilder = (params: [BrowseRepoRequest]) => (state: RootState) =>
  bitbucketBrowseSelector(state)[params[0].path || ''];

export const bitbucketHistorySelector = (state: RootState) => state.bitbucket.history;
export const bitbucketHistorySelectorBuilder = (params: [GetHistoryRequest]) => (state: RootState) =>
  bitbucketHistorySelector(state)[`${params[0].projectName}-${params[0].repoName}`];
