import React, {useCallback, useState} from 'react';
import {useModelFile} from '../../../../../store/modelFile';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import SubModelForm from './SubModelForm';
import {executionSetupCreate, sectionToType} from '../../../../../store/executionSetup';
import {ExecutionSetupUpdateDTO, ModelInputDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {ModelSectionActionProps} from '../../../actionMenuTypes';
import {trackSubModelCreated} from '../../../../../tracking/tracking';
import {modelInputsCreate} from '../../../../../store/modelInputs';

const SubModelAdd: React.FC<ModelSectionActionProps> = ({model, section, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const {files} = useModelFile(model);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (subModel: ExecutionSetupUpdateDTO, inputDefinition: ModelInputDTO) => {
      if (!subModel) {
        return;
      }
      setSubmitting(true);
      try {
        const createdSubModel = await executionSetupCreate(dispatch, model.uuid, {
          ...subModel,
          type: sectionToType(section),
        });

        await modelInputsCreate(dispatch, createdSubModel.id, inputDefinition);
        trackSubModelCreated(model, createdSubModel);
        dispatch(messageAdd('Sub-model has been created', MessageTypes.SUCCESS));
        setSubmitting(false);
        onSuccess?.();
      } catch (e) {
        setSubmitting(false);
      }
    },
    [dispatch, model, onSuccess, section],
  );

  return (
    <SuspenseNull>
      {files && (
        <>
          <h2>Add Sub-model</h2>
          <SubModelForm
            model={model}
            section={section}
            files={files}
            submitting={submitting}
            onSubmit={handleSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </SuspenseNull>
  );
};

export default SubModelAdd;
