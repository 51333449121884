import React from 'react';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {InputGenerator, InputType, StringInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {StringInputPrimitive, stringInputPrimitiveControlBuilder} from '../primitives/StringInputPrimitive';

type ControlsMap = {[key in keyof Required<StringInputAttributes>]: AbstractControl};

type StringInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const StringInput: React.FC<StringInputProps> = ({formGroup}) => {
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <StringInputPrimitive formGroup={formGroup} />
    </>
  );
};

export const stringInputGenerator: InputGenerator<InputType.STRING> = {
  type: InputType.STRING,
  buildControl: (initialValues = {}) =>
    new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      ...stringInputPrimitiveControlBuilder(initialValues),
    } as ControlsMap),
  render: StringInput,
};
