import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {getErrorText, getVisibleErrors} from './utils';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel} from '@material-ui/core';
import {tid} from '../../../testUtils';
import styles from './CheckboxControl.module.scss';

type CheckboxControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label?: React.ReactNode;
  inputProps?: any;
  classes?: object;
  plural?: boolean;
  required?: boolean;
  disabled?: boolean;
  options: any[];
  getOptionLabel?: (option: any) => string;
  customErrorMessages?: object;
  layout?: 'checkboxTop';
};

const CheckboxControl: React.FC<CheckboxControlProps> = ({
  label,
  plural,
  required,
  disabled = false,
  inputProps,
  options,
  getOptionLabel = (option) => option,
  customErrorMessages = {},
  layout,
  ...FieldControlProps
}) => {
  return (
    <FieldControl
      {...FieldControlProps}
      render={(renderProps) => {
        const {handler} = renderProps;
        const {onChange, value, type, ...restHandlers} = handler();
        const visibleErrors = getVisibleErrors(renderProps);
        const hasVisibleErrors = Object.keys(visibleErrors).length > 0;

        return (
          <FormControl
            fullWidth
            component="fieldset"
            error={hasVisibleErrors}
            hiddenLabel={true}
            className="MuiFormControl-hem">
            {label && (
              <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
                {label}
              </InputLabel>
            )}
            <FormGroup>
              {options.map((option) => {
                const selected = value.includes(option);
                return (
                  <FormControlLabel
                    key={option}
                    classes={{root: styles.checkboxTopLabelRoot}}
                    control={
                      <Checkbox
                        classes={{root: styles.checkboxTopCheckboxRoot}}
                        color="primary"
                        {...restHandlers}
                        disabled={disabled || restHandlers.disabled}
                        checked={selected}
                        value={true}
                        onChange={() =>
                          onChange(
                            selected
                              ? value.filter((existingValue: any) => existingValue !== option)
                              : [...value, option],
                          )
                        }
                        inputProps={{...(tid('multiple-checkbox-option', option) as {}), ...inputProps}}
                      />
                    }
                    label={
                      <>
                        {getOptionLabel(option)}
                        {hasVisibleErrors && options.length === 1 && (
                          <FormHelperText>
                            {getErrorText(visibleErrors, String(label), plural, customErrorMessages)}
                          </FormHelperText>
                        )}
                      </>
                    }
                  />
                );
              })}
            </FormGroup>
            {hasVisibleErrors && options.length !== 1 && (
              <FormHelperText>{getErrorText(visibleErrors, String(label), plural, customErrorMessages)}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default CheckboxControl;
