import {ModelInputAction, ModelInputsActions} from './types';
import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestModelInputsList} from './api';
import {sanitizeModelInputDetailDTO} from './utils';

export type ModelInputState = {
  [executionSetupId: number]: AsyncActionState<
    Await<ReturnType<typeof requestModelInputsList>>,
    Parameters<typeof requestModelInputsList>
  >;
};

export const modelInputsInitialState = {} as ModelInputState;

export const modelInputsReducer = (state = modelInputsInitialState, action: ModelInputAction): ModelInputState => {
  switch (action.type) {
    case ModelInputsActions.LOAD: {
      const [executionSetupId] = action.params;
      return {
        ...state,
        [executionSetupId]: reduceAsyncAction(action),
      };
    }

    case ModelInputsActions.CREATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [executionSetupId] = action.params;
        const newList = [...(state[executionSetupId]?.value?.list || []), sanitizeModelInputDetailDTO(action.value)];
        return {
          ...state,
          [executionSetupId]: {
            state: AsyncActionPhase.SUCCESS,
            ...state[executionSetupId],
            value: {
              list: newList,
              total: newList.length,
            },
          },
        };
      }

      return state;
    }

    case ModelInputsActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [executionSetupId, id, input] = action.params;
        return {
          ...state,
          [executionSetupId]: {
            ...state[executionSetupId],
            value: {
              ...state[executionSetupId].value,
              list: (state[executionSetupId]?.value?.list || []).map((i) =>
                i.id === id ? {...i, ...input, updatedOn: new Date()} : i,
              ),
            },
          },
        };
      }

      return state;
    }

    case ModelInputsActions.DELETE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [executionSetupId, id] = action.params;

        const newList = (state[executionSetupId]?.value?.list || []).filter((i) => i.id !== id);

        return {
          ...state,
          [executionSetupId]: {
            ...state[executionSetupId],
            value: {
              list: newList,
              total: newList.length,
            },
          },
        };
      }

      return state;
    }
  }

  return state;
};
