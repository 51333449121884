import {ExecutionSetupDTO, ExecutionSetupType, UserDTO} from 'hemwb-api';
import {useReduxLoader, useReduxManualLoader} from '../useReduxLoader';
import {executionSetupListSelectorBuilder, executionSetupSingleSelectorBuilder} from './selectors';
import {executionSetupLoad, executionSetupLoadList} from './actions';
import {useCallback, useMemo} from 'react';
import {useSelector} from '../rootReducer';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {useDispatch, useStore} from 'react-redux';

export type ExtendedExecutionSetupDTO = ExecutionSetupDTO & {createdByUser?: UserDTO; modifiedByUser?: UserDTO};

export const useExecutionSetupList = (modelUuid: string, type: ExecutionSetupType) => {
  const response = useReduxLoader(executionSetupListSelectorBuilder, executionSetupLoadList, [modelUuid, type]);
  return response ? response.list : response;
};

export const useExecutionSetupListLoader = (modelUuid: string, type: ExecutionSetupType) => {
  return useReduxManualLoader(executionSetupListSelectorBuilder, executionSetupLoadList, [modelUuid, type]);
};

export const useExecutionSetup = (executionSetupId?: number, modelUuid?: string) => {
  const dispatch = useDispatch();
  const single = useSelector(executionSetupSingleSelectorBuilder([executionSetupId || -1]));
  const listCalibration = useSelector(
    executionSetupListSelectorBuilder([modelUuid || '', ExecutionSetupType.CALIBRATION]),
  );
  const listScenario = useSelector(executionSetupListSelectorBuilder([modelUuid || '', ExecutionSetupType.SCENARIO]));

  return useMemo(() => {
    if (single) {
      return single.value || undefined;
    }

    const lists = [listCalibration, listScenario].filter(Boolean);
    const executionSetup = lists.reduce((acc, val) => {
      if (!acc && val.state === AsyncActionPhase.SUCCESS) {
        return val.value?.list?.find((s) => s.id === executionSetupId);
      }
      return acc;
    }, undefined as ExecutionSetupDTO | undefined);

    if (executionSetup) {
      return executionSetup;
    }

    if (!lists.some((l) => l.state !== AsyncActionPhase.SUCCESS) && executionSetupId) {
      executionSetupLoad(dispatch, executionSetupId);
    }
  }, [dispatch, executionSetupId, single, listCalibration, listScenario]);
};

export const useExecutionSetupLoaderWithParams = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const load = useCallback(
    (executionSetupId?: number, modelUuid?: string) => {
      const single = executionSetupSingleSelectorBuilder([executionSetupId || -1])(store.getState());
      const listCalibration = executionSetupListSelectorBuilder([modelUuid || '', ExecutionSetupType.CALIBRATION])(
        store.getState(),
      );
      const listScenario = executionSetupListSelectorBuilder([modelUuid || '', ExecutionSetupType.SCENARIO])(
        store.getState(),
      );

      if (single) {
        return single.value || undefined;
      }

      const lists = [listCalibration, listScenario].filter(Boolean);
      const executionSetup = lists.reduce((acc, val) => {
        if (!acc && val.state === AsyncActionPhase.SUCCESS) {
          return val.value?.list?.find((s) => s.id === executionSetupId);
        }
        return acc;
      }, undefined as ExecutionSetupDTO | undefined);

      if (executionSetup) {
        return Promise.resolve(executionSetup);
      }

      if (!lists.some((l) => l.state !== AsyncActionPhase.SUCCESS) && executionSetupId) {
        return executionSetupLoad(dispatch, executionSetupId);
      }
    },
    [dispatch, store],
  );

  return load;
};
