import {UserBaseDTO, UserType} from 'hemwb-api';
import React, {useMemo} from 'react';
import {DropDownControl, Form, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {getUserTypeName, userTypes} from '../../../../../store/user/utils';
import {tid} from '../../../../../testUtils';
import {Grid} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import {Routes} from '../../../../router/routes';
import GridTitle from '../../../../core/Form/GridTitle';
import AutocompleteUserControl from '../../../../core/Form/AutocompleteUserControl';
import {useUserList} from '../../../../../store/user';
import {Collaborate16} from '@carbon/icons-react';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';

type UserEnrollFormProps = {
  onSubmit: (values: UserEnrollFormValues) => void;
};

export type UserEnrollFormValues = {
  user: UserBaseDTO;
  userType: UserType;
};

const userFindOptions = {limit: 10, includeNpa: true};

export const UserEnrollForm: React.FC<UserEnrollFormProps> = ({onSubmit}) => {
  const form = useForm(
    new FormGroup({
      userType: new FormControl(null, Validators.required),
      user: new FormControl(null, Validators.required),
    }),
  );
  const list = useUserList();
  const enrolledUserIds = useMemo(() => (list || []).map((user) => user.id), [list]);

  if (!list) {
    return <SuspenseNull />;
  }

  return (
    <Form
      group={form}
      onSubmit={() => onSubmit(form.value)}
      render={({submitting}) => (
        <>
          <Grid container direction="column" alignItems="flex-start" justify="space-between" style={{width: '50%'}}>
            <GridTitle>Enroll new user</GridTitle>
            <GridItem>
              <DropDownControl
                name="userType"
                label="User Type"
                required
                options={userTypes}
                getOptionLabel={(option: UserType) => getUserTypeName(option)}
              />
            </GridItem>
            <GridItem>
              <AutocompleteUserControl
                name="user"
                label="Find user"
                required
                userFindOptions={userFindOptions}
                AutocompleteProps={{
                  getOptionDisabled: (option) => enrolledUserIds?.includes(option.id),
                }}
              />
            </GridItem>
          </Grid>

          <div className="buttonsContainer alignLeft">
            <CancelButton disabled={submitting} to={Routes.USER_LIST}></CancelButton>
            <SubmitButton active={submitting} endIcon={<Collaborate16 />} {...tid('btn-enroll')}>
              Enroll
            </SubmitButton>
          </div>
        </>
      )}
    />
  );
};
