import React, {useCallback, useState} from 'react';
import {Form, useForm} from '../../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {modelMetadataDeleteOption, modelMetadataOptionsLabels} from '../../../../../../store/modelMetadata';
import {useDispatch} from 'react-redux';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useMounted} from '../../../../../../hooks/useMounted';
import {Edit32, TrashCan16} from '@carbon/icons-react';
import CheckboxControl from '../../../../../core/Form/CheckboxControl';
import {trackModelMetadataOptionDeleted} from '../../../../../../tracking/tracking';
import UsageCountTitle from '../updateOption/UsageCountTitle';
import UsageList from '../updateOption/UsageList';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import ActionHeader from '../../../../../core/Header/ActionHeader/ActionHeader';

const DeleteOption: React.FC<ManageMetadataActionProps> = ({
  property,
  value,
  onSuccess,
  onCancel,
  refreshOptionsCount,
  fileTagCount,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const form = useForm(
    new FormGroup({
      agree: new FormControl([], [Validators.required]),
    }),
  );

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    return modelMetadataDeleteOption(dispatch, property, value)
      .then(() => {
        dispatch(messageAdd(`The option has been deleted`, MessageTypes.SUCCESS));
        trackModelMetadataOptionDeleted();
        onSuccess(null);
        if (refreshOptionsCount) refreshOptionsCount();
      })
      .catch(() => null)
      .finally(() => {
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, onSuccess, property, value, mounted, refreshOptionsCount]);

  return (
    <>
      <ActionHeader label={`Delete ${modelMetadataOptionsLabels[property]} Option`} Icon={<Edit32 />}>
        <UsageCountTitle property={property} value={value} fileTagCount={fileTagCount} />
      </ActionHeader>

      <Form
        className=""
        group={form}
        render={({valid}) => {
          return (
            <>
              <CheckboxControl
                name="agree"
                layout="checkboxTop"
                options={['confirm']}
                getOptionLabel={() => 'I confirm to delete this Option permanently.'}
                customErrorMessages={{required: 'You have to check confirmation checkbox'}}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} className="redButton" />
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  disabled={!valid}
                  className="redButton"
                  endIcon={<TrashCan16 />}
                  {...tid('btn-manage-metadata-delete')}>
                  Delete Option
                </SubmitButton>
              </div>
            </>
          );
        }}
      />

      <UsageList property={property} value={value} fileTagCount={fileTagCount} />
    </>
  );
};

export default DeleteOption;
