import React from 'react';
import {VisualizationResultsErrorElement} from '../../../../../store/visualization';

const Error: React.FC<VisualizationResultsErrorElement & {id: string}> = ({title, text, id}) => {
  return (
    <div style={{color: 'red'}}>
      <h3 tabIndex={0} id={id}>
        {title}
      </h3>
      {text && <p>{text}</p>}
    </div>
  );
};

export default Error;
