import {useReduxManualLoaderWithParams} from '../useReduxLoader';
import {Dispatch} from 'redux';
import {veevaLoadMdpList, veevaLoadVeap} from './actions';
import {veevaMdpSelectorBuilder, veevaVeapSelectorBuilder} from './selectors';

const loadVeapIgnoreInvalid = (dispatch: Dispatch, p: string) => {
  return p ? veevaLoadVeap(dispatch, p) : Promise.resolve();
};
loadVeapIgnoreInvalid.clear = veevaLoadVeap.clear;
export const useVeevaVeapLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(veevaVeapSelectorBuilder, loadVeapIgnoreInvalid);
};

const loadMdpIgnoreInvalid = (dispatch: Dispatch, p: string) => {
  return p ? veevaLoadMdpList(dispatch, p) : Promise.resolve();
};
loadMdpIgnoreInvalid.clear = veevaLoadMdpList.clear;
export const useVeevaMdpLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(veevaMdpSelectorBuilder, loadMdpIgnoreInvalid);
};
