import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {UserBaseDTO} from 'hemwb-api';
import {ModelDisplayViewersAction, ModelDisplayViewersActions} from './types';
import {ModelAccessAction, ModelAccessActions} from '../modelAccess/types';

type FetchedDisplayViewers = AsyncActionState<UserBaseDTO[], [string]> & {timestamp: number};

export const modelDisplayViewersInitialState = {
  displayViewers: {} as {[modelUuid: string]: FetchedDisplayViewers},
};

export type ModelDisplayViewersState = typeof modelDisplayViewersInitialState;

export const modelDisplayViewersReducer = (
  state = modelDisplayViewersInitialState,
  action: ModelDisplayViewersAction | ModelAccessAction,
): ModelDisplayViewersState => {
  switch (action.type) {
    case ModelDisplayViewersActions.FETCH: {
      const [modelUuid] = action.params;
      return {
        ...state,
        displayViewers: {
          ...state.displayViewers,
          [modelUuid]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelAccessActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [modelUuid, {displayViewers}] = action.params;
        if (displayViewers && state.displayViewers[modelUuid]) {
          return {
            ...state,
            displayViewers: {
              ...state.displayViewers,
              [modelUuid]: {
                ...state.displayViewers[modelUuid],
                value: displayViewers,
                timestamp: Date.now(),
              },
            },
          };
        }
      }
      return state;
    }
  }

  return state;
};
