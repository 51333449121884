import React, {useEffect, useState} from 'react';
import {TaskView32, UserAvatar32, Password32} from '@carbon/icons-react';
import ActionHeader from '../../../../../core/Header/ActionHeader/ActionHeader';
import {Typography} from '@material-ui/core';
import {ExUserRegistrationDetails, ModelAccessRequest} from 'hemwb-api';
import {requestExternalUserDetails} from '../../../../../../store/user';
import TableWithSortAndPagination, {Column} from '../../../../../core/TableWithSortAndPagination';
import {externalUserDetailGridId, useStoredGridState} from '../../../../../../store/grid';
import {CircularProgress} from '@material-ui/core';
import {getUrlRoute, Routes} from '../../../../../router/routes';

export type UserActionMenuProps = {
  selectedUser: ExUserRegistrationDetails | null;
};

const UserDetails: React.FC<UserActionMenuProps> = ({selectedUser}) => {
  const [userDetail, setUserDetail] = useState<ModelAccessRequest[]>([]);
  const [modelOwnerName, setModelOwnerName] = useState<string>();
  const gridState = useStoredGridState(externalUserDetailGridId);

  const ModelLink = ({modelName, modelUuid}: ModelAccessRequest) => {
    return (
      <div style={{marginBottom: '2px'}}>
        <a style={{color: 'blue'}} href={handleItemLink(modelUuid)}>
          {modelName}
        </a>
      </div>
    );
  };

  const handleItemLink = (modelUuid: string) => {
    return `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
      uuid: modelUuid,
    })}`;
  };

  const columns: Column<ModelAccessRequest>[] = [
    {
      id: 'name',
      label: 'Model Name',
      sortable: true,
      value: (modelAccesses) => modelAccesses.modelUuid,
      display: ModelLink,
    },
    {
      id: 'role',
      label: 'Assign Model Role',
      sortable: true,
      value: (modelAccesses) => modelAccesses.modelRole,
    },
  ];

  useEffect(() => {
    if (selectedUser) {
      requestExternalUserDetails(selectedUser.email).then((response) => {
        setUserDetail(response.modelAccesses);
        setModelOwnerName(response.modelOwnerName);
      });
    }
  }, [selectedUser]);

  return (
    <>
      <ActionHeader label="View Details" Icon={<TaskView32 />}>
        <div style={{fontSize: '1.9rem'}}>
          verify the details, model entries assigned and role assigned to the external users
        </div>
      </ActionHeader>
      <div style={{marginLeft: '1.5rem'}}>
        <ActionHeader label="" Icon={<UserAvatar32 />}>
          <div style={{fontSize: '1.9rem'}}>User Details</div>
        </ActionHeader>
        <div>
          <Typography>
            <span style={{fontWeight: 'bold'}}>First Name: </span>
            {selectedUser?.firstName}
          </Typography>
          <Typography>
            <span style={{fontWeight: 'bold'}}>Last Name: </span>
            {selectedUser?.lastName}
          </Typography>
          <Typography>
            <span style={{fontWeight: 'bold'}}>Email Id: </span>
            {selectedUser?.email}
          </Typography>
          <Typography>
            <span style={{fontWeight: 'bold'}}>Model Owner who provided the registration code: </span>
            {modelOwnerName}
          </Typography>
        </div>
        <ActionHeader label="" Icon={<Password32 />}>
          <div style={{fontSize: '1.9rem'}}>Model Entry and Roles Assigned</div>
        </ActionHeader>
        {userDetail.length !== 0 ? (
          <TableWithSortAndPagination
            getRowId={(modelAccesses) => modelAccesses.modelUuid}
            rows={userDetail}
            columns={columns}
            gridState={gridState}
            hidePagination={true}
            noResultsMessage="No details were found"
          />
        ) : (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexGrow: 1,
            }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
