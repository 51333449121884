import {DiffDTOTagEnum, MacroDiffDTO, SheetDiffDTO, WorkbookItemDTO} from 'hemwb-api';
import {ModelTreeNode} from '../../../../store/modelFile';

export enum Section {
  OVERVIEW = 'Overview',
  SHEETS = 'Sheets',
  MACROS = 'Macros',
}

export enum ViewMode {
  SINGLE_PANE = 'Single Pane View',
  DOUBLE_PANE = 'Double Pane View',
}

export type TabType = {
  tag: DiffDTOTagEnum;
  file1: ModelTreeNode;
  file2: ModelTreeNode;
  itemId1: number;
  itemId2: number;
  name: string;
  type: WorkbookItemDTO['type'];
};

export type SheetOrMacroDiffDTO = SheetDiffDTO | MacroDiffDTO;
export type DiffWithTab = {
  tab: TabType;
  diff: SheetOrMacroDiffDTO;
};

export type ChangeItem = {
  tab: TabType;
  diff?: SheetOrMacroDiffDTO;
  pageIndex: number;
  diffIndex: number;
};

export const ROWS_PER_PAGE = 200;
