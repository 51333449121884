import React from 'react';
import {ScenarioDTO} from 'hemwb-api';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import GridItem from '../../../../core/Form/GridItem';
import {tid} from '../../../../../testUtils';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';

export type AnalysisFormValues = {
  name: string;
};

type AnalysisFormProps = {
  scenario: ScenarioDTO;
  onSubmit: (values: AnalysisFormValues) => void;
  onCancel: () => void;
  submitting: boolean;
};

const VisualizationAnalysisForm: React.FC<AnalysisFormProps> = ({scenario, submitting, onSubmit, onCancel}) => {
  const form = useForm(
    new FormGroup({
      name: new FormControl(scenario.name, Validators.required),
    }),
  );

  return (
    <>
      <Form
        FieldGroupProps={{strict: false}}
        style={{marginLeft: 0}}
        className=""
        group={form}
        onSubmit={onSubmit}
        render={() => {
          return (
            <>
              <GridItem>
                <TextFieldControl
                  label="Name"
                  name="name"
                  TextFieldProps={{
                    inputProps: {
                      ...tid('input', 'name'),
                    },
                  }}
                />
              </GridItem>
              <br />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_ANALYSIS, 'cancel')} />
                <SubmitButton active={submitting} {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_ANALYSIS, 'submit')}>
                  Save
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default VisualizationAnalysisForm;
