import React, {useCallback, useState} from 'react';
import {DialogGeneric} from '../../../../../core/dialog/DialogGeneric';
import GridItem from '../../../../../core/Form/GridItem';
import {Form, TextFieldControl, useForm} from '../../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {tid} from '../../../../../../testUtils';
import {useMounted} from '../../../../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useDispatch} from 'react-redux';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {requestNotificationCreation} from '../../../../../../store/notification/api';

export type NotificationConfirmationDialogProps = {
  title: string;
  text: string;
  description: string;
  label: string;
  open: boolean;
  successMessage: string;
  type: string;
  uuid?: string;
  handleCancel: () => void;
};

const NotificationConfirmationDialog: React.FC<NotificationConfirmationDialogProps> = ({
  title,
  text,
  open,
  type,
  description,
  successMessage,
  label,
  handleCancel,
  uuid,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const mounted = useMounted();
  const form = useForm(
    new FormGroup({
      userNote: new FormControl(null, [Validators.required]),
    }),
  );

  const handleSubmit = useCallback(() => {
    if (form.valid && uuid) {
      setSubmitting(true);

      return requestNotificationCreation(uuid, type, form.value.userNote)
        .then(() => {
          dispatch(messageAdd(successMessage, MessageTypes.SUCCESS));
          handleCancel();
        })
        .catch(() => dispatch(messageAdd('Failed to send email notification', MessageTypes.ERROR)))
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    }
  }, [form.valid, dispatch, type, uuid, form.value.userNote, mounted, handleCancel, successMessage]);

  return (
    <DialogGeneric
      open={open}
      title={title}
      PaperProps={tid('dialog-request-file-access') as {}}
      childrenAsText={false}>
      <Form
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <div style={{minHeight: '35rem'}}>
              <div>
                <div>{text}</div>
                <div>{description}</div>
                <div style={{marginTop: '20px'}}>
                  <span style={{color: 'grey'}}>
                    Please provide users more details regarding {label}.<span style={{color: '#FF5656'}}>*</span>
                  </span>
                  <GridItem>
                    <TextFieldControl
                      label="User Note"
                      hideAsterisk={true}
                      name="userNote"
                      TextFieldProps={{
                        inputProps: {
                          ...tid('input', 'message'),
                        },
                        InputProps: {
                          style: {width: '54rem'},
                        },
                      }}
                    />
                  </GridItem>
                </div>
              </div>
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={handleCancel} {...tid('dialog-notify-cancel', 'cancel')}>
                  {' '}
                  No{' '}
                </CancelButton>
                <SubmitButton active={submitting} {...tid('dialog-notify-submit', 'submit')}>
                  Yes
                </SubmitButton>
              </div>
            </div>
          );
        }}
      />
    </DialogGeneric>
  );
};

export default NotificationConfirmationDialog;
