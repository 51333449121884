import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import styles from './IncidentTextOverviewTile.module.scss';

type IncidentTextOverviewTileProps = {
  label: string;
  value?: string;
  inline: boolean;
  className?: string;
};

const IncidentTextOverviewTile: React.FC<IncidentTextOverviewTileProps> = ({label, value, inline, className}) => {
  return (
    <>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              {inline ? (
                <Typography>
                  <Typography className={styles.title} component="span">
                    {label}:
                  </Typography>{' '}
                  <Typography className={className ? className : styles.value} component="span">
                    {value}
                  </Typography>{' '}
                </Typography>
              ) : (
                <div>
                  <Typography className={styles.title}>{label}:</Typography>
                  <Typography className={className ? className : styles.value}>{value}</Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default IncidentTextOverviewTile;
