/* eslint-disable @typescript-eslint/camelcase */

export const simpleFields = {
  // date_of_model_release: 'Date of Model Release',
  // legal_notice: 'Legal Notice',
  model_development_plan: 'Model Development Plan',
  // model_expiration_date: 'Model Expiration Date',
  // model_publication: 'Model Publication',
  // model_qualifier: 'Model Qualifier',
  // model_testing: 'Model Testing',
  // model_training: 'Model Training',
  // name_of_model_developer: 'Name of Model Developer',
  notes: 'Notes (links to model publications, name of vendors involved in development, etc.)',
  objective_of_model: 'Objective of Model',
  stage_of_model_development: 'Stage of Model Development',
  // url_to_model_documentation: 'URL to Model documentation',
  // url_to_model_if_external: 'URL to Model if external',
  // usage_notes: 'Usage Notes',
  revops_id: 'REV/OPS ID',
  version_log: 'Version Log',
  changeLogFileName: 'Changelog document',
  changelog_file: 'changelogId',
  platform: 'Platform',
  label: 'Model Label',
  indication: 'THEOREM Indication',
  mk_number: 'MK Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  id: 'ISID',
  expertiseLevel: 'Experstise Level',
};

export const fields = {
  country: 'Country',
  disease: 'Name of Disease',
  global: 'Global',
  webmodel: 'WebModel 2.0',
  information_classification: 'Information Classification',
  // model_qualified: 'Model Qualified',
  model_type: 'Model Type',
  product: 'Name of Product',
  study_number: 'Trial Number',
  therapeutic_area: 'Therapeutic Area',
  type: 'Model Language',
  veap_id: 'VEAP ID or REV/OPS ID',
  version_release_of_model: 'Version Release of Model',
  ...simpleFields,
};

export const metadataLabels = {
  ...fields,
  email_for_model_steward: 'Model Steward email',
  modelSteward: 'Model Steward',
  name_of_model: 'Name of Model',
  name_of_model_steward: 'Model Steward name',
  vestLead: 'VEST or VEAM Lead',
} as {[key: string]: string};

export const textAreas: {[key in FieldKey]?: true} = {
  notes: true,
  objective_of_model: true,
  version_log: true,
};

export type Values = Omit<typeof fields, 'global' | 'product'> & {
  global: boolean;
  product: string[];
};
export type FieldKey = keyof Values;

export const requiredFields: {[key in FieldKey]?: true} = {
  country: true,
  disease: true,
  information_classification: true,
  stage_of_model_development: true,
  model_type: true,
  product: true,
  therapeutic_area: true,
  type: true,
  version_release_of_model: true,
};

export const hardCodedOptions: {[key in FieldKey]?: string[]} = {
  information_classification: ['Confidential', 'Proprietary', 'Public', 'Sensitive'],
  type: ['ExcelNative', 'Mathematica', 'Python', 'R', 'Other'],
  expertiseLevel: ['250-Basic', '500-Intermediate', '750-Advanced', '1000-Expert'],
  //expertiseLevel: ['BASIC', 'INTERMEDIATE', 'ADVANCED', 'EXPERT']
};

export const getCommonFieldProps = (name: FieldKey) => ({
  key: name,
  name,
  label: fields[name],
  required: !!requiredFields[name],
});
