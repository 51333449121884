import {RootState} from '../rootReducer';
import {buildKey} from './utils';
import {GetMacroDiffRequest, GetSheetDiffRequest} from 'hemwb-api';

export const excelDiffSelector = (state: RootState) => state.excelDiff;

export const excelDiffContentListSelector = (state: RootState) => excelDiffSelector(state).contentLists;
export const excelDiffContentListSelectorBuilder = (params: [string]) => (state: RootState) =>
  excelDiffContentListSelector(state)[params[0]];

export const excelDiffSheetsSelector = (state: RootState) => excelDiffSelector(state).sheets;
export const excelDiffSheetSelectorBuilder = (params: [GetSheetDiffRequest]) => (state: RootState) =>
  excelDiffSheetsSelector(state)[buildKey(params[0])];

export const excelDiffMacrosSelector = (state: RootState) => excelDiffSelector(state).macros;
export const excelDiffMacroSelectorBuilder = (params: [GetMacroDiffRequest]) => (state: RootState) =>
  excelDiffMacrosSelector(state)[buildKey(params[0])];
