import React, {ReactNode} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import {Launch16} from '@carbon/icons-react';

type ModelTextOverviewTileProps = {
  label: string | ReactNode;
  value?: string;
  hideValue?: boolean;
  Icon: ReactNode;
  className?: string;
  externalModel?: boolean;
  platform?: string;
};

const ModelTextOverviewTile: React.FC<ModelTextOverviewTileProps> = ({
  label,
  value,
  hideValue = false,
  Icon,
  className,
  externalModel,
  platform,
}) => {
  let formattedValue = 'N/A';
  let isUrl = false;
  if (value && value !== '' && !externalModel) {
    isUrl = value.startsWith('http');
    if (isUrl) {
      formattedValue = 'Yes';
    } else {
      formattedValue = value;
    }
  }
  if (platform && platform !== '') {
    if (value) {
      isUrl = value.startsWith('http');
    }
    formattedValue = platform;
  }
  return (
    <Grid container className={clsx(styles.tile, className)} alignItems="center" wrap="nowrap">
      <Grid item className={styles.icon}>
        {Icon}
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography className={styles.label}>{label}</Typography>
            <Typography className={styles.value}>{!hideValue && formattedValue}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {isUrl && (
        <Grid item className={styles.attachment}>
          <Button
            className="inverseColor"
            variant="contained"
            component="a"
            target="_blank"
            href={value}
            size="small"
            endIcon={<Launch16 />}>
            {externalModel ? 'Link' : 'View'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ModelTextOverviewTile;
