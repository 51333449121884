import {List} from '@material-ui/core';
import React, {useCallback} from 'react';
import {UserDTO} from 'hemwb-api';
import {tid} from '../../../../../../testUtils';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {ReactComponent as DeactivateIcon} from '../../../../../../assets/icons/action/user-deactivate.svg';
import {Edit16, Email16, Chat16, UserAdmin16} from '@carbon/icons-react';
import {useUserSetActiveDialog} from '../UserSetActiveDialog/useUserSetActiveDialog';
import {userSetActive} from '../../../../../../store/user';
import {useDispatch, useSelector} from 'react-redux';
import SideBarLinkItem from '../../../../../layout/common/SideBarLinkItem';
import {getUrlRoute, Routes} from '../../../../../router/routes';
import SideBarAnchorItem from '../../../../../layout/common/SideBarAnchorItem';
import {authLoggedInUserSelector} from '../../../../../../store/auth';
import {useSharedSideBarToggle} from '../../../../../../store/customSettings';
import SideBar from '../../../../../layout/common/SideBar';
import {canActivateUser, canChangeUserRole, canDeactivateUser} from '../../../../../../permissions/usePermissions';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '19rem',
};

export type UserActionMenuProps = {
  selectedUser: UserDTO | null;
};

export const UserActionMenu: React.FC<UserActionMenuProps> = ({selectedUser}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(authLoggedInUserSelector);
  const {openUserSetActiveDialog, UserSetActiveDialog} = useUserSetActiveDialog();
  const toggleUserActivity = useCallback(() => userSetActive(dispatch, selectedUser!.id, !selectedUser!.active), [
    selectedUser,
    dispatch,
  ]);

  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);
  const disabled = !selectedUser || !loggedInUser;

  return (
    <>
      <SideBar position="right" {...sharedSidebar} {...tid('user-list-action-sidebar')}>
        <List dense component="nav">
          <SideBarLinkItem
            {...tid('btn', 'sidebar', 'edit')}
            permission={canChangeUserRole(selectedUser || undefined)}
            linkTo={disabled ? '#' : getUrlRoute(Routes.USER_EDIT, {id: selectedUser?.id ?? ''})}
            icon={<Edit16 />}
            text="Edit User"
          />
          <SideBarButtonItem
            {...tid('btn', 'sidebar', 'activate')}
            permission={canActivateUser(selectedUser || undefined)}
            icon={<UserAdmin16 />}
            text="Activate user"
            onClick={() => openUserSetActiveDialog({user: selectedUser!, onSubmit: toggleUserActivity})}
          />
          <SideBarButtonItem
            {...tid('btn', 'sidebar', 'deactivate')}
            permission={canDeactivateUser(selectedUser || undefined)}
            icon={<DeactivateIcon />}
            text="Deactivate user"
            onClick={() => openUserSetActiveDialog({user: selectedUser!, onSubmit: toggleUserActivity})}
          />
          <SideBarAnchorItem
            {...tid('btn', 'sidebar', 'email')}
            disabled={disabled || !selectedUser?.email || loggedInUser?.id === selectedUser?.id}
            href={disabled ? '#' : `mailto:${selectedUser?.email}`}
            icon={<Email16 />}
            text="Email User"
          />
          <SideBarButtonItem
            {...tid('btn', 'sidebar', 'chat')}
            disabled={disabled || !selectedUser?.email || loggedInUser?.id === selectedUser?.id}
            icon={<Chat16 />}
            text="Open Chat"
            onClick={() => window.open(`xmpp:${selectedUser!.email}?message`, '_blank')}
          />
        </List>
      </SideBar>
      {UserSetActiveDialog}
    </>
  );
};
