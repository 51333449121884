import React, {ReactElement, ReactNode} from 'react';
import {Container, Grid} from '@material-ui/core';
import PageHeaderTitle from '../../../../core/Header/PageHeader/PageHeaderTitle';
import {ReactComponent as GroupSecurity16} from '../../../../../assets/icons/group-security.svg';

type AdminSpaceHeaderProps = {
  Search?: ReactElement<any>;
  label?: string;
  Icon?: ReactNode;
};

const Header: React.FC<AdminSpaceHeaderProps> = ({Search, Icon, label}) => {
  return (
    <Container maxWidth={false} className="pageHeader">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <PageHeaderTitle
          md={12}
          lg={4}
          title="Externalization"
          label={label || 'Search and register external users'}
          Icon={Icon || <GroupSecurity16 />}
        />
        <Grid item md={12} lg={4}>
          {Search}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
