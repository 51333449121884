import {useContext, useEffect, useRef} from 'react';
import {WebSocketContext} from './WebSocketContext';
import {useDispatch} from 'react-redux';
import {StompSubscription} from '@stomp/stompjs';
import {scenarioUpdateStatus} from '../store/scenario';
import {messageAdd, MessageTypes} from '../store/message';
import {ScenarioDTOFromJSON} from 'hemwb-api';
import {useCurrentUser} from '../store/auth';

export const useScenarioStatusUpdates = () => {
  const {client, connected} = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const subscription = useRef<StompSubscription>();
  const loggedInUser = useCurrentUser();

  useEffect(() => {
    if (client?.connected && connected) {
      //
      //Sends message each time an accessible scenario status is updated
      //
      subscription.current = client.subscribe(`/user/${loggedInUser.id}/queue/scenarioStatusUpdates`, (message) => {
        try {
          dispatch(scenarioUpdateStatus(ScenarioDTOFromJSON(JSON.parse(message.body))));
        } catch (e) {
          dispatch(
            messageAdd(
              `Failed to parse WS response in /queue/scenarioStatusUpdates: ${message.body}`,
              MessageTypes.WARNING,
            ),
          );
        }
      });
    }

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [dispatch, client, connected, loggedInUser.id]);
};
