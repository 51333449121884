import {FileShareAccessApi} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const fileShareAccess = new FileShareAccessApi(configuration);

export const requestUserListForFileAccess = (modelUuid: string, fileUuid: string) =>
  fileShareAccess.findInternalUsersForFileAccess({modelUuid, fileUuid});

export const requestUpdateShareFileAccess = (fileUuid: string, userId: string, status: string) =>
  fileShareAccess.updateShareFileAccess({fileUuid, updateShareFileAccessDTO: {userId: userId, status: status}});
