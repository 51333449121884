import {useSideBarModal} from '../../../../../layout/common/useSideBarModal';
import React, {useCallback, useMemo} from 'react';
import SideBarModal from '../../../../../layout/common/SideBarModal';
import RetireOption from './RetireOption';
import {ManageMetadataActionProps} from '../ActionMenuTypes';

export const useRetireOptionSideBarModal = (props: Partial<ManageMetadataActionProps>) => {
  const {property, value, onSuccess, refreshOptionsCount, fileTagCount} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(property, value);

  const handleSuccess = useCallback(
    (selectNewValue) => {
      closeModal();
      onSuccess?.(selectNewValue);
    },
    [closeModal, onSuccess],
  );

  const ModalComponent = useMemo(() => {
    return (
      property &&
      value && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <RetireOption
                property={property}
                value={value}
                onSuccess={handleSuccess}
                onCancel={closeModal}
                refreshOptionsCount={refreshOptionsCount}
                fileTagCount={fileTagCount}
              />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, property, value, handleSuccess, closeModal, refreshOptionsCount, fileTagCount]);

  return {
    openModal,
    ModalComponent,
  };
};
