import {VersionControlAction, VersionControlActions, VersionControlPage} from './types';

export type VersionControlState = {
  diffSelection: string[];
  page: VersionControlPage;
};

export const versionControlInitialState: VersionControlState = {
  diffSelection: [],
  page: VersionControlPage.LINEAGE,
};

export const versionControlReducer = (
  state = versionControlInitialState,
  action: VersionControlAction,
): VersionControlState => {
  switch (action.type) {
    case VersionControlActions.INIT: {
      return {
        ...state,
        page: versionControlInitialState.page,
        diffSelection: [action.modelUuid],
      };
    }

    case VersionControlActions.PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }

    case VersionControlActions.TOGGLE_DIFF_SELECTION: {
      const {modelUuid} = action;
      if (state.diffSelection.find((uuid) => uuid === modelUuid)) {
        const versionDiffSelection = [...state.diffSelection];
        versionDiffSelection.splice(
          state.diffSelection.findIndex((uuid) => uuid === modelUuid),
          1,
        );
        return {
          ...state,
          diffSelection: versionDiffSelection,
        };
      } else if (state.diffSelection.length > 1) {
        return {
          ...state,
          diffSelection: [state.diffSelection[0], modelUuid],
        };
      }

      return {
        ...state,
        diffSelection: [...state.diffSelection, modelUuid],
      };
    }
  }

  return state;
};
