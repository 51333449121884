import React, {useCallback, useState} from 'react';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';
import VisualizationAnalysisForm, {AnalysisFormValues} from './VisualizationAnalysisForm';
import {useDispatch} from 'react-redux';
import {scenarioUpdate} from '../../../../../store/scenario';
import {trackInstanceVisualizationSaved, trackInstanceVisualizationUpdated} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';

export type VisualizationSaveProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  scenario: ScenarioDTO;
  onCancel: () => void;
  onSuccess: () => void;
};

const VisualizationAnalysisSave: React.FC<VisualizationSaveProps> = ({
  model,
  subModel,
  scenario,
  onSuccess,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values: AnalysisFormValues) => {
      setSubmitting(true);

      try {
        await scenarioUpdate(dispatch, scenario.id, {...values, hidden: false});
        if (!scenario.hidden) {
          trackInstanceVisualizationUpdated(model, subModel, {
            ...scenario,
            ...values,
          });
          dispatch(messageAdd('Analysis Updated', MessageTypes.SUCCESS));
        } else {
          trackInstanceVisualizationSaved(model, subModel, {
            ...scenario,
            ...values,
          });
          dispatch(messageAdd('Analysis Saved', MessageTypes.SUCCESS));
        }

        onSuccess?.();
      } catch (e) {
        setSubmitting(false);
      }
    },
    [dispatch, model, subModel, scenario, onSuccess],
  );

  return (
    <>
      <h2>Save Analysis</h2>
      <VisualizationAnalysisForm
        scenario={scenario}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitting={submitting}
      />
    </>
  );
};

export default VisualizationAnalysisSave;
