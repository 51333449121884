import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import {messageAdd, MessageTypes} from '../../../store/message';
import {requestSecurityAPI} from '../../../store/auth/api';

type AutocompleteItemProps = {
  primary: string;
  secondary: string;
  ownershipType?: string;
  isid?: string;
  modelUuid?: string;
  theoremModel?: boolean;
  fileUuid?: string;
  handleSecurityAPI?: () => void;
};

export const AutocompleteItem: React.FC<AutocompleteItemProps> = ({
  primary,
  secondary,
  ownershipType,
  isid,
  modelUuid,
  theoremModel,
  fileUuid,
  handleSecurityAPI,
  ...rest
}) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (isid && modelUuid && theoremModel && handleSecurityAPI) {
      requestSecurityAPI({securityAPIDTO: {isid: isid, modelUUID: modelUuid, fileUuid: fileUuid}})
        .then(() => {})
        .catch((error) => {
          if (error.status === 500) {
            handleSecurityAPI();
            dispatch(
              messageAdd(
                `This User must request access in THEOREM. Please have the User <a href="https://collaboration.merck.com/sites/THEOREM/Lists/FAQ%20%20THEOREM/DispForm.aspx?ID=53&e=mvH1Me">follow these steps</a> to request access in THEOREM.`,
                MessageTypes.ERROR,
              ),
            );
          }
        });
    }
  };

  return (
    <div {...rest} onClick={handleOnClick}>
      {ownershipType && <Typography variant="body1">{ownershipType}</Typography>}
      <Typography variant="body1">{primary}</Typography>
      <Typography variant="body2" color="textSecondary">
        {secondary}
      </Typography>
    </div>
  );
};
