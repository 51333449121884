import React from 'react';
import {ModelCard} from '../../ModelCard/ModelCard';
import {tid} from '../../../../../../testUtils';
import Suspense from '../../../../../core/Suspense/Suspense';
import {styles} from './styles';
import WarningText from '../../../../../core/WarningText/WarningText';
import {ModelDTO} from 'hemwb-api';
import {Tree, TreeNode} from 'react-organizational-chart';
import ModelCardExpanded from '../../ModelCard/ModelCardExpanded';
import ModelCardVersionForm from './ModelCardVersionForm';
import {ModelTreeNode} from '../../../../../../store/modelFile';
import {canBeParentOfModel} from '../../../../../../store/model';
import {ModelFlatNode} from '../../../../../../store/modelLinks';
import {calculateBranchSizeFromModelUuid} from '../../../../../../store/modelLinks/utils';

type ParentSelectionProps = {
  model: ModelDTO;
  flatHierarchy: ModelFlatNode[];
  isModelInHierarchy: boolean;
  onSelect: (node: ModelFlatNode<ModelDTO>) => void;
  onChange: (model: ModelDTO) => void;
  files: ModelTreeNode[];
  submitClicked: boolean;
};

const ParentSelection: React.FC<ParentSelectionProps> = ({
  model,
  flatHierarchy,
  isModelInHierarchy,
  onSelect,
  onChange,
  files,
  submitClicked,
}) => {
  const currentNode = flatHierarchy.find((n) => n.model.uuid === model.uuid);
  const possibleParentsCount = flatHierarchy.length - calculateBranchSizeFromModelUuid(flatHierarchy, model.uuid);

  const renderNode = (node: ModelFlatNode, parentId = '', selectable = true, cutBranch = false) => {
    const current = model.uuid === node.model.uuid;
    const selectableBranch = selectable && !current;
    const selectableNode =
      selectableBranch && (canBeParentOfModel(node.model, model) || currentNode?.originalParentId === node.model.uuid);
    const isCutBranch = cutBranch || (node.originalParentId === parentId && node.originalParentId !== node.parentId);
    const styleForNonCutBranch = () => {
      return !selectableNode && !current ? {opacity: 0.6} : {};
    };

    return (
      <TreeNode
        key={node.model.uuid}
        label={
          current && !isCutBranch ? (
            <ModelCardExpanded
              model={node.model as ModelDTO}
              hideDetailLink
              current={current}
              hideLeftSection
              Content={
                <>
                  <br />
                  <ModelCardVersionForm
                    files={files}
                    onChange={onChange}
                    model={node.model as ModelDTO}
                    parentModel={flatHierarchy.find((n) => n.model.uuid === node.parentId)?.model as ModelDTO}
                    submitClicked={submitClicked}
                  />
                </>
              }
            />
          ) : (
            <ModelCard
              {...tid('model-card', node.model.uuid, parentId || '')}
              style={isCutBranch ? {opacity: 0.2} : styleForNonCutBranch()}
              model={node.model}
              onClick={selectableNode ? () => onSelect(node as ModelFlatNode<ModelDTO>) : undefined}
              current={current}
              hideDetailLink
            />
          )
        }>
        {(node.model.uuid !== ''
          ? [
              ...flatHierarchy.filter(
                (n) => n.originalParentId === node.model.uuid && n.parentId !== n.originalParentId,
              ),
              ...flatHierarchy.filter((n) => n.parentId === node.model.uuid),
            ]
          : []
        ).map((n) => renderNode(n, node.model.uuid, selectableBranch, isCutBranch))}
      </TreeNode>
    );
  };

  return (
    <Suspense>
      <div className={styles.contentContainer}>
        <div className="headlineBlue">
          {isModelInHierarchy && possibleParentsCount === 1 && (
            <>
              The only one possible parent has been selected.
              <br />
            </>
          )}
          {(possibleParentsCount > 1 || (possibleParentsCount > 0 && !isModelInHierarchy)) && (
            <>
              Please select parent model entry. Your new entry will be linked to this
              <br />
              Lineage. You can not modify ancestry tree itself.
            </>
          )}
          {possibleParentsCount === 0 && !isModelInHierarchy && (
            <>
              There is no parent you can select.
              <br />
              This model entry is at top level.
            </>
          )}
        </div>
      </div>

      <div className={styles.contentContainer}>
        <WarningText
          md={8}
          text="Please mind, that you should only place this model entry in correct version order. You can not change the
            other lineage connections of this ancestry from here. In order to do that if you have permissions you can
            edit each model entry individually."
        />
      </div>
      <br />
      <br />

      {flatHierarchy[0]?.model && (
        <div style={{width: '100%', overflow: 'auto', paddingBottom: '5rem'}}>
          <Tree
            lineWidth="3px"
            lineBorderRadius="10px"
            lineColor="#0062ff"
            label={
              flatHierarchy[0].model.uuid === model.uuid ? (
                <ModelCardExpanded
                  model={flatHierarchy[0].model as ModelDTO}
                  hideDetailLink
                  current={true}
                  hideLeftSection
                  Content={
                    <>
                      <br />
                      <ModelCardVersionForm
                        files={files}
                        onChange={onChange}
                        model={flatHierarchy[0].model as ModelDTO}
                        submitClicked={submitClicked}
                      />
                    </>
                  }
                />
              ) : (
                <ModelCard
                  model={flatHierarchy[0].model}
                  onClick={
                    flatHierarchy[0].model.uuid !== model.uuid && canBeParentOfModel(flatHierarchy[0].model, model)
                      ? () => onSelect(flatHierarchy[0] as ModelFlatNode<ModelDTO>)
                      : undefined
                  }
                  current={flatHierarchy[0].model.uuid === model.uuid}
                  hideDetailLink
                  {...tid('model-card', flatHierarchy[0].model.uuid, '')}
                />
              )
            }>
            {(flatHierarchy[0].model.uuid !== ''
              ? [
                  ...flatHierarchy.filter(
                    (n) => n.originalParentId === flatHierarchy[0].model.uuid && n.parentId !== n.originalParentId,
                  ),
                  ...flatHierarchy.filter((n) => n.parentId === flatHierarchy[0].model.uuid),
                ]
              : []
            ).map((n) => renderNode(n, flatHierarchy[0].model.uuid, flatHierarchy[0].model.uuid !== model.uuid))}
          </Tree>
        </div>
      )}
    </Suspense>
  );
};

export default ParentSelection;
