import {AppDataAction, AppDataActions} from './types';
import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {ApplicationSettingsDTO, VersionInfoDTO} from 'hemwb-api';

export const appDataInitialState = {
  settings: buildInitialAsyncState<ApplicationSettingsDTO>(),
  version: buildInitialAsyncState<VersionInfoDTO>(),
};

export type AppDataState = typeof appDataInitialState;

export const appDataReducer = (state = appDataInitialState, action: AppDataAction): AppDataState => {
  switch (action.type) {
    case AppDataActions.LOAD_SETTINGS:
      return {
        ...state,
        settings: reduceAsyncAction(action),
      };

    case AppDataActions.LOAD_VERSION:
      return {
        ...state,
        version: reduceAsyncAction(action),
      };
  }
  return state;
};
