import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {useScenarioExecuteSideBarModal} from './useScenarioExecuteSideBarModal';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {canExecuteInstance} from '../../../../../permissions/usePermissions';
import {PlayOutline16} from '@carbon/icons-react';

const ScenarioExecuteActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {ModalComponent, openModal, buttonRef} = useScenarioExecuteSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_EXECUTE)}
        permission={canExecuteInstance(props.model, props.subModel, props.scenario)}
        onClick={openModal}
        icon={<PlayOutline16 />}
        text="Execute"
        disabled={!!buttonRef}
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioExecuteActionMenuButton;
