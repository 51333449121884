import React, {useCallback, useEffect, useRef} from 'react';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import GridItem from '../../../../core/Form/GridItem';
import {tid} from '../../../../../testUtils';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {ScenarioDTO} from 'hemwb-api';
import RadioControl from '../../../../core/Form/RadioControl';
import debounce from 'debounce-promise';
import {isScenarioFinished, scenarioNameExists} from '../../../../../store/scenario';
import {useDispatch} from 'react-redux';

export type AnalysisProgressSaveFormValues = {
  name: string;
  action: 'create' | 'update';
};

type VisualizationAnalysisProgressSaveFormProps = {
  modelUuid: string;
  executionSetupId: number;
  scenario?: ScenarioDTO;
  onSubmit: (values: AnalysisProgressSaveFormValues) => void;
  onCancel: () => void;
  submitting: boolean;
};

const VisualizationAnalysisProgressSaveForm: React.FC<VisualizationAnalysisProgressSaveFormProps> = ({
  modelUuid,
  executionSetupId,
  scenario,
  submitting,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const canUpdate = scenario && !isScenarioFinished(scenario.status);
  const defaultAction = canUpdate ? 'update' : 'create';

  const nameExistsCacheRef = useRef<any>({});
  const formRef = useRef<FormGroup>();

  const debouncedScenarioNameExists = debounce((name: string, action: string) => {
    const cacheKey = `${action}__${name}`;
    if (!nameExistsCacheRef.current[cacheKey]) {
      nameExistsCacheRef.current[cacheKey] = scenarioNameExists(dispatch, {
        name,
        modelUuid,
        executionSetupId,
        ...(action === 'create' ? {} : {scenarioId: scenario!.id}),
      });
    }
    return nameExistsCacheRef.current[cacheKey];
  }, 1000);

  const validateNameUnique = async (control: AbstractControl) => {
    const action = formRef.current?.value['action'] || defaultAction;
    const name = control.value;
    return !(name === scenario?.name && action === 'update') && (await debouncedScenarioNameExists(name, action))
      ? {isUnique: true}
      : null;
  };

  const form = useForm(
    new FormGroup({
      name: new FormControl(
        scenario && canUpdate ? scenario.name : '',
        [Validators.required, Validators.maxLength(200)],
        validateNameUnique,
      ),
      action: new FormControl(defaultAction),
    }),
  );

  useEffect(() => {
    formRef.current = form;

    const updateNameValidity = () => {
      setTimeout(() => {
        form.controls.name.updateValueAndValidity();
      }, 10);
    };
    form.controls.action.valueChanges.subscribe(updateNameValidity);
    return () => {
      form.controls.action.valueChanges.unsubscribe(updateNameValidity);
    };
  }, [form]);

  const getOptionLabel = useCallback((value: string) => {
    return value === 'create' ? 'Save as new' : 'Update current';
  }, []);

  return (
    <>
      <Form
        FieldGroupProps={{strict: false}}
        style={{marginLeft: 0}}
        className=""
        group={form}
        onSubmit={onSubmit}
        render={() => {
          return (
            <>
              <GridItem>
                <RadioControl
                  disabled={!canUpdate}
                  name="action"
                  options={['create', 'update']}
                  getOptionLabel={getOptionLabel}></RadioControl>
              </GridItem>
              <GridItem>
                <TextFieldControl
                  label="Name"
                  name="name"
                  TextFieldProps={{
                    inputProps: {
                      ...tid('input', 'name'),
                    },
                  }}
                />
              </GridItem>
              <br />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_PROGRESS, 'cancel')} />
                <SubmitButton active={submitting} {...tid(VISUALIZATION_ACTION_MENU_IDS.SAVE_PROGRESS, 'submit')}>
                  Save
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default VisualizationAnalysisProgressSaveForm;
