import {
  VisualizationResults,
  VisualizationResultsElements,
  VisualizationResultsOptions,
} from '../../../../store/visualization';
import {ChartTabularData} from '@carbon/charts/interfaces/model';
import {StringHashMap} from '../../../../commonTypes';

export const processResults = (results: VisualizationResults): VisualizationResults => {
  const {options, elements} = results;

  return {
    options,
    elements: elements.map((element) => {
      if (element.element === VisualizationResultsElements.CHART) {
        element.options.legend = {
          ...element.options.legend,
          truncation: {numCharacter: 30},
        };
        return {
          ...element,
          options: {
            height: '400px',
            ...getChartsColors(element.data, options),
            ...element.options,
          },
        };
      }
      return element;
    }),
  };
};

const colors = {
  teal60: '#00857C',
  pastelBlue60: '#1D74B5',
  red60: '#D1303E',
  lemon60: '#8E6A00',
  violet60: '#8745F5',
  lime60: '#607815',
  purple40: '#F484BB',
  vistaRich40: '#86A6FF',
  teal40: '#55B8A3',
  pastelBlue40: '#69B8F7',
  red40: '#FF8388',
  lemon40: '#C9A434',
  violet40: '#B49AF7',
  lime40: '#92B631',
};
const defaultChartsColors = Object.values(colors);

const getChartsColors = (data: ChartTabularData, resultsOptions: VisualizationResultsOptions = {}): object => {
  const colors = resultsOptions.chart?.colors || defaultChartsColors;
  const colorMap = data.reduce((acc, val) => {
    const {group} = val;
    if (group && !acc[group]) {
      acc[group] = true;
    }
    return acc;
  }, {});

  return {
    color: {
      scale: Object.keys(colorMap).reduce((acc, val, index) => {
        const color = colors[index] || defaultChartsColors[index];

        if (color) {
          acc[val] = color;
        }

        return acc;
      }, ({} as any) as StringHashMap),
    },
  };
};
