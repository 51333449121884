import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {getErrorText, getVisibleErrors} from './utils';
import {FormControl, FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup} from '@material-ui/core';
import {tid} from '../../../testUtils';

type RadioControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label?: React.ReactNode;
  inputProps?: any;
  classes?: object;
  plural?: boolean;
  required?: boolean;
  disabled?: boolean;
  options: any[];
  getOptionLabel?: (option: any) => string;
  customErrorMessages?: object;
};

const RadioControl: React.FC<RadioControlProps> = ({
  label,
  plural,
  required,
  disabled = false,
  inputProps,
  options,
  getOptionLabel = (option) => option,
  customErrorMessages = {},
  ...FieldControlProps
}) => {
  return (
    <FieldControl
      {...FieldControlProps}
      render={(renderProps) => {
        const {handler} = renderProps;
        const {onChange, ...restHandlers} = handler();
        const visibleErrors = getVisibleErrors(renderProps);
        const hasVisibleErrors = Object.keys(visibleErrors).length > 0;

        return (
          <FormControl
            fullWidth
            component="fieldset"
            error={hasVisibleErrors}
            hiddenLabel={true}
            className="MuiFormControl-hem">
            {label && (
              <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
                {label}
              </InputLabel>
            )}
            <br />
            <RadioGroup
              {...restHandlers}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange((event.target as HTMLInputElement).value);
              }}>
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option}
                    control={
                      <Radio
                        value={option}
                        color="default"
                        disabled={disabled || restHandlers.disabled}
                        inputProps={{...(tid('radio-option', option) as {}), ...inputProps}}
                      />
                    }
                    label={
                      <>
                        {getOptionLabel(option)}
                        {hasVisibleErrors && options.length === 1 && (
                          <FormHelperText>
                            {getErrorText(visibleErrors, String(label), plural, customErrorMessages)}
                          </FormHelperText>
                        )}
                      </>
                    }
                  />
                );
              })}
            </RadioGroup>
            {hasVisibleErrors && options.length !== 1 && (
              <FormHelperText>{getErrorText(visibleErrors, String(label), plural, customErrorMessages)}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default RadioControl;
