import React, {useCallback, useMemo, useState} from 'react';
import ScenarioAdd from '../../../scenario/common/ScenarioAdd/ScenarioAdd';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import styles from '../VisualizationScenarioAction.module.scss';

export const useScenarioAddDialog = (scenarioGridRef: any) => {
  const [openProps, setOpenProps] = useState<SubModelActionProps>();
  const handleOnCancel = useCallback(() => {
    setOpenProps(undefined);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setOpenProps(undefined);
    scenarioGridRef.current.reload();
  }, [scenarioGridRef]);

  const Component = useMemo(() => {
    return !openProps ? null : (
      <DialogGeneric open={true} title="Add Instance" childrenAsText={false} includesDialogActions={true}>
        <div className={styles.dialog}>
          <ScenarioAdd
            onSuccess={handleOnSuccess}
            onCancel={handleOnCancel}
            model={openProps?.model}
            subModel={openProps.subModel}
          />
        </div>
      </DialogGeneric>
    );
  }, [openProps, handleOnCancel, handleOnSuccess]);

  return {
    open: setOpenProps,
    Component,
  };
};
