import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {ScenarioGroupActions} from './types';
import {
  requestScenarioGroupCreate,
  requestScenarioGroupDelete,
  requestScenarioGroupDetail,
  requestScenarioGroupList,
  requestScenarioGroupUpdate,
} from './api';

const scenarioGroupLoadListActionsCreator = buildAsyncActionsCreator(ScenarioGroupActions.LIST);
export const scenarioGroupLoadList = createAsyncActionExecutor(
  scenarioGroupLoadListActionsCreator,
  requestScenarioGroupList,
);

const scenarioGroupLoadDetailActionsCreator = buildAsyncActionsCreator(ScenarioGroupActions.DETAIL);
export const scenarioGroupLoadDetail = createAsyncActionExecutor(
  scenarioGroupLoadDetailActionsCreator,
  requestScenarioGroupDetail,
);

const scenarioGroupCreateActionsCreator = buildAsyncActionsCreator(ScenarioGroupActions.CREATE);
export const scenarioGroupCreate = createAsyncActionExecutor(
  scenarioGroupCreateActionsCreator,
  requestScenarioGroupCreate,
);

const scenarioGroupUpdateActionsCreator = buildAsyncActionsCreator(ScenarioGroupActions.UPDATE);
export const scenarioGroupUpdate = createAsyncActionExecutor(
  scenarioGroupUpdateActionsCreator,
  requestScenarioGroupUpdate,
);

const scenarioGroupDeleteActionsCreator = buildAsyncActionsCreator(ScenarioGroupActions.DELETE);
export const scenarioGroupDelete = createAsyncActionExecutor(
  scenarioGroupDeleteActionsCreator,
  requestScenarioGroupDelete,
);
