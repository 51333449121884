import React from 'react';
import {Card, CardHeader} from '@material-ui/core';
import clsx from 'clsx';
import styles from './ModelCardCollapsed.module.scss';
import {Networking_03 as Networking03} from '@carbon/pictograms-react';

const ModelCardRestricted: React.FC = () => {
  return (
    <Card className={clsx('card', styles.container)}>
      <CardHeader
        disableTypography
        avatar={<Networking03 className="icon32" />}
        title={
          <>
            <div className="primary">Restricted access</div>
          </>
        }
      />
    </Card>
  );
};

export default ModelCardRestricted;
