import React, {useCallback, useMemo, useState} from 'react';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {modelPublish} from '../../../../../store/model';
import {IssueSeverity, useValidateModel} from '../../../../../store/model/validator';
import {useMounted} from '../../../../../hooks/useMounted';
import {useDispatch} from 'react-redux';
import {trackModelPublished} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {Launch16, Launch32} from '@carbon/icons-react';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../testUtils';
import WarningText from '../../../../core/WarningText/WarningText';
import ModelIssueList from '../../../modelUpload/StepNameConfirmation/ModelIssueList';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';

export type ModelPublishProps = {
  model: ModelDTO;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const stepToMenuButton = [
  MODEL_ACTION_MENU_IDS.EDIT_FILES,
  MODEL_ACTION_MENU_IDS.EDIT_METADATA,
  MODEL_ACTION_MENU_IDS.EDIT_LINEAGE,
  MODEL_ACTION_MENU_IDS.EDIT_PERMISSIONS,
  MODEL_ACTION_MENU_IDS.EDIT_SUFFIX,
];

const ModelPublish: React.FC<ModelPublishProps> = ({model, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const issues = useValidateModel(model);
  const errors = useMemo(() => {
    return issues?.filter((i) => i.severity >= IssueSeverity.ERROR_PUBLISH);
  }, [issues]);

  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    modelPublish(dispatch, model.uuid)
      .then(() => {
        dispatch(messageAdd('Model has been published.', MessageTypes.SUCCESS));
        trackModelPublished(model);
        onSuccess?.();
      })
      .catch(() => null)
      .finally(() => {
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, mounted, model, onSuccess]);

  const handleClickOnIssue = useCallback(
    (step: number) => {
      const el = document.querySelector(`[data-testid='${stepToMenuButton[step]}']`) as HTMLDivElement;
      if (el) {
        el.click();
        onCancel?.();
      }
    },
    [onCancel],
  );

  return (
    <>
      <ActionHeader label="Confirm Publishing" Icon={<Launch32 />}>
        This operation can&apos;t be undone
      </ActionHeader>

      <SuspenseNull>
        {issues && (
          <ModelIssueList issues={issues} setActiveStep={handleClickOnIssue} errorLevel={IssueSeverity.ERROR_PUBLISH} />
        )}
      </SuspenseNull>

      {errors && errors.length > 0 && (
        <WarningText text="Red issues are required to fix in order to be able to proceed. You can navigate to the desired step by clicking on any issue." />
      )}

      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} {...tid('dialog-confirm-publish-btn-cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          active={submitting}
          disabled={errors?.length !== 0}
          endIcon={<Launch16 />}
          {...tid('dialog-confirm-publish-btn-submit')}>
          Publish
        </SubmitButton>
      </div>
    </>
  );
};

export default ModelPublish;
