import {
  LoggedInUserDTO,
  ModelDTO,
  ScenarioDTO,
  ScenarioStatus,
  UserModelAccess,
  UserScenarioAccess,
  UserType,
} from 'hemwb-api';

export const isModelScenarioUserOnly = (loggedInUser: LoggedInUserDTO, model?: ModelDTO) =>
  model && model.userAccess === UserModelAccess.SCENARIOMODELER;
export const isModelCalibrationUserOnly = (loggedInUser: LoggedInUserDTO, model?: ModelDTO) =>
  model && model.userAccess === UserModelAccess.CALIBRATORMODELER;
export const isModelOwnerOrStewardOrSuperUser = (loggedInUser: LoggedInUserDTO, model?: ModelDTO) =>
  model &&
  (loggedInUser.userType === UserType.SUPERUSER ||
    model.userAccess === UserModelAccess.OWNER ||
    model.userAccess === UserModelAccess.STEWARD);

export const isInstanceExecutable = (instance: ScenarioDTO): boolean =>
  instance.status === ScenarioStatus.DRAFT ||
  instance.status === ScenarioStatus.FAILED ||
  instance.status === ScenarioStatus.CANCELLED;

export const isUserInstanceCreator = (instance: ScenarioDTO): boolean =>
  instance.userAccess === UserScenarioAccess.CREATOR;
