import React, {useCallback, useEffect, useState} from 'react';
import {Button, CircularProgress, Tab, Tabs} from '@material-ui/core';
import {useModelFileTreeTHLoader, useModelFileTreeLoader} from '../../../../../store/modelFile';
import styles from './ModelOverview.module.scss';
import {trackModelViewed} from '../../../../../tracking/tracking';
import ModelTextOverview from '../ModelTextOverview/ModelTextOverview';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {usePrefillModelListGridStateWithModel} from '../../../../../store/grid';
import {Maximize16, Minimize16, PageFirst16} from '@carbon/icons-react';
import clsx from 'clsx';
import ModelTextOverviewCondensed from '../ModelTextOverview/ModelTextOverviewCondensed';
import ModelOverviewRequestAccess from './ModelOverviewRequestAccess';
import ModelVersionControl from '../ModelVersionControl/ModelVersionControl';
import {useDispatch, useSelector} from 'react-redux';
import {VersionControlPage, versionControlPage, versionControlPageSelector} from '../../../../../store/versionControl';
import {useModelDetail, isModelExternal, isModelTheorem} from '../../../../../store/model';
import {tid} from '../../../../../testUtils';
import {canViewModelFolders} from '../../../../../permissions/usePermissions';
import ModelDownloadActionMenuButton from '../ModelDownload/ModelDownloadActionMenuButton';
import {ModelDTO} from 'hemwb-api';
import ModelFileRequestAccessDialog from './ModelFileRequestAccessDialog';
import FileTreeTH from '../../../../core/FileTree/FileTreeTH';
import ModelFileManageAccessDialog from './ModelFileManageAccessDialog';
import ModelFileManageOwnerAccessDialog from './ModelFileManageOwnerAccessDialog';
import FileTree from '../../../../core/FileTree/FileTree';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {requestSecurityAPI} from '../../../../../store/auth/api';
import {useAuth} from '../../../../../store/auth';

export type ModelOverviewProps = {
  model: ModelDTO;
  expanded: boolean;
  selectedTab?: string;
};

const ModelOverview: React.FC<ModelOverviewProps> = ({model, expanded, selectedTab}) => {
  const dispatch = useDispatch();
  const modelDetail = useModelDetail(model) || undefined;

  useEffect(() => {
    trackModelViewed(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.uuid]);

  const [tab, setTab] = useState(selectedTab ? selectedTab : 'text');
  const [nestedTab, setNestedTab] = useState('theorem');
  const externalModel = isModelExternal(model);
  const theoremModel = isModelTheorem(model);
  const versionControlCurrentPage = useSelector(versionControlPageSelector);
  const [requestDialog, setRequestDialog] = useState<boolean>(false);
  const [accessDialog, setAccessDialog] = useState<boolean>(false);
  const [ownershipDialog, setOwnershipDialog] = useState<boolean>(false);
  const [fileUuid, setFileUuid] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const {auth} = useAuth();
  const user = auth.loggedInUser;

  useEffect(() => {
    if (versionControlCurrentPage !== VersionControlPage.LINEAGE) {
      setTab('version');
    }
  }, [versionControlCurrentPage]);

  const {data: files, load: loadFiles} = useModelFileTreeTHLoader(model.uuid);
  const {data: supportingDocs, load: loadDocs} = useModelFileTreeLoader(model.uuid);

  useEffect(() => {
    if (tab === 'folder') {
      loadFiles();
      loadDocs();
    }
  }, [tab, loadFiles, loadDocs]);

  const handleRequestDialog = useCallback(
    (uuid: string, name: string) => {
      if (user && uuid) {
        requestSecurityAPI({securityAPIDTO: {isid: user.id, modelUUID: model.uuid, fileUuid: uuid}})
          .then(() => {
            setRequestDialog(true);
            setFileUuid(uuid);
            setFileName(name);
          })
          .catch((error) => {
            if (error.status === 500) {
              dispatch(
                messageAdd(
                  `Please request access to this file in THEOREM. Please have the User <a href="https://collaboration.merck.com/sites/THEOREM/Lists/FAQ%20%20THEOREM/DispForm.aspx?ID=53&e=mvH1Me">follow these steps</a> to request access in THEOREM.`,
                  MessageTypes.ERROR,
                ),
              );
            }
          });
      }
    },
    [dispatch, user, model],
  );

  const handleAccessDialog = useCallback((uuid: string, name: string) => {
    setAccessDialog(true);
    setFileUuid(uuid);
    setFileName(name);
  }, []);

  const handleOwnershipDialog = useCallback((uuid: string, name: string) => {
    setOwnershipDialog(true);
    setFileUuid(uuid);
    setFileName(name);
  }, []);

  const prefillModelGridState = usePrefillModelListGridStateWithModel();

  return (
    <div style={expanded ? {paddingLeft: '6vw', minHeight: '100%'} : {}} className="greyTheme">
      <ModelOverviewRequestAccess model={model} />

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ModelDownloadActionMenuButton model={modelDetail} fromSideBar={false} />
      </div>

      <div className={styles.navBar} {...tid('model-overview-nav')}>
        <Tabs
          value={tab}
          onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
          indicatorColor="primary"
          textColor="inherit"
          className="tabs-secondary">
          <Tab value="text" label="Text View" />
          <Tab value="folder" label="Folder View" disabled={!canViewModelFolders(model)} />
          <Tab value="version" label="Version Control" />
        </Tabs>

        <div className={styles.expandCollapseButton}>
          {tab === 'version' && versionControlCurrentPage !== VersionControlPage.LINEAGE && (
            <Button
              variant="contained"
              onClick={() => dispatch(versionControlPage(VersionControlPage.LINEAGE))}
              color="default"
              size="large"
              className={clsx('whiteButton noBoxShadow')}
              startIcon={<PageFirst16 />}>
              Back to node view
            </Button>
          )}
          &nbsp;
          <Button
            variant="contained"
            component={Link}
            to={expanded ? Routes.MODEL_LIST : getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}
            onClick={() => !expanded || prefillModelGridState(model)}
            color="default"
            size="large"
            className={clsx('whiteButton noBoxShadow')}
            startIcon={expanded ? <Minimize16 /> : <Maximize16 />}>
            {expanded ? 'Minimize' : 'Maximize'}
          </Button>
        </div>
      </div>

      <div style={{minHeight: '55rem', paddingTop: '1rem'}} {...tid('model-overview-content')}>
        {tab === 'text' &&
          (expanded ? (
            <ModelTextOverview model={modelDetail || model} />
          ) : (
            <ModelTextOverviewCondensed model={modelDetail || model} />
          ))}
        {tab === 'folder' && (
          <>
            <div className={styles.navBar}>
              <Tabs
                value={nestedTab}
                onChange={(event: React.ChangeEvent<{}>, newTab: string) => setNestedTab(newTab)}
                indicatorColor="primary"
                textColor="inherit"
                className="tabs-secondary">
                <Tab value="theorem" label="THEOREM Files" />
                <Tab value="supporting" label="Supporting Documents" />
              </Tabs>
            </div>
            <div style={{margin: '6rem  0  6rem 0'}}>
              {files ? (
                <>
                  {nestedTab === 'theorem' && (
                    <>
                      <FileTreeTH
                        externalModel={externalModel}
                        files={files}
                        handleRequestDialog={handleRequestDialog}
                        handleManageDialog={handleAccessDialog}
                        handleOwnershipDialog={handleOwnershipDialog}
                      />
                      <ModelFileRequestAccessDialog
                        model={model}
                        fileUuid={fileUuid}
                        fileName={fileName}
                        open={requestDialog}
                        onSuccess={() => {
                          setRequestDialog(false);
                        }}
                        onCancel={() => {
                          setRequestDialog(false);
                        }}
                      />
                      {fileUuid && accessDialog && (
                        <ModelFileManageAccessDialog
                          model={model}
                          fileUuid={fileUuid}
                          fileName={fileName}
                          open={accessDialog}
                          onSuccess={() => {
                            setAccessDialog(false);
                          }}
                          onCancel={() => {
                            setAccessDialog(false);
                          }}
                        />
                      )}
                      {ownershipDialog && (
                        <ModelFileManageOwnerAccessDialog
                          model={model}
                          file={files.specification.find((f) => f.uuid === fileUuid)}
                          fileUuid={fileUuid}
                          fileName={fileName}
                          open={ownershipDialog}
                          onSuccess={() => {
                            setOwnershipDialog(false);
                          }}
                          onCancel={() => {
                            setOwnershipDialog(false);
                          }}
                        />
                      )}
                      {model.metadata['model_development_plan'] && (
                        <>
                          <strong>MDP: </strong>
                          <a href={model.metadata['model_development_plan']} target="_blank" rel="noopener noreferrer">
                            {model.metadata['model_development_plan']}
                          </a>
                        </>
                      )}
                    </>
                  )}
                  {nestedTab === 'supporting' && supportingDocs && (
                    <FileTree files={supportingDocs} theoremModel={theoremModel} />
                  )}
                </>
              ) : (
                <CircularProgress />
              )}
            </div>
          </>
        )}
        {tab === 'version' && <ModelVersionControl model={model} />}
      </div>
    </div>
  );
};

export default ModelOverview;
