import {
  MetadataApi,
  MetadataOptionType,
  ModelMetadataOptionDTO,
  ModelMetadataOptionsDTOOptions,
  GetMetadataTypeCountRequest,
} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
import {ArrayStringHashMap} from '../../commonTypes';

export const modelMetadataOptionEnums: {[key in keyof ModelMetadataOptionsDTOOptions]: string} = {
  products: MetadataOptionType.NAMEOFPRODUCT,
  diseases: MetadataOptionType.NAMEOFDISEASE,
  countries: MetadataOptionType.COUNTRY,
  modelTypes: MetadataOptionType.MODELTYPE,
  therapeuticAreas: MetadataOptionType.THERAPEUTICAREA,
  studyNumbers: MetadataOptionType.STUDYNUMBER,
  stagesOfModelDevelopment: MetadataOptionType.STAGEOFMODELDEVELOPMENT,
  fileTag: MetadataOptionType.FILETAG,
};

const modelMetadataApi = new MetadataApi(configuration);

export const requestModelMetadataOptions = () => modelMetadataApi.getModelMetadataOptions({});

export const requestModelMetadataOptionsCount = (requestParameters: GetMetadataTypeCountRequest) =>
  modelMetadataApi.getMetadataTypeCount(requestParameters);

//eslint-disable-next-line
export const requestModelMetadataAddOption = (
  type: string,
  value: string,
  metadata: ModelMetadataOptionDTO['metadata'],
) =>
  modelMetadataApi.saveMetadataOption({
    // @ts-ignore
    modelMetadataOptionDTO: {type: modelMetadataOptionEnums[type], value, metadata},
  });

export const requestModelMetadataDeleteOption = (type: string, value: string) =>
  modelMetadataApi.deleteMetadataOption({
    // @ts-ignore
    type: modelMetadataOptionEnums[type],
    value,
  });

export const requestModelMetadataOptionsUsage = (type: string): Promise<ArrayStringHashMap> =>
  // @ts-ignore
  modelMetadataApi.getUsageOfOption({type: modelMetadataOptionEnums[type]});

export const requestModelMetadataUpdateOption = (
  type: string,
  prevValue: string,
  newValue: string,
  metadata: ModelMetadataOptionDTO['metadata'],
  retired?: true,
) =>
  modelMetadataApi.updateMetadataOption({
    // @ts-ignore
    type: modelMetadataOptionEnums[type],
    value: prevValue,
    modelMetadataOptionDTO: {
      // @ts-ignore
      type: modelMetadataOptionEnums[type],
      value: newValue,
      metadata,
      ...(retired ? {retired} : {}),
    },
  });
