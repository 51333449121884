import {GridState} from '../../../store/grid';
import {useCallback, useEffect, useRef, useState} from 'react';

const getKey = (gridState: GridState<any>): string =>
  `${gridState.filter}${gridState.search}${gridState.order}${gridState.orderBy}${gridState.page}${gridState.rowsPerPage}`;

export const useGridLoader = <T, F = null>(
  gridState: GridState<F>,
  loader: (gridState: GridState<F>) => Promise<T>,
) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);

  const [counter, setCounter] = useState(0);
  const reload = useCallback(() => setCounter((num) => ++num % 10_000), []);

  const key = getKey(gridState);

  const keyRef = useRef(key);
  useEffect(() => {
    keyRef.current = key;
  }, [key]);

  useEffect(() => {
    setLoading(true);
    Promise.all([Promise.resolve(key), loader(gridState)]).then(([k, d]) => {
      if (k === keyRef.current) {
        setData(d);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, loader, counter]);

  return {
    data,
    loading,
    reload,
  };
};
