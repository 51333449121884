import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ModelDownload from './ModelDownload';
import {ModelDetailActionProps} from '../../../actionMenuTypes';
import {isModelTheorem} from '../../../../../store/model';
import ModelDownloadTH from './ModelDownloadTH';

export const useModelDownloadSideBarModal = (props: Partial<ModelDetailActionProps>) => {
  const {model, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);
  const theoremModel = isModelTheorem(model);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '60rem', paddingLeft: '3rem'}}>
            {!!buttonRef && theoremModel ? (
              <ModelDownloadTH model={model} onSuccess={handleSuccess} onCancel={closeModal} />
            ) : (
              <ModelDownload model={model} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, handleSuccess, closeModal, theoremModel]);

  return {
    openModal,
    ModalComponent,
  };
};
