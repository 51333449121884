import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ModelShareSideBarModal from './ModelShareSideBarModal';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ModelActionProps} from '../../../actionMenuTypes';
import {Share16} from '@carbon/icons-react';

const ModelShareSideBarModalMenuButton: React.FC<Partial<ModelActionProps>> = ({model, onSuccess}) => {
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const Modal = useMemo(() => {
    return (
      model && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '80rem'}}>
            {!!buttonRef && <ModelShareSideBarModal model={model} onSuccess={handleSuccess} onCancel={closeModal} />}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, handleSuccess, model]);

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.SHARE)}
        disabled={!model}
        onClick={openModal}
        icon={<Share16 />}
        text="Share Model Entry"
      />
      {Modal}
    </>
  );
};

export default ModelShareSideBarModalMenuButton;
