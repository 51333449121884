import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {useModelCloneSideBarModal} from './useModelCloneSideBarModal';
import {canCloneModel} from '../../../../../permissions/usePermissions';
import {ModelActionProps} from '../../../actionMenuTypes';
import {Copy16} from '@carbon/icons-react';

const ModelCloneActionMenuButton: React.FC<Partial<ModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelCloneSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid('btn', 'model-clone')}
        permission={canCloneModel(props.model)}
        onClick={openModal}
        icon={<Copy16 />}
        text="Add New Model Version"
      />
      {ModalComponent}
    </>
  );
};

export default ModelCloneActionMenuButton;
