import React, {useCallback, useMemo} from 'react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ScenarioAccept from './ScenarioAccept';

const useScenarioAcceptSideBarModal = (props: Partial<ScenarioActionProps>) => {
  const {model, subModel, scenario, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(scenario?.id);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      subModel &&
      scenario && (
        <SideBarModal
          height={300}
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <ScenarioAccept
                model={model}
                subModel={subModel}
                scenario={scenario}
                onSuccess={handleSuccess}
                onCancel={closeModal}
              />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, subModel, scenario, closeModal, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};

export default useScenarioAcceptSideBarModal;
