import React, {useCallback, useState} from 'react';
import {Chip, Grid} from '@material-ui/core';
import FileInputButton from '../../../../core/Buttons/FileInputButton';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {visualizationTestResults} from '../../../../../store/visualization';
import {readFileAsync} from '../../../../../utils/readFileAsync';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {visualizationResultsValidator} from '../../../../../store/visualization/visualizationResultsValidator';
import {trackTestVisualization} from '../../../../../tracking/tracking';
import {ModelActionProps} from '../../../actionMenuTypes';
import {processResults} from '../../step3/processResults';

const VisualizationTestResults: React.FC<ModelActionProps> = ({model, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [file, setFile] = useState<File>();

  const handleSubmit = useCallback(async () => {
    if (!file) {
      return;
    }
    const rawData = await readFileAsync(file);
    const [results, error] = visualizationResultsValidator(rawData as string);
    if (results) {
      trackTestVisualization(model);
      dispatch(visualizationTestResults(processResults(results)));
      history.push({
        pathname: getUrlRoute(Routes.VISUALIZATION_STEP_3, {modelUuid: model.uuid, scenarioId: 0}),
        hash: Date.now().toString(),
      });
      onSuccess?.();
      return;
    }

    dispatch(messageAdd(error || `Invalid data structure. `, MessageTypes.WARNING));
    return;
  }, [dispatch, history, file, model, onSuccess]);

  return (
    <>
      <h2>Test Data Visualization</h2>
      <p>Upload a JSON file to see how your data visualization looks like.</p>

      <Grid container direction="row" alignItems="center">
        <Grid item>
          <FileInputButton label="Add file" onChange={(f) => setFile(f[0])} accept=".json" />
        </Grid>
        <Grid item>
          &nbsp;&nbsp;&nbsp;
          {file && <Chip label={file.name} onDelete={() => setFile(undefined)} variant="outlined" />}
        </Grid>
      </Grid>

      <br />
      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} {...tid(VISUALIZATION_ACTION_MENU_IDS.TEST_VISUALIZATION_RESULTS, 'cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          {...tid(VISUALIZATION_ACTION_MENU_IDS.TEST_VISUALIZATION_RESULTS, 'submit')}>
          Show
        </SubmitButton>
      </div>
    </>
  );
};

export default VisualizationTestResults;
