import React from 'react';
import {FormArray, FormGroup} from 'react-reactive-form';
import {TextFieldControl} from '../../../Form';

type SelectorColumnPrimitiveProps = {
  selectedColumn?: number;
  selectedRow?: number;
  columns: FormArray;
};

export const SelectorColumnPrimitive: React.FC<SelectorColumnPrimitiveProps> = ({
  selectedColumn,
  selectedRow,
  columns,
}) => {
  if (selectedColumn === undefined) {
    return null;
  }

  const column = columns.controls[selectedColumn] as FormGroup;
  const cellTextControl =
    selectedRow === undefined ? null : (column.get('cellText') as FormArray).controls[selectedRow];

  return (
    <>
      <TextFieldControl label="Key" control={column.get('key')} name="key" />
      <TextFieldControl label="Text" control={column.get('text')} name="text" />
      {cellTextControl && <TextFieldControl label="Cell Text" control={cellTextControl} name="cellText" />}
    </>
  );
};
