import React, {useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {Tree} from 'react-organizational-chart';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import styles from './ModelVersionControl.module.scss';
import {ModelCardDynamicView} from '../ModelCard/ModelCardDynamicView';
import ModelVersionControlLineageDynamicView from './ModelVersionControlLineageDynamicView';

type ModelVersionControlLineageForDynamicViewProps = {
  model: ModelDTO;
  dynamicView: boolean;
  handleSelection: () => void;
};

const primarySelectionStyle = {
  border: '2px solid #0062ff',
  // boxShadow: '0 4px 20px 0 #0062ff80',
};

const ModelVersionControlLineageForDynamicView: React.FC<ModelVersionControlLineageForDynamicViewProps> = ({
  model,
  dynamicView,
  handleSelection,
}) => {
  const [displayHierarchy, setDisplayHierarchy] = useState<boolean>(false);
  const handleOnClick = () => {
    handleSelection();
    setDisplayHierarchy(!displayHierarchy);
  };
  const toggleVersions = () => {
    setDisplayHierarchy(!displayHierarchy);
  };

  return (
    <SuspenseNull>
      <>
        {displayHierarchy ? (
          <ModelVersionControlLineageDynamicView toggleVersions={toggleVersions} model={model} />
        ) : (
          <div className={dynamicView ? styles.dynamicContainer : styles.container}>
            <Tree
              lineWidth="3px"
              lineBorderRadius="10px"
              lineColor="#0062ff"
              label={
                <ModelCardDynamicView
                  style={primarySelectionStyle}
                  model={model}
                  onClick={handleOnClick}
                  toggleVersioning={toggleVersions}
                  expanded={true}
                />
              }></Tree>
          </div>
        )}
      </>
    </SuspenseNull>
  );
};

export default ModelVersionControlLineageForDynamicView;
