import {Column, DisplayReturnType, GetColumnValueReturnType, EnhancedRow} from './types';
import {Order} from '../../../store/grid';

export function getColumnValue<R>(column: Column<R>, row: R): GetColumnValueReturnType {
  if (typeof column['value'] === 'function') {
    const value = column.value(row);
    if (value === null || value === undefined) {
      return '';
    }
    return value;
  }

  // @ts-ignore
  if (typeof row[column.id] === 'string' || typeof row[column.id] === 'number' || row[column.id] instanceof Date) {
    // @ts-ignore
    return row[column.id];
  }
  return '';
}

export function getColumnDisplay<R>(column: Column<R>, row: R): DisplayReturnType {
  if (typeof column['display'] === 'function') {
    return column.display(row);
  }

  const value = getColumnValue(column, row);

  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }

  if (value instanceof Date) {
    return value.toLocaleString();
  }

  return value;
}

export function desc<R>(a: EnhancedRow<R>, b: EnhancedRow<R>, orderByColumn: Column<R>) {
  if (orderByColumn.comparator) {
    return orderByColumn.comparator(a.row, b.row);
  }
  if (getColumnValue(orderByColumn, b.row) < getColumnValue(orderByColumn, a.row)) {
    return -1;
  }
  if (getColumnValue(orderByColumn, b.row) > getColumnValue(orderByColumn, a.row)) {
    return 1;
  }
  return 0;
}

export function stableSort<E>(array: E[], cmp: (a: E, b: E) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [E, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting<R>(
  order: Order,
  orderByColumn: Column<R>,
): (a: EnhancedRow<R>, b: EnhancedRow<R>) => number {
  return order === Order.DESC ? (a, b) => desc(a, b, orderByColumn) : (a, b) => -desc(a, b, orderByColumn);
}
