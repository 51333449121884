import {AsyncActionState} from '../asyncUtilsTypes';
import {requestVeevaMdpList, requestVeevaVeap} from './api';
import {VeevaAction, VeevaActions} from './types';
import {reduceAsyncAction} from '../asyncUtils';
import {Await} from '../types';

export type VeevaState = {
  veaps: {
    [key: string]: AsyncActionState<Await<ReturnType<typeof requestVeevaVeap>>, Parameters<typeof requestVeevaVeap>>;
  };
  mdps: {
    [key: string]: AsyncActionState<
      Await<ReturnType<typeof requestVeevaMdpList>>,
      Parameters<typeof requestVeevaMdpList>
    >;
  };
};

export const veevaInitialState = {
  veaps: {},
  mdps: {},
};

export const veevaReducer = (state = veevaInitialState, action: VeevaAction): VeevaState => {
  switch (action.type) {
    case VeevaActions.REQUEST_VEAP: {
      const [searchedTerm] = action.params;
      return {
        ...state,
        veaps: {
          ...state.veaps,
          [searchedTerm]: reduceAsyncAction(action),
        },
      };
    }

    case VeevaActions.REQUEST_MDP_LIST: {
      const [searchedTerm] = action.params;
      return {
        ...state,
        mdps: {
          ...state.mdps,
          [searchedTerm]: reduceAsyncAction(action),
        },
      };
    }
  }
  return state;
};
