import React, {useCallback, useMemo} from 'react';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ScenarioAdd from './ScenarioAdd';
import {ExecutionSetupType} from 'hemwb-api';

const useScenarioAddSideBarModal = (props: Partial<SubModelActionProps>) => {
  const {model, subModel, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(subModel?.id);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      subModel && (
        <SideBarModal
          height={subModel.type === ExecutionSetupType.SCENARIO ? 450 : 400}
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <ScenarioAdd model={model} subModel={subModel} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, subModel, closeModal, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};

export default useScenarioAddSideBarModal;
