import {VisualizationResultsTableElement} from '../../../../../store/visualization';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import Table from '../../step3/VisualizationElement/Table';
import domToImage from 'dom-to-image-more-patch';

export const useTableRenderer = () => {
  const [element, setElement] = useState<VisualizationResultsTableElement>();
  const divRef = useRef<HTMLDivElement>(null);

  const getTableImageData = useCallback(async (element: VisualizationResultsTableElement): Promise<string> => {
    setElement(element);
    await new Promise((r) => setTimeout(r, 300));

    if (!divRef.current) {
      return Promise.reject('Table is not rendered.');
    }

    const {width, height} = divRef.current.getBoundingClientRect();

    return domToImage
      .toPng(divRef.current, {
        quality: 1,
        width: width + 15,
        height: height + 15,
        filter: (node) => {
          // @ts-ignore
          if (node.classList && node.classList.contains('MuiButtonBase-root')) {
            return false;
          }

          return true;
        },
      })
      .then((dataUrl) => {
        setElement(undefined);
        return dataUrl;
      });
  }, []);

  const TableComponent = useMemo(() => {
    return element
      ? createPortal(
          <div
            ref={divRef}
            style={{
              position: 'absolute',
              top: 0,
              zIndex: -1,
              minWidth: 800,
              minHeight: 400,
              // backgroundColor: '#fff',
            }}>
            {/* @ts-ignore*/}
            <Table {...element} id="" />
          </div>,
          document.body,
        )
      : null;
  }, [element]);

  return {getTableImageData, TableComponent};
};
