import {LoggedInUserDTO, ModelDetailDTO, ModelDTO, UserModelAccess, UserType} from 'hemwb-api';
import {PERMISSION, Permission} from './types';
import {
  isModelDraft,
  isModelWeb,
  isModelExternal,
  isModelPublished,
  isModelRetired,
  isStaticMetadataValid,
  isModelTheorem,
} from '../store/model';

//HELPERS

const checkModelOwnerOrStewardAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER ||
      model.userAccess === UserModelAccess.OWNER ||
      model.userAccess === UserModelAccess.STEWARD)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkModelOwnerOrSuperUserAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER || model.userAccess === UserModelAccess.OWNER)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkModelOwnerOrStewardOrViewerAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER ||
      model.userAccess === UserModelAccess.OWNER ||
      model.userAccess === UserModelAccess.STEWARD ||
      model.userAccess === UserModelAccess.VIEWER ||
      model.userAccess === UserModelAccess.VESTLEAD)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkTHModelEditAccessOwnerOrStewardAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    isModelTheorem(model) &&
    ((loggedInUser.userType !== UserType.SUPERUSER && model.userAccess !== UserModelAccess.VESTLEAD) ||
      model.userAccess === UserModelAccess.OWNER ||
      model.userAccess === UserModelAccess.STEWARD)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkCalibrationModelerOrScenarioModelerAccess = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
): Permission => {
  if (
    model &&
    !isModelExternal(model) &&
    (model.userAccess === UserModelAccess.CALIBRATORMODELER || model.userAccess === UserModelAccess.SCENARIOMODELER)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkDisplayViewerAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (model && !isModelExternal(model) && model.userAccess === UserModelAccess.DISPLAYVIEWER) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const checkExternalModelAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER ||
      model.userAccess === UserModelAccess.OWNER ||
      model.userAccess === UserModelAccess.STEWARD ||
      model.userAccess === UserModelAccess.VIEWER ||
      model.userAccess === UserModelAccess.VESTLEAD)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

//PERMISSIONS

export const canViewModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    checkModelOwnerOrStewardOrViewerAccess(loggedInUser, model) ||
    checkExternalModelAccess(loggedInUser, model) ||
    checkCalibrationModelerOrScenarioModelerAccess(loggedInUser, model) ||
    checkDisplayViewerAccess(loggedInUser, model)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

export const canViewModelFolders = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    checkModelOwnerOrStewardOrViewerAccess(loggedInUser, model) ||
    checkExternalModelAccess(loggedInUser, model) ||
    checkDisplayViewerAccess(loggedInUser, model)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

export const canSaveAnalysis = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (checkDisplayViewerAccess(loggedInUser, model)) {
    return PERMISSION.DISALLOWED;
  }
  return PERMISSION.ALLOWED;
};

export const canCloneModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (checkModelOwnerOrStewardOrViewerAccess(loggedInUser, model) && !isModelExternal(model)) {
    if (isModelWeb(model) || (!isModelWeb(model) && !isModelDraft(model))) {
      return PERMISSION.ALLOWED;
    } else {
      return PERMISSION.DISALLOWED;
    }
  }
  return PERMISSION.DISALLOWED;
};

export const canViewModelFiles = canViewModel;

export const canEditModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    (checkModelOwnerOrStewardAccess(loggedInUser, model) || checkExternalModelAccess(loggedInUser, model)) &&
    !isModelRetired(model)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

export const canDeleteDraftModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (checkModelOwnerOrStewardAccess(loggedInUser, model) && isModelDraft(model) && !isModelExternal(model)) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canPublishModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    checkModelOwnerOrStewardAccess(loggedInUser, model) &&
    isModelDraft(model) &&
    isStaticMetadataValid(model!) &&
    !isModelExternal(model)
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

export const canRetireModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (checkModelOwnerOrStewardAccess(loggedInUser, model) && isModelPublished(model) && !isModelExternal(model)) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

export const canDownloadModelFiles = (loggedInUser: LoggedInUserDTO, model?: ModelDetailDTO): Permission => {
  if (isModelRetired(model)) {
    if (checkModelOwnerOrSuperUserAccess(loggedInUser, model)) {
      return PERMISSION.ALLOWED;
    } else {
      return PERMISSION.DISALLOWED;
    }
  }
  if (
    (checkModelOwnerOrStewardOrViewerAccess(loggedInUser, model) || checkExternalModelAccess(loggedInUser, model)) &&
    model!.hasFiles
  ) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

const accessCalibrationManagementRoles = [
  UserModelAccess.OWNER,
  UserModelAccess.VIEWER,
  UserModelAccess.CALIBRATORMODELER,
  UserModelAccess.STEWARD,
  UserModelAccess.VESTLEAD,
];
export const canAccessCalibrationManagement = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    model.metadata['type'] === 'Mathematica' &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER || accessCalibrationManagementRoles.includes(model.userAccess!))
  ) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

const accessScenarioManagementRoles = [...accessCalibrationManagementRoles, UserModelAccess.SCENARIOMODELER];
export const canAccessScenarioManagement = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    model.metadata['type'] !== 'ExcelNative' &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER || accessScenarioManagementRoles.includes(model.userAccess!))
  ) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canAccessDisplayManagement = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    model.metadata['type'] === 'Mathematica' &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER || accessScenarioManagementRoles.includes(model.userAccess!))
  ) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

const accessVisualizationRoles = [...accessScenarioManagementRoles, UserModelAccess.DISPLAYVIEWER];
export const canAccessVisualization = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (
    model &&
    model.metadata['type'] === 'Mathematica' &&
    !isModelExternal(model) &&
    (loggedInUser.userType === UserType.SUPERUSER || accessVisualizationRoles.includes(model.userAccess!))
  ) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canTestVisualization = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (!model || (model && isModelExternal(model)) || checkDisplayViewerAccess(loggedInUser, model)) {
    return PERMISSION.DISALLOWED;
  }

  if (loggedInUser.userType === UserType.SUPERUSER || model.userAccess === UserModelAccess.OWNER) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.FORBIDDEN;
};

export const canEditTHModelAccess = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (checkTHModelEditAccessOwnerOrStewardAccess(loggedInUser, model)) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};
