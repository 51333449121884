import React from 'react';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {canEditModel} from '../../../../../../permissions/usePermissions';
import {useModelEditLineageSideBarModal} from './useModelEditLineageSideBarModal';
import {DirectLink16} from '@carbon/icons-react';

const ModelEditLineageActionMenuButton: React.FC<Partial<ModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelEditLineageSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.EDIT_LINEAGE)}
        permission={canEditModel(props.model)}
        onClick={openModal}
        icon={<DirectLink16 />}
        text="Lineage"
      />
      {ModalComponent}
    </>
  );
};

export default ModelEditLineageActionMenuButton;
