import React, {useCallback, useEffect, useState} from 'react';
import {ModelDTO, UserAccessDTO} from 'hemwb-api';
import {AutocompleteControl, AutocompleteItem, Form, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {useDispatch} from 'react-redux';
import {tid} from '../../../../../testUtils';
import {Collaborate16, Password24, Share16} from '@carbon/icons-react';
import {Grid, IconButton} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import {UserCard} from '../../../../core/UserCard/UserCard';
import CloseIcon from '@material-ui/icons/Close';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {fullName} from '../../../../../store/user/utils';
import {approveFileAccessRequest} from '../../../../../store/model';
import {requestUserListForFileAccess} from '../../../../../store/fileShareAccess/api';
import ModelFileShareRemoveDialog from './ModelFileShareRemoveDialog';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useMounted} from '../../../../../hooks/useMounted';
import {titleCase} from '../../../../../store/scenario';

type ModelFileManageAccessTemplateProps = {
  model: ModelDTO;
  fileUuid: string;
  fileName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  displayInDialog: boolean;
};

const ModelFileManageAccessTemplate: React.FC<ModelFileManageAccessTemplateProps> = ({
  model,
  onSuccess,
  onCancel,
  fileUuid,
  fileName,
  displayInDialog,
}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const [modelUsers, setModelUsers] = useState<UserAccessDTO[]>();
  const [sharedUsers, setSharedUsers] = useState<UserAccessDTO[]>();
  const [removeUser, setRemoveUser] = useState<UserAccessDTO>();
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  const renderUserItem = (user: UserAccessDTO, name: string) => (
    <AutocompleteItem
      isid={user.id}
      fileUuid={fileUuid}
      modelUuid={model.uuid}
      theoremModel={true}
      handleSecurityAPI={handleSecurityAPIError}
      primary={fullName(user)}
      secondary={user.email || ''}
      {...tid('input-option', name, user.id)}
    />
  );

  const form = useForm(
    new FormGroup({
      users: new FormControl([], Validators.required),
    }),
    {
      users: [],
    },
  );

  const handleDeleteUser = useCallback(
    (user: UserAccessDTO) => {
      form.controls.users.setValue(form.controls.users.value.filter((u: UserAccessDTO) => u.id !== user.id));
      form.controls.users.markAsTouched();
    },
    [form],
  );

  const handleSecurityAPIError = useCallback(() => {
    form.controls.users.setValue([]);
  }, [form]);

  const addSharedUsers = useCallback((users: UserAccessDTO[]) => {
    setSharedUsers(users.filter((user) => user.userAccess));
    const filteredUsers = users.filter((user) => !user.userAccess);
    setModelUsers([...filteredUsers]);
  }, []);

  const requestConfirmation = useCallback((user: UserAccessDTO) => {
    setRemoveUser(user);
    setDeleteDialog(true);
  }, []);

  const handleStopSharing = useCallback(
    (user: UserAccessDTO) => {
      setDeleteDialog(false);
      if (sharedUsers) setSharedUsers([...sharedUsers.filter((u: UserAccessDTO) => u.id !== user.id)]);
      requestUserListForFileAccess(model.uuid, fileUuid).then((response) => {
        addSharedUsers(response);
      });
      onSuccess?.();
    },
    [onSuccess, sharedUsers, addSharedUsers, model, fileUuid],
  );

  useEffect(() => {
    if (form) {
      const onlyLastUser = () => {
        if (form.controls.users.value.length > 1) {
          form.controls.users.setValue([form.controls.users.value[form.controls.users.value.length - 1]]);
        }
      };

      form.controls.users.valueChanges.subscribe(onlyLastUser);
    }
    if (!modelUsers && model.uuid && fileUuid) {
      requestUserListForFileAccess(model.uuid, fileUuid).then((response) => {
        addSharedUsers(response);
      });
    }
  }, [form, model, modelUsers, fileUuid, addSharedUsers]);

  const handleSubmit = useCallback(() => {
    if (!form.valid) {
      return;
    }

    setSubmitting(true);
    approveFileAccessRequest(model.uuid, fileUuid, form.controls.users.value[0].id, fileName).then(() => {
      dispatch(messageAdd('File Access has been granted', MessageTypes.SUCCESS));
      form.controls.users.setValue([]);
      requestUserListForFileAccess(model.uuid, fileUuid).then((response) => {
        addSharedUsers(response);
      });
      mounted.current && setSubmitting(false);
    });
    onSuccess?.();
  }, [form, dispatch, mounted, onSuccess, fileUuid, addSharedUsers, model, fileName]);

  if (!modelUsers) {
    return <SuspenseNull />;
  }

  return (
    <>
      <Form
        group={form}
        onSubmit={handleSubmit}
        {...tid('file-share-form')}
        render={({valid}) => {
          return (
            <>
              <Grid container direction="row" alignItems="flex-start" justify="space-between">
                <Grid container item direction="column" style={{padding: displayInDialog ? '0 3rem 0 0' : '3rem'}}>
                  <GridItem>
                    <div style={{display: displayInDialog ? 'none' : 'block'}}>
                      <div style={{position: 'absolute', left: '18px', top: '125px'}}>
                        <Password24 />
                      </div>
                      <div style={{fontSize: '12px', fontWeight: 'bold'}}>File Access</div>
                      <div style={{fontSize: '10px', wordBreak: 'break-word'}}>{fileName}</div>
                    </div>
                  </GridItem>

                  <GridItem>
                    <AutocompleteControl
                      name="users"
                      label="Select a user to give access to the file"
                      AutocompleteProps={{
                        disableCloseOnSelect: false,
                        multiple: true,
                        options: modelUsers || [],
                        renderTags: () => null,
                        getOptionSelected: (option: UserAccessDTO, value: UserAccessDTO) => {
                          return option.id === value.id;
                        },
                        getOptionLabel: (option: UserAccessDTO) => option.firstName || '',
                        renderOption: (option: UserAccessDTO) => renderUserItem(option, option.id || ''),
                      }}
                    />
                  </GridItem>

                  <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                    {form.controls.users.value && form.controls.users.value[0] && (
                      <UserCard
                        style={{marginBottom: '0.8rem'}}
                        label={
                          form.controls.users.value[0].ownershipType
                            ? titleCase(form.controls.users.value[0].ownershipType.toString())
                            : 'Model Viewer'
                        }
                        user={form.controls.users.value[0]}
                        key={form.controls.users.value[0].id}
                        actions={
                          <>
                            <IconButton
                              style={{float: 'right'}}
                              aria-label="Remove"
                              size="small"
                              onClick={() => handleDeleteUser(form.controls.users.value[0])}
                              {...tid('user-card-remove', form.controls.users.value[0].id)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <div className="buttonsContainer">
                <CancelButton
                  onClick={onCancel}
                  disabled={submitting}
                  {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'cancel')}
                />
                <SubmitButton
                  active={submitting}
                  disabled={!valid}
                  endIcon={<Share16 />}
                  {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'submit')}>
                  Share File Access
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
      <div>
        <div>
          <Collaborate16 />
          &nbsp;&nbsp;
          <span style={{fontWeight: 'bold'}}>Shared with</span>
        </div>
        <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
          {sharedUsers?.map((user: UserAccessDTO) => (
            <UserCard
              style={{marginBottom: '0.8rem'}}
              label={user.ownershipType ? titleCase(user.ownershipType?.toString()) : 'Model Viewer'}
              user={user}
              key={user.id}
              actions={
                <>
                  <IconButton
                    style={{float: 'right'}}
                    aria-label="Remove"
                    size="small"
                    onClick={() => requestConfirmation(user)}
                    {...tid('shared-user-card-remove', user.id)}>
                    <CloseIcon />
                  </IconButton>
                </>
              }
            />
          ))}
        </Grid>
      </div>
      {removeUser && (
        <ModelFileShareRemoveDialog
          open={deleteDialog}
          user={removeUser}
          fileUuid={fileUuid}
          fileName={fileName}
          onCancel={() => setDeleteDialog(false)}
          onSuccess={() => handleStopSharing(removeUser)}
        />
      )}
    </>
  );
};

export default ModelFileManageAccessTemplate;
