import {AuthAction, AuthActions} from './types';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {LoggedInUserDTO} from 'hemwb-api';

export const authInitialState = {
  isFetching: false,
  loggedInUser: null as LoggedInUserDTO | null,
  loggedOutUser: null as LoggedInUserDTO | null,
};

export type AuthState = typeof authInitialState;

export const authReducer = (state = authInitialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActions.AUTH_GET_CURRENT_USER:
      switch (action.phase) {
        case AsyncActionPhase.START:
          return {
            ...state,
            isFetching: true,
          };
        case AsyncActionPhase.SUCCESS:
          return {
            ...state,
            isFetching: false,
            loggedInUser: action.value,
          };
        case AsyncActionPhase.FAILURE:
          return {
            ...state,
            isFetching: false,
            loggedInUser: null,
          };
      }
      break;

    case AuthActions.AUTH_LOGOUT:
      switch (action.phase) {
        case AsyncActionPhase.START:
          const [hard] = action.params;
          return {
            ...state,
            loggedOutUser: hard ? null : state.loggedInUser,
            isFetching: true,
          };

        case AsyncActionPhase.SUCCESS:
          return {
            ...state,
            isFetching: false,
            loggedInUser: null,
          };
        case AsyncActionPhase.FAILURE:
          return {
            ...state,
            isFetching: false,
          };
      }
      break;

    case AuthActions.AUTH_SET_LOGOUT:
      return {
        ...state,
        isFetching: false,
        loggedInUser: null,
        loggedOutUser: state.loggedInUser,
      };
  }

  return state;
};
