import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {
  requestExecutionSetup,
  requestExecutionSetupCreate,
  requestExecutionSetupDelete,
  requestExecutionSetupList,
  requestExecutionSetupUpdate,
} from './api';

export enum ExecutionSetupActions {
  REQUEST_LIST = 'EXECUTION_SETUP/REQUEST_LIST',
  REQUEST_SINGLE = 'EXECUTION_SETUP/REQUEST_SINGLE',
  REQUEST_CREATE = 'EXECUTION_SETUP/REQUEST_CREATE',
  REQUEST_UPDATE = 'EXECUTION_SETUP/REQUEST_UPDATE',
  REQUEST_DELETE = 'EXECUTION_SETUP/REQUEST_DELETE',
}

export type ExecutionSetupAction =
  | AsyncAction<
      ExecutionSetupActions.REQUEST_LIST,
      Await<ReturnType<typeof requestExecutionSetupList>>,
      Parameters<typeof requestExecutionSetupList>
    >
  | AsyncAction<
      ExecutionSetupActions.REQUEST_SINGLE,
      Await<ReturnType<typeof requestExecutionSetup>>,
      Parameters<typeof requestExecutionSetup>
    >
  | AsyncAction<
      ExecutionSetupActions.REQUEST_CREATE,
      Await<ReturnType<typeof requestExecutionSetupCreate>>,
      Parameters<typeof requestExecutionSetupCreate>
    >
  | AsyncAction<
      ExecutionSetupActions.REQUEST_UPDATE,
      Await<ReturnType<typeof requestExecutionSetupUpdate>>,
      Parameters<typeof requestExecutionSetupUpdate>
    >
  | AsyncAction<
      ExecutionSetupActions.REQUEST_DELETE,
      Await<ReturnType<typeof requestExecutionSetupDelete>>,
      Parameters<typeof requestExecutionSetupDelete>
    >;
