/* eslint-disable @typescript-eslint/camelcase */
import React, {useEffect, useRef} from 'react';
import {ModelDTO} from 'hemwb-api';
import WarningText from '../../../../core/WarningText/WarningText';
import {FormGroup} from 'react-reactive-form';
import {Values} from '../ModelEdit/ModelEditMetadata/constants';
import {mapValuesToModel} from '../ModelEdit/ModelEditMetadata/utils';
import ModelEditMetadataForm from '../ModelEdit/ModelEditMetadata/ModelEditMetadataForm';
import {useModelFileTreeLoader} from '../../../../../store/modelFile';

type StepMetadataProps = {
  model: ModelDTO;
  clonedFrom: ModelDTO;
  onStatusChange: (status: boolean) => void;
  onValueChange: (value: ModelDTO) => void;
};

const StepMetadata: React.FC<StepMetadataProps> = ({model, clonedFrom, onStatusChange, onValueChange}) => {
  const formObjectRef = useRef<FormGroup>();

  const {data: fileTree, load: loadFileTree} = useModelFileTreeLoader(model.uuid, ['Parameter'], undefined);
  useEffect(() => {
    loadFileTree();
  }, [loadFileTree]);

  useEffect(() => {
    if (formObjectRef.current) {
      const handleFormChange = (values: Values) => onValueChange(mapValuesToModel(values, model));
      formObjectRef.current.valueChanges.subscribe(handleFormChange);
      const handleStatusChange = (status: string) => {
        if (status) {
          onStatusChange(status === 'VALID');
        } else {
          onStatusChange(status === undefined);
        }
      };
      formObjectRef.current.statusChanges.subscribe(handleStatusChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObjectRef]);

  return (
    <>
      <WarningText text="Update the model version number and note any changes." />
      <ModelEditMetadataForm
        isEdit={true}
        model={model}
        files={fileTree ? fileTree : []}
        clonedFrom={clonedFrom}
        formObjectRef={formObjectRef}
        buttons={() => null}
        onSubmit={Promise.resolve}
        validateOnInit
      />
    </>
  );
};

export default StepMetadata;
