import React, {useEffect, useMemo, useState} from 'react';
import {isStaticMetadataValid, isModelExternal} from '../../../../../../store/model';
import {useModelHierarchyRootsByMetadata} from '../../../../../../store/modelLinks';
import {ModelDetailDTO, ModelDTO} from 'hemwb-api';
import {styles} from './styles';
import clsx from 'clsx';
import WarningText from '../../../../../core/WarningText/WarningText';
import {Button, CircularProgress, Grid} from '@material-ui/core';
import {tid} from '../../../../../../testUtils';
import {Link16} from '@carbon/icons-react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import LineageRoot from './LineageRoot';
import {isValidLineageMetadata} from '../../../../../../store/modelLinks/utils';

type RootSelectionProps = {
  model: ModelDTO;
  currentRootId: string;
  onSelected: (hierarchyRootId: string) => void;
  onNewHierarchy: () => void;
  onCancel: () => void;
};

const RootSelection: React.FC<RootSelectionProps> = ({model, currentRootId, onSelected, onNewHierarchy, onCancel}) => {
  const modelUuid = model.uuid || '';
  const validMetadata = useMemo(() => {
    if (modelUuid === '') {
      return false;
    }
    return isStaticMetadataValid(model as ModelDetailDTO);
  }, [model, modelUuid]);
  const [invalidFetchCriteria, setInvalidFetchCriteria] = useState(false);

  const roots = useModelHierarchyRootsByMetadata(model);
  const externalModel = isModelExternal(model);
  useEffect(() => {
    setInvalidFetchCriteria(!isValidLineageMetadata(model));
  }, [model]);

  return (
    <>
      <div className={styles.contentContainer}>
        <div className="headlineBlue">
          {roots?.length ? (
            <>
              Based on metadata the following ancestry possibilities were found,
              <br />
              please choose which one is valid for this model, or create new lineage.
            </>
          ) : (
            <>Based on metadata no ancestry possibilities were found.</>
          )}
        </div>
      </div>

      <div className={clsx('wrapRelative', styles.warningContainer)}>
        {!validMetadata && (
          <div className={styles.contentContainer}>
            <WarningText
              md={6}
              text={
                <>
                  If the metadata is not properly filled in (or you have skipped this step) this will result in no
                  ancestry possibilities.
                  <br />
                  Please update all metadata to show valid options.
                </>
              }
            />
          </div>
        )}
      </div>

      <div className={styles.contentContainer}>
        <Grid container direction="row" alignItems="flex-start" justify="space-between">
          {roots === null && !invalidFetchCriteria && (
            <Grid item className={styles.gridItem} sm={6}>
              <CircularProgress />
            </Grid>
          )}
          {roots?.map((rootModel) => (
            <Grid item key={rootModel.uuid} className={styles.gridItem} sm={12} md="auto">
              <Button
                disabled={externalModel}
                {...tid('btn-select', rootModel.uuid)}
                color="primary"
                variant="contained"
                onClick={() => onSelected(rootModel.uuid)}
                className={styles.selectButton}
                endIcon={<CheckCircleOutlineIcon />}>
                Select {rootModel.uuid === currentRootId && '(current)'}
              </Button>
              <LineageRoot model={rootModel} activeModelUuid={modelUuid} onClick={() => onSelected(rootModel.uuid)} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.buttonsContainer}>
        <CancelButton onClick={onCancel} {...tid('btn-cancel-lineage')} />
        <SubmitButton
          disabled={externalModel}
          onClick={onNewHierarchy}
          variant={!invalidFetchCriteria ? 'contained' : 'outlined'}
          endIcon={<Link16 />}
          {...tid('btn-new-lineage')}>
          New Lineage
        </SubmitButton>
      </div>
    </>
  );
};

export default RootSelection;
