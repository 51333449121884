import React, {useMemo} from 'react';
import {useAuth} from '../../store/auth';
import {Link} from '@material-ui/core';
import {boxLayoutStyles as styles} from '../layout/BoxLayout';
import SnackbarContainer from '../layout/common/SnackbarContainer';
import {Form, TextFieldControl, useForm} from '../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {tid} from '../../testUtils';
import {useSelector} from '../../store/rootReducer';
import {appDataSettingsSelector} from '../../store/appData';
import {buildRequestAccessLink} from './utils';
import {AsyncActionButton} from '../core/AsyncActionButton';

export const LoginForm: React.FC = () => {
  const form = useForm(
    new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    }),
  );

  const {login} = useAuth();
  const settings = useSelector(appDataSettingsSelector);
  const requestAccessLink = useMemo(() => buildRequestAccessLink(settings?.supportEmail), [settings]);

  return (
    <div>
      <div className={styles.heading}>
        <h2 className={styles.title}>
          Log In to <strong>Model Repository</strong>
        </h2>
        <div className={styles.subtitle}>Enter your isid and password</div>
      </div>
      <Form
        group={form}
        onSubmit={login}
        render={({submitting}) => (
          <>
            <TextFieldControl
              name="username"
              label="Username"
              required
              TextFieldProps={{
                id: 'username',
                inputProps: tid('input-username'),
              }}
            />

            <TextFieldControl
              name="password"
              label="Password"
              required
              TextFieldProps={{
                id: 'password',
                type: 'password',
                inputProps: tid('input-password'),
              }}
            />

            <div className={styles.links}>
              <Link href={requestAccessLink} target="_blank">
                Request access
              </Link>
              {settings && (
                <Link href={settings.privacyPolicyUrl} target="_blank">
                  Privacy policy
                </Link>
              )}
            </div>

            <AsyncActionButton
              data-testid="btn-submit"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!form.valid}
              active={submitting}
              endIcon={<SupervisorAccountIcon style={{marginLeft: '3rem'}} />}
              classes={{root: styles.loginButton}}>
              Log in
            </AsyncActionButton>
          </>
        )}
        autoComplete="off"
        data-testid="form-login"
      />
      <SnackbarContainer />
    </div>
  );
};
