import React, {useState} from 'react';
import {IncidentListDTO} from 'hemwb-api';
import {useIncidentDetail} from '../../../../../../store/incident';
import {Tab, Tabs} from '@material-ui/core';
import styles from './IncidentOverview.module.scss';
import IncidentTextOverview from '../incidentTextOverview/IncidentTextOverview';
import IncidentWorkOverview from '../incidentWorkOverview/IncidentWorkOverview';

export type IncidentOverviewProps = {
  incident: IncidentListDTO;
};

const IncidentOverview: React.FC<IncidentOverviewProps> = ({incident}) => {
  const incidentDetail = useIncidentDetail(incident) || undefined;
  const [tab, setTab] = useState('details');

  return (
    <div>
      <div className={styles.navBar}>
        <Tabs
          value={tab}
          onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
          indicatorColor="primary"
          textColor="inherit"
          className="tabs-incident">
          <Tab value="details" label="Details" />
          <Tab value="work" label="Worklog" />
        </Tabs>
      </div>
      <div>{tab === 'details' && <IncidentTextOverview incident={incidentDetail || incident} />}</div>
      <div>{tab === 'work' && <IncidentWorkOverview incident={incidentDetail || incident} />}</div>
    </div>
  );
};

export default IncidentOverview;
