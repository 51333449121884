import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import {SubModelActionProps} from '../../../actionMenuTypes';
import SubModelDelete from './SubModelDelete';

export const useSubModelDeleteSideBarModal = (props: Partial<SubModelActionProps>) => {
  const {model, subModel, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(subModel?.id);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      subModel && (
        <SideBarModal
          height={300}
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <SubModelDelete model={model} subModel={subModel} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, subModel, closeModal, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};
