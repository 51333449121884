import {RootState, useSelector} from '../rootReducer';
import {modelListSelector, modelEligibleListSelector, modelSelector, modelSelectorBuilder} from './selectors';
import {modelLoadDetail, modelLoadList, modelEligibleLoadList} from './actions';
import {ModelDetailDTO, ModelDTO} from 'hemwb-api';
import {
  useReduxLoader,
  useReduxManualLoader,
  useReduxManualLoaderWithParams,
  useSimpleReduxLoader,
} from '../useReduxLoader';
import {Dispatch} from 'redux';
import {useStore} from 'react-redux';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {isModelDTO} from './utils';

const detailSelector = (state: RootState) => modelSelector(state).detail;
const detailSelectorBuilder = (params: [string]) => (state: RootState) =>
  detailSelector(state)[params[0] || ''] || null;

const modelLoadDetailIgnoreNull = (dispatch: Dispatch, modelUuid: string | null) => {
  return modelUuid ? modelLoadDetail(dispatch, modelUuid) : Promise.resolve();
};
modelLoadDetailIgnoreNull.clear = modelLoadDetail.clear;

export const useModelDetail = (model: ModelDTO | string | null | undefined) => {
  const uuid = typeof model === 'string' ? model : model?.uuid || null;
  const loaded = useReduxLoader(detailSelectorBuilder, modelLoadDetailIgnoreNull, uuid);
  const modelInState = useSelector((state) => modelSelector(state).models[uuid || '']);

  return useMemo(() => {
    if (loaded && modelInState) {
      return modelInState as ModelDetailDTO;
    }
    return null;
  }, [loaded, modelInState]);
};

export const useModelDetailLoader = (modelUuid: string) => {
  return useReduxManualLoader(detailSelectorBuilder, modelLoadDetail, modelUuid);
};

export const useModelDetailLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(detailSelectorBuilder, modelLoadDetail) as (
    params: [string],
  ) => Promise<ModelDetailDTO>;
};

export const useModelLoaderWithParams = () => {
  const store = useStore<RootState>();
  const detailLoader = useModelDetailLoaderWithParams();

  return useCallback(
    (modelUuid: string): Promise<ModelDTO> => {
      const {models} = modelSelector(store.getState());
      const model = models[modelUuid] as any;
      if (model && model.name) {
        return Promise.resolve(model);
      }
      return detailLoader([modelUuid]);
    },
    [store, detailLoader],
  );
};

export const useModelList = (): ModelDTO[] | null => {
  const loaded = useSimpleReduxLoader(modelListSelector, modelLoadList);
  const models = useSelector((state) => modelSelector(state).models);

  return useMemo(() => {
    if (loaded) {
      return Object.values(models).filter(isModelDTO) as ModelDTO[];
    }
    return null;
  }, [loaded, models]);
};

export const useModelListBasedOnUserType = (externalUser: boolean | null): ModelDTO[] | null => {
  const loaded = useSimpleReduxLoader(modelListSelector, modelLoadList);
  const models = useSelector((state) => {
    if (externalUser) {
      return modelSelector(state).externalUserModels;
    } else {
      return modelSelector(state).models;
    }
  });

  return useMemo(() => {
    if (loaded) {
      return Object.values(models).filter(isModelDTO) as ModelDTO[];
    }
    return null;
  }, [loaded, models]);
};

export const useModelEligibleList = (): ModelDTO[] | null => {
  const loaded = useSimpleReduxLoader(modelEligibleListSelector, modelEligibleLoadList);
  const models = useSelector((state) => modelSelector(state).eligibleModels);

  return useMemo(() => {
    if (loaded) {
      return Object.values(models).filter(isModelDTO) as ModelDTO[];
    }
    return null;
  }, [loaded, models]);
};

export const useModels = () => {
  const loaded = useSimpleReduxLoader(modelListSelector, modelLoadList);
  const models = useSelector((state) => modelSelector(state).models);

  return useMemo(() => {
    if (loaded) {
      return models;
    }
    return null;
  }, [loaded, models]);
};

export const useModel = (uuid: string): ModelDTO | null => {
  const loadDetail = useModelDetailLoaderWithParams();
  const modelInState = useSelector(modelSelectorBuilder(uuid));

  const [output, setOutput] = useState<ModelDTO>();

  useEffect(() => {
    if (uuid) {
      if (modelInState && isModelDTO(modelInState)) {
        setOutput(modelInState);
      } else {
        loadDetail([uuid]);
      }
    } else {
      setOutput(undefined);
    }
  }, [loadDetail, modelInState, uuid]);

  return output || null;
};
