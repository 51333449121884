import React from 'react';
import {Button, Typography} from '@material-ui/core';
import ExternalUserRegistration from './ExternalUserRegistration';
import styles from './NewRegistrationPage.module.scss';
import clsx from 'clsx';
import {ReactComponent as IconLogo} from '../../assets/icons/logo.svg';

export const HomePage: React.FC = () => {
  const [homePage, setHomePage] = React.useState(true);
  return (
    <>
      {homePage && (
        <div>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <header className={clsx('noPrint', styles.header)}>
                <Typography variant="h6">
                  <div color="inherit" className={styles.title}>
                    <IconLogo className={styles.logo} />
                    <strong>Model Repository</strong>
                  </div>
                </Typography>
              </header>
              <div style={{textAlign: 'center', color: 'white'}}>
                <div style={{fontSize: '28px'}}>
                  <b>Welcome to Model Repository!</b>
                </div>
                <div style={{fontSize: '18px'}}>
                  <p style={{width: '100%'}}>
                    Model Repository is Merck and MSD&apos;s central place to document, access, share,
                    <br />
                    and execute health economic models
                  </p>
                </div>
                <div style={{fontSize: '85px'}}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHomePage(false);
                    }}
                    style={{backgroundColor: 'white'}}>
                    <strong>Submit Registration Request for Model Repository</strong>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!homePage && <ExternalUserRegistration />}
    </>
  );
};
