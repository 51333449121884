import React from 'react';
// @ts-ignore
import 'react-diff-view/style/index.css';
import FileDiff from './FileDiff';
import {DiffChange, DiffFile, DiffInput} from './types';
import ContainerDimensions from 'react-container-dimensions';

type GitDiffProps = {
  diff: DiffInput;
};

const compareChanges = (a: DiffChange, b: DiffChange) => {
  const aLine = a.lineNumber || a.newLineNumber || 0;
  const bLine = b.lineNumber || b.newLineNumber || 0;

  if (aLine < bLine) {
    return -1;
  } else if (aLine > bLine) {
    return 1;
  }
  return 0;
};

//Need to implement DiffInput should be GitDiffSummaryDTO from api-spec
const transformInput = (input: DiffInput): DiffFile[] => {
  return input.diffs.map((f) => {
    return {
      newEndingNewLine: true,
      newMode: '100644',
      newPath: f.destination.toString,
      newRevision: input.toHash,
      oldEndingNewLine: true,
      oldMode: '100644',
      oldPath: f.source.toString,
      oldRevision: input.fromHash || '',
      type: 'modify',
      hunks: f.hunks.map((h) => {
        return {
          content: `@@ -${h.sourceSpan},${h.sourceLine} +${h.destinationSpan},${h.destinationLine} @@`,
          isPlain: false,
          newLines: h.destinationLine,
          newStart: h.destinationSpan,
          oldLines: h.sourceLine,
          oldStart: h.sourceSpan,
          changes: h.segments
            .flatMap((s) => {
              return s.lines.map((l) => {
                let lp = {};
                switch (s.type) {
                  case 'REMOVED':
                    lp = {type: 'delete', isDelete: true, lineNumber: l.source};
                    break;
                  case 'ADDED':
                    lp = {type: 'insert', isInsert: true, lineNumber: l.destination};
                    break;
                  case 'CONTEXT':
                  default:
                    lp = {type: 'normal', isNormal: true, oldLineNumber: l.source, newLineNumber: l.destination};
                    break;
                }

                return {
                  content: l.line,
                  type: 'normal',
                  ...lp,
                };
              });
            })
            .sort(compareChanges),
        };
      }),
    };
  });
};

const GitDiff: React.FC<GitDiffProps> = ({diff}) => {
  const files = transformInput(diff);

  return (
    <ContainerDimensions>
      {({width}) => (
        <>
          {files.map((diff: DiffFile) => (
            <FileDiff
              key={diff.oldRevision + '-' + diff.newRevision}
              viewType={width > 1000 ? 'split' : 'unified'}
              {...diff}
            />
          ))}
        </>
      )}
    </ContainerDimensions>
  );
};

export default GitDiff;
