import {AsyncAction} from '../asyncUtilsTypes';
import {requestVeevaMdpList, requestVeevaVeap} from './api';
import {Await} from '../types';

export enum VeevaActions {
  REQUEST_VEAP = 'VEEVA/REQUEST_VEAP',
  REQUEST_MDP_LIST = 'VEEVA/REQUEST_MDP_LIST',
}

export type VeevaAction =
  | AsyncAction<
      VeevaActions.REQUEST_VEAP,
      Await<ReturnType<typeof requestVeevaVeap>>,
      Parameters<typeof requestVeevaVeap>
    >
  | AsyncAction<
      VeevaActions.REQUEST_MDP_LIST,
      Await<ReturnType<typeof requestVeevaMdpList>>,
      Parameters<typeof requestVeevaMdpList>
    >;
