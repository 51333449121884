import React from 'react';
import {FieldKey, getCommonFieldProps, textAreas} from './constants';
import {TextFieldControl} from '../../../../../core/Form';
import {tid} from '../../../../../../testUtils';

type MetadataTextFieldControlProps = {
  name: FieldKey;
  customErrorMessages?: object;
};

const MetadataTextFieldControl: React.FC<MetadataTextFieldControlProps> = ({name, customErrorMessages = {}}) => {
  return (
    <TextFieldControl
      customErrorMessages={customErrorMessages}
      {...getCommonFieldProps(name)}
      TextFieldProps={{
        multiline: !!textAreas[name],
        rows: 3,
        inputProps: {
          ...tid('input', name),
        },
      }}
    />
  );
};

export default React.memo(MetadataTextFieldControl);
