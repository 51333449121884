import {createContext} from 'react';
import {blankModel, ModelUploadFlow} from './types';
import {ModelIssue} from '../../../store/model/validator';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';

export type ModelUploadContextType = {
  flow: ModelUploadFlow;
  issues: ModelIssue[];
  model: ModelDTO;
  onModelChange: (model: ModelDTO) => void;
};

export const ModelUploadContext = createContext<ModelUploadContextType>({
  model: blankModel,
  flow: ModelUploadFlow.MANUAL_ENTRY,
  issues: [],
  onModelChange: () => undefined,
});
