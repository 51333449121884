import React from 'react';
import {DialogGeneric} from './../core/dialog/DialogGeneric';
import styles from './NewRegistrationPage.module.scss';

export type RegistrationConfirmationDialogProps = {
  open: boolean;
  handleCancel: () => void;
};

const RegistrationConfirmationDialog: React.FC<RegistrationConfirmationDialogProps> = ({open, handleCancel}) => {
  return (
    <DialogGeneric
      open={open}
      title={<span style={{fontSize: '22px'}}>Request Submitted Successfully!</span>}
      childrenAsText={false}
      onHeaderCloseButtonClick={handleCancel}
      PaperProps={{
        classes: {
          root: styles.dialogBox,
        },
      }}>
      <div style={{fontSize: '18px', marginTop: ' 1rem'}}>
        Thank you for submitting the request. Please wait for am emailed registration confirmation from Model Repository
        Team
      </div>
    </DialogGeneric>
  );
};

export default RegistrationConfirmationDialog;
