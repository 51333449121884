import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {ExamMode16} from '@carbon/icons-react';
import {useVisualizationTestResults} from './useVisualizationTestResults';
import {canTestVisualization} from '../../../../../permissions/usePermissions';
import {ModelActionProps} from '../../../actionMenuTypes';

const VisualizationTestResultsActionMenuButton: React.FC<Partial<ModelActionProps> & {scenarioInProgress: boolean}> = (
  props,
) => {
  const {model, scenarioInProgress} = props;
  const {ModalComponent, openModal} = useVisualizationTestResults(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.TEST_VISUALIZATION_RESULTS)}
        onClick={openModal}
        permission={canTestVisualization(model)}
        disabled={scenarioInProgress}
        icon={<ExamMode16 />}
        text="Test Visualization"
      />
      {ModalComponent}
    </>
  );
};

export default VisualizationTestResultsActionMenuButton;
