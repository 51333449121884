import React from 'react';
import {DialogProps} from '@material-ui/core/Dialog';
import {DialogGeneric, DialogGenericButton} from './DialogGeneric';
import {defaultSubmitButtonProps} from '../Buttons/SubmitButton';
import {defaultCancelButtonProps} from '../Buttons/CancelButton';

export type DialogConfirmationProps = DialogProps & {
  title?: string;
  text?: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
  onClickOk?: () => void;
};

const DialogConfirmation: React.FC<DialogConfirmationProps> = (props) => {
  const {title, text, onClickYes, onClickNo, onClickOk, ...dialogProps} = props;

  const buttons: DialogGenericButton[] = [];
  onClickNo &&
    buttons.push({
      ...defaultCancelButtonProps,
      key: 'no',
      onClick: onClickNo,
      color: 'primary',
      children: 'No',
    });
  onClickYes &&
    buttons.push({
      ...defaultSubmitButtonProps,
      key: 'yes',
      onClick: onClickYes,
      color: 'primary',
      autoFocus: true,
      children: 'Yes',
    });
  onClickOk &&
    buttons.push({
      ...defaultSubmitButtonProps,
      key: 'ok',
      onClick: onClickOk,
      color: 'primary',
      autoFocus: true,
      children: 'Ok',
    });

  return (
    <DialogGeneric {...dialogProps} title={title || 'Confirmation'} buttons={buttons}>
      {text}
    </DialogGeneric>
  );
};

export default DialogConfirmation;
