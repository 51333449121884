import React from 'react';
import {useSharedSideBarToggle} from '../../../../store/customSettings';
import SideBarButtonItem from '../../../layout/common/SideBarButtonItem';
import {Printer16, TableOfContents16} from '@carbon/icons-react';
import SideBar from '../../../layout/common/SideBar';
import {tid} from '../../../../testUtils';
import {List} from '@material-ui/core';
import SideBarLinkItem from '../../../layout/common/SideBarLinkItem';
import {getUrlRoute, Routes} from '../../../router/routes';
import {useLocation} from 'react-router';
import TestVisualizationActionMenuButton from '../common/VisualizationTestResults/VisualizationTestResultsActionMenuButton';
import VisualizationAnalysisSaveActionMenuButton from '../common/VisualizationAnalysisSave/VisualizationAnalysisSaveActionMenuButton';
import {ExecutionSetupDTO, ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import VisualizationShareLinkActionMenuButton from '../common/VisualizationShareLink/VisualizationShareLinkActionMenuButton';
import VisualizationCancelActionMenuButton from '../common/VisualizationCancel/VisualizationCancelActionMenuButton';
import {VisualizationResults} from '../../../../store/visualization';
import VisualizationResultsExportPPTActionMenuButton from '../common/VisualizationResultsExportPPT/VisualizationResultsExportPPTActionMenuButton';
import VisualizationResultsExportExcelActionMenuButton from '../common/VisualizationResultsExportExcel/VisualizationResultsExportExcelActionMenuButton';
import VisualizationResultsExportPDFActionMenuButton from '../common/VisualizationResultsExportPDF/VisualizationResultsExportPDFActionMenuButton';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '22rem',
};

type VisualizationStep3ActionMenuProps = {
  model?: ModelDTO;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDetailDTO;
  modelUuid: string;
  scenarioId: number;
  scenarioInProgress: boolean;
  results?: VisualizationResults;
};

const VisualizationStep3ActionMenu: React.FC<VisualizationStep3ActionMenuProps> = ({
  modelUuid,
  scenarioId,
  model,
  subModel,
  scenario,
  scenarioInProgress,
  results,
}) => {
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);
  const location = useLocation();
  const link = getUrlRoute(Routes.VISUALIZATION_PARAMETERS_DISPLAY, {modelUuid, scenarioId});

  return (
    <SideBar position="right" {...sharedSidebar} {...tid('visualization1-action-sidebar')}>
      <List dense component="nav">
        <SideBarLinkItem
          selected={location.pathname === link}
          disabled={!scenarioId}
          linkTo={link}
          icon={<TableOfContents16 />}
          text="Show Input Parameters"
        />

        <VisualizationResultsExportExcelActionMenuButton
          model={model}
          subModel={subModel}
          scenario={scenario}
          scenarioInProgress={scenarioInProgress}
        />

        <VisualizationResultsExportPPTActionMenuButton
          model={model}
          subModel={subModel}
          scenario={scenario}
          scenarioInProgress={scenarioInProgress}
          results={results}
        />

        <VisualizationResultsExportPDFActionMenuButton
          model={model}
          subModel={subModel}
          scenario={scenario}
          scenarioInProgress={scenarioInProgress}
          results={results}
        />

        <SideBarButtonItem
          onClick={window.print}
          disabled={location.pathname === link || scenarioInProgress}
          icon={<Printer16 />}
          text="Print"
        />

        <VisualizationShareLinkActionMenuButton
          model={model}
          subModel={subModel}
          scenario={scenario}
          scenarioInProgress={scenarioInProgress}
        />

        <VisualizationAnalysisSaveActionMenuButton
          model={model}
          subModel={subModel}
          scenario={scenario}
          scenarioInProgress={scenarioInProgress}
        />

        <TestVisualizationActionMenuButton model={model} scenarioInProgress={scenarioInProgress} />

        <VisualizationCancelActionMenuButton scenario={scenario || undefined} model={model} />
      </List>
    </SideBar>
  );
};

export default VisualizationStep3ActionMenu;
