/* eslint-disable @typescript-eslint/camelcase */
import {
  FindScenariosRequest,
  ScenarioApi,
  ScenarioCreateDTO,
  ScenarioExistsByNameRequest,
  ScenarioLabelDTO,
  ScenarioModelInputDTO,
  ScenarioStatus,
  ScenarioUpdateDTO,
  ShareInstanceDTO,
  UsersApi,
  ModelsApi,
} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
import {visualizationResultsValidator} from '../visualization/visualizationResultsValidator';
import {VisualizationResults} from '../visualization/types';
import {downloadBlob} from '../../utils/download';

const scenarioApi = new ScenarioApi(configuration);
const usersApi = new UsersApi(configuration);
const modelsApi = new ModelsApi(configuration);

// scenarioApi.getCalibratedInput()

export const requestScenarioList = (requestParameters: FindScenariosRequest) =>
  scenarioApi.findScenarios(requestParameters);

export const requestScenarioDetail = (scenarioId: number) => scenarioApi.getScenarioDetail({scenarioId});

export const requestScenarioCreate = (executionSetupId: number, scenarioCreateDTO: ScenarioCreateDTO) =>
  scenarioApi.createScenario({executionSetupId, scenarioCreateDTO});

export const requestScenarioDelete = (scenarioId: number) => scenarioApi.deleteScenario({scenarioId});

export const requestScenarioNameExists = (props: ScenarioExistsByNameRequest) =>
  scenarioApi.scenarioExistsByName(props).then((value) => value === 'true');

export const requestScenarioUpdate = (scenarioId: number, scenarioUpdateDTO: ScenarioUpdateDTO) =>
  scenarioApi.updateScenario({
    scenarioId,
    scenarioUpdateDTO,
  });

export const requestScenarioUpdateInputs = (scenarioId: number, scenarioModelInputDTO: ScenarioModelInputDTO) =>
  scenarioApi.scenarioScenarioIdInputPut({scenarioId, scenarioModelInputDTO});

export const requestScenarioGetOutput = (scenarioId: number) => scenarioApi.getScenarioOutput({scenarioId});
export const requestScenarioGetOutputRaw = (scenarioId: number) => scenarioApi.getScenarioOutputRaw({scenarioId});
export const requestScenarioGetOutputVisualization = async (scenarioId: number) => {
  const blob = await requestScenarioGetOutput(scenarioId);
  if (!blob) {
    throw new Error('Invalid Blob response');
  }

  if (blob.type.toLowerCase() !== 'application/json') {
    if (blob.type.toLowerCase() === 'application/zip') {
      requestScenarioGetOutputRaw(scenarioId).then((response) => {
        response.value().then((blob) => {
          const name = response.raw.headers
            .get('content-disposition')
            ?.split('filename=')[1]
            ?.toString()
            .replaceAll('"', '');
          downloadBlob(blob, name);
        });
      });
    } else {
      throw new Error(`Invalid response type ${blob.type}`);
    }
  }
  const text = await blob.text();
  const [results, error] = visualizationResultsValidator(text);
  if (error) {
    throw new Error(error);
  }
  return results as VisualizationResults;
};

export const requestScenarioGetOutputVisualizationExcelRaw = (scenarioId: number, elementIndex?: number) =>
  scenarioApi.getScenarioOutputExportRaw({
    scenarioId,
    indexPart: elementIndex === undefined ? undefined : [elementIndex],
  });

export const requestScenarioStatusUpdate = (scenarioId: number, status: ScenarioStatus) =>
  scenarioApi.setScenarioStatus({scenarioId, status});

export const requestScenarioLoadError = (scenarioId: number) => scenarioApi.getScenarioError({scenarioId});

export const requestScenarioShare = (scenarioId: number, shareInstanceDTO: ShareInstanceDTO) =>
  scenarioApi.share({scenarioId, shareInstanceDTO});

export const requestScenarioUpdateLabel = (scenarioId: number, scenarioLabelDTO: ScenarioLabelDTO) =>
  scenarioApi.setLabel({scenarioId, scenarioLabelDTO});

export const requestScenarioOutdate = (executionSetupId: number[]) =>
  scenarioApi.outdateScenario({scenarioOutdateDTO: {executionSetupId}});

export const requestUserList = (modelUuid: string, scenarioId: number) =>
  usersApi.findInstanceShareUsers({modelUuid: modelUuid, scenarioId: scenarioId});

export const requestShareInstance = (identifier: number, id: number, isids: Array<string>, messageText: string) =>
  //modelsApi.updateShareInstance({scenarioId: identifier,updateScenarioDTO:{ isid:isid, status:messageText}})
  modelsApi.shareInstanceWithUser({
    identifier: identifier,
    id: id,
    shareInstanceDTO: {isids: isids, messageText: messageText},
  });

export const requestUpdateShareInstance = (scenarioId: number, isid: string, status: string) =>
  modelsApi.updateShareInstance({scenarioId: scenarioId, updateScenarioDTO: {isid: isid, status: status}});
