import React from 'react';
import {InputGenerator, InputType, RealNumberInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type ControlsMap = {[key in keyof Required<RealNumberInputAttributes>]: AbstractControl};

type RealNumberInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const RealNumberInput: React.FC<RealNumberInputProps> = ({formGroup}) => {
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <NumberInputPrimitive formGroup={formGroup} type={InputType.REAL_NUMBER} />
    </>
  );
};

export const realNumberInputGenerator: InputGenerator<InputType.REAL_NUMBER> = {
  type: InputType.REAL_NUMBER,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      ...numberInputPrimitiveControlBuilder(initialValues, InputType.REAL_NUMBER),
    });

    return fg;
  },
  render: RealNumberInput,
};
