import {UserAction, UserActions} from './types';
import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {UserDTO, ExUserRegistrationDetails} from 'hemwb-api';
import {AsyncActionPhase} from '../asyncUtilsTypes';

export const userInitialState = {
  detail: buildInitialAsyncState<UserDTO>(),
  list: buildInitialAsyncState<UserDTO[]>(),
  externalList: buildInitialAsyncState<ExUserRegistrationDetails[]>(),
};

export type UserState = typeof userInitialState;

const updateUserInList = (state: UserState, user: Partial<UserDTO> & {id: UserDTO['id']}): UserState => {
  if (state.list.value) {
    return {
      ...state,
      list: {
        ...state.list,
        value: state.list.value?.map((u) => (u.id === user.id ? {...u, ...user} : u)),
      },
    };
  }

  return state;
};

export const userReducer = (state = userInitialState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActions.FETCH_LIST:
      return {
        ...state,
        list: reduceAsyncAction(action),
      };

    case UserActions.FETCH_EXTERNAL_LIST:
      return {
        ...state,
        externalList: reduceAsyncAction(action),
      };

    case UserActions.FETCH_DETAIL:
      return {
        ...state,
        detail: reduceAsyncAction(action),
      };

    case UserActions.SET_ACTIVE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [id, active] = action.params;
        return updateUserInList(state, {id, active});
      }
      return state;
    }

    case UserActions.SET_USER_TYPE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [id, userType] = action.params;
        return updateUserInList(state, {id, userType});
      }
      return state;
    }

    case UserActions.ENROLL: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return {
          ...state,
          list: userInitialState.list,
        };
      }
      return state;
    }

    case UserActions.ACTIVATE_EXTERNAL_USER:
    case UserActions.DEACTIVATE_EXTERNAL_USER:
    case UserActions.REJECT_EXTERNAL_USER: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return userInitialState;
      }
      return state;
    }

    default:
      return state;
  }
};
