import React, {PropsWithChildren, ReactNode} from 'react';
import {Grid} from '@material-ui/core';
import styles from './ActionHeader.module.scss';

type ActionHeaderProps = PropsWithChildren<React.ReactNode> & {
  label?: string | ReactNode;
  Icon?: ReactNode;
};

const ActionHeader: React.FC<ActionHeaderProps> = ({Icon, label, children}) => {
  return (
    <Grid item sm={12} className={styles.container}>
      {Icon && <div className={styles.icon}>{Icon}</div>}
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.title}>{children}</div>
    </Grid>
  );
};

export default ActionHeader;
