import React, {useEffect, useMemo, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {Form, useForm} from '../../../core/Form';
import {FormControl, FormGroup} from 'react-reactive-form';
import {ModelCreator, ModelFilterState, ModelStewardInfo} from './types';
import {extractDistinctMetadata} from './utils';
import {MultipleCheckboxFilter} from '../../../core/filter';
import {getModelLanguageText} from '../../../../store/model';

const stewardLabel = (steward: ModelStewardInfo) => (
  <>
    {steward.name} {steward.email && <small>({steward.email})</small>}
  </>
);

const compareStewards = (a: ModelStewardInfo, b: ModelStewardInfo) => {
  const nameCmp = a.name.localeCompare(b.name);
  if (nameCmp === 0) {
    return a.email.localeCompare(b.email);
  }
  return nameCmp;
};

const buildStewardKey = (steward: ModelStewardInfo) => steward.name + ';' + steward.email;

const creatorLabel = (creator: ModelCreator) => (
  <>
    {creator.lastName ? `${creator.firstName} ${creator.lastName}` : creator.id}{' '}
    {creator.email && <small>({creator.email})</small>}
  </>
);

const buildCreatorKey = (creator: ModelCreator) => creator.id;

const compareCreators = (a: ModelCreator, b: ModelCreator) => {
  return a.id.localeCompare(b.id);
};

export type ModelFilterProps = {
  models: ModelDTO[] | null;
  filter: ModelFilterState | null;
  onFilterChange: (filter: ModelFilterState) => void;
};

export const ModelFilter: React.FC<ModelFilterProps> = ({models, filter, onFilterChange}) => {
  const options = useMemo(() => {
    const countries = extractDistinctMetadata(models, 'country');

    const globalOptions = [];
    if (models) {
      if (models.find((model) => model.metadata.global === 'true')) {
        globalOptions.push('global');
      }
      if (models.find((model) => !!model.metadata.country)) {
        globalOptions.push('countryAdaptation');
      }
    }
    return {
      modelTypes: extractDistinctMetadata(models, 'model_type'),
      global: globalOptions,
      countries: countries,
      stewards: Object.values(
        (models || [])
          .map((model) =>
            model.metadata && model.metadata.name_of_model_steward
              ? {
                  name: model.metadata.name_of_model_steward,
                  email: model.metadata.email_for_model_steward || '',
                }
              : null,
          )
          .filter((steward): steward is ModelStewardInfo => !!steward)
          .reduce((map, steward) => {
            map[buildStewardKey(steward)] = steward;
            return map;
          }, ({} as any) as {[key: string]: ModelStewardInfo}),
      ).sort(compareStewards),
      creators: Object.values(
        (models || [])
          .filter((model): model is ModelDTO => !!model.createdBy.id)
          .reduce((map, model) => {
            map[buildCreatorKey(model.createdBy)] = model.createdBy;
            return map;
          }, ({} as any) as {[key: string]: ModelCreator}),
      ).sort(compareCreators),
      products: extractDistinctMetadata(models, 'product'),
      diseases: extractDistinctMetadata(models, 'disease'),
      type: extractDistinctMetadata(models, 'type'),
      therapeuticAreas: extractDistinctMetadata(models, 'therapeutic_area'),
      platforms: extractDistinctMetadata(models, 'platform'),
      status: ['Draft', 'Published', 'Retired'],
    };
  }, [models]);

  const [expandedFilter, setExpandedFilter] = useState<string | null>(null);
  useEffect(() => {
    if (!!models && !expandedFilter) {
      const populatedFilterKeys = Object.entries(filter || {})
        .filter(([, filterValue]) => filterValue && filterValue.length > 0)
        .map(([filterKey]) => filterKey);

      setExpandedFilter(
        (Object.keys(options) as (keyof typeof options)[]).find(
          (optionsKey) =>
            !!options[optionsKey] &&
            !!options[optionsKey].length &&
            (populatedFilterKeys.length === 0 || populatedFilterKeys.includes(optionsKey)),
        ) || null,
      );
    }
  }, [models, filter, expandedFilter, setExpandedFilter, options]);

  const form = useForm(
    new FormGroup({
      modelTypes: new FormControl([]),
      global: new FormControl([]),
      countries: new FormControl([]),
      stewards: new FormControl([]),
      creators: new FormControl([]),
      products: new FormControl([]),
      diseases: new FormControl([]),
      type: new FormControl([]),
      therapeuticAreas: new FormControl([]),
      platforms: new FormControl([]),
      status: new FormControl(['Draft', 'Published']),
    }),
  );

  useEffect(() => {
    filter && form.setValue(filter, {emitEvent: false});
    form.valueChanges.subscribe(onFilterChange);
    return () => form.valueChanges.unsubscribe(onFilterChange);
  }, [form, filter, onFilterChange]);

  const checkboxFilterProps = <G extends keyof typeof options>(group: G) => ({
    control: form.get(group),
    collapsed: expandedFilter !== group,
    onToggleCollapsed: () => setExpandedFilter(expandedFilter === group ? null : group),
    options: options[group],
  });

  return (
    <div>
      {models ? (
        <Form
          group={form}
          FieldGroupProps={{strict: false}}
          render={() => (
            <>
              <MultipleCheckboxFilter label="Model Types" {...checkboxFilterProps('modelTypes')} />

              <MultipleCheckboxFilter
                label="Steward"
                getDataTestIdPostfixFromValue={(s) => s.email || s.name || ''}
                optionLabel={stewardLabel}
                optionKey={buildStewardKey}
                optionLabelMatcher={(steward, search) =>
                  `${steward.name} ${steward.email}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                }
                optionValueMatcher={(a, b) => compareStewards(a, b) === 0}
                {...checkboxFilterProps('stewards')}
                options={options.stewards}
              />

              <MultipleCheckboxFilter
                label="Creator"
                getDataTestIdPostfixFromValue={buildCreatorKey}
                optionLabel={creatorLabel}
                optionKey={buildCreatorKey}
                optionLabelMatcher={(creator, search) =>
                  `${creator.id} ${creator.firstName} ${creator.lastName} ${creator.email}`
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
                }
                optionValueMatcher={(a, b) => compareCreators(a, b) === 0}
                {...checkboxFilterProps('creators')}
                options={options.creators}
              />

              <MultipleCheckboxFilter
                label={!!filter?.global?.length ? 'Global / Country adapt.' : 'Global / Country adaptation'}
                {...checkboxFilterProps('global')}
                optionLabel={(global) => (global === 'global' ? 'Global' : 'Country Adaptation')}
              />
              <MultipleCheckboxFilter label="Countries" {...checkboxFilterProps('countries')} />
              <MultipleCheckboxFilter label="Product" {...checkboxFilterProps('products')} />
              <MultipleCheckboxFilter
                label="Platform"
                {...checkboxFilterProps('platforms')}
                optionLabel={(platform) => (platform === 'Pneumococcal Vaccines' ? 'PneuInsights' : platform)}
              />
              <MultipleCheckboxFilter label="Name of Disease" {...checkboxFilterProps('diseases')} />
              <MultipleCheckboxFilter
                label="Model Language"
                {...checkboxFilterProps('type')}
                optionLabel={getModelLanguageText}
              />
              <MultipleCheckboxFilter label="Therapeutic Area " {...checkboxFilterProps('therapeuticAreas')} />
              <MultipleCheckboxFilter label="Status" {...checkboxFilterProps('status')} />
            </>
          )}
        />
      ) : (
        'Loading...'
      )}
    </div>
  );
};
