import React, {PropsWithChildren} from 'react';
import ReactDOM from 'react-dom';
import styles from './SecondaryHeader.module.scss';

type SecondaryHeaderProps = {};

const SecondaryHeader: React.FC<PropsWithChildren<SecondaryHeaderProps>> = ({children}) => {
  const el = document.getElementById('secondaryNav-portal');
  return el && ReactDOM.createPortal(<div className={styles.container}>{children}</div>, el);
};

export default SecondaryHeader;
