import {CalibrationScenarioInputs} from './types';
import {InputAttributes, InputType} from '../InputDefinition/types';
import {convertByType} from '../InputDefinition/convertor';

export const formatInput = (
  definitions: InputAttributes[],
  values: CalibrationScenarioInputs,
): CalibrationScenarioInputs => {
  return definitions.reduce((acc, definition) => {
    const {name} = definition;
    const value = values[name];

    if (definition.type === InputType.LABEL) {
      return acc;
    }

    if (!value && value !== 0 && value !== false) {
      acc[name] = definition.type === InputType.STRING ? '' : null;
      return acc;
    }

    switch (definition.type) {
      case InputType.STRING:
      case InputType.INTEGER:
      case InputType.REAL_NUMBER:
      case InputType.DECIMAL:
      case InputType.DATE:
      case InputType.BOOLEAN:
      case InputType.FILE:
        acc[name] = convertByType(definition.type, value, true, name);
        break;
      case InputType.SELECTOR:
      case InputType.SINGLE_SELECT:
        acc[name] = convertByType(InputType.STRING, value, true, name);
        break;
      case InputType.MULTI_SELECT:
        acc[name] = value?.filter(Boolean).map((v: string) => convertByType(InputType.STRING, v, true, name)) || null;
        break;
      case InputType.RANGE:
        acc[name] = [
          convertByType(definition.dataType, value?.[0], true, name),
          convertByType(definition.dataType, value?.[1], true, name),
        ];
        break;
      case InputType.ARRAY:
        acc[name] = definition.columns.map((column, index) => {
          const columnValues = value[index];
          return columnValues?.map((v: string) => convertByType(column.dataType, v, true, name));
        });
        break;
    }

    return acc;
  }, ({} as any) as CalibrationScenarioInputs);
};
