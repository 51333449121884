import React, {useCallback} from 'react';
import {driver} from 'driver.js';
import {useDispatch} from 'react-redux';
import './tour.scss';
import styles from '../model/list/ModelList.module.scss';
import style from '../../../components/core/TableWithSortAndPagination/TableWithSortAndPagination.module.scss';
import {useGuidingTour} from '../../../store/guidingTour/hooks';
import {guidingTourLoadDetail} from '../../../store/guidingTour/actions';

export type TourProps = {
  userType: string | undefined;
  showTour: boolean;
  onTourComplete: () => void;
};

const Tour: React.FC<TourProps> = ({showTour, onTourComplete, userType}) => {
  const dispatch = useDispatch();
  const tour = useGuidingTour('GT', 'HOMEPAGE');
  const handleSubmit = useCallback(() => {
    onTourComplete();
    return guidingTourLoadDetail(dispatch, 'GT', 'HOMEPAGE');
  }, [dispatch, onTourComplete]);
  const addClassNameToEditMenu = () => {
    const editMenu = document.querySelector('[data-testid="model-list-action-sidebar"]');
    editMenu?.classList.add('tour-edit');
    return 'tour-edit';
  };

  const addClassNameToAddModel = () => {
    const parent = document.querySelectorAll('.MuiTabs-flexContainer')[0];
    const addModel = parent?.querySelector(':first-child');
    addModel?.classList.add('tour-addModel');
    return 'tour-addModel';
  };

  const addClassNameToDynamicView = () => {
    const containers = document.querySelectorAll('.MuiTabs-flexContainer');
    if (containers.length > 1) {
      const parent = containers[1];
      if (parent.children.length > 1) {
        const dynamicView = parent.children[1];
        dynamicView.classList.add('tour-dynamic');
      }
    }
    return 'tour-dynamic';
  };

  const addClassNameToDownloadModel = () => {
    const download = document.querySelector('[data-testid="btn-download-model"]');
    download?.classList.add('tour-download');
    return 'tour-download';
  };

  const addClassNameToRetiredModel = () => {
    const retire = document.querySelector('[data-testid="btn-retire-model"]');
    retire?.classList.add('tour-retire');
    return 'tour-retire';
  };

  const elements = [
    '.body',
    `.${style.row}`,
    `.${styles.searchPanel}`,
    `.${addClassNameToDynamicView()}`,
    `.${addClassNameToAddModel()}`,
    `.${addClassNameToEditMenu()}`,
    `.${addClassNameToDownloadModel()}`,
    `.${addClassNameToRetiredModel()}`,
  ];

  const steps =
    tour?.contentList?.map((item, index) => {
      const parsedContent = item.content ? JSON.parse(item.content) : null;
      const {title, Description, progressText, showButtons, side, align, NextButtonText} = parsedContent;
      return {
        element: elements[index],
        popover: {
          title: title,
          description: Description,
          showButtons: showButtons,
          nextBtnText: NextButtonText,
          progressText: progressText,
          side: side,
          align: align,
        },
      };
    }) ?? [];

  if ((!tour?.viewed || showTour) && userType !== 'EXTERNAL_USER') {
    const driverObj = driver({
      showProgress: true,
      prevBtnText: 'Previous ',
      allowClose: false,
      disableActiveInteraction: true,
      stageRadius: 0,
      stagePadding: 0,
      steps,
      onDestroyed: handleSubmit,
    });

    driverObj.drive();
  }
  return null;
};

export default Tour;
