import React, {useMemo} from 'react';
import DirNode from './DirNode';
import {FileTreeProps} from './types';
import {modelFileSelector, ModelTreeNode, TreeNode} from '../../../store/modelFile';
import {calculateTags, nodeAddTag} from '../../../store/modelFile/utils';
import {useSelector} from '../../../store/rootReducer';

const rootNode = {name: '', path: '', folder: true, uploaded: false, uuid: ''};

function FileTree<N extends TreeNode>(props: FileTreeProps<N>) {
  const {
    files,
    onDrop,
    externalModel,
    theoremModel,
    webModel,
    createFlow,
    uploadFlow,
    cloneFlow,
    handleFileTag,
    clonedModel,
    ...restProps
  } = props;
  const config = useSelector((state) => modelFileSelector(state).config);

  const sortedFiles = useMemo(() => {
    return files
      .map((node) => (node.tags ? node : nodeAddTag(node, calculateTags(node as ModelTreeNode, config, false))))
      .sort((a, b) => a.path.localeCompare(b.path));
  }, [config, files]);

  return (
    <div
      className="fileTree"
      onDrop={(event: React.DragEvent) => {
        if (onDrop) {
          event.stopPropagation();
          onDrop(event, rootNode);
        }
      }}>
      <DirNode<N>
        externalModel={externalModel}
        webModel={webModel}
        theoremModel={theoremModel}
        createFlow={createFlow}
        uploadFlow={uploadFlow}
        cloneFlow={cloneFlow}
        files={sortedFiles}
        handleFileTag={handleFileTag}
        node={rootNode}
        isRoot={true}
        onDrop={onDrop}
        clonedModel={clonedModel}
        {...restProps}
      />
    </div>
  );
}

export default FileTree;
