import React from 'react';
import {DialogGeneric} from '../../core/dialog/DialogGeneric';
import {tid} from '../../../testUtils';

type SnackbarReportConfirmationDialogProps = {
  open: boolean;
  onCancel?: () => void;
};

const SnackbarReportConfirmationDialog: React.FC<SnackbarReportConfirmationDialogProps> = ({open, onCancel}) => {
  return (
    <DialogGeneric
      onHeaderCloseButtonClick={onCancel}
      open={open}
      style={{textAlign: 'center'}}
      title={<>Thank you for reporting this</>}
      PaperProps={tid('dialog-report-issue') as {}}
      childrenAsText={false}>
      <>
        <div style={{padding: '10px', textAlign: 'left'}}>
          We appreciate you for letting us know. To provide a feedback on any other issues,
          <a style={{textDecoration: 'underline', color: '#3f8ac9'}} href="https://forms.office.com/r/tMBqdgtpD0">
            {' '}
            {/*feedbackURL*/}
            visit our feedback page.
          </a>
        </div>
      </>{' '}
    </DialogGeneric>
  );
};

export default SnackbarReportConfirmationDialog;
