import React, {useCallback, useMemo} from 'react';
import {ChangeItem, DiffWithTab, SheetOrMacroDiffDTO, TabType} from './types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {IconButton, Tooltip} from '@material-ui/core';
import {isSheetDiff} from './utils';
import {DiffDTOTagEnum} from 'hemwb-api';

type ChangeNavigatorProps = {
  tab: TabType;
  diff?: SheetOrMacroDiffDTO;
  diffsWithTab: DiffWithTab[];
  markedChange?: ChangeItem;
  setMarkedChange: (change: ChangeItem) => void;
};

const createChangeItem = (diffWithTab: DiffWithTab, pageIndex = 0, diffIndex = 0): ChangeItem => ({
  diff: pageIndex === 0 ? diffWithTab.diff : undefined,
  tab: diffWithTab.tab,
  pageIndex,
  diffIndex,
});

const getRowPrevAndNextChangeIndexes = (markedChange: ChangeItem): [number | undefined, number | undefined] => {
  if (!markedChange.diff || !isSheetDiff(markedChange.diff)) {
    return [undefined, undefined];
  }

  let prevFirstChangeIndexOnRow;
  let firstChangeIndexOnRow;
  let nextFirstChangeIndexOnRow = 0;
  let counter = markedChange.diff.firstDiffIndexInPage;

  for (const row of markedChange.diff.cellDiff) {
    if (counter <= markedChange.diffIndex && firstChangeIndexOnRow !== counter) {
      prevFirstChangeIndexOnRow = firstChangeIndexOnRow;
      firstChangeIndexOnRow = counter;
    }

    if (
      firstChangeIndexOnRow !== counter &&
      !nextFirstChangeIndexOnRow &&
      counter <= markedChange.diff.lastDiffIndexInPage
    ) {
      nextFirstChangeIndexOnRow = counter;
    }

    for (const cell of row) {
      if (cell.tag !== DiffDTOTagEnum.Equal) {
        counter++;
      }
    }
  }

  return [prevFirstChangeIndexOnRow, nextFirstChangeIndexOnRow];
};

const ChangeNavigator: React.FC<ChangeNavigatorProps> = ({tab, diff, diffsWithTab, markedChange, setMarkedChange}) => {
  const totalChangesCount = useMemo(() => {
    return diffsWithTab.reduce((acc, val) => {
      return acc + val.diff.diffCount;
    }, 0);
  }, [diffsWithTab]);

  const currentChangeIndex = useMemo(() => {
    if (markedChange) {
      let found = false;
      return diffsWithTab
        .reduce((acc, val) => {
          if (val.tab === markedChange.tab) {
            found = true;
            return acc + markedChange.diffIndex;
          } else if (!found) {
            return acc + val.diff.diffCount;
          }
          return acc;
        }, 0)
        .toString();
    }
    return '';
  }, [diffsWithTab, markedChange]);

  const firstChange = useMemo(() => {
    if (diffsWithTab.length > 0) {
      return createChangeItem(diffsWithTab[0], diffsWithTab[0].diff.firstDiffPageIndex, 1);
    }
  }, [diffsWithTab]);

  const lastChange = useMemo(() => {
    if (diffsWithTab.length > 0) {
      return createChangeItem(
        diffsWithTab[diffsWithTab.length - 1],
        diffsWithTab[diffsWithTab.length - 1].diff.lastDiffPageIndex,
        diffsWithTab[diffsWithTab.length - 1].diff.diffCount,
      );
    }
  }, [diffsWithTab]);

  const handleNextTabClick = useCallback(() => {
    if (!lastChange || !firstChange) {
      return;
    }

    if (markedChange) {
      const nextDiffWithTabAndChange = getNextDiffWithTabAndChange(diffsWithTab, markedChange.tab);
      if (nextDiffWithTabAndChange) {
        if (nextDiffWithTabAndChange.tab === markedChange.tab) {
          return setMarkedChange(lastChange);
        }
        return setMarkedChange(
          createChangeItem(nextDiffWithTabAndChange, nextDiffWithTabAndChange.diff.firstDiffPageIndex, 1),
        );
      }
    }
    return setMarkedChange(firstChange);
  }, [diffsWithTab, firstChange, lastChange, markedChange, setMarkedChange]);

  const handlePrevTabClick = useCallback(() => {
    if (!lastChange || !firstChange) {
      return;
    }

    if (markedChange) {
      const prevDiffWithTabAndChange = getPrevDiffWithTabAndChange(diffsWithTab, markedChange.tab);
      if (prevDiffWithTabAndChange) {
        if (prevDiffWithTabAndChange.tab === markedChange.tab) {
          return setMarkedChange(firstChange);
        }
        return setMarkedChange(
          createChangeItem(
            prevDiffWithTabAndChange,
            prevDiffWithTabAndChange.diff.lastDiffPageIndex,
            prevDiffWithTabAndChange.diff.diffCount,
          ),
        );
      }
    }
    return setMarkedChange(lastChange);
  }, [diffsWithTab, firstChange, lastChange, markedChange, setMarkedChange]);

  const handleNextChangeClick = useCallback(() => {
    if (markedChange) {
      if (markedChange.diffIndex < markedChange.diff!.lastDiffIndexInPage) {
        setMarkedChange({...markedChange, diffIndex: markedChange.diffIndex + 1});
      } else if (markedChange.diff!.nextDiffPageIndex !== -1) {
        return setMarkedChange(
          createChangeItem(
            markedChange as DiffWithTab,
            markedChange.diff!.nextDiffPageIndex,
            markedChange.diffIndex + 1,
          ),
        );
      } else {
        const nextDiffWithTabAndChange = getNextDiffWithTabAndChange(diffsWithTab, markedChange.tab);
        if (nextDiffWithTabAndChange) {
          return setMarkedChange(
            createChangeItem(nextDiffWithTabAndChange, nextDiffWithTabAndChange.diff.firstDiffPageIndex, 1),
          );
        }
      }

      //TODO
      return;
    }

    const currentDiffWithTab = diffsWithTab.find((d) => d.tab === tab);
    if (currentDiffWithTab && currentDiffWithTab.diff.diffCount > 0) {
      return setMarkedChange(createChangeItem(currentDiffWithTab, currentDiffWithTab.diff.firstDiffPageIndex, 1));
    }
    const nextDiffWithTabAndChange = getNextDiffWithTabAndChange(diffsWithTab, tab);
    if (nextDiffWithTabAndChange) {
      return setMarkedChange(
        createChangeItem(nextDiffWithTabAndChange, nextDiffWithTabAndChange.diff.firstDiffPageIndex, 1),
      );
    }

    //TODO
    return;
  }, [diffsWithTab, markedChange, setMarkedChange, tab]);

  const handlePrevChangeClick = useCallback(() => {
    if (markedChange) {
      if (markedChange.diffIndex > markedChange.diff!.firstDiffIndexInPage) {
        return setMarkedChange({...markedChange, diffIndex: markedChange.diffIndex - 1});
      }

      if (markedChange.diff!.previousDiffPageIndex !== -1) {
        return setMarkedChange(
          createChangeItem(
            markedChange as DiffWithTab,
            markedChange.diff!.previousDiffPageIndex,
            markedChange.diffIndex - 1,
          ),
        );
      }

      const prevDiffWithTabAndChange = getPrevDiffWithTabAndChange(diffsWithTab, markedChange.tab);
      if (prevDiffWithTabAndChange) {
        return setMarkedChange(
          createChangeItem(
            prevDiffWithTabAndChange,
            prevDiffWithTabAndChange.diff.lastDiffPageIndex,
            prevDiffWithTabAndChange.diff.diffCount,
          ),
        );
      }

      //TODO
      return;
    }

    const currentDiffWithTab = diffsWithTab.find((d) => d.tab === tab);
    if (currentDiffWithTab && currentDiffWithTab.diff.diffCount > 0) {
      return setMarkedChange(
        createChangeItem(
          currentDiffWithTab,
          currentDiffWithTab.diff.lastDiffPageIndex,
          currentDiffWithTab.diff.diffCount,
        ),
      );
    }
    const prevDiffWithTabAndChange = getPrevDiffWithTabAndChange(diffsWithTab, tab);
    if (prevDiffWithTabAndChange) {
      return setMarkedChange(
        createChangeItem(
          prevDiffWithTabAndChange,
          prevDiffWithTabAndChange.diff.lastDiffPageIndex,
          prevDiffWithTabAndChange.diff.diffCount,
        ),
      );
    }

    //TODO
    return;
  }, [diffsWithTab, markedChange, setMarkedChange, tab]);

  const handleNextRowClick = useCallback(() => {
    if (markedChange) {
      if (markedChange.diff) {
        if (isSheetDiff(markedChange.diff)) {
          const [, nextFirstChangeIndexOnRow] = getRowPrevAndNextChangeIndexes(markedChange);

          if (nextFirstChangeIndexOnRow) {
            return setMarkedChange(
              createChangeItem(markedChange as DiffWithTab, markedChange.pageIndex, nextFirstChangeIndexOnRow),
            );
          }

          if (markedChange.diff.nextDiffPageIndex !== -1) {
            return setMarkedChange({
              tab: markedChange.tab,
              diff: undefined,
              pageIndex: markedChange.diff.nextDiffPageIndex,
              diffIndex: markedChange.diff.lastDiffIndexInPage + 1,
            });
          }

          return handleNextTabClick();
        }
        return handleNextChangeClick();
      }
      //do nothing
      return;
    }

    return handleNextChangeClick();
  }, [handleNextChangeClick, handleNextTabClick, markedChange, setMarkedChange]);

  const handlePrevRowClick = useCallback(() => {
    if (markedChange) {
      if (markedChange.diff) {
        if (isSheetDiff(markedChange.diff)) {
          const [prevFirstChangeIndexOnRow] = getRowPrevAndNextChangeIndexes(markedChange);

          if (prevFirstChangeIndexOnRow) {
            return setMarkedChange(
              createChangeItem(markedChange as DiffWithTab, markedChange.pageIndex, prevFirstChangeIndexOnRow),
            );
          }

          if (markedChange.diff.previousDiffPageIndex !== -1) {
            return setMarkedChange({
              tab: markedChange.tab,
              diff: undefined,
              pageIndex: markedChange.diff.previousDiffPageIndex,
              diffIndex: markedChange.diff.firstDiffIndexInPage - 1,
            });
          }

          return handlePrevTabClick();
        }
        return handlePrevChangeClick();
      }
      //do nothing
      return;
    }

    return handlePrevChangeClick();
  }, [handlePrevChangeClick, handlePrevTabClick, markedChange, setMarkedChange]);

  if (totalChangesCount < 2) {
    return null;
  }

  const disableButtonsBeforeDiffLoad = markedChange && !markedChange.diff;
  const disableSheetButtons = markedChange
    ? markedChange.diff && !isSheetDiff(markedChange.diff)
    : diff && !isSheetDiff(diff);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        background: 'white',
        border: '1px solid black',
        width: 400,
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
      }}>
      <Tooltip title="Previous tab">
        <IconButton disabled={disableButtonsBeforeDiffLoad} onClick={handlePrevTabClick} style={{position: 'relative'}}>
          <NavigateBeforeIcon style={{position: 'relative', left: '0rem'}} />
          <NavigateBeforeIcon style={{position: 'absolute', left: '0.5rem'}} />
          <NavigateBeforeIcon style={{position: 'absolute', left: '2rem'}} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Previous row">
        <IconButton disabled={disableButtonsBeforeDiffLoad} onClick={handlePrevRowClick} style={{position: 'relative'}}>
          <NavigateBeforeIcon style={{position: 'relative', left: '0.1rem'}} />
          <NavigateBeforeIcon style={{position: 'absolute', left: '0.7rem'}} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Previous change">
        <IconButton disabled={disableButtonsBeforeDiffLoad || disableSheetButtons} onClick={handlePrevChangeClick}>
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      Change {currentChangeIndex} / {totalChangesCount}
      <Tooltip title="Next change">
        <IconButton disabled={disableButtonsBeforeDiffLoad || disableSheetButtons} onClick={handleNextChangeClick}>
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next row">
        <IconButton disabled={disableButtonsBeforeDiffLoad} onClick={handleNextRowClick} style={{position: 'relative'}}>
          <NavigateNextIcon style={{position: 'relative', left: '0rem'}} />
          <NavigateNextIcon style={{position: 'absolute', left: '0.6rem'}} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next tab">
        <IconButton disabled={disableButtonsBeforeDiffLoad} onClick={handleNextTabClick} style={{position: 'relative'}}>
          <NavigateNextIcon style={{position: 'relative', left: '0rem'}} />
          <NavigateNextIcon style={{position: 'absolute', left: '0.5rem'}} />
          <NavigateNextIcon style={{position: 'absolute', left: '2rem'}} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ChangeNavigator;

const getNextDiffWithTabAndChange = (diffsWithTab: DiffWithTab[], currentTab: TabType): DiffWithTab | null => {
  const index = diffsWithTab.findIndex((dt) => dt.tab === currentTab);
  if (index !== -1) {
    const reordered = [...diffsWithTab.slice(index + 1), ...diffsWithTab].filter((dt) => dt.diff.diffCount > 0);
    return reordered[0];
  }
  return null;
};

const getPrevDiffWithTabAndChange = (diffsWithTab: DiffWithTab[], currentTab: TabType): DiffWithTab | null => {
  const index = diffsWithTab.findIndex((dt) => dt.tab === currentTab);
  if (index !== -1) {
    const reordered = [...diffsWithTab, ...diffsWithTab.slice(0, index)].filter((dt) => dt.diff.diffCount > 0);
    return reordered[reordered.length - 1];
  }
  return null;
};
