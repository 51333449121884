import {ExecutionSetupDTO, ExecutionSetupType, ModelDTO, ScenarioDTO} from 'hemwb-api';
import {useSelector} from '../store/rootReducer';
import {authSelector} from '../store/auth';
import {useEffect} from 'react';
import {getFormattedUTCDateTime, isModelGlobal} from '../store/model';

//GA4 https://share.merck.com/display/~mikulase/Model+Repository+GA4+tracking+design
// window.dataLayer.push({
//   event: 'modelTracking',
//   model_action: View,
//   model_id: 750105e6-f1a4-4f6a-b471-94cccaebaaef,
//   model_name: Infectious Diseases - COVID-19 - Molnupiravir - Budget Impact - Global - Version: 1.1 - MOVe2-IT,
//   model_type: Cost Effectiveness,
//   model_therapeuticarea: Infectious Diseases,
//   model_country: United Kingdom,
//   model_region: Global,
//   model_access_reason: {value},
// })

type Model = Pick<ModelDTO, 'uuid' | 'name' | 'metadata'>;
type TrackModel = {
  model_id: string;
  model_name: string;
  model_type?: string;
  model_therapeuticarea?: string;
  model_country?: string;
  model_region?: string;
};
const convertModel = (m: Model): TrackModel => ({
  model_id: m.uuid,
  model_name: m.name,
  model_type: m.metadata.model_type,
  model_therapeuticarea: m.metadata.therapeutic_area,
  model_country: m.metadata.country === '' ? undefined : m.metadata.country,
  model_region: isModelGlobal(m) ? 'Global' : undefined,
});

type SubModel = Pick<ExecutionSetupDTO, 'id' | 'name' | 'environment' | 'type'>;
type TrackSubModel = {
  submodel_id: number;
  submodel_name: string;
  submodel_type?: ExecutionSetupType;
  submodel_environment: string;
};
const convertSubModel = (s: SubModel): TrackSubModel => ({
  submodel_id: s.id,
  submodel_name: s.name,
  submodel_type: s.type,
  submodel_environment: s.environment,
});
type Scenario = Pick<ScenarioDTO, 'id' | 'name'>;
type TrackScenario = {
  scenario_id: number;
  scenario_name: string;
};
const convertScenario = (s: Scenario): TrackScenario => ({
  scenario_id: s.id,
  scenario_name: s.name,
});

const emptyEvent = {
  model_id: undefined,
  model_name: undefined,
  model_type: undefined,
  model_therapeuticarea: undefined,
  model_country: undefined,
  model_region: undefined,
  model_access_reason: undefined,
  model_access_reason_text: undefined,
  submodel_id: undefined,
  submodel_name: undefined,
  submodel_type: undefined,
  submodel_environment: undefined,
  scenario_id: undefined,
  scenario_name: undefined,
};

const trackEvent = (data: object) => {
  // @ts-ignore
  // explicitly clean all the unused fields to prevent "inheriting" wrong values from previous events
  window.dataLayer?.push({event: 'eventTracking', ...emptyEvent, ...data});
};

//
//
//
// USER ADMINISTRATION TRACKING
//

const trackUserAdministration = (user_administration_action: string) => () =>
  trackEvent({
    event: 'userAdministrationTracking',
    user_administration_action,
  });

export const trackUserTypeChanged = trackUserAdministration('Type Changed');
export const trackUserActivated = trackUserAdministration('Activated');
export const trackUserDeactivated = trackUserAdministration('Deactivated');
export const trackUserEnrolled = trackUserAdministration('Enrolled');
export const trackUserAccessRequested = trackUserAdministration('Access Requested');

//
//
//
// MODEL ADMINISTRATION TRACKING
//

const trackModelAdministration = (model_administration_action: string) => () =>
  trackEvent({
    event: 'modelAdministrationTracking',
    model_administration_action,
  });

export const trackModelMetadataOptionAdded = trackModelAdministration('Metadata Option Added');
export const trackModelMetadataOptionUpdated = trackModelAdministration('Metadata Option Updated');
export const trackModelMetadataOptionDeleted = trackModelAdministration('Metadata Option Deleted');
export const trackModelMetadataOptionRetired = trackModelAdministration('Metadata Option Retired');

//
//
//
// MODEL TRACKING
//

const trackModelEvent = (model_action: string, additionalData?: object) => (model: Model) =>
  trackEvent({
    event: 'modelTracking',
    model_action,
    ...convertModel(model),
    ...additionalData,
  });

export const trackModelCreated = trackModelEvent('Created');
export const trackModelViewed = trackModelEvent('Viewed');
export const trackModelDownloaded = trackModelEvent('Download Model Entry');
export const trackModelItemDownloaded = (model: Model, item_name: string, item_id: string) =>
  trackModelEvent('Item Downloaded', {item_name, item_id})(model);
export const trackModelItemsUpdated = (
  model: Model,
  items_uploaded: number,
  items_modified: number,
  items_deleted: number,
) => trackModelEvent('Items Updated', {items_uploaded, items_modified, items_deleted})(model);
export const trackModelChangeLogFileSelected = trackModelEvent('Change Log File Selected');
export const trackModelChangeLogFileUnselected = trackModelEvent('Change Log File Unselected');
export const trackModelLineageParentSelected = trackModelEvent('Lineage Parent Selected');
export const trackModelMetadataUpdated = trackModelEvent('Metadata Updated');
export const trackModelSuffixUpdated = trackModelEvent('Suffix Updated');
export const trackModelPublished = trackModelEvent('Published');
export const trackModelRetired = trackModelEvent('Retired');
export const trackModelAccessRequested = (
  model: Model,
  model_access_reason: string,
  model_access_reason_text: string,
) =>
  trackModelEvent('Access Requested', {
    model_access_reason,
    model_access_reason_text,
    request_time: getFormattedUTCDateTime(new Date()),
  })(model);
export const trackFileAccessRequested = (
  model: Model,
  fileUuid: string,
  model_access_reason: string,
  model_access_reason_text: string,
  fileName: string,
) =>
  trackModelEvent('File Access Requested', {
    fileUuid,
    model_access_reason,
    model_access_reason_text,
    request_time: getFormattedUTCDateTime(new Date()),
    fileName,
  })(model);
export const trackModelAccessGranted = (model: Model) =>
  trackModelEvent('Access Granted', {access_time: getFormattedUTCDateTime(new Date())})(model);
export const trackTestVisualization = trackModelEvent('Visualization Tested');

//
//
//
// SUB-MODEL TRACKING
//

const trackSubModelEvent = (submodel_action: string) => (model: Model, subModel: SubModel) =>
  trackEvent({
    event: 'submodelTracking',
    submodel_action,
    ...convertModel(model),
    ...convertSubModel(subModel),
  });

export const trackSubModelCreated = trackSubModelEvent('Created');
export const trackSubModelUpdated = trackSubModelEvent('Updated');
export const trackSubModelDeleted = trackSubModelEvent('Deleted');
export const trackSubModelInputExported = trackSubModelEvent('Input Exported');
export const trackSubModelInputUpdated = trackSubModelEvent('Input Updated');

//
//
//
// SCENARIO TRACKING
//

const trackInstanceEvent = (scenario_action: string) => (model: Model, subModel: SubModel, scenario: Scenario) =>
  trackEvent({
    event: 'scenarioTracking',
    scenario_action,
    ...convertModel(model),
    ...convertSubModel(subModel),
    ...convertScenario(scenario),
  });

export const trackInstanceCreated = trackInstanceEvent('Created');
export const trackInstanceUpdated = trackInstanceEvent('Updated');
export const trackInstanceParametersUpdated = trackInstanceEvent('Parameters Updated');
export const trackInstanceCloned = trackInstanceEvent('Cloned');
export const trackInstanceDeleted = trackInstanceEvent('Deleted');
export const trackInstanceExecutionCreated = trackInstanceEvent('Data Visualization');
export const trackInstanceExecuted = trackInstanceEvent('Executed');
export const trackInstanceCanceled = trackInstanceEvent('Canceled');
export const trackInstanceResultsDownloaded = trackInstanceEvent('Results Downloaded');
export const trackInstanceParametersExported = trackInstanceEvent('Parameters Exported');
export const trackInstanceCalibrationAccepted = trackInstanceEvent('Calibration Accepted');
export const trackInstanceCalibrationSuperseded = trackInstanceEvent('Calibration Superseded');
export const trackInstanceCalibrationUnaccepted = trackInstanceEvent('Calibration Unaccepted');
export const trackInstanceVisualizationSaved = trackInstanceEvent('Visualization Saved');
export const trackInstanceVisualizationUpdated = trackInstanceEvent('Visualization Updated');
export const trackInstanceVisualizationShared = trackInstanceEvent('Visualization Shared');
export const trackInstanceVisualizationExportedPPT = trackInstanceEvent('Visualization PPT Exported');
export const trackInstanceVisualizationExportedPDF = trackInstanceEvent('Visualization PDF Exported');
export const trackInstanceVisualizationExportedExcel = trackInstanceEvent('Visualization Excel Exported');

//
//
// VISUALIZATION STEPS TRACKING
//
const trackVisualizationTabEvent = (step: string) => (model: Model) =>
  trackEvent({
    event: 'visualization',
    step: step,
    ...convertModel(model),
  });

export const trackModelDetailTabEvent = trackVisualizationTabEvent('Step1');
export const trackScenarioSelectionTabEvent = trackVisualizationTabEvent('Step2');
export const trackSettingParametersTabEvent = trackVisualizationTabEvent('Step3');
export const trackDataVisualizationTabEvent = trackVisualizationTabEvent('Step4');

//
//
// ADD MODEL ENTRIES STEPS TRACKING
//
const trackAddModelEntriesTabEvent = (step: string) => () =>
  trackEvent({
    event: 'model_entry',
    step: step,
  });

export const trackFilesTabEvent = trackAddModelEntriesTabEvent('Step1');
export const trackMetaDataTabEvent = trackAddModelEntriesTabEvent('Step2');
export const trackLineageTabEvent = trackAddModelEntriesTabEvent('Step3');
export const trackPermissionsTabEvent = trackAddModelEntriesTabEvent('Step4');
export const trackNameConfirmationTabEvent = trackAddModelEntriesTabEvent('Step5');

//
//
//
// LOGGED IN USER TRACKING
//

const useLoggedInUser = () => {
  const loggedInUser = useSelector((state) => authSelector(state).loggedInUser);

  useEffect(() => {
    if (loggedInUser) {
      trackEvent({
        event: 'userInfoUpdate',
        user_country: loggedInUser.country,
        department: loggedInUser.department,
        division_name: loggedInUser.divisionName,
        user_loginid: cyrb53(loggedInUser.id, 8),
        user_type: loggedInUser.userType,
      });
    }
  }, [loggedInUser]);
};

export const useTracking = () => {
  useLoggedInUser();
};

const cyrb53 = function (str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
