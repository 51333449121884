import React from 'react';
import clsx from 'clsx';
import {tid} from '../../../testUtils';
import styles from './FileInputButton.module.scss';
import {Upload16} from '@carbon/icons-react';

type FileInputButtonProps = {
  label: string;
  onChange: (files: FileList) => void;
  disabled?: boolean;
  accept?: string;
};

const FileInputButton: React.FC<FileInputButtonProps> = ({label, onChange, disabled = false, accept}) => {
  return (
    <label
      {...tid('input-file-label')}
      className={clsx('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary', styles.labelButton)}>
      <span className="MuiButton-label">
        <span className="MuiButton-startIcon MuiButton-iconSizeMedium">
          <Upload16 />
        </span>
        {label}
      </span>

      <input
        type="file"
        disabled={disabled}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          event.currentTarget.files && onChange(event.currentTarget.files)
        }
        {...tid('input-file')}
        accept={accept}
      />
    </label>
  );
};

export default FileInputButton;
