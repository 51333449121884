import {Message, MessageAction, MessageActions} from './types';
import {createNetworkErrorMessage} from './utils';
import {AuthAction, AuthActions} from '../auth/types';
import {AsyncActionPhase} from '../asyncUtilsTypes';

export const messageInitialState = {
  messages: [] as Message[],
};

export type MessageState = typeof messageInitialState;

const groupOrAddMessage = (state: MessageState, message: Message, disableOccurrenceCount = false): MessageState => {
  const groupWith = state.messages.filter(
    (m) => !m.disableGrouping && !message.disableGrouping && m.text === message.text,
  );
  const groupedOccurrence = disableOccurrenceCount
    ? 0
    : groupWith.reduce((acc, message) => {
        return acc + message.occurrence;
      }, 0);

  return {
    ...state,
    messages: [
      ...state.messages.filter((message) => groupWith.findIndex((m) => m.key === message.key) === -1),
      {
        ...message,
        ...(groupedOccurrence > 0
          ? {display: `(${groupedOccurrence + 1}) ${message.text}`, occurrence: groupedOccurrence + 1}
          : {}),
      },
    ],
  };
};

export const messageReducer = (state = messageInitialState, action: MessageAction | AuthAction): MessageState => {
  switch (action.type) {
    case AuthActions.AUTH_LOGIN:
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return {
          ...state,
          messages: state.messages.map((message) => ({...message, duration: 500})),
        };
      }
      return state;

    case AuthActions.AUTH_REGISTRATION:
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return {
          ...state,
          messages: state.messages.map((message) => ({...message, duration: 500})),
        };
      }
      return state;

    case MessageActions.ADD: {
      return groupOrAddMessage(state, action.message);
    }

    case MessageActions.UPDATE: {
      const {message} = action;

      return {
        ...state,
        messages: state.messages.map((m) => (m.key === message.key ? message : m)),
      };
    }

    case MessageActions.REMOVE:
      return {
        ...state,
        messages: state.messages.filter((message) => message !== action.message),
      };

    case MessageActions.NETWORK_ERROR: {
      const status = action.response.status;
      if (status < 400) {
        return state;
      }

      let text;
      if (status === 403) {
        text = 'Access is forbidden. You have been redirected.';
      } else if (action.body?.message) {
        text = `An error has occurred: ${action.body.message}`;
      } else if (action.body?.reportId) {
        text = `An error has occurred. Please try to reload the page. If this error persists, please click the report button to submit the report, report id: ${action.body.reportId}.`;
      } else {
        text = `An unknown error has occurred. Please try to reload page.`;
      }
      return groupOrAddMessage(state, createNetworkErrorMessage(text, action.body?.stackTrace, action.body?.reportId));
    }
  }
  return state;
};
