import {AsyncAction} from '../asyncUtilsTypes';
import {ApplicationSettingsDTO, VersionInfoDTO} from 'hemwb-api';

export enum AppDataActions {
  LOAD_SETTINGS = 'APP_DATA/LOAD_SETTINGS',
  LOAD_VERSION = 'APP_DATA/LOAD_VERSION',
}

export type AppDataAction =
  | AsyncAction<AppDataActions.LOAD_SETTINGS, ApplicationSettingsDTO, []>
  | AsyncAction<AppDataActions.LOAD_VERSION, VersionInfoDTO, []>;
