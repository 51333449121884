import React, {useEffect, useState} from 'react';
import {MacroDiffDTO, SheetDiffDTO, WorkbookItemDTOTypeEnum} from 'hemwb-api';
import {ModelTreeNode} from '../../../../store/modelFile';
import Tabs from './Tabs';
import {useExcelDiffMacro, useExcelDiffSheet} from '../../../../store/excelDiff';
import SuspenseNull from '../../Suspense/SuspenseNull';
import TextDiff from './TextDiff';
import {useExcelDiff} from './useExcelDiff';
import ChangeNavigator from './ChangeNavigator';
import SheetDiff from './SheetDiff';
import Overview from './Overview';
import WarningTextWithTitle from '../../WarningText/WarningTextWithTitle';
import {isSheetDiff} from './utils';
import Pagination from './Pagination';

type ExcelDiffProps = {
  file1: ModelTreeNode;
  file2: ModelTreeNode;
};

const ExcelDiff: React.FC<ExcelDiffProps> = ({file1, file2}) => {
  const [diffLoading, setDiffLoading] = useState(false);
  const [diff, setDiff] = useState<SheetDiffDTO | MacroDiffDTO>();

  const {
    tabs,
    tab,
    // setTab,
    changeTab,
    section,
    setSection,
    diffsWithTab,
    pageIndex,
    setPageIndex,
    viewMode,
    setViewMode,
    fetchErrorList,
    diffError,
    markedChange,
    setMarkedChange,
    loading,
    tabsCountFetched,
    tabsCountTotal,
  } = useExcelDiff(file1, file2);

  const sheetDiff = useExcelDiffSheet({
    itemId1: tab?.type === WorkbookItemDTOTypeEnum.SHEET ? tab.itemId1 : 0,
    itemId2: tab?.itemId2 || 0,
    itemUUID1: file1.uuid,
    itemUUID2: file2.uuid,
    pageIndex: pageIndex,
  });

  const macroDiff = useExcelDiffMacro({
    itemId1: tab && tab.type !== WorkbookItemDTOTypeEnum.SHEET ? tab.itemId1 : 0,
    itemId2: tab?.itemId2 || 0,
    itemUUID1: file1.uuid,
    itemUUID2: file2.uuid,
    pageIndex: pageIndex,
  });

  useEffect(() => {
    const d = sheetDiff || macroDiff || undefined;
    if (d) {
      setDiff(d);
      setDiffLoading(false);
    } else if (diffError) {
      setDiff(undefined);
      setDiffLoading(false);
    } else if (tab) {
      setDiffLoading(true);
    }
  }, [sheetDiff, macroDiff, diffError, section, tab]);

  if (!tabs) {
    return loading ? (
      <SuspenseNull />
    ) : (
      <WarningTextWithTitle
        text="This might be due to files are still being processed on our server. Please try again later."
        title="Failed to fetch diff"
      />
    );
  }

  return (
    <div style={{marginBottom: 100}}>
      <div className="wrapRelative">
        <Tabs
          tabs={tabs}
          onTabChange={changeTab}
          tab={tab}
          section={section}
          onSectionChange={setSection}
          viewMode={viewMode}
          setViewMode={setViewMode}
          showViewModeButtons={!!(diff && isSheetDiff(diff))}
        />
      </div>
      {tab && (
        <ChangeNavigator
          tab={tab}
          diff={diff}
          markedChange={markedChange}
          setMarkedChange={setMarkedChange}
          diffsWithTab={diffsWithTab}
        />
      )}
      {diff && tab && <Pagination pageIndex={pageIndex} setPageIndex={setPageIndex} diff={diff} />}
      {!tab ? (
        <Overview
          diffsWithTab={diffsWithTab}
          fetchErrorList={fetchErrorList}
          setMarkedChange={setMarkedChange}
          loading={loading}
          diffsCountFetched={tabsCountFetched}
          diffsCountTotal={tabsCountTotal}
        />
      ) : (
        <div style={{opacity: diffLoading ? 0.5 : 1}}>
          {diff && isSheetDiff(diff) && (
            <SheetDiff
              key={`${tab.name}${pageIndex}`}
              diff={diff}
              markedChange={markedChange}
              pageIndex={pageIndex}
              viewMode={viewMode}
            />
          )}
          {diff && !isSheetDiff(diff) && (
            <TextDiff
              key={`${tab.name}${pageIndex}`}
              tab={tab}
              diff={diff}
              markedChange={markedChange}
              pageIndex={pageIndex}
            />
          )}
          {diffError && (
            <WarningTextWithTitle
              text="This might be due to files are still being processed on our server. Please try again later."
              title="Failed to fetch diff"
            />
          )}
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default ExcelDiff;
