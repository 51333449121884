import {ModelMetadataOptionsDTOOptions} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {
  requestModelMetadataAddOption,
  requestModelMetadataDeleteOption,
  requestModelMetadataOptionsCount,
  requestModelMetadataOptions,
  requestModelMetadataOptionsUsage,
  requestModelMetadataUpdateOption,
} from './api';

export enum ModelMetadataActions {
  REQUEST_OPTIONS = 'MODEL_METADATA/REQUEST_OPTIONS',
  REQUEST_OPTIONS_COUNT = 'MODEL_METADATA/REQUEST_OPTIONS_COUNT',
  REQUEST_OPTIONS_USAGE = 'MODEL_METADATA/REQUEST_OPTIONS_USAGE',
  REQUEST_OPTION_ADD = 'MODEL_METADATA/REQUEST_OPTION_ADD',
  REQUEST_OPTION_UPDATE = 'MODEL_METADATA/REQUEST_OPTION_UPDATE',
  REQUEST_OPTION_DELETE = 'MODEL_METADATA/REQUEST_OPTION_DELETE',
}

export type ModelMetadataAction =
  | AsyncAction<ModelMetadataActions.REQUEST_OPTIONS, Await<ReturnType<typeof requestModelMetadataOptions>>, []>
  | AsyncAction<
      ModelMetadataActions.REQUEST_OPTIONS_COUNT,
      Await<ReturnType<typeof requestModelMetadataOptionsCount>>,
      Parameters<typeof requestModelMetadataOptionsCount>
    >
  | AsyncAction<
      ModelMetadataActions.REQUEST_OPTIONS_USAGE,
      Await<ReturnType<typeof requestModelMetadataOptionsUsage>>,
      Parameters<typeof requestModelMetadataOptionsUsage>
    >
  | AsyncAction<
      ModelMetadataActions.REQUEST_OPTION_ADD,
      Await<ReturnType<typeof requestModelMetadataAddOption>>, //ModelMetadataOptionDTO
      Parameters<typeof requestModelMetadataAddOption>
    >
  | AsyncAction<
      ModelMetadataActions.REQUEST_OPTION_UPDATE,
      Await<ReturnType<typeof requestModelMetadataUpdateOption>>, //void
      Parameters<typeof requestModelMetadataUpdateOption>
    >
  | AsyncAction<
      ModelMetadataActions.REQUEST_OPTION_DELETE,
      Await<ReturnType<typeof requestModelMetadataDeleteOption>>, //void
      Parameters<typeof requestModelMetadataDeleteOption>
    >;

export type ModelMetadata = {[key: string]: string};

export const modelMetadataOptionsLabels: {[key in keyof ModelMetadataOptionsDTOOptions]: string} = {
  modelTypes: 'Model Types',
  therapeuticAreas: 'Therapeutic Areas',
  products: 'Products',
  diseases: 'Diseases',
  studyNumbers: 'Trial Numbers',
  countries: 'Countries',
  stagesOfModelDevelopment: 'Stages of Model Development',
  fileTag: 'File Types',
};

export const modelMetadataOptionProperties: {[key in keyof ModelMetadataOptionsDTOOptions]: string} = {
  modelTypes: 'model_type',
  therapeuticAreas: 'therapeutic_area',
  products: 'product',
  diseases: 'disease',
  studyNumbers: 'study_number',
  countries: 'country',
  stagesOfModelDevelopment: 'stage_of_model_development',
  fileTag: 'file_tag',
};

export type ModelMetadataOptionSimplifyDTO = {[key in keyof ModelMetadataOptionsDTOOptions]: string[]};

export const dynamicSearchTypes: {[key: string]: string} = {
  'Therapeutic Area': 'therapeutic_area',
  'Name Of Disease': 'disease',
  'Trial Number': 'study_numbers',
  Product: 'products',
  'Global/Adaptation': 'global_adaptation',
  Countries: 'countries',
  'Model Type': 'model_types',
  Platform: 'platform',
};
