import React, {useCallback, useState} from 'react';
import DefaultPage from '../../layout/common/DefaultPage';
import {Button, Container, Grid} from '@material-ui/core';
import PageHeaderTitle from '../../core/Header/PageHeader/PageHeaderTitle';
import {DesignAndDevelopment_02 as DesignAndDevelopment02} from '@carbon/pictograms-react';
import TableWithSortAndPagination, {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../../core/TableWithSortAndPagination';
import {GridState, Order, useGridState} from '../../../store/grid';
import {
  ScenarioExecutionGroupDetailDTO,
  ScenarioExecutionDTO,
  ScenarioExecutionGroupListDTO,
  ScenarioExecutionOrderBy,
  ScenarioOrderBy,
} from 'hemwb-api';
import {useGridLoader} from '../../core/TableWithSortAndPagination/useGridLoader';
import styles from './ScenarioExecutionQueue.module.scss';
import {fullName} from '../../../store/user/utils';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../router/routes';
import RefreshIcon from '@material-ui/icons/Refresh';
import {tid} from '../../../testUtils';
import ScenarioInstanceList from './ScenarioInstanceList';
import {scenarioExecutionGroupLoadList, scenarioExecutionSelector} from '../../../store/scenarioExecution';
import ExecutionStatus from './ExecutionStatus';
import {useDispatch} from 'react-redux';
import {useSelector} from '../../../store/rootReducer';
import {useExecutionStatusUpdate} from '../../../websocket/useExecutionStatusUpdate';
import {useFlashRow} from '../../core/TableWithSortAndPagination/useFlashRow';

const RestrictedAccessText = () => <span className={styles.restricted}>Restricted access</span>;

const ModelDetailLink = ({model}: ScenarioExecutionGroupDetailDTO) => {
  return model ? (
    <Link to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}>{model.name}</Link>
  ) : (
    <RestrictedAccessText />
  );
};

const scenarioInstanceListComp = (executionItem: ScenarioExecutionGroupDetailDTO) => {
  return <ScenarioInstanceList scenarioExecutionInstance={executionItem.executionDetails} />;
};

const columns: Column<ScenarioExecutionGroupDetailDTO>[] = [
  {
    id: ScenarioExecutionOrderBy.ScenarioName,
    label: 'Instance Name',
    sortable: true,
    value: (executionItem) => executionItem.name || '',
    //display: ScenarioDetailLink,
    classes: {th: styles.colScenarioNameHeading},
  },
  {
    id: ScenarioExecutionOrderBy.Filename,
    label: 'File Name',
    sortable: true,
    value: () => '',
  },
  {
    id: 'modelName',
    label: 'Model Name',
    value: (executionItem) => executionItem.model?.name,
    display: ModelDetailLink,
    classes: {th: styles.colModelNameHeading},
  },
  {
    id: ScenarioExecutionOrderBy.ExecutedBy,
    label: 'Executed by',
    sortable: true,
    value: (executionItem) => (executionItem.executedBy ? fullName(executionItem.executedBy) : ''),
    classes: {th: styles.colUserHeading},
  },
  {
    id: ScenarioExecutionOrderBy.CreatedOn,
    label: 'Executed on',
    sortable: true,
    value: (executionItem) => executionItem.createdOn,
    display: (executionItem) => executionItem.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
    classes: {th: styles.colDateHeading},
  },
  {
    id: ScenarioExecutionOrderBy.Status,
    label: 'Status',
    sortable: true,
    value: (executionItem) => executionItem.status,
    display: ExecutionStatus,
    classes: {th: styles.colStatusHeading},
  },
];

const ScenarioExecutionQueue: React.FC = () => {
  useExecutionStatusUpdate();
  const dispatch = useDispatch();
  const [selectedExecutionItem, setSelectedExecutionItem] = useState<ScenarioExecutionDTO>();

  const gridState = useGridState({orderBy: ScenarioOrderBy.CreatedOn, order: Order.DESC, rowsPerPage: 50});
  const loader = useCallback(
    (gState: GridState) => {
      return scenarioExecutionGroupLoadList(dispatch, {
        pageSize: gState.rowsPerPage,
        pageIndex: gState.page * gState.rowsPerPage,
        orderBy: ((gState.orderBy as any) as ScenarioExecutionOrderBy) || ScenarioExecutionOrderBy.CreatedOn,
        orderDesc: gState.order === Order.DESC,
      });
    },
    [dispatch],
  );
  const {loading, reload} = useGridLoader<ScenarioExecutionGroupListDTO>(gridState, loader);

  const handleClickRow = useCallback(
    (executionItem: ScenarioExecutionDTO) => {
      if (executionItem?.id === selectedExecutionItem?.id) {
        setSelectedExecutionItem(undefined);
      } else {
        setSelectedExecutionItem(executionItem);
      }
    },
    [selectedExecutionItem, setSelectedExecutionItem],
  );

  const data = useSelector((state) => scenarioExecutionSelector(state).groupByList.value);
  const lastStatusUpdate = useSelector((state) => scenarioExecutionSelector(state).lastStatusUpdate);
  useFlashRow(lastStatusUpdate?.id);

  return (
    <DefaultPage style={{overflow: 'hidden'}}>
      <Container maxWidth={false} className="pageHeader" style={{backgroundColor: '#002172'}}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <PageHeaderTitle
            sm={12}
            md={5}
            xl={3}
            title="Execution Queue"
            label="Monitor Status of Executed Instances"
            Icon={<DesignAndDevelopment02 className="icon32" />}
          />
        </Grid>
      </Container>

      <div className="listPanel">
        {!!lastStatusUpdate && <div className={styles.newChangesText}>New changes in queue</div>}
        <Button
          onClick={() => reload()}
          variant="contained"
          color="default"
          startIcon={<RefreshIcon />}
          {...tid('btn', 'refresh-list')}>
          Refresh
        </Button>
      </div>
      <TableWithSortAndPagination
        client={false}
        getRowId={(executionItem) => executionItem.groupId.toString()}
        getExpandedRow={scenarioInstanceListComp}
        rows={data?.list || []}
        total={data?.total || 0}
        columns={columns}
        onClickRow={handleClickRow}
        isRowSelected={(executionItem) => executionItem?.id === selectedExecutionItem?.id}
        gridState={gridState}
        loading={loading}
        noResultsMessage="No executions were found"
      />
    </DefaultPage>
  );
};

export default ScenarioExecutionQueue;
