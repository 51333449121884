import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {ModelFileTags, ModelTreeNode} from '../../../../../store/modelFile';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {nodeHasTag} from '../../../../../store/modelFile/utils';
import {Button} from '@material-ui/core';
import {FolderAdd16} from '@carbon/icons-react';
import {useFilePickerDialog} from '../../../../core/FilePickerDialog/FilePickerDialog';

type Props = {
  model1: ModelDTO | null;
  model2: ModelDTO | null;
  files1: ModelTreeNode[] | null;
  files2: ModelTreeNode[] | null;
};
export const useExcelDiffHead = ({model1, model2, files1, files2}: Props) => {
  const excelFiles1 = useMemo(() => {
    return files1?.filter((file) => nodeHasTag(file, ModelFileTags.MODEL_FILE_EXCEL));
  }, [files1]);
  const excelFiles2 = useMemo(() => {
    return files2?.filter((file) => nodeHasTag(file, ModelFileTags.MODEL_FILE_EXCEL));
  }, [files2]);

  const [excelFile1, setExcelFile1] = useState<ModelTreeNode>();
  const [excelFile2, setExcelFile2] = useState<ModelTreeNode>();

  useEffect(() => {
    if (excelFiles1?.length === 1) {
      setExcelFile1(excelFiles1[0]);
    }
  }, [excelFiles1]);
  useEffect(() => {
    if (excelFiles2?.length === 1) {
      setExcelFile2(excelFiles2[0]);
    }
  }, [excelFiles2]);

  const handleSelectFile1 = useCallback(
    (uuid) => {
      setExcelFile1(files1!.find((n) => n.uuid === uuid));
    },
    [files1],
  );
  const handleSelectFile2 = useCallback(
    (uuid) => {
      setExcelFile2(files2!.find((n) => n.uuid === uuid));
    },
    [files2],
  );

  const {open: openFilePicker1, DialogComponent: FilePicker1} = useFilePickerDialog(
    handleSelectFile1,
    excelFiles1 || [],
    excelFile1?.uuid || '',
  );
  const {open: openFilePicker2, DialogComponent: FilePicker2} = useFilePickerDialog(
    handleSelectFile2,
    excelFiles2 || [],
    excelFile2?.uuid || '',
  );

  const processExcelDiff =
    model1?.metadata.type === 'ExcelNative' && model2?.metadata.type === 'ExcelNative' && !!files1 && !!files2;

  const Component = () => {
    if (!processExcelDiff) {
      return null;
    }
    return (
      <>
        <tr>
          <td>
            <h2 className="headlineBlue">File to compare</h2>
            <Button
              variant="outlined"
              onClick={openFilePicker1}
              disabled={excelFiles1!.length === 1}
              endIcon={<FolderAdd16 />}>
              {excelFile1?.name || 'Select a file'}
            </Button>
          </td>
          <td>
            <h2 className="headlineBlue">File to compare </h2>
            <Button
              variant="outlined"
              onClick={openFilePicker2}
              disabled={excelFiles2!.length === 1}
              endIcon={<FolderAdd16 />}>
              {excelFile2?.name || 'Select a file'}
            </Button>
          </td>
        </tr>
        {FilePicker1}
        {FilePicker2}
      </>
    );
  };

  return {Component: <Component />, excelFile1, excelFile2};
};
