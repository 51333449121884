import {tokenize, markEdits, pickRanges} from 'react-diff-view';
import flatMap from 'lodash/flatMap';
import {DiffChange, DiffHunk} from './types';

type Range = {
  type: string;
  start: number;
  length: number;
  properties: object;
};

const TOKEN_TYPE_SPACE = 'space';

const findLeadingRange = (change: DiffChange) => {
  const [spaces] = /^\s*/.exec(change.content) as string[];
  return spaces
    ? {
        type: TOKEN_TYPE_SPACE,
        start: 0,
        length: spaces.length,
        properties: {value: spaces},
      }
    : null;
};

const findTrailingRange = (change: DiffChange) => {
  let i = change.content.length - 1;
  while (i >= 0 && /\s/.test(change.content.charAt(i))) --i;
  const spaces = change.content.substring(i + 1);
  return spaces
    ? {
        type: TOKEN_TYPE_SPACE,
        start: change.content.length - spaces.length,
        length: spaces.length,
        properties: {value: spaces},
      }
    : null;
};

const pickLeadingAndTrailingSpaces = (hunks: DiffHunk[]) => {
  const changes = flatMap(hunks, (hunk) => hunk.changes);
  const [oldRanges, newRanges] = changes.reduce(
    ([oldRanges, newRanges], change) => {
      const leadingRange = findLeadingRange(change);
      const trailingRange = findTrailingRange(change);
      const pushRange = (ranges: Range[]) => {
        leadingRange && ranges.push(leadingRange);
        trailingRange && ranges.push(trailingRange);
      };

      if (!change.isInsert) {
        pushRange(oldRanges);
      }
      if (!change.isDelete) {
        pushRange(newRanges);
      }

      return [oldRanges, newRanges];
    },
    [[], []],
  );
  return pickRanges(oldRanges, newRanges);
};

export default (hunks: DiffHunk[]) => {
  if (!hunks) {
    return undefined;
  }

  const options = {
    highlight: false,
    enhancers: [markEdits(hunks, {type: 'block'}), pickLeadingAndTrailingSpaces(hunks)],
  };

  try {
    return tokenize(hunks, options);
  } catch (ex) {
    return undefined;
  }
};
