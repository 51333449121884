export enum EditLineageMode {
  SELECT_PARENT,
  SELECT_ROOT,
}

export type VersionFormValues = {
  version_release_of_model: string;
  version_log: string;
  changeLogFileName: string;
  changelog_file: string;
};
