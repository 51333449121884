import React, {FormEvent, FormHTMLAttributes, forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import formStyles from './Form.module.scss';
import {FieldGroup, FormGroup, GroupProps} from 'react-reactive-form';

export type FormRenderProps = FormGroup & {submitting: boolean};

export type FormProps<Values = any> = Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  group: FormGroup;
  onSubmit?: (value: Values) => any | Promise<any>;
  render: (control: FormGroup & {submitting: boolean}) => React.ReactElement<any> | React.ReactElement<any>[];
  validate?: boolean;
  FieldGroupProps?: Omit<GroupProps, 'render'>;
};

export const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const {group, onSubmit, render, validate = true, FieldGroupProps, ...FormProps} = props;
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  (group as FormGroup & {submitting: boolean}).submitting = submitting;

  const onValidationFinished = useCallback(() => {
    if ((!validate || group.valid) && onSubmit) {
      let response = onSubmit(group.value);
      if (!response) {
        return;
      }
      if (typeof response.catch === 'function') {
        // disregard rejections
        response = response.catch(() => Promise.resolve());
      }
      if (typeof response.finally === 'function') {
        group.disable();
        setSubmitting(true);
        response.finally(() => {
          if (isMounted.current) {
            setSubmitting(false);
            group.enable();
          }
        });
      }
    }
  }, [validate, onSubmit, group]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      group.markAsSubmitted();
      event.preventDefault();

      if (group.pending) {
        setSubmitting(true);
        const statusChangeHandler = () => {
          if (!group.pending) {
            group.statusChanges.unsubscribe(statusChangeHandler);
            if (isMounted.current) {
              setSubmitting(false);
              onValidationFinished();
            }
          }
        };
        group.statusChanges.subscribe(statusChangeHandler);
      } else {
        onValidationFinished();
      }
    },
    [group, onValidationFinished],
  );

  return (
    <form ref={ref} onSubmit={handleSubmit} className={formStyles.form} {...FormProps}>
      <FieldGroup control={group} render={render as any} {...FieldGroupProps} />
    </form>
  );
});
Form.displayName = 'Form';
