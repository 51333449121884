import React, {useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import ModelCloneSideBarModal from './ModelCloneSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import {ModelActionProps} from '../../../actionMenuTypes';

export const useModelCloneSideBarModal = (props: Partial<ModelActionProps>) => {
  const {model, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model);

  const ModalComponent = useMemo(() => {
    return (
      model && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '80rem'}}>
            {!!buttonRef && <ModelCloneSideBarModal onCancel={closeModal} model={model} onSuccess={onSuccess} />}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, onSuccess, model]);

  return {
    openModal,
    ModalComponent,
  };
};
