import React, {useCallback, useState} from 'react';
import {ModelDTO, ScenarioDTO, ScenarioUpdateDTO} from 'hemwb-api';
import {useDispatch, useStore} from 'react-redux';
import VisualizationAnalysisProgressSaveForm, {
  AnalysisProgressSaveFormValues,
} from './VisualizationAnalysisProgressSaveForm';
import {scenarioCreate, scenarioUpdate, scenarioUpdateInputs} from '../../../../../store/scenario';
import {visualizationInitAnalysisData, visualizationSelector} from '../../../../../store/visualization';
import {useModelInputLoaderWithParams} from '../../../../../store/modelInputs';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {useHistory} from 'react-router';
import {stringifyScenarioParameters} from '../../../../core/ModelInput/ScenarioParameters/inputs/stringifyScenarioParameters';

export type VisualizationAnalysisProgressSaveProps = {
  model: ModelDTO;
  displaySubModelId: number;
  scenario?: ScenarioDTO;
  onCancel: () => void;
  onSuccess: () => void;
  onSuccessRoute?: Routes;
};

const VisualizationAnalysisProgressSave: React.FC<VisualizationAnalysisProgressSaveProps> = ({
  model,
  displaySubModelId,
  scenario,
  onSuccess,
  onCancel,
  onSuccessRoute,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const loadInput = useModelInputLoaderWithParams();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values: AnalysisProgressSaveFormValues) => {
      const {action, name} = values;

      const storedState = visualizationSelector(store.getState());
      const {selectedScenarios = {}, scenarios, subModel} = storedState.selection || {};
      const parameters =
        storedState.parameters?.displaySubModelId === subModel?.displayExecutionSetupId
          ? storedState.parameters?.parameters
          : undefined;

      setSubmitting(true);

      let newScenario;

      const scenarioUpdateDTO: ScenarioUpdateDTO = {
        name,
        hidden: false,
        displayScenarios: scenarios!
          .filter((s) => selectedScenarios[s.id])
          .map((s, index) => ({
            id: s.id,
            label: `TODO label (${s.name})`,
            order: index + 1,
          })),
      };

      if (action === 'create' || !scenario) {
        newScenario = await scenarioCreate(dispatch, displaySubModelId, scenarioUpdateDTO);
      } else {
        newScenario = await scenarioUpdate(dispatch, scenario.id, scenarioUpdateDTO);
      }

      if (parameters) {
        const input = await loadInput([displaySubModelId]);
        await scenarioUpdateInputs(dispatch, newScenario.id, {
          modelInputId: input!.id,
          value: stringifyScenarioParameters(input!, parameters),
        });
      }

      dispatch(
        visualizationInitAnalysisData(newScenario.id, {
          modelUuid: model.uuid,
          selection: storedState.selection,
          parameters: storedState.parameters,
        }),
      );

      if (onSuccessRoute && action === 'create') {
        // @ts-ignore
        history.push(getUrlRoute(onSuccessRoute, {modelUuid: model.uuid, scenarioId: newScenario.id}));
      }

      onSuccess();
    },
    [dispatch, history, store, model, displaySubModelId, loadInput, scenario, onSuccess, onSuccessRoute],
  );

  return (
    <>
      <h2>Save Analysis</h2>
      <VisualizationAnalysisProgressSaveForm
        modelUuid={model.uuid}
        executionSetupId={displaySubModelId}
        scenario={scenario}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitting={submitting}
      />
    </>
  );
};

export default VisualizationAnalysisProgressSave;
