import {Message, MessageActions} from './types';
import {createMessage} from './utils';

export const messageNetworkError = (response: Response, body?: any) => ({
  type: MessageActions.NETWORK_ERROR,
  response,
  body,
});

export const messageAdd = (text: Message['text'], type: Message['type'], disableGrouping?: boolean) => ({
  type: MessageActions.ADD,
  message: createMessage(text, type, disableGrouping),
});

export const messageRemove = (message: Message) => ({
  type: MessageActions.REMOVE,
  message,
});

export const messageUpdate = (message: Message) => ({
  type: MessageActions.UPDATE,
  message,
});
