import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {useSubModelEditSideBarModal} from './useSubModelEditSideBarModal';
import {canEditSubModel} from '../../../../../permissions/usePermissions';
import {Edit16} from '@carbon/icons-react';

const SubModelEditActionMenuButton: React.FC<Partial<SubModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useSubModelEditSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.EDIT)}
        permission={canEditSubModel(props.model, props.subModel)}
        onClick={openModal}
        icon={<Edit16 />}
        text="Edit"
      />
      {ModalComponent}
    </>
  );
};

export default SubModelEditActionMenuButton;
