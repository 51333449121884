import {RootState} from '../rootReducer';

export const modelSelector = (state: RootState) => state.model;

export const modelListSelector = (state: RootState) => state.model.list;

export const modelListBasedOnUserTypeSelector = (state: RootState) => state.model.externalUserModelsList;

export const modelEligibleListSelector = (state: RootState) => state.model.eligibleList;

export const modelSelectorBuilder = (uuid: string) => (state: RootState) => modelSelector(state).models[uuid];
