import {
  useReduxLoader,
  useReduxManualLoader,
  useReduxManualLoaderWithParams,
  useSimpleReduxLoader,
} from '../useReduxLoader';
import {bitbucketLoadBrowse, bitbucketLoadHistory, bitbucketLoadProjects, bitbucketLoadRepositories} from './actions';
import {
  bitbucketBrowseSelectorBuilder,
  bitbucketHistorySelectorBuilder,
  bitbucketProjectsSelector,
  bitbucketRepositoriesSelectorBuilder,
} from './selectors';
import {Dispatch} from 'redux';
import {GetHistoryRequest, ModelDTO} from 'hemwb-api';

export const useBitbucketProjects = () => {
  return useSimpleReduxLoader(bitbucketProjectsSelector, bitbucketLoadProjects);
};

const loadRepositoriesIgnoreNull = (dispatch: Dispatch, projectName?: string) => {
  return projectName ? bitbucketLoadRepositories(dispatch, projectName) : Promise.resolve();
};
loadRepositoriesIgnoreNull.clear = bitbucketLoadRepositories.clear;

export const useBitbucketRepositories = (projectName?: string) => {
  const repos = useReduxLoader(bitbucketRepositoriesSelectorBuilder, loadRepositoriesIgnoreNull, projectName);
  return projectName ? repos : null;
};

export const useBitbucketBrowseLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(bitbucketBrowseSelectorBuilder, bitbucketLoadBrowse);
};

const loadHistoryIgnoreInvalid = (dispatch: Dispatch, param: GetHistoryRequest) => {
  return param.projectName && param.repoName ? bitbucketLoadHistory(dispatch, param) : Promise.resolve();
};
loadHistoryIgnoreInvalid.clear = bitbucketLoadHistory.clear;
export const useBitbucketHistory = (model: ModelDTO) => {
  const {bitbucket_project: projectName, bitbucket_repository: repoName} = model.metadata;
  const history = useReduxLoader(bitbucketHistorySelectorBuilder, loadHistoryIgnoreInvalid, {
    projectName,
    repoName,
  });
  return projectName && repoName ? history : null;
};

export const useBitbucketHistoryLoader = (model: ModelDTO) => {
  return useReduxManualLoader(bitbucketHistorySelectorBuilder, loadHistoryIgnoreInvalid, {
    projectName: model.metadata.bitbucket_project,
    repoName: model.metadata.bitbucket_repository,
  });
};

export const useBitbucketHistoryLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(bitbucketHistorySelectorBuilder, bitbucketLoadHistory);
};
