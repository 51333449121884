import React, {HTMLAttributes} from 'react';
import styles from './Stepper.module.scss';
import clsx from 'clsx';
import WizardStepIcon from '../WizardStepIcon/WizardStepIcon';
import {Tooltip} from '@material-ui/core';

export enum StepStatus {
  DEFAULT,
  COMPLETED,
  WARNING,
  ERROR,
}

type Step = HTMLAttributes<HTMLDivElement> & {
  title: string;
  status: StepStatus;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
};

type StepperProps = HTMLAttributes<HTMLDivElement> & {
  steps: Step[];
  activeStep: number;
  nonLinear?: boolean;
  getStepStatus?: (index: number) => StepStatus;
  variableWidths?: boolean;
};

const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep,
  nonLinear = false,
  className,
  variableWidths = false,
  ...restStepperProps
}) => {
  return (
    <div className={clsx(styles.container, className)} {...restStepperProps}>
      {steps.map(({title, label, onClick, status, tooltip, disabled, ...restStepProps}, index) => {
        const disabledElement = (!nonLinear && activeStep < index) || disabled;
        const Wrapper = (props: any) =>
          tooltip ? (
            <Tooltip title={tooltip} placement="top">
              {props.children}
            </Tooltip>
          ) : (
            props.children
          );
        return (
          <Wrapper key={title}>
            <div
              style={variableWidths ? {flexGrow: 1} : {width: `calc(100% / ${steps.length})`}}
              className={clsx(styles.step, {
                [styles.disabled]: disabledElement,
                [styles.completed]: status === StepStatus.COMPLETED,
                [styles.warning]: status === StepStatus.WARNING,
                [styles.error]: status === StepStatus.ERROR,
                [styles.active]: activeStep === index,
              })}
              onClick={(event) => !disabledElement && onClick?.(event)}
              {...restStepProps}>
              <WizardStepIcon
                completed={status === StepStatus.COMPLETED}
                active={index === activeStep}
                className={styles.icon}
              />
              <div className={styles.labelContainer}>
                <span className={clsx(styles.stepTitle, 'stepTitle')}>{title}</span>
                <span className={clsx(styles.stepLabel, 'stepLabel')}>{label}</span>
              </div>
            </div>
          </Wrapper>
        );
      })}
    </div>
  );
};

export default Stepper;
