import React, {useCallback, useMemo, useState} from 'react';
import ScenarioCancel from '../../../scenario/common/ScenarioCancel/ScenarioCancel';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import styles from '../VisualizationScenarioAction.module.scss';
import {ScenarioActionProps} from '../../../actionMenuTypes';

export const useScenarioCancelDialog = () => {
  const [openProps, setOpenProps] = useState<ScenarioActionProps>();
  const handleOnCancel = useCallback(() => {
    setOpenProps(undefined);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setOpenProps(undefined);
    openProps?.onSuccess?.();
  }, [openProps]);

  const Component = useMemo(() => {
    return !openProps ? null : (
      <DialogGeneric open={true} title="Cancel Scenario" childrenAsText={false} includesDialogActions={true}>
        <div className={styles.dialog}>
          <ScenarioCancel
            onSuccess={handleOnSuccess}
            onCancel={handleOnCancel}
            model={openProps.model}
            subModel={openProps.subModel}
            scenario={openProps.scenario}
          />
        </div>
      </DialogGeneric>
    );
  }, [openProps, handleOnCancel, handleOnSuccess]);

  return {
    open: setOpenProps,
    Component,
  };
};
