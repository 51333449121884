import React from 'react';
import {InputType, NumericAttributes, rangeInputTypes} from '../../InputDefinition/types';
import {FormControl, ValidatorFn, Validators} from 'react-reactive-form';
import {
  DateControl,
  TextFieldControl,
  validateHigherThan,
  validateInteger,
  validateLowerThan,
  validateNumber,
} from '../../../Form';
import {Grid} from '@material-ui/core';
import GridItem from '../../../Form/GridItem';

type NumberInputPrimitiveProps = {
  control: FormControl;
  type: keyof typeof rangeInputTypes;
  disabled?: boolean;
};

export const NumberInputPrimitive: React.FC<NumberInputPrimitiveProps> = ({control, type, disabled}) => {
  const Component = type === InputType.DATE ? DateControl : TextFieldControl;

  return (
    <Grid container direction="row" alignItems="flex-start" justify="space-between">
      <GridItem>
        <Component label="Value" control={control} name="value" disabled={disabled} strict={false} />
      </GridItem>
    </Grid>
  );
};

export const numberInputPrimitiveControlBuilder = (
  definition: Partial<NumericAttributes>,
  initialValue?: string | number,
  validator?: ValidatorFn,
) => {
  const validators = [];
  if (definition.required) {
    validators.push(Validators.required);
  }
  if (definition.min) {
    validators.push(validateHigherThan(new FormControl(definition.min), true));
  }
  if (definition.max) {
    validators.push(validateLowerThan(new FormControl(definition.max), true));
  }
  if (definition.type === InputType.INTEGER) {
    validators.push(validateInteger);
  } else if (definition.type !== InputType.DATE) {
    validators.push(validateNumber);
  }

  if (validator) {
    validators.push(validator);
  }

  return new FormControl(initialValue === undefined ? definition.defaultValue : initialValue, validators);
};
