import {ScenarioExecutionAction, ScenarioExecutionActions} from './types';
import {
  FindScenarioExecutionsRequest,
  ScenarioExecutionListDTO,
  ScenarioExecutionGroupListDTO,
  ScenarioExecutionStatusUpdateDTO,
} from 'hemwb-api';
import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';

export type ScenarioExecutionState = {
  list: AsyncActionState<ScenarioExecutionListDTO, [FindScenarioExecutionsRequest]>;
  groupByList: AsyncActionState<ScenarioExecutionGroupListDTO, [FindScenarioExecutionsRequest]>;
  lastStatusUpdate: ScenarioExecutionStatusUpdateDTO | null;
};

export const scenarioExecutionInitialState: ScenarioExecutionState = {
  list: buildInitialAsyncState<ScenarioExecutionListDTO>(),
  groupByList: buildInitialAsyncState<ScenarioExecutionGroupListDTO>(),
  lastStatusUpdate: null,
};

export const scenarioExecutionReducer = (
  state = scenarioExecutionInitialState,
  action: ScenarioExecutionAction,
): ScenarioExecutionState => {
  switch (action.type) {
    case ScenarioExecutionActions.REQUEST_LIST: {
      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          list: {...reduceAsyncAction(action), value: state.list.value},
        };
      }
      return {
        ...state,
        lastStatusUpdate: null,
        list: reduceAsyncAction(action),
      };
    }
    case ScenarioExecutionActions.REQUEST_GROUP_LIST: {
      if (action.phase === AsyncActionPhase.START) {
        return {
          ...state,
          groupByList: {...reduceAsyncAction(action), value: state.groupByList.value},
        };
      }
      return {
        ...state,
        lastStatusUpdate: null,
        groupByList: reduceAsyncAction(action),
      };
    }
    case ScenarioExecutionActions.UPDATE_STATUS: {
      const {statusUpdate} = action;
      if (state.list.value?.list.some((execution) => execution.id === statusUpdate.id)) {
        return {
          ...state,
          lastStatusUpdate: statusUpdate,
          list: {
            ...state.list,
            value: {
              ...state.list.value,
              list: state.list.value.list.map((execution) =>
                execution.id === statusUpdate.id ? {...execution, ...statusUpdate} : execution,
              ),
            },
          },
        };
      }

      return {
        ...state,
        lastStatusUpdate: statusUpdate,
      };
    }
  }
  return state;
};
