export const downloadFromResponse = async (response: Response) => {
  const contentDispositionHeader = response.headers.get('content-disposition');
  let fileName;
  if (contentDispositionHeader) {
    const matches = contentDispositionHeader.match(/"((?:\\.|[^"\\])*)"/);
    fileName = (matches && matches[1]) || undefined;
  }

  downloadBlob(await response.blob(), fileName);
};

export const downloadBlob = (blob: Blob, fileName?: string) => {
  // @ts-ignore
  if (navigator.msSaveBlob) {
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    if (fileName) {
      a.download = fileName;
    }
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};

export const sanitizeFilename = (filename: string) => filename.replace(/[^A-z0-9_]/gi, '_').toLowerCase();
