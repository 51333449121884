import {GuidingTourActions, GuidingTourAction} from './types';
import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestHasViewedGuidedTour} from './api';

export type GuidingTourState = {
  detail: {
    [key: string]: AsyncActionState<
      Await<ReturnType<typeof requestHasViewedGuidedTour>>,
      Parameters<typeof requestHasViewedGuidedTour>
    >;
  };
};

export const guidingTourInitialState: GuidingTourState = {
  detail: {},
};

export const guidingTourReducer = (state = guidingTourInitialState, action: GuidingTourAction): GuidingTourState => {
  if (action.type === GuidingTourActions.FETCH_DETAIL) {
    const [type, code] = action.params;
    return {
      ...state,
      detail: {
        ...state.detail,
        [`${type}-${code}`]: reduceAsyncAction(action),
      },
    };
  }
  return state;
};
