import React, {useMemo, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {tid} from '../../../../../testUtils';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {ExternalUserWithModel} from './types';
import {UserEnrollFormValues} from './UserEnrollForm';
import {ModelAccessRequest, UserModelAccess} from 'hemwb-api';
import TableWithSortAndPagination, {Column} from '../../../../core/TableWithSortAndPagination';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {Order, useGridState} from '../../../../../store/grid';
import {registerExternalUser} from '../../../../../store/user';
import styles from './RolesTable.module.scss';
import {getOrigin} from '../../../../router/routes';

export type PreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  values: UserEnrollFormValues;
  selectedModels: ExternalUserWithModel[];
};

const userGridColumns: Column<ExternalUserWithModel>[] = [
  {id: 'modelName', label: 'Model Name', sortable: true, value: (o) => o.name},
  {
    id: 'modelRole',
    label: 'Assign Model Role',
    sortable: true,
    value: (o) => o.role,
  },
];

export const PreviewDialog: React.FC<PreviewDialogProps> = ({open, values, selectedModels, onClose}) => {
  const gridState = useGridState({orderBy: 'updatedOn', order: Order.ASC});

  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    const modelAccesses: ModelAccessRequest[] = [];
    let modelRole: UserModelAccess;
    selectedModels.forEach((selectedModel) => {
      if (selectedModel.role === 'Model Owner') {
        modelRole = UserModelAccess.OWNER;
      }
      if (selectedModel.role === 'Model Viewer') {
        modelRole = UserModelAccess.VIEWER;
      }

      if (selectedModel.role === 'Calibration Modeler') {
        modelRole = UserModelAccess.CALIBRATORMODELER;
      }
      if (selectedModel.role === 'Scenario Modeler') {
        modelRole = UserModelAccess.SCENARIOMODELER;
      }
      if (selectedModel.role === 'Model Display Viewer') {
        modelRole = UserModelAccess.DISPLAYVIEWER;
      }
      modelAccesses.push({modelUuid: selectedModel.uuid, modelRole: modelRole});
    });

    registerExternalUser(dispatch, {
      firstname: values.firstName.trim(),
      lastname: values.lastName.trim(),
      email: values.email.toLowerCase(),
      modelAccesses: modelAccesses,
    }).then(() => {
      onClose();
      dispatch(
        messageAdd('Registration code has been emailed to the external user successfully', MessageTypes.SUCCESS),
      );
      window.location.replace(getOrigin() + '/#/externalization/list');
    });
  }, [dispatch, values, selectedModels, onClose]);
  return (
    <DialogGeneric
      open={open}
      title="Review and Generate Registration Invite"
      PaperProps={tid('dialog-user-preview') as {}}
      childrenAsText={false}>
      <>
        <div style={{display: 'table-row'}}>
          <div style={{display: 'table-cell'}} className={styles.previewBold}>
            First Name:&nbsp;
          </div>
          <div style={{display: 'table-cell'}}>{values.firstName.trim()}</div>
        </div>
        <div style={{display: 'table-row'}}>
          <div style={{display: 'table-cell'}} className={styles.previewBold}>
            Last Name:&nbsp;
          </div>
          <div style={{display: 'table-cell'}}>{values.lastName.trim()}</div>
        </div>
        <div style={{display: 'table-row', marginBottom: '5px'}}>
          <div style={{display: 'table-cell'}} className={styles.previewBold}>
            Email Id:&nbsp;
          </div>
          <div style={{display: 'table-cell'}}>{values.email.toLowerCase()}</div>
        </div>
        <TableWithSortAndPagination<ExternalUserWithModel>
          getRowId={(o) => o.uuid}
          // @ts-ignore
          rows={selectedModels}
          columns={userGridColumns}
          hidePagination={true}
          gridState={gridState}
        />
        <div className={styles.previewFooterContent}>
          The external user will receive email instructions and a unique registration code to submit there access
          request. The registration code will expire in 1 week.
        </div>
        <div className="buttonsContainer">
          <br />
          <br />
          <CancelButton onClick={onClose} {...tid('dialog-user-preview', 'cancel')} />
          <SubmitButton onClick={handleSubmit} {...tid('dialog-user-preview', 'submit')}>
            Generate Unique Code and Registration Invite
          </SubmitButton>
        </div>
      </>
    </DialogGeneric>
  );
};

export type PreviewDialogOpenProps = {
  onClose?: PreviewDialogProps['onClose'];
  values: PreviewDialogProps['values'];
  selectedModels: PreviewDialogProps['selectedModels'];
};

export const usePreviewDialog = () => {
  const [openProps, setOpenProps] = useState<PreviewDialogOpenProps>();
  const PreviewDialogComponent = useMemo(
    () =>
      !openProps ? null : (
        <PreviewDialog
          open={true}
          values={openProps.values}
          selectedModels={openProps.selectedModels}
          onClose={() => {
            openProps.onClose && openProps.onClose();
            setOpenProps(undefined);
          }}
        />
      ),
    [openProps, setOpenProps],
  );

  return {
    openPreviewDialog: setOpenProps as (props: PreviewDialogOpenProps) => void,
    PreviewDialog: PreviewDialogComponent,
  };
};
