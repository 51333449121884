import {BooleanHashMap} from '../commonTypes';
import {useCallback, useState} from 'react';

type InputType = BooleanHashMap | string[] | number[];

const arrayToBooleanHashMap = (arr?: string[] | number[]): BooleanHashMap => {
  if (!arr) {
    return {};
  }

  // @ts-ignore
  return arr.reduce((acc, val) => {
    acc[val.toString()] = true;
    return acc;
  }, ({} as any) as BooleanHashMap);
};

const getBooleanHashMap = (input: InputType): BooleanHashMap =>
  Array.isArray(input) ? arrayToBooleanHashMap(input) : input;

export const useToggleValues = (initialState: InputType = {}) => {
  const [values, setValues] = useState<BooleanHashMap>(() => getBooleanHashMap(initialState));

  const toggle = useCallback((v: string | number) => {
    setValues((prevState) => {
      const newState = {...prevState};
      if (newState[v]) {
        delete newState[v];
        return newState;
      }
      newState[v] = true;
      return newState;
    });
  }, []);

  const set = useCallback((v: string | number) => {
    setValues((prevState) => {
      if (prevState[v]) {
        return prevState;
      }
      const newState = {...prevState};
      newState[v] = true;
      return newState;
    });
  }, []);

  const replace = useCallback((input: InputType = {}) => {
    setValues(getBooleanHashMap(input));
  }, []);

  const isSelected = useCallback(
    (v: string | number): boolean => {
      return values[v] || false;
    },
    [values],
  );

  return {
    values,
    toggle,
    set,
    replace,
    isSelected,
  };
};
