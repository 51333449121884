import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_TH_STRING_OPTIONS,
  TO_UTC_DATE_STRING_OPTIONS,
} from '../TableWithSortAndPagination';
import React, {useEffect, useState} from 'react';
import {FileRenderItem, FileTreeTHProps} from './types';
import {Order, useGridState} from '../../../store/grid';
import {Development24, Locked16} from '@carbon/icons-react';
import {Tooltip} from '@material-ui/core';
import {useCurrentUser} from '../../../store/auth';

const Icon = Development24;
const FileNameTag: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    <Icon className="nodeIcon" />
    <span>{file.fileName}</span>
  </>
);

const FileOwnerTag: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    <span>
      {file.owner.lastname} {file.owner.firstname}
    </span>
  </>
);

const PrivateIcon: React.FC<{file: FileRenderItem}> = ({file}) => (
  <>
    {file.isPrivate && (
      <Tooltip
        title={
          <div style={{fontSize: 12}}>
            <span>
              The privacy settings for files is handled in <br />
              THEOREM
            </span>
          </div>
        }
        placement="top"
        arrow>
        <span>
          <Locked16 />
        </span>
      </Tooltip>
    )}
  </>
);

export const displayFileName = (file: FileRenderItem) => <FileNameTag file={file} />;
export const displayFileOwner = (file: FileRenderItem) => <FileOwnerTag file={file} />;
export const displayPrivateIcon = (file: FileRenderItem) => <PrivateIcon file={file} />;

const filesTHGridColumns: Column<FileRenderItem>[] = [
  {
    id: 'asOfDate',
    label: 'THEOREM "As-of Date"',
    sortable: true,
    value: (es) => es.asOfDate,
    display: (es) => (es.asOfDate ? es.asOfDate.toLocaleDateString('en-US', TO_LOCALE_DATE_TH_STRING_OPTIONS) : ''),
  },
  {id: 'fileName', label: 'File Name', sortable: false, value: (o) => o.fileName, display: displayFileName},
  {
    id: ',createdOn',
    label: 'File Uploaded On',
    sortable: true,
    value: (es) => es.createdOn,
    display: (es) => (es.createdOn ? es.createdOn.toLocaleDateString('en-US', TO_UTC_DATE_STRING_OPTIONS) : ''),
  },
  {
    id: 'owner',
    label: 'File Owner',
    sortable: false,
    value: (es) => es.owner.firstname || '',
    display: displayFileOwner,
  },
  {
    id: 'isPrivate',
    label: 'Private Files',
    sortable: false,
    value: (es) => es.isPrivate || '',
    display: displayPrivateIcon,
  },
  {
    id: 'comment',
    label: 'Comments',
    sortable: false,
    value: (es) => es.comment || '',
  },
  {
    id: 'fileTag',
    label: 'What type of file is this? ',
    sortable: false,
    value: (es) => es.fileTag || '',
  },
  {
    id: 'button',
    label: ' ',
    sortable: false,
    value: (es) => es.downloadButton || '',
  },
];

function DownloadFileTreeTH(props: FileTreeTHProps) {
  const {files, externalModel, renderButtons} = props;
  const gridState = useGridState({orderBy: 'asOfDate', order: Order.ASC});
  const [displayedFiles, setDisplayedFiles] = useState<FileRenderItem[]>([]);
  const {id: currentUserId} = useCurrentUser();

  useEffect(() => {
    const updatedRows = files.specification?.map((item) => {
      const fileItem = item as FileRenderItem;
      if (renderButtons) {
        if ((fileItem.userAccess && !fileItem.isPrivate) || item.owner.isid === currentUserId) {
          fileItem.downloadButton = renderButtons(item);
        }
      }
      return fileItem;
    });
    setDisplayedFiles(updatedRows);
  }, [files, renderButtons, currentUserId]);

  return (
    <>
      {files.specification?.length !== 0 && (
        <TableWithSortAndPagination<FileRenderItem>
          getRowId={(o) => o.uuid}
          externalModel={externalModel}
          // @ts-ignore
          rows={displayedFiles}
          columns={filesTHGridColumns}
          hidePagination={true}
          gridState={gridState}
        />
      )}
    </>
  );
}

export default DownloadFileTreeTH;
