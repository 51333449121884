import React, {useCallback, useRef, useState} from 'react';
import {ScenarioActionProps} from '../../actionMenuTypes';
import {useModelInput} from '../../../../store/modelInputs';
import {useGridState} from '../../../../store/grid';
import {JSONEditorInstance} from '../../../core/JSONEditor/types';
import {useSelector} from '../../../../store/rootReducer';
import {scenarioSelector, useScenarioDetail} from '../../../../store/scenario';
import {useFlashRow} from '../../../core/TableWithSortAndPagination/useFlashRow';
import {useScenarioStatusUpdates} from '../../../../websocket/useScenarioStatusUpdates';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {useLastValidValue} from '../../../../hooks/useLastValidValue';
import styles from './ScenarioDetail.module.scss';
import TableWithSortAndPagination from '../../../core/TableWithSortAndPagination';
import ScenarioError from './ScenarioError';
import ModelTextOverviewTile from '../../model/common/ModelTextOverview/ModelTextOverviewTile';
import {Path} from '@carbon/pictograms-react';
import JSONEditor from '../../../core/JSONEditor/JSONEditor';
import {getScenarioListGridColumns} from '../list/getScenarioListColumns';

const scenarioGridColumns = getScenarioListGridColumns().map((column) => ({
  ...column,
  sortable: false,
}));

const ScenarioDetailContent: React.FC<ScenarioActionProps> = ({model, subModel, scenario}) => {
  const _scenarioDetail = useScenarioDetail(Number(scenario.id));
  const scenarioDetail = useLastValidValue(_scenarioDetail);
  const input = useModelInput(scenarioDetail?.executionSetupId);

  const gridState = useGridState();
  const editorRef = useRef<JSONEditorInstance>();
  const [loading, setLoading] = useState(true);

  const handleEditorInit = useCallback(() => {
    if (scenarioDetail && scenarioDetail.input.modelInputId && editorRef.current) {
      setLoading(false);
      editorRef.current.updateText(scenarioDetail.input.value || '{}');
      editorRef.current.format();
    }
  }, [scenarioDetail]);

  const lastStatusUpdate = useSelector((state) => scenarioSelector(state).lastStatusUpdate);
  useFlashRow(lastStatusUpdate?.id);
  useScenarioStatusUpdates();

  if (!model || !subModel || !input || !scenario) {
    return <SuspenseNull />;
  }

  return (
    <>
      <div className={styles.tableContainer}>
        <TableWithSortAndPagination
          getRowId={(scenarioRow) => scenarioRow.id.toString()}
          rows={[scenario]}
          columns={scenarioGridColumns}
          gridState={gridState}
          hidePagination
        />
      </div>

      <div className={styles.contentContainer}>
        <ScenarioError scenario={scenario} />

        <ModelTextOverviewTile label={<>&nbsp;</>} Icon={<Path />} value="Input Data" />

        <div className={styles.editorContainer}>
          <JSONEditor
            readOnly
            loading={loading}
            jsonEditorRef={editorRef}
            onInit={handleEditorInit}
            // theme="ace/theme/idle_fingers"
          />
        </div>
      </div>
    </>
  );
};

export default ScenarioDetailContent;
