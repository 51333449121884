import React from 'react';
import {InputAttributes, InputType} from '../../InputDefinition/types';
import {FormArray} from 'react-reactive-form';
import GridItem from '../../../Form/GridItem';
import {TextFieldControl, validateHigherThan} from '../../../Form';
import {Grid} from '@material-ui/core';
import {InputGenerator} from '../types';
import InputHeader from './InputHeader';
import {numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type RangeInputProps = {
  control: FormArray;
  definition: InputAttributes;
  disabled?: boolean;
};

const RangeInput: React.FC<RangeInputProps> = ({control, definition, disabled}) => {
  if (!definition.visible) {
    return null;
  }

  const {controls} = control;
  return (
    <>
      <InputHeader definition={definition} />
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <TextFieldControl label="From" control={controls[0]} name="from" disabled={disabled} strict={false} />
        </GridItem>
        <GridItem>
          <TextFieldControl label="To" control={controls[1]} name="to" disabled={disabled} strict={false} />
        </GridItem>
      </Grid>
    </>
  );
};

const RangeStatic: React.FC<RangeInputProps> = ({control, definition}) => {
  if (!definition.visible) {
    return null;
  }

  const {controls} = control;
  return (
    <>
      <InputHeader definition={definition} />
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>From: {controls[0].value}</GridItem>
        <GridItem>To: {controls[1].value}</GridItem>
      </Grid>
    </>
  );
};

export const rangeInputGenerator: InputGenerator<InputType.RANGE> = {
  type: InputType.RANGE,
  buildControl: (definition, initialValues = []) => {
    const fromControl = numberInputPrimitiveControlBuilder(
      // @ts-ignore
      {...definition.from, type: definition.dataType, required: definition.required},
      initialValues?.[0],
    );
    const toControl = numberInputPrimitiveControlBuilder(
      // @ts-ignore
      {...definition.to, type: definition.dataType, required: definition.required},
      initialValues?.[1],
      validateHigherThan(fromControl, true),
    );
    return new FormArray([fromControl, toControl]);
  },
  render: RangeInput,
  renderStatic: RangeStatic,
};
