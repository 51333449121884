import React from 'react';
import {useSharedSideBarToggle} from '../../../../store/customSettings';
import {tid} from '../../../../testUtils';
import styles from '../../model/common/ModelActionMenu/ModelActionMenu.module.scss';
import SideBar from '../../../layout/common/SideBar';
import {List} from '@material-ui/core';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../test/types';
import SideBarButtonItem from '../../../layout/common/SideBarButtonItem';
import {Analytics16} from '@carbon/icons-react';
import TestVisualizationActionMenuButton from '../common/VisualizationTestResults/VisualizationTestResultsActionMenuButton';
import VisualizationAnalysisProgressSaveActionMenuButton from '../common/VisualizationAnalysisProgressSave/VisualizationAnalysisProgressSaveActionMenuButton';
import {ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import VisualizationFreshStartActionMenuButton from '../common/VisualizationFreshStart/VisualizationFreshStartActionMenuButton';
import {Routes} from '../../../router/routes';
import VisualizationCancelActionMenuButton from '../common/VisualizationCancel/VisualizationCancelActionMenuButton';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '22rem',
};

type VisualizationStep1ActionMenuProps = {
  modelUuid: string;
  model?: ModelDTO;
  scenario?: ScenarioDetailDTO;
  onClickCreateVisualization?: () => void;
  onClickGroupInstances?: () => void;
  onClickEditLabel?: () => void;
  scenarioInProgress: boolean;
};

const VisualizationStep1ActionMenu: React.FC<VisualizationStep1ActionMenuProps> = ({
  model,
  scenario,
  modelUuid,
  onClickCreateVisualization,
  scenarioInProgress,
}) => {
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);

  return (
    <SideBar
      position="right"
      {...sharedSidebar}
      {...tid('visualization1-action-sidebar')}
      className={styles.modelActionMenu}>
      <List dense component="nav">
        <SideBarButtonItem
          {...tid(VISUALIZATION_ACTION_MENU_IDS.CREATE_VISUALIZATION)}
          onClick={onClickCreateVisualization ? onClickCreateVisualization : () => null}
          disabled={!onClickCreateVisualization || scenarioInProgress}
          icon={<Analytics16 />}
          text="Set Display Parameters"
        />
        <VisualizationFreshStartActionMenuButton modelUuid={modelUuid} scenarioInProgress={scenarioInProgress} />
        <VisualizationAnalysisProgressSaveActionMenuButton
          model={model}
          scenario={scenario}
          onSuccessRoute={Routes.VISUALIZATION_STEP_1}
          scenarioInProgress={scenarioInProgress}
        />
        <TestVisualizationActionMenuButton model={model} scenarioInProgress={scenarioInProgress} />
        <VisualizationCancelActionMenuButton scenario={scenario || undefined} model={model} />
      </List>
    </SideBar>
  );
};

export default VisualizationStep1ActionMenu;
