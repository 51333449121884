import {CustomSettings, CustomSettingsAction, CustomSettingsActions} from './types';

export type CustomSettingsState = CustomSettings;

export const customSettingsInitialState = {
  isSidebarCollapsed: false,
};

export const customSettingsReducer = (
  state = customSettingsInitialState,
  action: CustomSettingsAction,
): CustomSettingsState => {
  switch (action.type) {
    case CustomSettingsActions.INIT:
    case CustomSettingsActions.UPDATE: {
      return {
        ...state,
        ...action.customSettings,
      };
    }
  }
  return state;
};
