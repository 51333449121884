import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {FormControl, FormControlLabel, InputLabel, Switch} from '@material-ui/core';
import {getVisibleErrors} from './utils';

type SwitchControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label?: React.ReactNode;
  onOffLabel?: boolean;
  inputProps?: any;
  plural?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export const SwitchControl: React.FC<SwitchControlProps> = ({
  label,
  onOffLabel = false,
  plural,
  required = false,
  disabled = false,
  inputProps,
  ...FieldControlProps
}) => (
  <FieldControl
    strict={false}
    {...FieldControlProps}
    render={(renderProps) => {
      const {handler, value} = renderProps;
      const visibleErrors = getVisibleErrors(renderProps);
      const hasVisibleErrors = Object.keys(visibleErrors).length > 0;
      const {onChange} = handler();
      const onOffLabels = () => {
        return value ? 'On' : 'Off';
      };
      return (
        <FormControl fullWidth error={hasVisibleErrors} hiddenLabel={true} className="MuiFormControl-hem">
          {label && (
            <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
              {label}
            </InputLabel>
          )}
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                classes={{root: label ? 'withLabel' : 'noLabel'}}
                checked={value}
                onChange={(x, v) => onChange(v)}
                color="primary"
                inputProps={inputProps}
              />
            }
            label={!onOffLabel ? '' : onOffLabels()}
          />
        </FormControl>
      );
    }}
  />
);
