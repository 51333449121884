import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {useSubModelAddSideBarModal} from './useSubModelAddSideBarModal';
import {ModelSectionActionProps} from '../../../actionMenuTypes';
import {canAddSubModel} from '../../../../../permissions/usePermissions';
import {AddAlt16} from '@carbon/icons-react';

const SubModelAddActionMenuButton: React.FC<Partial<ModelSectionActionProps>> = (props) => {
  const {ModalComponent, openModal} = useSubModelAddSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.ADD)}
        permission={canAddSubModel(props.model)}
        onClick={openModal}
        icon={<AddAlt16 />}
        text="Add"
      />
      {ModalComponent}
    </>
  );
};

export default SubModelAddActionMenuButton;
