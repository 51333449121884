import React, {useMemo} from 'react';
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {DocumentBlank24, Document24, Development24} from '@carbon/icons-react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {Column, EnhancedRow, TableWithSortAndPaginationProps, TableWithSortAndPaginationTheme} from './types';
import {getColumnDisplay, getSorting, stableSort} from './utils';
import clsx from 'clsx';
import styles from './TableWithSortAndPagination.module.scss';
import {Order, RowId} from '../../../store/grid';
import {tid} from '../../../testUtils';
import TablePagination from '../materialui/TablePagination';
import {nodeHasTag} from '../../../store/modelFile/utils';
import {ModelFileTags} from '../../../store/modelFile';
import TableFilterLabel from './TableFilterLabel';

function TableWithSortAndPagination<R>(props: TableWithSortAndPaginationProps<R>) {
  const {
    columns,
    rows,
    client = true,
    getRowId,
    onClickRow,
    getExpandedRow,
    rowRenderer,
    isRowSelected = () => false,
    hidePagination = false,
    loading = false,
    externalModel,
    noResultsMessage = 'No records were found',
    theme = TableWithSortAndPaginationTheme.Default,
    gridState: {
      order,
      setOrder,
      orderBy,
      setOrderBy,
      expandedRowId,
      setExpandedRowId,
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
    },
    filterStrategies,
  } = props;

  const orderByColumn: Column<R> | null = columns.find((c) => c.id === orderBy) || null;

  const isExpandable = !!getExpandedRow;

  const enhancedRows: EnhancedRow<R>[] = useMemo(() => {
    return rows.map((row) => ({
      row,
      rowId: getRowId(row),
    }));
  }, [rows, getRowId]);

  const sortedRows = useMemo(() => {
    if (orderByColumn === null || !client) {
      return enhancedRows;
    }
    return stableSort(enhancedRows, getSorting(order, orderByColumn));
  }, [enhancedRows, order, orderByColumn, client]);

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeSort(event: React.MouseEvent<unknown>, property: string) {
    if (orderBy === property) {
      setOrder(order === Order.DESC ? Order.ASC : Order.DESC);
    }
    setOrderBy(property);
  }

  function handleToggleExpandedRow(rowId: RowId) {
    if (expandedRowId === rowId) {
      setExpandedRowId(null);
    } else {
      setExpandedRowId(rowId);
    }
  }

  function renderColumnLabel(column: Column<R>) {
    if (filterStrategies && filterStrategies[column.id]) {
      return (
        <TableFilterLabel
          column={column}
          onSetOrder={setOrder}
          onSetOrderBy={setOrderBy}
          order={order}
          orderBy={orderBy}
          filterStrategy={filterStrategies[column.id]}
        />
      );
    } else if (column.sortable) {
      return (
        <TableSortLabel
          className={styles.headerLabel}
          active={orderBy === column.id}
          direction={order}
          onClick={(event) => handleChangeSort(event, column.id)}
          {...tid('grid-column-sort', column.id)}>
          {column.label}
        </TableSortLabel>
      );
    } else if (column.customHeader) {
      return (
        <>
          <div style={{fontWeight: 'bold'}}>{column.customHeader.heading}</div>
          <div>{column.customHeader.subHeading}</div>
        </>
      );
    } else {
      return column.label;
    }
  }

  function fallbackRowRenderer(enhancedRow: EnhancedRow<R>) {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        onClick={() => onClickRow && onClickRow(enhancedRow.row)}
        style={{userSelect: 'none'}}
        className={clsx({
          [styles.row]: true,
          // [styles.rowSelected]: isRowSelected(enhancedRow.row),
          rowSelected: isRowSelected(enhancedRow.row),
          [styles.rowExpandable]: isExpandable,
          [styles.rowExpanded]: enhancedRow.rowId === expandedRowId,
          [styles.rowNotExpanded]: enhancedRow.rowId !== expandedRowId,
          //[styles.externalTRow]: externalModel,
        })}
        {...tid('grid-row', String(enhancedRow.rowId))}>
        {isExpandable && (
          <TableCell padding="checkbox">
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleToggleExpandedRow(enhancedRow.rowId);
              }}
              {...tid('grid-row-expand-toggle', String(enhancedRow.rowId))}>
              <ChevronLeftIcon className={styles.rowExpandableButton} />
            </IconButton>
          </TableCell>
        )}
        {columns.map((column) => {
          const classNames = column.classes && column.classes.td;
          if (externalModel) {
            let Icon = DocumentBlank24;
            rows.forEach((file: any) => {
              if (file.uuid !== enhancedRow.rowId) {
                return;
              }
              if (nodeHasTag(file, ModelFileTags.MODEL_FILE)) {
                Icon = Development24;
              } else if (nodeHasTag(file, ModelFileTags.DOCUMENTATION_FILE)) {
                Icon = Document24;
              }
            });
            return (
              <TableCell key={column.id} className={classNames || ''}>
                {column.id === 'name' && <Icon className={clsx('nodeIcon', styles.icon)} />}
                {getColumnDisplay(column, enhancedRow.row)}
              </TableCell>
            );
          }
          return (
            <TableCell key={column.id} className={classNames || ''}>
              {getColumnDisplay(column, enhancedRow.row)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  const columnCount = Object.keys(columns).length + (isExpandable ? 1 : 0);
  const displayedRows = client ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;

  return (
    <>
      <div className={theme}>
        <Table stickyHeader size="small" className={clsx({[styles.externalTable]: externalModel})}>
          <TableHead>
            <TableRow>
              {isExpandable && <TableCell className={styles.columnExpandable} />}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  className={(column.classes && column.classes.th) || ''}
                  {...tid('grid-column', column.id)}>
                  {renderColumnLabel(column)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell colSpan={columnCount} style={{padding: 0, height: 0}}>
                {loading && (
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      flexGrow: 1,
                    }}>
                    <CircularProgress style={{position: 'absolute'}} />
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{opacity: loading ? 0.2 : 1}}>
            {displayedRows.map((enhancedRow) => (
              <React.Fragment key={enhancedRow.rowId}>
                {rowRenderer ? rowRenderer(enhancedRow.row) : fallbackRowRenderer(enhancedRow)}
                {isExpandable && (
                  <TableRow className={styles.rowExpansion} {...tid('grid-row-expanded', String(enhancedRow.rowId))}>
                    <TableCell colSpan={columnCount}>
                      <div>
                        {enhancedRow.rowId === expandedRowId && getExpandedRow && getExpandedRow(enhancedRow.row)}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
            {rows.length === 0 && page === 0 && !loading && !!noResultsMessage && (
              <TableRow className={styles.emptyRow} {...tid('grid-row-empty')}>
                <TableCell colSpan={columnCount}>{noResultsMessage}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={props.client !== false ? rows.length : props.total}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
            ...tid('pagination-previous'),
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
            ...tid('pagination-next'),
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

export default TableWithSortAndPagination;
