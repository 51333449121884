import React, {useMemo, useState, useEffect} from 'react';
import ModelUploadPageHeader from '../common/ModelUploadPageHeader';
import ModelEditSuffixForm from '../../model/common/ModelEdit/ModelEditSuffix/ModelEditSuffixForm';
import ModelUploadFooter from '../common/ModelUploadFooter';
import {ModelDTO} from 'hemwb-api';
import {IssueSeverity, ModelIssue} from '../../../../store/model/validator';
import WarningText from '../../../core/WarningText/WarningText';
import ModelIssueList from './ModelIssueList';
import {trackNameConfirmationTabEvent} from '../../../../tracking/tracking';

type StepNameConfirmationProps = {
  onSubmit: (updatedModel: ModelDTO, publish: boolean) => void;
  onChange: (updatedModel: ModelDTO, valid: boolean) => void;
  model: ModelDTO;
  canPublish: boolean;
  setActiveStep: (index: number) => void;
  issues: ModelIssue[];
};

const StepNameConfirmation: React.FC<StepNameConfirmationProps> = ({
  setActiveStep,
  onSubmit,
  onChange,
  model,
  canPublish,
  issues,
}) => {
  const [action, setAction] = useState(false);

  const errors = useMemo(() => {
    return issues.filter((i) => i.severity === IssueSeverity.ERROR_CREATE);
  }, [issues]);

  const renderButtons = (renderParams: any) => {
    return (
      <ModelUploadFooter
        {...renderParams}
        onClickSubmitAndPublish={() => setAction(true)}
        onClickSubmit={() => setAction(false)}
        canPublish={canPublish}
      />
    );
  };

  useEffect(() => {
    trackNameConfirmationTabEvent();
  }, []);

  return (
    <div>
      <ModelUploadPageHeader
        title="Confirm the generated name"
        subTitle="You can also add custom model name as a suffix"
      />
      <div className="whiteTheme" style={{maxWidth: '80rem', margin: '0 auto', paddingTop: '6rem'}}>
        {errors.length > 0 ? (
          <div>
            <div className="headlineRed">Your model entry is invalid</div>
            <ModelIssueList issues={issues} setActiveStep={setActiveStep} />
            <WarningText text="Red issues are required to fix in order to be able to proceed. You can navigate to the desired step by clicking on any issue." />
            <ModelUploadFooter
              valid={false}
              onClickSubmitAndPublish={() => setAction(true)}
              onClickSubmit={() => setAction(false)}
              canPublish={false}
            />
          </div>
        ) : (
          <ModelEditSuffixForm
            model={model}
            onChange={onChange}
            onSubmit={(model) => {
              onSubmit(model, action);
            }}
            issueList={
              <>
                <ModelIssueList issues={issues} setActiveStep={setActiveStep} />
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            }
            buttons={renderButtons}
          />
        )}
      </div>
    </div>
  );
};

export default StepNameConfirmation;
