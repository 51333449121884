import {ExecutionSetupDTO, LoggedInUserDTO, ModelDTO} from 'hemwb-api';
import {PERMISSION, Permission} from './types';
import {isModelOwnerOrStewardOrSuperUser} from './utils';
import {isModelRetired} from '../store/model';

export const canAddSubModel = (loggedInUser: LoggedInUserDTO, model?: ModelDTO): Permission => {
  if (isModelOwnerOrStewardOrSuperUser(loggedInUser, model)) {
    if (isModelRetired(model)) {
      return PERMISSION.DISALLOWED;
    }
    return PERMISSION.ALLOWED;
  }
  if (model) {
    return PERMISSION.FORBIDDEN;
  }
  return PERMISSION.DISALLOWED;
};

export const canEditSubModel = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
): Permission => {
  const p = canAddSubModel(loggedInUser, model);
  if (p) {
    if (subModel) {
      return PERMISSION.ALLOWED;
    }
    return PERMISSION.DISALLOWED;
  }
  return p;
};

export const canDeleteSubModel = canEditSubModel;

export const canAddInputDefinition = canEditSubModel;

export const canEditInputDefinition = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
): Permission => {
  const p = canAddInputDefinition(loggedInUser, model, subModel);
  if (p) {
    return PERMISSION.ALLOWED;
  }
  return p;
};

export const canExportInputDefinition = canEditInputDefinition;
