import React from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {FormControl, InputLabel} from '@material-ui/core';
import {KeyboardDatePicker, KeyboardDatePickerProps} from '@material-ui/pickers';
import {getErrorText, getVisibleErrors} from './utils';
import {tid} from '../../../testUtils';

type DateControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  KeyboardDatePickerProps?: Partial<KeyboardDatePickerProps>;
  label: string;
  plural?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export const DateControl: React.FC<DateControlProps> = ({
  label,
  plural,
  required,
  disabled = false,
  KeyboardDatePickerProps,
  ...FieldControlProps
}) => {
  return (
    <FieldControl
      strict={false}
      {...FieldControlProps}
      render={(renderProps) => {
        const {handler} = renderProps;
        const visibleErrors = getVisibleErrors(renderProps);
        const hasVisibleErrors = Object.keys(visibleErrors).length > 0;

        const {value, ...restHandlers} = handler();

        return (
          <FormControl fullWidth error={hasVisibleErrors} hiddenLabel={true} className="MuiFormControl-hem">
            <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
              {label}
            </InputLabel>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              inputVariant="filled"
              format="MM/dd/yyyy"
              margin="dense"
              InputAdornmentProps={{
                position: 'end',
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{
                ...tid('input', FieldControlProps.name!),
              }}
              label=""
              hiddenLabel={true}
              {...KeyboardDatePickerProps}
              error={hasVisibleErrors}
              helperText={hasVisibleErrors && getErrorText(visibleErrors, String(label), plural)}
              {...restHandlers}
              disabled={disabled || restHandlers.disabled}
              value={value || null}
            />
          </FormControl>
        );
      }}
    />
  );
};
