import React, {FunctionComponent} from 'react';
import {ExecutionStatus, ScenarioStatus} from 'hemwb-api';
import {
  CarbonIconProps,
  Checkmark16,
  Development16,
  Error16,
  PauseOutline16,
  Search16,
  StopOutline16,
  Time16,
  InProgress16,
} from '@carbon/icons-react';

const ExecutionStatusComponentMap: {
  [key in ExecutionStatus | ScenarioStatus]:
    | FunctionComponent<React.SVGProps<SVGSVGElement>>
    | React.FC<CarbonIconProps>;
} = {
  [ScenarioStatus.DRAFT]: Development16,
  [ExecutionStatus.NOTSTARTED]: Search16,
  [ExecutionStatus.INQUEUE]: Time16,
  [ExecutionStatus.INPROCESS]: InProgress16,
  [ExecutionStatus.POSTPROCESSING]: Search16,
  [ExecutionStatus.SUSPENDED]: PauseOutline16,
  [ExecutionStatus.COMPLETED]: Checkmark16,
  [ExecutionStatus.CANCELLED]: StopOutline16,
  [ExecutionStatus.FAILED]: Error16,
  [ScenarioStatus.ACCEPTED]: Checkmark16,
  [ScenarioStatus.SUPERSEDED]: Error16,
};

type ExecutionStatusIconProps = {
  status: ExecutionStatus | ScenarioStatus;
};

const ExecutionStatusIcon: React.FC<ExecutionStatusIconProps> = ({status, ...iconProps}) => {
  const Icon = ExecutionStatusComponentMap[status];
  if (!Icon) {
    return null;
  } else {
    switch (Icon) {
      case Error16:
        return <Icon style={{fill: '#FB4B53'}} />;
      case PauseOutline16:
      case StopOutline16:
      case Search16:
        return <Icon style={{fill: '#A4A4A4'}} />;
      case Time16:
      case InProgress16:
        return <Icon style={{fill: '#408BFC'}} />;
      case Checkmark16:
        return <Icon style={{fill: '#00D563'}} />;
      default:
        return <Icon {...iconProps} />;
    }
  }
};

export default ExecutionStatusIcon;
