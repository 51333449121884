import React from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {OptionProps, OptionWrapperProps, RadioFilterProps} from './types';
import {OptionFilter, optionStyles} from './OptionFilter';
import {tid} from '../../../testUtils';

const RadioOption: React.FC<OptionProps<any>> = <T,>(props: OptionProps<T>) => {
  const {option, optionLabel, optionValue} = props;
  const newValue = optionValue(option);
  return (
    <FormControlLabel
      value={newValue}
      control={
        <Radio
          size="small"
          classes={{root: optionStyles.radioRoot}}
          inputProps={tid('radio-option-radio', newValue) as {}}
        />
      }
      label={optionLabel(option)}
      labelPlacement="end"
      classes={{root: optionStyles.label, label: optionStyles.optionLabel}}
      {...tid('radio-option-label', newValue)}
    />
  );
};

const RadioOptionWrapper: React.FC<OptionWrapperProps> = ({handler, children}) => {
  return <RadioGroup {...handler()}>{children}</RadioGroup>;
};

export const RadioFilter: React.FC<RadioFilterProps<any>> = <T,>(props: RadioFilterProps<T>) => (
  <OptionFilter
    {...props}
    optionWrapperComponent={RadioOptionWrapper}
    optionComponent={RadioOption}
    {...tid('radio-filter', props.label)}
  />
);
