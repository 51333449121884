import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {CssBaseline, List, ListItem, ListItemText} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {useCurrentUser} from '../../../../../store/auth';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `10px`,
    height: '100%',
    width: '99%',
    marginTop: theme.spacing(7),
  },
  link: {
    color: 'rgba(0,0,0,0.65)',
    textDecoration: 'none',
    marginLeft: '10%',
    alignSelf: 'flex-start',
    '&:hover': {
      color: 'rgba(0,0,0,1)',
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

interface UserRegistrationDTO {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  reviewerCode: string;
  mobile: string;
  consent: string;
}

export default function RegistrationReview() {
  const classes = useStyles();
  const [data, setData] = React.useState<UserRegistrationDTO[]>([]);
  const [firstLoad, setLoad] = React.useState(true);
  const currentUser = useCurrentUser();

  async function handleLoadRequests() {
    const isid = currentUser.id;
    const response = await fetch('/api/v2/auth/registration/review/' + isid);
    if (response.status === 200) {
      const body = await response.json();
      setData(body);
      setLoad(false);
    }
  }

  const handleResolveRequest = (email: string, approved: boolean) => {
    const sendResponse = async () => {
      const response = await fetch('/api/v2/auth/registration/review', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify([{email, approved}]), // body data type must match "Content-Type" header
      });
      if (response.status === 200) {
        await handleLoadRequests();
      }
    };
    sendResponse().then(() => alert('Access request reviewed'));
  };

  if (firstLoad) {
    handleLoadRequests().then(() => setLoad(false));
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <h1>Incoming user requests</h1>
      <List>
        {data.map((item) => (
          <ListItem key={item.email} alignItems="flex-start">
            <ListItemText
              primary={item.firstName + ' ' + item.lastName}
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="body2">
                    {item.country} {item.email} {item.mobile}
                  </Typography>
                  <br />
                  Consent: {item.consent}
                </React.Fragment>
              }
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleResolveRequest(item.email, true)}>
              Accept
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={() => handleResolveRequest(item.email, false)}>
              Reject
            </Button>
          </ListItem>
        ))}
      </List>

      <Link to="/admin">
        <Button fullWidth variant="outlined" color="primary" className={classes.submit}>
          Back
        </Button>
      </Link>
    </Container>
  );
}
