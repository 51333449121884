import UserActionDialog, {UserActionDialogProps} from './UserActionDialog';
import React, {useMemo, useState} from 'react';

type UserActionDialogOpenProps = Omit<UserActionDialogProps, 'onCancel' | 'open'> & {
  onCancel?: UserActionDialogProps['onCancel'];
};

export const useUserActionDialog = () => {
  const [openProps, setOpenProps] = useState<UserActionDialogOpenProps>();
  const UserActionDialogComponent = useMemo(
    () =>
      !openProps ? null : (
        <UserActionDialog
          open={true}
          user={openProps.user}
          onCancel={() => {
            openProps.onCancel && openProps.onCancel();
            setOpenProps(undefined);
          }}
          action={openProps.action}
        />
      ),
    [openProps, setOpenProps],
  );

  return {
    openUserActionDialog: setOpenProps as (props: UserActionDialogOpenProps) => void,
    UserActionDialog: UserActionDialogComponent,
  };
};
