import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import ModelEditPermissionsForm, {ModelEditPermissionsFormData} from './ModelEditPermissionsForm';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {Collaborate16} from '@carbon/icons-react';
import {tid} from '../../../../../../testUtils';
import SuspenseNull from '../../../../../core/Suspense/SuspenseNull';
import {
  isModelExternal,
  isModelTheorem,
  isModelWeb,
  isModelPneuInsights,
  getModelSteward,
} from '../../../../../../store/model';
import {modelStewardSet} from '../../../../../../store/modelSteward';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';
import {modelAccessUpdateList, useModelAccess} from '../../../../../../store/modelAccess';
import {trackModelAccessGranted} from '../../../../../../tracking/tracking';
import {canEditTHModelAccess} from '../../../../../../permissions/usePermissions';

const ModelEditPermissionsSideBarModal: React.FC<ModelActionProps> = ({onSuccess, onCancel, model}) => {
  const {uuid} = model;
  const dispatch = useDispatch();

  const {loaded, ...modelAccess} = useModelAccess(uuid);
  const [defaultValues, setDefaultValues] = useState<any>();
  const externalModel = isModelExternal(model);
  const theoremModel = isModelTheorem(model);
  const pneuInsightsModel = isModelPneuInsights(model);
  const webModel = isModelWeb(model);

  loaded && !defaultValues && setDefaultValues({...modelAccess, model});
  useEffect(() => {}, [loaded, defaultValues, modelAccess, model]);

  const handleSubmit = useCallback(
    (values: ModelEditPermissionsFormData) => {
      return modelAccessUpdateList(dispatch, uuid, {
        owners: values.owners,
        vestLead: values.vestLead,
        calibratorModeler: values.calibrationModelers,
        scenarioModeler: values.scenarioModelers,
        viewers: values.viewers,
        displayViewers: values.displayViewers,
      })
        .then(() => {
          const modelSteward = getModelSteward(values.model);
          if (modelSteward) {
            return modelStewardSet(dispatch, uuid, modelSteward);
          }
          return Promise.resolve();
        })
        .then(() => {
          trackModelAccessGranted(model);
          dispatch(messageAdd('Model Permissions updated', MessageTypes.SUCCESS));
          onSuccess?.();
        });
    },
    [uuid, onSuccess, model, dispatch],
  );

  const renderFooterButtons = (fromObject: any) => {
    return (
      <div className="buttonsContainer">
        <CancelButton
          onClick={onCancel}
          disabled={fromObject.submitting}
          {...tid(MODEL_ACTION_MENU_IDS.EDIT_PERMISSIONS, 'cancel')}
        />
        <SubmitButton
          disabled={pneuInsightsModel || (theoremModel && !canEditTHModelAccess(model))}
          active={fromObject.submitting}
          endIcon={<Collaborate16 />}
          {...tid(MODEL_ACTION_MENU_IDS.EDIT_PERMISSIONS, 'submit')}>
          Update Permissions
        </SubmitButton>
      </div>
    );
  };

  return (
    <SuspenseNull>
      {defaultValues && (
        <ModelEditPermissionsForm
          onSubmit={handleSubmit}
          model={model}
          externalModel={externalModel}
          theoremModel={theoremModel}
          pneuInsightsModel={pneuInsightsModel}
          webModel={webModel}
          // @ts-ignore
          data={defaultValues}
          buttons={renderFooterButtons}
        />
      )}
    </SuspenseNull>
  );
};

export default ModelEditPermissionsSideBarModal;
