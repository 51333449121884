import React, {ReactNode, useMemo} from 'react';
import styles from './ModelCardExpanded.module.scss';
import {IconButton} from '@material-ui/core';
import {getModelLanguageText, isModelRetired, stringifyCountry} from '../../../../../store/model';
import {tid} from '../../../../../testUtils';
import {Minimize16, Launch16} from '@carbon/icons-react';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../../../router/routes';
import clsx from 'clsx';
import {SharedModelCardProps} from './types';
import {Networking_03 as Networking03} from '@carbon/pictograms-react';

type ModelCardExpandedProps = SharedModelCardProps & {
  onClickCollapse?: () => void;
  Content?: ReactNode;
  hideLeftSection?: boolean;
};

const ModelCardExpanded: React.FC<ModelCardExpandedProps> = ({
  hideDetailLink = false,
  onClickCollapse,
  model,
  selectable = true,
  className,
  current = false,
  Content,
  hideLeftSection = false,
  ...restProps
}) => {
  const metadata = useMemo(() => {
    if (model) {
      const m = model.metadata;
      return [
        m.therapeutic_area,
        m.study_number,
        m.disease,
        m.model_type,
        getModelLanguageText(m.type),
        stringifyCountry(model),
        m.version_release_of_model,
      ]
        .filter(Boolean)
        .join('\n');
    }
    return '';
  }, [model]);

  return (
    <div
      className={clsx('card', styles.container, className, {
        [styles.notSelectable]: !selectable,
        [styles.retired]: isModelRetired(model),
        [styles.selectable]: !!restProps.onClick,
        [styles.current]: current,
      })}
      {...restProps}>
      <div className={styles.wrapper}>
        {!hideLeftSection && (
          <div className={styles.leftSection}>
            <h2>
              <Networking03 className="icon32" />
              Version Control
            </h2>
            {metadata}
          </div>
        )}
        <div className={styles.rightSection}>
          <div>
            <h2>Model Name</h2>
            {model?.name}
          </div>
          {!Content && model?.metadata.version_log && (
            <div>
              <h2>Model Version Log</h2>
              {model?.metadata.version_log}
            </div>
          )}
          {Content}
          <div className={styles.actionContainer}>
            {onClickCollapse && (
              <IconButton
                size="small"
                className="icon32"
                onClick={(event) => {
                  event.stopPropagation();
                  onClickCollapse();
                }}
                {...tid('model-card-collapse', model?.uuid || '')}>
                <Minimize16 />
              </IconButton>
            )}
            {!hideDetailLink && model?.uuid && (
              <IconButton
                className="icon32"
                size="small"
                component={Link}
                to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}
                {...tid('model-card-detail', model.uuid)}>
                <Launch16 />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelCardExpanded;
