import {excelDiffSelector, useExcelDiffItemContentListLoaderWithParams} from '../../../../store/excelDiff';
import {ModelTreeNode} from '../../../../store/modelFile';
import {useEffect, useMemo} from 'react';
import {DiffDTOTagEnum} from 'hemwb-api';
import {isSameTab} from './utils';
import {TabType} from './types';
import {useSelector} from '../../../../store/rootReducer';
import {AsyncActionPhase} from '../../../../store/asyncUtilsTypes';

export const useTabs = (file1: ModelTreeNode, file2: ModelTreeNode): TabType[] | false | undefined => {
  const load = useExcelDiffItemContentListLoaderWithParams();

  useEffect(() => {
    load([file1.uuid]).catch(() => null);
  }, [load, file1.uuid]);
  useEffect(() => {
    load([file2.uuid]).catch(() => null);
  }, [load, file2.uuid]);

  const state1 = useSelector((state) => excelDiffSelector(state).contentLists[file1.uuid]);
  const state2 = useSelector((state) => excelDiffSelector(state).contentLists[file2.uuid]);

  return useMemo(() => {
    if (state1 && state2) {
      if (state1.state === AsyncActionPhase.SUCCESS && state2.state === AsyncActionPhase.SUCCESS) {
        return [...state1.value!, ...state2.value!].reduce((acc, val) => {
          if (!acc.some(isSameTab(val))) {
            const t1 = state1.value!.find(isSameTab(val));
            const t2 = state2.value!.find(isSameTab(val));
            acc.push({
              type: val.type,
              name: val.name,
              tag: t1 && t2 ? DiffDTOTagEnum.Equal : t1 ? DiffDTOTagEnum.Minus : DiffDTOTagEnum.Plus,
              file1,
              file2,
              itemId1: t1?.id || -1,
              itemId2: t2?.id || -1,
            });
          }

          return acc;
        }, ([] as any) as TabType[]);
      } else if (state1.state === AsyncActionPhase.FAILURE || state2.state === AsyncActionPhase.FAILURE) {
        return false;
      }
    }
  }, [state1, state2, file1, file2]);
};
