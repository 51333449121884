import React, {ReactElement, ReactNode} from 'react';
import {Container, Grid} from '@material-ui/core';
import {useSelector} from '../../../../../store/rootReducer';
import {authSelector} from '../../../../../store/auth';
import {getUserTypeName} from '../../../../../store/user/utils';
import {Group} from '@carbon/pictograms-react';
import PageHeaderTitle from '../../../../core/Header/PageHeader/PageHeaderTitle';

type AdminSpaceHeaderProps = {
  Search?: ReactElement<any>;
  label?: string;
  Icon?: ReactNode;
};

const AdminSpaceHeader: React.FC<AdminSpaceHeaderProps> = ({Search, Icon, label}) => {
  const loggedInUser = useSelector((state) => authSelector(state).loggedInUser);

  return (
    <Container maxWidth={false} className="pageHeader">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <PageHeaderTitle
          md={12}
          lg={4}
          title="Admin Space"
          label={label || 'Enroll, activate and deactivate users'}
          Icon={Icon || <Group className="icon32" />}
        />
        <Grid item md={12} lg={4}>
          {Search}
        </Grid>

        {loggedInUser && (
          <Grid item md={12} lg={2}>
            <p style={{marginTop: 0}}>My current role: {getUserTypeName(loggedInUser.userType)}</p>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AdminSpaceHeader;
