import React from 'react';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import {MANAGE_METADATA_MENU_IDS} from '../../../../../../test/types';
import {useAddOptionSideBarModal} from './useAddOptionSideBarModal';
import {canManageMetadata} from '../../../../../../permissions/usePermissions';
import {Add16} from '@carbon/icons-react';

const AddOptionActionMenuButton: React.FC<Partial<ManageMetadataActionProps>> = (props) => {
  const {ModalComponent, openModal} = useAddOptionSideBarModal(props);
  return (
    <>
      <SideBarButtonItem
        icon={<Add16 />}
        text="Add Option"
        permission={canManageMetadata(props.property)}
        onClick={openModal}
        {...tid(MANAGE_METADATA_MENU_IDS.ADD)}
      />
      {ModalComponent}
    </>
  );
};

export default AddOptionActionMenuButton;
