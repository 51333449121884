import {IncidentStatus} from 'hemwb-api';

const incidentStatusMap = {
  [IncidentStatus.OPEN]: 'OPEN',
  [IncidentStatus.CLOSED]: 'CLOSED',
};

export const IncidentStatuses = Object.keys(incidentStatusMap) as IncidentStatus[];

type UpdateIncidentFormValues = {
  status: IncidentStatus;
};

export const mapValuesToIncident = (i: UpdateIncidentFormValues): IncidentStatus => i.status;
