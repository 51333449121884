import React from 'react';
import {CircularProgress} from '@material-ui/core';
import DefaultPage from '../../layout/common/DefaultPage';

const Suspense: React.FC<Partial<React.SuspenseProps>> = (props) => {
  return (
    <React.Suspense
      fallback={
        <DefaultPage>
          <CircularProgress style={{marginLeft: '50%', marginTop: '10%'}} />
        </DefaultPage>
      }
      {...props}
    />
  );
};

export default Suspense;
