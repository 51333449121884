import React, {useCallback, useState} from 'react';
import {AbstractControl, FormArray, FormGroup} from 'react-reactive-form';
import {InputGenerator, InputType, SingleSelectInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {Button, Grid, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import {SelectItemPrimitive, selectItemPrimitiveControlBuilder} from '../primitives/SelectItemPrimitive';
import nanoid from 'nanoid';
import IconButton from '@material-ui/core/IconButton';
import {TrashCan16} from '@carbon/icons-react';
import {Add} from '@material-ui/icons';
import GridItem from '../../../Form/GridItem';

type ControlsMap = {[key in keyof Required<SingleSelectInputAttributes>]: AbstractControl};

type SingleSelectInputInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const SingleSelectInput: React.FC<SingleSelectInputInputProps> = ({formGroup}) => {
  const {controls} = formGroup;
  const options = formGroup.get('options') as FormArray;

  const [selected, setSelected] = useState<number>();

  const handleAddOption = useCallback(() => {
    let i = 1;
    let key = `newKey${i}`;
    const keys = Object.values(options.value).map((v: any) => v.key);
    while (keys.includes(key)) {
      i++;
      key = `newKey${i}`;
    }
    const fg = new FormGroup(
      selectItemPrimitiveControlBuilder(
        {key, selected: false, text: `New Item ${i}`},
        controls.type.value === InputType.SINGLE_SELECT,
      ),
    );
    fg.meta = {
      key: nanoid(),
    };

    if (selected !== undefined) {
      options.insert(selected, fg);
    } else {
      setSelected(options.length);
      options.insert(options.length, fg);
    }
  }, [options, selected, controls.type.value]);

  const handleRemoveOption = useCallback(
    (index: number) => {
      options.removeAt(index);
      if (selected !== undefined) {
        if (index === selected) {
          setSelected(undefined);
        } else if (selected > index) {
          setSelected(selected - 1);
        }
      }
      options.controls.forEach((fg) => {
        fg.get('key').updateValueAndValidity();
        fg.get('text').updateValueAndValidity();
      });
    },
    [options, selected],
  );

  return (
    <>
      <SharedInputParameters formGroup={formGroup} />

      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <InputLabel shrink>Options</InputLabel>
          <div style={{background: 'white', height: 200, overflow: 'auto', marginTop: 5}}>
            <List dense>
              {(options.controls as FormGroup[]).map((fg, index) => {
                const textValue = fg.get('text').value;
                const keyValue = fg.get('key').value;
                return (
                  <ListItem
                    key={`${textValue}${keyValue}${fg.meta.key}`}
                    button
                    onClick={() => setSelected(index === selected ? undefined : index)}
                    selected={selected === index}>
                    <ListItemText
                      primary={textValue || 'EMPTY'}
                      primaryTypographyProps={{color: fg.status === 'VALID' ? 'textPrimary' : 'error'}}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveOption(index)}>
                        <TrashCan16 />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </GridItem>
        <GridItem>
          {selected !== undefined && (
            <SelectItemPrimitive
              key={`${selected}${options.controls[selected].meta.key}`}
              formGroup={options.controls[selected] as FormGroup}
            />
          )}
        </GridItem>
      </Grid>
      <Button variant="outlined" onClick={handleAddOption} endIcon={<Add />}>
        Add option
      </Button>
    </>
  );
};

export const singleSelectInputGenerator: InputGenerator<InputType.SINGLE_SELECT> = {
  type: InputType.SINGLE_SELECT,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      options: new FormArray(
        (initialValues.options || []).map((attr) => new FormGroup(selectItemPrimitiveControlBuilder(attr))),
      ),
    } as ControlsMap);

    return fg;
  },
  render: SingleSelectInput,
};
