import React from 'react';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import {MANAGE_METADATA_MENU_IDS} from '../../../../../../test/types';
import {TrashCan16} from '@carbon/icons-react';
import {useDeleteOptionSideBarModal} from './useDeleteOptionSideBarModal';
import {canManageMetadata} from '../../../../../../permissions/usePermissions';

const DeleteOptionActionMenuButton: React.FC<Partial<ManageMetadataActionProps>> = (props) => {
  const {ModalComponent, openModal} = useDeleteOptionSideBarModal(props);
  const fileTagUsageCount = props.fileTagCount?.find((fileTag) => fileTag.value === props.value)?.count;

  return (
    <>
      <SideBarButtonItem
        red
        icon={<TrashCan16 />}
        text="Delete Option"
        permission={canManageMetadata(props.property)}
        disabled={
          !props.value ||
          props.usageCount !== 0 ||
          props.retired ||
          (typeof fileTagUsageCount !== 'undefined' && fileTagUsageCount > 0)
        }
        onClick={openModal}
        {...tid(MANAGE_METADATA_MENU_IDS.DELETE)}
      />
      {ModalComponent}
    </>
  );
};

export default DeleteOptionActionMenuButton;
