import {userListSelector, externalUserListSelector} from './selectors';
import {useMemo} from 'react';
import {userLoadList, externalUserLoadList} from './actions';
import {UserDTO} from 'hemwb-api';
import {useSimpleReduxLoader} from '../useReduxLoader';

export const useUserList = () => {
  return useSimpleReduxLoader(userListSelector, userLoadList);
};

export const useExternalUserList = () => {
  return useSimpleReduxLoader(externalUserListSelector, externalUserLoadList);
};

export const useUserFromList = (id: string) => {
  const list = useUserList();
  const user: UserDTO | null = useMemo(() => list?.find((u) => u.id === id) || null, [list, id]);

  return {
    user,
    notFound: list && !user,
  };
};
