import {ModelLinksActions} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {requestModelHierarchy, requestModelHierarchyRootsByMetadata, requestSetModelParent} from './api';

export const modelLinksLoadHierarchyActionsCreator = buildAsyncActionsCreator(ModelLinksActions.FETCH_HIERARCHY);
export const modelLinksLoadHierarchy = createAsyncActionExecutor(
  modelLinksLoadHierarchyActionsCreator,
  requestModelHierarchy,
);

export const modelLinksLoadHierarchyRootsByMetadataActionsCreator = buildAsyncActionsCreator(
  ModelLinksActions.FETCH_ROOTS_BY_METADATA,
);
export const modelLinksLoadHierarchyRootsByMetadata = createAsyncActionExecutor(
  modelLinksLoadHierarchyRootsByMetadataActionsCreator,
  requestModelHierarchyRootsByMetadata,
);

export const modelLinksSetParentActionsCreator = buildAsyncActionsCreator(ModelLinksActions.SET_PARENT);
export const modelLinksSetParent = createAsyncActionExecutor(modelLinksSetParentActionsCreator, requestSetModelParent);
