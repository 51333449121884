import React from 'react';
import {UserDTO} from 'hemwb-api';
import {IconButton} from '@material-ui/core';
import {Chat16, Email16} from '@carbon/icons-react';

type UserCardDefaultActionsProps = {
  user: UserDTO;
};

const UserCardDefaultActions: React.FC<UserCardDefaultActionsProps> = ({user}) => {
  return user.email ? (
    <>
      <IconButton
        aria-label="Open Chat"
        size="small"
        //onClick={() => window.open(`xmpp:${user.email}?message`, '_blank')}
        onClick={() => window.open(`https://teams.microsoft.com/l/chat/1/0?users=${user.email}`, '_blank')}>
        <Chat16 />
      </IconButton>
      <IconButton aria-label="Compose Email" size="small" component="a" href={`mailto:${user.email}`}>
        <Email16 />
      </IconButton>
    </>
  ) : null;
};

export default UserCardDefaultActions;
