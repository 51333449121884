import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {FormArray, FormGroup} from 'react-reactive-form';
import {InputAttributes, InputType} from './types';
import {generators} from './generators';
import {Form, useForm} from '../../Form';
import SubmitButton from '../../Buttons/SubmitButton';
import {getCommonInputAttributes, getInputDefinitionCategories, getNextInputId} from './utils';
import InputList from './InputList';
import CancelButton from '../../Buttons/CancelButton';
import {useDebounce} from '../../../../hooks/useDebounce';
import {useMounted} from '../../../../hooks/useMounted';
import DialogConfirmation from '../../dialog/DialogConfirmation';
import {inputDefinitionFormGenerator} from './inputDefinitionFormGenerator';
import {ExecutionSetupType, ModelDTO} from 'hemwb-api';

type InputDefinitionProps = {
  subModelType: ExecutionSetupType;
  definitions: InputAttributes[];
  onSubmit: (definitions: InputAttributes[]) => void;
  submitting: boolean;
  onCancel: () => void;
  inputListPortalRef?: RefObject<HTMLDivElement>;
  model: ModelDTO;
};

const InputDefinition: React.FC<InputDefinitionProps> = ({
  onSubmit,
  submitting,
  onCancel,
  inputListPortalRef,
  definitions,
  subModelType,
  model,
}) => {
  const inputCategories = getInputDefinitionCategories(subModelType);
  const mounted = useMounted();
  const [selectedInputId, setSelectedInputId] = useState<number>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [showInvalidConfirmation, setShowInvalidConfirmation] = useState(false);

  const data: InputAttributes[] = definitions;

  useEffect(() => {
    if (data.length > 0) {
      mounted.current && setSelectedInputId(data[0]?.id);
    }
  }, [mounted, data]);

  const formRef = useRef<FormGroup>();

  const handleChangeType = useCallback(
    (formGroup: FormGroup) => (type: InputType) => {
      if (formRef.current) {
        const formDefinitions = formRef.current.get('definitions') as FormArray;
        const newFormGroup = generators[type].buildControl({
          ...(getCommonInputAttributes(formGroup.value) as any),
          type,
        });
        newFormGroup.controls.type.valueChanges.subscribe(handleChangeType(newFormGroup));

        const index = formDefinitions.controls.findIndex((fg: any) => {
          return fg === formGroup;
        });
        if (index !== -1) {
          formDefinitions.setControl(index, newFormGroup);
        }
      }
    },
    [formRef],
  );

  const form = useForm(inputDefinitionFormGenerator(data, handleChangeType));

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  const [inputListDefinitions, setInputListDefinitions] = useState<any[]>();
  const handleInputListChangeChange = useCallback(() => {
    setInputListDefinitions(
      (form.get('definitions') as FormArray).controls.map((fg) => ({
        id: fg.value.id,
        categoryId: fg.value.categoryId,
        title: fg.value.title,
        valid: fg.status === 'VALID',
      })),
    );
  }, [form]);

  const debouncedChange = useDebounce(() => handleInputListChangeChange(), 300);

  const handleAddItem = useCallback(() => {
    const formDefinitions = form.get('definitions') as FormArray;
    const id = getNextInputId(formDefinitions);
    const formGroup = generators[InputType.STRING].buildControl({
      type: InputType.STRING,
      title: 'Title of new item',
      id,
      categoryId: selectedCategoryId,
    });

    formGroup.controls.type.valueChanges.subscribe(handleChangeType(formGroup));
    formDefinitions.insert(formDefinitions.controls.length, formGroup);

    mounted.current && setSelectedInputId(id);
    handleInputListChangeChange();
  }, [mounted, form, handleChangeType, handleInputListChangeChange, selectedCategoryId]);

  const handleDeleteItem = useCallback(
    (itemId: number) => {
      const formDefinitions = form.get('definitions') as FormArray;
      const index = formDefinitions.value.map((d: InputAttributes) => d.id).indexOf(itemId);
      formDefinitions.removeAt(index);
      handleInputListChangeChange();
    },
    [form, handleInputListChangeChange],
  );

  const handleInputOrderChange = useCallback(
    (originalIndex: number, newIndex: number, categoryId: string) => {
      const formDefinitions = form.get('definitions') as FormArray;
      const movedControl = formDefinitions.controls[originalIndex];
      let calculatedNewIndex = newIndex;

      if (movedControl.value.categoryId !== categoryId) {
        if (newIndex === 0) {
          const categoryIds = Object.keys(inputCategories);

          let found = false;
          const usedCategoryIdList = formDefinitions.value.map((d: any) => d.categoryId);
          calculatedNewIndex = categoryIds.reduce((acc, cId) => {
            if (cId === categoryId || found) {
              found = true;
              return acc;
            }
            acc = acc + usedCategoryIdList.filter((c: any) => c === cId).length;
            return acc;
          }, 0);

          if (
            categoryIds.indexOf(movedControl.value.categoryId) < categoryIds.indexOf(categoryId) &&
            calculatedNewIndex > 0
          ) {
            calculatedNewIndex--;
          }
        } else if (calculatedNewIndex > 1) {
          calculatedNewIndex--;
        }
      }

      if (movedControl) {
        movedControl.get('categoryId').setValue(categoryId);
        formDefinitions.removeAt(originalIndex);
        formDefinitions.insert(calculatedNewIndex, movedControl);
      }

      handleInputListChangeChange();
    },
    [form, handleInputListChangeChange, inputCategories],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(form.get('definitions').value);
    setShowInvalidConfirmation(false);
  }, [form, onSubmit]);

  const handleFormSubmit = useCallback(() => {
    if (form.valid) {
      handleSubmit();
    } else {
      setShowInvalidConfirmation(true);
    }
  }, [form, handleSubmit]);

  return (
    <>
      {inputListDefinitions && (
        <InputList
          subModelType={subModelType}
          inputListPortalRef={inputListPortalRef}
          definitions={inputListDefinitions}
          setSelectedInputId={setSelectedInputId}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedInputId={selectedInputId}
          onInputOrderChange={handleInputOrderChange}
          onInputAdd={handleAddItem}
          onInputDelete={handleDeleteItem}
        />
      )}
      <Form
        group={form}
        FieldGroupProps={{strict: false}}
        render={() => {
          debouncedChange();
          return (
            <>
              {((form.get('definitions') as FormArray).controls as FormGroup[]).map((c) => {
                if (selectedInputId !== c.value.id) {
                  return null;
                }
                const type = c!.get('type').value as InputType;
                const generator = generators[type];
                const Component = generator.render;
                return <Component model={model} formGroup={c} key={`${c!.get('id').value}${type}`} />;
              })}

              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} />
                <SubmitButton active={submitting} onClick={handleFormSubmit} />
              </div>
            </>
          );
        }}
      />

      <DialogConfirmation
        open={showInvalidConfirmation}
        onClickYes={handleSubmit}
        onClickNo={() => setShowInvalidConfirmation(false)}
        title="Input Definition is not valid"
        text="Do you want to save it anyway?"
      />
    </>
  );
};

export default InputDefinition;
