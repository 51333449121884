import React from 'react';
import {BooleanInputAttributes, booleanOptions, InputType} from '../../InputDefinition/types';
import {AbstractControl, FormControl, Validators} from 'react-reactive-form';
import GridItem from '../../../Form/GridItem';
import {DropDownControl} from '../../../Form';
import {InputGenerator} from '../types';
import InputHeader from './InputHeader';
import {getBooleanInputTypeLabel} from '../../InputDefinition/utils';

type BooleanInputProps = {
  control: AbstractControl;
  definition: BooleanInputAttributes;
  disabled?: boolean;
};

const BooleanInput: React.FC<BooleanInputProps> = ({control, definition, disabled}) => {
  return (
    <>
      <InputHeader definition={definition} />
      <GridItem>
        <DropDownControl
          strict={false}
          label="Value"
          control={control}
          disabled={disabled}
          name="value"
          options={booleanOptions}
          getOptionLabel={getBooleanInputTypeLabel}
        />
      </GridItem>
    </>
  );
};

const BooleanStatic: React.FC<BooleanInputProps> = ({control, definition}) => {
  return (
    <>
      <InputHeader definition={definition} />
      {getBooleanInputTypeLabel(control.value)}
    </>
  );
};

export const booleanInputGenerator: InputGenerator<InputType.BOOLEAN> = {
  type: InputType.BOOLEAN,
  buildControl: (definition, initialValues) => {
    const validators = [];
    if (definition.required) {
      validators.push(Validators.required);
    }
    return new FormControl(initialValues === undefined ? definition.defaultValue : initialValues, validators);
  },
  render: BooleanInput,
  renderStatic: BooleanStatic,
};
