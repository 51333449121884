import React from 'react';
import {Tab as UITab, TabProps as UITabProps} from '@material-ui/core';
import {TabType} from './types';
import clsx from 'clsx';
import {DiffDTOTagEnum} from 'hemwb-api';
import {useDiffSelector} from './useDiffSelector';

type TabProps = Omit<UITabProps, 'label' | 'value'> & {value: TabType};

const Tab: React.FC<TabProps> = ({value, ...props}) => {
  const diff = useDiffSelector(value);

  const count = diff ? `${diff.diffCount} change${diff.diffCount > 1 ? 's' : ''}` : '(?)';

  return (
    <UITab
      {...props}
      value={value}
      label={`${value.name} (${count})`}
      className={clsx({redTab: value.tag === DiffDTOTagEnum.Minus, greenTab: value.tag === DiffDTOTagEnum.Plus})}
    />
  );
};

export default Tab;
