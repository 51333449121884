import React from 'react';
import {useModelRetireSideBarModal} from './useModelRetireSideBarModal';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ModelActionProps} from '../../../actionMenuTypes';
import {canRetireModel} from '../../../../../permissions/usePermissions';
import {Power16} from '@carbon/icons-react';

const ModelRetireActionMenuButton: React.FC<Partial<ModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelRetireSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        red
        {...tid(MODEL_ACTION_MENU_IDS.RETIRE)}
        permission={canRetireModel(props.model)}
        onClick={openModal}
        icon={<Power16 />}
        text="Retire Model Entry"
      />
      {ModalComponent}
    </>
  );
};

export default ModelRetireActionMenuButton;
