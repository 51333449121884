import {UserActions} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {
  requestFindUsers,
  requestUserDetail,
  requestUserEnroll,
  requestUserList,
  requestExternalUserList,
  requestUserSetActive,
  requestUserSetUserType,
  requestExternalUserActivate,
  requestExternalUserDeactivate,
  requestExternalUserReject,
  requestExternalUserRegistration,
  requestExternalUserSelfRegistration,
} from './api';

export const userLoadListActionsCreator = buildAsyncActionsCreator(UserActions.FETCH_LIST);
export const userLoadList = createAsyncActionExecutor(userLoadListActionsCreator, requestUserList);

export const externalUserLoadListActionsCreator = buildAsyncActionsCreator(UserActions.FETCH_EXTERNAL_LIST);
export const externalUserLoadList = createAsyncActionExecutor(
  externalUserLoadListActionsCreator,
  requestExternalUserList,
);

export const userLoadDetailActionsCreator = buildAsyncActionsCreator(UserActions.FETCH_DETAIL);
export const userLoadDetail = createAsyncActionExecutor(userLoadDetailActionsCreator, requestUserDetail);

export const userSetActiveActionsCreator = buildAsyncActionsCreator(UserActions.SET_ACTIVE);
export const userSetActive = createAsyncActionExecutor(userSetActiveActionsCreator, requestUserSetActive);

export const userEnrollActionsCreator = buildAsyncActionsCreator(UserActions.ENROLL);
export const userEnroll = createAsyncActionExecutor(userEnrollActionsCreator, requestUserEnroll, {
  showError: false,
});

export const userSetUserTypeActionsCreator = buildAsyncActionsCreator(UserActions.SET_USER_TYPE);
export const userSetUserType = createAsyncActionExecutor(userSetUserTypeActionsCreator, requestUserSetUserType);

export const userFindActionsCreator = buildAsyncActionsCreator(UserActions.FIND);
export const userFind = createAsyncActionExecutor(userFindActionsCreator, requestFindUsers);

export const externalUserActivateActionsCreator = buildAsyncActionsCreator(UserActions.ACTIVATE_EXTERNAL_USER);
export const activateExternalUser = createAsyncActionExecutor(
  externalUserActivateActionsCreator,
  requestExternalUserActivate,
);

export const externalUserDeactivateActionsCreator = buildAsyncActionsCreator(UserActions.DEACTIVATE_EXTERNAL_USER);
export const deactivateExternalUser = createAsyncActionExecutor(
  externalUserDeactivateActionsCreator,
  requestExternalUserDeactivate,
);

export const externalUserRejectActionsCreator = buildAsyncActionsCreator(UserActions.REJECT_EXTERNAL_USER);
export const rejectExternalUser = createAsyncActionExecutor(
  externalUserRejectActionsCreator,
  requestExternalUserReject,
);

export const externalUserRegisterActionsCreator = buildAsyncActionsCreator(UserActions.EXTERNAL_REGISTER);
export const registerExternalUser = createAsyncActionExecutor(
  externalUserRegisterActionsCreator,
  requestExternalUserRegistration,
);

export const externalUserSelfRegisterActionsCreator = buildAsyncActionsCreator(UserActions.EXTERNAL_SELF_REGISTER);
export const selfRegisterExternalUser = createAsyncActionExecutor(
  externalUserSelfRegisterActionsCreator,
  requestExternalUserSelfRegistration,
);
