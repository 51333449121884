import React, {useMemo} from 'react';
import {FormControl, Validators} from 'react-reactive-form';
import {Grid} from '@material-ui/core';
import GridItem from '../../../Form/GridItem';
import {DropDownControl} from '../../../Form';
import {InputType, MultiSelectInputAttributes, SingleSelectInputAttributes} from '../../InputDefinition/types';

type SelectItemPrimitiveProps = {
  control: FormControl;
  definition: SingleSelectInputAttributes | MultiSelectInputAttributes;
  disabled?: boolean;
};

export const SelectItemPrimitive: React.FC<SelectItemPrimitiveProps> = ({control, definition, disabled}) => {
  const options = useMemo(() => {
    return definition.options.map((option) => option.key);
  }, [definition.options]);

  const labels = useMemo(() => {
    return definition.options.reduce((acc, option) => {
      acc[option.key] = option.text;
      return acc;
    }, ({} as any) as {[key: string]: string});
  }, [definition.options]);

  return (
    <Grid container direction="row" alignItems="flex-start" justify="space-between">
      <GridItem>
        <DropDownControl
          strict={false}
          SelectProps={{
            multiple: definition.type === InputType.MULTI_SELECT,
          }}
          disabled={disabled}
          label="Value"
          control={control}
          name="value"
          options={options}
          getOptionLabel={(key) => labels[key] || key}
        />
      </GridItem>
    </Grid>
  );
};

export const selectInputPrimitiveControlBuilder = (
  definition: Partial<SingleSelectInputAttributes | MultiSelectInputAttributes>,
  initialValue?: any,
) => {
  const validators = [];
  if (definition.required) {
    validators.push(Validators.required);
  }

  const defaultValue =
    definition.type === InputType.MULTI_SELECT
      ? definition.options?.filter((option) => option.selected).map((option) => option.key)
      : definition.options
          ?.filter((option) => option.selected)
          .map((option) => option.key)
          .reduce((acc, val) => val, '');

  return new FormControl(initialValue === undefined ? defaultValue : initialValue, validators);
};
