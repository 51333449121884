import {InputAttributes, InputType} from '../InputDefinition/types';
import {CalibrationScenarioInputs} from './types';

export const associateFormValuesWithName = (
  definitions: InputAttributes[],
  formValues: any[],
): CalibrationScenarioInputs => {
  return definitions.reduce((acc, definition, index) => {
    if (definition.type === InputType.LABEL) {
      return acc;
    }
    acc[definition.name] = formValues[index];
    return acc;
  }, ({} as any) as CalibrationScenarioInputs);
};
