import {BoxLayout, boxLayoutStyles as styles} from '../../layout/BoxLayout';
import React from 'react';
import {Button} from '@material-ui/core';
import {getUrlRoute, Routes} from '../../router/routes';

export const Error404Page: React.FC<{}> = () => (
  <BoxLayout>
    <div className={styles.heading}>
      <h2 className={styles.title}>
        This page does not exist in <strong>Model Repository</strong>
      </h2>
      <div className={styles.subtitle}>
        This may be because of a broken link, insufficient access or application issue.
        <br />
        <br />
        You may continue by navigating to home page or checking the url if you typed it manually.
      </div>
      <div className={styles.links}>
        <Button component="a" href={getUrlRoute(Routes.ROOT)} color="primary" variant="contained">
          Home page
        </Button>
      </div>
    </div>
  </BoxLayout>
);
