import {Message, MessageDuration, MessageTypes} from './types';
import nanoid from 'nanoid';

const getDurationForType = (type: Message['type']): Message['duration'] =>
  type === MessageTypes.ERROR ? MessageDuration.INFINITY : MessageDuration.MEDIUM;

export const createMessage = (text: Message['text'], type: Message['type'], disableGrouping = false): Message => {
  return {
    type,
    display: text,
    text,
    occurrence: 1,
    disableGrouping,
    key: nanoid(),
    duration: getDurationForType(type),
  };
};

export const createNetworkErrorMessage = (
  text: Message['text'],
  stackTrace: Message['stackTrace'],
  reportId: Message['reportId'],
): Message => ({
  type: MessageTypes.ERROR,
  display: text,
  text,
  occurrence: 1,
  disableGrouping: false,
  key: nanoid(),
  duration: getDurationForType(MessageTypes.ERROR),
  stackTrace,
  reportId,
});
