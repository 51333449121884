import React, {useCallback, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import ModelEditSuffixForm from './ModelEditSuffixForm';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../../testUtils';
import {trackModelSuffixUpdated} from '../../../../../../tracking/tracking';
import {modelUpdate} from '../../../../../../store/model';
import {ModelActionProps} from '../../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../../test/types';

const ModelEditSuffixSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const {uuid} = model;
  const [workingModel] = useState(model);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (updatedModel: ModelDTO) => {
      return modelUpdate(dispatch, uuid, {metadata: {suffix: updatedModel.metadata.suffix}}).then(() => {
        trackModelSuffixUpdated(updatedModel);
        onSuccess?.();
      });
    },
    [dispatch, uuid, onSuccess],
  );

  const renderFooterButtons = (formObject: any) => {
    return (
      <div className="buttonsContainer">
        <CancelButton
          onClick={onCancel}
          disabled={formObject.submitting}
          {...tid(MODEL_ACTION_MENU_IDS.EDIT_SUFFIX, 'cancel')}
        />
        <SubmitButton
          active={formObject.submitting}
          disabled={!formObject.valid}
          {...tid(MODEL_ACTION_MENU_IDS.EDIT_SUFFIX, 'submit')}
        />
      </div>
    );
  };

  return <ModelEditSuffixForm model={workingModel} onSubmit={handleSubmit} buttons={renderFooterButtons} />;
};

export default ModelEditSuffixSideBarModal;
