import {AnyAction} from 'redux';
import {AppDataActions} from '../appData/types';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {AuthActions} from '../auth/types';
import {calculateExpiration} from './utils';
import {AuthExpirationActions} from './types';

export const authExpirationInitialState = {
  expiration: 0,
  sessionTimeout: 0,
};

export type AuthExpirationState = typeof authExpirationInitialState;

const ignoreActionTypes = [AuthActions.AUTH_LOGOUT, AppDataActions.LOAD_SETTINGS, AppDataActions.LOAD_VERSION];

export const authExpirationReducer = (state = authExpirationInitialState, action: AnyAction): AuthExpirationState => {
  if (action.type === AppDataActions.LOAD_SETTINGS && action.phase === AsyncActionPhase.SUCCESS) {
    return {
      ...state,
      sessionTimeout: action.value.sessionTimeout,
    };
  }

  if (action.phase === AsyncActionPhase.SUCCESS && !ignoreActionTypes.includes(action.type)) {
    return {
      ...state,
      expiration: calculateExpiration(state.sessionTimeout),
    };
  }

  if (action.type === AuthExpirationActions.AUTH_EXPIRATION_SET) {
    return {
      ...state,
      expiration: action.expiration,
    };
  }

  return state;
};
