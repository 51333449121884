import React from 'react';
import {Share16} from '@carbon/icons-react';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import {useVisualizationShareLinkSideBarModal} from './useVisualizationShareLinkSideBarModal';
import {VisualizationShareLinkProps} from './VisualizationShareLink';

const VisualizationShareLinkActionMenuButton: React.FC<
  Partial<VisualizationShareLinkProps> & {scenarioInProgress?: boolean}
> = (props) => {
  const {ModalComponent, openModal} = useVisualizationShareLinkSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.SHARE)}
        onClick={openModal}
        disabled={!props.model || !props.scenario || props.scenarioInProgress}
        icon={<Share16 />}
        text="Share Link"
      />
      {ModalComponent}
    </>
  );
};

export default VisualizationShareLinkActionMenuButton;
