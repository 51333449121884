import React from 'react';
import {
  Divider,
  InputBase,
  Select,
  TableCell,
  TablePaginationProps,
  Theme,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
// @ts-ignore

export const styles = (theme: Theme) => ({
  /* Styles applied to the root element. */
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: 'auto',
    // Increase the specificity to override TableCell.
    '&:last-child': {
      padding: 0,
    },
  },
  /* Styles applied to the Toolbar component. */
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  /* Styles applied to the spacer element. */
  spacer: {
    flex: '1 1 100%',
  },
  /* Styles applied to the caption Typography components if `variant="caption"`. */
  caption: {
    flexShrink: 0,
  },
  // TODO v5: `.selectRoot` should be merged with `.input`
  /* Styles applied to the Select component root element. */
  selectRoot: {
    marginRight: 32,
    marginLeft: 8,
  },
  /* Styles applied to the Select component `select` class. */
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: 'right',
    textAlignLast: 'right', // Align <select> on Chrome.
  },
  // TODO v5: remove
  /* Styles applied to the Select component `icon` class. */
  selectIcon: {},
  /* Styles applied to the `InputBase` component. */
  input: {
    color: 'inherit',
    fontSize: 'inherit',
    flexShrink: 0,
  },
  /* Styles applied to the MenuItem component. */
  menuItem: {},
  /* Styles applied to the internal `TablePaginationActions` component. */
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
});

const defaultLabelDisplayedRows = ({from, to, count}: {from: number; to: number; count: number}) =>
  `${from}-${to} of ${count !== -1 ? count : `more than ${to}`} items`;
const defaultRowsPerPageOptions = [10, 25, 50, 100];

type Props = TablePaginationProps & {
  backIconButtonText?: string;
  nextIconButtonText?: string;
  component?: any;
};

const TablePagination = React.forwardRef(function TablePagination(props: Props, ref) {
  const {
    ActionsComponent = TablePaginationActions,
    backIconButtonProps,
    backIconButtonText = 'Previous page',
    classes,
    className,
    colSpan: colSpanProp,
    component: Component = TableCell,
    count,
    labelDisplayedRows = defaultLabelDisplayedRows,
    labelRowsPerPage = 'Items per page:',
    nextIconButtonProps,
    nextIconButtonText = 'Next page',
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions = defaultRowsPerPageOptions,
    SelectProps = {},
    ...other
  } = props;
  let colSpan;

  if (Component === TableCell || Component === 'td') {
    colSpan = colSpanProp || 1000; // col-span over everything
  }

  const MenuItemComponent = SelectProps.native ? 'option' : MenuItem;

  const pagesCount = count === -1 ? 1 : Math.ceil(count / rowsPerPage);

  return (
    <Component className={clsx(classes!.root, className)} colSpan={colSpan} ref={ref} {...other}>
      <Toolbar className={classes!.toolbar}>
        {rowsPerPageOptions.length > 1 && (
          <Typography color="inherit" variant="body2" className={classes!.caption}>
            {labelRowsPerPage}
          </Typography>
        )}
        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes!.select,
              icon: classes!.selectIcon,
            }}
            input={<InputBase className={clsx(classes!.input, classes!.selectRoot)} />}
            value={rowsPerPage}
            // @ts-ignore
            onChange={onChangeRowsPerPage}
            {...SelectProps}>
            {rowsPerPageOptions.map((rowsPerPageOption) => (
              <MenuItemComponent
                className={classes!.menuItem}
                key={typeof rowsPerPageOption === 'object' ? rowsPerPageOption!.value : rowsPerPageOption}
                value={typeof rowsPerPageOption === 'object' ? rowsPerPageOption!.value : rowsPerPageOption}>
                {typeof rowsPerPageOption === 'object' ? rowsPerPageOption.label : rowsPerPageOption}
              </MenuItemComponent>
            ))}
          </Select>
        )}
        <Divider />
        <Typography color="inherit" variant="body2" className={classes!.caption}>
          {labelDisplayedRows({
            from: count === 0 ? 0 : page * rowsPerPage + 1,
            to: count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage,
            count: count === -1 ? -1 : count,
            page,
          })}
        </Typography>
        <div className={classes!.spacer} />

        {pagesCount > 1 && (
          <>
            <Select
              classes={{
                select: classes!.select,
                icon: classes!.selectIcon,
              }}
              input={<InputBase className={clsx(classes!.input, classes!.selectRoot)} />}
              value={page}
              onChange={(event) => {
                onChangePage(event as any, event.target.value as number);
              }}>
              {Array.from(Array(pagesCount).keys()).map((p) => (
                <MenuItemComponent className={classes!.menuItem} key={p} value={p}>
                  {p + 1}
                </MenuItemComponent>
              ))}
            </Select>
            <Typography color="inherit" variant="body2" className={classes!.caption}>
              &nbsp;&nbsp;of {pagesCount} pages
            </Typography>
            <Divider />
          </>
        )}

        <ActionsComponent
          className={classes!.actions}
          backIconButtonProps={{
            title: backIconButtonText,
            'aria-label': backIconButtonText,
            ...backIconButtonProps,
          }}
          count={count}
          nextIconButtonProps={{
            title: nextIconButtonText,
            'aria-label': nextIconButtonText,
            ...nextIconButtonProps,
          }}
          onChangePage={onChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Toolbar>
    </Component>
  );
});

// @ts-ignore
export default withStyles(styles, {name: 'MuiTablePagination'})(TablePagination);
