import React from 'react';
import {BoxLayout, boxLayoutStyles as styles} from '../../layout/BoxLayout';
import {Button} from '@material-ui/core';
import {getUrlRoute, Routes} from '../../router/routes';

const UnauthorisedPage: React.FC = () => {
  return (
    <BoxLayout>
      <div className={styles.heading}>
        <h2 className={styles.title}>You don&apos;t have permission to view this page.</h2>
        <div className={styles.subtitle}>
          You may continue by navigating to home page or checking the url if you typed it manually.
        </div>
        <div className={styles.links}>
          <Button component="a" href={getUrlRoute(Routes.ROOT)} color="primary" variant="contained">
            Home page
          </Button>
        </div>
      </div>
    </BoxLayout>
  );
};

export default UnauthorisedPage;
