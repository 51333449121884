import React from 'react';
import {useSettings, useVersion} from '../../../store/appData';
import {TO_LOCALE_DATE_STRING_OPTIONS} from '../TableWithSortAndPagination';

const year = new Date().getFullYear();

export const VersionInfo = () => {
  const settings = useSettings();
  const version = useVersion();
  return (
    <>
      &copy; {year} Model Repository{' '}
      {version && (
        <>
          {version.version} {version.buildTime?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}.
        </>
      )}
      <br />
      All rights reserved. {settings?.trackingSettings.enabled && 'Site uses Google Analytics.'}
    </>
  );
};
