import {InputType} from '../InputDefinition/types';
import {stringInputGenerator} from './inputs/StringInput';
import {rangeInputGenerator} from './inputs/RangeInput';
import {arrayInputGenerator} from './inputs/ArrayInput';
import {integerInputGenerator} from './inputs/IntegerInput';
import {fileInputGenerator} from './inputs/FileInput';
import {realNumberInputGenerator} from './inputs/RealNumberInput';
import {singleSelectInputGenerator} from './inputs/SingleSelectInput';
import {multiSelectInputGenerator} from './inputs/MultiSelectInput';
import {selectorInputGenerator} from './inputs/SelectorInput';
import {booleanInputGenerator} from './inputs/BooleanInput';
import {labelInputGenerator} from './inputs/LabelInput';
import {decimalInputGenerator} from './inputs/DecimalInput';
import {dateInputGenerator} from './inputs/DatelInput';

export const generators = {
  [InputType.STRING]: stringInputGenerator,
  [InputType.BOOLEAN]: booleanInputGenerator,
  [InputType.INTEGER]: integerInputGenerator,
  [InputType.REAL_NUMBER]: realNumberInputGenerator,
  [InputType.DECIMAL]: decimalInputGenerator,
  [InputType.DATE]: dateInputGenerator,
  [InputType.SINGLE_SELECT]: singleSelectInputGenerator,
  [InputType.MULTI_SELECT]: multiSelectInputGenerator,
  [InputType.ARRAY]: arrayInputGenerator,
  [InputType.RANGE]: rangeInputGenerator,
  [InputType.LABEL]: labelInputGenerator,
  [InputType.SELECTOR]: selectorInputGenerator,
  [InputType.FILE]: fileInputGenerator,

  // [InputType.JSON]: rangeInputGenerator,
  // [InputType.REFERENCETYPE]: rangeInputGenerator,
  // [InputType.FILEUPLOAD]: rangeInputGenerator,
};
