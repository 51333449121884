import React from 'react';
import {List} from '@material-ui/core';
import ListItem from '../../../core/materialui/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Permission} from '../../../../permissions/types';
import styles from '../../../../components/core/ButtonWithPopper/ButtonWithPopper.module.scss';

type SubModelActionPopperContentProps = {
  onClickAddInstance?: () => void;
  onClickCreateGroup: () => void;
  permission?: Permission;
};

const SubModelActionPopperContent: React.FC<SubModelActionPopperContentProps> = ({
  onClickAddInstance,
  onClickCreateGroup,
  permission,
}) => {
  return (
    <div>
      <List dense component="nav" className={styles.actionlist}>
        <ListItem permission={permission} button disableGutters onClick={onClickAddInstance}>
          <ListItemText primary="Add Instance" />
        </ListItem>
        <ListItem button disableGutters onClick={onClickCreateGroup}>
          <ListItemText primary="Create Group" />
        </ListItem>
      </List>
    </div>
  );
};

export default SubModelActionPopperContent;
