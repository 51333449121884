import {ModelAccessApi, ModelOwnersApi, OwnershipType, UserDTO, ModelAccessDTO} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
const modelOwnersApi = new ModelOwnersApi(configuration);
const modelAccessApi = new ModelAccessApi(configuration);

export const requestList = (modelUuid: string, type: OwnershipType) => modelOwnersApi.getModelOwners({modelUuid, type});

type Access = Record<keyof Omit<ModelAccessDTO, 'vestLead'>, UserDTO[] | undefined> & {
  vestLead?: UserDTO;
  displayViewers?: UserDTO[];
};

export const setList = (modelUuid: string, access: Access) => {
  const modelAccessDTO = (Object.keys(access) as Array<keyof ModelAccessDTO>).reduce((acc, key) => {
    if (access[key]) {
      if (key === 'vestLead') {
        acc[key] = (access[key] as UserDTO).id;
      } else {
        acc[key] = (access[key] || []).map((u: UserDTO) => u.id);
      }
    }
    return acc;
  }, ({} as any) as ModelAccessDTO);

  return modelAccessApi.setModelAccess({modelUuid, modelAccessDTO});
};
