import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ModelDTO} from 'hemwb-api';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Connect16} from '@carbon/icons-react';
import {tid} from '../../../../../testUtils';
import ModelRequestAccessDialog from './ModelRequestAccessDialog';
import {IconButton} from '@material-ui/core';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {requestSecurityAPI} from '../../../../../store/auth/api';
import {useAuth} from '../../../../../store/auth';
import {isModelTheorem} from '../../../../../store/model';

type ModelRequestAccessButtonProps = {
  model: ModelDTO;
  fromDynamicSearch?: boolean;
};

const ModelRequestAccessButton: React.FC<ModelRequestAccessButtonProps> = ({model, fromDynamicSearch}) => {
  const [open, setOpen] = useState(false);
  const [requested, setRequested] = useState(false);
  const {auth} = useAuth();
  const user = auth.loggedInUser;
  const dispatch = useDispatch();
  const theoremModel = isModelTheorem(model);

  const handleSuccess = useCallback(() => {
    setOpen(false);
    setRequested(true);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSecurityAPI = useCallback(() => {
    if (user && model && theoremModel) {
      requestSecurityAPI({securityAPIDTO: {isid: user.id, modelUUID: model.uuid}})
        .then(() => {
          setOpen(true);
        })
        .catch((error) => {
          if (error.status === 500) {
            dispatch(
              messageAdd(
                `Please request access to this model in THEOREM. Please <a href="https://collaboration.merck.com/sites/THEOREM/Lists/FAQ%20%20THEOREM/DispForm.aspx?ID=53&e=mvH1Me">follow these steps</a> to request access in THEOREM.`,
                MessageTypes.ERROR,
              ),
            );
          }
        });
    } else {
      setOpen(true);
    }
  }, [dispatch, model, user, theoremModel]);

  return (
    <>
      {fromDynamicSearch ? (
        <IconButton
          aria-label="Request Access"
          size="small"
          onClick={handleSecurityAPI}
          disabled={requested}
          className="icon32"
          {...tid('model-card-request-access')}>
          <Connect16 />
        </IconButton>
      ) : (
        <SubmitButton
          onClick={handleSecurityAPI}
          disabled={requested}
          type="button"
          endIcon={<Connect16 />}
          {...tid('btn-request-access')}>
          Request Access
        </SubmitButton>
      )}

      {open && (
        <ModelRequestAccessDialog
          model={model}
          open={open && !requested}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ModelRequestAccessButton;
