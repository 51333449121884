import {TabType} from './types';
import {useSelector} from 'react-redux';
import {excelDiffSelector} from '../../../../store/excelDiff';
import {useCallback, useMemo} from 'react';
import {WorkbookItemDTOTypeEnum} from 'hemwb-api';
import {buildKeyFromTab} from './utils';

export const useDiffSelector = (tab: TabType) => {
  const excelDiffState = useSelector(excelDiffSelector);
  return useMemo(() => {
    const key = buildKeyFromTab(tab);
    if (tab.type === WorkbookItemDTOTypeEnum.SHEET) {
      return excelDiffState.sheets[key]?.value || null;
    } else {
      return excelDiffState.macros[key]?.value || null;
    }
  }, [excelDiffState, tab]);
};

export const useDiffSelectorGetter = () => {
  const {sheets, macros} = useSelector(excelDiffSelector);

  return useCallback(
    (tab: TabType, pageIndex = 0) => {
      const key = buildKeyFromTab(tab, pageIndex);
      if (tab.type === WorkbookItemDTOTypeEnum.SHEET) {
        return sheets[key]?.value || null;
      }

      return macros[key]?.value || null;
    },
    [sheets, macros],
  );
};
