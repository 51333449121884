import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {GitBrowseDTO, ModelDTO} from 'hemwb-api';
import {ModelFileUploaded, TreeNode} from '../../../store/modelFile';
import FileTree from '../FileTree/FileTree';
import {
  bitbucketBrowseSelector,
  modelHasValidBitbucketMetadata,
  useBitbucketBrowseLoaderWithParams,
} from '../../../store/bitbucket';
import {useSelector} from '../../../store/rootReducer';
import {useDispatch} from 'react-redux';
import {AsyncActionPhase} from '../../../store/asyncUtilsTypes';
import {Checkbox} from '@material-ui/core';
import SuspenseNull from '../Suspense/SuspenseNull';
import {isModelWeb} from '../../../store/model';

const transformBrowseResponse = (r: GitBrowseDTO): TreeNode[] => {
  return r.children.values.map((n) => {
    // @ts-ignore
    const fullPath = `${r.path.toString}${r.path.toString === '' ? '' : '/'}${n.path.toString}`;
    const folder = n.type !== 'FILE';
    return {
      uuid: n.contentId || fullPath,
      path: fullPath,
      name: n.path.toString,
      uploaded: true,
      folder,
      ...(folder ? {contentLoaded: false} : {}),
    };
  });
};

type BitbucketFileTreeProps = {
  projectName: string;
  repoName: string;
  model: ModelDTO;
  onPathChange: (path: string) => void;
};

const BitbucketFileTree: React.FC<BitbucketFileTreeProps> = ({onPathChange, projectName, repoName, model}) => {
  const dispatch = useDispatch();
  const data = useSelector(bitbucketBrowseSelector);
  const [initialized, setInitialized] = useState(false);
  const [defaultFolderOpen, setDefaultFolderOpen] = useState<string | false>(false);

  const load = useBitbucketBrowseLoaderWithParams();
  const webModel = isModelWeb(model);

  useEffect(() => {
    const promises: Promise<any>[] = [];
    if (
      modelHasValidBitbucketMetadata(model) &&
      model.metadata.bitbucket_project === projectName &&
      model.metadata.bitbucket_repository === repoName
    ) {
      const index = model.metadata.bitbucket_path.lastIndexOf('/');
      if (index > 0) {
        const path = model.metadata.bitbucket_path.substring(0, index);
        setDefaultFolderOpen(path);
        let tmpPath = '';
        const parts = path.split('/');
        parts.forEach((part) => {
          tmpPath = `${tmpPath}${tmpPath === '' ? '' : '/'}${part}`;
          promises.push(
            load([
              {
                projectName,
                repoName,
                path: tmpPath,
              },
            ]),
          );
        });
      }
    }

    Promise.all([
      ...promises,
      load([
        {
          projectName,
          repoName,
          path: '',
        },
      ]),
    ]).then(() => setInitialized(true));
  }, [dispatch, projectName, repoName, load, model]);

  const handleFileTag = useCallback((node: ModelFileUploaded, fileTag: string) => {
    return node.fileTag + fileTag;
  }, []);
  const handleFolderOpen = useCallback(
    (node: TreeNode) => {
      if (!data[node.path]) {
        load([
          {
            projectName,
            repoName,
            path: node.path,
          },
        ]);
      }
    },
    [data, projectName, repoName, load],
  );

  const files: TreeNode[] = useMemo(() => {
    return Object.keys(data).reduce((acc, path) => {
      const res = data[path];
      if (res.state === AsyncActionPhase.SUCCESS) {
        const nodes = transformBrowseResponse(res.value!).map((node) => {
          if (node.folder && data[node.path] && data[node.path].state === AsyncActionPhase.SUCCESS) {
            return {
              ...node,
              contentLoaded: true,
            };
          }
          return node;
        });
        return acc.concat(nodes);
      }
      return acc;
    }, ([] as any) as TreeNode[]);
  }, [data]);

  const renderButtons = useCallback(
    (node: TreeNode) => {
      if (node.folder) {
        return null;
      }
      return [
        <Checkbox
          key={node.uuid}
          color="primary"
          checked={node.path === model.metadata.bitbucket_path}
          onClick={() => onPathChange(node.path)}
        />,
      ];
    },
    [onPathChange, model.metadata.bitbucket_path],
  );

  return (
    <div style={{position: 'relative'}}>
      <SuspenseNull>
        {initialized && (
          <FileTree
            files={files}
            handleFileTag={handleFileTag}
            webModel={webModel}
            defaultFolderOpen={defaultFolderOpen}
            onFolderOpen={handleFolderOpen}
            renderButtons={renderButtons}
            onRowClick={(node) => onPathChange(node.path)}
          />
        )}
      </SuspenseNull>
    </div>
  );
};

export default BitbucketFileTree;
