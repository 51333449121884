import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {buildInitialAsyncState, reduceAsyncAction} from '../asyncUtils';
import {requestLatestNotifications} from './api';
import {notificationAction, notificationActions} from './types';
import {NotificationDTO} from 'hemwb-api';

export type NotificationsState = {
  list: AsyncActionState<
    Await<ReturnType<typeof requestLatestNotifications>>,
    Parameters<typeof requestLatestNotifications>
  >;
  latestNotification: NotificationDTO | null;
  visited: boolean;
};

export const notificationsInitialState: NotificationsState = {
  list: buildInitialAsyncState<NotificationDTO[]>(),
  latestNotification: null,
  visited: true,
};

export const notificationsReducer = (
  state = notificationsInitialState,
  action: notificationAction,
): NotificationsState => {
  switch (action.type) {
    case notificationActions.NOTIFICATIONS_LIST: {
      return {
        ...state,
        list: reduceAsyncAction(action),
        latestNotification: null,
        visited: false,
      };
    }

    case notificationActions.UPDATE_LIST: {
      const {newNotification} = action;

      let listCopy = state.list;
      if (newNotification && state.list.value) {
        if (state.list.value.length === 3) {
          state.list.value.pop();
        }

        listCopy = {
          ...state.list,
          value: [newNotification, ...state.list.value],
        };
      }
      return {
        ...state,
        list: listCopy,
        latestNotification: newNotification,
      };
    }

    case notificationActions.RESET_FIELD: {
      return {
        ...state,
        list: state.list,
        latestNotification: null,
        visited: true,
      };
    }

    case notificationActions.REQUEST_DELETE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [notificationIds] = action.params;
        let listCopy = state.list;
        if (notificationIds && state.list.value) {
          listCopy = {
            ...state.list,
            value: state.list.value.filter((item) => !notificationIds.includes(item.notificationId)),
          };
        }
        return {
          ...state,
          list: listCopy,
        };
      }
    }
  }
  return state;
};
