import React, {PropsWithChildren, useCallback, useContext, useEffect, useRef} from 'react';
import {Drawer} from '@material-ui/core';
import styles from './SideBarModal.module.scss';
import {SideBarRefContext} from './SideBar';
import clsx from 'clsx';
import {tid} from '../../../testUtils';
import {useSelector} from 'react-redux';
import {appZoomSelector} from '../../../store/app';
import {useCurrentUser} from '../../../store/auth';

export type SideBarModalProps = {
  buttonRef?: Element;
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
  height?: number;
};

const SideBarModal: React.FC<PropsWithChildren<SideBarModalProps>> = ({
  className,
  isOpen,
  closeModal,
  buttonRef,
  height: passedHeight,
  children,
}) => {
  const user = useCurrentUser();
  const zoomLevel = useSelector(appZoomSelector);
  const arrowRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useContext(SideBarRefContext);
  const modalRef = useRef<HTMLElement>(null);
  const handleClose = useCallback(() => {
    isOpen && closeModal();
  }, [isOpen, closeModal]);

  if (sideBarRef && sideBarRef.current) {
    sideBarRef.current.onclick = handleClose;
  }

  const getCss = useCallback(() => {
    const output = {
      drawer: {},
      arrow: {},
    };
    if (!buttonRef || !sideBarRef.current) {
      return output;
    }
    const sideBarRect = sideBarRef.current.getBoundingClientRect();
    const buttonTop = buttonRef.getBoundingClientRect().top;

    if (passedHeight) {
      const height = passedHeight * zoomLevel;
      const arrowTop = buttonTop + 6;
      if (arrowTop - 6 - height / 2 < sideBarRect.top) {
        //too top condition
        output.drawer = {
          height,
          top: `${sideBarRect.top + 6}px`,
          marginRight: `${sideBarRect.width + 12}px`,
        };
        output.arrow = {
          marginTop: `${buttonTop - 44}px`,
          opacity: buttonTop > 44 ? 1 : 0,
        };
      } else if (arrowTop + 20 - 6 + height / 2 > window.innerHeight) {
        //too bottom condition
        output.drawer = {
          height,
          top: `${window.innerHeight - height - 6}px`,
          marginRight: `${sideBarRect.width + 12}px`,
        };
        output.arrow = {
          marginTop: `${height - 32 - window.innerHeight + buttonTop + sideBarRect.top}px`,
          opacity: window.innerHeight > buttonTop + 32 ? 1 : 0,
        };
      } else {
        output.drawer = {
          height,
          top: `${arrowTop - height / 2}px`,
          marginRight: `${sideBarRect.width + 12}px`,
        };
        output.arrow = {
          marginTop: `${height / 2}px`,
          opacity: 1,
        };
      }
    } else {
      const arrowTop = buttonTop - sideBarRect.top + 6;
      output.arrow = {
        marginTop: `${arrowTop}px`,
        opacity: arrowTop < 0 || arrowTop > sideBarRect.height - 34 ? 0 : 1,
      };
      output.drawer = {
        height: `calc(100vh - ${window.innerHeight - sideBarRect.height + 20}px)`,
        marginTop: `${sideBarRect.top + 5}px`,
        marginRight: `${sideBarRect.width + 12}px`,
      };
    }
    return output;
  }, [sideBarRef, buttonRef, passedHeight, zoomLevel]);

  useEffect(() => {
    if (sideBarRef && sideBarRef.current) {
      sideBarRef.current.style.zIndex = isOpen && user.id ? '1301' : 'auto';
    }
  }, [isOpen, sideBarRef, user]);

  useEffect(() => {
    if (buttonRef && sideBarRef.current) {
      const setCss = () => {
        const s = getCss();

        Object.keys(s.arrow).forEach((property) => {
          if (arrowRef.current) {
            // @ts-ignore
            arrowRef.current.style[property] = s.arrow[property];
          }
        });
        Object.keys(s.drawer).forEach((property) => {
          if (contentRef.current) {
            // @ts-ignore
            contentRef.current.style[property] = s.drawer[property];
          }
        });
      };
      sideBarRef.current.addEventListener('scroll', setCss);
      const sideBar = sideBarRef.current;
      return () => {
        sideBar.removeEventListener('scroll', setCss);
      };
    }
  }, [buttonRef, getCss, sideBarRef]);

  const css = getCss();

  return (
    <Drawer
      ref={modalRef}
      anchor="right"
      open={isOpen}
      classes={{paper: clsx(styles.modal, className)}}
      PaperProps={{style: css.drawer, ref: contentRef}}
      onClose={handleClose}>
      <div className={clsx('sidebar-modal', styles.content)} {...tid('sidebar-modal')}>
        {children}
      </div>
      <div className={styles.arrow} style={css.arrow} ref={arrowRef} />
    </Drawer>
  );
};

export default SideBarModal;
