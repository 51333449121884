import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Edit32} from '@carbon/icons-react';
import {IncidentActionProps} from '../IncidentActionMenuTypes';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import UpdateIncidentForm from './UpdateIncidentForm';
import {useMounted} from '../../../../../hooks/useMounted';
import {IncidentStatus} from 'hemwb-api';
import {incidentUpdate} from '../../../../../store/incident';
import {messageAdd, MessageTypes} from '../../../../../store/message';

const UpdateIncident: React.FC<IncidentActionProps> = ({
  status,
  incidentId,
  onSuccess,
  onCancel,
  reportId,
  issueSummary,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const handleSubmit = useCallback(
    (status: IncidentStatus, comments: string) => {
      setSubmitting(true);
      return incidentUpdate(dispatch, incidentId ?? '', {
        issueSummary: issueSummary || '',
        reportId: reportId || 0,
        status: status || '',
        comments: comments || '',
      })
        .then(() => {
          dispatch(messageAdd(`The incident has been updated`, MessageTypes.SUCCESS));
          onSuccess?.(status, comments);
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setSubmitting(false);
        });
    },
    [dispatch, onSuccess, mounted, incidentId, issueSummary, reportId],
  );

  return (
    <>
      <ActionHeader label="Edit status" Icon={<Edit32 />}>
        <div style={{fontSize: '1.9rem'}}>
          Update the Incident&apos;s status and
          <br />
          include any closing comments for the user
        </div>
      </ActionHeader>
      <UpdateIncidentForm submitting={submitting} status={status} onCancel={onCancel} onSubmit={handleSubmit} />
    </>
  );
};

export default UpdateIncident;
