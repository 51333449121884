import {RootState} from '../rootReducer';
import {modelDisplayViewersSelector} from './selectors';
import {modelDisplayViewersLoad} from './actions';
import {useReduxLoader, useReduxManualLoader} from '../useReduxLoader';

const displayViewersSelectorBuilder = (params: [string]) => (state: RootState) =>
  modelDisplayViewersSelector(state).displayViewers[params[0]] || null;

export const useModelDisplayViewers = (modelUuid: string) => {
  return useReduxLoader(displayViewersSelectorBuilder, modelDisplayViewersLoad, modelUuid);
};

export const useModelDisplayViewersLoader = (modelUuid: string) => {
  return useReduxManualLoader(displayViewersSelectorBuilder, modelDisplayViewersLoad, modelUuid);
};
