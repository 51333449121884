import React, {useCallback, useEffect, useMemo, useState} from 'react';
import sidebarStyles from '../step1/LeftSideBar.module.scss';
import SideBar from '../../../layout/common/SideBar';
import DefaultPage from '../../../layout/common/DefaultPage';
import VisualizationStep3ActionMenu from './VisualizationStep3ActionMenu';
import VisualizationElement from './VisualizationElement/VisualizationElement';
import './VisualizationStep3.module.scss';
import styles from './VisualizationStep3.module.scss';
import {Routes, useMatchedRoute} from '../../../router/routes';
import VisualizationStepper, {VisualizationSteps} from '../common/VisualizationStepper';
import {
  isScenarioFinished,
  isScenarioRunning,
  requestScenarioGetOutputVisualization,
  useScenarioDetail,
} from '../../../../store/scenario';
import ScenarioError from '../../scenario/detail/ScenarioError';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {useExecutionSetup} from '../../../../store/executionSetup';
import {useSelector} from '../../../../store/rootReducer';
import {
  VisualizationResults,
  VisualizationResultsElement,
  VisualizationResultsElements,
  VisualizationResultsSectionElement,
  visualizationSelector,
} from '../../../../store/visualization';
import {messageAdd, MessageTypes} from '../../../../store/message';
import {useDispatch} from 'react-redux';
import {useModel} from '../../../../store/model';
import {useScenarioStatusUpdates} from '../../../../websocket/useScenarioStatusUpdates';
import SectionList from './SectionList';
import {useAnalysisLoader} from '../common/useAnalysisLoader';
import {ExecutionSetupType} from 'hemwb-api';
import VisualizationInProgress from './VisualizationInProgress';
import {processResults} from './processResults';
import SequentialRenderer from '../../../core/SequentialRenderer';
import {trackDataVisualizationTabEvent} from '../../../../tracking/tracking';

type SectionRangeType = VisualizationResultsSectionElement & {
  start: number;
  end?: number;
};

const initialResults: VisualizationResults = {
  options: {},
  elements: [],
};

const VisualizationStep3: React.FC = () => {
  const dispatch = useDispatch();
  useScenarioStatusUpdates();
  const {modelUuid, scenarioId: _scenarioId} = useMatchedRoute(Routes.VISUALIZATION_STEP_3);
  const scenarioId = Number(_scenarioId);

  const storedState = useSelector(visualizationSelector);
  const {subModel} = storedState.selection || {};

  const {results} = useSelector(visualizationSelector);
  const [fetchedResults, setFetchedResults] = useState<VisualizationResults>();
  const [fetchedResultsScenarioId, setFetchedResultsScenarioId] = useState<number>();
  const [fetchingResults, setFetchingResults] = useState(false);

  const workingResults = (scenarioId === 0 ? results : fetchedResults) || initialResults;
  const {elements, options} = workingResults;

  const model = useModel(modelUuid) || undefined;
  const scenario = useScenarioDetail(scenarioId || null) || undefined;
  const displaySubModel = useExecutionSetup(scenario?.executionSetupId, modelUuid);

  const scenarioInProgress = isScenarioRunning(scenario?.status);

  useAnalysisLoader(modelUuid, scenarioId);

  const [sections, setSections] = useState<SectionRangeType[]>([]);
  const [sectionVisibility, setSectionVisibility] = useState<boolean[]>([]);
  const [elementsVisibility, setElementsVisibility] = useState<boolean[]>([]);

  useEffect(() => {
    if (scenario) {
      // dispatch all required data in order to fill the stepper if scenarioId !== 0
    }
  }, [scenario]);

  useEffect(() => {
    const sections = (elements as VisualizationResultsElement[]).reduce((acc, val, index) => {
      if (val.element === VisualizationResultsElements.SECTION) {
        if (acc.length > 0) {
          acc[acc.length - 1].end = index;
        }

        acc.push({
          ...val,
          start: index,
        });
      }

      return acc;
    }, [] as SectionRangeType[]);

    setSections(sections);
    setSectionVisibility(new Array(sections.length).fill(true));
    setElementsVisibility(new Array(elements.length).fill(true));
  }, [elements]);

  useEffect(() => {
    if (scenario && fetchedResultsScenarioId !== scenario.id && isScenarioFinished(scenario.status)) {
      setFetchingResults(true);
      requestScenarioGetOutputVisualization(scenario.id)
        .then((results) => {
          setFetchedResults(processResults(results));
          setFetchedResultsScenarioId(scenario.id);
          if (model) {
            trackDataVisualizationTabEvent(model);
          }
        })
        .catch((error) => {
          dispatch(messageAdd(error.message || `Invalid data structure. `, MessageTypes.ERROR));
          setFetchedResults(initialResults);
          setFetchedResultsScenarioId(scenario.id);
        })
        .finally(() => setFetchingResults(false));
    }
  }, [dispatch, fetchedResultsScenarioId, scenario, model]);

  const toggleSection = useCallback(
    (sectionIndex: number) => {
      let newState = false;
      const section = sections[sectionIndex];
      if (section) {
        setSectionVisibility((s) => {
          newState = !s[sectionIndex];
          return s.map((v, i) => (i === sectionIndex ? newState : v));
        });
        setElementsVisibility((s) =>
          s.map((v, i) => (i < section.start || (section.end && i >= section.end) ? v : newState)),
        );
      }
    },
    [sections],
  );

  const title = useMemo(() => {
    if (options?.title) {
      return options.title;
    }

    if (subModel) {
      return subModel.type === ExecutionSetupType.CALIBRATION ? 'Calibration Analysis' : 'Scenario Analysis';
    }

    return 'Analysis';
  }, [options, subModel]);

  const elementsToRender = useMemo(() => {
    return elements.map((d, index) =>
      elementsVisibility[index] ? (
        <VisualizationElement
          key={index}
          id={`element-title-${index}`}
          element={d as VisualizationResultsElement}
          elementIndex={index}
          model={model}
          subModel={subModel}
          scenario={scenario}
        />
      ) : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, elementsVisibility]);

  if ((!scenario || !subModel) && scenarioId !== 0) {
    return <SuspenseNull />;
  }

  return (
    <>
      <SideBar position="left" className={sidebarStyles.container} style={{width: '24rem'}}>
        <SectionList
          sections={sections}
          elements={elements}
          toggleSection={toggleSection}
          sectionVisibility={sectionVisibility}
        />
      </SideBar>
      <DefaultPage style={{position: 'relative'}}>
        <VisualizationStepper step={VisualizationSteps.STEP_3} />
        <div className={styles.visualElements}>
          <h1 className={styles.pageTitle}>{title}</h1>
          <VisualizationInProgress
            scenarioInProgress={scenarioInProgress}
            fetchingResults={fetchingResults}
            displaySubModel={displaySubModel}
          />
          <ScenarioError scenario={scenario} />
          <SequentialRenderer elements={elementsToRender} />
        </div>
      </DefaultPage>
      <VisualizationStep3ActionMenu
        modelUuid={modelUuid}
        scenarioId={scenarioId}
        scenarioInProgress={scenarioInProgress}
        model={model}
        subModel={subModel}
        scenario={scenario}
        results={workingResults}
      />
    </>
  );
};

export default VisualizationStep3;
