import React, {useEffect} from 'react';
import ModelUploadPageHeader from '../common/ModelUploadPageHeader';
import ModelEditFiles, {ModelEditFilesProps} from '../../model/common/ModelEdit/ModelEditFiles/ModelEditFiles';
import ModelUploadFooter from '../common/ModelUploadFooter';
import {trackFilesTabEvent} from '../../../../tracking/tracking';

type StepFileProps = Omit<ModelEditFilesProps, 'buttons'>;

const renderButtons = () => {
  return <ModelUploadFooter />;
};

const StepFiles: React.FC<StepFileProps> = (props) => {
  useEffect(() => {
    trackFilesTabEvent();
  }, []);
  return (
    <div>
      <ModelUploadPageHeader
        title="Upload files from Bitbucket or your local storage"
        subTitle={
          <>
            If you already have existing project in Bitbucket, you can upload from there.
            <br />
            For Excel models or scripted languages not in Bitbucket, please use Upload from local.
          </>
        }
      />
      <ModelEditFiles uploadFlow={true} buttons={renderButtons} {...props} />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default StepFiles;
