import {ModelInputType} from 'hemwb-api';
import {readFileAsync} from '../../../utils/readFileAsync';
import {parseFileExtension} from '../../../store/modelFile/utils';
import {formatInput} from '../../core/ModelInput/InputDefinition/formatInput';
import {importFromXML} from '../../core/ModelInput/InputDefinition/importFromXML';
import {expertiseLevel} from '../../core/ModelInput/InputDefinition/types';

export const parseModelInputFile = async (type: ModelInputType, file?: File): Promise<string> => {
  if (file) {
    const fileContent = await readFileAsync(file);
    const extension = parseFileExtension(file.name);

    if (extension === 'json') {
      const parsedJson = JSON.parse(fileContent as string);

      const finalJSON = parsedJson.map((item: any) => {
        // @ts-ignore
        item.expertiseLevel = expertiseLevel[item.expertise];
        const {expertise, ...rest} = item;
        return rest;
      });

      if (type === ModelInputType.JSONTEMPLATE) {
        if (!Array.isArray(finalJSON)) {
          throw new Error('Invalid data structure');
        }
        return Promise.resolve(
          JSON.stringify({
            definitions: finalJSON.map((i: any) => formatInput(i)),
          }),
        );
      } else if (type === ModelInputType.JSON) {
        return Promise.resolve(JSON.stringify({definitions: finalJSON}));
      }

      throw new Error('Unsupported Input Definition Type');
    } else if (extension === 'xml') {
      if (type === ModelInputType.JSONTEMPLATE) {
        return Promise.resolve(
          JSON.stringify({
            definitions: importFromXML(fileContent as string),
          }),
        );
      }
    }

    throw new Error('Unsupported File Extension');
  }

  return Promise.resolve('');
};
