import React, {useCallback, useMemo, useState} from 'react';
import {useMounted} from '../../../../../hooks/useMounted';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {useScenarioDetail} from '../../../../../store/scenario';
import {useDispatch} from 'react-redux';
import {trackInstanceExecuted} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {ScenarioOutdateWarning} from '../ScenarioOutdateWarning/ScenarioOutdateWarning';
import {PlayOutline16} from '@carbon/icons-react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useModelInput} from '../../../../../store/modelInputs';
import {scenarioExecutionCreate, scenarioExecutionExecute} from '../../../../../store/scenarioExecution';
import {isScenarioParametersValid} from '../../../../core/ModelInput/ScenarioParameters/isScenarioParametersValid';
import {useModelFile} from '../../../../../store/modelFile';

const ScenarioExecute: React.FC<ScenarioActionProps> = ({model, subModel, scenario, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const scenarioDetail = useScenarioDetail(scenario.id);
  const input = useModelInput(subModel.id);
  const {files} = useModelFile(model);

  const fileError = useMemo(() => {
    if (files) {
      if (subModel.executionModels.length === 0) {
        return 'There is no executable file.';
      }
      const missingFiles = subModel.executionModels.filter((file) => !files.find((f) => f.uuid === file.uuid));
      if (missingFiles.length > 0) {
        return `${missingFiles.map((f) => f.name).join(', ')} file${
          missingFiles.length === 1 ? ' is' : 's are'
        } missing.`;
      }
    }
  }, [files, subModel]);

  let isMissingCalibrationInstance;
  if (subModel.executionSetupId && !scenarioDetail?.scenarioId) {
    isMissingCalibrationInstance = true;
  }

  const hasValidParameters = useMemo(() => {
    return input && scenarioDetail ? isScenarioParametersValid(scenarioDetail, input) : false;
  }, [input, scenarioDetail]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      const group = await scenarioExecutionCreate(dispatch, scenario.id);

      if (!group.groupId) {
        dispatch(messageAdd('Failed to create an execution', MessageTypes.ERROR));
      } else {
        await scenarioExecutionExecute(dispatch, group.groupId);
        trackInstanceExecuted(model, subModel, scenario);
        dispatch(messageAdd('Scenario execution request sent', MessageTypes.SUCCESS));
        onSuccess?.();
      }
    } catch (e) {
    } finally {
      mounted.current && setSubmitting(false);
    }
  }, [dispatch, mounted, model, subModel, scenario, onSuccess]);

  if (!scenarioDetail || !files) {
    return <SuspenseNull />;
  }

  return (
    <>
      <h2>Execute Instance</h2>
      <h3>{scenario.name}</h3>
      {hasValidParameters && !isMissingCalibrationInstance && !fileError && (
        <>Are you sure you want to execute the selected instance? Instance will be queued.</>
      )}

      {!hasValidParameters && (
        <span className="colorError">Instance parameters are invalid a need to be fixed before execution. </span>
      )}
      {isMissingCalibrationInstance && (
        <span className="colorError">Calibration Instance has to be selected before execution. </span>
      )}
      {fileError && <span className="colorError">{fileError}</span>}

      <br />
      <br />
      {scenario && <ScenarioOutdateWarning modelUuid={model.uuid} />}
      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_EXECUTE, 'cancel')} />
        <SubmitButton
          onClick={handleSubmit}
          active={submitting}
          disabled={!hasValidParameters || isMissingCalibrationInstance || !!fileError}
          endIcon={<PlayOutline16 />}
          {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_EXECUTE, 'submit')}>
          Execute
        </SubmitButton>
      </div>
    </>
  );
};

export default ScenarioExecute;
