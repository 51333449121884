import React, {useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import VisualizationTestResults from './VisualizationTestResults';
import {ModelActionProps} from '../../../actionMenuTypes';

export const useVisualizationTestResults = (props: Partial<ModelActionProps>) => {
  const {model} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const ModalComponent = useMemo(() => {
    return (
      model && (
        <SideBarModal
          height={350}
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && <VisualizationTestResults model={model} onCancel={closeModal} onSuccess={closeModal} />}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, model]);

  return {
    openModal,
    ModalComponent,
  };
};
