import React, {MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {DiffDTO, DiffDTOTagEnum, SheetDiffDTO} from 'hemwb-api';
import {ChangeItem, ROWS_PER_PAGE, ViewMode} from './types';
import {getColumnName, getColumnsCount} from './utils';
import styles from './SheetDiff.module.scss';
import clsx from 'clsx';
import Cell, {CellMode} from './Cell';
import {useSelector} from 'react-redux';
import {appZoomSelector} from '../../../../store/app';
import SheetDiffUnchangedRow from './SheetDiffUnchangedRow';

type SheetDiffProps = {
  diff: SheetDiffDTO;
  markedChange?: ChangeItem;
  pageIndex: number;
  viewMode: ViewMode;
};

const SheetDiff: React.FC<SheetDiffProps> = ({diff, markedChange, pageIndex, viewMode}) => {
  const zoomLevel = useSelector(appZoomSelector);

  const renderRowCount = diff.cellDiff.length;
  const renderColumnCount = useMemo(() => getColumnsCount(diff), [diff]);

  const startingRowDefault = pageIndex * ROWS_PER_PAGE;

  const originalData = useMemo(() => {
    const tmp = [...diff.cellDiff] as (DiffDTO[] | null)[];
    diff.rowDiff.inserted.forEach((index) => {
      const i = startingRowDefault > 0 ? index - startingRowDefault : index;
      tmp[i] = null;
    });
    return tmp;
  }, [diff, startingRowDefault]);

  const newData = useMemo(() => {
    const tmp = [...diff.cellDiff] as (DiffDTO[] | null)[];
    diff.rowDiff.deleted.forEach((index) => {
      const i = startingRowDefault > 0 ? index - startingRowDefault : index;
      tmp[i] = null;
    });
    return tmp;
  }, [diff, startingRowDefault]);

  const startingRowOriginal =
    startingRowDefault - diff.rowDiff.inserted.filter((index) => index < startingRowDefault).length;
  const startingRowNew = startingRowDefault - diff.rowDiff.deleted.filter((index) => index < startingRowDefault).length;

  const getRowNumber = (index: number, src: 'original' | 'new' = 'original') => {
    const data = src === 'new' ? newData : originalData;
    const start = src === 'new' ? startingRowNew : startingRowOriginal;

    if (!data[index]) {
      return null;
    }

    return start + data.filter((r, i) => i <= index && r).length;
  };

  const columnNamesOld = useMemo(() => {
    const tmp = new Array(renderColumnCount).fill(true);
    diff.columnDiff.inserted.forEach((index) => {
      tmp[index] = null;
    });
    return tmp.map((el, index) => {
      if (!el) {
        return null;
      }
      return getColumnName(tmp.filter((r, i) => i <= index && r).length - 1);
    });
  }, [diff, renderColumnCount]);

  const columnNamesNew = useMemo(() => {
    const tmp = new Array(renderColumnCount).fill(true);
    diff.columnDiff.deleted.forEach((index) => {
      tmp[index] = null;
    });
    return tmp.map((el, index) => {
      if (!el) {
        return null;
      }
      return getColumnName(tmp.filter((r, i) => i <= index && r).length - 1);
    });
  }, [diff, renderColumnCount]);

  const wrapper1Ref = useRef<HTMLDivElement>(null);
  const wrapper2Ref = useRef<HTMLDivElement>(null);
  const markedCellRef = useRef<HTMLTableCellElement>(null);

  useLayoutEffect(() => {
    if (markedCellRef.current) {
      markedCellRef.current.focus();
    }
  }, [markedCellRef, markedChange, diff]);

  useEffect(() => {
    if (viewMode === ViewMode.DOUBLE_PANE) {
      const tables = document.querySelectorAll(`.${styles.doublePaneWrapper} table`);
      if (tables.length === 2) {
        //align rows height
        const rows1 = tables[0].querySelectorAll('tr');
        const rows2 = tables[1].querySelectorAll('tr');
        rows1.forEach((tr, index) => {
          if (rows2[index]) {
            if (tr.offsetHeight > rows2[index].offsetHeight) {
              rows2[index].style.height = `${tr.offsetHeight}px`;
            } else if (tr.offsetHeight < rows2[index].offsetHeight) {
              tr.style.height = `${rows2[index].offsetHeight}px`;
            }
          }
        });
      }
    }
  }, [diff, zoomLevel, viewMode]);

  useEffect(() => {
    if (viewMode === ViewMode.DOUBLE_PANE && wrapper1Ref.current && wrapper2Ref.current) {
      const synchronizeScroll = (event: Event) => {
        const controlledDiv =
          wrapper1Ref.current === event.currentTarget ? wrapper2Ref.current : (wrapper1Ref.current as HTMLDivElement);
        controlledDiv!.scrollLeft = (event.currentTarget as HTMLDivElement).scrollLeft;
      };

      const el1 = wrapper1Ref.current;
      const el2 = wrapper2Ref.current;

      el1.addEventListener('scroll', synchronizeScroll);
      el2.addEventListener('scroll', synchronizeScroll);

      return () => {
        el1.removeEventListener('scroll', synchronizeScroll);
        el2.removeEventListener('scroll', synchronizeScroll);
      };
    }
  }, [viewMode, wrapper1Ref, wrapper2Ref]);

  const changedCellIndex1Ref = useRef(markedChange?.diff?.firstDiffIndexInPage || 0);
  changedCellIndex1Ref.current = markedChange?.diff?.firstDiffIndexInPage || 0;

  const changedCellIndex2Ref = useRef(markedChange?.diff?.firstDiffIndexInPage || 0);
  changedCellIndex2Ref.current = markedChange?.diff?.firstDiffIndexInPage || 0;

  const renderCells = (
    cells: DiffDTO[] | null,
    counterRef: MutableRefObject<number>,
    mode: CellMode = 'both',
    definedCellsOnRow = renderColumnCount,
  ) => {
    const data = cells || [];
    return data.concat(Array.from(Array(renderColumnCount - data.length))).map((cell: any, cellKey: number) => {
      if (cell) {
        let marked = false;
        if (cell.tag !== DiffDTOTagEnum.Equal) {
          marked = counterRef.current === markedChange?.diffIndex;
          counterRef.current++;
        }

        return (
          <Cell
            key={cellKey}
            cell={cell}
            mode={mode}
            marked={marked}
            {...(marked ? {cellRef: markedCellRef, tabIndex: 0} : {})}
          />
        );
      }

      if (!cells && cellKey < definedCellsOnRow) {
        const marked = counterRef.current === markedChange?.diffIndex;
        counterRef.current++;
        const cellProps = marked ? {className: 'marked', tabIndex: 0, ref: markedCellRef} : {};

        return <td key={cellKey} {...cellProps} />;
      }

      return <td key={cellKey} />;
    });
  };

  return (
    <>
      <br />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}></div>

      {viewMode === ViewMode.DOUBLE_PANE ? (
        <div className={styles.doublePaneWrapper}>
          <div ref={wrapper1Ref}>
            <table className={clsx(styles.container, styles.oldTable)}>
              <thead>
                <tr>
                  <th />
                  {columnNamesOld.map((name, index) => (
                    <th key={index}>{name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from(Array(renderRowCount)).map((row, index) => {
                  const rowWithChange = diff.cellDiff[index].some((c: any) => c.tag !== DiffDTOTagEnum.Equal);

                  if (!rowWithChange) {
                    return (
                      <SheetDiffUnchangedRow
                        key={index}
                        cells={originalData[index]}
                        mode="old"
                        rowNumber={getRowNumber(index)}
                        renderColumnCount={renderColumnCount}
                      />
                    );
                  }

                  return (
                    <tr key={index} className={styles.rowWithChange}>
                      <th>{getRowNumber(index)}</th>
                      {renderCells(originalData[index], changedCellIndex1Ref, 'old', newData[index]?.length)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div ref={wrapper2Ref}>
            <table className={clsx(styles.container, styles.newTable)}>
              <thead>
                <tr>
                  <>
                    <th />
                    {columnNamesNew.map((name, index) => (
                      <th key={index}>{name}</th>
                    ))}
                  </>
                </tr>
              </thead>
              <tbody>
                {Array.from(Array(renderRowCount)).map((row, index) => {
                  const rowWithChange = diff.cellDiff[index].some((c: any) => c.tag !== DiffDTOTagEnum.Equal);

                  if (!rowWithChange) {
                    return (
                      <SheetDiffUnchangedRow
                        key={index}
                        cells={newData[index]}
                        mode="new"
                        rowNumber={getRowNumber(index, 'new')}
                        renderColumnCount={renderColumnCount}
                      />
                    );
                  }

                  return (
                    <tr key={index} className={styles.rowWithChange}>
                      <th>{getRowNumber(index, 'new')}</th>
                      {renderCells(newData[index], changedCellIndex2Ref, 'new', originalData[index]?.length)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <table className={clsx(styles.container, styles.singlePane)}>
            <thead>
              <tr>
                <th />
                {columnNamesOld.map((name, index) => (
                  <th key={index}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from(Array(renderRowCount)).map((row, index) => {
                const rowWithChange = diff.cellDiff[index].some((c: any) => c.tag !== DiffDTOTagEnum.Equal);

                if (!rowWithChange) {
                  return (
                    <SheetDiffUnchangedRow
                      key={index}
                      cells={diff.cellDiff[index]}
                      rowNumber={getRowNumber(index)}
                      renderColumnCount={renderColumnCount}
                    />
                  );
                }

                return (
                  <tr key={index} className={styles.rowWithChange}>
                    <th>{getRowNumber(index)}</th>
                    {renderCells(diff.cellDiff[index], changedCellIndex1Ref)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default SheetDiff;
