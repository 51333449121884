import React from 'react';
import {FormArray, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {DropDownControl, SingleCheckboxControl, TextFieldControl} from '../../../Form';
import {StringInputPrimitive, stringInputPrimitiveControlBuilder} from './StringInputPrimitive';
import {arrayInputTypes, ColumnType, InputType} from '../types';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from './NumberInputPrimitive';
import GridItem from '../../../Form/GridItem';
import {getModelInputTypeLabel} from '../utils';

type ArrayColumnPrimitiveProps = {
  selectedColumn?: number;
  selectedRow?: number;
  columns: FormArray;
};

export const ArrayColumnPrimitive: React.FC<ArrayColumnPrimitiveProps> = ({selectedColumn, selectedRow, columns}) => {
  if (selectedColumn === undefined) {
    return null;
  }

  const column = columns.controls[selectedColumn] as FormGroup;
  const dataType = column.get('dataType').value;

  const Component = dataType === InputType.STRING ? StringInputPrimitive : NumberInputPrimitive;

  return (
    <>
      <TextFieldControl label="Text" control={column.get('text')} name="text" />
      <SingleCheckboxControl name="required" label="Required" control={column.get('required')} />
      <GridItem>
        <DropDownControl
          label="Data Type"
          control={column.get('dataType')}
          name="dataType"
          options={Object.keys(arrayInputTypes)}
          getOptionLabel={getModelInputTypeLabel}
        />
      </GridItem>
      <Component formGroup={column} index={selectedRow} type={dataType} />
    </>
  );
};

export const generateSingleDefaultValueControl = (defaultValue: any, dataType: InputType) => {
  if (dataType === InputType.STRING) {
    const controls = stringInputPrimitiveControlBuilder({defaultValue});
    return controls.defaultValue;
  }

  // @ts-ignore
  const controls = numberInputPrimitiveControlBuilder({defaultValue}, dataType);
  return controls.defaultValue;
};

export const generateColumnFormGroup = (column: ColumnType, clear = false) => {
  const initialValues = clear ? ({defaultValue: (column.defaultValue as any[]).map(() => null)} as any) : column;
  let controls;
  if (!column.dataType || column.dataType === InputType.STRING) {
    controls = {
      ...stringInputPrimitiveControlBuilder(initialValues),
      defaultValue: new FormArray(
        initialValues.defaultValue.map((defaultValue: any) =>
          generateSingleDefaultValueControl(defaultValue, column.dataType),
        ),
      ),
    };
  } else {
    controls = {
      // @ts-ignore
      ...numberInputPrimitiveControlBuilder(initialValues, column.dataType),
      defaultValue: new FormArray(
        initialValues.defaultValue.map((defaultValue: any) =>
          generateSingleDefaultValueControl(defaultValue, column.dataType),
        ),
      ),
    };
  }

  return new FormGroup({
    dataType: new FormControl(column.dataType || InputType.STRING),
    text: new FormControl(column.text, [Validators.required]),
    required: new FormControl(column.required),
    ...controls,
  });
};
