/* eslint-disable @typescript-eslint/camelcase */
import {useDispatch} from 'react-redux';
import {modelFileItemHistorySelectorBuilder, modelFileSelector} from './selectors';
import {
  modelFileAdd,
  modelFileDelete,
  modelFileItemHistory,
  modelFileLoadTHTree,
  modelFileLoadTree,
  modelFileMarkDelete,
  modelFileMarkFileTag,
  modelFileRemove,
  modelFileSubmitAbort,
  modelFileSubmitFinish,
  modelFileSubmitStart,
  modelFileTagUpdate,
  modelFileUnmarkDelete,
  unMarkFileTagUpdate,
  modelFileUpdate,
  modelFileUpload,
  modelFileUploaderInit,
  modelFileUploaderMerge,
} from './actions';
import {ModelFileQueued, ModelFileTags, ModelTreeNode} from './types';
import {
  calculateFileName,
  calculateTags,
  isModelFileUploaded,
  nodeAddTag,
  nodeHasTag,
  parseFileExtension,
  parseFileNameFromPath,
} from './utils';
import {messageAdd, MessageTypes} from '../message';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import nanoid from 'nanoid';
import {RootState, useSelector} from '../rootReducer';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {isModelPublished, modelUpdate} from '../model';
import {trackModelItemsUpdated} from '../../tracking/tracking';
import {GitCommitDTO, ModelDTO, ModelItemType} from 'hemwb-api';
import {useReduxManualLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {bitbucketSaveFile, modelHasValidBitbucketMetadata, useBitbucketHistoryLoaderWithParams} from '../bitbucket';
import {
  canViewModelFiles,
  canAccessCalibrationManagement,
  canAccessScenarioManagement,
} from '../../permissions/usePermissions';

const fileSelectorBuilder = (params: [string]) => (state: RootState) =>
  modelFileSelector(state).fetched[params[0] || ''] || null;

const fileTHSelectorBuilder = (params: [string]) => (state: RootState) =>
  modelFileSelector(state).fetchedTH[params[0] || ''] || null;

export const useModelFile = (model: ModelDTO | null, isRefreshEnabled = true) => {
  const modelUuid = model?.uuid || '';
  const dispatch = useDispatch();
  const fetchedTree = useSelector((state) => modelFileSelector(state).fetched[modelUuid]);
  const refreshOlder = Date.now() - 10 * 60 * 1000;
  let reloading = false;

  if (
    (!fetchedTree || (fetchedTree.timestamp < refreshOlder && isRefreshEnabled)) &&
    (canViewModelFiles(model || undefined) ||
      canAccessCalibrationManagement(model || undefined) ||
      canAccessScenarioManagement(model || undefined))
  ) {
    reloading = true;
    modelUuid !== '' && modelFileLoadTree(dispatch, modelUuid, ['Parameter'], []);
  }

  const config = useSelector((state) => modelFileSelector(state).config);
  const modelPublished = isModelPublished(model);
  const modelLanguage = model?.metadata.type;
  const bitbucketPath = model?.metadata.bitbucket_path;
  const files = useMemo(() => {
    return fetchedTree && fetchedTree.state === AsyncActionPhase.SUCCESS && !reloading
      ? fetchedTree.value?.map((node) =>
          nodeAddTag(node, calculateTags(node, config, modelPublished, modelLanguage, bitbucketPath)),
        ) || null
      : null;
  }, [fetchedTree, reloading, config, bitbucketPath, modelLanguage, modelPublished]);

  return {
    files,
  };
};

export const useModelFileTH = (model: ModelDTO | null, isRefreshEnabled = true) => {
  const modelUuid = model?.uuid || '';
  const dispatch = useDispatch();
  const fetchedTree = useSelector((state) => modelFileSelector(state).fetchedTH[modelUuid]);
  const refreshOlder = Date.now() - 10 * 60 * 1000;
  let reloading = false;

  if (
    (!fetchedTree || (fetchedTree.timestamp < refreshOlder && isRefreshEnabled)) &&
    (canViewModelFiles(model || undefined) ||
      canAccessCalibrationManagement(model || undefined) ||
      canAccessScenarioManagement(model || undefined))
  ) {
    reloading = true;
    modelUuid !== '' && modelFileLoadTHTree(dispatch, modelUuid);
  }

  const files = useMemo(() => {
    return fetchedTree && fetchedTree.state === AsyncActionPhase.SUCCESS && !reloading
      ? fetchedTree.value || null
      : null;
  }, [fetchedTree, reloading]);

  return {
    files,
  };
};

export const useModelFileTreeLoader = (modelUuid: string, filterTags?: Array<string>, excludeTags?: Array<string>) => {
  return useReduxManualLoader(fileSelectorBuilder, modelFileLoadTree, [modelUuid, filterTags, excludeTags]);
};

export const useModelFileTreeTHLoader = (modelUuid: string) => {
  return useReduxManualLoader(fileTHSelectorBuilder, modelFileLoadTHTree, modelUuid);
};

export const useModelFileItemHistoryLoader = (model: ModelDTO) => {
  return useReduxManualLoader(modelFileItemHistorySelectorBuilder, modelFileItemHistory, [
    model.uuid,
    model.metadata.main_file,
  ]);
};

export const useModelFileItemHistoryLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(modelFileItemHistorySelectorBuilder, modelFileItemHistory);
};

export const useModelFileUploader = (model: ModelDTO | null, filesCreation?: ModelTreeNode[]) => {
  const [initialModelState] = useState(model);
  const modelUuid = model?.uuid || '';
  const dispatch = useDispatch();

  const loadHistory = useBitbucketHistoryLoaderWithParams();
  const uploader = useSelector((state) => modelFileSelector(state).uploader);
  const {nodes} = uploader;
  const config = useSelector((state) => modelFileSelector(state).config);
  const isInitialized = uploader.modelUuid === modelUuid;
  const modelPublished = isModelPublished(model);
  const modelLanguage = model?.metadata.type;
  const bitbucketPath = model?.metadata.bitbucket_path;
  const abortRef = useRef(false);

  const fileResult = useModelFile(model, uploader.modelUuid !== modelUuid);
  const files = filesCreation || fileResult.files;

  useEffect(() => {
    if (!isInitialized && Array.isArray(files)) {
      dispatch(
        modelFileUploaderInit(
          modelUuid,
          files.map((node) =>
            nodeAddTag(node, calculateTags(node, config, modelPublished, modelLanguage, bitbucketPath)),
          ),
        ),
      );
    }
  }, [isInitialized, modelUuid, files, dispatch, config, modelPublished, modelLanguage, bitbucketPath]);

  useEffect(() => {
    if (bitbucketPath && isInitialized) {
      const name = calculateFileName(parseFileNameFromPath(bitbucketPath));

      const node: ModelFileQueued = {
        folder: false,
        uploaded: false,
        file: new File([''], name),
        uuid: bitbucketPath,
        name: name,
        path: bitbucketPath,
      };

      const tags = calculateTags(node, config, modelPublished, modelLanguage, bitbucketPath);

      dispatch(modelFileAdd(modelUuid, nodeAddTag(node, tags)));

      return () => {
        dispatch(modelFileRemove(modelUuid, node));
      };
    }
  }, [dispatch, bitbucketPath, isInitialized, config, modelLanguage, modelPublished, modelUuid]);

  const [totalOperationsCount, setTotalOperationsCount] = useState(0);
  const remainingOperationsCount = useMemo(() => {
    return nodes.filter((node) => (node.uploaded && nodeHasTag(node, ModelFileTags.DELETE)) || !node.uploaded).length;
  }, [nodes]);

  const promiseResolve = useRef<null | (() => void)>(null);
  const promiseReject = useRef<null | (() => void)>(null);

  if (promiseResolve.current) {
    if (
      !uploader.isSubmitting &&
      !uploader.nodes.some((node) => !node.uploaded || nodeHasTag(node, ModelFileTags.DELETE))
    ) {
      // @ts-ignore
      promiseResolve.current(true);
    }
  }

  if (promiseReject.current) {
    if (!uploader.isSubmitting && uploader.nodes.some((node) => nodeHasTag(node, ModelFileTags.UPLOAD_ERROR))) {
      promiseReject.current();
    }
  }

  const abort = useCallback(() => {
    abortRef.current = true;
    dispatch(modelFileSubmitAbort());
  }, [dispatch, abortRef]);

  return {
    uploader,
    model,
    remainingOperationsCount,
    totalOperationsCount,

    add: (file: File, path: string) => {
      if (modelPublished) {
        const isReplacingMainFile = uploader.nodes.some((n) => isModelFileUploaded(n) && n.mainFile && n.path === path);
        if (isReplacingMainFile) {
          dispatch(messageAdd(`Unable to replace main file on published model`, MessageTypes.ERROR));
          return;
        }
        if (modelLanguage) {
          const lockedFileExtensions = config.modelFileExtensions[modelLanguage];
          if (lockedFileExtensions) {
            const isUploadingLockedExtension = lockedFileExtensions.includes(parseFileExtension(file.name));
            if (isUploadingLockedExtension) {
              dispatch(
                messageAdd(
                  `Unable to add or replace files with extensions corresponding to selected model language on a published model`,
                  MessageTypes.ERROR,
                ),
              );
              return;
            }
          }
        }
      }

      const node: ModelFileQueued = {
        folder: false,
        uploaded: false,
        file,
        uuid: nanoid(),
        name: file.name,
        path,
      };

      const tags = calculateTags(node, config, modelPublished, modelLanguage, bitbucketPath);

      dispatch(modelFileAdd(modelUuid, nodeAddTag(node, tags)));
    },
    markForFileTag: (node: ModelTreeNode, fileTag: string) => {
      dispatch(modelFileMarkFileTag(fileTag, node));
    },
    unMarkFileTagUpdate: (modelUuid: string, node: ModelTreeNode) => {
      dispatch(unMarkFileTagUpdate(modelUuid, node));
    },
    markDelete: (node: ModelTreeNode) => {
      dispatch(modelFileMarkDelete(modelUuid, node));
    },
    unmarkDelete: (node: ModelTreeNode) => {
      dispatch(modelFileUnmarkDelete(modelUuid, node));
    },
    remove: (node: ModelTreeNode) => {
      dispatch(modelFileRemove(modelUuid, node));
    },

    start: (updatedModel?: ModelDTO): Promise<boolean> => {
      const createdModelUuid = updatedModel?.uuid;
      abortRef.current = false;
      if (uploader.isSubmitting || (uploader.modelUuid !== modelUuid && !createdModelUuid)) {
        return Promise.reject();
      }

      setTotalOperationsCount(remainingOperationsCount);
      const promise = new Promise<boolean>((resolve, reject) => {
        promiseResolve.current = resolve;
        promiseReject.current = reject;
      });

      const nodesToDelete = uploader.nodes
        .filter((node) => node.uploaded && nodeHasTag(node, ModelFileTags.DELETE))
        .map((node) => {
          return () => modelFileDelete(dispatch, modelUuid, node.uuid);
        });

      const nodesToUpdateFileTag = uploader.nodes
        .filter((node) => node.uploaded && nodeHasTag(node, ModelFileTags.FILE_TAG_OVERWRITE))
        .map((node) => {
          return () =>
            modelFileTagUpdate(dispatch, {
              fileTagDTO: {
                type: 'file_tag',
                value: node.fileTag ? node.fileTag : 'Model',
                identifier: node.uuid,
                optionId: 0,
                status: 'ACTIVE',
                modelUuid: modelUuid,
              },
            });
        });

      const nodesToUpload = uploader.nodes
        .filter(
          (node): node is ModelFileQueued =>
            !node.uploaded &&
            !nodeHasTag(node, ModelFileTags.OVERWRITE) &&
            !nodeHasTag(node, ModelFileTags.BITBUCKET_FILE),
        )
        .map((node) => {
          return () =>
            modelFileUpload(dispatch, {
              modelUuid: createdModelUuid || modelUuid,
              type: ModelItemType.EXECUTION,
              file: node.file,
              path: node.path,
              fileTag: node.fileTag,
            });
        });

      const nodesToUpdate = uploader.nodes
        .filter(
          (node): node is ModelFileQueued =>
            !node.uploaded &&
            nodeHasTag(node, ModelFileTags.OVERWRITE) &&
            !nodeHasTag(node, ModelFileTags.BITBUCKET_FILE),
        )
        .map((node) => {
          return () => {
            const oldNode = uploader.nodes.find((n) => n.uploaded && n.path === node.path);
            return modelFileUpdate(dispatch, {
              modelUuid,
              itemUuid: oldNode?.uuid || '',
              type: ModelItemType.EXECUTION,
              file: node.file,
              path: node.path,
              fileTag: node.fileTag,
            });
          };
        });

      let bitbucketPromise: () => Promise<any> = () => Promise.resolve();
      if (updatedModel && initialModelState) {
        const {
          bitbucket_project: projectName,
          bitbucket_repository: repoName,
          bitbucket_path: path,
          bitbucket_commit: commit,
        } = updatedModel.metadata;

        if (modelHasValidBitbucketMetadata(updatedModel)) {
          bitbucketPromise = () =>
            loadHistory([
              {
                projectName,
                repoName,
              },
            ]).then((res: any) => {
              const history = res as GitCommitDTO[];

              const newCommit = history && history.length > 0 && history[0].id !== commit ? history[0].id : null;
              if (newCommit) {
                return bitbucketSaveFile(dispatch, {
                  projectName,
                  repoName,
                  path,
                  revision: newCommit,
                  modelId: updatedModel.uuid,
                }).then(() =>
                  modelUpdate(dispatch, updatedModel.uuid, {
                    ...updatedModel,
                    metadata: {bitbucket_commit: newCommit},
                  }),
                );
              }
            });
        } else if (modelHasValidBitbucketMetadata(initialModelState)) {
          //just clear bitbucket metadata
          bitbucketPromise = () =>
            modelUpdate(dispatch, updatedModel.uuid, {
              metadata: {
                ...initialModelState.metadata,
                bitbucket_project: '',
                bitbucket_repository: '',
                bitbucket_path: '',
                bitbucket_commit: '',
              },
            });
        }
      }

      const promises = [
        ...nodesToDelete,
        ...nodesToUpload,
        ...nodesToUpdate,
        ...nodesToUpdateFileTag,
        bitbucketPromise,
      ];

      if (promises.length > 0) {
        dispatch(modelFileSubmitStart());
        promises
          .reduce(
            (chain, currentTask) =>
              chain.then(() => {
                if (abortRef.current) {
                  return Promise.reject(new Error('Canceled'));
                } else {
                  return currentTask();
                }
              }),
            Promise.resolve(),
          )
          .catch((error: Error) => {
            messageAdd(error.message, MessageTypes.ERROR);
          })
          .finally(() => {
            trackModelItemsUpdated(model!, nodesToUpload.length, nodesToUpdate.length, nodesToDelete.length);
            dispatch(modelFileSubmitFinish());
            dispatch(
              modelFileUploaderMerge(
                createdModelUuid || model!.uuid,
                totalOperationsCount > nodesToDelete.length ||
                  (totalOperationsCount === 0 && uploader.nodes.length > 0),
              ),
            );
          });
        return promise;
      } else {
        promiseResolve.current = null;
        return Promise.resolve(false);
      }
    },
    abort,
  };
};
