/* eslint-disable @typescript-eslint/camelcase */
import React, {useCallback, useEffect, useRef} from 'react';
import {ModelDTO} from 'hemwb-api';
import {Form, TextFieldControl, useForm} from '../../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {tid} from '../../../../../../testUtils';
import MetadataTextFieldControl from '../ModelEditMetadata/MetadataTextFieldControl';
import {mapVersionFormValuesToModel, transformModelToVersionFormValues} from './utils';
import {ModelTreeNode} from '../../../../../../store/modelFile';
import {canBeChangeLogFile} from '../../../../../../store/modelFile/utils';
import {useFilePickerDialog} from '../../../../../core/FilePickerDialog/FilePickerDialog';
import {Grid, InputAdornment} from '@material-ui/core';
import GridItem from '../../../../../core/Form/GridItem';
import {FolderAdd16} from '@carbon/icons-react';
import FormInfoText from '../../../../../core/Form/FormInfoText';
import {Networking_03 as Networking03} from '@carbon/pictograms-react';
import {modifyModelMetadata, useModels, isModelExternal} from '../../../../../../store/model';
import {validateModelVersion} from '../../../../../../store/model/validator';

type ModelCardVersionFormProps = {
  model: ModelDTO;
  files: ModelTreeNode[];
  parentModel?: ModelDTO;
  onChange: (model: ModelDTO) => void;
  submitClicked: boolean;
};

const ModelCardVersionForm: React.FC<ModelCardVersionFormProps> = ({
  files,
  onChange,
  model,
  parentModel,
  submitClicked,
}) => {
  const formInstance = useRef<FormGroup>();
  const models = useModels();
  const externalModel = isModelExternal(model);
  const form = useForm(
    new FormGroup({
      version_release_of_model: new FormControl(null, [
        Validators.required,
        Validators.maxLength(7),
        ({value}) => validateModelVersion(modifyModelMetadata(model, {version_release_of_model: value}), models!),
      ]),
      version_log: new FormControl(null),
      changeLogFileName: new FormControl(null),
      changelog_file: new FormControl(null),
    }),
    transformModelToVersionFormValues(model, files),
  );

  const handleSelectedChangeLogFile = useCallback(
    (uuid) => {
      const node = files.find((n) => n.uuid === uuid);
      form.controls.changeLogFileName.setValue(node?.name);
      form.controls.changelog_file.setValue(node?.uuid);

      onChange(mapVersionFormValuesToModel(form.value, model));
    },
    [form, files, onChange, model],
  );

  const changeLogInputRef = useRef<HTMLInputElement>();
  const changeLogFiles = files.filter((node) => canBeChangeLogFile(node));
  const {open: openChangeLogDialog, DialogComponent: ChangeLogDialog} = useFilePickerDialog(
    handleSelectedChangeLogFile,
    changeLogFiles,
    form.controls.changelog_file.value,
    'Select Changelog document',
  );

  useEffect(() => {
    formInstance.current = form;
    if (externalModel) {
      form.disable();
    } else {
      form.enable();
    }
  }, [form, externalModel]);

  useEffect(() => {
    if (submitClicked) {
      Object.values(form.controls).forEach((control) => {
        control.markAsTouched();
      });
      form.enable();
    }
  }, [form, submitClicked]);

  useEffect(() => {
    form.controls.version_release_of_model.updateValueAndValidity();
  }, [form, parentModel]);

  const useHigherVersionInfoText = validateModelVersion(
    modifyModelMetadata(model, {version_release_of_model: '0'}),
    models!,
  );

  return (
    <>
      <h2>
        <Networking03 className="icon32" />
        Version Control
      </h2>
      <Form
        group={form}
        className=""
        {...tid('version-form')}
        onChange={() => onChange(mapVersionFormValuesToModel(form.value, model))}
        render={() => {
          return (
            <Grid container direction="row" alignItems="flex-start" justify="space-between">
              <GridItem>
                <MetadataTextFieldControl
                  name="version_release_of_model"
                  customErrorMessages={{format: 'Version must match number format like "1" or "1.1"'}}
                />
              </GridItem>
              {useHigherVersionInfoText ? (
                <FormInfoText
                  style={{width: '22rem'}}
                  text={`Enter a version number greater than the parent version number ${
                    useHigherVersionInfoText.min!.min
                  }`}
                />
              ) : (
                <FormInfoText style={{width: '22rem'}} text="Enter a version number of your choice" />
              )}
              <GridItem>
                <MetadataTextFieldControl name="version_log" />
              </GridItem>
              {parentModel && (
                <FormInfoText
                  style={{width: '22rem'}}
                  text="Enter a brief description of the differences between this model and its parent"
                />
              )}
              <GridItem>
                <div
                  // className={styles.inputFile}
                  onClick={() => {
                    changeLogInputRef.current?.blur();
                    changeLogFiles.length > 0 && openChangeLogDialog();
                  }}>
                  <TextFieldControl
                    TextFieldProps={{
                      inputRef: changeLogInputRef,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <FolderAdd16 />
                          </InputAdornment>
                        ),
                      },
                      inputProps: {
                        ...tid('input', 'mainFile'),
                      },
                    }}
                    disabled={changeLogFiles.length === 0}
                    name="changeLogFileName"
                    strict={false}
                    label="Changelog document"
                  />
                </div>
              </GridItem>
              {parentModel && (
                <FormInfoText
                  style={{width: '22rem'}}
                  text="Select an uploaded file that describes the detailed differences between this model and its parent"
                />
              )}
            </Grid>
          );
        }}
      />
      {ChangeLogDialog}
    </>
  );
};

export default ModelCardVersionForm;
