import React, {useCallback, useMemo} from 'react';
import {getUrlRoute, Routes, useRoute} from '../../../router/routes';
import Stepper, {StepStatus} from '../../../core/materialui/Stepper';
import {useHistory} from 'react-router';
import {useSelector} from '../../../../store/rootReducer';
import {visualizationSelector} from '../../../../store/visualization';
import {useScenarioDetail} from '../../../../store/scenario';
import {formatScenarioExecutionStatusValue} from '../../../../store/scenarioExecution/utils';
import {ScenarioStatus, ModelDTO} from 'hemwb-api';
import {
  trackModelDetailTabEvent,
  trackScenarioSelectionTabEvent,
  trackSettingParametersTabEvent,
  trackDataVisualizationTabEvent,
} from '../../../../tracking/tracking';
import {useModelDetail} from '../../../../store/model';

export enum VisualizationSteps {
  STEP_1,
  STEP_2,
  STEP_3,
}

type VisualizationStepperProps = {
  step: VisualizationSteps;
};

const VisualizationStepper: React.FC<VisualizationStepperProps> = ({step}) => {
  const history = useHistory();
  const {modelUuid, scenarioId: _scenarioId} = useRoute<Routes.VISUALIZATION_STEP_1>();
  const scenarioId = Number(_scenarioId);
  const {parameters, selection} = useSelector(visualizationSelector);
  const scenario = useScenarioDetail(scenarioId);
  const model = useModelDetail(modelUuid);

  const getScenarioIdParameter = useCallback(() => {
    return scenarioId ? {scenarioId} : {};
  }, [scenarioId]);

  const steps = useMemo(() => {
    const completedStep1 = selection?.scenarios.length;
    const disabledStep2 = !parameters || !completedStep1;
    const allowedStep3 = scenario && scenario.status !== ScenarioStatus.DRAFT;

    return [
      {
        title: 'Model Detail',
        status: StepStatus.COMPLETED,
        onClick: () => {
          trackModelDetailTabEvent(model as ModelDTO);
          history.push(getUrlRoute(Routes.MODEL_DETAIL, {uuid: modelUuid}));
        },
      },
      {
        title: 'Scenario selection',
        status: completedStep1 ? StepStatus.COMPLETED : StepStatus.DEFAULT,
        onClick: () => {
          trackScenarioSelectionTabEvent(model as ModelDTO);
          history.push(getUrlRoute(Routes.VISUALIZATION_STEP_1, {modelUuid, ...getScenarioIdParameter()}));
        },
      },
      {
        title: 'Setting Display Parameters',
        status:
          !disabledStep2 && parameters
            ? parameters.valid
              ? StepStatus.COMPLETED
              : StepStatus.ERROR
            : StepStatus.DEFAULT,
        disabled: disabledStep2,
        onClick: () => {
          trackSettingParametersTabEvent(model as ModelDTO);
          history.push(getUrlRoute(Routes.VISUALIZATION_STEP_2, {modelUuid, ...getScenarioIdParameter()}));
        },
      },
      {
        title: 'Dynamic data visualization',
        label: scenario ? formatScenarioExecutionStatusValue(scenario.status) : undefined,
        status: allowedStep3 ? StepStatus.COMPLETED : StepStatus.DEFAULT,
        disabled: !allowedStep3,
        onClick: () => {
          trackDataVisualizationTabEvent(model as ModelDTO);
          history.push(
            getUrlRoute(Routes.VISUALIZATION_STEP_3, {
              modelUuid,
              scenarioId: scenarioId || 0,
            }),
          );
        },
      },
    ];
  }, [history, scenarioId, getScenarioIdParameter, selection, scenario, modelUuid, model, parameters]);

  return (
    <div
      style={{maxWidth: 720, marginLeft: '4rem', marginTop: '2rem', minHeight: '5.5rem'}}
      className="visualization-stepper">
      <Stepper activeStep={step + 1} nonLinear steps={steps} variableWidths />
    </div>
  );
};

export default VisualizationStepper;
