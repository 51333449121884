import {ModelInputDetailDTO, ModelInputType} from 'hemwb-api';
import {scenarioFormGenerator} from './scenarioFormGenerator';
import {formatInput} from './formatInput';
import {associateFormValuesWithName} from './utils';

export const getScenarioDefaultParameters = (input: ModelInputDetailDTO): object => {
  if (input.type === ModelInputType.JSONTEMPLATE) {
    const definitions = JSON.parse(input.value)?.definitions || [];
    const form = scenarioFormGenerator(definitions, {}, true);
    return formatInput(definitions, associateFormValuesWithName(definitions, form.value.parameters));
  }

  return JSON.parse(input.value || '{}');
};
