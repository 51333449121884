import React, {useCallback, useState, useRef} from 'react';
import {useMounted} from '../../../../../hooks/useMounted';
import {
  scenarioCreate,
  scenarioNameExists,
  scenarioUpdateInputs,
  useScenarioDetail,
} from '../../../../../store/scenario';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useDispatch} from 'react-redux';
import {trackInstanceCloned} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {ScenarioFormValues} from '../ScenarioAdd/ScenarioForm';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import debounce from 'debounce-promise';
import GridItem from '../../../../core/Form/GridItem';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SCENARIO_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Copy16} from '@carbon/icons-react';

const ScenarioClone: React.FC<ScenarioActionProps & {sayCopyInsteadOfClone?: boolean}> = ({
  model,
  subModel,
  scenario,
  onSuccess,
  onCancel,
  sayCopyInsteadOfClone,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();
  const scenarioDetail = useScenarioDetail(scenario.id);
  const nameExistsCacheRef = useRef<any>({});

  const debouncedScenarioNameExists = debounce((name: string) => {
    if (!nameExistsCacheRef.current[name]) {
      nameExistsCacheRef.current[name] = scenarioNameExists(dispatch, {
        name,
        modelUuid: model.uuid,
        scenarioId: scenario?.id,
      });
    }
    return nameExistsCacheRef.current[name];
  }, 1000);
  const validateNameUnique = async (control: AbstractControl) => {
    const name = control.value;
    return (await debouncedScenarioNameExists(name)) ? {isUnique: true} : null;
  };

  const form = useForm(
    new FormGroup({
      name: new FormControl(scenario?.name, [Validators.required, Validators.maxLength(200)], validateNameUnique),
    }),
  );

  const handleSubmit = useCallback(
    async (values: ScenarioFormValues) => {
      setSubmitting(true);

      try {
        const createdScenario = await scenarioCreate(dispatch, scenarioDetail!.executionSetupId, {
          ...scenario,
          ...values,
          scenarioId: scenarioDetail!.scenarioId || undefined,
        });
        await scenarioUpdateInputs(dispatch, createdScenario.id, scenarioDetail!.input);
        trackInstanceCloned(model, subModel, scenario);
        dispatch(messageAdd(`Scenario ${sayCopyInsteadOfClone ? 'copied' : 'cloned'}`, MessageTypes.SUCCESS));
        onSuccess?.();
      } catch (e) {
      } finally {
        mounted.current && setSubmitting(false);
      }
    },
    [dispatch, mounted, model, subModel, onSuccess, scenario, scenarioDetail, sayCopyInsteadOfClone],
  );

  if (!scenarioDetail) {
    return <SuspenseNull />;
  }

  return (
    <>
      <h2>{sayCopyInsteadOfClone ? 'Copy' : 'Clone'} Scenario</h2>
      <Form
        FieldGroupProps={{strict: false}}
        style={{marginLeft: 0}}
        className=""
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <GridItem>
                <TextFieldControl label="Name" name="name" />
              </GridItem>

              <br />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} {...tid(SCENARIO_ACTION_MENU_IDS.CLONE, 'cancel')} />
                <SubmitButton
                  active={submitting}
                  {...tid(SCENARIO_ACTION_MENU_IDS.CLONE, 'submit')}
                  endIcon={<Copy16 />}>
                  {sayCopyInsteadOfClone ? 'Copy' : 'Clone'}
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default ScenarioClone;
