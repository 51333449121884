import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CardNodeColumn = ({children, className, farsideColumn}: any) => {
  return <div className={clsx('column', {farsideColumn: farsideColumn}, className)}>{children}</div>;
};

export {CardNodeColumn};

CardNodeColumn.propTypes = {
  /**
   * Pass in the children that will be rendered within the CardNodeColumn
   */
  children: PropTypes.node,

  /**
   * Provide an optional class to be applied on the outer element
   */
  className: PropTypes.string,

  /**
   * Specify whether this is the last column
   */
  farsideColumn: PropTypes.bool,
};
