import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {calculateParentId, getModelSteward, modelClone, modelPublish} from '../../../../../store/model';
import {useModelFileTreeLoader, useModelFileUploader} from '../../../../../store/modelFile';
import Stepper, {StepStatus} from '../../../../core/materialui/Stepper';
import StepFiles from './StepFiles';
import {PropertySelection} from './types';
import {Grid} from '@material-ui/core';
import {Copy32, Launch16} from '@carbon/icons-react';
import StepAccess from './StepAccess';
import StepMetadata from './StepMetadata';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../testUtils';
import {FormGroup} from 'react-reactive-form';
import {useMounted} from '../../../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {useHistory} from 'react-router';
import {styles} from './styles';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useNameCollisionCheckDialog} from '../ModelEdit/ModelEditSuffix/useNameCollisionCheckDialog';
import {ModelEditPermissionsFormData} from '../ModelEdit/ModelEditPermissons/ModelEditPermissionsForm';
import {modelLinksSetParent, useModelFlatHierarchy} from '../../../../../store/modelLinks';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {ModelActionProps} from '../../../actionMenuTypes';
import {modelAccessUpdateList, useModelAccess} from '../../../../../store/modelAccess';
import {modelStewardSet} from '../../../../../store/modelSteward';
import NotificationConfirmationDialog from '../ModelEdit/ModelEditFiles/NotificationConfirmationDialog';

import StepFileTypes from '../ModelCloneSideBarModal/StepFileTypes';

const steps = ['Metadata', 'Select Files', 'Add File Types', 'Access'];

const ModelCloneSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const history = useHistory();
  const {checkFutureName, DialogComponent: NameCollisionDialogComponent} = useNameCollisionCheckDialog();
  const flatHierarchy = useModelFlatHierarchy(model.uuid);

  const [activeStep, setActiveStep] = useState(0);
  const [metadataValid, setMetadataValid] = useState(false);
  const [accessValid, setAccessValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [openNotifyDialog, setOpenNotifyDialog] = useState<boolean>(false);
  const [clonedModelUuid, setClonedModelUuid] = useState<string>();
  const [newFiles, setNewFiles] = useState<string[]>([]);

  const {data: fileTree, load: loadFileTree} = useModelFileTreeLoader(model.uuid, ['Parameter'], undefined);

  const modelAccess = useModelAccess(model.uuid);

  const [filesSelection, setFilesSelection] = useState<PropertySelection>({});
  const [newModel, setNewModel] = useState<ModelDTO>(model);
  const {start: saveFiles} = useModelFileUploader(newModel, fileTree ?? undefined);

  const [permissions, setPermissions] = useState<
    Pick<
      ModelEditPermissionsFormData,
      'owners' | 'viewers' | 'displayViewers' | 'calibrationModelers' | 'scenarioModelers' | 'vestLead'
    >
  >({
    owners: [],
    viewers: [],
    displayViewers: [],
    calibrationModelers: [],
    scenarioModelers: [],
    vestLead: undefined,
  });

  useEffect(() => {
    loadFileTree();
  }, [loadFileTree]);

  useEffect(() => {
    if (modelAccess.loaded) {
      // @ts-ignore
      setPermissions(modelAccess);

      const steward = getModelSteward(model);
      const {vestLead} = modelAccess;
      setAccessValid(!!vestLead && !!steward);
    }
  }, [model, modelAccess]);

  useEffect(() => {
    if (fileTree) {
      setFilesSelection(
        fileTree.reduce((acc, val) => {
          if (!val.folder) {
            acc[val.uuid] = true;
          }
          return acc;
        }, ({} as any) as PropertySelection),
      );
    }
  }, [fileTree]);

  const handleNotifyCancel = useCallback(() => {
    setOpenNotifyDialog(false);
    onCancel?.();
    onSuccess?.();
    if (clonedModelUuid) {
      history.push(getUrlRoute(Routes.MODEL_DETAIL, {uuid: clonedModelUuid}));
      window.location.reload();
    }
  }, [onSuccess, onCancel, clonedModelUuid, history]);

  const formObjectRef = useRef<FormGroup>();

  const handleSubmit = useCallback(
    async (isPublished: boolean) => {
      setSubmitting(true);
      setDisableLoading(isPublished);
      const params = {
        modelItemUuids: Object.keys(filesSelection).reduce((acc, uuid) => {
          if (filesSelection[uuid]) {
            acc.push(uuid);
          }
          return acc;
        }, ([] as any) as string[]),
        metadata: newModel.metadata,
        ownersIsids: permissions.owners.map((u) => u.id),
        viewersIsids: permissions.viewers.map((u) => u.id),
        displayViewersIsids: permissions.displayViewers.map((u) => u.id),
      };

      // const handleSubmitFiles = useCallback(
      //     (updatedModel: ModelDTO) => {
      //       updatedModelRef.current = updatedModel;
      //       if (!list || list.length === 0) {
      //         handleSubmit();
      //         return;
      //       }
      //       setStep(STEP_SUBMODELS);
      //     },
      //     [list, handleSubmit],
      // );

      try {
        const newModelWithSuffix = await checkFutureName(newModel);
        const clonedModel = await modelClone(dispatch, model.uuid, {...params, metadata: newModelWithSuffix.metadata});
        setClonedModelUuid(clonedModel.uuid);
        await modelAccessUpdateList(dispatch, clonedModel.uuid, {
          owners: permissions.owners,
          vestLead: permissions.vestLead,
          calibratorModeler: permissions.calibrationModelers,
          scenarioModeler: permissions.scenarioModelers,
          viewers: permissions.viewers,
          displayViewers: permissions.displayViewers,
        });

        const modelSteward = getModelSteward(newModel);
        if (modelSteward) {
          await modelStewardSet(dispatch, clonedModel.uuid, modelSteward);
        }

        const parentId = calculateParentId(model, clonedModel, flatHierarchy);
        if (parentId) {
          await modelLinksSetParent(dispatch, clonedModel.uuid, parentId, true);
        }

        const {uuid} = clonedModel;

        try {
          await saveFiles({...newModel, uuid});
        } catch (e) {
          dispatch(messageAdd('Failed to save files', MessageTypes.WARNING));
        }

        if (isPublished) {
          await modelPublish(dispatch, clonedModel.uuid);
          dispatch(messageAdd(`New Model Version created and Published`, MessageTypes.SUCCESS));
        } else {
          dispatch(messageAdd(`New Model Version created and saved as Draft`, MessageTypes.SUCCESS));
        }

        setOpenNotifyDialog(true);
      } catch (e) {
      } finally {
        mounted.current && setSubmitting(false);
      }
    },
    [model, newModel, flatHierarchy, permissions, dispatch, filesSelection, mounted, checkFutureName, saveFiles],
  );
  const getStepStatus = useCallback(
    (step: number): StepStatus => {
      if (step === 0) {
        return metadataValid ? StepStatus.COMPLETED : StepStatus.ERROR;
      }
      if (step === 2) {
        return accessValid ? StepStatus.COMPLETED : StepStatus.ERROR;
      }

      return StepStatus.COMPLETED;
    },
    [accessValid, metadataValid],
  );

  useEffect(() => {
    if (newFiles !== null) {
      newFiles.forEach((uuid) => {
        setFilesSelection((prevFilesSelection) => ({
          ...prevFilesSelection,
          [uuid]: true,
        }));
      });
    }
  }, [newFiles]);

  return (
    <>
      {NameCollisionDialogComponent}
      <SuspenseNull>
        {modelAccess.loaded && !!fileTree && (
          <>
            <Grid container direction="row" alignItems="center" justify="space-between" style={{paddingLeft: '3rem'}}>
              <Grid item md={6}>
                <ActionHeader label="Add New Model Version" Icon={<Copy32 />}>
                  Review the metadata, files and
                  <br />
                  access that will be used for the
                  <br />
                  new model entry
                </ActionHeader>
              </Grid>
              <Grid item md={6}>
                <Stepper
                  steps={steps.map((title: string, index) => ({
                    title,
                    status: getStepStatus(index),
                    onClick: () => setActiveStep(index),
                  }))}
                  activeStep={activeStep}
                  nonLinear
                  className={styles.stepper}
                />
              </Grid>
            </Grid>
            <Grid container item direction="column" sm={12} style={{paddingLeft: '3rem'}}>
              {activeStep === 0 && formObjectRef && (
                <StepMetadata
                  model={newModel}
                  clonedFrom={model}
                  onStatusChange={setMetadataValid}
                  onValueChange={setNewModel}
                />
              )}

              {activeStep === 1 && fileTree && (
                <StepFiles
                  files={fileTree}
                  loadNewSelection={(modelItemUuids: string[]) => {
                    setNewFiles(modelItemUuids);
                    setActiveStep(0);
                    setActiveStep(1);
                  }}
                  model={newModel}
                  filesSelection={filesSelection}
                  toggleFile={(uuid) => setFilesSelection({...filesSelection, [uuid]: !filesSelection[uuid]})}
                />
              )}

              {activeStep === 2 && fileTree && (
                <StepFileTypes
                  model={newModel}
                  files={fileTree}
                  filesSelection={filesSelection}
                  toggleFile={(uuid) => setFilesSelection({...filesSelection, [uuid]: !filesSelection[uuid]})}
                  onSubmit={(newModel, newFiles) => {
                    setNewModel(newModel);
                    const fileUuids = newFiles.map((file) => file.uuid);
                    setNewFiles(fileUuids);
                  }}
                />
              )}

              {activeStep === 3 && modelAccess.loaded && (
                <StepAccess
                  permissions={{
                    ...permissions,
                    model: newModel,
                  }}
                  onChange={({model, ...newPermissions}) => {
                    setPermissions(newPermissions);
                    setNewModel(model);

                    const steward = getModelSteward(model);
                    const vestLead = newPermissions.vestLead;
                    setAccessValid(!!vestLead && !!steward);
                  }}
                />
              )}
            </Grid>
            <div className="buttonsContainer">
              <CancelButton onClick={onCancel} disabled={submitting} />
              {activeStep === 3 && modelAccess.loaded && (
                <>
                  <div style={{display: 'inline', padding: '1px 3px'}}>
                    <SubmitButton
                      onClick={() => handleSubmit(false)}
                      active={submitting}
                      disableLoading={disableLoading}
                      disabled={!metadataValid || !accessValid}
                      {...tid('btn-clone-draft')}>
                      Save as Draft
                    </SubmitButton>

                    <SubmitButton
                      onClick={() => handleSubmit(true)}
                      active={submitting}
                      disableLoading={!disableLoading}
                      disabled={!metadataValid || !accessValid}
                      endIcon={<Launch16 />}
                      {...tid('btn-clone-publish')}>
                      Save and Publish
                    </SubmitButton>
                  </div>
                </>
              )}

              {activeStep !== 3 && modelAccess.loaded && (
                <SubmitButton
                  onClick={() => setActiveStep(activeStep + 1)}
                  active={submitting}
                  disabled={!metadataValid || !accessValid}
                  {...tid('btn-clone-proceed')}>
                  Save & proceed
                </SubmitButton>
              )}
            </div>
          </>
        )}
      </SuspenseNull>
      <NotificationConfirmationDialog
        open={openNotifyDialog}
        title="Notify Model Users ?"
        text="A new version of the model has been added.Do you want to notify users for this change ? "
        description="An email notification will be sent to everyone who has permissions for this model entry."
        label="new model version"
        successMessage="Email notification of new model version sent to model users"
        uuid={clonedModelUuid}
        type="New Model Version"
        handleCancel={handleNotifyCancel}
      />
    </>
  );
};

export default ModelCloneSideBarModal;
