import React from 'react';
import SideBarButtonItem from '../../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../../testUtils';
import {ManageMetadataActionProps} from '../ActionMenuTypes';
import {MANAGE_METADATA_MENU_IDS} from '../../../../../../test/types';
import {Edit16} from '@carbon/icons-react';
import {useUpdateOptionSideBarModal} from './useUpdateOptionSideBarModal';
import {canManageMetadata} from '../../../../../../permissions/usePermissions';

const UpdateOptionActionMenuButton: React.FC<Partial<ManageMetadataActionProps>> = (props) => {
  const {ModalComponent, openModal} = useUpdateOptionSideBarModal(props);
  return (
    <>
      <SideBarButtonItem
        icon={<Edit16 />}
        text="Edit Option"
        permission={canManageMetadata(props.property)}
        disabled={!props.value || props.retired}
        onClick={openModal}
        {...tid(MANAGE_METADATA_MENU_IDS.EDIT)}
      />
      {ModalComponent}
    </>
  );
};

export default UpdateOptionActionMenuButton;
