import React from 'react';
import {useSharedSideBarToggle} from '../../../../../store/customSettings';
import SideBar from '../../../../layout/common/SideBar';
import {tid} from '../../../../../testUtils';
import {List} from '@material-ui/core';
import UpdateOptionActionMenuButton from './updateOption/UpdateOptionActionMenuButton';
import AddOptionActionMenuButton from './addOption/AddOptionActionMenuButton';
import DeleteOptionActionMenuButton from './deleteOption/DeleteOptionActionMenuButton';
import {ActionMenuProps} from './ActionMenuTypes';
import RetireOptionActionMenuButton from './retireOption/RetireOptionActionMenuButton';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '19rem',
};

const ActionMenu: React.FC<ActionMenuProps> = (props) => {
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);
  return (
    <SideBar position="right" {...sharedSidebar} {...tid('manage-metadata-list-action-sidebar')}>
      <List dense component="nav">
        <AddOptionActionMenuButton {...props} />
        <UpdateOptionActionMenuButton {...props} />
        <RetireOptionActionMenuButton {...props} />
        <DeleteOptionActionMenuButton {...props} />
      </List>
    </SideBar>
  );
};

export default ActionMenu;
