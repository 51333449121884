import React, {useCallback, useEffect, useRef} from 'react';
import {
  AutocompleteControl,
  Form,
  optionValidator,
  TextFieldControl,
  useForm,
  validateOption,
} from '../../../../../core/Form';
import {tid} from '../../../../../../testUtils';
import GridItem from '../../../../../core/Form/GridItem';
import {getCommonFieldProps, hardCodedOptions} from '../../../../model/common/ModelEdit/ModelEditMetadata/constants';
import {renderProductOptionItem} from '../../../../model/common/ModelEdit/ModelEditMetadata/renderProductOptionItem';
import {getModelLanguageText} from '../../../../../../store/model';
import CancelButton from '../../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import {Edit16, Add16} from '@carbon/icons-react';
import {
  ModelMetadata,
  useModelMetadataAssociations,
  useModelMetadataOptions,
  useModelMetadataOptionsLowerCase,
  modelMetadataOptionsLabels,
} from '../../../../../../store/modelMetadata';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {ActionMenuProps} from '../ActionMenuTypes';
import {ModelMetadataOptionsDTOOptions} from 'hemwb-api';

type UpdateOptionFormProps = {
  property: Required<ActionMenuProps>['property'];
  value?: string;
  submitting: boolean;
  onSubmit: (newValue: string, metadata?: ModelMetadata) => void;
  onCancel?: () => void;
};

const UpdateOptionForm: React.FC<UpdateOptionFormProps> = ({property, value = '', onSubmit, onCancel, submitting}) => {
  const optionsLowerCase = useModelMetadataOptionsLowerCase();
  const options = useModelMetadataOptions();
  const optionsRef = useRef<ModelMetadataOptionsDTOOptions | null>(null);
  const associations = useModelMetadataAssociations();
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

  const metadata =
    // @ts-ignore
    associations && associations[property] && associations[property][value] ? associations[property][value] : {};

  const validateUnique = useCallback(
    (control: AbstractControl) => {
      const newValueLower = control.value?.toLocaleLowerCase() || '';
      if (newValueLower !== value.toLocaleLowerCase() && optionsLowerCase![property]?.includes(newValueLower)) {
        return {isUnique: true};
      }
      return null;
    },
    [optionsLowerCase, property, value],
  );

  const metadataOptionValidator = useCallback(
    (optionsGetter: (metadata: ModelMetadataOptionsDTOOptions) => any[]) => (control: AbstractControl) =>
      optionsRef.current ? validateOption(control.value, optionsGetter(optionsRef.current)) : null,
    [optionsRef],
  );

  const form = useForm(
    new FormGroup({
      newValue: new FormControl(value, [Validators.required, validateUnique]),
      disease: new FormControl(metadata.disease || null, [metadataOptionValidator((opt) => opt.diseases)]),
      product: new FormControl(metadata.product?.split('|') || [], [metadataOptionValidator((opt) => opt.products)]),
      therapeutic_area: new FormControl(metadata.therapeutic_area || null, [
        metadataOptionValidator((opt) => opt.therapeuticAreas),
      ]),
      model_type: new FormControl(metadata.model_type || null, [metadataOptionValidator((opt) => opt.modelTypes)]),
      type: new FormControl(metadata.type || null, optionValidator(hardCodedOptions['type']!)),
      information_classification: new FormControl(
        metadata.information_classification || null,
        optionValidator(hardCodedOptions['information_classification']!),
      ),
    }),
  );

  useEffect(() => {
    form.enable();
  }, [form, options]);

  const handleSubmit = useCallback(() => {
    if (form.valid) {
      const {newValue, ...metadata} = form.value;
      metadata.product = metadata.product.join('|');
      let sendReducedMetadata = false;
      const reducedMetadata = Object.keys(metadata).reduce((acc, key) => {
        if (metadata[key]) {
          sendReducedMetadata = true;
          acc[key] = metadata[key];
        }
        return acc;
      }, ({} as any) as ModelMetadata);
      onSubmit(newValue, sendReducedMetadata ? reducedMetadata : undefined);
    }
  }, [onSubmit, form]);

  return (
    <Form
      className=""
      group={form}
      render={() => {
        return (
          <>
            {modelMetadataOptionsLabels[property] === 'File Types' ? (
              <TextFieldControl
                name="newValue"
                label="Option"
                TextFieldProps={{
                  autoComplete: 'off',
                  inputProps: {
                    ...tid('input', 'value'),
                    maxLength: 25,
                  },
                  onKeyDown: (event) => {
                    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  },
                }}
              />
            ) : (
              <TextFieldControl
                name="newValue"
                label="Option"
                TextFieldProps={{
                  autoComplete: 'off',
                  inputProps: {
                    ...tid('input', 'value'),
                  },
                }}
              />
            )}

            {property === 'studyNumbers' && (
              <div>
                <h2>Assigned metadata</h2>

                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('disease')}
                    required={false}
                    loading={options === null}
                    AutocompleteProps={{
                      getOptionLabel: (option: string) => option || '',
                      options: options?.diseases || [],
                    }}
                  />
                </GridItem>

                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('product')}
                    required={false}
                    loading={options === null}
                    AutocompleteProps={{
                      disableCloseOnSelect: true,
                      multiple: true,
                      renderOption: renderProductOptionItem,
                      getOptionLabel: (option: string) => option || '',
                      options: options?.products || [],
                    }}
                  />
                </GridItem>
                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('therapeutic_area')}
                    required={false}
                    loading={options === null}
                    AutocompleteProps={{
                      getOptionLabel: (option: string) => option || '',
                      options: options?.therapeuticAreas || [],
                    }}
                  />
                </GridItem>
                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('type')}
                    required={false}
                    AutocompleteProps={{
                      getOptionLabel: (option: string) => getModelLanguageText(option) || '',
                      options: hardCodedOptions['type'],
                    }}
                  />
                </GridItem>
                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('model_type')}
                    required={false}
                    loading={options === null}
                    AutocompleteProps={{
                      getOptionLabel: (option: string) => option || '',
                      options: options?.modelTypes || [],
                    }}
                  />
                </GridItem>
                <GridItem>
                  <AutocompleteControl
                    {...getCommonFieldProps('information_classification')}
                    required={false}
                    AutocompleteProps={{
                      getOptionLabel: (option: string) => option || '',
                      options: hardCodedOptions['information_classification'],
                    }}
                  />
                </GridItem>
              </div>
            )}

            <div className="buttonsContainer">
              <br />
              <br />
              <CancelButton onClick={onCancel} disabled={submitting} />
              {value ? (
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  endIcon={<Edit16 />}
                  {...tid('btn-manage-metadata-update')}>
                  Update Option
                </SubmitButton>
              ) : (
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  endIcon={<Add16 />}
                  {...tid('btn-manage-metadata-add')}>
                  Add Option
                </SubmitButton>
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default UpdateOptionForm;
