import React, {useCallback} from 'react';
import {Button} from '@material-ui/core';
import styles from './LoginHomePage.module.scss';
import clsx from 'clsx';
import IconLogo from '../../assets/icons/MR_logo.png';
import Logo from '../../assets/logo.png';
import AgreementDialog from './AgreementDialog';
import {getOrigin} from '../router/routes';

const LoginHomePage: React.FC = () => {
  const [homePage, setHomePage] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleEmployeeLogin = useCallback(() => {
    window.location.assign(getOrigin() + '/api/v2/auth/loginInternal');
  }, []);

  return (
    <>
      {homePage && (
        <div>
          <div>
            <div className={styles.wrapper}>
              <span className={clsx('noPrint', styles.header)}>
                <img src={IconLogo} alt="MR Logo" className={styles.logo} />
                <strong style={{color: 'black'}}> Model Repository </strong>
              </span>
              <div className={styles.content}>
                <div>
                  <img src={Logo} alt="Merck Logo" className={styles.mainLogo} />
                </div>
                <div style={{fontSize: '20px', marginTop: '2rem'}}>
                  <b>Welcome to Model Repository!</b>
                </div>
                <div style={{fontSize: '17px'}}>
                  <p>
                    Model Repository is Merck and MSD&apos;s central place to document, access, share,
                    <br />
                    and execute health economic models
                  </p>
                </div>
                <div style={{fontSize: '17px', marginTop: '7rem'}}>
                  <strong>Login with your existing account</strong>
                </div>
                <div style={{marginTop: '20px'}}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setHomePage(false);
                    }}
                    className={styles.button}>
                    <strong style={{color: 'white'}}>I&apos;m an Employee</strong>
                  </Button>
                </div>

                <div style={{marginTop: '20px'}}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    className={styles.button}>
                    <strong style={{color: 'white'}}>I&apos;m not an Employee</strong>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <AgreementDialog open={openDialog} title="Legal Agreement" handleCancel={handleCancel} LoginHomePage={true} />
        </div>
      )}
      {!homePage && handleEmployeeLogin()}
    </>
  );
};

export default LoginHomePage;
