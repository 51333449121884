import React, {CSSProperties, ReactNode} from 'react';
import styles from './FormInfoText.module.scss';
import {Information32} from '@carbon/icons-react';

type FormInfoTextProps = {
  text: string | ReactNode;
  style?: CSSProperties;
};

const FormInfoText: React.FC<FormInfoTextProps> = ({text, ...restProps}) => {
  return (
    <div className={styles.container} {...restProps}>
      <Information32 className={styles.icon} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default FormInfoText;
