import React from 'react';
import {ExecutionSetupDTO, ExecutionSetupType} from 'hemwb-api';
import {Chip20, Category20} from '@carbon/icons-react';

type SubModelIconProps = {
  subModel: ExecutionSetupDTO;
};

const SubModelIcon: React.FC<SubModelIconProps> = ({subModel}) => {
  switch (subModel.type) {
    case ExecutionSetupType.CALIBRATION:
      return <Chip20 />;
    case ExecutionSetupType.SCENARIO:
      return <Category20 />;
    case ExecutionSetupType.DISPLAY:
      return <Chip20 />;
  }

  return null;
};

export default SubModelIcon;
