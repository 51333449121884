import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import React, {useCallback, useMemo} from 'react';
import SideBarModal from '../../../../layout/common/SideBarModal';
import {IncidentActionProps} from '../IncidentActionMenuTypes';
import UpdateIncident from './UpdateIncident';

export const useUpdateIncidentSideBarModal = (props: Partial<IncidentActionProps>) => {
  const {onSuccess, status, incidentId, issueSummary, reportId, comments} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(status);

  const handleSuccess = useCallback(
    (selectNewValue, comments) => {
      closeModal();
      if (onSuccess) {
        onSuccess(selectNewValue, comments);
      }
    },
    [closeModal, onSuccess],
  );

  const ModalComponent = useMemo(() => {
    return (
      <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
        <div style={{width: '40rem', paddingLeft: '3rem'}}>
          {!!buttonRef && (
            <UpdateIncident
              status={status}
              onSuccess={handleSuccess}
              onCancel={closeModal}
              incidentId={incidentId}
              issueSummary={issueSummary}
              reportId={reportId}
              comments={comments}
            />
          )}
        </div>
      </SideBarModal>
    );
  }, [buttonRef, closeModal, handleSuccess, status, incidentId, issueSummary, reportId, comments]);

  return {
    openModal,
    ModalComponent,
  };
};
