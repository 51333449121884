import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import SubModelAdd from './SubModelAdd';
import {ModelSectionActionProps} from '../../../actionMenuTypes';

export const useSubModelAddSideBarModal = (props: Partial<ModelSectionActionProps>) => {
  const {model, section, onSuccess} = props;
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const ModalComponent = useMemo(() => {
    return (
      model &&
      section && (
        <SideBarModal className="whiteTheme" isOpen={!!buttonRef} buttonRef={buttonRef} closeModal={closeModal}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <SubModelAdd model={model} section={section} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, model, section, closeModal, handleSuccess]);

  return {
    openModal,
    ModalComponent,
  };
};
