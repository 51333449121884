import React, {useCallback, useMemo} from 'react';
import {useGridState} from '../../../../../store/grid';
import TableWithSortAndPagination, {Column} from '../../../../core/TableWithSortAndPagination';
import {modelMetadataOptionsLabels} from '../../../../../store/modelMetadata';
import {MetadataOptionDTO, ModelMetadataOptionsDTOOptions, MetadataCountDTO} from 'hemwb-api';
import {Search} from '../../../../core/Search/Search';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {ManageMetadataActionProps} from '../common/ActionMenuTypes';

type MetadataPropertyProps = {
  property: keyof ModelMetadataOptionsDTOOptions;
  onSelectChange: (selectedValues: Partial<ManageMetadataActionProps>) => void;
  selectedValues?: Partial<ManageMetadataActionProps>;
  usages: {[key: string]: ModelDTO[]} | null;
  options: ModelMetadataOptionsDTOOptions;
  fileTagCount: MetadataCountDTO[] | undefined;
};

const Grid: React.FC<MetadataPropertyProps> = ({
  selectedValues,
  options,
  property,
  onSelectChange,
  usages,
  fileTagCount,
}) => {
  const gridState = useGridState();

  // const [selectedValue, setSelectedValue] = useState<string>();
  const handleClickRow = (row: MetadataOptionDTO) => {
    if (selectedValues?.value === row.value) {
      onSelectChange({value: undefined, retired: false});
    } else {
      onSelectChange({value: row.value, retired: row.retired});
    }
  };

  const handleSearchChange = useCallback(
    (search: string) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setSearch(search);
    },
    [gridState],
  );

  const columns: Column<MetadataOptionDTO>[] = useMemo(() => {
    return [
      {id: property, label: modelMetadataOptionsLabels[property], value: (row) => row.value},
      {id: 'usage', label: 'Usage Count', value: (row) => (usages ? usages[row.value]?.length : 0)},
      {id: 'retired', label: 'Retired', value: (row) => row.retired},
    ];
  }, [property, usages]);

  const fileTagColumns: Column<MetadataCountDTO>[] = useMemo(() => {
    return [
      {id: property, label: modelMetadataOptionsLabels[property], value: (row) => row.value},
      {id: 'count', label: 'Usage Count', value: (row) => row.count},
      {id: 'retired', label: 'Retired', value: (row) => row.retired},
    ];
  }, [property]);

  const rows = useMemo(() => {
    if (gridState.search && options && options[property]) {
      const lcSearch = gridState.search.toLocaleLowerCase();
      return options[property].filter((o) => o.value.toLocaleLowerCase().includes(lcSearch));
    }

    return (options && options[property]) || [];
  }, [property, options, gridState.search]);

  const fileTagRows = useMemo(() => {
    if (gridState.search && fileTagCount) {
      const lcSearch = gridState.search.toLocaleLowerCase();
      return fileTagCount.filter((o) => o.value.toLocaleLowerCase().includes(lcSearch));
    }
    return fileTagCount || [];
  }, [fileTagCount, gridState.search]);

  return (
    <>
      <div className="listPanel">
        <div>
          <Search value={gridState.search} onValueChange={handleSearchChange} />
        </div>
      </div>
      {modelMetadataOptionsLabels[property] === 'File Types' ? (
        <TableWithSortAndPagination<MetadataCountDTO>
          columns={fileTagColumns}
          rows={fileTagRows}
          getRowId={(row) => row.value}
          onClickRow={handleClickRow}
          isRowSelected={(row) => row.value === selectedValues?.value}
          gridState={gridState}
        />
      ) : (
        <TableWithSortAndPagination<MetadataOptionDTO>
          columns={columns}
          rows={rows}
          getRowId={(row) => row.value}
          onClickRow={handleClickRow}
          isRowSelected={(row) => row.value === selectedValues?.value}
          gridState={gridState}
        />
      )}
    </>
  );
};

export default Grid;
