import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Collapse, List, ListItem, ListItemText} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Search} from '../../../core/Search/Search';
import {Checkbox} from '@material-ui/core';

type SectionListProps = {
  sections: any;
  elements: any;
  toggleSection: (sectionIndex: number) => void;
  sectionVisibility: boolean[];
};

const SectionList: React.FC<SectionListProps> = ({sections, elements, toggleSection, sectionVisibility}) => {
  const [searchText, setSearchText] = useState('');
  const filteredSections = useMemo(() => {
    return searchText
      ? elements?.filter((el: any) =>
          el.title
            ? el.title.toLowerCase().includes(searchText.toLowerCase())
            : el.options.title.toString().toLowerCase().includes(searchText.toLowerCase()),
        )
      : elements;
  }, [elements, searchText]);

  const [openSections, setOpenSections] = useState<string[]>([]);
  const toggleOpenSection = useCallback(
    (section: any) => {
      if (openSections.includes(section)) {
        setOpenSections(openSections.filter((item) => item !== section));
      } else {
        setOpenSections([...openSections, section]);
      }
    },
    [openSections],
  );

  useEffect(() => {
    if (sections?.length > 0) {
      setOpenSections([sections[0].title]);
    }
  }, [sections]);

  const handleElementClick = useCallback(
    (el: string) => {
      const index = elements?.findIndex((item: any) => item === el);
      return document.getElementById(`element-title-${index}`)?.focus();
    },
    [elements],
  );

  const renderOutput = (
    <>
      <ListItem component="div">
        <Search debounce={100} value={searchText} onValueChange={setSearchText} placeholder="Search" />
      </ListItem>

      {sections?.map((section: any, sectionIndex: any) => {
        const elementsInSection = elements.filter((el: any, index: number) =>
          filteredSections.includes(el)
            ? section.end
              ? index > section.start && index < section.end
              : index > section.start
            : null,
        );
        if (elementsInSection?.length === 0) {
          return null;
        }
        const sectionOpen = openSections.includes(section.title);
        return (
          <React.Fragment key={section.title}>
            <ListItem
              button
              onClick={elementsInSection.length === 0 ? undefined : () => toggleOpenSection(section.title)}>
              <ListItemText secondary={`${section.title} (${elementsInSection.length})`} />
              <Checkbox
                checked={sectionVisibility[sectionIndex]}
                style={{background: 'transparent'}}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSection(sectionIndex);
                }}
              />
              {sectionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <List dense>
              <Collapse in={sectionOpen} timeout="auto">
                {elementsInSection?.map((el: any) => {
                  const elementTitle = el.title ? el.title : el.options.title;
                  return (
                    <ListItem button key={elementTitle + sectionIndex} onClick={() => handleElementClick(el)}>
                      <ListItemText primary={elementTitle} />
                    </ListItem>
                  );
                })}
              </Collapse>
            </List>
          </React.Fragment>
        );
      })}
    </>
  );

  return renderOutput;
};

export default SectionList;
