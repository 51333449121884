import React from 'react';
import {DateInputAttributes, InputGenerator, InputType} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type ControlsMap = {[key in keyof Required<DateInputAttributes>]: AbstractControl};

type DateInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const DateInput: React.FC<DateInputProps> = ({formGroup}) => {
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <NumberInputPrimitive formGroup={formGroup} type={InputType.DATE} />
    </>
  );
};

export const dateInputGenerator: InputGenerator<InputType.DATE> = {
  type: InputType.DATE,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      ...numberInputPrimitiveControlBuilder(initialValues, InputType.DATE),
    });

    return fg;
  },
  render: DateInput,
};
