import {UserDTO, UserType, ExUserRegistrationDetails} from 'hemwb-api';
import {userFind} from './actions';

const userTypeMap = {
  [UserType.SUPERUSER]: 'Super User',
  [UserType.INTERNALUSER]: 'Internal User',
  [UserType.EXTERNALUSER]: 'External User',
};

export const userTypes = Object.keys(userTypeMap) as UserType[];
export const getUserTypeName = (userType: UserType) => userTypeMap[userType];

export const fullName = (user: Pick<UserDTO, 'lastName' | 'firstName' | 'id'>) =>
  user ? [user.lastName, user.firstName].filter((v) => !!v).join(', ') || user.id : '';

export const fullNameWestern = (user: Pick<UserDTO, 'lastName' | 'firstName' | 'id'>) =>
  user ? [user.firstName, user.lastName].filter((v) => !!v).join(' ') || user.id : '';

export const userFindValueReturned = (...params: Parameters<typeof userFind>) =>
  userFind(...params).then((response: any) => response.value as UserDTO[]);

export const externalUserStatusText = (user: ExUserRegistrationDetails) =>
  user
    ? user.status
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
