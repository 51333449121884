import {AsyncAction} from '../asyncUtilsTypes';
import {
  requestModelInputsList,
  requestModelInputsCreate,
  requestModelInputsDelete,
  requestModelInputsUpdate,
} from './api';
import {Await} from '../types';

export enum ModelInputsActions {
  LOAD = 'MODEL_INPUTS/LOAD',
  CREATE = 'MODEL_INPUTS/CREATE',
  UPDATE = 'MODEL_INPUTS/UPDATE',
  DELETE = 'MODEL_INPUTS/DELETE',
}

export type ModelInputAction =
  | AsyncAction<
      ModelInputsActions.LOAD,
      Await<ReturnType<typeof requestModelInputsList>>,
      Parameters<typeof requestModelInputsList>
    >
  | AsyncAction<
      ModelInputsActions.CREATE,
      Await<ReturnType<typeof requestModelInputsCreate>>,
      Parameters<typeof requestModelInputsCreate>
    >
  | AsyncAction<
      ModelInputsActions.UPDATE,
      Await<ReturnType<typeof requestModelInputsUpdate>>,
      Parameters<typeof requestModelInputsUpdate>
    >
  | AsyncAction<
      ModelInputsActions.DELETE,
      Await<ReturnType<typeof requestModelInputsDelete>>,
      Parameters<typeof requestModelInputsDelete>
    >;
