import {RootState, useSelector} from '../rootReducer';
import {modelLinksSelector} from './selectors';
import {modelLinksLoadHierarchy} from './actions';
import {useReduxLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {Dispatch} from 'redux';
import {ModelDTO} from 'hemwb-api';
import {useModels} from '../model/hooks';
import {modelSelector} from '../model/selectors';
import {useEffect, useMemo, useState} from 'react';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {useStore} from 'react-redux';
import {buildFlatHierarchy, matchLineageMetadata} from './utils';
import {ModelFlatNode} from './types';
import {isModelDTO, isModelPublished} from '../model';

const hierarchySelectorBuilder = (params: [string]) => (state: RootState) =>
  modelLinksSelector(state).hierarchy[params[0] || ''] || null;

const modelHierarchyIgnore = (dispatch: Dispatch, modelUuid: string | null) => {
  return modelUuid ? modelLinksLoadHierarchy(dispatch, modelUuid) : Promise.resolve();
};
modelHierarchyIgnore.clear = modelLinksLoadHierarchy.clear;

export const useModelHierarchy = (modelUuid: string) => {
  return useReduxLoader(hierarchySelectorBuilder, modelHierarchyIgnore, modelUuid);
};

export const useModelHierarchyLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(hierarchySelectorBuilder, modelLinksLoadHierarchy);
};

export const useModelFlatHierarchy = (uuid: string | undefined): ModelFlatNode[] | undefined => {
  const loadHierarchy = useModelHierarchyLoaderWithParams();
  const store = useStore<RootState>();
  const list = useSelector((state) => modelSelector(state).list);
  const hierarchy = useSelector((state) => modelLinksSelector(state).hierarchy[uuid || '']);
  const models = useSelector((state) => modelSelector(state).models);
  const [output, setOutput] = useState<ModelFlatNode[]>();
  const treeRootId = uuid ? models?.[uuid]?.metadata.tree_root_id : undefined;

  useEffect(() => {
    if (!treeRootId) {
      setOutput(undefined);
    } else if (hierarchy?.state === AsyncActionPhase.SUCCESS && models[treeRootId]) {
      // all data is fetched for sure
      setOutput(buildFlatHierarchy(models[treeRootId], Object.values(models)));
    } else if (list.state === AsyncActionPhase.SUCCESS && models[treeRootId]) {
      const flatHierarchy = buildFlatHierarchy(models[treeRootId], Object.values(models));
      if (Object.values(models).filter((m) => m.metadata.tree_root_id === treeRootId).length === flatHierarchy.length) {
        // there might be missing some restricted leaves in the hierarchy, but ignore theme
        setOutput(flatHierarchy);
      } else {
        loadHierarchy([uuid]);
      }
    } else {
      loadHierarchy([uuid]);
    }
  }, [treeRootId, store, hierarchy, list, models, loadHierarchy, uuid]);

  return output;
};

export const useModelHierarchyRootsByMetadata = (model: ModelDTO) => {
  const models = useModels();
  const loadHierarchy = useModelHierarchyLoaderWithParams();

  return useMemo(() => {
    if (models) {
      const list = Object.values(models);
      return list
        .filter((m) => isModelDTO(m) && isModelPublished(m) && matchLineageMetadata(m, model))
        .reduce((acc, m) => {
          if (m.metadata.tree_root_id && !acc.includes(m.metadata.tree_root_id)) {
            if (!models[m.metadata.tree_root_id]) {
              loadHierarchy([m.uuid]);
            }
            acc.push(m.metadata.tree_root_id);
          }
          return acc;
        }, ([] as any) as string[])
        .map((uuid) => {
          return models[uuid] || {uuid, metadata: {tree_root_id: uuid, tree_parent_id: ''}};
        });
    }
    return null;
  }, [models, model, loadHierarchy]);
};
