import React from 'react';
import {InputGenerator} from '../types';
import {FormControl} from 'react-reactive-form';
import {InputType, SingleSelectInputAttributes} from '../../InputDefinition/types';
import {selectInputPrimitiveControlBuilder, SelectItemPrimitive} from '../primitives/SelectItemPrimitive';
import InputHeader from './InputHeader';
import StaticInputSelectPrimitive from '../primitives/StaticInputSelectPrimitive';

type SingleSelectInputProps = {
  control: FormControl;
  definition: SingleSelectInputAttributes;
  disabled?: boolean;
};

export const SingleSelectInput: React.FC<SingleSelectInputProps> = ({control, definition, disabled}) => {
  return (
    <>
      <InputHeader definition={definition} />
      <SelectItemPrimitive control={control} definition={definition} disabled={disabled} />
    </>
  );
};

export const singleSelectInputGenerator: InputGenerator<InputType.SINGLE_SELECT> = {
  type: InputType.SINGLE_SELECT,
  buildControl: (definition, initialValues) => {
    return selectInputPrimitiveControlBuilder(definition, initialValues);
  },
  render: React.memo(SingleSelectInput),
  renderStatic: React.memo(StaticInputSelectPrimitive),
};
