import {GetModelInputsOrderByEnum, ModelInputDTO, ModelInputsApi, ModelInputType} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
import {sanitizeModelInputDetailDTO} from './utils';

const modelInputsApi = new ModelInputsApi(configuration);

const checkInputType = (modelInputDTO: ModelInputDTO) => {
  return modelInputDTO.type === ModelInputType.JSON ? JSON.stringify({}) : JSON.stringify({definitions: []});
};
export const requestModelInputsList = (executionSetupId: number) =>
  modelInputsApi
    .getModelInputs({
      pageSize: 1000,
      pageIndex: 0,
      orderBy: GetModelInputsOrderByEnum.ModifiedOn,
      orderDesc: true,
      executionSetupId,
    })
    .then((response) => {
      return {
        ...response,
        list: response.list ? response.list.map(sanitizeModelInputDetailDTO) : response.list,
      };
    });

export const requestModelInputsCreate = (executionSetupId: number, modelInputDTO: ModelInputDTO) =>
  modelInputsApi.createModelInput({
    executionSetupId,
    modelInputDTO: {
      ...modelInputDTO,
      name: 'default',
      value: modelInputDTO.value ? modelInputDTO.value : checkInputType(modelInputDTO),
    },
  });

export const requestModelInputsUpdate = (executionSetupId: number, id: number, modelInputDTO: ModelInputDTO) =>
  modelInputsApi.updateModelInput({id, modelInputDTO});

export const requestModelInputsDelete = (executionSetupId: number, id: number) => modelInputsApi.deleteModelInput({id});
