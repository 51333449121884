import React from 'react';
import WarningText from '../../../../core/WarningText/WarningText';
import {styles} from './styles';
import ModelEditPermissionsForm, {
  ModelEditPermissionsFormData,
} from '../ModelEdit/ModelEditPermissons/ModelEditPermissionsForm';
import {isModelWeb} from '../../../../../store/model';

type StepAccessProps = {
  permissions: ModelEditPermissionsFormData;
  onChange: (permissions: ModelEditPermissionsFormData) => void;
};

const StepAccess: React.FC<StepAccessProps> = ({permissions, onChange}) => {
  const model = permissions.model;
  const webModel = isModelWeb(model);
  return (
    <>
      <WarningText text="You can update these permissions in the new model entry anytime." />
      <br />
      <div className={styles.stepContainer}>
        <ModelEditPermissionsForm
          onSubmit={() => undefined}
          onChange={onChange}
          data={permissions}
          webModel={webModel}
          buttons={() => null}
        />
      </div>
    </>
  );
};

export default StepAccess;
