import React, {useCallback, useState} from 'react';
import {InputGenerator, InputType, SelectorColumnType, SelectorInputAttributes} from '../types';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from 'react-reactive-form';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import nanoid from 'nanoid';
import {Button, Grid, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import GridItem from '../../../Form/GridItem';
import IconButton from '@material-ui/core/IconButton';
import {TrashCan16} from '@carbon/icons-react';
import {Add} from '@material-ui/icons';
import {TextFieldControl} from '../../../Form';
import {SelectorColumnPrimitive} from '../primitives/SelectorColumnPrimitive';

type ControlsMap = {[key in keyof Required<SelectorInputAttributes>]: AbstractControl};

type SelectorInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const SelectorInput: React.FC<SelectorInputProps> = ({formGroup}) => {
  const rows = formGroup.get('rows') as FormArray;
  const columns = formGroup.get('columns') as FormArray;

  const [selectedColumn, setSelectedColumn] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();

  const handleAddColumn = useCallback(() => {
    const fg = generateColumnFormGroup({
      key: 'newKey',
      text: 'New Column Item',
      cellText: rows.controls.map(() => ''),
    });

    fg.meta = {
      key: nanoid(),
    };

    if (selectedColumn !== undefined) {
      columns.insert(selectedColumn, fg);
    } else {
      setSelectedColumn(columns.length);
      columns.insert(columns.length, fg);
    }
  }, [rows, columns, selectedColumn]);

  const handleRemoveColumn = useCallback(
    (index: number) => {
      columns.removeAt(index);
      if (selectedColumn !== undefined) {
        if (index === selectedColumn) {
          setSelectedColumn(undefined);
        } else if (selectedColumn > index) {
          setSelectedColumn(selectedColumn - 1);
        }
      }
    },
    [columns, selectedColumn],
  );

  const handleAddRow = useCallback(() => {
    const c = new FormControl('New Row Item', [Validators.required]);

    c.meta = {
      key: nanoid(),
    };

    if (selectedRow !== undefined) {
      rows.insert(selectedRow, c);
    } else {
      setSelectedRow(rows.length);
      rows.insert(rows.length, c);
    }

    (columns.controls as FormGroup[]).forEach((colControl) => {
      (colControl.get('cellText') as FormArray).insert(
        selectedRow ?? rows.length,
        new FormControl(null, Validators.required),
      );
    });
  }, [rows, columns, selectedRow]);

  const handleRemoveRow = useCallback(
    (index: number) => {
      rows.removeAt(index);
      if (selectedRow !== undefined) {
        if (index === selectedRow) {
          setSelectedRow(undefined);
        } else if (selectedRow > index) {
          setSelectedRow(selectedRow - 1);
        }
      }

      (columns.controls as FormGroup[]).forEach((colControl) => {
        (colControl.get('cellText') as FormArray).removeAt(index);
      });
    },
    [rows, columns, selectedRow],
  );

  return (
    <>
      <SharedInputParameters formGroup={formGroup} />

      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <InputLabel shrink>Columns</InputLabel>
          <div style={{background: 'white', height: 200, overflow: 'auto', marginTop: 5}}>
            <List dense>
              {(columns.controls as FormGroup[]).map((c, index) => {
                const textValue = c.value.text;
                const keyValue = c.value.key;
                return (
                  <ListItem
                    key={`${keyValue}${textValue}${c.meta.key}${index}`}
                    button
                    onClick={() => setSelectedColumn(index === selectedColumn ? undefined : index)}
                    selected={selectedColumn === index}>
                    <ListItemText
                      primary={textValue || 'EMPTY'}
                      primaryTypographyProps={{color: c.status === 'VALID' ? 'textPrimary' : 'error'}}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveColumn(index)}>
                        <TrashCan16 />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <br />
          <Button variant="outlined" onClick={handleAddColumn} endIcon={<Add />}>
            Add column
          </Button>
        </GridItem>
        <GridItem>
          <SelectorColumnPrimitive
            key={`${rows.length}${selectedColumn}${selectedRow}${
              columns.controls?.[selectedColumn || 0]?.value.dataType
            }${columns.controls?.[selectedColumn || 0]?.meta.key}`}
            columns={columns}
            selectedColumn={selectedColumn}
            selectedRow={selectedRow}
          />
        </GridItem>
        <GridItem>
          <InputLabel shrink>Rows</InputLabel>
          <div style={{background: 'white', height: 200, overflow: 'auto', marginTop: 5}}>
            <List dense>
              {rows.controls.map((c, index) => {
                const textValue = c.value;
                return (
                  <ListItem
                    key={`${textValue}${c.meta.key}${index}`}
                    button
                    onClick={() => setSelectedRow(index === selectedRow ? undefined : index)}
                    selected={selectedRow === index}>
                    <ListItemText
                      primary={textValue || 'EMPTY'}
                      primaryTypographyProps={{color: c.status === 'VALID' ? 'textPrimary' : 'error'}}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveRow(index)}>
                        <TrashCan16 />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <br />
          <Button variant="outlined" onClick={handleAddRow} endIcon={<Add />}>
            Add row
          </Button>
        </GridItem>
        <GridItem>
          {selectedRow !== undefined && (
            <TextFieldControl
              key={`${selectedRow}${rows.controls[selectedRow].meta.key}`}
              label="Text"
              control={rows.controls[selectedRow]}
              name="text"
            />
          )}
        </GridItem>
      </Grid>
    </>
  );
};

const generateColumnFormGroup = (column: SelectorColumnType) =>
  new FormGroup({
    key: new FormControl(column.key, Validators.required),
    text: new FormControl(column.text, Validators.required),
    cellText: new FormArray(column.cellText.map((cellText) => new FormControl(cellText, Validators.required))),
  });

export const selectorInputGenerator: InputGenerator<InputType.SELECTOR> = {
  type: InputType.SELECTOR,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      columns: new FormArray((initialValues.columns || []).map((column) => generateColumnFormGroup(column))),
      rows: new FormArray((initialValues.rows || []).map((r) => new FormControl(r, [Validators.required]))),
    } as ControlsMap);

    return fg;
  },
  render: SelectorInput,
};
