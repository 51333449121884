import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  ExecutionSetupDTO,
  ExecutionSetupType,
  ExecutionSetupUpdateDTO,
  ModelDTO,
  ModelInputDetailDTO,
  ModelInputDTO,
  ModelInputType,
  ModelItemBaseDTO,
} from 'hemwb-api';
import {calculateTags, canBeMainFile, nodeAddTag} from '../../../../../store/modelFile/utils';
import {modelFileSelector, ModelTreeNode} from '../../../../../store/modelFile';
import {isModelPublished} from '../../../../../store/model';
import {useSelector} from '../../../../../store/rootReducer';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {
  AutocompleteControl,
  DropDownControl,
  Form,
  getErrorText,
  getVisibleErrors,
  TextFieldControl,
  useForm,
  validateInteger,
} from '../../../../core/Form';
import {useFilePickerDialog} from '../../../../core/FilePickerDialog/FilePickerDialog';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {Button, Chip, FormControl as FormControlUI, FormHelperText, Grid, InputLabel} from '@material-ui/core';
import {tid} from '../../../../../testUtils';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import GridItem from '../../../../core/Form/GridItem';
import {environmentOptionsByLanguage, mapValuesToSubModel, transformSubModelToValues} from './utils';
import {SubModelSection} from '../../../../router/routes';
import {useDispatch} from 'react-redux';
import {useExecutionSetupListLoader} from '../../../../../store/executionSetup';
import RadioControl from '../../../../core/Form/RadioControl';
import {getModelInputTypeLabel} from '../../../modelInput/common/ModelInputAdd/utils';
import FileInputButton from '../../../../core/Buttons/FileInputButton';
import {parseModelInputFile} from '../../../modelInput/parseModelInputFile';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {Upload16} from '@carbon/icons-react';

type SubModelFormProps = {
  model: ModelDTO;
  section: SubModelSection;
  files: ModelTreeNode[];
  subModel?: ExecutionSetupDTO;
  input?: ModelInputDetailDTO;
  onSubmit?: (subModel: ExecutionSetupUpdateDTO, inputDefinition: ModelInputDetailDTO | ModelInputDTO) => void;
  onCancel?: () => void;
  submitting: boolean;
};

const SubModelForm: React.FC<SubModelFormProps> = ({
  model,
  section,
  files,
  subModel,
  input,
  submitting,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File>();

  const environmentOptions = environmentOptionsByLanguage[model.metadata.type];
  const config = useSelector((state) => modelFileSelector(state).config);
  const modelPublished = isModelPublished(model);
  const modelFiles = useMemo(() => {
    return files
      ?.map((node) => nodeAddTag(node, calculateTags(node, config, modelPublished, model?.metadata.type)))
      .filter((node) => canBeMainFile(node, model.metadata['type']));
  }, [config, files, model, modelPublished]);

  const {data, load: loadCalibrations} = useExecutionSetupListLoader(model.uuid, ExecutionSetupType.CALIBRATION);
  const calibrations = data?.list;
  const {data: data1, load: loadDisplays} = useExecutionSetupListLoader(model.uuid, ExecutionSetupType.DISPLAY);
  const displays = data1?.list;

  useEffect(() => {
    if (section === SubModelSection.SCENARIO) {
      loadCalibrations();
      loadDisplays();
    }
    if (section === SubModelSection.CALIBRATION) {
      loadDisplays();
    }
  }, [dispatch, model.uuid, section, loadCalibrations, loadDisplays]);

  const isExecutableLanguage = !!environmentOptions?.length;
  const hasExecutableFiles = !!modelFiles?.length;

  const canExecute = isExecutableLanguage && hasExecutableFiles;

  const formInstance = useRef<FormGroup>();

  const form = useForm(
    new FormGroup({
      name: new FormControl(null, Validators.required),
      // type: new FormControl(null, Validators.required),
      executionModels: new FormControl(null, Validators.required),
      calibration: new FormControl(null),
      display: new FormControl(null),
      environment: new FormControl(null, [Validators.required]),
      timeoutSeconds: new FormControl(null, [
        Validators.required,
        validateInteger,
        Validators.min(1),
        Validators.max(1440), //24 hours
      ]),

      inputType: new FormControl(null, Validators.required),
      outputPathDir: new FormControl(null, [Validators.required, Validators.pattern(/^[^/][/a-zA-Z0-9_-]+$/)]),
    }),
  );

  useEffect(() => {
    formInstance.current = form;
  }, [form]);

  useEffect(() => {
    subModel && form.setValue(transformSubModelToValues(subModel, input, calibrations, displays));
  }, [form, subModel, input, calibrations, displays]);

  const handleSelectedFile = useCallback(
    (uuids) => {
      const items = files.filter((f) => uuids.includes(f.uuid));
      form.controls.executionModels.setValue(items || []);
    },
    [form, files],
  );

  const handleRemoveFile = useCallback(
    (fi: ModelItemBaseDTO) => {
      form.controls.executionModels.setValue(
        (form.controls.executionModels.value || []).filter((f: ModelItemBaseDTO) => f !== fi),
      );
    },
    [form],
  );

  const {open: openMainFileDialog, DialogComponent: MainFileDialog} = useFilePickerDialog(
    handleSelectedFile,
    modelFiles,
    form.controls.executionModels.value?.map((f: ModelItemBaseDTO) => f.uuid) || [],
  );

  const handleSubmit = useCallback(async () => {
    const newInputDefinition = {name: '', value: '', ...input, type: form.value.inputType};
    try {
      newInputDefinition.value = await parseModelInputFile(newInputDefinition.type, file);
      onSubmit?.(mapValuesToSubModel(form.value), newInputDefinition);
    } catch (e) {
      dispatch(messageAdd(e.message, MessageTypes.ERROR));
    }
  }, [dispatch, form.value, onSubmit, input, file]);

  return (
    <>
      <Form
        FieldGroupProps={{strict: false}}
        style={{marginLeft: 0}}
        className=""
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          const executionModelsVisibleErrors = getVisibleErrors(form.controls.executionModels);
          const executionModelsHasErrors = Object.keys(executionModelsVisibleErrors).length > 0;

          return (
            <>
              <GridItem>
                <TextFieldControl
                  label="Name"
                  name="name"
                  TextFieldProps={{
                    inputProps: {
                      ...tid('input', 'name'),
                    },
                  }}
                />
              </GridItem>

              {/*<GridItem>*/}
              {/*  <DropDownControl*/}
              {/*    name="type"*/}
              {/*    strict={false}*/}
              {/*    label="Type"*/}
              {/*    options={Object.keys(ExecutionSetupType)}*/}
              {/*    getOptionLabel={getSubModelTypeLabel}*/}
              {/*  />*/}
              {/*</GridItem>*/}

              <GridItem>
                <div style={{marginBottom: -5}}>
                  <FormControlUI
                    fullWidth
                    error={executionModelsHasErrors}
                    hiddenLabel={true}
                    className="MuiFormControl-hem">
                    <InputLabel shrink required>
                      Execution Files
                    </InputLabel>

                    {executionModelsHasErrors && (
                      <FormHelperText style={{margin: '20px 0px 10px'}}>
                        {getErrorText(executionModelsVisibleErrors, 'Execution Files', true)}
                      </FormHelperText>
                    )}
                  </FormControlUI>
                </div>

                {(form.controls.executionModels.value || []).map((f: ModelItemBaseDTO) => (
                  <Chip
                    key={f.uuid}
                    label={f.name}
                    style={{marginTop: 10}}
                    onDelete={() => handleRemoveFile(f)}
                    variant="outlined"
                  />
                ))}

                <Button
                  disabled={!canExecute}
                  style={{marginLeft: -6}}
                  variant="text"
                  color="primary"
                  startIcon={<Upload16 />}
                  {...tid('btn', 'select-executable-files')}
                  onClick={() => {
                    canExecute && openMainFileDialog();
                  }}>
                  Select execution files
                </Button>
              </GridItem>

              <GridItem>
                <TextFieldControl
                  label="Output Directory Path "
                  name="outputPathDir"
                  customErrorMessages={{pattern: 'Path must be relative path'}}
                  TextFieldProps={{
                    inputProps: {
                      ...tid('input', 'outputPathDir'),
                    },
                  }}
                />
              </GridItem>

              {section === SubModelSection.SCENARIO && (
                <GridItem>
                  <AutocompleteControl
                    // disabled={!!subModel}
                    name="calibration"
                    label="Calibration Sub-model"
                    loading={!calibrations}
                    AutocompleteProps={{
                      options: calibrations || [],
                      getOptionLabel: (s: ExecutionSetupDTO) => s.name || '',
                      disableOpenOnFocus: true,
                    }}
                  />
                </GridItem>
              )}
              {(section === SubModelSection.SCENARIO || section === SubModelSection.CALIBRATION) && (
                <GridItem>
                  <AutocompleteControl
                    // disabled={!!subModel}
                    name="display"
                    label="Display Sub-model"
                    loading={!displays}
                    AutocompleteProps={{
                      options: displays || [],
                      getOptionLabel: (s: ExecutionSetupDTO) => s.name || '',
                      disableOpenOnFocus: true,
                    }}
                  />
                </GridItem>
              )}

              <GridItem>
                <DropDownControl name="environment" strict={false} label="Environment" options={environmentOptions} />
              </GridItem>
              <GridItem>
                <TextFieldControl
                  name="timeoutSeconds"
                  strict={false}
                  label="Execution timeout (in minutes)"
                  required
                  TextFieldProps={{
                    autoComplete: 'off',
                    inputProps: {
                      type: 'number',
                      ...tid('input', 'timeoutSeconds'),
                    },
                  }}
                />
              </GridItem>

              <h3>Input Definition</h3>
              <GridItem>
                <RadioControl
                  disabled={!!subModel}
                  name="inputType"
                  label="Definition Type"
                  options={[ModelInputType.JSON, ModelInputType.JSONTEMPLATE]}
                  getOptionLabel={getModelInputTypeLabel}
                />
              </GridItem>

              <span>Definition file (xml, json)</span>

              <Grid item>
                {file && (
                  <>
                    <Chip
                      label={file.name}
                      onDelete={() => setFile(undefined)}
                      variant="outlined"
                      style={{marginTop: 10}}
                    />
                    <br />
                  </>
                )}

                <FileInputButton label="Select import file" onChange={(f) => setFile(f[0])} accept=".json, .xml" />
              </Grid>

              <br />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.ADD, 'cancel')} />
                <SubmitButton active={submitting} {...tid(SUBMODEL_ACTION_MENU_IDS.ADD, 'submit')}>
                  Save
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
      {MainFileDialog}
    </>
  );
};

export default SubModelForm;
