import {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {UserDTO, ModelDTO} from 'hemwb-api';

export const useSideBarModal = (...dependencies: any[]) => {
  const [buttonRef, setButtonRef] = useState<Element>();
  const [user, setUser] = useState<UserDTO>();
  const [model, setModelId] = useState<ModelDTO>();

  useEffect(() => {
    setButtonRef(undefined);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  const openModal = useCallback((event: SyntheticEvent, userInfo?: any, models?: any) => {
    setButtonRef(event.currentTarget);
    setModelId(models);
    if (userInfo) {
      setUser(userInfo);
    }
  }, []);

  const closeModal = useCallback(() => setButtonRef(undefined), [setButtonRef]);

  return {
    //models,
    model,
    user,
    openModal,
    closeModal,
    buttonRef,
    isOpen: !!buttonRef,
  };
};
