import mainStyles from './Main.module.scss';
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Invention',
    fontSize: 22,
  },
  shape: {
    borderRadius: 0,
  },
};

export const defaultThemeConfig: ThemeOptions = {
  ...baseTheme,
  palette: {
    primary: {
      main: mainStyles.primary,
    },
    secondary: {
      main: mainStyles.secondary,
    },
  },
};
