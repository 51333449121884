import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from '../rootReducer';
import {appInitializedSelector} from '../app';
import {authSelector, authSetLogout, loadCurrentUser} from '../auth';
import {authExpirationSelector} from './selectors';
import {authExpirationSet} from './actions';

export const useAuthExpirationListener = () => {
  const dispatch = useDispatch();
  const appInitialized = useSelector(appInitializedSelector);
  const sessionTimeout = useSelector((state) => authExpirationSelector(state).sessionTimeout);
  const storedExpiration = useSelector((state) => authExpirationSelector(state).expiration);
  const {loggedInUser, loggedOutUser} = useSelector((state) => authSelector(state));

  const getParsedExpiration = useCallback(() => {
    const parsed = localStorage.getItem('authExpiration');
    return parsed ? Number.parseInt(parsed) : 0;
  }, []);

  const handleStorageEvent = useCallback(
    (e) => {
      if (e.key === 'authUserId') {
        if (loggedInUser) {
          if (loggedInUser.id !== e.newValue) {
            window.location.reload();
          }
        } else if (loggedOutUser) {
          if (loggedOutUser.id !== e.newValue) {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      } else if (e.key === 'authExpiration') {
        const next = Number.parseInt(e.newValue) || 0;
        if (next === -1) {
          dispatch(authSetLogout());
        }
        if (next > 0 && storedExpiration !== next && Math.abs(next - storedExpiration) > 1000) {
          dispatch(authExpirationSet(next));
        }
      }
    },
    [loggedInUser, loggedOutUser, storedExpiration, dispatch],
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);
    return () => window.removeEventListener('storage', handleStorageEvent);
  }, [handleStorageEvent]);

  //update userId in localStorage after successful login
  useEffect(() => {
    if (loggedInUser && loggedInUser.id !== localStorage.getItem('authUserId')) {
      localStorage.setItem('authUserId', loggedInUser.id || '');
    }
  }, [loggedInUser]);

  //update expiration after successful login or logout
  useEffect(() => {
    if (appInitialized) {
      if (!loggedOutUser && !loggedInUser) {
        localStorage.setItem('authExpiration', '-1');
      }
    }
  }, [loggedInUser, loggedOutUser, appInitialized, sessionTimeout]);

  //update expiration according to requests
  useEffect(() => {
    const parsedExpiration = getParsedExpiration();
    if (
      parsedExpiration !== storedExpiration &&
      storedExpiration > 0 &&
      Math.abs(parsedExpiration - storedExpiration) > 1000
    ) {
      localStorage.setItem('authExpiration', storedExpiration.toString());
    }
  }, [storedExpiration, getParsedExpiration]);

  //Logout user on session expiration
  useEffect(() => {
    if (storedExpiration > Date.now()) {
      if (!loggedInUser && storedExpiration > Date.now()) {
        loadCurrentUser(dispatch);
      }
      const timer = setTimeout(() => {
        dispatch(authSetLogout());
      }, storedExpiration - Date.now());
      return () => clearTimeout(timer);
    }
  }, [dispatch, storedExpiration, loggedInUser]);
};
