import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {versionControlDiffSelectionSelector} from '../../../../../store/versionControl';
import {bitbucketLoadDiff, modelHasValidBitbucketMetadata} from '../../../../../store/bitbucket';
import {DiffInput} from '../../../../core/Diff/GitDiff/types';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import GitDiff from '../../../../core/Diff/GitDiff/GitDiff';
import {ModelCard} from '../ModelCard/ModelCard';
import styles from './ModelVersionControlDiff.module.scss';
import {tid} from '../../../../../testUtils';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {ApiResponse, ModelDTO} from 'hemwb-api';
import {downloadFromResponse} from '../../../../../utils/download';
import {modelFileItemDownload, useModelFile} from '../../../../../store/modelFile';
import {trackModelItemDownloaded} from '../../../../../tracking/tracking';
import {Download16} from '@carbon/icons-react';
import {useModel} from '../../../../../store/model';
import ExcelDiff from '../../../../core/Diff/ExcelDiff/ExcelDiff';
import {useExcelDiffHead} from './useExcelDiffHead';
import {excelDiffClear} from '../../../../../store/excelDiff';

type ModelVersionControlDiffProps = {};

const ModelVersionControlDiff: React.FC<ModelVersionControlDiffProps> = () => {
  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);
  const [modelUuid1, modelUuid2] = versionDiffSelection;

  const model1Unsorted = useModel(modelUuid1);
  const model2Unsorted = useModel(modelUuid2);

  let model1 = model1Unsorted;
  let model2 = model2Unsorted;
  if (model1Unsorted && model2Unsorted && model1Unsorted.createdOn > model2Unsorted.createdOn) {
    model1 = model2Unsorted;
    model2 = model1Unsorted;
  }

  const {files: files1} = useModelFile(model1);
  const {files: files2} = useModelFile(model2);

  const changeLogFile1 = useMemo(() => {
    return files1?.find((n) => n.uuid === model1?.metadata.changelog_file);
  }, [files1, model1]);
  const changeLogFile2 = useMemo(() => {
    return files2?.find((n) => n.uuid === model2?.metadata.changelog_file);
  }, [files2, model2]);

  const processGitDiff =
    model1 && model2 && modelHasValidBitbucketMetadata(model1) && modelHasValidBitbucketMetadata(model2);
  const [gitDiff, setGitDiff] = useState<DiffInput>();
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState('');

  const {Component: ExcelHeadComponent, excelFile1, excelFile2} = useExcelDiffHead({model1, model2, files1, files2});

  useEffect(() => {
    if (processGitDiff) {
      bitbucketLoadDiff(dispatch, {
        projectName: model1!.metadata.bitbucket_project,
        repoName: model1!.metadata.bitbucket_repository,
        path: model1!.metadata.bitbucket_path,
        since: model1!.metadata.bitbucket_commit,
        until: model2!.metadata.bitbucket_commit,
      }).then((r) => {
        setGitDiff((r as any) as DiffInput);
      });
    }
  }, [processGitDiff, model1, model2, dispatch]);

  const cancelled = useRef(false);
  const downloadIfNotCancelled = useCallback(
    (response: ApiResponse<Blob>) => (cancelled.current ? Promise.reject() : downloadFromResponse(response.raw)),
    [],
  );

  const handleDownloadChangeLogFile = useCallback(
    (model: ModelDTO, itemName: string) => {
      setDownloading(model.metadata.changelog_file);
      return modelFileItemDownload(dispatch, model.uuid, model.metadata.changelog_file)
        .then(downloadIfNotCancelled)
        .then((res) => {
          setDownloading('');
          trackModelItemDownloaded(model, itemName, model.metadata.changelog_file);
          return res;
        });
    },
    [dispatch, downloadIfNotCancelled],
  );

  useEffect(() => {
    return () => {
      dispatch(excelDiffClear());
    };
  }, [dispatch]);

  if (!model1 || !model2) {
    return null;
  }

  if (!files1 || !files2) {
    return <SuspenseNull />;
  }

  return (
    <>
      <table className={styles.container}>
        <tbody>
          <tr>
            <td>
              <ModelCard model={model1} />
            </td>
            <td>
              <ModelCard model={model2} />
            </td>
          </tr>
          <tr>
            <td>
              <h2 className="headlineBlue">Model Version Log</h2>
            </td>
            <td>
              <h2 className="headlineBlue">Model Version Log</h2>
            </td>
          </tr>
          <tr>
            <td>{model1.metadata.version_log ? model1.metadata.version_log : 'No version log was captured.'}</td>
            <td>{model2.metadata.version_log ? model2.metadata.version_log : 'No version log was captured.'}</td>
          </tr>
          <tr>
            <td>
              <h2 className="headlineBlue">Changelog document</h2>
              <span>
                {model1.metadata.changelog_file
                  ? 'Stored in folder structure of this model entry'
                  : 'No changelog file was uploaded'}
              </span>
              {changeLogFile1 && (
                <>
                  <br />
                  <br />
                  <SubmitButton
                    onClick={() => handleDownloadChangeLogFile(model1!, changeLogFile1.name)}
                    disabled={!!downloading}
                    active={downloading === changeLogFile1.uuid}
                    endIcon={<Download16 />}
                    {...tid('btn-download-changelog', model1.uuid)}>
                    Download
                  </SubmitButton>
                </>
              )}
            </td>
            <td>
              <h2 className="headlineBlue">Changelog document</h2>
              <span>
                {model2.metadata.changelog_file
                  ? 'Stored in folder structure of this model entry'
                  : 'No changelog file was uploaded'}
              </span>
              {changeLogFile2 && (
                <>
                  <br />
                  <br />
                  <SubmitButton
                    onClick={() => handleDownloadChangeLogFile(model2!, changeLogFile2.name)}
                    disabled={!!downloading}
                    active={downloading === changeLogFile2.uuid}
                    endIcon={<Download16 />}
                    {...tid('btn-download-changelog', model2.uuid)}>
                    Download
                  </SubmitButton>
                </>
              )}
            </td>
          </tr>
          {ExcelHeadComponent}
        </tbody>
      </table>

      {processGitDiff && <SuspenseNull>{gitDiff && <GitDiff diff={gitDiff} />}</SuspenseNull>}

      {excelFile1 && excelFile2 && <ExcelDiff file1={excelFile1} file2={excelFile2} />}
    </>
  );
};

export default ModelVersionControlDiff;
