import React from 'react';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import GridItem from '../../../Form/GridItem';
import {TextFieldControl} from '../../../Form';
import {Grid} from '@material-ui/core';
import {StringInputAttributes} from '../../InputDefinition/types';

type StringInputPrimitiveProps = {
  formGroup: FormGroup;
};

export const StringInputPrimitive: React.FC<StringInputPrimitiveProps> = ({formGroup}) => {
  const {controls} = formGroup;

  return (
    <Grid container direction="row" alignItems="flex-start" justify="space-between">
      <GridItem>
        <TextFieldControl label="Value" control={controls.value} name="value" />
      </GridItem>
    </Grid>
  );
};

export const stringInputPrimitiveControlBuilder = (
  definition: Partial<StringInputAttributes>,
  initialValue?: string,
) => {
  const validators = [];
  if (definition.required) {
    validators.push(Validators.required);
  }
  if (definition.length) {
    validators.push(Validators.maxLength(definition.length));
  }

  return new FormControl(initialValue === undefined ? definition.defaultValue : initialValue, validators);
};
