import React, {useEffect, useRef} from 'react';
import ModelUploadPageHeader from '../common/ModelUploadPageHeader';
import {ModelTreeNode} from '../../../../store/modelFile';
import ModelEditMetadataForm from '../../model/common/ModelEdit/ModelEditMetadata/ModelEditMetadataForm';
import ModelUploadFooter from '../common/ModelUploadFooter';
import {ModelDTO} from 'hemwb-api';
import {FormGroup} from 'react-reactive-form';
import {mapValuesToModel} from '../../model/common/ModelEdit/ModelEditMetadata/utils';
import {Values} from '../../model/common/ModelEdit/ModelEditMetadata/constants';
import {trackMetaDataTabEvent} from '../../../../tracking/tracking';

type StepMetadataProps = {
  model: ModelDTO;
  files: ModelTreeNode[];
  onChange: (updatedModel: ModelDTO) => void;
  onSubmit: (updatedModel: ModelDTO) => void;
  showErrors: boolean;
};

const renderButtons = (renderParams: any) => {
  return <ModelUploadFooter {...renderParams} />;
};

const StepMetadata: React.FC<StepMetadataProps> = ({showErrors, files, model, onSubmit, onChange}) => {
  const formObjectRef = useRef<FormGroup>();

  useEffect(() => {
    trackMetaDataTabEvent();
    if (formObjectRef.current) {
      const onFormChange = (values: Values) => onChange(mapValuesToModel(values, model, false));
      formObjectRef.current.valueChanges.subscribe(onFormChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObjectRef]);

  return (
    <div>
      <ModelUploadPageHeader
        title="Fill in Metadata"
        subTitle="It helps us find the lineage and version, and generate model entry name for you"
        aside="For naming convention and version control consistency"
      />
      <div className="whiteTheme" style={{maxWidth: '80rem', margin: '0 auto'}}>
        <ModelEditMetadataForm
          formObjectRef={formObjectRef}
          files={files}
          model={model}
          onSubmit={onSubmit}
          validateOnInit={showErrors}
          buttons={renderButtons}
        />
      </div>
    </div>
  );
};

export default StepMetadata;
