import React, {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, useForm} from '../../../../core/Form';
import {FormControl, FormGroup} from 'react-reactive-form';
import {Package16, Package32} from '@carbon/icons-react';
import CheckboxControl from '../../../../core/Form/CheckboxControl';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../testUtils';
import {SideBarModalProps} from '../../../../layout/common/SideBarModal';
import {modelDownloadAll} from '../../../../../store/model';
import {useMounted} from '../../../../../hooks/useMounted';
import {ApiResponse} from 'hemwb-api';
import {downloadFromResponse} from '../../../../../utils/download';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';

type ModelDownloadAllSideBarModalProps = SideBarModalProps & {
  onSuccess?: () => void;
};

const ModelDownloadAllSideBarModal: React.FC<ModelDownloadAllSideBarModalProps> = ({closeModal, onSuccess}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const cancelled = useRef(false);

  const form = useForm(
    new FormGroup({
      includeHistory: new FormControl([]),
    }),
  );

  const downloadIfNotCancelled = useCallback(
    (response: ApiResponse<Blob>) => (cancelled.current ? Promise.reject() : downloadFromResponse(response.raw)),
    [],
  );

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    return modelDownloadAll(dispatch, form.controls['includeHistory'].value.length !== 0)
      .then(downloadIfNotCancelled)
      .then(() => {
        onSuccess?.();
        closeModal();
      })
      .catch(() => null)
      .finally(() => {
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, downloadIfNotCancelled, mounted, onSuccess, closeModal, form]);

  const handleClose = useCallback(() => {
    cancelled.current = true;
    closeModal();
  }, [cancelled, closeModal]);

  return (
    <>
      <ActionHeader label="Download All Entries" Icon={<Package32 />}>
        This operation can take a long time
      </ActionHeader>

      <Form
        className=""
        group={form}
        render={() => {
          return (
            <>
              <CheckboxControl
                name="includeHistory"
                layout="checkboxTop"
                options={['confirm']}
                getOptionLabel={() => 'Include Items history. (This will significantly increase downloading time.)'}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={handleClose} />
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  endIcon={<Package16 />}
                  {...tid('btn-download-all')}>
                  Download All Entries
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default ModelDownloadAllSideBarModal;
