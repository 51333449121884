import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './App.scss';
import Router from './router/Router';
import {authSelector, loadCurrentUser} from '../store/auth';
import {appDataLoadSettings, appDataSettingsSelector} from '../store/appData';
import {appInitializedSelector, appZoomSelector} from '../store/app';
import EmptyPageWithSpinner from './pages/empty/EmptyPageWithSpinner';
import {Dialog} from '@material-ui/core';
import {LoginPage} from './auth/LoginPage';
import {useAuthExpirationListener} from '../store/authExpiration';
import SessionWillExpire from './auth/SessionWillExpire';
import {useTracking} from '../tracking/tracking';
import {useDocumentTitle} from '../hooks/useDocumentTitle';
import {useLocation} from 'react-router';
import {ErrorRouter} from './pages/error/ErrorRouter';
//import {LoggedOutPage} from './auth/LoggedOutPage';
import LoginHomePage from './auth/LoginHomePage';
import {useUserScenarioExecutionStatusUpdate} from '../websocket/useUserScenarioExecutionStatusUpdate';
import {useCustomSettingsLocalStorage} from '../store/customSettings';
import {usePermissions} from '../permissions/usePermissions';

const App: React.FC = () => {
  const zoom = useSelector(appZoomSelector);
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.style.fontSize = `${10 * zoom}px`;
    }
  }, [zoom]);

  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const isError = pathname.startsWith('/error');
  useEffect(() => {
    if (!isError) {
      loadCurrentUser(dispatch);
      appDataLoadSettings(dispatch);
    }
  }, [dispatch, isError]);

  useDocumentTitle();
  useTracking();
  usePermissions();
  useAuthExpirationListener();
  useUserScenarioExecutionStatusUpdate();
  useCustomSettingsLocalStorage();

  const auth = useSelector(authSelector);
  const appInitialized = useSelector(appInitializedSelector);
  const settings = useSelector(appDataSettingsSelector);

  if (isError) {
    return <ErrorRouter />;
  }
  if (!appInitialized) {
    return <EmptyPageWithSpinner />;
  }

  return (
    <>
      {!auth.loggedInUser && (
        <Dialog fullScreen open={true}>
          {settings?.ssoEnabled ? <LoginHomePage /> : <LoginPage />}
        </Dialog>
      )}

      {(auth.loggedInUser || auth.loggedOutUser) && <Router />}
      {auth.loggedInUser && <SessionWillExpire />}
    </>
  );
};

export default App;
