import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {Grid} from '@material-ui/core';
import styles from './ModelOverview.module.scss';
import ModelRequestAccessButton from '../ModelRequestAccess/ModelRequestAccessButton';
import {canViewModelFiles} from '../../../../../permissions/usePermissions';
import {isModelRetired} from '../../../../../store/model';

type ModelOverviewRequestAccessProps = {
  model: ModelDTO;
  fromDynamicSearch?: boolean;
};

const ModelOverviewRequestAccess: React.FC<ModelOverviewRequestAccessProps> = ({model, fromDynamicSearch}) => {
  const requestAccess = !canViewModelFiles(model);

  if (!requestAccess || isModelRetired(model)) {
    return null;
  }

  return (
    <Grid container justify="space-between" alignItems="center">
      {!fromDynamicSearch && (
        <div className={styles.requestAccessText}>
          You can only preview general information of this model.
          <br />
          To view further details or download this model, please request access
        </div>
      )}

      <div>
        <ModelRequestAccessButton fromDynamicSearch={fromDynamicSearch} model={model} />
      </div>
    </Grid>
  );
};

export default ModelOverviewRequestAccess;
