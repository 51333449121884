import React, {useCallback, useEffect, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from 'react-reactive-form';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {useDispatch} from 'react-redux';
import {tid} from '../../../../../testUtils';
import RadioControl from '../../../../core/Form/RadioControl';
import {requestSendfileAccessRequested} from '../../../../../store/fileRequestAccess/api';
import {trackFileAccessRequested} from '../../../../../tracking/tracking';
import {useMounted} from '../../../../../hooks/useMounted';

type ModelFileRequestAccessDialogProps = {
  model: ModelDTO;
  open: boolean;
  fileUuid: string;
  fileName: string;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const reasonOptions = [
  'To develop a local model and/or materials for external use (e.g., HTA submissions)',
  'To create materials for internal use (e.g., training resources)',
  'To inform a strategic policy, resource, or commercial decision',
  'Other',
];

const reasonTextValidator: ValidatorFn = (control: AbstractControl) => {
  return !control.value && control.parent && control.parent.get('reason').value === 'Other' ? {required: true} : null;
};

const ModelFileRequestAccessDialog: React.FC<ModelFileRequestAccessDialogProps> = ({
  model,
  open,
  onSuccess,
  onCancel,
  fileUuid,
  fileName,
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMounted();

  const form = useForm(
    new FormGroup({
      reason: new FormControl(null, Validators.required),
      reasonText: new FormControl(null, [reasonTextValidator]),
    }),
  );

  useEffect(() => {
    const updateReasonTextValidity = () => form.get('reasonText').updateValueAndValidity();
    form.get('reason').valueChanges.subscribe(updateReasonTextValidity);
    return () => {
      form.get('reason').valueChanges.unsubscribe(updateReasonTextValidity);
    };
  }, [form]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
    form.reset();
  }, [onCancel, form]);

  const handleSubmit = useCallback(() => {
    if (!form.valid) {
      return;
    }

    setSubmitting(true);
    const value = form.value;
    const reason = [value.reason, value.reasonText].filter(Boolean).join('<br />');
    requestSendfileAccessRequested(model.uuid, fileUuid, reason, fileName)
      .then(() => {
        trackFileAccessRequested(model, fileUuid, value.reason, value.reasonText, fileName);
        dispatch(
          messageAdd(
            'Model File access request email has been sent. You will get an email notification once the file access is granted',
            MessageTypes.SUCCESS,
          ),
        );
        onSuccess?.();
      })
      .catch(() => {
        dispatch(messageAdd('Failed to request access', MessageTypes.ERROR));
      })
      .finally(() => {
        mounted.current && setSubmitting(false);
        form.reset();
      });
  }, [dispatch, form, mounted, onSuccess, fileName, fileUuid, model]);

  return (
    <DialogGeneric
      open={open}
      title="Request File Access"
      PaperProps={tid('dialog-request-file-access') as {}}
      childrenAsText={false}>
      <Form
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <RadioControl label="Reason" name="reason" options={reasonOptions} />
              <TextFieldControl
                label="Please share more details about your request."
                name="reasonText"
                customErrorMessages={{required: 'This field is required.'}}
                TextFieldProps={{multiline: true, rows: 3}}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={handleCancel} {...tid('dialog-request-file-access', 'cancel')} />
                <SubmitButton active={submitting} {...tid('dialog-request-file-access', 'submit')}>
                  Request Access
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </DialogGeneric>
  );
};

export default ModelFileRequestAccessDialog;
