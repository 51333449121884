import React from 'react';
import {InputType, StringInputAttributes} from '../../InputDefinition/types';
import {FormControl} from 'react-reactive-form';
import GridItem from '../../../Form/GridItem';
import {TextFieldControl} from '../../../Form';
import {Grid} from '@material-ui/core';
import {InputGenerator} from '../types';
import InputHeader from './InputHeader';
import {stringInputPrimitiveControlBuilder} from '../primitives/StringInputPrimitive';
import StaticInputValuePrimitive from '../primitives/StaticInputValuePrimitive';

type StringInputProps = {
  control: FormControl;
  definition: StringInputAttributes;
  disabled?: boolean;
};

const StringInput: React.FC<StringInputProps> = ({control, definition, disabled}) => {
  return (
    <>
      <InputHeader definition={definition} />
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <TextFieldControl strict={false} label="Value" control={control} name="value" disabled={disabled} />
        </GridItem>
      </Grid>
    </>
  );
};

export const stringInputGenerator: InputGenerator<InputType.STRING> = {
  type: InputType.STRING,
  buildControl: (definition, initialValues) => {
    return stringInputPrimitiveControlBuilder(definition, initialValues);
  },
  render: StringInput,
  renderStatic: StaticInputValuePrimitive,
};
