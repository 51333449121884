import React from 'react';
import {InputGenerator, InputType, IntegerInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type ControlsMap = {[key in keyof Required<IntegerInputAttributes>]: AbstractControl};

type IntegerInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const IntegerInput: React.FC<IntegerInputProps> = ({formGroup}) => {
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <NumberInputPrimitive formGroup={formGroup} type={InputType.INTEGER} />
    </>
  );
};

export const integerInputGenerator: InputGenerator<InputType.INTEGER> = {
  type: InputType.INTEGER,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      ...numberInputPrimitiveControlBuilder(initialValues, InputType.INTEGER),
    });

    return fg;
  },
  render: IntegerInput,
};
