import {ExecutionSetupAction, ExecutionSetupActions} from './types';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestExecutionSetup, requestExecutionSetupList} from './api';
import {reduceAsyncAction} from '../asyncUtils';
import {buildKey} from './utils';

export type ExecutionSetupState = {
  list: {
    [modelUuid: string]: AsyncActionState<
      Await<ReturnType<typeof requestExecutionSetupList>>,
      Parameters<typeof requestExecutionSetupList>
    >;
  };
  single: {
    [id: number]: AsyncActionState<
      Await<ReturnType<typeof requestExecutionSetup>>,
      Parameters<typeof requestExecutionSetup>
    >;
  };
};
export const executionSetupInitialState: ExecutionSetupState = {
  list: {},
  single: {},
};

export const executionSetupReducer = (
  state = executionSetupInitialState,
  action: ExecutionSetupAction,
): ExecutionSetupState => {
  switch (action.type) {
    case ExecutionSetupActions.REQUEST_SINGLE: {
      const id = action.params[0];
      return {
        ...state,
        single: {
          ...state.single,
          [id]: reduceAsyncAction(action),
        },
      };
    }

    case ExecutionSetupActions.REQUEST_LIST: {
      const key = buildKey(action.params[0], action.params[1]);
      return {
        ...state,
        list: {
          ...state.list,
          [key]: reduceAsyncAction(action),
        },
      };
    }

    case ExecutionSetupActions.REQUEST_CREATE: {
      if (action.phase !== AsyncActionPhase.SUCCESS) {
        return state;
      }
      const key = buildKey(action.params[0], action.params[1].type);
      return {
        ...state,
        list: {
          ...state.list,
          [key]: {
            ...state.list[key],
            value: {
              total: (state.list[key].value?.total || 0) + 1,
              list: [...(state.list[key].value?.list || []), action.value],
            },
          },
        },
      };
    }

    case ExecutionSetupActions.REQUEST_UPDATE: {
      if (action.phase !== AsyncActionPhase.SUCCESS) {
        return state;
      }
      const [id] = action.params;
      const key = findKeyById(id, state);

      const singleCopy = {
        ...state.single,
      };
      delete singleCopy[id];

      let listCopy = state.list;
      if (key) {
        listCopy = {
          ...state.list,
        };
        delete listCopy[key];
      }

      return {...state, list: listCopy, single: singleCopy};
    }

    case ExecutionSetupActions.REQUEST_DELETE: {
      if (action.phase !== AsyncActionPhase.SUCCESS) {
        return state;
      }
      const [id] = action.params;
      const key = findKeyById(id, state);

      const singleCopy = {
        ...state.single,
      };
      delete singleCopy[id];

      let listCopy = state.list;
      if (key) {
        listCopy = {
          ...state.list,
          [key]: {
            ...state.list[key],
            value: {
              total: (state.list[key].value?.total || 0) - 1,
              list: state.list[key].value?.list?.filter((es) => es.id !== id),
            },
          },
        };
      }

      return {
        ...state,
        list: listCopy,
        single: singleCopy,
      };
    }
  }
  return state;
};

export const findKeyById = (id: number, state: ExecutionSetupState): string | null => {
  let key: string | null = null;

  Object.keys(state.list).forEach((k) => {
    if (!key && state.list[k].value?.list?.some((es) => es.id === id)) {
      key = k;
    }
  });

  return key;
};
