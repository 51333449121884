import React from 'react';
import CardNode, {CardNodeColumn, CardNodeSubtitle} from '../../../core/carbonui/CardNode';
import {ExecutionSetupDTO, ExecutionSetupType} from 'hemwb-api';
import {getSubModelColorHashCode} from './constants';
import SubModelIcon from './SubModelIcon';
import {Radio} from '@material-ui/core';

type SubModelCardProps = {
  subModel: ExecutionSetupDTO;
  isSelected: boolean;
  onClick: () => void;
};

const SubModelCard = React.forwardRef<HTMLDivElement, SubModelCardProps>(({subModel, isSelected, onClick}, ref) => {
  return (
    <div className="cardNode treeNode" ref={ref} onClick={onClick}>
      <CardNode
        color={getSubModelColorHashCode(subModel)}
        theme="whiteTheme"
        fullBorder={subModel.type !== ExecutionSetupType.DISPLAY && isSelected}>
        <CardNodeColumn>
          <SubModelIcon subModel={subModel} />
        </CardNodeColumn>
        <CardNodeColumn>
          <CardNodeSubtitle>{subModel.name}</CardNodeSubtitle>
        </CardNodeColumn>
        {subModel.type === ExecutionSetupType.DISPLAY && (
          <CardNodeColumn farsideColumn>
            <Radio checked={isSelected} size="small" color="secondary" style={{padding: 0}} />
          </CardNodeColumn>
        )}
      </CardNode>
    </div>
  );
});

export default SubModelCard;

SubModelCard.displayName = 'SubModelCard';
