import React, {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {InputAttributes, InputType} from '../InputDefinition/types';
import {Collapse, List, ListItem, ListItemText} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {createPortal} from 'react-dom';
import {Search} from '../../Search/Search';
import {getInputDefinitionCategories} from '../InputDefinition/utils';
import {ExecutionSetupType} from 'hemwb-api';

type InputsStatus = {[id: number]: boolean};
type InputsControlStatus = {[id: number]: string | null};

type InputListProps = {
  subModelType: ExecutionSetupType;
  definitions: InputAttributes[];
  inputsStatus: InputsStatus;
  inputsControlStatus: InputsControlStatus;
  onInputClick: (inputId: number) => void;
  inputListPortalRef?: RefObject<HTMLDivElement>;
  expertiseLevelValue: number;
};

const InputList: React.FC<InputListProps> = ({
  subModelType,
  definitions,
  inputsStatus,
  inputsControlStatus,
  onInputClick,
  inputListPortalRef,
  expertiseLevelValue,
}) => {
  const inputCategories = getInputDefinitionCategories(subModelType);
  const [searchText, setSearchText] = useState('');
  const filteredDefinitions = useMemo(() => {
    const visibleDefinitions = definitions.filter((definition) => definition.visible);

    return searchText
      ? visibleDefinitions.filter((definition) => definition.title.toLowerCase().includes(searchText.toLowerCase()))
      : visibleDefinitions;
  }, [definitions, searchText]);

  const [openCategories, setOpenCategories] = useState<string[]>([]);
  const toggleOpenCategory = useCallback(
    (categoryId: string) => {
      if (openCategories.includes(categoryId)) {
        setOpenCategories(openCategories.filter((cId) => cId !== categoryId));
      } else {
        setOpenCategories([...openCategories, categoryId]);
      }
    },
    [openCategories],
  );

  useEffect(() => {
    if (definitions.length > 0) {
      setOpenCategories([definitions[0].categoryId]);
    }
  }, [definitions]);

  const renderOutput = (
    <>
      <ListItem component="div">
        <Search debounce={100} value={searchText} onValueChange={setSearchText} placeholder="Search" />
      </ListItem>

      {Object.keys(inputCategories).map((categoryId) => {
        const definitionsInCategory = filteredDefinitions.filter((d) => {
          const parameterLevel = d.expertiseLevel ? d.expertiseLevel.split('-')[0] : 500;
          return (
            d.categoryId === categoryId &&
            inputsControlStatus?.[d.id] !== 'HS' &&
            expertiseLevelValue >= Number(parameterLevel)
          );
        });
        if (definitionsInCategory.length === 0) {
          return null;
        }

        const categoryOpen = openCategories.includes(categoryId);

        const colorProperty = (input: any) => {
          return inputsStatus?.[input.id] ? 'textPrimary' : 'error';
        };

        return (
          <React.Fragment key={categoryId}>
            <ListItem
              button
              onClick={definitionsInCategory.length === 0 ? undefined : () => toggleOpenCategory(categoryId)}>
              <ListItemText secondary={`${inputCategories[categoryId]} (${definitionsInCategory.length})`} />
              {categoryOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <List dense>
              <Collapse in={categoryOpen} timeout="auto">
                {definitionsInCategory.map((input) => {
                  return (
                    <ListItem key={input.id} button onClick={() => onInputClick(input.id)}>
                      <ListItemText
                        primary={input.title}
                        primaryTypographyProps={{
                          color: input.type === InputType.LABEL ? 'textSecondary' : colorProperty(input),
                        }}
                      />
                    </ListItem>
                  );
                })}
              </Collapse>
            </List>
          </React.Fragment>
        );
      })}
    </>
  );

  if (inputListPortalRef && inputListPortalRef.current) {
    return createPortal(renderOutput, inputListPortalRef.current);
  }

  return renderOutput;
};

export default InputList;
