import React, {useCallback} from 'react';
import {ExecutionSetupDTO, ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import {DocumentPdf16} from '@carbon/icons-react';
import {VISUALIZATION_ACTION_MENU_IDS} from '../../../../../test/types';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {usePdfExport} from './usePdfExport';
import {VisualizationResults} from '../../../../../store/visualization';
import {trackInstanceVisualizationExportedPDF} from '../../../../../tracking/tracking';

type VisualizationResultsExportPDFProps = {
  scenarioInProgress: boolean;
  model?: ModelDTO;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDetailDTO;
  results?: VisualizationResults;
};

const VisualizationResultsExportPDFActionMenuButton: React.FC<VisualizationResultsExportPDFProps> = ({
  scenarioInProgress,
  model,
  subModel,
  scenario,
  results,
}) => {
  const {downloadResultsAndExportPDF, exportPDF, ExportPDFComponent} = usePdfExport();

  const handleClick = useCallback(() => {
    if (scenarioInProgress) {
      return;
    }

    if (results) {
      model && subModel && trackInstanceVisualizationExportedPDF(model, subModel, {id: 0, name: 'test_visualization'});
      return exportPDF(results);
    } else if (scenario) {
      model && subModel && trackInstanceVisualizationExportedPDF(model, subModel, scenario);
      return downloadResultsAndExportPDF(scenario);
    }
  }, [model, subModel, scenario, results, scenarioInProgress, exportPDF, downloadResultsAndExportPDF]);

  return (
    <>
      <SideBarButtonItem
        {...tid(VISUALIZATION_ACTION_MENU_IDS.EXPORT_RESULTS_PDF)}
        disabled={(!scenario || scenarioInProgress) && !results?.elements.length}
        onClick={handleClick}
        icon={<DocumentPdf16 />}
        text="Export PDF"
      />
      {ExportPDFComponent}
    </>
  );
};

export default VisualizationResultsExportPDFActionMenuButton;
