import React from 'react';
import {Tooltip} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '../../core/materialui/ListItem/ListItem';

type SideBarAnchorItemProps = {
  disabled?: boolean;
  href: string;
  icon: React.ReactElement;
  text: string;
};

const SideBarAnchorItem: React.FC<SideBarAnchorItemProps> = ({disabled, icon, text, ...rest}) => {
  return (
    <ListItem button disabled={disabled} component="a" {...rest} disableGutters selectedLeftIndicator>
      <Tooltip title={text}>
        <ListItemIcon style={{color: 'inherit'}}>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default SideBarAnchorItem;
