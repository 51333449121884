import {AppComponentsAction, AppComponentsActions, AppComponentsState} from './types';

export const appComponentsInitialState: AppComponentsState = {
  buttonWithPopper: {},
};

export const appComponentsReducer = (
  state = appComponentsInitialState,
  action: AppComponentsAction,
): AppComponentsState => {
  switch (action.type) {
    case AppComponentsActions.BUTTON_WITH_POPPER_CLEAR: {
      return {...state, buttonWithPopper: {}};
    }
    case AppComponentsActions.BUTTON_WITH_POPPER_TOGGLE: {
      const prevButtonState = state.buttonWithPopper[action.button.group];
      const newButtonState =
        prevButtonState && prevButtonState.id === action.button.id ? {...action.button, id: undefined} : action.button;

      return {
        ...state,
        buttonWithPopper: {
          ...state.buttonWithPopper,
          [newButtonState.group]: newButtonState,
        },
      };
    }
    case AppComponentsActions.BUTTON_WITH_POPPER_SET: {
      return {
        ...state,
        buttonWithPopper: {
          ...state.buttonWithPopper,
          [action.button.group]: action.button,
        },
      };
    }
  }
  return state;
};
