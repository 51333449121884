import React, {PropsWithChildren} from 'react';
import {CircularProgress} from '@material-ui/core';

const SuspenseNull: React.FC<PropsWithChildren<any>> = ({children}) => {
  if (children) {
    return children;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
      }}>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          zIndex: 2,
        }}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default SuspenseNull;
