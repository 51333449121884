import {VisualizationAction, VisualizationActions, VisualizationState} from './types';
import {ScenarioDTO} from 'hemwb-api';
import {toggleValue} from '../../utils/toggleValue';
import {ScenarioGridFilter} from '../../components/pages/visualization/step1/types';
import {ScenarioAction, ScenarioActions} from '../scenario/types';

export const visualizationInitialState: VisualizationState = {
  step1: {
    filter: ScenarioGridFilter.ALL_INSTANCES,
  },
  analyseInitialState: {},
  analyseDataChanged: false,
};

export const visualizationReducer = (
  state = visualizationInitialState,
  action: VisualizationAction | ScenarioAction,
): VisualizationState => {
  switch (action.type) {
    case VisualizationActions.INIT: {
      if (state.modelUuid && state.modelUuid === action.modelUuid) {
        if (state.selection) {
          return {
            ...state,
            selection: {
              ...state.selection,
              scenarios: state.selection.scenarios.filter((s) => state.selection!.selectedScenarios[s.id]),
            },
          };
        }

        return state;
      }

      return {...visualizationInitialState, modelUuid: action.modelUuid};
    }

    case VisualizationActions.FRESH_START: {
      return {...visualizationInitialState, modelUuid: state.modelUuid};
      // return {
      //   step1: state.step1,
      // };
    }

    case VisualizationActions.INIT_ANALYSIS: {
      return {
        ...state,
        analyseId: action.analyseId,
      };
    }

    case VisualizationActions.INIT_ANALYSIS_DATA: {
      if (state.analyseId !== action.analyseId) {
        return state;
      }

      return {
        ...state,
        ...action.state,
        analyseId: action.analyseId,
        analyseInitialState: {
          selection: action.state.selection,
          parameters: action.state.parameters,
        },
        analyseDataChanged: false,
      };
    }

    case VisualizationActions.UNLOAD_ANALYSIS: {
      const {modelUuid, step1} = state;
      return {
        modelUuid,
        step1,
        analyseInitialState: {},
        analyseDataChanged: false,
      };
    }

    case VisualizationActions.DISCARD_CHANGES: {
      const {selection, parameters} = state.analyseInitialState || {};
      return {...state, selection, parameters, analyseDataChanged: false};
    }

    case VisualizationActions.SET_FILTER: {
      return {...state, step1: {...state.step1, filter: action.filter}};
    }

    case VisualizationActions.TOGGLE_SUB_MODEL: {
      const {subModel} = action;

      const {parameters, step1, ...restState} = state;
      return {
        ...restState,
        step1: {
          ...step1,
          openedSubModel: toggleValue(subModel, step1.openedSubModel),
        },
        // selection: {
        //   subModel: toggleValue(subModel, state?.selection?.subModel),
        //   scenarios: [],
        //   selectedScenarios: {},
        // },
        // ...(subModel.displayExecutionSetupId === state.parameters?.displaySubModelId ? {parameters} : {}),
      };
    }

    case VisualizationActions.TOGGLE_SELECTION: {
      const {subModel, scenario} = action;

      let selectedScenarios, scenarios: ScenarioDTO[];
      if (subModel.id === state?.selection?.subModel?.id) {
        selectedScenarios = state.selection.selectedScenarios;
        scenarios = state.selection.scenarios;
      } else {
        scenarios = [];
        selectedScenarios = {};
      }

      const selectedScenariosClone = {...selectedScenarios};
      let scenariosClone = scenarios;

      if (selectedScenariosClone[scenario.id]) {
        delete selectedScenariosClone[scenario.id];
      } else {
        if (!scenariosClone.some((s) => s.id === scenario.id)) {
          scenariosClone = [...scenariosClone, scenario];
        }
        selectedScenariosClone[scenario.id] = true;
      }

      return {
        ...state,
        analyseDataChanged: true,
        selection: {
          subModel,
          scenarios: scenariosClone,
          selectedScenarios: selectedScenariosClone,
        },
        ...(subModel.displayExecutionSetupId === state.parameters?.displaySubModelId
          ? {parameters: state.parameters}
          : {}),
      };
    }

    case VisualizationActions.SORT_SCENARIOS: {
      if (state.selection?.scenarios) {
        const {fromIndex, toIndex} = action;
        const scenarios = [...state.selection.scenarios];
        const i = scenarios[fromIndex];
        scenarios.splice(fromIndex, 1);
        scenarios.splice(toIndex, 0, i);
        return {
          ...state,
          analyseDataChanged: true,
          selection: {
            ...state.selection,
            scenarios,
          },
        };
      }
      return state;
    }

    case VisualizationActions.SET_PARAMETERS: {
      const {parameters, valid, displaySubModelId} = action;
      return {
        ...state,
        analyseDataChanged: true,
        parameters: {parameters, valid, displaySubModelId},
      };
    }

    case VisualizationActions.TEST_RESULTS: {
      return {
        ...state,
        analyseDataChanged: false,
        results: action.results,
      };
    }

    case ScenarioActions.UPDATE_STATUS: {
      const {scenario} = action;
      if (state.selection?.scenarios?.some((s) => s.id === scenario.id)) {
        return {
          ...state,
          selection: {
            ...state.selection,
            scenarios: state.selection.scenarios.map((s) => (s.id === scenario.id ? {...s, ...scenario} : s)),
          },
        };
      }

      return state;
    }
  }
  return state;
};
