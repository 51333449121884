import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import useScenarioParametersExport from './useScenarioParametersExport';
import {Upload16} from '@carbon/icons-react';

const ScenarioParametersExportActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const download = useScenarioParametersExport(props);

  return (
    <SideBarButtonItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_PARAMETERS_EXPORT)}
      disabled={!props.scenario}
      onClick={download}
      icon={<Upload16 />}
      text="Export"
    />
  );
};

export default ScenarioParametersExportActionMenuButton;
