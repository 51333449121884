import {AbstractControl} from 'react-reactive-form';
import {FieldKey} from './constants';
import {transformModelToValues} from './utils';
import {ModelDTO} from 'hemwb-api';
import isEqual from 'lodash/isEqual';

const pattern = /^\d*(\.\d*)?$/;
export const validateVersion = (control: AbstractControl, formInstance?: any, clonedFrom?: ModelDTO) => {
  if (!pattern.test(control.value || '')) {
    return {
      format: true,
    };
  }

  if (clonedFrom && formInstance.current) {
    const original = transformModelToValues(clonedFrom);

    const match = (['country', 'global', 'disease', 'product', 'therapeutic_area'] as FieldKey[]).reduce((acc, val) => {
      return (
        acc &&
        (Array.isArray(original[val])
          ? isEqual(original[val], formInstance.current.controls[val].value)
          : original[val] === formInstance.current.controls[val].value)
      );
    }, true);

    if (match && parseFloat(control.value) <= parseFloat(original['version_release_of_model'])) {
      return {
        min: {
          min: original['version_release_of_model'],
        },
      };
    }
  }

  return null;
};
