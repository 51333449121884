import {InputType} from './types';
import DateFnsUtilsClass from '@date-io/date-fns';

export const convertByType = (type: InputType, value: any, allowNull = true, context?: object | string) => {
  if (allowNull) {
    if (type === InputType.STRING && (value === '' || value === undefined || value === null)) {
      return '';
    }

    if (type !== InputType.STRING && (value === '' || value === undefined || value === null)) {
      return null;
    }
  }

  switch (type) {
    case InputType.BOOLEAN:
      return convertToBoolean(value, context);
    case InputType.STRING:
      return convertToString(value, context);
    case InputType.INTEGER:
      return convertToInteger(value, context);
    case InputType.DECIMAL:
      return convertToDecimalFormat(value, context);
    case InputType.REAL_NUMBER:
      return convertToFloat(value, context);
    case InputType.DATE:
      return convertToDateStringFormat(value, context);
    case InputType.FILE:
      return convertToFile(value, context);
  }

  throw new Error(`Unsupported "${type}" converter${context ? ` in context of ${stringifyContext(context)}` : ''}`);
};

const stringifyContext = (context: any): string => {
  let output;
  try {
    output = JSON.stringify(context, null, 4);
  } catch (e) {
    output = context + '';
  }

  return output;
};

export const convertToString = (value: any, context?: any): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  if (value === null) {
    return '';
  }

  throw new Error(
    `Failed to parse string from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

export const convertToInteger = (value: any, context?: any) => {
  const numValue = Number(value);

  if (value !== null && Number.isInteger(numValue)) {
    return numValue;
  }

  throw new Error(
    `Failed to parse integer from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

export const convertToFile = (value: any, context?: any) => {
  if (value !== null) {
    return value;
  }

  throw new Error(
    `Failed to parse file from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

export const convertToFloat = (value: any, context?: any) => {
  const numValue = Number(value);

  if (value !== null && Number.isFinite(numValue)) {
    return numValue;
  }

  throw new Error(
    `Failed to parse float from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

export const convertToDecimalFormat = (value: any, context?: any) => {
  const numValue = Number(value);

  if (value !== null && !Number.isNaN(numValue)) {
    return numValue;
  }

  throw new Error(
    `Failed to parse decimal from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

const datePattern = /^([0-9]{2,4})[-//]([0-1][0-9])[-//]([0-3][0-9])/;
const DateFnsUtils = new DateFnsUtilsClass();
export const convertToDateStringFormat = (value: any, context?: any) => {
  let dateValue;
  if (value instanceof Date) {
    dateValue = value;
  } else if (typeof value === 'string' && datePattern.test(value)) {
    dateValue = new Date(value);
  }

  if (dateValue) {
    return DateFnsUtils.format(dateValue, 'yyyy-MM-dd');
  }

  throw new Error(
    `Failed to parse date from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};

export const convertToBoolean = (value: any, context?: any) => {
  if (value === true || value === false) {
    return value;
  }
  if (typeof value === 'string') {
    const valueLower = value.toLowerCase();
    if (valueLower === 'true') {
      return true;
    }
    if (valueLower === 'false') {
      return false;
    }
  }

  throw new Error(
    `Failed to parse boolean from "${value}"${context ? ` in context of ${stringifyContext(context)}` : ''}`,
  );
};
