import React from 'react';
import {CircularProgress} from '@material-ui/core';
import styles from './EmptyPageWithSpinner.module.scss';

const EmptyPageWithSpinner: React.FC = () => (
  <div className={styles.container}>
    <CircularProgress />
  </div>
);

export default EmptyPageWithSpinner;
