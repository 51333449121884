import {FieldKey, fields, hardCodedOptions, requiredFields, Values} from './constants';
import {FormControl, Validators} from 'react-reactive-form';
import {optionValidator} from '../../../../../core/Form';
import {ModelDTO, VEAPActivity} from 'hemwb-api';
import {ModelWithMetadata, isModelExternal} from '../../../../../../store/model';
import {ModelTreeNode} from '../../../../../../store/modelFile';

export const formatDate = (date: Date | string): string =>
  date instanceof Date ? `${date.toLocaleDateString('en-US')} 12:00:00 AM` : date;

export const mapValuesToModel = (values: Values, model: ModelDTO, formWithVersion = true): ModelDTO => {
  const {version_release_of_model, version_log, veap_id, product, ...simpleValues} = values;
  const objectVeapId = () => {
    return typeof veap_id === 'object' ? (veap_id as VEAPActivity).id : veap_id;
  };
  return {
    ...model,
    metadata: {
      ...model.metadata,
      ...simpleValues,
      global: String(values['global']),
      platform: values['webmodel'] ? 'WebModel 2.0' : 'Model Repository',
      product: product.join('|'),
      veap_id: veap_id ? objectVeapId() : '',
      ...(formWithVersion ? {version_release_of_model, version_log} : {}),
    },
  };
};

export const transformModelToValues = (model: ModelWithMetadata, files?: ModelTreeNode[]): Values => {
  const {metadata} = model;
  const externalModel = isModelExternal(model);
  return (Object.keys(fields).reduce((acc, key) => {
    if (!metadata) {
      acc[key] = '';
    } else if (key === 'global') {
      acc[key] = metadata['global'] === 'true';
    } else if (key === 'webmodel') {
      acc[key] = metadata['platform'] === 'WebModel 2.0';
    } else if (key === 'product') {
      acc[key] = metadata['product']?.split('|') || [];
    } else if (key === 'mk_number' && externalModel) {
      acc[key] = metadata['mk_number']?.split(', ') || [];
    } else {
      acc[key] = metadata[key] || '';
    }
    if (files) {
      return {
        changelog_file: model.metadata.changelog_file || '',
        changeLogFileName: model.metadata.changelog_file
          ? files.find((node) => node.uuid === model.metadata.changelog_file)?.name || ''
          : '',
      };
    }

    return acc;
  }, ({} as any) as {[key: string]: any}) as any) as Values;
};

export const buildHardcodedOptionsControls = () =>
  Object.entries(hardCodedOptions).reduce((acc, [key, options]) => {
    acc[key] = new FormControl(null, [
      ...(requiredFields[key as FieldKey] ? [Validators.required] : []),
      optionValidator(options ?? []),
    ]);
    return acc;
  }, ({} as any) as {[key: string]: FormControl});
