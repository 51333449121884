import {AsyncActionState} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestList, requestUserExpertiseSetLevel} from './api';
import {userExpertiseAction, userExpertiseActions} from './types';
import {reduceAsyncAction} from '../asyncUtils';

export type ExpertiseLevelState = {
  list: {[key: string]: AsyncActionState<Await<ReturnType<typeof requestList>>, Parameters<typeof requestList>>};
  optionsList: {
    [key: string]: AsyncActionState<
      Await<ReturnType<typeof requestUserExpertiseSetLevel>>,
      Parameters<typeof requestUserExpertiseSetLevel>
    >;
  };
};

export const userExpertiseInitialState: ExpertiseLevelState = {
  list: {},
  optionsList: {},
};

export const userExpertiseReducer = (
  state = userExpertiseInitialState,
  action: userExpertiseAction,
): ExpertiseLevelState => {
  if (action.type === userExpertiseActions.MODEL_USER_TYPE) {
    const [modelUuid, userId] = action.params;
    return {
      ...state,
      list: {...state.list, [`${modelUuid}-${userId}`]: reduceAsyncAction(action)},
    };
  }
  return state;
};
