import {Grid} from '@material-ui/core';
import React from 'react';
import {ModelDetailDTO, ModelDTO} from 'hemwb-api';
import {InformationClassificationIcon} from '../../../../icons/classification/InformationClassificationIcon';
import styles from './styles';
import ModelTextOverviewTile from './ModelTextOverviewTile';
import {
  ChartBubbleLine,
  CloudServices,
  EuropeAfrica,
  FinancialGain,
  GlobeLocations,
  Magnify,
  Research,
  Microorganisms,
  NetworkServices,
  ResetHybridCloud,
  StrategyMove,
  Tags,
  MedicalCharts,
  Coronavirus,
  DataBackup,
  Prescription,
  Transform_01 as Transform01,
} from '@carbon/pictograms-react';
import {getModelGlobalAsTextValue, isModelExternal, isModelWeb} from '../../../../../store/model';
import {ModelTextOverviewUsers} from './ModelTextOverviewUsers';
import {getModelLanguageAndExecutionEnvironment} from './utils';

type ModelTextOverviewCondensedProps = {
  model: ModelDTO | ModelDetailDTO;
};

const ModelTextOverviewCondensed: React.FC<ModelTextOverviewCondensedProps> = ({model}) => {
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  return (
    <>
      <Grid container spacing={0} direction="column" className={styles.wrapper}>
        <Grid container direction="row" justify="space-between">
          <ModelTextOverviewTile
            label="Model Name"
            value={model.name}
            Icon={<ChartBubbleLine />}
            className={styles.tileModelName}
          />
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            {externalModel && (
              <ModelTextOverviewTile
                label="Trial Number"
                value={model.metadata.study_number}
                Icon={<MedicalCharts />}
              />
            )}
            <ModelTextOverviewTile
              label="Therapeutic Area"
              value={model.metadata.therapeutic_area}
              Icon={<Research />}
            />
            <ModelTextOverviewTile label="Name of Disease" value={model.metadata.disease} Icon={<Magnify />} />
            {externalModel && (
              <ModelTextOverviewTile
                label="THEOREM Indication"
                value={model.metadata.indication}
                Icon={<Coronavirus />}
              />
            )}
            <ModelTextOverviewTile label="Drug/Product Name" value={model.metadata.product} Icon={<Microorganisms />} />
            {externalModel && (
              <ModelTextOverviewTile label="MK Number" value={model.metadata.mk_number} Icon={<Prescription />} />
            )}
            <ModelTextOverviewTile
              label="Global/Adaptation"
              value={getModelGlobalAsTextValue(model)}
              Icon={<GlobeLocations />}
            />
            <ModelTextOverviewTile label="Country" value={model.metadata.country} Icon={<EuropeAfrica />} />
          </Grid>
          <ModelTextOverviewUsers modelId={model.uuid} />
          <Grid item container lg={4} md={12} direction="column" justify="flex-start">
            {externalModel && (
              <ModelTextOverviewTile label="Model Label" value={model.metadata.label} Icon={<Tags />} />
            )}
            <ModelTextOverviewTile label="Model Type" value={model.metadata.model_type} Icon={<FinancialGain />} />
            {externalModel && (
              <ModelTextOverviewTile
                externalModel={externalModel}
                label="Platform"
                platform={
                  model.metadata.platform === 'Pneumococcal Vaccines' ? 'PneuInsights' : model.metadata.platform
                }
                value={model.metadata?.ext_url}
                Icon={<DataBackup />}
              />
            )}
            {webModel && (
              <ModelTextOverviewTile
                label="Platform"
                platform={model.metadata.platform}
                value={model.metadata?.ext_url}
                Icon={<DataBackup />}
              />
            )}

            <ModelTextOverviewTile
              label="Model Language"
              value={getModelLanguageAndExecutionEnvironment(model.metadata)}
              Icon={<NetworkServices />}
            />
            <ModelTextOverviewTile
              label="Version Release of Model"
              value={model.metadata.version_release_of_model}
              Icon={<ResetHybridCloud />}
            />
            <ModelTextOverviewTile label="Status" value={model.metadata.status} Icon={<CloudServices />} />
            <ModelTextOverviewTile
              label="Information Classification"
              value={model.metadata.information_classification}
              Icon={
                <InformationClassificationIcon classification={model.metadata.information_classification} markOnly />
              }
            />
            <ModelTextOverviewTile
              label="Model Development Plan"
              value={model.metadata.model_development_plan}
              Icon={<StrategyMove />}
            />
            <ModelTextOverviewTile
              label="Stage of Model Development"
              value={model.metadata.stage_of_model_development}
              Icon={<Transform01 />}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ModelTextOverviewCondensed;
