import {AsyncAction} from '../asyncUtilsTypes';
import {ScenarioDTO, ScenarioExistsByNameRequest} from 'hemwb-api';
import {
  requestScenarioCreate,
  requestScenarioDelete,
  requestScenarioDetail,
  requestScenarioGetOutput,
  requestScenarioList,
  requestScenarioLoadError,
  requestScenarioOutdate,
  requestScenarioShare,
  requestScenarioStatusUpdate,
  requestScenarioUpdate,
  requestScenarioUpdateInputs,
  requestScenarioUpdateLabel,
  requestShareInstance,
  requestUpdateShareInstance,
  requestUserList,
} from './api';
import {Await} from '../types';

export enum ScenarioActions {
  REQUEST_DETAIL = 'SCENARIO/REQUEST_DETAIL',
  REQUEST_LIST = 'SCENARIO/REQUEST_LIST',
  REQUEST_CREATE = 'SCENARIO/REQUEST_CREATE',
  REQUEST_UPDATE = 'SCENARIO/REQUEST_UPDATE',
  REQUEST_UPDATE_LABEL = 'SCENARIO/REQUEST_UPDATE_LABEL',
  REQUEST_UPDATE_INPUTS = 'SCENARIO/REQUEST_UPDATE_INPUTS',
  REQUEST_OUTPUTS = 'SCENARIO/REQUEST_OUTPUTS',
  REQUEST_STATUS_UPDATE = 'SCENARIO/REQUEST_STATUS_UPDATE',
  REQUEST_DELETE = 'SCENARIO/REQUEST_DELETE',
  UPDATE_STATUS = 'SCENARIO/UPDATE_STATUS',
  REQUEST_NAME_EXISTS = 'SCENARIO/REQUEST_NAME_EXISTS',
  REQUEST_ERROR = 'SCENARIO/REQUEST_ERROR',
  REQUEST_SHARE = 'SCENARIO/REQUEST_SHARE',
  REQUEST_OUTDATE = 'SCENARIO/REQUEST_OUTDATE',
  REQUEST_USERS = 'SCENARIO/REQUEST_USERS',
  INSTANCE_SHARE = 'SCENARIO/INSTANCE_SHARE',
  UPDATE_INSTANCE_SHARE = 'SCENARIO/UPDATE_INSTANCE_SHARE',
}

export type ScenarioActionUpdateStatus = {
  type: ScenarioActions.UPDATE_STATUS;
  scenario: ScenarioDTO;
};

export type ScenarioAction =
  | AsyncAction<
      ScenarioActions.REQUEST_DETAIL,
      Await<ReturnType<typeof requestScenarioDetail>>,
      Parameters<typeof requestScenarioDetail>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_LIST,
      Await<ReturnType<typeof requestScenarioList>>,
      Parameters<typeof requestScenarioList>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_CREATE,
      Await<ReturnType<typeof requestScenarioCreate>>,
      Parameters<typeof requestScenarioCreate>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_UPDATE,
      Await<ReturnType<typeof requestScenarioUpdate>>,
      Parameters<typeof requestScenarioUpdate>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_UPDATE_LABEL,
      Await<ReturnType<typeof requestScenarioUpdateLabel>>,
      Parameters<typeof requestScenarioUpdateLabel>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_UPDATE_INPUTS,
      Await<ReturnType<typeof requestScenarioUpdateInputs>>,
      Parameters<typeof requestScenarioUpdateInputs>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_STATUS_UPDATE,
      Await<ReturnType<typeof requestScenarioStatusUpdate>>,
      Parameters<typeof requestScenarioStatusUpdate>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_DELETE,
      Await<ReturnType<typeof requestScenarioDelete>>,
      Parameters<typeof requestScenarioDelete>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_OUTPUTS,
      Await<ReturnType<typeof requestScenarioGetOutput>>,
      Parameters<typeof requestScenarioGetOutput>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_ERROR,
      Await<ReturnType<typeof requestScenarioLoadError>>,
      Parameters<typeof requestScenarioLoadError>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_SHARE,
      Await<ReturnType<typeof requestScenarioShare>>,
      Parameters<typeof requestScenarioShare>
    >
  | ScenarioActionUpdateStatus
  | AsyncAction<ScenarioActions.REQUEST_NAME_EXISTS, boolean, [ScenarioExistsByNameRequest]>
  | AsyncAction<
      ScenarioActions.REQUEST_OUTDATE,
      Await<ReturnType<typeof requestScenarioOutdate>>,
      Parameters<typeof requestScenarioOutdate>
    >
  | AsyncAction<
      ScenarioActions.REQUEST_USERS,
      Await<ReturnType<typeof requestUserList>>,
      Parameters<typeof requestUserList>
    >
  | AsyncAction<
      ScenarioActions.INSTANCE_SHARE,
      Await<ReturnType<typeof requestShareInstance>>,
      Parameters<typeof requestShareInstance>
    >
  | AsyncAction<
      ScenarioActions.UPDATE_INSTANCE_SHARE,
      Await<ReturnType<typeof requestUpdateShareInstance>>,
      Parameters<typeof requestUpdateShareInstance>
    >;
