import {configuration} from '../getBackendConfiguration';
import {FindScenarioExecutionsRequest, ScenarioExecutionApi} from 'hemwb-api';

const scenarioExecutionApi = new ScenarioExecutionApi(configuration);

export const requestScenarioExecutionList = (requestParameters: FindScenarioExecutionsRequest) =>
  scenarioExecutionApi.findScenarioExecutions(requestParameters);

export const requestScenarioGroupExecutionList = (requestParameters: FindScenarioExecutionsRequest) =>
  scenarioExecutionApi.getAllInstanceByGroupingWithGroupId(requestParameters);

export const requestScenarioExecutionCreate = (scenarioId: number) =>
  scenarioExecutionApi.createScenarioExecutionGroup({scenarioId});

export const requestScenarioExecutionExecute = (groupId: number) => scenarioExecutionApi.executeScenario({groupId});

export const requestScenarioExecutionCancel = (groupId: number) =>
  scenarioExecutionApi.cancelScenarioExecution({groupId});
