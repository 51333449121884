import {
  ExecutionSetupDTO,
  ExecutionSetupType,
  LoggedInUserDTO,
  ModelDTO,
  ScenarioDTO,
  ScenarioStatus,
  UserScenarioAccess,
  UserType,
} from 'hemwb-api';
import {PERMISSION, Permission} from './types';
import {isInstanceExecutable, isModelScenarioUserOnly} from './utils';
import {isModelRetired} from '../store/model';

const checkSectionAccessibility = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
): Permission => {
  if (model && subModel) {
    if (
      isModelScenarioUserOnly(loggedInUser, model) &&
      subModel.type === ExecutionSetupType.CALIBRATION &&
      loggedInUser.userType !== UserType.SUPERUSER
    ) {
      return PERMISSION.FORBIDDEN;
    }
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canAddInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
): Permission => {
  if (model && subModel) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

export const canEditInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (model && subModel && instance && !isModelRetired(model) && isInstanceExecutable(instance)) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

export const canExecuteInstance = canEditInstance;

export const canCloneInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (model && subModel && instance && !isModelRetired(model) && !instance.outdated) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

export const canCancelInstanceExecution = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (
    model &&
    subModel &&
    instance &&
    (instance.status === ScenarioStatus.INQUEUE || instance.status === ScenarioStatus.INPROCESS) &&
    (instance.userAccess === UserScenarioAccess.CREATOR || instance.shared)
  ) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canDeleteInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (
    model &&
    subModel &&
    instance &&
    !isModelRetired(model) &&
    (isInstanceExecutable(instance) || instance.status === ScenarioStatus.COMPLETED) &&
    instance.userAccess === UserScenarioAccess.CREATOR
  ) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

const downloadResultsInstanceStates = [ScenarioStatus.COMPLETED, ScenarioStatus.ACCEPTED];
export const canViewInstanceResults = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (model && subModel && instance && downloadResultsInstanceStates.includes(instance.status)) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

export const canAcceptInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (subModel?.type === ExecutionSetupType.SCENARIO) {
    return PERMISSION.FORBIDDEN;
  }

  if (model && subModel && instance && instance.status === ScenarioStatus.COMPLETED) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};

export const canUnacceptInstance = (
  loggedInUser: LoggedInUserDTO,
  model?: ModelDTO,
  subModel?: ExecutionSetupDTO,
  instance?: ScenarioDTO,
): Permission => {
  if (subModel?.type === ExecutionSetupType.SCENARIO) {
    return PERMISSION.FORBIDDEN;
  }

  if (model && subModel && instance && instance.status === ScenarioStatus.ACCEPTED) {
    return checkSectionAccessibility(loggedInUser, model, subModel);
  }

  return PERMISSION.DISALLOWED;
};
