import {ModelDetailDTO, ModelDTO, UserDTO, UserType} from 'hemwb-api';
import {
  ModelStatusCreating,
  ModelStatusDraft,
  ModelStatusPublished,
  ModelStatusRetired,
  ModelWithMetadata,
  StoredModel,
} from './types';
import {
  FieldKey,
  hardCodedOptions,
  requiredFields,
} from '../../components/pages/model/common/ModelEdit/ModelEditMetadata/constants';
import {ModelMetadata} from '../modelMetadata/types';
import {ModelFlatNode} from '../modelLinks/types';

export type modelType = ModelDTO | ModelWithMetadata | null;

export const modifyModel = <M extends ModelDTO>(model: M, parts: Partial<M>): M => ({
  ...model,
  ...parts,
  metadata: {...model.metadata, ...parts.metadata},
});

export const modifyModelMetadata = <M extends StoredModel>(model: M, metadata: ModelMetadata): M => ({
  ...model,
  metadata: {
    ...model.metadata,
    ...metadata,
  },
});

export function isModelDTO(model: StoredModel): model is ModelDTO {
  return model.hasOwnProperty('name');
}

export function isModelDetailDTO(model: StoredModel): model is ModelDetailDTO {
  return model.hasOwnProperty('owners');
}

export const isModelPublished = (model: ModelDTO | null = null): boolean => {
  return !!model && model.metadata.status === ModelStatusPublished;
};

export const isModelRetired = (model: ModelDTO | null = null): boolean => {
  return !!model && model.metadata.status === ModelStatusRetired;
};

export const isModelDraft = (model: ModelDTO | null = null): boolean => {
  return !!model && model.metadata.status === ModelStatusDraft;
};

export const isModelCreating = (model: ModelDTO | null = null): boolean => {
  return !!model && model.metadata.status === ModelStatusCreating;
};

export const isModelGlobal = (model: ModelWithMetadata | null = null): boolean => {
  return !!model && model.metadata.global === 'true';
};

export const isModelExternal = (model: modelType = null): boolean => {
  return (
    !!model && (model.metadata?.platform === 'THEOREM Oncology' || model.metadata?.platform === 'Pneumococcal Vaccines')
  );
};

export const isModelTheorem = (model: modelType = null): boolean => {
  return !!model && model.metadata?.platform === 'THEOREM Oncology';
};

export const isModelWeb = (model: modelType = null): boolean => {
  return !!model && model.metadata.platform === 'WebModel 2.0';
};

export const isModelRepository = (model: modelType = null): boolean => {
  return !!model && model.metadata?.platform === 'Model Repository';
};

export const isModelPneuInsights = (model: modelType = null): boolean => {
  return !!model && model.metadata?.platform === 'Pneumococcal Vaccines';
};

export const stringifyCountry = (model: ModelWithMetadata, separator = ' & ') => {
  let country = model.metadata.country;
  country = country && country.indexOf('(') > -1 ? country.substring(0, country.indexOf('(')).trim() : country;
  return [isModelGlobal(model) && 'Global', country].filter(Boolean).join(separator);
};

export const getModelGlobalAsTextValue = (model: ModelDTO): string | undefined => {
  if (model.metadata.global === 'true' && model.metadata.country && model.metadata.country !== 'Not Applicable') {
    return 'Global + Country Adaptation';
  }
  if (model.metadata.global === 'true') {
    return 'Global';
  }
  if (model.metadata.country) {
    return 'Country Adaptation';
  }

  return undefined;
};

export const getModelLanguageText = (modelLanguage: string) => {
  return modelLanguage === 'ExcelNative' ? 'Excel' : modelLanguage || '';
};

export const getModelLanguageValue = (modelLanguageText: string) => {
  return modelLanguageText === 'Excel' ? 'ExcelNative' : modelLanguageText;
};

export const getModelSteward = (model: ModelDTO): UserDTO | null => {
  if (
    model.metadata['isid_of_model_steward'] &&
    model.metadata['name_of_model_steward'] &&
    model.metadata['email_for_model_steward']
  ) {
    const [lastName, firstName] = model.metadata['name_of_model_steward'].split(', ');
    return {
      id: model.metadata['isid_of_model_steward'],
      email: model.metadata['email_for_model_steward'],
      firstName,
      lastName,
      active: true,
      country: '',
      department: '',
      divisionName: '',
      userType: UserType.INTERNALUSER,
    };
  }
  return null;
};

export const canBeParentOfModel = (parent: StoredModel, current: ModelDTO): boolean => {
  if (
    !isModelDTO(parent) ||
    (!isModelPublished(parent) && !isModelRetired(parent)) ||
    (isModelGlobal(current) && !isModelGlobal(parent))
  ) {
    return false;
  }

  return true;
};

export const doLineageMetadataMatch = (parent: StoredModel, current: ModelDTO): boolean => {
  return (
    parent.metadata.product === current.metadata.product &&
    parent.metadata.disease === current.metadata.disease &&
    parent.metadata.therapeutic_area === current.metadata.therapeutic_area
  );
};

export const calculateParentId = (parent: StoredModel, current: ModelDTO, flatHierarchy?: ModelFlatNode[]): string => {
  if (
    doLineageMetadataMatch(parent, current) &&
    (canBeParentOfModel(parent, current) || isModelRetired(parent as ModelDTO))
  ) {
    return parent.uuid;
  }

  if (flatHierarchy) {
    const parentOfParentNode = flatHierarchy.find(
      (n) => n.model.uuid === parent.metadata.tree_parent_id && n.model.uuid !== parent.uuid,
    );
    if (parentOfParentNode) {
      return calculateParentId(parentOfParentNode.model, current, flatHierarchy);
    }
  }
  return '';
};

export const isStaticMetadataValid = (model: ModelDTO, ignoreVersion = false): boolean => {
  const isMissingRequiredField = Object.keys(requiredFields).some((property) => {
    if (property === 'modelSteward') {
      return !model.metadata['email_for_model_steward'] || !model.metadata['isid_of_model_steward'];
    }

    if (property === 'country') {
      return !isModelGlobal(model) && !model.metadata[property];
    }

    if (property === 'version_release_of_model' && ignoreVersion) {
      return false;
    }

    return !model.metadata[property];
  });

  if (isMissingRequiredField) {
    return false;
  }

  return (Object.keys(hardCodedOptions) as FieldKey[]).some((property) =>
    (hardCodedOptions[property] || []).includes(model.metadata[property]),
  );
};

export const getMissingRequiredMetadata = (model: ModelDTO): FieldKey[] => {
  return (Object.keys(requiredFields) as FieldKey[]).reduce((acc, property) => {
    if (property === 'country') {
      if (!isModelGlobal(model) && !model.metadata[property]) {
        acc.push(property);
      }
    } else if (!model.metadata[property]) {
      acc.push(property);
    }

    return acc;
  }, ([] as any) as FieldKey[]);
};

export const isModelExecutable = (model?: ModelDTO | null) => model?.metadata.executable === 'true';

const whiteListedMetadataForUpdate = {
  bitbucket_commit: true,
  bitbucket_path: true,
  bitbucket_project: true,
  bitbucket_repository: true,
  changelog_file: true,
  country: true,
  disease: true,
  global: true,
  platform: true,
  information_classification: true,
  isid_of_model_steward: true,
  model_development_plan: true,
  model_type: true,
  notes: true,
  objective_of_model: true,
  product: true,
  stage_of_model_development: true,
  study_number: true,
  suffix: true,
  therapeutic_area: true,
  type: true,
  usage_notes: true,
  veap_id: true,
  version_log: true,
  version_release_of_model: true,
} as {[key: string]: true};

export const clearEmptyAndBlacklistedMetadata = (metadata: ModelMetadata): ModelMetadata =>
  Object.keys(metadata).reduce((acc, key) => {
    if (!!metadata[key] && whiteListedMetadataForUpdate[key]) {
      acc[key] = metadata[key];
    }
    return acc;
  }, ({} as any) as ModelMetadata);

export const clearBlacklistedMetadata = (metadata: ModelMetadata): ModelMetadata =>
  Object.keys(metadata).reduce((acc, key) => {
    if (whiteListedMetadataForUpdate[key]) {
      acc[key] = metadata[key];
    }
    return acc;
  }, ({} as any) as ModelMetadata);

export const getFormattedUTCDateTime = (date: Date) => {
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + date.getUTCDate()).slice(-2);
  const year = date.getUTCFullYear();
  const hour = ('0' + date.getUTCHours()).slice(-2);
  const min = ('0' + date.getUTCMinutes()).slice(-2);

  return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ' UTC';
};
