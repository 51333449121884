import {RootState} from '../rootReducer';
import {modelViewersSelector} from './selectors';
import {modelViewersLoad} from './actions';
import {useReduxLoader, useReduxManualLoader} from '../useReduxLoader';

const viewersSelectorBuilder = (params: [string]) => (state: RootState) =>
  modelViewersSelector(state).viewers[params[0]] || null;

export const useModelViewers = (modelUuid: string) => {
  return useReduxLoader(viewersSelectorBuilder, modelViewersLoad, modelUuid);
};

export const useModelViewersLoader = (modelUuid: string) => {
  return useReduxManualLoader(viewersSelectorBuilder, modelViewersLoad, modelUuid);
};
