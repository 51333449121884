import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {GridState, Order, useGridState} from '../../../../store/grid';
import {
  ExecutionSetupDTO,
  ModelDTO,
  ScenarioDTO,
  ScenarioGroupDetailDTO,
  ScenarioListDTO,
  ScenarioOrderBy,
  ScenarioStatus,
} from 'hemwb-api';
import {getScenarioListKey, scenarioLoadList, scenarioSelector} from '../../../../store/scenario';
import {useDispatch} from 'react-redux';
import {useGridLoader} from '../../../core/TableWithSortAndPagination/useGridLoader';
import {useSelector} from '../../../../store/rootReducer';
import {useFlashRow} from '../../../core/TableWithSortAndPagination/useFlashRow';
import TableWithSortAndPagination, {TableWithSortAndPaginationTheme} from '../../../core/TableWithSortAndPagination';
import {scenarioGridColumns} from '../common/constants';
import {useCurrentUser} from '../../../../store/auth';
import {ScenarioGridFilter} from './types';
import {sortInstancesByGroupOrder} from './utils';

type ScenarioGridProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  filter: ScenarioGridFilter;
  groups: ScenarioGroupDetailDTO[];
  search: string;
  onRender?: (scenarios: ScenarioDTO[]) => void;
  rowRenderer?: (scenario: ScenarioDTO) => ReactNode;
};

const ScenarioGrid: React.FC<ScenarioGridProps> = (
  {model, subModel, groups, filter, search, onRender, rowRenderer},
  ref,
) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const gridState = useGridState<ScenarioGridFilter>({
    orderBy: ScenarioOrderBy.CreatedOn,
    order: Order.DESC,
    filter: filter,
  });

  type LoaderCacheType = {
    key: string;
    loader: Promise<ScenarioListDTO>;
  };
  const loaderCache = useRef<LoaderCacheType>({key: '', loader: Promise.resolve({total: 0, list: []})});

  const loader = useCallback(
    (gState: GridState<ScenarioGridFilter>) => {
      const filterParams: any = {};
      switch (gState.filter) {
        case ScenarioGridFilter.COMPLETED_SCENARIOS: {
          filterParams['status'] = [ScenarioStatus.COMPLETED, ScenarioStatus.SUPERSEDED, ScenarioStatus.ACCEPTED];
          break;
        }
        case ScenarioGridFilter.ALL_MY_INSTANCES: {
          filterParams.userId = user.id;
          break;
        }
        case ScenarioGridFilter.SAVED_ANALYSES: {
          filterParams.executionSetupId = subModel.displayExecutionSetupId || 0;
        }
      }

      const requestParameters = {
        modelUuid: model.uuid,
        executionSetupId: subModel.id,
        pageSize: gState.rowsPerPage,
        pageIndex: gState.page * gState.rowsPerPage,
        orderBy: ((gState.orderBy as any) as ScenarioOrderBy) || ScenarioOrderBy.CreatedOn,
        orderDesc: gState.order === Order.DESC,
        query: gState.search,
        hidden: false,
        ...filterParams,

        // query: gState.search,
        // scenarioType: isCalibration ? ScenarioType.CALIBRATION : ScenarioType.MAIN,
      };
      const requestParametersKey = getScenarioListKey(requestParameters);

      let loaderPromise: Promise<ScenarioListDTO>;
      if (loaderCache.current?.key === requestParametersKey) {
        loaderPromise = loaderCache.current.loader;
      } else {
        const newLoader = scenarioLoadList(dispatch, requestParameters);
        //  loaderCache.current = {key: requestParametersKey, loader: newLoader};
        loaderPromise = newLoader;
      }

      return loaderPromise
        .then((results) => {
          if (gState.filter === ScenarioGridFilter.SAVED_ANALYSES) {
            const list = results.list.filter((s) => s.name);
            return {total: list.length, list};
          }
          return results;
        })
        .then((results) => {
          if (groups) {
            return {...results, list: sortInstancesByGroupOrder(results.list, groups)};
          }
          return results;
        });

      // return scenarioLoadList(dispatch, requestParameters)
      //   .then((results) => {
      //     if (gState.filter === ScenarioGridFilter.SAVED_ANALYSES) {
      //       const list = results.list.filter((s) => s.name);
      //       return {total: list.length, list};
      //     }
      //     return results;
      //   })
      //   .then((results) => {
      //     if (groups) {
      //       return {...results, list: sortInstancesByGroupOrder(results.list, groups)};
      //     }
      //     return results;
      //   });
    },
    [dispatch, groups, model, subModel, user],
  );

  const [listData, setListData] = useState<ScenarioListDTO | null>();
  const {loading, reload, data} = useGridLoader<ScenarioListDTO, ScenarioGridFilter>(gridState, loader);
  // const data = useSelector((state) => scenarioSelector(state).list.value);

  useEffect(() => {
    setListData(data);
  }, [data]);

  useEffect(() => {
    setListData({total: 0, list: []});
  }, [subModel]);

  const lastStatusUpdate = useSelector((state) => scenarioSelector(state).lastStatusUpdate);
  useEffect(() => {
    if (lastStatusUpdate && listData && listData.list.some((s) => s.id === lastStatusUpdate.id)) {
      setListData({
        ...listData,
        list: listData.list.map((s) => (s.id === lastStatusUpdate.id ? {...s, ...lastStatusUpdate} : s)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatusUpdate]);
  useFlashRow(lastStatusUpdate?.id);

  // const handleScenariosChange = useCallback(() => {
  //   if (!mounted.current) {
  //     return;
  //   }
  //
  //   if (data?.list.length === 1 && gridState.page > 0) {
  //     gridState.setPage(gridState.page - 1);
  //   } else {
  //     reload();
  //   }
  // }, [data, gridState, reload, mounted]);

  useImperativeHandle(
    ref,
    () => ({
      reload,
    }),
    [reload],
  );

  useEffect(() => {
    if (gridState.page !== 0) {
      gridState.setPage(0);
    }
    gridState.setSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (gridState.page !== 0) {
      gridState.setPage(0);
    }
    gridState.setFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useLayoutEffect(() => {
    if (loading) {
      onRender?.([]);
    } else {
      onRender?.(data?.list || []);
    }
  }, [onRender, data, loading]);

  return (
    <TableWithSortAndPagination
      client={false}
      theme={TableWithSortAndPaginationTheme.Lightweight}
      hidePagination
      getRowId={(scenario) => scenario.id.toString()}
      rows={listData?.list || []}
      total={listData?.total || 0}
      columns={scenarioGridColumns}
      rowRenderer={rowRenderer}
      // onClickRow={handleClickRow}
      // isRowSelected={(scenario) => selectedScenario?.id === scenario.id}
      gridState={gridState}
      loading={loading}
      noResultsMessage="No scenario was found"
    />
  );
};

export default forwardRef(ScenarioGrid);
