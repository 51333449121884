import React, {PropsWithChildren} from 'react';
import {Button, ButtonProps, CircularProgress} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import {PERMISSION, Permission} from '../../../permissions/types';

export const defaultSubmitButtonProps: ButtonProps & {'data-testid': string} = {
  type: 'submit',
  variant: 'contained',
  size: 'large',
  color: 'primary',
  className: 'submitButton',
  endIcon: <CheckCircleOutlineIcon />,
  children: 'Save',
  'data-testid': 'btn-submit',
};

type SubmitButtonProps = PropsWithChildren<
  {active?: boolean; disableLoading?: boolean; permission?: Permission} & ButtonProps
>;

const SubmitButton: React.FC<SubmitButtonProps> = ({active, disableLoading, permission, className, ...props}) => {
  const mergedClassName = clsx('submitButton', className);
  if (permission === PERMISSION.DISALLOWED) {
    return null;
  }
  if (typeof active === 'boolean') {
    return (
      <Button
        {...defaultSubmitButtonProps}
        {...props}
        className={mergedClassName}
        disabled={props.disabled || active}
        {...(active && !disableLoading && {endIcon: <CircularProgress style={{width: '1.6rem', height: '1.6rem'}} />})}
      />
    );
  }

  return <Button {...defaultSubmitButtonProps} {...props} className={mergedClassName} />;
};

export default SubmitButton;
