import {CommonInputAttributes, expertiseLevel, InputAttributes, InputType} from './types';
import convert from 'xml-js';
import {formatInput} from './formatInput';
import {
  parseArrayAttributes,
  parseBooleanAttributes,
  parseLabelAttributes,
  parseNumberAttributes,
  parseRangeAttributes,
  parseSelectAttributes,
  parseStringAttributes,
} from '../typesUtility';
import {getOptionKeys} from './utils';

export const importFromXML = (xmlData: string): InputAttributes[] => {
  const output: InputAttributes[] = [];
  const parsed = convert.xml2js(xmlData, {compact: false});

  // const modelType = parsed.elements[0].modelType;
  // const categoryId = parsed.elements[0].elements[0].attributes.id;

  parsed.elements[0].elements.forEach((category: any) => {
    category.elements.forEach((question: any) => {
      const parsedQuestion = parseQuestion(question);
      if (parsedQuestion && category?.attributes?.id) {
        parsedQuestion.categoryId = category.attributes.id + '';
      }

      if (parsedQuestion) {
        output.push(parsedQuestion);
      }
    });
  });

  return recalculateControlledBy(output);
};

const defaultCommonInputAttributes: CommonInputAttributes = {
  id: 0,
  categoryId: '3',
  name: '',
  title: '',
  question: '',
  visible: true,
  required: false,
  viewable: true,
};

const parseQuestion = (data: any): InputAttributes | null => {
  if (data.name !== 'question') {
    return null;
  }
  const {attributes, elements} = data;

  const commonInputAttributes = {...defaultCommonInputAttributes};
  let uniqueInputAttributes: any;

  commonInputAttributes.id = Number(attributes.id);
  commonInputAttributes.visible = attributes.isVisible === 'true';
  commonInputAttributes.required = attributes.isRequired === 'true';
  commonInputAttributes.viewable = attributes.isVisible === 'true';
  // @ts-ignore
  commonInputAttributes.expertiseLevel = expertiseLevel[attributes.expertise];

  elements.forEach((e: any) => {
    if (e?.attributes?.controlType) {
      commonInputAttributes.controlType = e.attributes.controlType;
    }

    switch (e.name) {
      case 'title':
        commonInputAttributes.title = e.elements[0].text;
        break;
      case 'questionText':
        commonInputAttributes.question = e.elements[0].text;
        break;
      case 'mathematicaName':
        commonInputAttributes.name = e.elements[0].text;
        break;
      case 'controlParameters':
        const {controllerId, optionId} = e.elements[0].attributes;
        commonInputAttributes.controlledBy = {
          id: controllerId,
          // WebModel is indexing from 1, but MR from 0
          value: optionId && optionId > 0 ? optionId - 1 : optionId,
        };
        break;
      case 'string':
        uniqueInputAttributes = parseStringAttributes(e);
        break;
      case 'number':
        uniqueInputAttributes = parseNumberAttributes(e);
        break;
      case 'boolean':
        uniqueInputAttributes = parseBooleanAttributes(e);
        break;
      case 'select':
        uniqueInputAttributes = parseSelectAttributes(e);
        break;
      case 'range':
        uniqueInputAttributes = parseRangeAttributes(e);
        break;
      case 'array':
        uniqueInputAttributes = parseArrayAttributes(e);
        break;
      case 'label':
        uniqueInputAttributes = parseLabelAttributes(e);
        break;
    }
  });

  if (uniqueInputAttributes) {
    return formatInput({...commonInputAttributes, ...uniqueInputAttributes});
  }

  return null;
};

const recalculateControlledBy = (definitions: InputAttributes[]): InputAttributes[] => {
  return definitions.map((definition) => {
    if (definition.controlledBy) {
      const id = Number(definition.controlledBy.id);
      const optionId = Number(definition.controlledBy.value);
      const controllerDefinition = definitions.find((d) => d.id === id);
      if (controllerDefinition) {
        if (controllerDefinition.type === InputType.BOOLEAN) {
          return {
            ...definition,
            controlledBy: {
              id,
              value: optionId === 1,
            },
          };
        }

        const options = getOptionKeys(controllerDefinition);

        const value = options[optionId];
        if (value !== undefined) {
          return {
            ...definition,
            controlledBy: {
              id,
              value,
            },
          };
        }

        throw new Error(
          `Failed to assign value to controlParameter optionId "${definition.controlledBy.value}" for controllerId "${definition.controlledBy.id}"`,
        );
      }
    }

    return definition;
  });
};
