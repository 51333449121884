import {IncidentManagementActions, IncidentManagementAction, StoredIncident} from './types';
import {buildInitialAsyncState, reduceAsyncAction, reduceAsyncActionReduced} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionStateReduced} from '../asyncUtilsTypes';
import {IncidentListDTO} from 'hemwb-api';

type FetchedDetail = AsyncActionStateReduced<[string]>;

export const incidentManagementInitialState = {
  detail: {} as {[incidentId: string]: FetchedDetail},
  list: buildInitialAsyncState<IncidentListDTO[]>(),
  incidents: {} as {[incidentId: string]: StoredIncident},
};

export type IncidentManagementState = typeof incidentManagementInitialState;

const insertIncidentInState = (state: IncidentManagementState, incident: IncidentListDTO): IncidentManagementState => {
  return {
    ...state,
    incidents: {
      ...state.incidents,
      [incident.incidentId]: {...incident},
    },
  };
};

export const incidentManagementReducer = (
  state = incidentManagementInitialState,
  action: IncidentManagementAction,
): IncidentManagementState => {
  switch (action.type) {
    case IncidentManagementActions.FETCH_LIST:
      return {
        ...state,
        list: reduceAsyncAction(action),
      };

    case IncidentManagementActions.FETCH_DETAIL: {
      const [incidentId] = action.params;
      return {
        ...(action.phase === AsyncActionPhase.SUCCESS ? insertIncidentInState(state, action.value) : state),
        detail: {
          ...state.detail,
          [incidentId]: reduceAsyncActionReduced(action),
        },
      };
    }

    case IncidentManagementActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        return incidentManagementInitialState;
      }
      return state;
    }

    default:
      return state;
  }
};
