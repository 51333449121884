import React, {RefObject} from 'react';
import {DiffDTO, DiffDTOTagEnum} from 'hemwb-api';
import {formatLine} from './utils';
import clsx from 'clsx';

export type CellMode = 'both' | 'new' | 'old';

type CellProps = {
  cell: DiffDTO;
  mode?: CellMode;
  marked?: boolean;
  cellRef?: RefObject<HTMLTableCellElement>;
};

const Cell: React.FC<CellProps> = ({cell, mode = 'both', marked = false, cellRef, ...restProps}) => {
  if (cell.tag === DiffDTOTagEnum.Equal) {
    return (
      <td ref={cellRef} {...restProps}>
        {cell.oldLine}
      </td>
    );
  }

  if (cell.tag === DiffDTOTagEnum.Plus && mode !== 'old') {
    return (
      <td
        ref={cellRef}
        {...restProps}
        className={clsx({marked: marked})}
        dangerouslySetInnerHTML={{__html: formatLine(cell.newLine, 'new')}}
      />
    );
  }

  if (cell.tag === DiffDTOTagEnum.Minus && mode !== 'new') {
    return (
      <td
        ref={cellRef}
        {...restProps}
        className={clsx({marked: marked})}
        dangerouslySetInnerHTML={{__html: formatLine(cell.oldLine, 'old')}}
      />
    );
  }

  if (cell.tag === DiffDTOTagEnum.Tilde) {
    return (
      <td ref={cellRef} {...restProps} className={clsx({marked: marked})}>
        {mode !== 'new' && <div dangerouslySetInnerHTML={{__html: formatLine(cell.oldLine, 'old')}} />}
        {mode !== 'old' && <div dangerouslySetInnerHTML={{__html: formatLine(cell.newLine, 'new')}} />}
      </td>
    );
  }

  return <td ref={cellRef} {...restProps} className={clsx({marked: marked})} />;
};

function areEqual(prevProps: CellProps, nextProps: CellProps): boolean {
  return (
    prevProps.marked === nextProps.marked &&
    prevProps.cellRef === nextProps.cellRef &&
    prevProps.mode === nextProps.mode
  );
}

export default React.memo(Cell, areEqual);
