export type AsyncActionState<V, P> = {
  value: V | null;
  error: Response | null;
  state: AsyncActionPhase | null;
  params: P;
  timestamp?: number;
};

export type AsyncActionStateReduced<P> = {
  value: boolean;
  error: Response | null;
  state: AsyncActionPhase | null;
  params: P;
  timestamp?: number;
};

export enum AsyncActionPhase {
  START = 'START',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type AsyncStartAction<T extends string, P> = {
  type: T;
  phase: AsyncActionPhase.START;
  params: P;
};

export type AsyncSuccessAction<T extends string, V, P> = {
  type: T;
  phase: AsyncActionPhase.SUCCESS;
  value: V;
  params: P;
};

export type AsyncFailureAction<T extends string, P> = {
  type: T;
  phase: AsyncActionPhase.FAILURE;
  error: Response;
  params: P;
};

export type AsyncClearAction<T extends string, P> = {
  type: T;
  phase: undefined;
  clear: true;
  params: P;
};

export type AsyncAction<T extends string, V, P extends any = void> =
  | AsyncStartAction<T, P>
  | AsyncSuccessAction<T, V, P>
  | AsyncFailureAction<T, P>
  | AsyncClearAction<T, P>;
