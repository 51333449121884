import React from 'react';
import styles from './Table.module.scss';
import printStyles from '../VisualizationStep3.module.scss';
import clsx from 'clsx';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Download16} from '@carbon/icons-react';
import {VisualizationResultsTableElement} from '../../../../../store/visualization';
import {useExcelExport} from '../../common/VisualizationResultsExportExcel/useExcelExport';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';

export type TableExportProps = {
  model?: ModelDTO;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDTO;
  elementIndex: number;
};

type TableProps = VisualizationResultsTableElement &
  TableExportProps & {
    id: string;
  };

const Table: React.FC<TableProps> = ({data, options, id, model, subModel, scenario, elementIndex}) => {
  const {exportExcel, exportExcelInProgress} = useExcelExport(model, subModel, scenario);

  const rowsCount =
    data.reduce((acc: any, val: any) => {
      if (val.row > acc) {
        return val.row;
      }
      return acc;
    }, 0) + 1;

  const colsCount =
    data.reduce((acc: any, val: any) => {
      if (val.column > acc) {
        return val.column;
      }
      return acc;
    }, 0) + 1;

  let colSpanContinueOnIndex: number | undefined;

  const renderCell = (column: number, row: number) => {
    const cell = data.find((c: any) => c.row === row && c.column === column);

    let props;
    if (cell) {
      if (cell.colSpan) {
        colSpanContinueOnIndex = cell.colSpan + column;
      }

      props = {
        ...(cell.colSpan ? {colSpan: cell.colSpan * 2} : {}),
        ...(cell.align ? {align: cell.align} : {}),
      };
    }

    const Component = cell?.th ? 'th' : 'td';

    const isNumber = typeof cell?.value === 'number';
    const cellNumberValueAsString = typeof cell?.value === 'number' && cell!.value!.toString();

    if (cellNumberValueAsString && !cell?.align && cellNumberValueAsString.indexOf('e') === -1) {
      const parts = cell!.value!.toString().split('.');
      return (
        <React.Fragment key={column}>
          <Component className={clsx('leftPartCell')}>
            {cell!.valuePrefix}
            {parts[0]}
          </Component>
          <Component className={clsx('rightPartCell')}>
            {parts[1] && '.'}
            {parts[1]}
            {cell!.valuePostfix}
          </Component>
        </React.Fragment>
      );
    }

    const style = {
      ...(cell?.indent !== undefined
        ? {
            paddingLeft: 4 + cell.indent * 5,
            textAlign: 'left',
          }
        : {}),
      ...(isNumber
        ? {
            textAlign: cell?.align || 'right',
            whiteSpace: 'nowrap',
          }
        : {}),
    };

    return (
      <Component
        key={column}
        colSpan={2}
        style={style}
        {...props}
        dangerouslySetInnerHTML={{__html: cell?.value?.toString() || ''}}
      />
    );
  };

  return (
    <>
      <div className={clsx(styles.wrapper, printStyles.table_wrapper)}>
        <div className={styles.header}>
          <h3 tabIndex={0} id={id}>
            {options.title}
          </h3>
          <SubmitButton
            size="small"
            onClick={() => exportExcel(elementIndex)}
            active={exportExcelInProgress}
            endIcon={<Download16 />}
            className="noPrint">
            Download Excel
          </SubmitButton>
        </div>
      </div>
      <div className={clsx(styles.wrapper, printStyles.table_wrapper)}>
        <table className={clsx(styles.table)}>
          {options.tableHeadTillRow !== undefined && (
            <thead>
              {Array.from(Array(rowsCount)).map((val, row) => {
                if (row > options.tableHeadTillRow!) {
                  return null;
                }

                colSpanContinueOnIndex = undefined;
                return (
                  <tr key={row}>
                    {Array.from(Array(colsCount)).map((val, column) => {
                      if (colSpanContinueOnIndex && colSpanContinueOnIndex > column) {
                        return null;
                      }

                      return renderCell(column, row);
                    })}
                  </tr>
                );
              })}
            </thead>
          )}

          <tbody>
            {Array.from(Array(rowsCount)).map((val, row) => {
              if (options.tableHeadTillRow !== undefined && row <= options.tableHeadTillRow) {
                return null;
              }

              colSpanContinueOnIndex = undefined;
              return (
                <tr key={row}>
                  {Array.from(Array(colsCount)).map((val, column) => {
                    if (colSpanContinueOnIndex && colSpanContinueOnIndex > column) {
                      return null;
                    }

                    return renderCell(column, row);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {options.footnotes && (
          <div className={styles.footNotesContainer}>
            {options.footnotes?.map((footNote, index) => (
              <p key={index} dangerouslySetInnerHTML={{__html: footNote}} />
            ))}
          </div>
        )}

        {options.caption && (
          <div className={styles.captionContainer}>
            <p dangerouslySetInnerHTML={{__html: options.caption}} />
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
