import React from 'react';
import {SubModelActionProps} from '../../actionMenuTypes';
import {useLocation} from 'react-router';
import {getUrlRoute, Routes} from '../../../router/routes';
import SideBarLinkItem from '../../../layout/common/SideBarLinkItem';
import {tid} from '../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../test/types';
import {ListNumbered16} from '@carbon/icons-react';

const ScenarioListActionMenuLink: React.FC<Partial<SubModelActionProps>> = ({model, subModel}) => {
  const location = useLocation();
  const link =
    model && subModel
      ? getUrlRoute(Routes.SCENARIO_LIST, {
          modelUuid: model.uuid,
          subModelId: subModel.id,
        })
      : '#';

  return (
    <SideBarLinkItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_LIST)}
      selected={location.pathname === link}
      disabled={!model || !subModel}
      linkTo={link}
      icon={<ListNumbered16 />}
      text="List"
    />
  );
};

export default ScenarioListActionMenuLink;
