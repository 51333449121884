import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {tid} from '../../../../../testUtils';
import CheckboxControl from '../../../../core/Form/CheckboxControl';
import {Form, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {useMounted} from '../../../../../hooks/useMounted';
import {modelDeleteDraft} from '../../../../../store/model';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {useHistory} from 'react-router';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {ModelActionProps} from '../../../actionMenuTypes';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {TrashCan16, TrashCan32} from '@carbon/icons-react';

const ModelDeleteDraftSideBarModal: React.FC<ModelActionProps> = ({onCancel, onSuccess, model}) => {
  const {uuid} = model;
  const dispatch = useDispatch();
  const mounted = useMounted();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    return modelDeleteDraft(dispatch, uuid)
      .then(() => {
        dispatch(messageAdd(`Model has been deleted`, MessageTypes.SUCCESS));
        onSuccess?.();
      })
      .catch(() => null)
      .finally(() => {
        history.push(getUrlRoute(Routes.MODEL_LIST));
        mounted.current && setSubmitting(false);
      });
  }, [dispatch, history, uuid, mounted, onSuccess]);

  const form = useForm(
    new FormGroup({
      agree: new FormControl([], [Validators.required]),
    }),
  );

  return (
    <>
      <ActionHeader label="Delete model" Icon={<TrashCan32 />}>
        This operation can&apos;t be undone
      </ActionHeader>

      <Form
        className=""
        group={form}
        render={({valid}) => {
          return (
            <>
              <CheckboxControl
                name="agree"
                layout="checkboxTop"
                options={['confirm']}
                getOptionLabel={() => 'I confirm that this model will be permanently deleted alongside with all files.'}
                customErrorMessages={{required: 'You have to check confirmation checkbox'}}
              />
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton
                  onClick={onCancel}
                  className="redButton"
                  {...tid(MODEL_ACTION_MENU_IDS.DELETE_DRAFT, 'cancel')}
                />
                <SubmitButton
                  onClick={handleSubmit}
                  active={submitting}
                  disabled={!valid}
                  className="redButton"
                  endIcon={<TrashCan16 />}
                  {...tid(MODEL_ACTION_MENU_IDS.DELETE_DRAFT, 'submit')}>
                  Delete model
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default ModelDeleteDraftSideBarModal;
