import React, {useMemo} from 'react';
import {Routes} from '../../../../router/routes';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import styles from '../../../../layout/common/NavBar.module.scss';
import {Tab, Tabs} from '@material-ui/core';
import {tid} from '../../../../../testUtils';

const tabLinks: [string, Routes][] = [
  ['Manage Metadata', Routes.MANAGE_METADATA],
  ['Internal Users', Routes.USER_LIST],
  ['Incident Management', Routes.INCIDENT_MANAGEMENT],
  ['External Users', Routes.ADMIN_EXTERNALIZATION_LIST],
];

const AdminNavBar: React.FC = () => {
  const {pathname} = useLocation();
  const activeTabIndex = useMemo(() => {
    return tabLinks.findIndex((tabLink) => pathname.startsWith(tabLink[1]));
  }, [pathname]);

  return (
    <div className="centerFlex">
      <Tabs
        value={activeTabIndex !== -1 ? activeTabIndex : false}
        classes={{indicator: styles.indicator}}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth">
        {tabLinks.map(([label, url]) => (
          <Tab
            {...tid('navbar', ...label.toLocaleLowerCase().split(' '))}
            key={url}
            label={label}
            component={RouterLink}
            to={url}
            classes={{root: styles.navButton}}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default AdminNavBar;
