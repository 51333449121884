import React from 'react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useHistory} from 'react-router-dom';
import styles from './SubModelSectionHeader.module.scss';
import {Container, Grid} from '@material-ui/core';
import {getUrlRoute, Routes, SubModelSection} from '../../../../router/routes';
import ModelTextOverviewTile from '../../../model/common/ModelTextOverview/ModelTextOverviewTile';
import clsx from 'clsx';
import {ChartBubbleLine, Microorganisms} from '@carbon/pictograms-react';
import {DataVis_332 as DataVis332} from '@carbon/icons-react';

const SubModelSectionHeader: React.FC<Partial<ScenarioActionProps> & {title?: string; section: SubModelSection}> = ({
  model,
  subModel,
  section,
  scenario,
  title,
}) => {
  const history = useHistory();
  const displaySubModel = () => {
    return section === SubModelSection.DISPLAY ? 'Display Management' : 'Scenario Management';
  };
  if (!model) {
    return null;
  }

  return (
    <Container maxWidth={false} className={styles.modelInfoContainer}>
      <div className="headlineBlue">
        <strong>
          {section === SubModelSection.CALIBRATION ? 'Calibration Management' : displaySubModel()} / {title}
        </strong>
      </div>
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
        <div
          onClick={() => history.push(getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid}))}
          style={{cursor: 'pointer'}}>
          <ModelTextOverviewTile
            label="Model Name"
            value={model.name}
            Icon={<ChartBubbleLine />}
            className={clsx(styles.tileModelName, styles.tile)}
          />
        </div>

        {section && subModel && (
          <ModelTextOverviewTile
            label="Sub-model"
            value={subModel.name}
            Icon={<Microorganisms />}
            className={styles.tile}
          />
        )}

        {subModel && scenario && (
          <div
            onClick={() =>
              history.push(
                getUrlRoute(Routes.SCENARIO_PARAMETERS_EDITOR, {
                  modelUuid: model.uuid,
                  scenarioId: scenario.id,
                }),
              )
            }
            style={{cursor: 'pointer'}}>
            <ModelTextOverviewTile
              label="Instance"
              value={scenario.name}
              Icon={<DataVis332 />}
              className={styles.tile}
            />
          </div>
        )}
      </Grid>
      <br />
    </Container>
  );
};

export default SubModelSectionHeader;
