import React, {useCallback, useState} from 'react';
import {getUrlRoute, Routes, useMatchedRoute} from '../../../router/routes';
import {useModel} from '../../../../store/model';
import {scenarioUpdateInputs, useScenarioDetail} from '../../../../store/scenario';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {useExecutionSetup} from '../../../../store/executionSetup';
import {useModelInput} from '../../../../store/modelInputs';
import {ModelInputType} from 'hemwb-api';
import ScenarioParametersBuilder from './ScenarioParametersBuilder';
import ScenarioParametersJSONEditor from './ScenarioParametersJSONEditor';
import {useLastValidValue} from '../../../../hooks/useLastValidValue';
import {messageAdd, MessageTypes} from '../../../../store/message';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../hooks/useMounted';
import {useHistory} from 'react-router';
import {canEditInstance} from '../../../../permissions/usePermissions';
import {trackInstanceParametersUpdated} from '../../../../tracking/tracking';
import DefaultPage from '../../../layout/common/DefaultPage';

const ScenarioParametersEditor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useMounted();
  const {modelUuid, scenarioId} = useMatchedRoute(Routes.SCENARIO_PARAMETERS_EDITOR);

  const model = useModel(modelUuid);
  const _scenario = useScenarioDetail(Number(scenarioId));
  const scenario = useLastValidValue(_scenario);
  const subModel = useExecutionSetup(scenario?.executionSetupId, modelUuid);
  const input = useModelInput(scenario?.executionSetupId);

  const [submitting, setSubmitting] = useState(false);

  const handleCancel = useCallback(() => {
    if (scenario && subModel) {
      history.push(
        getUrlRoute(Routes.SCENARIO_LIST, {
          modelUuid,
          subModelId: scenario.executionSetupId,
        }),
      );
    }
  }, [history, modelUuid, subModel, scenario]);

  const handleSubmit = useCallback(
    async (value: string) => {
      setSubmitting(true);
      try {
        await scenarioUpdateInputs(dispatch, scenario!.id, {
          ...scenario!.input,
          value,
          addNewInstance: false,
        });
        model && subModel && scenario && trackInstanceParametersUpdated(model, subModel, scenario);
        dispatch(messageAdd('Scenario Parameters have been updated', MessageTypes.SUCCESS));
      } catch (e) {
      } finally {
        mounted.current && setSubmitting(false);
      }
    },
    [dispatch, mounted, model, subModel, scenario],
  );

  if (!model || !subModel || !input || !scenario) {
    return (
      <DefaultPage>
        <SuspenseNull />
      </DefaultPage>
    );
  }

  if (input.type === ModelInputType.JSONTEMPLATE) {
    return (
      <ScenarioParametersBuilder
        model={model}
        subModel={subModel}
        input={input}
        scenario={scenario}
        onCancel={handleCancel}
        onSubmit={canEditInstance(model, subModel, scenario) ? handleSubmit : undefined}
        submitting={submitting}
      />
    );
  }

  if (input.type === ModelInputType.JSON) {
    return (
      <ScenarioParametersJSONEditor
        model={model}
        subModel={subModel}
        input={input}
        scenario={scenario}
        onCancel={handleCancel}
        onSubmit={canEditInstance(model, subModel, scenario) ? handleSubmit : undefined}
        submitting={submitting}
      />
    );
  }

  return null;
};

export default ScenarioParametersEditor;
