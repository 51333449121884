import {AsyncAction} from '../asyncUtilsTypes';
import {Await} from '../types';
import {requestLatestNotifications, deleteNotifications} from './api';
import {NotificationDTO} from 'hemwb-api';

export enum notificationActions {
  UPDATE_LIST = 'NOTIFICATION/UPDATE_LIST',
  RESET_FIELD = 'NOTIFICATION/RESET_FIELD',
  NOTIFICATIONS_LIST = 'NOTIFICATION/NOTIFICATIONS_LIST',
  REQUEST_DELETE = 'NOTIFICATION/REQUEST_DELETE',
}

export type NotificationUpdateList = {
  type: notificationActions.UPDATE_LIST;
  newNotification: NotificationDTO;
};

export type ResetLatestNotificationFiled = {
  type: notificationActions.RESET_FIELD;
};

export type notificationAction =
  | AsyncAction<
      notificationActions.REQUEST_DELETE,
      Await<ReturnType<typeof deleteNotifications>>,
      Parameters<typeof deleteNotifications>
    >
  | AsyncAction<
      notificationActions.NOTIFICATIONS_LIST,
      Await<ReturnType<typeof requestLatestNotifications>>,
      Parameters<typeof requestLatestNotifications>
    >
  | ResetLatestNotificationFiled
  | NotificationUpdateList;
