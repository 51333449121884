import {appDataSelector} from './selectors';
import {appDataLoadSettings, appDataLoadVersion} from './actions';
import {useSimpleReduxLoader} from '../useReduxLoader';
import {RootState} from '../rootReducer';

export const useSettings = () => {
  return useSimpleReduxLoader((state: RootState) => appDataSelector(state).settings, appDataLoadSettings);
};

export const useVersion = () => {
  return useSimpleReduxLoader((state: RootState) => appDataSelector(state).version, appDataLoadVersion);
};
