/* eslint-disable @typescript-eslint/camelcase */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {modelHasValidBitbucketMetadata, useBitbucketProjects, useBitbucketRepositories} from '../../../store/bitbucket';
import {AutocompleteControl, Form, useForm} from '../Form';
import {FormControl, FormGroup} from 'react-reactive-form';
import BitbucketFileTree from './BitbucketFileTree';
import clsx from 'clsx';
import formStyles from '../Form/Form.module.scss';
import {Grid} from '@material-ui/core';
import GridItem from '../Form/GridItem';
import styles from './BitBucketBrowser.module.scss';
import {ModelDTO} from 'hemwb-api';

type BitbucketBrowserProps = {
  model: ModelDTO;
  updateModel: (model: ModelDTO) => void;
};

const BitbucketBrowser: React.FC<BitbucketBrowserProps> = ({model, updateModel}) => {
  const [forceMetadata, setForceMetadata] = useState(modelHasValidBitbucketMetadata(model));
  const formInstance = useRef<FormGroup>();
  const [, setRefresh] = useState({});

  const projectName = formInstance.current?.controls.project.value?.key;
  const repoName = formInstance.current?.controls.repository.value?.slug;

  const projects = useBitbucketProjects();
  const repositories = useBitbucketRepositories(
    forceMetadata ? model.metadata.bitbucket_project : formInstance.current?.controls.project.value?.key,
  );

  const form = useForm(
    new FormGroup({
      project: new FormControl(null),
      repository: new FormControl(null),
    }),
  );

  useEffect(() => {
    if (form && forceMetadata) {
      if (!projects || !repositories) {
        form.disable();
      } else {
        const project = projects.find((p) => p.key === model.metadata.bitbucket_project);
        if (project) {
          form.controls.project.setValue(project);
          const repository = repositories.find((p) => p.slug === model.metadata.bitbucket_repository);

          if (repository) {
            form.controls.repository.setValue(repository);
          }
        }

        form.enable();
        setForceMetadata(false);
      }
    }
  }, [form, forceMetadata, model, repositories, projects]);

  useEffect(() => {
    if (form && !forceMetadata) {
      const refresh = () => setRefresh({});
      const resetRepository = () => form.controls.repository.setValue(null);
      formInstance.current = form;
      form.controls.project.valueChanges.subscribe(resetRepository);
      form.controls.project.valueChanges.subscribe(refresh);
      form.controls.repository.valueChanges.subscribe(refresh);

      return () => {
        form.controls.project.valueChanges.unsubscribe(resetRepository);
        form.controls.project.valueChanges.unsubscribe(refresh);
        form.controls.repository.valueChanges.unsubscribe(refresh);
      };
    }
  }, [form, forceMetadata]);

  const handlePathChange = useCallback(
    (path: string) => {
      const modelClone: ModelDTO = {
        ...model,
        metadata: {
          ...model.metadata,
          bitbucket_project: formInstance.current?.controls.project.value?.key,
          bitbucket_repository: formInstance.current?.controls.repository.value?.slug,
          bitbucket_commit: '',
        },
      };

      if (path === modelClone.metadata.bitbucket_path) {
        modelClone.metadata.bitbucket_path = '';
      } else {
        modelClone.metadata.bitbucket_path = path;
      }

      updateModel(modelClone);
    },
    [model, updateModel],
  );

  return (
    <div className={styles.container}>
      <Form
        className=""
        group={form}
        FieldGroupProps={{strict: false}}
        render={() => {
          return (
            <div className={clsx(formStyles.form)}>
              <Grid container direction="row" alignItems="flex-start" justify="space-between">
                <GridItem>
                  <AutocompleteControl
                    name="project"
                    strict={false}
                    label="Project"
                    loading={projects === null}
                    disabled={projects === null}
                    AutocompleteProps={{
                      options: projects || [],
                      getOptionLabel: (option) => option.name,
                    }}
                  />
                </GridItem>
                <GridItem>
                  <AutocompleteControl
                    name="repository"
                    strict={false}
                    label="Repository"
                    disabled={repositories === null}
                    loading={repositories === null && !!projectName}
                    AutocompleteProps={{
                      options: repositories || [],
                      getOptionLabel: (option) => option.name,
                    }}
                  />
                </GridItem>
              </Grid>
            </div>
          );
        }}
      />
      {projectName && repoName && (
        <BitbucketFileTree
          projectName={projectName}
          repoName={repoName}
          model={model}
          onPathChange={handlePathChange}
        />
      )}
    </div>
  );
};

export default BitbucketBrowser;
