import UserSetActiveDialog, {UserSetActiveDialogProps} from './UserSetActiveDialog';
import React, {useMemo, useState} from 'react';
import {trackUserActivated, trackUserDeactivated} from '../../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useDispatch} from 'react-redux';

type UserSetActiveDialogOpenProps = Omit<UserSetActiveDialogProps, 'onCancel' | 'open'> & {
  onCancel?: UserSetActiveDialogProps['onCancel'];
};

export const useUserSetActiveDialog = () => {
  const dispatch = useDispatch();
  const [openProps, setOpenProps] = useState<UserSetActiveDialogOpenProps>();
  const UserSetActiveDialogComponent = useMemo(
    () =>
      !openProps ? null : (
        <UserSetActiveDialog
          open={true}
          user={openProps.user}
          onSubmit={() => {
            return (openProps?.onSubmit() || Promise.resolve()).then(() => {
              setOpenProps(undefined);
              if (openProps.user.active) {
                dispatch(messageAdd('User Deactivated', MessageTypes.SUCCESS));
                trackUserDeactivated();
              } else {
                dispatch(messageAdd('User Activated', MessageTypes.SUCCESS));
                trackUserActivated();
              }
            });
          }}
          onCancel={() => {
            openProps.onCancel && openProps.onCancel();
            setOpenProps(undefined);
          }}
        />
      ),
    [openProps, setOpenProps, dispatch],
  );

  return {
    openUserSetActiveDialog: setOpenProps as (props: UserSetActiveDialogOpenProps) => void,
    UserSetActiveDialog: UserSetActiveDialogComponent,
  };
};
