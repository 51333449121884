import {ModelInputDetailDTO, ModelInputType} from 'hemwb-api';
import {readFileAsync} from '../../../utils/readFileAsync';
import {parseFileExtension} from '../../../store/modelFile/utils';
import {importFromXML} from '../../core/ModelInput/ScenarioParameters/importFromXML';
import {formatInput} from '../../core/ModelInput/ScenarioParameters/formatInput';

export const parseScenarioParametersFile = async (
  input: ModelInputDetailDTO,
  file?: File,
): Promise<object | undefined> => {
  if (file) {
    const fileContent = await readFileAsync(file);
    const extension = parseFileExtension(file.name);

    if (extension === 'json') {
      const parsedJson = JSON.parse(fileContent as string);

      if (input.type === ModelInputType.JSONTEMPLATE) {
        const definitions = JSON.parse(input.value)?.definitions || [];
        return Promise.resolve(formatInput(definitions, parsedJson));
      } else if (input.type === ModelInputType.JSON) {
        return Promise.resolve(parsedJson);
      }
    } else if (extension === 'xml') {
      if (input.type === ModelInputType.JSONTEMPLATE) {
        const definitions = JSON.parse(input.value)?.definitions || [];
        return Promise.resolve(importFromXML(fileContent as string, definitions));
      }
    }

    throw new Error('Unsupported File Extension');
  }

  return Promise.resolve(undefined);
};
