import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {SubModelActionProps} from '../../../actionMenuTypes';
import useScenarioAddSideBarModal from './useScenarioAddSideBarModal';
import {canAddInstance} from '../../../../../permissions/usePermissions';
import {AddAlt16} from '@carbon/icons-react';

const ScenarioAddActionMenuButton: React.FC<Partial<SubModelActionProps>> = (props) => {
  const {ModalComponent, openModal} = useScenarioAddSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ADD)}
        permission={canAddInstance(props.model, props.subModel)}
        onClick={openModal}
        icon={<AddAlt16 />}
        text="Add"
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioAddActionMenuButton;
