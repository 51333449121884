import React, {useCallback, useEffect, useState} from 'react';
import {ModelFileUploaded, TreeNode} from '../../../store/modelFile';
import {FileTreeProps, ModelFileAuditedDTOWithButton} from './types';
import {Folders24, Folder24, Development24} from '@carbon/icons-react';
import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_STRING_OPTIONS,
  TO_UTC_DATE_STRING_OPTIONS,
} from '../TableWithSortAndPagination';
import {Order, useGridState} from '../../../store/grid';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridItem from '../Form/GridItem';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {useModelMetadataActiveOptions} from '../../../store/modelMetadata';
import Checkbox from '@material-ui/core/Checkbox';

export type DirNodeProps<N> = FileTreeProps<N> & {
  isRoot?: boolean;
  node: TreeNode;
  externalModel?: boolean;
  webModel?: boolean;
  theoremModel?: boolean;
  createFlow?: boolean;
  uploadFlow?: boolean;
  cloneFlow?: boolean;
  setSpecificFiles?: (uuids: string[], checked: boolean) => void;
  unSelectAll?: boolean;
};

const Icon = Development24;

const FileTypeTag: React.FC<{file: ModelFileAuditedDTOWithButton}> = ({file}) => (
  <>
    <span>{file.fileTag}</span>
  </>
);

const FileNameTag: React.FC<{file: ModelFileAuditedDTOWithButton}> = ({file}) => (
  <>
    <Icon className="nodeIcon" />
    <span>{file.name}</span>
  </>
);

const displayFileName = (file: ModelFileAuditedDTOWithButton) => <FileNameTag file={file} />;
const displayFileType = (file: ModelFileAuditedDTOWithButton) => <FileTypeTag file={file} />;

function DirNode<N extends TreeNode>(props: DirNodeProps<N>) {
  const {
    files,
    node,
    isRoot = false,
    onDrop,
    renderButtons,
    defaultFolderOpen = true,
    onFolderOpen,
    onRowClick,
    externalModel,
    webModel,
    theoremModel,
    createFlow,
    uploadFlow,
    cloneFlow,
    handleFileTag,
    setSpecificFiles,
    unSelectAll,
    clonedModel,
  } = props;

  const options = useModelMetadataActiveOptions();
  const filter = createFilterOptions();
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (unSelectAll) {
      setSelectAll(false);
    }
  }, [unSelectAll]);

  const displayDropdown = (file: ModelFileAuditedDTOWithButton) => <DropDownList file={file} />;

  const fileTagAutocomplete = (file: ModelFileAuditedDTOWithButton) => {
    return (
      <Autocomplete
        disableClearable
        data-testid={'file.fileTag-' + file.fileTag}
        style={{width: 300}}
        value={file.fileTag}
        onChange={(event: any, newValue: string | null) => {
          if (newValue && handleFileTag) {
            const replacedValue = newValue.replace(' (Create New)', '');
            handleFileTag(file as ModelFileUploaded, replacedValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push(`${params.inputValue} (Create New)`);
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option;
        }}
        renderOption={(option) => option}
        options={options?.fileTag}
        renderInput={(params) => (
          <TextField
            name="fileTag"
            style={{width: 300}}
            {...params}
            inputProps={{...params.inputProps, maxLength: 25}}
            onKeyDown={(event) => {
              if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        )}
      />
    );
  };

  const clonedModelFileTags = (file: ModelFileAuditedDTOWithButton) => {
    return !file.uploaded ? (
      fileTagAutocomplete(file)
    ) : (
      <TextField disabled name="fileTag" style={{width: 300}} value={file.fileTag} />
    );
  };

  const DropDownList: React.FC<{file: ModelFileAuditedDTOWithButton}> = ({file}) => (
    <>
      <GridItem>{clonedModel ? clonedModelFileTags(file) : fileTagAutocomplete(file)}</GridItem>
    </>
  );

  const filesGridColumns: Column<ModelFileAuditedDTOWithButton>[] = [
    {id: 'name', label: 'File Name', sortable: false, value: (o) => o.name, display: displayFileName},
    {
      id: 'updatedOn',
      label: 'UpdatedOn',
      sortable: true,
      value: (es) => es.updatedOn,
      display: (es) => es.updatedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      customHeader: {
        heading: 'UpdatedBy',
      },
      sortable: false,
      value: (es) => es.updatedBy || '',
    },
    {
      id: 'fileTag',
      label: 'What type of file is this?',
      sortable: false,
      value: (es) => es.fileTag || '',
      display: displayFileType,
    },
    {
      id: 'button',
      label: ' ',
      sortable: false,
      value: (es) => es.button || '',
    },
  ];

  const createFlowColumns: Column<ModelFileAuditedDTOWithButton>[] = [
    filesGridColumns[0],
    filesGridColumns[1],
    filesGridColumns[2],
    {
      id: 'fileType',
      label: '<div>What type of file is this?</div> <div>Start typing to find and create you own file type</div>',
      sortable: false,
      customHeader: {
        heading: 'What type of file is this ?',
        subHeading: 'Press down to choose a suggestion, or start typing to find and create your own file type',
      },
      value: (es) => es.fileTag || '',
      display: displayDropdown,
    },
    {
      id: 'selected',
      label: 'SelectAll',
      customHeader: {
        heading: (
          <div>
            {' '}
            SelectAll
            <Checkbox
              color="primary"
              checked={selectAll}
              onChange={(event, checked) => {
                setSelectAll(checked);
                if (setSpecificFiles) {
                  if (clonedModel) {
                    setSpecificFiles(
                      childrenFiles
                        .map((item) => (item.uploaded === false ? item.uuid : null))
                        .filter((item) => item !== null) as string[],
                      checked,
                    );
                  } else {
                    setSpecificFiles(
                      childrenFiles.map((file) => file.uuid),
                      checked,
                    );
                  }
                }
              }}
            />
          </div>
        ),
      },
      sortable: false,
      value: (es) => es.selected || '',
    },
    filesGridColumns[4],
  ];

  const uploadFlowColumns: Column<ModelFileAuditedDTOWithButton>[] = [
    filesGridColumns[0],
    createFlowColumns[3],
    createFlowColumns[4],
    filesGridColumns[4],
  ];

  const cloneFlowColumns: Column<ModelFileAuditedDTOWithButton>[] = [
    filesGridColumns[0],
    filesGridColumns[1],
    filesGridColumns[2],
    filesGridColumns[4],
  ];

  const externalModelColumns: Column<ModelFileAuditedDTOWithButton>[] = [
    filesGridColumns[0],
    {
      id: 'updatedOn',
      label: 'Updated On"',
      sortable: true,
      value: (es) => es.updatedOn,
      display: (es) => es.updatedOn?.toLocaleDateString('en-US', TO_UTC_DATE_STRING_OPTIONS),
    },
    filesGridColumns[2],
  ];
  const [isOpen, setOpen] = useState(
    isRoot ||
      defaultFolderOpen === true ||
      (typeof defaultFolderOpen === 'string' && defaultFolderOpen.startsWith(node.path)),
  );
  const gridState = useGridState({orderBy: 'updatedOn', order: Order.ASC});
  const {path, name} = node;

  const currentPathLength = path.length + (isRoot ? 0 : 1);

  const directories: {[dirName: string]: TreeNode} = {};
  const childrenFiles: N[] = [];

  files
    .filter((childNode) => childNode.path.startsWith(isRoot ? '' : `${path}/`))
    .forEach((childNode) => {
      const restPath = childNode.path.substring(currentPathLength);
      const slashIndex = restPath.indexOf('/');
      if (slashIndex === -1) {
        if (childNode.folder) {
          directories[childNode.name] = childNode;
        } else if (clonedModel && childNode.uploaded) {
          childNode.button = null;
          childrenFiles.push(childNode);
        } else {
          childNode.button = renderButtons && renderButtons(childNode);
          childrenFiles.push(childNode);
        }
        return;
      }
      const dirName = restPath.substring(0, slashIndex);
      if (dirName === '' && childNode.folder) {
        childNode.button = renderButtons && renderButtons(childNode);
        directories[childNode.name] = childNode;
      }
      if (dirName !== '' && !directories[dirName]) {
        const pathName = `${path}/`;
        const dirPath = `${path === '' ? '' : pathName}${dirName}`;
        directories[dirName] = {
          uploaded: false,
          folder: true,
          name: dirName,
          path: dirPath,
          uuid: dirPath,
        };
      }
    });

  const handleToggleOpen = useCallback(() => {
    if (!isOpen) {
      onFolderOpen?.(node);
    }
    setOpen(!isOpen);
  }, [isOpen, onFolderOpen, node]);

  let FolderIcon = Folder24;
  if (isOpen && childrenFiles.length === 0 && Object.values(directories).length === 0 && node.contentLoaded !== false) {
    FolderIcon = Folders24;
  } else if (isOpen && node.contentLoaded === false) {
    FolderIcon = Folders24;
  } else if (isOpen) {
    FolderIcon = Folder24;
  }

  const arrows = useCallback(
    (isOpen: boolean) => {
      return isOpen ? (
        <KeyboardArrowDownIcon onClick={handleToggleOpen} />
      ) : (
        <KeyboardArrowUpIcon onClick={handleToggleOpen} />
      );
    },
    [handleToggleOpen],
  );

  const displayCloneColumns = () => {
    return cloneFlow ? cloneFlowColumns : filesGridColumns;
  };
  const displayUploadColumns = () => {
    return uploadFlow ? uploadFlowColumns : displayCloneColumns();
  };
  const displayCreateColumns = () => {
    return createFlow ? createFlowColumns : displayUploadColumns();
  };

  return (
    <>
      {isRoot && (webModel || theoremModel) && (
        <>
          <FolderIcon onClick={handleToggleOpen} className="nodeIcon rootNodeIcon" />
          {arrows(isOpen)}
        </>
      )}
      {!isRoot && (
        <div className="treeNode">
          <FolderIcon onClick={handleToggleOpen} className="nodeIcon" />
          <span data-testid="file-name" className="fileName">
            {name} {arrows(isOpen)}
          </span>
        </div>
      )}
      {isOpen && (
        <ol>
          {Object.values(directories).map((childNode) => {
            return (
              <li
                key={childNode.uuid}
                onDrop={(event: React.DragEvent) => {
                  if (onDrop) {
                    event.stopPropagation();
                    onDrop(event, childNode);
                  }
                }}>
                <DirNode<N>
                  files={files}
                  handleFileTag={handleFileTag}
                  node={childNode}
                  webModel={webModel}
                  onDrop={onDrop}
                  renderButtons={renderButtons}
                  defaultFolderOpen={defaultFolderOpen}
                  onFolderOpen={onFolderOpen}
                  onRowClick={onRowClick}
                  setSpecificFiles={setSpecificFiles}
                  createFlow={createFlow}
                  uploadFlow={uploadFlow}
                  cloneFlow={cloneFlow}
                  unSelectAll={unSelectAll}
                  clonedModel={clonedModel}
                />
              </li>
            );
          })}
          {childrenFiles.length !== 0 && (
            <TableWithSortAndPagination<ModelFileAuditedDTOWithButton>
              getRowId={(o) => o.uuid}
              onClickRow={onRowClick}
              externalModel={externalModel}
              // @ts-ignore
              rows={childrenFiles}
              columns={externalModel ? externalModelColumns : displayCreateColumns()}
              hidePagination={true}
              gridState={gridState}
            />
          )}
        </ol>
      )}
    </>
  );
}

export default DirNode;
