import React, {useCallback, useEffect, useState} from 'react';
import {Routes, useMatchedRoute} from '../../../router/routes';
import {sectionToType, useExecutionSetupList} from '../../../../store/executionSetup';
import {useModel} from '../../../../store/model';
import {Order, useGridState} from '../../../../store/grid';
import {useLastValidValue} from '../../../../hooks/useLastValidValue';
import {useToggleSelected} from '../../../../hooks/useToggleSelected';
import {ExecutionSetupDTO} from 'hemwb-api';
import TableWithSortAndPagination, {
  Column,
  TO_LOCALE_DATE_STRING_OPTIONS,
} from '../../../core/TableWithSortAndPagination';
import DefaultPage from '../../../layout/common/DefaultPage';
import {fullName} from '../../../../store/user/utils';
import SubModelActionMenu from '../common/SubModelActionMenu/SubModelActionMenu';
import SubModelSectionHeader from '../common/SubModelSectionHeader/SubModelSectionHeader';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';

export const subModelGridColumns: Column<ExecutionSetupDTO>[] = [
  {id: 'name', label: 'Name', sortable: true, value: (o) => o.name},
  {
    id: 'createdBy',
    label: 'Created by',
    sortable: true,
    value: (es) => (es.createdBy ? fullName(es.createdBy) : ''),
  },
  {
    id: 'createdOn',
    label: 'Created',
    sortable: true,
    value: (es) => es.createdOn,
    display: (es) => es.createdOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
  },

  {
    id: 'modifiedBy',
    label: 'Modified by',
    sortable: true,
    value: (es) => (es.modifiedBy ? fullName(es.modifiedBy) : ''),
  },
  {
    id: 'modifiedOn',
    label: 'Modified on',
    sortable: true,
    value: (es) => es.modifiedOn,
    display: (es) => es.modifiedOn?.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS),
  },
];

const SubModelList: React.FC = () => {
  const {modelUuid, section} = useMatchedRoute(Routes.SUBMODEL_LIST);
  const type = sectionToType(section);
  const model = useModel(modelUuid);
  const list = useExecutionSetupList(modelUuid, type);
  const gridState = useGridState({orderBy: 'name', order: Order.ASC});
  const storedList = useLastValidValue(list);

  const {selected, toggleSelected} = useToggleSelected();
  const handleClickRow = useCallback(
    (row: ExecutionSetupDTO) => {
      toggleSelected(row.id);
    },
    [toggleSelected],
  );

  const [selectedSubModel, setSelectedSubModel] = useState<ExecutionSetupDTO>();
  useEffect(() => {
    if (selected) {
      const found = storedList?.find((o) => o.id === selected);
      if (found) {
        setSelectedSubModel(found);
        return;
      }
    }
    setSelectedSubModel(undefined);
  }, [selected, storedList]);

  return (
    <>
      <DefaultPage>
        <SuspenseNull>
          {model && section && (
            <>
              <SubModelSectionHeader model={model} section={section} title="Sub-model list" />
              <TableWithSortAndPagination<ExecutionSetupDTO>
                getRowId={(o) => o.id}
                rows={storedList || []}
                columns={subModelGridColumns}
                onClickRow={handleClickRow}
                isRowSelected={(o) => o?.id === selected}
                gridState={gridState}
                loading={!list}
                noResultsMessage="No SubModel was found"
              />
            </>
          )}
        </SuspenseNull>
      </DefaultPage>
      <SubModelActionMenu model={model} subModel={selectedSubModel} section={section} />
    </>
  );
};

export default SubModelList;
