import React from 'react';
import {DiffDTO} from 'hemwb-api';
import Cell, {CellMode} from './Cell';

type SheetDiffUnchangedRowProps = {
  cells: DiffDTO[] | null;
  mode?: CellMode;
  rowNumber: number | null;
  renderColumnCount: number;
};

const SheetDiffUnchangedRow: React.FC<SheetDiffUnchangedRowProps> = ({
  cells,
  mode = 'both',
  rowNumber,
  renderColumnCount,
}) => {
  const data = cells || [];

  return (
    <tr>
      <th>{rowNumber}</th>
      {data.concat(Array.from(Array(renderColumnCount - data.length))).map((cell: any, cellKey: number) => {
        if (cell) {
          return <Cell key={cellKey} cell={cell} mode={mode} />;
        }

        return <td key={cellKey} />;
      })}
    </tr>
  );
};

function areEqual(prevProps: SheetDiffUnchangedRowProps, nextProps: SheetDiffUnchangedRowProps): boolean {
  return prevProps.mode === nextProps.mode;
}

export default React.memo(SheetDiffUnchangedRow, areEqual);
