import React, {useEffect, useMemo, useRef, useState, useCallback} from 'react';
import DefaultPage from '../../../layout/common/DefaultPage';
import VisualizationStep3ActionMenu from '../step3/VisualizationStep3ActionMenu';
import {Routes, useMatchedRoute} from '../../../router/routes';
import {
  isScenarioDTO,
  isScenarioRunning,
  useScenarioDetail,
  useScenarioDetailLoaderWithParams,
} from '../../../../store/scenario';
import {Tab, Tabs} from '@material-ui/core';
import {ExecutionSetupType, ScenarioDetailDTO} from 'hemwb-api';
import {useModel} from '../../../../store/model';
import {useModelInput} from '../../../../store/modelInputs';
import SideBar from '../../../layout/common/SideBar';
import ScenarioParameters from '../../../core/ModelInput/ScenarioParameters/ScenarioParameters';
import VisualizationStepper, {VisualizationSteps} from '../common/VisualizationStepper';
import {useAnalysisLoader} from '../common/useAnalysisLoader';
import {useSelector} from '../../../../store/rootReducer';
import {visualizationSelector} from '../../../../store/visualization';
import {useAuth} from '../../../../store/auth';
import {useUserExpertise} from '../../../../store/userExpertise';

const VisualizationParametersDisplay: React.FC = () => {
  const {modelUuid, scenarioId} = useMatchedRoute(Routes.VISUALIZATION_PARAMETERS_DISPLAY);
  const loadScenarioDetail = useScenarioDetailLoaderWithParams();

  const [scenarios, setScenarios] = useState<ScenarioDetailDTO[]>([]);

  const detail = useScenarioDetail(scenarioId);
  const {auth} = useAuth();
  const user = auth.loggedInUser;
  const userid = user ? user.id : '';

  const [expertiseLevel, setExpertiseLevel] = useState<number>();

  useEffect(() => {
    detail && setScenarios((s) => [detail, ...s]);
  }, [detail]);

  const selection = useSelector(visualizationSelector).selection;

  useAnalysisLoader(modelUuid, scenarioId);

  useEffect(() => {
    if (selection && selection.scenarios) {
      Promise.all(selection.scenarios.map((s) => loadScenarioDetail([s.id]) as Promise<ScenarioDetailDTO>)).then(
        (details) => {
          setScenarios((s) => [...s, ...details]);
        },
      );
    }
  }, [selection, loadScenarioDetail]);

  const model = useModel(modelUuid) || undefined;

  const input1 = useModelInput(detail?.executionSetupId);
  const input2 = useModelInput(scenarios[1]?.executionSetupId);

  const tabsData: ScenarioDetailDTO[] = scenarios.filter(isScenarioDTO);
  const [tab, setTab] = useState(0);
  const [instancesTab, setInstancesTab] = useState(0);

  const {scenario, definitions, values} = useMemo(() => {
    // const subModel = tab > 0 ? subModel2 : subModel1;
    const input = tab > 0 ? input2 : input1;
    const scenario = tabsData[tab > 0 ? instancesTab + 1 : tab];

    if (!input || !scenario) {
      return {};
    }

    const parsed = input.value ? JSON.parse(input.value) : {};
    const definitions = parsed.definitions || [];
    const values = JSON.parse(scenario.input.value || '{}');

    return {
      // subModel,
      // input,
      scenario,
      definitions,
      values,
    };
  }, [tab, instancesTab, tabsData, input1, input2]);

  const canEnableTab = useCallback(
    (index: number, label: string) => {
      if (index === 0) return false;
      else {
        if (selection?.subModel?.type) {
          return label.toLowerCase().indexOf(selection?.subModel?.type.toLowerCase()) === -1;
        }
      }
    },
    [selection],
  );

  const expertiseLevelObject = useUserExpertise(modelUuid, userid);

  useEffect(() => {
    setExpertiseLevel(Number(expertiseLevelObject?.expertiseTypeLevel?.split('-')[0]));
  }, [expertiseLevelObject]);

  const inputListPortalRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <SideBar
        position="left"
        className="leftSelection"
        style={{boxShadow: '5px 0px 4px rgba(0,0,0,0.3)', position: 'relative', zIndex: 2}}>
        <h3 style={{paddingLeft: 15}}>Parameters</h3>
        <div ref={inputListPortalRef} />
      </SideBar>
      <DefaultPage style={{position: 'relative'}}>
        <VisualizationStepper step={VisualizationSteps.STEP_3} />
        <div style={{marginLeft: '4rem', marginTop: '4rem'}}>
          <Tabs
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newTab: number) => setTab(newTab)}
            indicatorColor="primary"
            textColor="inherit"
            className="tabs-secondary">
            {['Display Parameters', 'Scenario Parameters', 'Calibration Parameters'].map((d, index) => (
              <Tab key={index} value={index} label={d} disabled={canEnableTab(index, d)} />
            ))}
          </Tabs>
          {model && scenario && definitions && values && (
            <div style={{marginTop: '4rem'}}>
              {tab > 0 && (
                <Tabs
                  value={instancesTab}
                  onChange={(event: React.ChangeEvent<{}>, newTab: number) => setInstancesTab(newTab)}
                  indicatorColor="primary"
                  textColor="inherit"
                  className="tabs-secondary">
                  {tabsData.slice(1).map((d, index) => (
                    <Tab key={index + '_' + d} value={index} label={d.name} />
                  ))}
                </Tabs>
              )}

              <ScenarioParameters
                expertiseLevel={expertiseLevel ? expertiseLevel : 500}
                key={scenario.id}
                subModelType={tab === 0 ? ExecutionSetupType.DISPLAY : selection?.subModel?.type!}
                definitions={definitions}
                values={values}
                submitting={false}
                inputListPortalRef={inputListPortalRef}
                renderStatic
                modelUuid={modelUuid}
              />
            </div>
          )}
        </div>
      </DefaultPage>
      <VisualizationStep3ActionMenu
        modelUuid={modelUuid}
        scenarioId={scenarioId}
        model={model}
        subModel={selection?.subModel}
        scenario={scenario}
        scenarioInProgress={isScenarioRunning(scenario?.status)}
      />
    </>
  );
};

export default VisualizationParametersDisplay;
