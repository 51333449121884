import {AsyncAction} from '../asyncUtilsTypes';
import {MacroDiffDTO, SheetDiffDTO, WorkbookItemDTO} from 'hemwb-api';
import {requestContentList, requestMacroDiff, requestSheetDiff} from './api';
import {Action} from 'redux';

export enum ExcelDiffActions {
  REQUEST_CONTENT_LIST = 'EXCELDIFF/REQUEST_CONTENT_LIST',
  REQUEST_SHEET_DIFF = 'EXCELDIFF/REQUEST_SHEET_DIFF',
  REQUEST_MACRO_DIFF = 'EXCELDIFF/REQUEST_MACRO_DIFF',
  CLEAR = 'EXCELDIFF/CLEAR',
}

export type ExcelDiffAction =
  | AsyncAction<ExcelDiffActions.REQUEST_CONTENT_LIST, WorkbookItemDTO[], Parameters<typeof requestContentList>>
  | AsyncAction<ExcelDiffActions.REQUEST_SHEET_DIFF, SheetDiffDTO, Parameters<typeof requestSheetDiff>>
  | AsyncAction<ExcelDiffActions.REQUEST_MACRO_DIFF, MacroDiffDTO, Parameters<typeof requestMacroDiff>>
  | Action<ExcelDiffActions.CLEAR>;
