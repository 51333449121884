import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {authLoggedInUserSelector, authSelector} from './selectors';
import {LoggedInUserDTO, LoginCredentialsDTO} from 'hemwb-api';
import {authLogin, authLogout} from './actions';
import {useSelector} from '../rootReducer';
import {AsyncActionPhase} from '../asyncUtilsTypes';

export const useOnUserChanged = () => {
  const auth = useSelector((state) => authSelector(state));
  const {loggedOutUser} = auth;
  return useCallback(
    (result: any) => {
      if (result.phase === AsyncActionPhase.SUCCESS) {
        localStorage.setItem('authUserId', result.value.id);
        if (
          loggedOutUser &&
          (result.value.id !== loggedOutUser.id || result.value.userType !== loggedOutUser.userType)
        ) {
          window.location.reload();
        }
      }
    },
    [loggedOutUser],
  );
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => authSelector(state));
  const onUserChanged = useOnUserChanged();

  const login = useCallback(
    (credentials: LoginCredentialsDTO) => {
      return authLogin(dispatch, credentials).then(onUserChanged);
    },
    [dispatch, onUserChanged],
  );
  const logout = useCallback(() => {
    // @ts-ignore
    authLogout(dispatch, true);
  }, [dispatch]);

  return {
    auth,
    login,
    logout,
  };
};

export const useCurrentUser = () => {
  return useSelector(authLoggedInUserSelector) || ({} as LoggedInUserDTO);
};
