import React from 'react';
import Suspense from '../../../core/Suspense/Suspense';

const LazyLoaded = React.lazy(() => import('./ScenarioDetail'));

const ScenarioDetailLazy: React.FC = () => {
  return (
    <Suspense>
      <LazyLoaded />
    </Suspense>
  );
};

export default ScenarioDetailLazy;
