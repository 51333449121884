import React from 'react';
import '@carbon/charts/styles.css';
import './Chart.scss';
import styles from './Chart.module.scss';
import printStyles from '../VisualizationStep3.module.scss';
import clsx from 'clsx';
import * as charts from '@carbon/charts-react';
import {VisualizationResultsChartElement} from '../../../../../store/visualization';

const Chart: React.FC<VisualizationResultsChartElement & {id: string}> = ({data, options, chart, id}) => {
  //@ts-ignore
  const ChartComponent = charts[chart];

  return (
    <div tabIndex={0} id={id} className={clsx(styles.chart, printStyles.chart)}>
      <ChartComponent options={options} data={data} />
    </div>
  );
};

export default Chart;
