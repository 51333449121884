import {CustomSettings, CustomSettingsActionInit, CustomSettingsActions, CustomSettingsActionUpdate} from './types';

export const customSettingsInit = (customSettings: Partial<CustomSettings>): CustomSettingsActionInit => ({
  type: CustomSettingsActions.INIT,
  customSettings,
});

export const customSettingsUpdate = (customSettings: Partial<CustomSettings>): CustomSettingsActionUpdate => ({
  type: CustomSettingsActions.UPDATE,
  customSettings,
});
