import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {modelLoadDynamic} from '../../../../store/model';
import {GetModelsForDynamicFiltersRequest, ModelDTO} from 'hemwb-api';
import {Button, Chip, CircularProgress, Container, Grid} from '@material-ui/core';
import styles from './DynamicView.module.scss';
import {tid} from '../../../../testUtils';

import {CloudServices} from '@carbon/pictograms-react';
import PageHeaderTitle from '../../../core/Header/PageHeader/PageHeaderTitle';
import {useAuth} from '../../../../store/auth';
import DynamicViewDropDownController from './DynamicViewDropDownController';
import {dynamicSearchGridId, useStoredGridState} from '../../../../store/grid';
import {DynamicFilterState} from '../filter';
import {useDispatch} from 'react-redux';
import {DialogGeneric} from '../../../core/dialog/DialogGeneric';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ModelVersionControlLineageForDynamicView from '../common/ModelVersionControl/ModelVersionControlLineageForDynamicView';

type DynamicViewProps = {
  handleSelection: (model: ModelDTO) => void;
};

const searchMyModels = (models: ModelDTO[], loggedInUserId: string, viewUserModels: boolean) => {
  if (!loggedInUserId) {
    return models;
  }

  if (viewUserModels) {
    const lcSearch = loggedInUserId.toLocaleLowerCase();
    return models.filter(
      (model) =>
        model.userAccess !== undefined ||
        null ||
        Object.values(model.createdBy).some(
          (value) => !!value && typeof value === 'string' && value.toLocaleLowerCase().includes(lcSearch),
        ),
    );
  } else {
    return models;
  }
};

const LoaderElement: React.FC<{length: number; loading: boolean}> = (props) =>
  props.length > 0 && props.loading ? (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        flexGrow: 1,
      }}>
      <CircularProgress />
    </div>
  ) : null;

const DynamicView: React.FC<DynamicViewProps> = ({handleSelection}) => {
  const gridState = useStoredGridState<DynamicFilterState>(dynamicSearchGridId);
  const [list, setList] = useState<ModelDTO[]>();
  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const {auth} = useAuth();
  const user = auth.loggedInUser;
  const dispatch = useDispatch();
  const [therapeuticAreaRemoveOption, setTherapeuticAreaRemoveOption] = useState<boolean>(false);
  const [diseaseRemoveOption, setDiseaseRemoveOption] = useState<boolean>(false);
  const [trailNumberRemoveOption, setTrailNumberRemoveOption] = useState<boolean>(false);
  const [productRemoveOption, setProductRemoveOption] = useState<boolean>(false);
  const [globalRemoveOption, setGlobalRemoveOption] = useState<boolean>(false);
  const [countryRemoveOption, setCountryRemoveOption] = useState<boolean>(false);
  const [modelTypeRemoveOption, setModelTypeRemoveOption] = useState<boolean>(false);
  const [platformRemoveOption, setPlatformRemoveOption] = useState<boolean>(false);

  const [therapeuticAreaOptions, setTherapeuticAreaOptions] = useState<string[]>([]);
  const [diseaseOptions, setDiseaseOptions] = useState<string[]>([]);
  const [trailNumberOptions, setTrailNumberOptions] = useState<string[]>([]);
  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [globalOptions, setGlobalOptions] = useState<string[]>([]);
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [modelTypeOptions, setModelTypeOptions] = useState<string[]>([]);
  const [platformOptions, setPlatformOptions] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleSelectModel = (model: ModelDTO) => {
    handleSelection(model);
  };

  const parentElement = document.getElementById('searchForm');
  let selectedDropdown = document.getElementsByClassName('therapeutic_area')[0] as HTMLDivElement;
  let element = document.getElementsByClassName('therapeutic_area')[0] as HTMLDivElement;

  const swapElements = useCallback(
    (element: HTMLDivElement, selectedOne: HTMLDivElement) => {
      const tmp = element.cloneNode(false);
      // Replace element with tmp
      parentElement?.replaceChild(tmp, element);
      // Replace selectedDropdown with element
      parentElement?.replaceChild(element, selectedOne);
      // Replace tmp node with selectedDropdown
      parentElement?.replaceChild(selectedOne, tmp);
    },
    [parentElement],
  );

  const reArrangeDivElements = useCallback(() => {
    const listOfDropdowns = document.getElementsByClassName(styles.dropDownWrapper);
    if (listOfDropdowns.length === 7) {
      if (!listOfDropdowns[0].classList.contains('therapeutic_area')) {
        swapElements(
          listOfDropdowns[0] as HTMLDivElement,
          document.getElementsByClassName('therapeutic_area')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[1].classList.contains('disease')) {
        swapElements(
          listOfDropdowns[1] as HTMLDivElement,
          document.getElementsByClassName('disease')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[2].classList.contains('study_numbers')) {
        swapElements(
          listOfDropdowns[2] as HTMLDivElement,
          document.getElementsByClassName('study_numbers')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[3].classList.contains('products')) {
        swapElements(
          listOfDropdowns[3] as HTMLDivElement,
          document.getElementsByClassName('products')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[4].classList.contains('global_adaptation')) {
        swapElements(
          listOfDropdowns[4] as HTMLDivElement,
          document.getElementsByClassName('global_adaptation')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[5].classList.contains('countries')) {
        swapElements(
          listOfDropdowns[5] as HTMLDivElement,
          document.getElementsByClassName('countries')[0] as HTMLDivElement,
        );
      }
      if (!listOfDropdowns[6].classList.contains('model_types')) {
        swapElements(
          listOfDropdowns[6] as HTMLDivElement,
          document.getElementsByClassName('model_types')[0] as HTMLDivElement,
        );
      }
      parentElement?.appendChild(document.getElementsByClassName('platform')[0]);
    }
  }, [swapElements, parentElement]);

  const disableDisease = (selectedValue: string) => {
    if (diseaseOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, disease: []});
      setDiseaseRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('disease')[0] as HTMLDivElement;
      setSelectedIndex(1);
    }
  };
  const disableTrialNumber = (selectedValue: string) => {
    if (trailNumberOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, study_number: []});
      setTrailNumberRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('study_numbers')[0] as HTMLDivElement;
      setSelectedIndex(2);
    }
  };

  const disableProducts = (selectedValue: string) => {
    if (productOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, product: []});
      setProductRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('products')[0] as HTMLDivElement;
      setSelectedIndex(3);
    }
  };
  const disableGlobal = (selectedValue: string) => {
    if (globalOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, global: []});
      setGlobalRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('global_adaptation')[0] as HTMLDivElement;
      setSelectedIndex(4);
    }
  };
  const disableCountry = (selectedValue: string) => {
    if (countryOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, country: []});
      setCountryRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('countries')[0] as HTMLDivElement;
      setSelectedIndex(5);
    }
  };
  const disableModelType = (selectedValue: string) => {
    if (modelTypeOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, model_type: []});
      setModelTypeRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('model_types')[0] as HTMLDivElement;
      setSelectedIndex(6);
    }
  };

  const disablePlatform = (selectedValue: string) => {
    if (platformOptions.includes(selectedValue) && gridState.filter) {
      gridState.setFilter({...gridState.filter, platform: []});
      setPlatformRemoveOption(true);
      selectedDropdown = document.getElementsByClassName('platform')[0] as HTMLDivElement;
      setSelectedIndex(7);
    }
  };
  const disableDropdowns = (selectedValue: string) => {
    if (gridState.filter) {
      if (therapeuticAreaOptions.includes(selectedValue)) {
        gridState.setFilter({...gridState.filter, therapeutic_area: []});
        setTherapeuticAreaRemoveOption(true);
      }
      disableDisease(selectedValue);
      disableTrialNumber(selectedValue);
      disableProducts(selectedValue);
      disableGlobal(selectedValue);
      disableCountry(selectedValue);
      disableModelType(selectedValue);
      disablePlatform(selectedValue);

      element = document.getElementsByClassName(styles.dropDownWrapper)[selectedIndex + 1] as HTMLDivElement;
      parentElement?.insertBefore(selectedDropdown, element);
    }
  };

  const onDelete = (selectedValue: string) => () => {
    setSelectedFilters((value) => value.filter((v) => v !== selectedValue));
    disableDropdowns(selectedValue);
    reArrangeDivElements();
  };

  const displayMyModels = () => {
    if (gridState.dynamicPageViewMyModels) {
      gridState.setDynamicPageViewMyModels(false);
    } else {
      gridState.setDynamicPageViewMyModels(true);
    }
  };

  const handleModelListChanges = useCallback(() => {
    setList([]);
    setLoading(true);
    if (gridState.filter) {
      const filtersObj: GetModelsForDynamicFiltersRequest = {
        dynamicFiltersDTO: {
          filters: {
            filterParameters: gridState.filter,
          },
        },
      };
      modelLoadDynamic(dispatch, filtersObj).then((response) => {
        setList(response.models);
        // @ts-ignore
        if (response.filters[0]) {
          // @ts-ignore
          const filtersObj = response.filters[0];
          const filterParameters = filtersObj.availableFilters.filterParameters;
          setTherapeuticAreaOptions(filterParameters.therapeutic_area);
          setDiseaseOptions(filterParameters.disease);
          setTrailNumberOptions(filterParameters.study_number);
          setProductOptions(filterParameters.product);
          setGlobalOptions(filterParameters.global);
          setCountryOptions(filterParameters.country);
          setModelTypeOptions(filterParameters.model_type);
          setPlatformOptions(filterParameters.platform);
          setLoading(false);
        }
      });
    }
  }, [dispatch, gridState.filter]);

  const handleDialogClose = () => {
    setOpen(false);
    gridState.setFilter({
      therapeutic_area: [],
      disease: [],
      study_number: [],
      product: [],
      global: [],
      country: [],
      model_type: [],
      platform: [],
    });
  };

  const handleSearch = (searchType: string, value: string) => {
    if (gridState.filter) {
      switch (searchType) {
        case 'therapeutic_area': {
          gridState.setFilter({...gridState.filter, therapeutic_area: [value]});
          break;
        }
        case 'disease': {
          gridState.setFilter({...gridState.filter, disease: [value]});
          selectedDropdown = document.getElementsByClassName('disease')[0] as HTMLDivElement;
          break;
        }
        case 'study_numbers': {
          gridState.setFilter({...gridState.filter, study_number: [value]});
          selectedDropdown = document.getElementsByClassName('study_numbers')[0] as HTMLDivElement;
          break;
        }
        case 'products': {
          gridState.setFilter({...gridState.filter, product: [value]});
          selectedDropdown = document.getElementsByClassName('products')[0] as HTMLDivElement;
          break;
        }
        case 'global_adaptation': {
          gridState.setFilter({...gridState.filter, global: [value]});
          selectedDropdown = document.getElementsByClassName('global_adaptation')[0] as HTMLDivElement;
          break;
        }
        case 'countries': {
          gridState.setFilter({...gridState.filter, country: [value]});
          selectedDropdown = document.getElementsByClassName('countries')[0] as HTMLDivElement;
          break;
        }
        case 'model_types': {
          gridState.setFilter({...gridState.filter, model_type: [value]});
          selectedDropdown = document.getElementsByClassName('model_types')[0] as HTMLDivElement;
          break;
        }
        case 'platform': {
          gridState.setFilter({...gridState.filter, platform: [value]});
          selectedDropdown = document.getElementsByClassName('platform')[0] as HTMLDivElement;
          break;
        }
      }
      const length = document.getElementsByClassName(styles.disable).length;
      if (length > 0) {
        element = document.getElementsByClassName(styles.disable)[length - 1].nextSibling as HTMLDivElement;
      }
      parentElement?.insertBefore(selectedDropdown, element);
    }
    setSelectedFilters([...selectedFilters, value]);
  };

  useEffect(() => {
    if (gridState.filter && !open) {
      setOpen(false);
      handleModelListChanges();
      setSelectedFilters([
        ...gridState.filter.therapeutic_area,
        ...gridState.filter.disease,
        ...gridState.filter.study_number,
        ...gridState.filter.product,
        ...gridState.filter.global,
        ...gridState.filter.country,
        ...gridState.filter.model_type,
        ...gridState.filter.platform,
      ]);
    }
  }, [handleModelListChanges, gridState.filter, open]);

  const filteredList = useMemo(() => {
    if (user) {
      return list && searchMyModels(list, user.id, gridState.dynamicPageViewMyModels);
    } else {
      return list;
    }
  }, [user, list, gridState.dynamicPageViewMyModels]);

  return (
    <>
      <Container maxWidth={false} className="pageHeader">
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <PageHeaderTitle
            sm={12}
            md={5}
            xl={3}
            title="Model entries"
            label="Search, edit, and organize"
            Icon={<CloudServices className="icon32" />}
            IconNextToTitle
          />
        </Grid>
      </Container>
      <DialogGeneric
        title=" "
        classes={{
          paper: styles.initialDialog,
        }}
        open={open}>
        <span className={styles.dialogContent}>Begin your search with any filter</span>
        <IconButton className={styles.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogGeneric>
      <div className="listPanel">
        <Button onClick={displayMyModels} variant="contained" color="primary" {...tid('btn', 'view-my-model')}>
          {gridState.dynamicPageViewMyModels ? 'View All Model Entries' : 'View My Model Entries'}
        </Button>
      </div>
      <div id="searchForm" style={{background: '#D8D8D8'}}>
        <DynamicViewDropDownController
          label="Therapeutic Area"
          options={therapeuticAreaOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={therapeuticAreaRemoveOption}
        />
        <DynamicViewDropDownController
          label="Name Of Disease"
          options={diseaseOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={diseaseRemoveOption}
        />
        <DynamicViewDropDownController
          label="Trial Number"
          options={trailNumberOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={trailNumberRemoveOption}
        />
        <DynamicViewDropDownController
          label="Product"
          options={productOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={productRemoveOption}
        />
        <DynamicViewDropDownController
          label="Global/Adaptation"
          options={globalOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={globalRemoveOption}
        />
        <DynamicViewDropDownController
          label="Countries"
          options={countryOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={countryRemoveOption}
        />
        <DynamicViewDropDownController
          label="Model Type"
          options={modelTypeOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={modelTypeRemoveOption}
        />
        <DynamicViewDropDownController
          label="Platform"
          options={platformOptions}
          handleOnchange={handleSearch}
          handleRemoveOption={platformRemoveOption}
        />
      </div>
      <div id="filtersContainer" className={styles.filtersContainer}>
        {selectedFilters.length > 0
          ? selectedFilters.map((v) => (
              <Chip className={styles.selectedFilter} key={v} label={v} onDelete={onDelete(v)} />
            ))
          : 'No filters selected.'}
      </div>
      {filteredList && !open ? (
        <div className={styles.gridContainer}>
          {filteredList.length > 0 && selectedFilters.length > 0 ? (
            filteredList
              .slice(0, 500)
              .map((model) => (
                <ModelVersionControlLineageForDynamicView
                  handleSelection={() => handleSelectModel(model)}
                  dynamicView={true}
                  key={model.uuid}
                  model={model}
                />
              ))
          ) : (
            <LoaderElement length={selectedFilters.length} loading={loading} />
          )}
        </div>
      ) : (
        <div> No results found</div>
      )}
    </>
  );
};

export default DynamicView;
