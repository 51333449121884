import React, {useCallback} from 'react';
import {Form, TextFieldControl, useForm} from '../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {tid} from '../../../../testUtils';
import SubmitButton from '../../../core/Buttons/SubmitButton';
import CancelButton from '../../../core/Buttons/CancelButton';

type EditScenarioLabelPopperContentProps = {
  name: string;
  onSubmit: (name: string) => void;
  handleClose: () => void;
  fieldLabel?: string;
};

const EditScenarioLabelPopperContent: React.FC<EditScenarioLabelPopperContentProps> = ({
  name,
  handleClose,
  onSubmit,
}) => {
  const form = useForm(
    new FormGroup({
      name: new FormControl(name, [Validators.required, Validators.maxLength(50)]),
    }),
  );

  const handleSubmit = useCallback(() => {
    onSubmit(form.value.name);
    handleClose();
  }, [form, onSubmit, handleClose]);

  return (
    <div style={{width: '35rem', padding: '1rem'}}>
      <Form
        className=""
        group={form}
        onSubmit={handleSubmit}
        render={({valid}) => {
          return (
            <>
              <TextFieldControl
                name="name"
                label="Instance label"
                TextFieldProps={{
                  autoFocus: true,
                  autoComplete: 'off',
                  inputProps: {
                    ...tid('input', 'value'),
                  },
                }}
              />
              <div style={{margin: '2.4rem 0 0.8rem', display: 'flex', justifyContent: 'flex-end'}}>
                <CancelButton onClick={handleClose} />
                <SubmitButton style={{marginLeft: '1rem'}} disabled={!valid}>
                  Save Label
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default EditScenarioLabelPopperContent;
