import {ScenarioDTO, ScenarioGroupDetailDTO} from 'hemwb-api';
import {NumberHashMap} from '../../../../commonTypes';

export const sortInstancesByGroupOrder = (
  instances: ScenarioDTO[],
  groups: ScenarioGroupDetailDTO[],
): ScenarioDTO[] => {
  const groupIndexById = groups.reduce((acc, group, index) => {
    acc[group.id] = index;
    return acc;
  }, ({} as any) as NumberHashMap);

  const instanceGroupAssociation = instances.reduce((acc, instance) => {
    const group = groups.find((g) => g.scenarioIds.includes(instance.id));
    if (group) {
      acc[instance.id] = group.id;
    }
    return acc;
  }, ({} as any) as NumberHashMap);

  const getGroupOrderByInstance = (instance: ScenarioDTO) => {
    const groupId = instanceGroupAssociation[instance.id];
    return groupId ? groupIndexById[groupId] : -1;
  };

  return instances.sort((i1, i2) => {
    const a = getGroupOrderByInstance(i1);
    const b = getGroupOrderByInstance(i2);
    return a > b ? 1 : a < b ? -1 : 0;
  });
};
