import styles from './BoxLayout.module.scss';
import React, {ReactNode} from 'react';
import {AppBar} from '@material-ui/core';

export const boxLayoutStyles = styles;

export const BoxLayout: React.FC<React.PropsWithChildren<{footer?: ReactNode}>> = ({children, footer}) => (
  <div className={styles.page}>
    <div className={styles.container}>{children}</div>
    {footer && (
      <AppBar classes={{root: styles.footer}} color="default">
        {footer}
      </AppBar>
    )}
  </div>
);
