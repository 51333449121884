import {
  VisualizationActions,
  VisualizationInitAction,
  VisualizationInitAnalysis,
  VisualizationInitAnalysisData,
  VisualizationResults,
  VisualizationSetFilterAction,
  VisualizationSetParametersAction,
  VisualizationSortScenariosAction,
  VisualizationTestResults,
  VisualizationToggleSelectionAction,
  VisualizationToggleSubModelAction,
  VisualizationUnloadAnalysis,
} from './types';

export const visualizationInit = (modelUuid: string): VisualizationInitAction => ({
  type: VisualizationActions.INIT,
  modelUuid,
});

export const visualizationInitAnalysis = (analyseId: number): VisualizationInitAnalysis => ({
  type: VisualizationActions.INIT_ANALYSIS,
  analyseId,
});
export const visualizationInitAnalysisData = (
  analyseId: number,
  state: VisualizationInitAnalysisData['state'],
): VisualizationInitAnalysisData => ({
  type: VisualizationActions.INIT_ANALYSIS_DATA,
  analyseId,
  state,
});

export const visualizationUnloadAnalysis = (analyseId: number): VisualizationUnloadAnalysis => ({
  type: VisualizationActions.UNLOAD_ANALYSIS,
  analyseId,
});

export const visualizationFreshStart = () => ({
  type: VisualizationActions.FRESH_START,
});

export const visualizationDiscardChanges = () => ({
  type: VisualizationActions.DISCARD_CHANGES,
});

export const visualizationToggleSelection = (
  subModel: VisualizationToggleSelectionAction['subModel'],
  scenario: VisualizationToggleSelectionAction['scenario'],
): VisualizationToggleSelectionAction => ({
  type: VisualizationActions.TOGGLE_SELECTION,
  subModel,
  scenario,
});

export const visualizationToggleSubModel = (
  subModel: VisualizationToggleSelectionAction['subModel'],
): VisualizationToggleSubModelAction => ({
  type: VisualizationActions.TOGGLE_SUB_MODEL,
  subModel,
});

export const visualizationSetFilter = (
  filter: VisualizationSetFilterAction['filter'],
): VisualizationSetFilterAction => ({
  type: VisualizationActions.SET_FILTER,
  filter,
});

export const visualizationSortScenarios = (fromIndex: number, toIndex: number): VisualizationSortScenariosAction => ({
  type: VisualizationActions.SORT_SCENARIOS,
  fromIndex,
  toIndex,
});

export const visualizationSetParameters = (
  parameters: object,
  valid: boolean,
  displaySubModelId: number,
): VisualizationSetParametersAction => ({
  type: VisualizationActions.SET_PARAMETERS,
  parameters,
  valid,
  displaySubModelId,
});

export const visualizationTestResults = (results: VisualizationResults): VisualizationTestResults => ({
  type: VisualizationActions.TEST_RESULTS,
  results,
});
