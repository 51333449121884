import React from 'react';
import {FormControl} from 'react-reactive-form';
import {InputAttributes, InputType, rangeInputTypes} from '../../InputDefinition/types';
import {InputGenerator} from '../types';
import InputHeader from './InputHeader';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type DateInputProps = {
  control: FormControl;
  definition: InputAttributes;
  disabled?: boolean;
};

export const DateInput: React.FC<DateInputProps> = ({control, definition, disabled}) => {
  const type = definition.type;
  if (!rangeInputTypes.hasOwnProperty(type)) {
    return null;
  }

  return (
    <>
      <InputHeader definition={definition} />
      <NumberInputPrimitive
        type={definition.type as keyof typeof rangeInputTypes}
        control={control}
        disabled={disabled}
      />
    </>
  );
};

export const dateInputGenerator: InputGenerator<InputType.DATE> = {
  type: InputType.DATE,
  buildControl: (definition, initialValues) => {
    return numberInputPrimitiveControlBuilder(
      definition,
      initialValues === undefined ? definition.defaultValue : initialValues,
    );
  },
  render: DateInput,
  renderStatic: DateInput,
};
