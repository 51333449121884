import React, {ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

type SequentialRendererProps = {
  onRenderStart?: () => void;
  onRenderFinish?: () => void;
  elements: ReactNode[];
  delay?: number;
};

const SequentialRenderer: React.FC<SequentialRendererProps> = ({
  onRenderStart,
  onRenderFinish,
  elements,
  delay = 10,
}) => {
  const [storedElements, setStoredElements] = useState<ReactNode[]>([]);

  const renderingRef = useRef(false);

  const elementsRef = useRef(elements);
  elementsRef.current = elements;
  const storedElementsRef = useRef(elements);
  storedElementsRef.current = storedElements;

  const calculateChange = useCallback(() => {
    const maxIndex = Math.max(elementsRef.current.length, storedElementsRef.current.length);

    for (let i = 0; i < maxIndex; i++) {
      // @ts-ignore
      if (storedElementsRef.current[i] !== elementsRef.current[i]) {
        if (!renderingRef.current) {
          renderingRef.current = true;
          onRenderStart?.();
        }

        const newStoredElements = [...storedElementsRef.current];
        newStoredElements[i] = elementsRef.current[i];
        setStoredElements(newStoredElements);

        break;
      }
    }

    if (renderingRef.current) {
      renderingRef.current = false;
      onRenderFinish?.();
    }
  }, [onRenderStart, onRenderFinish]);

  useEffect(() => {
    if (storedElementsRef.current.length === 0 && elements.length === 0) {
      return;
    }
    setTimeout(() => calculateChange(), delay);
  }, [elements, calculateChange, delay]);

  useLayoutEffect(() => {
    setTimeout(() => calculateChange(), delay);
  }, [storedElements, calculateChange, delay]);

  return <>{storedElements}</>;
};

export default SequentialRenderer;
