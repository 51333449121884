import React, {useCallback, useMemo, useRef} from 'react';
import SubModelActionMenu from '../../subModel/common/SubModelActionMenu/SubModelActionMenu';
import InputDefinition from '../../../core/ModelInput/InputDefinition/InputDefinition';
import DefaultPage from '../../../layout/common/DefaultPage';
import SideBar from '../../../layout/common/SideBar';
import {SubModelActionProps} from '../../actionMenuTypes';
import {ModelInputJSONEditorSubmitType} from './ModelInputJSONEditor';
import {InputAttributes} from '../../../core/ModelInput/InputDefinition/types';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import SuspenseNull from '../../../core/Suspense/SuspenseNull';
import {typeToSection} from '../../../../store/executionSetup';

const ModelInputBuilder: React.FC<SubModelActionProps & ModelInputJSONEditorSubmitType> = ({
  model,
  subModel,
  input,
  onSubmit,
  submitting,
  onCancel,
}) => {
  const inputListPortalRef = useRef<HTMLDivElement>(null);
  const section = typeToSection(subModel.type);

  const workingInput = useMemo(() => {
    return input;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const definitions = useMemo(() => {
    const parsed = workingInput?.value ? JSON.parse(workingInput.value) : {};
    return parsed.definitions || [];
  }, [workingInput]);

  const handleSubmit = useCallback(
    (definitions: InputAttributes[]) => {
      onSubmit(JSON.stringify({definitions}));
    },
    [onSubmit],
  );

  return (
    <>
      <SideBar
        position="left"
        className="leftSelection"
        style={{boxShadow: '5px 0px 4px rgba(0,0,0,0.3)', position: 'relative', zIndex: 2}}>
        <h3 style={{paddingLeft: 15}}>Parameters</h3>
        <div ref={inputListPortalRef} />
      </SideBar>
      <DefaultPage>
        <SuspenseNull>
          {model && subModel && input && (
            <>
              <SubModelSectionHeader
                model={model}
                section={section}
                subModel={subModel}
                title="Input Definition Editor"
              />

              <InputDefinition
                key={workingInput?.value || ''}
                onCancel={onCancel!}
                onSubmit={handleSubmit}
                submitting={submitting}
                definitions={definitions}
                inputListPortalRef={inputListPortalRef}
                subModelType={subModel.type!}
                model={model}
              />
            </>
          )}
        </SuspenseNull>
      </DefaultPage>
      <SubModelActionMenu model={model} section={section} subModel={subModel} />
    </>
  );
};

export default ModelInputBuilder;
