import {useCallback, useState} from 'react';

export const useToggleSelected = (initialState?: string | number | string[]) => {
  const [selection, setSelection] = useState(initialState);

  const toggleSelected = useCallback(
    (value) => {
      if (Array.isArray(selection)) {
        if (selection.includes(value)) {
          setSelection(selection.filter((v) => v !== value));
        } else {
          setSelection([...selection, value]);
        }
      } else if (selection === value) {
        setSelection(undefined);
      } else {
        setSelection(value);
      }
    },
    [selection],
  );

  const addSelected = useCallback(
    (value) => {
      if (Array.isArray(selection)) {
        setSelection([...selection.filter((v) => v !== value), value]);
      } else {
        setSelection(value);
      }
    },
    [selection],
  );

  const isSelected = useCallback(
    (value: string) => {
      if (Array.isArray(selection)) {
        return selection.includes(value);
      }

      return selection === value;
    },
    [selection],
  );

  const selected = selection;
  return {selected, addSelected, toggleSelected, isSelected};
};
