import React, {useCallback, useEffect, useState} from 'react';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useDispatch} from 'react-redux';
import {useMounted} from '../../../../../hooks/useMounted';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {shareInstance, shareUsersList, useScenarioDetail, titleCase} from '../../../../../store/scenario';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useModelInput} from '../../../../../store/modelInputs';
import {useCurrentUser} from '../../../../../store/auth';
import {AutocompleteControl, AutocompleteItem, Form, TextFieldControl, useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {fullName, fullNameWestern} from '../../../../../store/user/utils';
import {UserAccessDTO} from 'hemwb-api';
import {tid} from '../../../../../testUtils';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import {Share16, Share32} from '@carbon/icons-react';
import {Grid, IconButton} from '@material-ui/core';
import GridItem from '../../../../core/Form/GridItem';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {UserCard} from '../../../../core/UserCard/UserCard';
import CloseIcon from '@material-ui/icons/Close';
import ScenarioShareRemoveDialog from './ScenarioShareRemoveDialog';
import {SubModelSection} from '../../../../router/routes';

const defaultMessage = '{user} has shared an instance with you in the following model: {name}';

const renderUserItem = (user: UserAccessDTO, name: string) => (
  <AutocompleteItem
    primary={fullName(user)}
    secondary={user.email || ''}
    {...tid('input-option', name, user.id)}
    ownershipType={titleCase(user.ownershipType?.toString())}
  />
);

const ScenarioShare: React.FC<ScenarioActionProps> = ({model, subModel, scenario, section, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [submitting, setSubmitting] = useState(false);
  const currentUser = useCurrentUser();
  const [modelUsers, setModelUsers] = useState<UserAccessDTO[]>();
  const [sharedUsers, setSharedUsers] = useState<UserAccessDTO[]>();
  const [removeUser, setRemoveUser] = useState<UserAccessDTO>();
  const [open, setOpen] = useState<boolean>(false);

  const form = useForm(
    new FormGroup({
      users: new FormControl([], Validators.required),
      message: new FormControl('', [Validators.required]),
    }),
    {
      users: [],
      message: defaultMessage.replace('{user}', fullNameWestern(currentUser)).replace('{name}', model.name),
    },
  );

  const handleDeleteUser = useCallback(
    (user: UserAccessDTO) => {
      form.controls.users.setValue(form.controls.users.value.filter((u: UserAccessDTO) => u.id !== user.id));
      form.controls.users.markAsTouched();
    },
    [form],
  );

  const addSharedUsers = useCallback((users: UserAccessDTO[]) => {
    setModelUsers(users.filter((user) => !user.userAccess));
    setSharedUsers(users.filter((user) => user.userAccess));
  }, []);

  const filterBasedOnSection = useCallback(
    (users: UserAccessDTO[]) => {
      const filteredUsers = users.filter((user) => {
        if (
          user.ownershipType &&
          (user.ownershipType.toString().indexOf('CALIBRATOR_MODELER') > -1 ||
            user.ownershipType?.toString()?.indexOf('OWNER') > -1)
        ) {
          return user;
        }
        return null;
      });
      setModelUsers(filteredUsers.filter((user) => !user.userAccess));
      addSharedUsers([...filteredUsers]);
    },
    [addSharedUsers],
  );

  useEffect(() => {
    if (form) {
      const onlyLastUser = () => {
        if (form.controls.users.value.length > 1) {
          form.controls.users.setValue([form.controls.users.value[form.controls.users.value.length - 1]]);
        }
      };

      form.controls.users.valueChanges.subscribe(onlyLastUser);
    }
    if (!modelUsers) {
      shareUsersList(dispatch, model.uuid, scenario.id).then((response) => {
        if (section === SubModelSection.CALIBRATION) {
          filterBasedOnSection(response);
        } else {
          addSharedUsers(response);
        }
      });
    }
  }, [dispatch, model, scenario, section, modelUsers, addSharedUsers, filterBasedOnSection, form]);

  const requestConfirmation = useCallback((user: UserAccessDTO) => {
    setRemoveUser(user);
    setOpen(true);
  }, []);

  const handleStopSharing = useCallback(
    (user: UserAccessDTO) => {
      setOpen(false);
      if (sharedUsers) setSharedUsers([...sharedUsers.filter((u: UserAccessDTO) => u.id !== user.id)]);
      shareUsersList(dispatch, model.uuid, scenario.id).then((response) => {
        if (section === SubModelSection.CALIBRATION) {
          filterBasedOnSection(response);
        } else {
          addSharedUsers(response);
        }
      });
      onSuccess?.();
    },
    [sharedUsers, onSuccess, dispatch, model, scenario, section, addSharedUsers, filterBasedOnSection],
  );

  const input = useModelInput(subModel.id);
  const scenarioDetail = useScenarioDetail(scenario.id);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    setSubmitting(false);
    try {
      const selectedUser = modelUsers?.find((user: UserAccessDTO) => user.id === form.controls.users.value[0].id);
      if (subModel.id && selectedUser) {
        await shareInstance(dispatch, scenario.id, subModel.id, [selectedUser.id], form.controls.message.value);
        // @ts-ignore
        setSharedUsers((sharedUsers) => [...sharedUsers, selectedUser]);
        form.controls.users.setValue([]);
        dispatch(messageAdd('Instance has been shared', MessageTypes.SUCCESS));
        mounted.current && setSubmitting(false);
        onSuccess?.();
      }
    } catch (e) {
      setSubmitting(false);
    }
  }, [dispatch, subModel, scenario, mounted, onSuccess, form, modelUsers]);

  if (!input || !scenarioDetail) {
    return <SuspenseNull />;
  }

  return (
    <>
      <Form
        FieldGroupProps={{strict: false}}
        group={form}
        onSubmit={handleSubmit}
        className=""
        {...tid('model-share-form')}
        render={({valid}) => {
          // @ts-ignore
          return (
            <>
              <div style={{paddingLeft: '3rem'}}>
                <ActionHeader label="Share Instance" Icon={<Share32 />}>
                  {scenario.name}
                </ActionHeader>
              </div>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                style={{paddingLeft: '3rem'}}>
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <GridItem>
                    <AutocompleteControl
                      name="users"
                      label="Send an email to selected users"
                      AutocompleteProps={{
                        disableCloseOnSelect: false,
                        multiple: true,
                        options: modelUsers || [],
                        renderTags: () => null,
                        getOptionSelected: (option: UserAccessDTO, value: UserAccessDTO) => {
                          return option.id === value.id;
                        },
                        getOptionLabel: (option: UserAccessDTO) => option.firstName || '',
                        renderOption: (option: UserAccessDTO) => renderUserItem(option, option.id || ''),
                      }}
                    />
                  </GridItem>

                  <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
                    {form.controls.users.value && form.controls.users.value[0] && (
                      <UserCard
                        style={{marginBottom: '0.8rem'}}
                        label={titleCase(form.controls.users.value[0].ownershipType.toString())}
                        user={form.controls.users.value[0]}
                        key={form.controls.users.value[0].id}
                        actions={
                          <>
                            <IconButton
                              style={{float: 'right'}}
                              aria-label="Remove"
                              size="small"
                              onClick={() => handleDeleteUser(form.controls.users.value[0])}
                              {...tid('user-card-remove', form.controls.users.value[0].id)}>
                              <CloseIcon />
                            </IconButton>
                          </>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item direction="column" sm={12} md={6} style={{paddingLeft: '3rem'}}>
                  <GridItem>
                    <TextFieldControl
                      label="Customize your email message"
                      name="message"
                      TextFieldProps={{
                        multiline: true,
                        rows: 5,
                        inputProps: {
                          ...tid('input', 'message'),
                        },
                      }}
                    />
                  </GridItem>
                </Grid>
              </Grid>

              <div className="buttonsContainer">
                <CancelButton
                  onClick={onCancel}
                  disabled={submitting}
                  {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'cancel')}
                />
                <SubmitButton
                  active={submitting}
                  disabled={!valid}
                  endIcon={<Share16 />}
                  {...tid(MODEL_ACTION_MENU_IDS.SHARE, 'submit')}>
                  Share
                </SubmitButton>
              </div>
            </>
          );
        }}
      />
      <div style={{paddingLeft: '3rem'}}>
        <ActionHeader label="Shared with" Icon={<Share32 />}>
          {scenario.name}
        </ActionHeader>
        <Grid item style={{width: '36.7rem', marginBottom: '2rem'}}>
          {sharedUsers?.map((user: UserAccessDTO) => (
            <UserCard
              style={{marginBottom: '0.8rem'}}
              label={titleCase(user.ownershipType?.toString())}
              user={user}
              key={user.id}
              actions={
                <>
                  <IconButton
                    style={{float: 'right'}}
                    aria-label="Remove"
                    size="small"
                    onClick={() => requestConfirmation(user)}
                    {...tid('shared-user-card-remove', user.id)}>
                    <CloseIcon />
                  </IconButton>
                </>
              }
            />
          ))}
        </Grid>
      </div>
      {removeUser && (
        <ScenarioShareRemoveDialog
          scenario={scenario}
          open={open}
          user={removeUser}
          onCancel={() => setOpen(false)}
          onSuccess={() => handleStopSharing(removeUser)}
        />
      )}
    </>
  );
};

export default ScenarioShare;
