import {useContext, useEffect, useRef} from 'react';
import {WebSocketContext} from './WebSocketContext';
import {useDispatch} from 'react-redux';
import {StompSubscription} from '@stomp/stompjs';
import {scenarioExecutionUpdateStatus} from '../store/scenarioExecution';
import {messageAdd, MessageTypes} from '../store/message';

export const useExecutionStatusUpdate = () => {
  const {client, connected} = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const subscription = useRef<StompSubscription>();

  useEffect(() => {
    if (client?.connected && connected) {
      //
      // Sends message each time scenario execution status is updated
      //
      subscription.current = client.subscribe('/topic/scenarioExecutionStatusUpdates', (message) => {
        try {
          dispatch(scenarioExecutionUpdateStatus(JSON.parse(message.body)));
        } catch (e) {
          dispatch(
            messageAdd(
              `Failed to parse WS response in /topic/scenarioExecutionStatusUpdates: ${message.body}`,
              MessageTypes.WARNING,
            ),
          );
        }
      });
    }

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [dispatch, client, connected]);
};
