import React, {useCallback, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import {TextOrProgress} from '../../../../core/textOrProgress/TextOrProgress';
import {tid} from '../../../../../testUtils';
import {Form, TextFieldControl, useForm} from '../../../../core/Form';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {ExecutionSetupDTO, ModelDTO} from 'hemwb-api';
import {useScenarioGroupIsNameUnique} from '../../../../../store/scenarioGroup/hooks';
import {scenarioGroupCreate} from '../../../../../store/scenarioGroup';
import {useDispatch} from 'react-redux';
import {defaultCancelButtonProps} from '../../../../core/Buttons/CancelButton';
import {defaultSubmitButtonProps} from '../../../../core/Buttons/SubmitButton';

export type VisualizationScenarioGroupAddDialogProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  open: boolean;
  handleClose: () => void;
};

const VisualizationScenarioGroupAddDialog: React.FC<VisualizationScenarioGroupAddDialogProps> = ({
  model,
  subModel,
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isNameUnique = useScenarioGroupIsNameUnique(model.uuid);

  const validateNameUnique = async (control: AbstractControl) => {
    const name = control.value;
    return name && !(await isNameUnique(name, subModel.id)) ? {isUnique: true} : null;
  };

  const form = useForm(
    new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(20)], validateNameUnique),
    }),
  );

  const handleSubmit = useCallback(async () => {
    if (form.valid) {
      setIsSubmitting(true);
      await scenarioGroupCreate(dispatch, model.uuid, subModel.id, {name: form.value.name, scenarioIds: []});
      handleClose();
    }
  }, [dispatch, form, handleClose, subModel.id, model.uuid]);

  return (
    <DialogGeneric
      open={open}
      buttons={[
        {
          ...defaultCancelButtonProps,
          onClick: handleClose,
          disabled: isSubmitting,
          ...tid('dialog-scenario-group', 'btn', 'cancel'),
        },
        {
          ...defaultSubmitButtonProps,
          onClick: handleSubmit,
          disabled: isSubmitting,
          children: <TextOrProgress active={isSubmitting}>Create</TextOrProgress>,
          ...tid('dialog-scenario-group', 'btn', 'submit'),
        },
      ]}
      title="Create Group"
      PaperProps={tid('dialog-scenario-group') as {}}
      childrenAsText={false}>
      <Form
        className=""
        group={form}
        onSubmit={handleSubmit}
        render={() => {
          return (
            <>
              <TextFieldControl
                name="name"
                label="Group Name"
                TextFieldProps={{
                  autoFocus: true,
                  autoComplete: 'off',
                  inputProps: {
                    ...tid('input', 'value'),
                  },
                }}
              />
            </>
          );
        }}
      />
    </DialogGeneric>
  );
};

export default VisualizationScenarioGroupAddDialog;
