import {useLocation} from 'react-router';
import {useEffect} from 'react';
import {getTitleByPathname} from '../components/router/titles';

export const useDocumentTitle = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    document.title = getTitleByPathname(pathname);
  }, [pathname]);
};
