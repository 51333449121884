import {RootState} from '../rootReducer';
import {useReduxLoader} from '../useReduxLoader';
import {OwnershipType} from 'hemwb-api';
import {modelAccessSelector} from './selectors';
import {modelAccessLoadList} from './actions';
import {useModelViewers} from '../modelViewers';
import {useMemo} from 'react';
import {useModelDisplayViewers} from '../modelDisplayViewers';

const modelAccessSelectorBuilder = (params: [string, OwnershipType]) => (state: RootState) =>
  modelAccessSelector(state)[params[1]]?.[params[0]] || null;

export const useModelAccessGranular = (modelUuid: string, type: OwnershipType) => {
  return useReduxLoader(modelAccessSelectorBuilder, modelAccessLoadList, [modelUuid, type]);
};

export const useModelAccess = (modelUuid: string) => {
  const vestLeads = useModelAccessGranular(modelUuid, OwnershipType.VESTLEAD);
  const owners = useModelAccessGranular(modelUuid, OwnershipType.OWNER);
  const calibrationModelers = useModelAccessGranular(modelUuid, OwnershipType.CALIBRATORMODELER);
  const scenarioModelers = useModelAccessGranular(modelUuid, OwnershipType.SCENARIOMODELER);
  const viewers = useModelViewers(modelUuid);
  const displayViewers = useModelDisplayViewers(modelUuid);

  const vestLead = vestLeads?.[0];

  return useMemo(
    () => ({
      vestLead,
      owners,
      calibrationModelers,
      scenarioModelers,
      viewers,
      displayViewers,
      loaded: !!owners && !!calibrationModelers && !!scenarioModelers && !!viewers && !!vestLeads && !!displayViewers,
    }),
    [vestLead, vestLeads, owners, calibrationModelers, scenarioModelers, viewers, displayViewers],
  );
};
