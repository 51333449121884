import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import './styles/index.scss';
import App from './components/App';
import {HomePage} from './components/auth/HomePage';
import {history, store} from './store/rootReducer';
import * as serviceWorker from './serviceWorker';
import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import {defaultTheme} from './styles';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import WebSocketClientProvider from './websocket/WebSocketClientProvider';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW) {
  require('./test/browserServer');
}

ReactDOM.render(
  <>
    {/* This code is for distinguish landing pages in 3000 port */}
    {window.location.href.indexOf('registration') > -1 ? (
      <HomePage />
    ) : (
      <Provider store={store}>
        <WebSocketClientProvider enabled={!process.env.REACT_APP_MSW}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <ConnectedRouter history={history}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
                {/*<LoginPage />*/}
                {/*<RegistrationReview />*/}
              </MuiPickersUtilsProvider>
            </ConnectedRouter>
          </ThemeProvider>
        </WebSocketClientProvider>
      </Provider>
    )}
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
