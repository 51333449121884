import React from 'react';
import {ScenarioExecutionGroupFileDetailDTO} from 'hemwb-api';
import ExecutionStatusIcon from '../../icons/ExecutionStatusIcon';
import {formatScenarioExecutionStatusValue} from '../../../store/scenarioExecution/utils';
import styles from './ExecutionStatus.module.scss';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const InstanceExecutionStatus: React.FC<ScenarioExecutionGroupFileDetailDTO> = ({executions}) => {
  return (
    <div style={{marginLeft: '12px'}}>
      <TransitionGroup className={styles.animationWrapper}>
        <CSSTransition timeout={1300} classNames="execution-status" key={executions[0].status}>
          <div className={styles.container}>
            <ExecutionStatusIcon status={executions[0].status} />
            <span>{formatScenarioExecutionStatusValue(executions[0].status)}</span>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default InstanceExecutionStatus;
