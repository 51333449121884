import {ScenarioExecutionActions, ScenarioExecutionActionUpdateStatus} from './types';
import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {
  requestScenarioExecutionCancel,
  requestScenarioExecutionCreate,
  requestScenarioExecutionExecute,
  requestScenarioExecutionList,
  requestScenarioGroupExecutionList,
} from './api';
import {ScenarioExecutionStatusUpdateDTO} from 'hemwb-api';

const scenarioExecutionLoadListActionsCreator = buildAsyncActionsCreator(ScenarioExecutionActions.REQUEST_LIST);
export const scenarioExecutionLoadList = createAsyncActionExecutor(
  scenarioExecutionLoadListActionsCreator,
  requestScenarioExecutionList,
);

const scenarioExecutionGroupLoadListActionsCreator = buildAsyncActionsCreator(
  ScenarioExecutionActions.REQUEST_GROUP_LIST,
);
export const scenarioExecutionGroupLoadList = createAsyncActionExecutor(
  scenarioExecutionGroupLoadListActionsCreator,
  requestScenarioGroupExecutionList,
);

const scenarioExecutionCreateActionsCreator = buildAsyncActionsCreator(ScenarioExecutionActions.REQUEST_CREATE);
export const scenarioExecutionCreate = createAsyncActionExecutor(
  scenarioExecutionCreateActionsCreator,
  requestScenarioExecutionCreate,
);

const scenarioExecutionExecuteActionsCreator = buildAsyncActionsCreator(ScenarioExecutionActions.REQUEST_EXECUTE);
export const scenarioExecutionExecute = createAsyncActionExecutor(
  scenarioExecutionExecuteActionsCreator,
  requestScenarioExecutionExecute,
);

const scenarioExecutionCancelActionsCreator = buildAsyncActionsCreator(ScenarioExecutionActions.REQUEST_CANCEL);
export const scenarioExecutionCancel = createAsyncActionExecutor(
  scenarioExecutionCancelActionsCreator,
  requestScenarioExecutionCancel,
);

export const scenarioExecutionUpdateStatus = (
  statusUpdate: ScenarioExecutionStatusUpdateDTO,
): ScenarioExecutionActionUpdateStatus => ({
  type: ScenarioExecutionActions.UPDATE_STATUS,
  statusUpdate,
});
