import React from 'react';
import {DecimalInputAttributes, InputGenerator, InputType} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import {AbstractControl, FormGroup} from 'react-reactive-form';
import {NumberInputPrimitive, numberInputPrimitiveControlBuilder} from '../primitives/NumberInputPrimitive';

type ControlsMap = {[key in keyof Required<DecimalInputAttributes>]: AbstractControl};

type DecimalInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
};

export const DecimalInput: React.FC<DecimalInputProps> = ({formGroup}) => {
  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <NumberInputPrimitive formGroup={formGroup} type={InputType.DECIMAL} />
    </>
  );
};

export const decimalInputGenerator: InputGenerator<InputType.DECIMAL> = {
  type: InputType.DECIMAL,
  buildControl: (initialValues = {}) => {
    const fg = new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      ...numberInputPrimitiveControlBuilder(initialValues, InputType.DECIMAL),
    });

    return fg;
  },
  render: DecimalInput,
};
