import React, {useMemo, useState} from 'react';
import VisualizationScenarioGroupAddDialog, {
  VisualizationScenarioGroupAddDialogProps,
} from './VisualizationScenarioGroupAddDialog';

type VisualizationScenarioGroupAddOpenProps = Omit<VisualizationScenarioGroupAddDialogProps, 'handleClose' | 'open'>;

export const useVisualizationScenarioGroupAddDialog = () => {
  const [openProps, setOpenProps] = useState<VisualizationScenarioGroupAddOpenProps>();

  const Component = useMemo(() => {
    return !openProps ? null : (
      <VisualizationScenarioGroupAddDialog
        open={true}
        {...openProps}
        handleClose={() => {
          // openProps.onCancel?.()
          setOpenProps(undefined);
        }}
      />
    );
  }, [openProps]);

  return {
    open: setOpenProps,
    Component,
  };
};
