import React, {useCallback, useEffect, useState} from 'react';
import AdminPage from '../../common/AdminPage/AdminPage';
import {
  modelMetadataOptionsLabels,
  useModelMetadataOptions,
  useModelMetadataOptionsUsage,
  modelMetadataLoadOptionsCount,
} from '../../../../../store/modelMetadata';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {Tab, Tabs} from '@material-ui/core';
import Grid from './Grid';
import {ModelMetadataOptionsDTOOptions, MetadataCountDTO} from 'hemwb-api';
import {useHistory} from 'react-router';
import {getUrlRoute, Routes, useRoute} from '../../../../router/routes';
import Header from '../common/Header';
import {tid} from '../../../../../testUtils';
import ActionMenu from '../common/ActionMenu';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {useLastValidValue} from '../../../../../hooks/useLastValidValue';
import {ManageMetadataActionProps} from '../common/ActionMenuTypes';
import {useDispatch} from 'react-redux';

const getProperties = () => Object.keys(modelMetadataOptionsLabels) as (keyof ModelMetadataOptionsDTOOptions)[];

const List: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {property} = useRoute<Routes.MANAGE_METADATA_PROPERTY>();
  const fetchedOptions = useModelMetadataOptions();
  const options = useLastValidValue(fetchedOptions);
  const temporaryUsages = useModelMetadataOptionsUsage(property);
  const usages = useLastValidValue(temporaryUsages);

  const [tab, setTab] = useState<keyof ModelMetadataOptionsDTOOptions>();
  const [selectedValues, setSelectedValues] = useState<Partial<ManageMetadataActionProps>>();
  const [optionsCount, setOptionsCount] = useState<MetadataCountDTO[]>();
  const [refreshOptionsCount, setRefreshOptionsCount] = useState<boolean>(false);
  useEffect(() => {
    setSelectedValues({value: undefined, retired: false});
    if (!optionsCount || refreshOptionsCount) {
      modelMetadataLoadOptionsCount(dispatch, {metadataType: 'file_tag'})
        .then((response) => {
          setOptionsCount(response);
          setRefreshOptionsCount(false);
        })
        .catch(() => {
          dispatch(messageAdd('Failed to report the issue', MessageTypes.ERROR));
        });
    }
  }, [property, options, dispatch, optionsCount, refreshOptionsCount]);
  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<{}>, p: keyof ModelMetadataOptionsDTOOptions) => {
      setTab(p);
      history.replace(getUrlRoute(Routes.MANAGE_METADATA_PROPERTY, {property: p}));
    },
    [history],
  );

  const handleSuccess = useCallback((selectNewValue?: string | null) => {
    if (selectNewValue === undefined) {
      return;
    }
    if (selectNewValue === null) {
      setSelectedValues({value: undefined, retired: false});
      return;
    }
    setSelectedValues({value: selectNewValue, retired: false});
  }, []);

  useEffect(() => {
    if (modelMetadataOptionsLabels && !tab) {
      const properties = getProperties();

      if (property in modelMetadataOptionsLabels) {
        setTab(property as keyof ModelMetadataOptionsDTOOptions);
      } else if (properties.length > 0) {
        setTab(properties[0]);
        history.replace(getUrlRoute(Routes.MANAGE_METADATA_PROPERTY, {property: properties[0]}));
      }
    }
  }, [history, tab, property]);

  if (!options || !tab) {
    return (
      <AdminPage style={{paddingLeft: '2rem'}}>
        <Header />
        <SuspenseNull />
      </AdminPage>
    );
  }

  return (
    <>
      <AdminPage style={{paddingLeft: '2rem'}}>
        <Header />
        <br />
        <br />
        <div style={{display: 'flex'}}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="inherit"
            className="tabs-secondary">
            {getProperties().map((p) => (
              <Tab key={p} {...tid('tab', p)} value={p} label={modelMetadataOptionsLabels[p] || ''} />
            ))}
          </Tabs>
        </div>

        <Grid
          options={options}
          selectedValues={selectedValues}
          onSelectChange={setSelectedValues}
          property={tab}
          usages={usages}
          fileTagCount={optionsCount}
        />
      </AdminPage>

      <ActionMenu
        property={tab}
        value={selectedValues?.value}
        retired={selectedValues?.retired}
        usageCount={selectedValues?.value ? usages?.[selectedValues.value]?.length : undefined}
        onSuccess={handleSuccess}
        fileTagCount={optionsCount}
        refreshOptionsCount={() => setRefreshOptionsCount(true)}
      />
    </>
  );
};

export default List;
