export enum MessageTypes {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum MessageDuration {
  SHORT = 3000,
  MEDIUM = 5000,
  LONG = 7000,
  INFINITY = 0,
}

export type Message = {
  type: MessageTypes;
  display: string;
  text: string;
  occurrence: number;
  disableGrouping: boolean;
  duration: number;
  key: string;
  height?: number;
  stackTrace?: string;
  reportId?: number;
};

export enum MessageActions {
  NETWORK_ERROR = 'MESSAGE/NETWORK_ERROR',
  ADD = 'MESSAGE/ADD',
  REMOVE = 'MESSAGE/REMOVE',
  UPDATE = 'MESSAGE/UPDATE',
}

export type MessageActionNetworkError = {
  type: MessageActions.NETWORK_ERROR;
  response: Response;
  body: any;
};

export type MessageActionAdd = {
  type: MessageActions.ADD;
  message: Message;
};

export type MessageActionRemove = {
  type: MessageActions.REMOVE;
  message: Message;
};

export type MessageActionUpdate = {
  type: MessageActions.UPDATE;
  message: Message;
};

export type MessageAction = MessageActionNetworkError | MessageActionAdd | MessageActionRemove | MessageActionUpdate;
