import React from 'react';
import {FormControl} from 'react-reactive-form';
import {InputType, MultiSelectInputAttributes, SingleSelectInputAttributes} from '../../InputDefinition/types';
import InputHeader from '../inputs/InputHeader';

type StaticInputSelectPrimitiveProps = {
  control: FormControl;
  definition: SingleSelectInputAttributes | MultiSelectInputAttributes;
};

const StaticInputSelectPrimitive: React.FC<StaticInputSelectPrimitiveProps> = ({control, definition}) => {
  const labels = definition.options.reduce((acc, option) => {
    acc[option.key] = option.text;
    return acc;
  }, ({} as any) as {[key: string]: string});

  let value;
  if (definition.type === InputType.MULTI_SELECT && Array.isArray(control.value)) {
    value = control.value
      .reduce((acc, key) => {
        acc.push(labels[key] || key);
        return acc;
      }, [])
      .join(', ');
  } else if (definition.type === InputType.SINGLE_SELECT && control.value && !Array.isArray(control.value)) {
    value = labels[control.value] || control.value;
  }

  return (
    <>
      <InputHeader definition={definition} />
      {value}
    </>
  );
};

export default StaticInputSelectPrimitive;
