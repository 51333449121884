import React from 'react';
import {InputType, LabelInputAttributes} from '../../InputDefinition/types';
import {InputGenerator} from '../types';
import {FormGroup} from 'react-reactive-form';

type StringInputProps = {
  definition: LabelInputAttributes;
};

export const LabelInput: React.FC<StringInputProps> = ({definition}) => {
  return (
    <h2 className="headlineBlue" id={`input-title-${definition.id}`} tabIndex={0}>
      {definition.defaultValue}
    </h2>
  );
};

export const labelInputGenerator: InputGenerator<InputType.LABEL> = {
  type: InputType.LABEL,
  buildControl: () => new FormGroup({}),
  render: LabelInput,
  renderStatic: LabelInput,
};
