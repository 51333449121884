import {VisualizationResultsChartElement} from '../../../../../store/visualization';
import * as charts from '@carbon/charts';
import domToImage from 'dom-to-image-more-patch';

export const chartToPNG = async (element: VisualizationResultsChartElement): Promise<string> => {
  const div = document.createElement('div');
  const chartHolderDiv = document.createElement('div');
  div.style.cssText = 'position:absolute;width:800px;height:400px;z-index:100;';
  div.appendChild(chartHolderDiv);
  document.body.appendChild(div);

  // @ts-ignore
  const Chart = charts[element.options.chart];

  if (!Chart) {
    return Promise.reject(`Undefined or wrong chart type "${element.options.chart}"`);
  }

  new Chart(chartHolderDiv, element);
  await new Promise((r) => setTimeout(r, 300));
  const scale = 2;
  return domToImage
    .toPng(chartHolderDiv, {
      quality: 1,
      width: 815 * scale,
      height: 415 * scale,
      style: {transform: 'scale(' + scale + ')', transformOrigin: 'top left'},
      filter: (node: any) => {
        // @ts-ignore
        return !(node.classList && (node.classList.contains('bx--cc--toolbar') || node.classList.contains('zoom-bar')));
      },
    })
    .then((dataUrl: string) => {
      document.body.removeChild(div);
      return dataUrl;
    });
};
