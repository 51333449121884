import {
  FileRequestApproveApi,
  GetModelsForDynamicFiltersRequest,
  ModelApi,
  ModelCloneDTO,
  ModelsApi,
  UpdatableModelDTO,
} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';
import {clearBlacklistedMetadata} from './utils';
import {ModelMetadata} from '../modelMetadata/types';

export const prepareMetadataInRequest = <T extends {metadata: ModelMetadata}>(model: T): T => {
  return {...model, metadata: clearBlacklistedMetadata(model.metadata)};
};

const modelsApi = new ModelsApi(configuration);
const modelApi = new ModelApi(configuration);
const fileRequestApproveApi = new FileRequestApproveApi(configuration);

export const requestModelList = () => modelsApi.getModelList();

export const requestEligibleModelList = () => modelsApi.getCreatorStewardOwnerModels();

export const requestModelListForDynamicFilers = (requestParameters: GetModelsForDynamicFiltersRequest) =>
  modelsApi.getModelsForDynamicFilters(requestParameters);

export const requestModelDetail = (modelUuid: string) => modelsApi.getModel({modelUuid});
export const requestModelNameExists = (model: UpdatableModelDTO) =>
  modelsApi
    .modelExistsByModelParameters({updatableModelDTO: prepareMetadataInRequest(model)})
    .then((value) => value === 'true');
export const requestModelCreate = (model: UpdatableModelDTO) =>
  modelsApi.createModel({updatableModelDTO: prepareMetadataInRequest(model)});
export const requestModelUpdate = (modelUuid: string, model: UpdatableModelDTO) =>
  modelsApi.updateModel({modelUuid, updatableModelDTO: prepareMetadataInRequest(model)});

export const requestModelPublish = (modelUuid: string) => modelsApi.publishModel({modelUuid});

export const requestModelRetire = (modelUuid: string, reason: string) => modelApi.retireModel({modelUuid, reason});

export const requestModelDownload = (modelUuid: string) => modelsApi.downloadModelRaw({modelUuid});

export const requestModelDownloadAll = (history = true) => modelsApi.downloadAllModelListRaw({history});

export const requestSendModelAccessRequested = (modelUuid: string, reason: string) =>
  modelsApi.sendModelAccessRequested({modelUuid, reason});

export const approveModelAccessRequest = (modelUuid: string, role: string, userId: string) =>
  modelsApi.approveModelAccessRequest({modelUuid, role, userId});

export const approveFileAccessRequest = (modelUuid: string, fileUuid: string, userId: string, fileName: string) =>
  fileRequestApproveApi.approveFileAccessRequest({modelUuid, fileUuid, userId, fileName});

export const requestModelClone = (modelUuid: string, modelClone: ModelCloneDTO) =>
  modelsApi.cloneModel({
    modelUuid,
    modelCloneDTO: prepareMetadataInRequest(modelClone),
  });

export const requestModelCloneAll = (modelUuid: string) => modelsApi.cloneModelAll({modelUuid});

export const requestModelShareEmail = (modelUuid: string, isids: string[], messageText: string) =>
  modelsApi.sendModelShareEmail({modelUuid, shareInstanceDTO: {isids, messageText}});

export const requestModelDeleteDraft = (modelUuid: string) => modelsApi.deleteDraftModel({modelUuid});
