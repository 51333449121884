import {useCallback, useState} from 'react';

// round to 2 decimals to avoid floating point computational errors
const roundZoom = (zoom: number) => Math.round(zoom * 100) / 100;

export const useZoom = (initialZoom = 1, defaultZoom = 1, increment = 0.1, min = increment, max = defaultZoom * 2) => {
  const [zoom, setZoom] = useState(initialZoom);
  const zoomIn = useCallback(() => setZoom(roundZoom(zoom + increment)), [zoom, setZoom, increment]);
  const zoomOut = useCallback(() => setZoom(roundZoom(zoom - increment)), [zoom, setZoom, increment]);
  const zoomReset = useCallback(() => setZoom(defaultZoom), [setZoom, defaultZoom]);
  const isDefault = zoom === defaultZoom;
  const isMin = zoom <= min;
  const isMax = zoom >= max;
  return {
    zoom,
    zoomIn,
    zoomOut,
    zoomReset,
    isDefault,
    isMin,
    isMax,
  };
};
