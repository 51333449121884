export enum CustomSettingsActions {
  INIT = 'CUSTOM_SETTINGS/INIT',
  UPDATE = 'CUSTOM_SETTINGS/UPDATE',
}

export type CustomSettings = {
  isSidebarCollapsed: boolean;
};

export type CustomSettingsActionInit = {
  type: CustomSettingsActions.INIT;
  customSettings: Partial<CustomSettings>;
};

export type CustomSettingsActionUpdate = {
  type: CustomSettingsActions.UPDATE;
  customSettings: Partial<CustomSettings>;
};

export type CustomSettingsAction = CustomSettingsActionInit | CustomSettingsActionUpdate;
