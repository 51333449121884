import React, {useCallback, useRef} from 'react';
import {InputGenerator} from '../types';
import {InputType, SelectorInputAttributes} from '../../InputDefinition/types';
import {FormControl, Validators} from 'react-reactive-form';
import InputHeader from './InputHeader';
import {Radio, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {RadioProps} from '@material-ui/core/Radio/Radio';

type SelectorInputProps = {
  control: FormControl;
  definition: SelectorInputAttributes;
  disabled?: boolean;
  renderStatic?: boolean;
};

export const SelectorInput: React.FC<SelectorInputProps> = ({control, definition, disabled, renderStatic}) => {
  const {rows, columns} = definition;
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      control.setValue(event.target.value);
    },
    [control],
  );

  return (
    <>
      <InputHeader definition={definition} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columns.map((column) => (
              <TableCell variant="head" key={column.text}>
                {column.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow key={row}>
                <TableCell variant="head">{row}</TableCell>
                {columns.map((column) => (
                  <TableCell key={column.cellText[rowIndex]}>{column.cellText[rowIndex]}</TableCell>
                ))}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell></TableCell>
            {columns.map((column) => (
              <TableCell key={column.key}>
                <RadioWithFocus
                  disabled={renderStatic ? true : disabled}
                  checked={control.value === column.key}
                  onChange={handleChange}
                  value={column.key}
                  style={{backgroundColor: 'transparent'}}
                  size="small"
                  tabIndex={0}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const RadioWithFocus = (props: RadioProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const actionRef = useRef<any>();

  const handleFocus = useCallback(() => {
    inputRef.current?.focus();
    actionRef.current?.focusVisible();
  }, [inputRef, actionRef]);

  return <Radio {...props} inputRef={inputRef} onFocus={handleFocus} action={actionRef} />;
};

export const selectorInputGenerator: InputGenerator<InputType.SELECTOR> = {
  type: InputType.SELECTOR,
  buildControl: (definition, initialValues) => {
    const validators = [];
    if (definition.required) {
      validators.push(Validators.required);
    }
    return new FormControl(initialValues, validators);
  },
  render: SelectorInput,
  renderStatic: function renderStatic(p: any) {
    return <SelectorInput {...p} renderStatic />;
  },
};
