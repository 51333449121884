import {getModelSteward, useModelDetail} from '../../../../../store/model';
import styles from './styles';
import {Grid} from '@material-ui/core';
import React from 'react';
import {UserCard} from '../../../../core/UserCard/UserCard';

export type ModelTextOverviewUsersProps = {
  modelId: string;
};

export const ModelTextOverviewUsers: React.FC<ModelTextOverviewUsersProps> = ({modelId}) => {
  const model = useModelDetail(modelId);
  const modelSteward = model ? getModelSteward(model) : null;
  return (
    <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
      {model && (
        <>
          {modelSteward && (
            <div className={styles.userCardWrapper}>
              <UserCard label="Steward Name" user={modelSteward} />
            </div>
          )}
          {model.vestLead && (
            <div className={styles.userCardWrapper}>
              <UserCard label="Value and Implementation Lead" user={model.vestLead} />
            </div>
          )}
          <div className={styles.userCardWrapper}>
            <UserCard label="Creator Name" user={model.createdBy} />
          </div>
          {model.owners?.map((owner) => (
            <div key={owner.id} className={styles.userCardWrapper}>
              <UserCard label="Owner Name" user={owner} />
            </div>
          ))}
        </>
      )}
    </Grid>
  );
};
