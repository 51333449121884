import React from 'react';
import {FormArray} from 'react-reactive-form';
import {ColumnType, InputType} from '../../InputDefinition/types';
import {DateControl, TextFieldControl} from '../../../Form';
import {stringInputPrimitiveControlBuilder} from './StringInputPrimitive';
import {numberInputPrimitiveControlBuilder} from './NumberInputPrimitive';

type ArrayColumnPrimitiveProps = {
  columnFormArray: FormArray;
  index: number;
  type: InputType;
  disabled?: boolean;
  renderStatic?: boolean;
};

export const ArraySingleInputPrimitive: React.FC<ArrayColumnPrimitiveProps> = ({
  columnFormArray,
  index,
  type,
  disabled,
  renderStatic,
}) => {
  const Component = type === InputType.DATE ? DateControl : TextFieldControl;
  const control = columnFormArray.controls[index];

  if (!control) {
    return null;
  }

  if (renderStatic) {
    return control.value;
  }

  return control && <Component label="Value" control={control} name="value" disabled={disabled} strict={false} />;
};

export const arrayColumnControlBuilder = (column: ColumnType, initialValue: (string | number)[] = []) => {
  if (column.dataType === InputType.STRING) {
    return new FormArray(
      column.defaultValue.map((defaultValue, index) =>
        stringInputPrimitiveControlBuilder(
          {
            required: column.required,
            // @ts-ignore
            defaultValue: initialValue[index] ?? defaultValue,
            length: column.length,
          },
          initialValue[index],
        ),
      ),
    );
  }

  return new FormArray(
    column.defaultValue.map((defaultValue, index) =>
      numberInputPrimitiveControlBuilder(
        // @ts-ignore
        {...column, type: column.dataType, defaultValue: initialValue[index] ?? defaultValue},
        initialValue[index],
      ),
    ),
  );
};
