import React, {FunctionComponent} from 'react';
import {ReactComponent as PublicIcon} from '../../../assets/icons/classification/public.svg';
import {ReactComponent as ProprietaryIcon} from '../../../assets/icons/classification/proprietary.svg';
import {ReactComponent as SensitiveIcon} from '../../../assets/icons/classification/sensitive.svg';
import {ReactComponent as ConfidentialIcon} from '../../../assets/icons/classification/confidential.svg';
import {ReactComponent as PublicMarkIcon} from '../../../assets/icons/classification/public-mark.svg';
import {ReactComponent as ProprietaryMarkIcon} from '../../../assets/icons/classification/proprietary-mark.svg';
import {ReactComponent as SensitiveMarkIcon} from '../../../assets/icons/classification/sensitive-mark.svg';
import {ReactComponent as ConfidentialMarkIcon} from '../../../assets/icons/classification/confidential-mark.svg';
import {ReactComponent as ExclamationMarkIcon} from '../../../assets/icons/classification/exclamation-mark.svg';

const informationClassificationComponentMap: {[key: string]: FunctionComponent<React.SVGProps<SVGSVGElement>>} = {
  Public: PublicIcon,
  Proprietary: ProprietaryIcon,
  Sensitive: SensitiveIcon,
  Confidential: ConfidentialIcon,
};

const informationClassificationMarkComponentMap: {[key: string]: FunctionComponent<React.SVGProps<SVGSVGElement>>} = {
  Public: PublicMarkIcon,
  Proprietary: ProprietaryMarkIcon,
  Sensitive: SensitiveMarkIcon,
  Confidential: ConfidentialMarkIcon,
};

export type InformationClassificationIconProps = React.SVGProps<SVGSVGElement> & {
  classification: string;
  markOnly?: boolean;
};

export const InformationClassificationIcon: React.FC<InformationClassificationIconProps> = ({
  classification,
  markOnly = false,
  ...iconProps
}) => {
  const map = markOnly ? informationClassificationMarkComponentMap : informationClassificationComponentMap;
  const ClassificationIcon = map[classification];

  if (ClassificationIcon) {
    return <ClassificationIcon {...iconProps} />;
  }

  if (markOnly) {
    return <ExclamationMarkIcon {...iconProps} />;
  }

  return null;
};
