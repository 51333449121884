import {Gitgraph, templateExtend, TemplateName} from '@gitgraph/react';
import React, {useMemo} from 'react';
import {GitCommitDTO} from 'hemwb-api';

type GitHistoryChartProps = {
  history: GitCommitDTO[];
};

const transformInput = (history: GitCommitDTO[]) => {
  return history.map((commit) => {
    return {
      refs: ['HEAD', 'master'],
      hash: commit.id,
      hashAbbrev: commit.displayId,
      parents: commit.parents.map((p) => p.id),
      parentsAbbrev: commit.parents.map((p) => p.displayId),
      author: {
        name: commit.author.name,
        email: commit.author.emailAddress,
        timestamp: commit.authorTimestamp.getTime(),
      },
      committer: {
        name: commit.committer?.name || '',
        email: commit.committer?.emailAddress || '',
        timestamp: commit.committerTimestamp?.getTime() || 0,
      },
      subject: `${commit.committerTimestamp?.toLocaleString()} - ${commit.message}`,
      body: '',
      notes: '',
      stats: [],
    };
  });
};

const template = templateExtend(TemplateName.Metro, {
  commit: {
    message: {
      displayHash: false,
    },
  },
});

const GitHistoryChart: React.FC<GitHistoryChartProps> = ({history}) => {
  const formattedHistory = useMemo(() => transformInput(history), [history]);
  return (
    <Gitgraph options={{template}}>
      {(gitgraph) => {
        gitgraph.import(formattedHistory);
      }}
    </Gitgraph>
  );
};

export default GitHistoryChart;
