import React from 'react';
import {Tooltip} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom';
import {Permission} from '../../../permissions/usePermissions';
import ListItem from '../../core/materialui/ListItem/ListItem';

type SideBarLinkItemProps = {
  permission?: Permission;
  disabled?: boolean;
  selected?: boolean;
  linkTo: any;
  icon: React.ReactElement;
  text: string;
};

const SideBarLinkItem: React.FC<SideBarLinkItemProps> = ({icon, text, linkTo, ...rest}) => {
  return (
    <ListItem button component={Link} to={linkTo} {...rest} disableGutters selectedLeftIndicator>
      <Tooltip title={text}>
        <ListItemIcon style={{color: 'inherit'}}>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default SideBarLinkItem;
