export enum AuthExpirationActions {
  AUTH_EXPIRATION_SET = 'AUTH_EXPIRATION/SET',
}

export type AuthExpirationSetAction = {
  type: AuthExpirationActions.AUTH_EXPIRATION_SET;
  expiration: number;
};

export type AuthExpirationAction = AuthExpirationSetAction;
