import React, {useCallback, useMemo, useRef, useState} from 'react';
import {DialogGeneric, DialogGenericProps} from './DialogGeneric';

export const useDialogGenericPromise = (props: Omit<DialogGenericProps, 'open'>) => {
  const [isOpen, setIsOpen] = useState(false);

  const promiseResolve = useRef<(buttonIndex: number) => void>();
  const promiseReject = useRef<() => void>();

  const DialogComponent = useMemo(() => {
    return (
      <DialogGeneric
        {...props}
        buttons={props.buttons?.map((b, index) => ({
          ...b,
          onClick: () => {
            setIsOpen(false);
            promiseResolve.current?.(index);
          },
        }))}
        open={isOpen}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, promiseResolve]);

  const openDialog = useCallback(() => {
    setIsOpen(true);
    return new Promise<number>((resolve, reject) => {
      promiseResolve.current = resolve;
      promiseReject.current = reject;
    });
  }, [promiseResolve, promiseReject]);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    promiseReject.current?.();
  }, [promiseReject]);

  return {DialogComponent, openDialog, closeDialog};
};
