import React from 'react';
import {Container, Grid} from '@material-ui/core';
import {CloudServices} from '@carbon/pictograms-react';
import PageHeaderTitle from '../../core/Header/PageHeader/PageHeaderTitle';
import Stepper, {StepStatus} from '../../core/materialui/Stepper';
import {modelUploadSteps} from './types';
import {styles} from './styles';

type ModelUploadHeaderProps = {
  activeStep: number;
  setActiveStep: (index: number) => void;
  getStepStatus: (index: number) => StepStatus;
};

const getTooltip = (status: StepStatus): string | undefined => {
  if (status === StepStatus.ERROR) {
    return 'There is an error';
  }
  if (status === StepStatus.WARNING) {
    return 'There is a warning';
  }
};

const ModelUploadHeader: React.FC<ModelUploadHeaderProps> = ({activeStep, setActiveStep, getStepStatus}) => {
  return (
    <Container maxWidth={false} className="pageHeader">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <PageHeaderTitle
          xl={5}
          lg={3}
          title="Add Model Entry"
          label="Add model files, documentation, and metadata"
          Icon={<CloudServices className="icon32" />}
          IconNextToTitle
        />
        <Grid item xs={12} md={12} lg={9} xl={7}>
          <Stepper
            activeStep={activeStep}
            nonLinear
            steps={modelUploadSteps.map((title: string, index) => {
              const status = getStepStatus(index);
              return {
                title,
                status,
                onClick: () => setActiveStep(index),
                label: `Step ${index + 1}`,
                tooltip: activeStep !== index ? getTooltip(status) : undefined,
              };
            })}
            className={styles.stepper}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ModelUploadHeader;
