import React, {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Collapse, List, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {TrashCan16} from '@carbon/icons-react';
import {DragDropContext, Draggable, DraggableProvidedDraggableProps, Droppable} from 'react-beautiful-dnd';
import RootRef from '@material-ui/core/RootRef';
import {defaultSubmitButtonProps} from '../../Buttons/SubmitButton';
import {createPortal} from 'react-dom';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import ListItem from '../../materialui/ListItem/ListItem';
import {getInputDefinitionCategories} from './utils';
import {ExecutionSetupType} from 'hemwb-api';

type InputListItem = {
  title: string;
  id: number;
  categoryId: string;
  valid: boolean;
};

type InputListProps = {
  subModelType: ExecutionSetupType;
  definitions: InputListItem[];
  setSelectedInputId: (id?: number) => void;
  selectedInputId?: number;
  setSelectedCategoryId: (id?: string) => void;
  onInputOrderChange: (originalIndex: number, newIndex: number, categoryId: string) => void;
  onInputAdd: () => void;
  onInputDelete: (inputId: number) => void;
  inputListPortalRef?: RefObject<HTMLDivElement>;
};

const getItemStyle = (isDragging: boolean, draggableStyle: DraggableProvidedDraggableProps['style']) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const InputList: React.FC<InputListProps> = ({
  subModelType,
  definitions,
  onInputOrderChange,
  selectedInputId,
  setSelectedInputId,
  setSelectedCategoryId,
  onInputAdd,
  onInputDelete,
  inputListPortalRef,
}) => {
  const inputCategories = getInputDefinitionCategories(subModelType);

  const indexById = useMemo(() => {
    return definitions.reduce((acc, definition, index) => {
      acc[definition.id] = index;
      return acc;
    }, {} as any);
  }, [definitions]);

  const [openCategories, setOpenCategories] = useState<string[]>([]);
  const toggleOpenCategory = useCallback(
    (categoryId: string) => {
      if (openCategories.includes(categoryId)) {
        setOpenCategories(openCategories.filter((cId) => cId !== categoryId));
      } else {
        setOpenCategories([...openCategories, categoryId]);
      }
      setSelectedCategoryId(categoryId);
    },
    [openCategories, setSelectedCategoryId],
  );

  const openCategory = useCallback((categoryId) => {
    setOpenCategories((c) => [...c.filter((cId) => cId !== categoryId), categoryId]);
  }, []);

  useEffect(() => {
    if (selectedInputId) {
      const categoryId = definitions.find((d: any) => d.id === selectedInputId)?.categoryId;
      if (categoryId) {
        openCategory(categoryId);
        setSelectedCategoryId(categoryId);
      }
    }
  }, [definitions, selectedInputId, openCategory, setSelectedCategoryId]);

  const setSelectedValues = useCallback(
    (id, categoryId) => {
      setSelectedInputId(id);
      setSelectedCategoryId(categoryId);
    },
    [setSelectedInputId, setSelectedCategoryId],
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      onInputOrderChange(result.source.index, result.destination.index, result.destination.droppableId);
      // openCategory(result.destination.droppableId);
    },
    [onInputOrderChange],
  );

  const renderOutput = (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.keys(getInputDefinitionCategories(subModelType)).map((categoryId) => {
          const definitionsInCategory = definitions.filter((d) => d.categoryId === categoryId);
          const categoryOpen = openCategories.includes(categoryId);

          return (
            <Droppable droppableId={categoryId} key={categoryId}>
              {(droppableProvided, droppableState) => (
                <>
                  <ListItem
                    button
                    onClick={definitionsInCategory.length === 0 ? undefined : () => toggleOpenCategory(categoryId)}>
                    <ListItemText
                      secondary={`${inputCategories[categoryId]} (${definitionsInCategory.length})`}
                      secondaryTypographyProps={{
                        color: droppableState.isDraggingOver ? 'primary' : 'textSecondary',
                      }}
                    />
                    {definitionsInCategory.length === 0 ? null : categoryOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <RootRef rootRef={droppableProvided.innerRef}>
                    <List dense>
                      <Collapse in={categoryOpen} timeout="auto">
                        {definitionsInCategory.map((input) => {
                          const id = input.id;
                          const titleValue = input.title; // + ' ' + input.id + ' ' + indexById[id];

                          return (
                            <Draggable key={id} draggableId={String(id)} index={indexById[id]}>
                              {(draggableProvided, snapshot) => {
                                return (
                                  /*//@ts-ignore*/
                                  <ListItem
                                    button
                                    selectedLeftIndicator
                                    // onClick={() => setSelectedInputId(id === selectedInputId ? undefined : id)}
                                    onClick={() => setSelectedValues(id, categoryId)}
                                    selected={id === selectedInputId}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}>
                                    <ListItemText
                                      primary={titleValue || 'EMPTY'}
                                      primaryTypographyProps={{color: input.valid ? 'textPrimary' : 'error'}}
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => onInputDelete(input.id)}>
                                        <TrashCan16 />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </Collapse>
                      {droppableProvided.placeholder}
                    </List>
                  </RootRef>
                </>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>

      <br />
      <Button
        {...defaultSubmitButtonProps}
        type="button"
        size="small"
        className=""
        onClick={onInputAdd}
        style={{marginRight: '2rem', float: 'right'}}>
        Add
      </Button>
    </div>
  );

  if (inputListPortalRef && inputListPortalRef.current) {
    return createPortal(renderOutput, inputListPortalRef.current);
  }

  return renderOutput;
};

export default InputList;
