import React, {useMemo, useState} from 'react';
import {DialogGeneric} from '../dialog/DialogGeneric';
import {tid} from '../../../testUtils';
import {VersionInfo} from './VersionInfo';

export type AboutDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const AboutDialog: React.FC<AboutDialogProps> = ({open, onClose}) => {
  return (
    <DialogGeneric
      open={open}
      buttons={[
        {
          onClick: onClose,
          children: 'Close',
          color: 'default',
          variant: 'contained',
          ...tid('dialog-about-btn-close'),
        },
      ]}
      title="About Model Repository"
      PaperProps={tid('dialog-close') as {}}>
      <VersionInfo />
    </DialogGeneric>
  );
};

export type AboutDialogOpenProps = {
  onClose?: AboutDialogProps['onClose'];
};

export const useAboutDialog = () => {
  const [openProps, setOpenProps] = useState<AboutDialogOpenProps>();
  const AboutDialogComponent = useMemo(
    () =>
      !openProps ? null : (
        <AboutDialog
          open={true}
          onClose={() => {
            openProps.onClose && openProps.onClose();
            setOpenProps(undefined);
          }}
        />
      ),
    [openProps, setOpenProps],
  );

  return {
    openAboutDialog: setOpenProps as (props: AboutDialogOpenProps) => void,
    AboutDialog: AboutDialogComponent,
  };
};
