import {Dispatch} from 'react';
import {Action} from 'redux';

export enum GridActions {
  UPDATE_STATE = 'GRID/UPDATE_STATE',
  SET_STATE = 'GRID/SET_STATE',
}

export type GridStateSetAction<F> = Action<GridActions.SET_STATE> & {
  gridId: string;
  state: {[key in keyof GridState<F>]?: GridState<F>[key]};
};

export type GridStateUpdateAction<F> = Action<GridActions.UPDATE_STATE> & {
  gridId: string;
  update: {[key in keyof GridState<F>]?: GridState<F>[key]};
};

export type GridStateUpdateActionCreator<F> = {
  [key in keyof GridState<F>]: (value: GridState<F>[key]) => GridStateUpdateAction<F>;
};

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type RowId = string | number;

export type GridState<F = null> = {
  order: Order;
  orderBy: string | null;
  expandedRowId: null | RowId;
  page: number;
  rowsPerPage: number;
  filter: F | null;
  search: string;
  viewMyModels: boolean;
  dynamicPageViewMyModels: boolean;
};

type GridStateSetter<T extends keyof GridState<F>, F = any> = Dispatch<GridState<F>[T]>;

export type GridModifier<F> = {
  setOrder: GridStateSetter<'order'>;
  setOrderBy: GridStateSetter<'orderBy'>;
  setExpandedRowId: GridStateSetter<'expandedRowId'>;
  setPage: GridStateSetter<'page'>;
  setRowsPerPage: GridStateSetter<'rowsPerPage'>;
  setFilter: GridStateSetter<'filter', F>;
  setSearch: GridStateSetter<'search'>;
  setViewMyModels: GridStateSetter<'viewMyModels'>;
  setDynamicPageViewMyModels: GridStateSetter<'dynamicPageViewMyModels'>;
};

export type GridAction = GridStateUpdateAction<any> | GridStateSetAction<any>;
