import React, {useCallback} from 'react';
import {UserEditForm, UserEditFormValues} from './UserEditForm';
import {useHistory} from 'react-router-dom';
import {userSetUserType, useUserFromList} from '../../../../../store/user';
import {useDispatch} from 'react-redux';
import {trackUserTypeChanged} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {fullName} from '../../../../../store/user/utils';
import {getUrlRoute, Routes, useRoute} from '../../../../router/routes';
import AdminSpaceHeader from '../../common/AdminSpaceHeader/AdminSpaceHeader';
import AdminPage from '../../common/AdminPage/AdminPage';

const UserEdit: React.FC = () => {
  const {id} = useRoute<Routes.USER_EDIT>();
  const history = useHistory();
  const dispatch = useDispatch();
  const {user, notFound} = useUserFromList(id);
  if (notFound) {
    dispatch(messageAdd('User not found', MessageTypes.ERROR));
    history.push(getUrlRoute(Routes.USER_LIST));
  }

  const handleSubmit = useCallback(
    (values: UserEditFormValues) => {
      const {userType} = values;
      const onSuccess = () => {
        dispatch(messageAdd(`${fullName(user!)} has been updated`, MessageTypes.SUCCESS));
        history.push(getUrlRoute(Routes.USER_LIST));
      };
      if (userType === user!.userType) {
        onSuccess();
        return;
      }

      userSetUserType(dispatch, user!.id, userType).then(() => {
        trackUserTypeChanged();
        onSuccess();
      });
    },
    [dispatch, history, user],
  );

  return (
    <AdminPage style={{paddingLeft: '2rem'}}>
      <AdminSpaceHeader />
      {user && <UserEditForm user={user} onSubmit={handleSubmit} />}
    </AdminPage>
  );
};

export default UserEdit;
