import React, {ReactNode, SyntheticEvent} from 'react';
import {ListItemIcon, ListItemText} from '@material-ui/core';
import {Permission} from '../../../../permissions/types';
import styles from './ListItemBigButton.module.scss';
import ListItem from './ListItem';

type ListItemBigButtonProps = {
  selectedLeftIndicator?: boolean;
  selectedIndicatorColor?: string;
  permission?: Permission;
  disabled?: boolean;
  icon?: React.ReactElement;
  text: string | ReactNode;
  onClick?: (event: SyntheticEvent) => void;
  selected?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const ListItemBigButton: React.FC<ListItemBigButtonProps> = ({
  selectedLeftIndicator,
  icon,
  text,
  className,
  style,
  ...restProps
}) => {
  return (
    <ListItem
      button
      selectedLeftIndicator
      disableGutters
      component="div"
      className={styles.listItemBigButton}
      {...restProps}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default ListItemBigButton;
