import React from 'react';
import {ScenarioDTO} from 'hemwb-api';
import {useScenarioError} from '../../../../store/scenario';
import styles from './ScenarioDetail.module.scss';
import ModelTextOverviewTile from '../../model/common/ModelTextOverview/ModelTextOverviewTile';
import {Error32} from '@carbon/icons-react';

type ScenarioErrorProps = {
  scenario?: ScenarioDTO | null;
};

const ScenarioError: React.FC<ScenarioErrorProps> = ({scenario}) => {
  const executionErrors = useScenarioError(scenario);

  if (!executionErrors) {
    return null;
  }

  return (
    <div className={styles.executionError}>
      <ModelTextOverviewTile label={<>&nbsp;</>} Icon={<Error32 />} value="Execution Failed" />
      <div className={styles.executionErrorText}>
        {executionErrors.map((error) => (
          <p key={error.name + error.value}>
            <strong>{error.name}: </strong>
            {error.value}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ScenarioError;
