import React from 'react';
import {useLocation} from 'react-router';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {getUrlRoute, Routes, SubModelSection} from '../../../../router/routes';
import {tid} from '../../../../../testUtils';
import SideBarLinkItem from '../../../../layout/common/SideBarLinkItem';
import {ModelSectionActionProps} from '../../../actionMenuTypes';
import {
  canAccessCalibrationManagement,
  canAccessDisplayManagement,
  canAccessScenarioManagement,
} from '../../../../../permissions/usePermissions';
import {ListNumbered16, Settings16} from '@carbon/icons-react';

const SubModelListActionMenuLink: React.FC<Partial<ModelSectionActionProps> & {foreignContext?: boolean}> = ({
  model,
  section,
  foreignContext = false,
}) => {
  const location = useLocation();
  const link = model && section ? getUrlRoute(Routes.SUBMODEL_LIST, {modelUuid: model.uuid, section}) : '#';

  const displayAndScenarioSubModel = () => {
    return section === SubModelSection.DISPLAY
      ? {
          ...tid(SUBMODEL_ACTION_MENU_IDS.LIST_CALIBRATION),
          text: foreignContext ? 'Display Management' : 'Sub-model List',
        }
      : {
          ...tid(SUBMODEL_ACTION_MENU_IDS.LIST),
          text: foreignContext ? 'Scenario Management' : 'Sub-model List',
        };
  };

  const props =
    section === SubModelSection.CALIBRATION
      ? {
          ...tid(SUBMODEL_ACTION_MENU_IDS.LIST_CALIBRATION),
          text: foreignContext ? 'Calibration Management' : 'Sub-model List',
        }
      : displayAndScenarioSubModel();

  let permission = null;
  if (section === SubModelSection.CALIBRATION) {
    permission = canAccessCalibrationManagement(model);
  } else if (section === SubModelSection.SCENARIO) {
    permission = canAccessScenarioManagement(model);
  } else if (section === SubModelSection.DISPLAY) {
    permission = canAccessDisplayManagement(model);
  }

  return (
    <SideBarLinkItem
      selected={location.pathname === link}
      permission={permission}
      linkTo={link}
      icon={foreignContext ? <Settings16 /> : <ListNumbered16 />}
      {...props}
    />
  );
};

export default SubModelListActionMenuLink;
