import {useEffect, useRef} from 'react';

// AFAIK this should be a last-resort solution to prevent
// state updates after a component has been unmounted,
// but until I know a better one, at least I want to keep code DRY
export const useMounted = () => {
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
};
