import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {Checkbox} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const renderModelOptionItem = (option: ModelDTO, {selected}: any) => (
  <React.Fragment>
    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{marginRight: 8}} checked={selected} />
    {option.name}
  </React.Fragment>
);
