import React from 'react';
import {InputAttributes} from '../../InputDefinition/types';

type InputHeaderProps = {
  definition: InputAttributes;
};

const InputHeader: React.FC<InputHeaderProps> = ({definition}) => {
  return (
    <h3 id={`input-title-${definition.id}`} tabIndex={0} dangerouslySetInnerHTML={{__html: definition.question}} />
  );
};

export default InputHeader;
