import {FormGroup} from 'react-reactive-form';
import {ModelInputDetailDTO} from 'hemwb-api';

export enum InputType {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',

  INTEGER = 'INTEGER',
  REAL_NUMBER = 'REAL_NUMBER',
  DECIMAL = 'DECIMAL',
  DATE = 'DATE',

  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  ARRAY = 'ARRAY',

  RANGE = 'RANGE',
  SELECTOR = 'SELECTOR',
  LABEL = 'LABEL',

  FILE = 'FILE',
}

//{[c in InputType]: string}
export const inputTypes = {
  [InputType.STRING]: 'String',
  [InputType.BOOLEAN]: 'Boolean',
  [InputType.INTEGER]: 'Integer',
  [InputType.REAL_NUMBER]: 'Real Number',
  [InputType.DECIMAL]: 'Decimal',
  [InputType.DATE]: 'Date',
  [InputType.SINGLE_SELECT]: 'Single Select',
  [InputType.MULTI_SELECT]: 'Multi Select',
  [InputType.ARRAY]: 'Array',
  [InputType.RANGE]: 'Range',
  [InputType.SELECTOR]: 'Selector',
  [InputType.LABEL]: 'Label',
  [InputType.FILE]: 'File',
};

export const rangeInputTypes = {
  [InputType.INTEGER]: 'Integer',
  [InputType.REAL_NUMBER]: 'Real Number',
  [InputType.DECIMAL]: 'Decimal',
  [InputType.DATE]: 'Date',
};

export const arrayInputTypes = {
  [InputType.STRING]: 'String',
  [InputType.INTEGER]: 'Integer',
  [InputType.REAL_NUMBER]: 'Real Number',
  [InputType.DECIMAL]: 'Decimal',
  [InputType.DATE]: 'Date',
};

export type NumberTypeProps = {
  min?: number;
  max?: number;
  defaultValue?: number;
};

export type StringNumberTypeProps = {
  min?: string;
  max?: string;
  defaultValue?: string;
};

export type StringTypeProps = {
  length?: number;
  defaultValue?: string;
};

export const controlType = {
  HS: 'Hide/Show',
  ED: 'Enable/Disable',
};

export const expertiseLevel = {
  '250': '250 - Basic',
  '500': '500 - Intermediate',
  '750': '750 - Advanced',
  '1000': '1000 - Expert',
};

export const controlTypeAllowed = {
  [InputType.BOOLEAN]: true,
  [InputType.SINGLE_SELECT]: true,
  [InputType.SELECTOR]: true,
};

export type ControlledByType = {
  id: number;
  value: any;
};

export type CommonInputAttributes = {
  id: number;
  categoryId: string;
  name: string;
  title: string;
  question: string;
  visible?: boolean;
  required?: boolean;
  viewable?: boolean;
  controlType?: keyof typeof controlType;
  controlledBy?: ControlledByType;
  expertiseLevel?: keyof typeof expertiseLevel;
};

export type LabelSpecificInputAttributes = {
  type: InputType.LABEL;
  defaultValue?: string;
};
export type LabelInputAttributes = CommonInputAttributes & LabelSpecificInputAttributes;

export type StringSpecificInputAttributes = {
  type: InputType.STRING;
  length: number;
  defaultValue?: string;
};
export type StringInputAttributes = CommonInputAttributes & StringSpecificInputAttributes;

export type BooleanSpecificInputAttributes = {
  type: InputType.BOOLEAN;
  defaultValue?: boolean;
};
export type BooleanInputAttributes = CommonInputAttributes & BooleanSpecificInputAttributes;

export type IntegerSpecificInputAttributes = NumberTypeProps & {
  type: InputType.INTEGER;
};
export type IntegerInputAttributes = CommonInputAttributes & IntegerSpecificInputAttributes;

export type RealNumberSpecificInputAttributes = NumberTypeProps & {
  type: InputType.REAL_NUMBER;
};
export type RealNumberInputAttributes = CommonInputAttributes & RealNumberSpecificInputAttributes;

export type DecimalSpecificInputAttributes = NumberTypeProps & {
  type: InputType.DECIMAL;
};
export type DecimalInputAttributes = CommonInputAttributes & DecimalSpecificInputAttributes;

export type DateSpecificInputAttributes = StringNumberTypeProps & {type: InputType.DATE};
export type DateInputAttributes = CommonInputAttributes & DateSpecificInputAttributes;

export type NumericAttributes =
  | IntegerInputAttributes
  | RealNumberInputAttributes
  | DecimalInputAttributes
  | DateInputAttributes;

export type RangeSpecificInputAttributes = {
  type: InputType.RANGE;
  dataType: keyof typeof rangeInputTypes;
  from?: NumberTypeProps | StringNumberTypeProps;
  to?: NumberTypeProps | StringNumberTypeProps;
};
export type RangeInputAttributes = CommonInputAttributes & RangeSpecificInputAttributes;

export type SelectItem = {
  key: string;
  text: string;
  selected?: boolean;
};

export type SingleSelectInputAttributes = CommonInputAttributes & {
  type: InputType.SINGLE_SELECT;
  options: SelectItem[];
};

export type MultiSelectInputAttributes = CommonInputAttributes & {
  type: InputType.MULTI_SELECT;
  options: SelectItem[];
};

export type CommonColumnType = {
  text: string;
  required: boolean;
};

export type StringColumnType = CommonColumnType & {
  dataType: InputType.STRING;
  length: number;
  defaultValue: string[];
};

export type IntegerColumnType = CommonColumnType & {
  dataType: InputType.INTEGER;
  min?: number;
  max?: number;
  defaultValue: number[];
};

export type ColumnType = StringColumnType | IntegerColumnType;
export type ArraySpecificInputAttributes = {
  type: InputType.ARRAY;
  columns: ColumnType[];
  rows: string[];
  rowDescription?: string;
};
export type ArrayInputAttributes = CommonInputAttributes & ArraySpecificInputAttributes;

export type SelectorColumnType = {
  key: string;
  text: string;
  cellText: string[];
};

export type SelectorSpecificInputAttributes = {
  type: InputType.SELECTOR;
  columns: SelectorColumnType[];
  rows: string[];
};
export type SelectorInputAttributes = CommonInputAttributes & SelectorSpecificInputAttributes;

export type FileSpecificInputAttributes = {
  type: InputType.FILE;
  size: number;
  files: string;
};

export type FileInputAttributes = CommonInputAttributes & FileSpecificInputAttributes;

export type InputAttributes =
  | StringInputAttributes
  | BooleanInputAttributes
  | IntegerInputAttributes
  | RealNumberInputAttributes
  | DecimalInputAttributes
  | DateInputAttributes
  | SingleSelectInputAttributes
  | MultiSelectInputAttributes
  | ArrayInputAttributes
  | SelectorInputAttributes
  | RangeInputAttributes
  | LabelInputAttributes
  | FileInputAttributes;
type FindByType<Union, Type> = Union extends {type: Type} ? Union : never;

export type InputGenerator<T extends InputType> = {
  type: T;
  buildControl: (initialValues?: Partial<FindByType<InputAttributes, T>>) => FormGroup;
  render: any;
};

export const booleanOptions = [null, true, false];

export type CalibrationDTO = {
  id: ModelInputDetailDTO['id'];
  definitions: InputAttributes[];
};

export type InputCategory = {
  id: number;
  text: string;
};

export const inputCategories = {
  '3': 'Demographics',
  '12': 'Behavioral',
  '43': 'Screening',
  '6': 'Natural History of Disease',
  '9': 'Disease/Treatment Patterns',
  '46': 'Vaccine Assumptions',
  '15': 'Vaccination Strategy',
  '49': 'Costs',
  '52': 'QALYs',
  '18': 'Other',
} as {[categoryId: string]: string};

export const inputDisplayCategories = {
  '1': 'Content Options',
  '2': 'Presentation Options',
  '18': 'Other',
} as {[categoryId: string]: string};
