import {ModelTreeNode} from '../../../../../../store/modelFile/types';
import {ModelDTO} from 'hemwb-api';

export function calculateFilePath(fileName: string, webkitRelativePath?: string): string {
  let path = fileName;
  if (webkitRelativePath && webkitRelativePath !== '') {
    const chunks = webkitRelativePath.split('/');
    if (chunks.length > 2) {
      chunks[chunks.length - 1] = fileName;
      path = `${chunks.slice(1, chunks.length).join('/')}`;
    }
  }

  return path;
}

export function onDocumentDragOver(event: DragEvent) {
  event.preventDefault();
  event.preventDefault();
}

export enum ModelEditFilesMode {
  NONE = 'none',
  BITBUCKET = 'bitbucket',
  MLMP = 'mlmp',
}
export const getModelEditFilesMode = (model: ModelDTO, files?: ModelTreeNode[]): ModelEditFilesMode => {
  if (typeof model.metadata.bitbucket_project === 'string') {
    return ModelEditFilesMode.BITBUCKET;
  } else if (files && files.length > 0) {
    return ModelEditFilesMode.MLMP;
  }
  return ModelEditFilesMode.NONE;
};
