import {StepStatus} from '../../core/materialui/Stepper';
import {getSectionStatus, IssueSeverity, ModelEditSection, ModelIssue} from '../../../store/model/validator';

const sectionStatusToStepStatus = (status: IssueSeverity): StepStatus => {
  switch (status) {
    case IssueSeverity.OK:
      return StepStatus.COMPLETED;
    case IssueSeverity.WARNING:
      return StepStatus.WARNING;
    case IssueSeverity.ERROR_CREATE:
      return StepStatus.ERROR;
  }
  return StepStatus.DEFAULT;
};

export const getStepStatus = (
  step: number,
  visited: boolean,
  issues: ModelIssue[],
  suffixValidity: boolean,
): StepStatus => {
  if (!visited) {
    return StepStatus.DEFAULT;
  }
  if (step === ModelEditSection.SUFFIX) {
    return suffixValidity ? StepStatus.COMPLETED : StepStatus.ERROR;
  }
  return sectionStatusToStepStatus(getSectionStatus(step, issues));
};
