import {useCallback, useEffect} from 'react';
import {useScenarioDetailLoaderWithParams} from '../../../../store/scenario';
import {useExecutionSetupList} from '../../../../store/executionSetup';
import {ExecutionSetupType, ScenarioDetailDTO} from 'hemwb-api';
import {useModelInputLoaderWithParams} from '../../../../store/modelInputs';
import {useDispatch, useStore} from 'react-redux';
import {BooleanHashMap} from '../../../../commonTypes';
import {
  visualizationInitAnalysis,
  visualizationInitAnalysisData,
  visualizationSelector,
} from '../../../../store/visualization';
import {ScenarioGridFilter} from '../step1/types';
import {isScenarioParametersValid} from '../../../core/ModelInput/ScenarioParameters/isScenarioParametersValid';

export const useAnalysisLoader = (modelUuid: string, scenarioId?: string | number) => {
  const store = useStore();
  const dispatch = useDispatch();
  const scenarioSubModelList = useExecutionSetupList(modelUuid, ExecutionSetupType.SCENARIO);
  const calibrationSubModelList = useExecutionSetupList(modelUuid, ExecutionSetupType.CALIBRATION);
  const displaySubModelList = useExecutionSetupList(modelUuid, ExecutionSetupType.DISPLAY);

  const loadScenario = useScenarioDetailLoaderWithParams();
  const loadInput = useModelInputLoaderWithParams();

  const handleScenarioIdChanged = useCallback(
    async (scenarioId: number) => {
      if (!scenarioId || !scenarioSubModelList || !calibrationSubModelList || !displaySubModelList) {
        return;
      }

      if (visualizationSelector(store.getState()).analyseId === scenarioId) {
        return;
      }

      dispatch(visualizationInitAnalysis(scenarioId));

      let selection, parameters, openedSubModel;

      const scenarioDetail = (await loadScenario([scenarioId])) as ScenarioDetailDTO;
      if (!scenarioDetail) return;

      const displaySubModel = displaySubModelList.find((s) => s.id === scenarioDetail.executionSetupId);

      if (displaySubModel) {
        //
        //SET SELECTION
        //
        if (scenarioDetail.displayScenarios) {
          const scenarios = (await Promise.all(
            scenarioDetail.displayScenarios.map((association) => loadScenario([association.id])),
          )) as ScenarioDetailDTO[];

          const subModel =
            scenarios &&
            scenarios.length > 0 &&
            [...scenarioSubModelList, ...calibrationSubModelList].find((s) => s.id === scenarios[0].executionSetupId);

          if (subModel) {
            openedSubModel = subModel;
            selection = {
              subModel,
              scenarios,
              selectedScenarios: scenarios.reduce((acc, val) => {
                acc[val.id] = true;
                return acc;
              }, ({} as any) as BooleanHashMap),
            };
          }
        } else {
          //FIXME how can I find find openedSubModel without displayScenarios? Single Display SubModel can be linked to multiple SubModels
          //This shouldn't happen since at least one scenario has to be selected in order to save analysis
          return;
        }

        //
        //SET PARAMETERS
        //
        const displayInput = await loadInput([displaySubModel.id]);

        parameters = {
          parameters: scenarioDetail?.input?.value ? JSON.parse(scenarioDetail.input.value) : {},
          valid: displayInput ? isScenarioParametersValid(scenarioDetail, displayInput) : false,
          displaySubModelId: displaySubModel.id,
        };

        dispatch(
          visualizationInitAnalysisData(scenarioId, {
            modelUuid,
            selection,
            parameters,
            step1: {
              filter: ScenarioGridFilter.SAVED_ANALYSES,
              openedSubModel,
            },
          }),
        );
      } else {
        //TODO handle shared analysis
      }
    },
    [
      store,
      dispatch,
      modelUuid,
      loadScenario,
      loadInput,
      scenarioSubModelList,
      calibrationSubModelList,
      displaySubModelList,
    ],
  );

  useEffect(() => {
    if (scenarioId !== undefined && scenarioSubModelList && calibrationSubModelList && displaySubModelList) {
      const _scenarioId = Number(scenarioId);
      _scenarioId && handleScenarioIdChanged(_scenarioId);
    }
  }, [scenarioId, handleScenarioIdChanged, scenarioSubModelList, calibrationSubModelList, displaySubModelList]);
};
