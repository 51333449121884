/* eslint-disable @typescript-eslint/camelcase */
import {ModelDTO, ModelNodeDTO} from 'hemwb-api';
import {VersionFormValues} from './types';
import {ModelTreeNode} from '../../../../../../store/modelFile';
import nanoid from 'nanoid';

export type ModelFlatNode = {
  node: ModelNodeDTO['node'];
  parentId?: string;
  originalParentId?: null | string;
};

export const flattenHierarchy = (
  node: ModelNodeDTO,
  parentId: ModelFlatNode['parentId'] = undefined,
  flatNodes: ModelFlatNode[] = [],
): ModelFlatNode[] => {
  let uuid: string;
  if (node.node) {
    uuid = node.node.uuid;
    flatNodes.push({
      node: node.node,
      parentId,
      originalParentId: parentId,
    });
  } else {
    const notRestrictedChildNode = node.children?.find((n) => n.node);
    if (notRestrictedChildNode) {
      uuid = notRestrictedChildNode.node!.metadata.parentId;
    } else {
      uuid = nanoid();
    }
    flatNodes.push({
      node: undefined,
      parentId,
      originalParentId: parentId,
    });
  }

  if (node.children) {
    node.children.forEach((n) => flattenHierarchy(n, uuid, flatNodes));
  }

  return flatNodes;
};

export const getParentNode = (node: ModelNodeDTO, modelUuid: string): ModelNodeDTO | null => {
  if (node.children) {
    if (node.children.some((n) => n.node?.uuid === modelUuid)) {
      return node;
    }

    for (const n of node.children) {
      const parentNode = getParentNode(n, modelUuid);
      if (parentNode) {
        return parentNode;
      }
    }
  }

  return null;
};

export const getNodeByModelUuid = (node: ModelNodeDTO, modelUuid: string): ModelNodeDTO | null => {
  if (node.node?.uuid === modelUuid) {
    return node;
  }

  if (node.children) {
    for (const n of node.children) {
      const found = getNodeByModelUuid(n, modelUuid);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

export const mapVersionFormValuesToModel = (values: VersionFormValues, model: ModelDTO): ModelDTO => {
  const {changeLogFileName, ...rest} = values;
  return {
    ...model,
    metadata: {
      ...model.metadata,
      ...rest,
    },
  };
};

export const transformModelToVersionFormValues = (model: ModelDTO, files: ModelTreeNode[]): VersionFormValues => {
  return {
    version_release_of_model: model.metadata.version_release_of_model || '',
    version_log: model.metadata.version_log || '',
    changelog_file: model.metadata.changelog_file || '',
    changeLogFileName: model.metadata.changelog_file
      ? files.find((node) => node.uuid === model.metadata.changelog_file)?.name || ''
      : '',
  };
};

export const isVersionSectionChanged = (model1: ModelDTO, model2: ModelDTO): boolean =>
  model1.metadata.version_release_of_model !== model2.metadata.version_release_of_model ||
  model1.metadata.version_log !== model2.metadata.version_log;
