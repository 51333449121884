import {markAllAsTouched} from '../../Form';
import {FormArray, FormGroup} from 'react-reactive-form';
import {generators} from './generators';
import {InputAttributes} from './types';

export const inputDefinitionFormGenerator = (data: InputAttributes[], handleChangeType?: Function) => {
  return new FormGroup({
    definitions: new FormArray(
      data.map((attr) => {
        const formGroup = generators[attr.type].buildControl(attr as any);
        markAllAsTouched(formGroup);
        if (handleChangeType) {
          formGroup.controls.type.valueChanges.subscribe(handleChangeType(formGroup));
        }
        return formGroup;
      }),
    ),
  });
};
