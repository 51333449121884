import React from 'react';
import {SheetOrMacroDiffDTO} from './types';
import {IconButton} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

type PaginationProps = {
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  diff: SheetOrMacroDiffDTO;
};

const Pagination: React.FC<PaginationProps> = ({pageIndex, setPageIndex, diff}) => {
  if (diff.maxPageIndex < 1) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        marginLeft: 399,
        display: 'flex',
        background: 'white',
        border: '1px solid black',
        width: 200,
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
      }}>
      <IconButton disabled={pageIndex === 0} onClick={() => setPageIndex(pageIndex - 1)}>
        <NavigateBeforeIcon />
      </IconButton>
      Page {pageIndex + 1} / {diff.maxPageIndex + 1}
      <IconButton disabled={pageIndex === diff.maxPageIndex} onClick={() => setPageIndex(pageIndex + 1)}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
};

export default Pagination;
