import React, {ReactNode} from 'react';
import {GridProps, Grid} from '@material-ui/core';

type PageHeaderTitleProps = Partial<GridProps> & {
  title: string;
  label: string;
  Icon: ReactNode;
  IconNextToTitle?: boolean;
};

const PageHeaderTitle: React.FC<PageHeaderTitleProps> = ({
  title,
  label,
  Icon,
  IconNextToTitle = false,
  ...gridProps
}) => {
  return (
    <Grid container direction="row" alignItems="center" item {...gridProps}>
      <Grid item>
        <h1>
          {title} {IconNextToTitle && Icon}
        </h1>
        <p>{label}</p>
      </Grid>
      {!IconNextToTitle && <Grid item>{Icon}</Grid>}
    </Grid>
  );
};

export default PageHeaderTitle;
