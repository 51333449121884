import {LoginForm} from './LoginForm';
import {Toolbar} from '@material-ui/core';
import {tid} from '../../testUtils';
import React from 'react';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import styles from './LoginPage.module.scss';
import {BoxLayout} from '../layout/BoxLayout';
import {VersionInfo} from '../core/AboutDialog/VersionInfo';

const LoginPageFooter = () => (
  <Toolbar variant="dense" {...tid('app-version')}>
    <div className={styles.version}>
      <LogoIcon className={styles.footerIcon} />
      <div>
        <VersionInfo />
      </div>
    </div>
  </Toolbar>
);

export const LoginPage: React.FC = () => (
  <BoxLayout footer={<LoginPageFooter />}>
    <LoginForm />
  </BoxLayout>
);
