import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {useModelPublishSideBarModal} from './useModelPublishSideBarModal';
import {canPublishModel} from '../../../../../permissions/usePermissions';
import {ModelDetailActionProps} from '../../../actionMenuTypes';
import {Upload16} from '@carbon/icons-react';

const PublishModelActionMenuButton: React.FC<Partial<ModelDetailActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelPublishSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.PUBLISH)}
        permission={canPublishModel(props.model)}
        onClick={openModal}
        icon={<Upload16 />}
        text="Publish Model Entry"
      />
      {ModalComponent}
    </>
  );
};

export default PublishModelActionMenuButton;
