import {ModelNodeDTO} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';
import {requestModelHierarchyRootsByMetadata} from './api';
import {StoredModel} from '../model/types';

export enum ModelLinksActions {
  FETCH_HIERARCHY = 'MODEL_LINKS/FETCH_HIERARCHY',
  FETCH_ROOTS_BY_METADATA = 'MODEL_LINKS/FETCH_ROOTS_BY_METADATA',
  SET_PARENT = 'MODEL_LINKS/SET_PARENT',
}

export type ModelLinksAction =
  | AsyncAction<ModelLinksActions.FETCH_HIERARCHY, ModelNodeDTO, [string]>
  | AsyncAction<
      ModelLinksActions.FETCH_ROOTS_BY_METADATA,
      ModelNodeDTO[],
      Parameters<typeof requestModelHierarchyRootsByMetadata>
    >
  | AsyncAction<ModelLinksActions.SET_PARENT, void, [string, string | null, boolean | undefined]>;

export type ModelFlatNode<M = StoredModel> = {
  model: M;
  parentId?: string;
  originalParentId?: string;
};
