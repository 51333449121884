import {
  CreateExecutionSetupRequest,
  ExecutionSetupApi,
  ExecutionSetupType,
  ExecutionSetupUpdateDTO,
  GetExecutionSetupListOrderByEnum,
} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const executionSetupApi = new ExecutionSetupApi(configuration);

export const requestExecutionSetupList = (modelUuid: string, type: ExecutionSetupType) =>
  executionSetupApi.getExecutionSetupList({
    modelUuid: modelUuid,
    orderBy: GetExecutionSetupListOrderByEnum.ModifiedOn,
    type: type,
  });

export const requestExecutionSetup = (id: number) => executionSetupApi.getExecutionSetup({id});

export const requestExecutionSetupCreate = (
  modelUuid: string,
  executionSetup: CreateExecutionSetupRequest['executionSetupCreateDTO'],
) => executionSetupApi.createExecutionSetup({modelUuid, executionSetupCreateDTO: executionSetup});

export const requestExecutionSetupUpdate = (id: number, executionSetupUpdateDTO: ExecutionSetupUpdateDTO) =>
  executionSetupApi.setExecutionOptions({id, executionSetupUpdateDTO});

export const requestExecutionSetupDelete = (id: number) => executionSetupApi.deleteExecutionSetup({id});
