import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {AbstractControl, FieldControl, GroupProps} from 'react-reactive-form';
import {TextFieldProps} from '@material-ui/core/TextField';
import {CircularProgress, FormControl, InputLabel, TextField} from '@material-ui/core';
import Autocomplete, {AutocompleteProps} from '@material-ui/lab/Autocomplete';
import debounce from 'debounce-promise';
import {tid} from '../../../testUtils';
import {getVisibleErrors, getErrorText} from './utils';
import clsx from 'clsx';

export type AutocompleteControlProps = GroupProps & {
  name: Required<GroupProps['name']>;
  label: React.ReactNode;
  TextFieldProps?: TextFieldProps;
  AutocompleteProps: Omit<AutocompleteProps, 'renderInput'>;
  disableNoOptionsForEmptySearch?: boolean;
  wait?: number;
  search?: (inputValue: string) => Promise<any[]>;
  loading?: boolean;
  plural?: boolean;
  required?: boolean;
  disableDeletingTagsByKeyboard?: boolean;
  disabled?: boolean;
};

const stopEventPropagation = (event: Event) => event.stopPropagation();

export const AutocompleteControl: React.FC<AutocompleteControlProps> = ({
  TextFieldProps,
  AutocompleteProps,
  disableNoOptionsForEmptySearch = false,
  wait = 0,
  search,
  loading = false,
  label,
  plural,
  required,
  disableDeletingTagsByKeyboard = false,
  disabled = false,
  ...FieldControlProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const debouncedSearchRef = useRef(search ? debounce(search, wait) : null);

  const inputRef = useRef<HTMLInputElement>();

  const setInputRef = useCallback(
    (ref: typeof inputRef) => {
      if (inputRef.current !== ref.current) {
        inputRef.current = ref.current;
        inputRef.current &&
          disableDeletingTagsByKeyboard &&
          inputRef.current.addEventListener('keydown', stopEventPropagation);
      }
    },
    [disableDeletingTagsByKeyboard],
  );
  useEffect(() => {
    return () => {
      inputRef.current &&
        disableDeletingTagsByKeyboard &&
        inputRef.current.removeEventListener('keydown', stopEventPropagation);
    };
  }, [disableDeletingTagsByKeyboard]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (!search) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      setIsLoading(false);
      return undefined;
    }

    setIsLoading(true);
    let active = true;

    debouncedSearchRef.current!(inputValue).then((response) => {
      if (active) {
        setIsLoading(false);
        setOptions(response || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, search]);

  const {getOptionLabel: passedGetOptionLabel} = AutocompleteProps;

  const getOptionLabel = useCallback(
    (option: any) => {
      if (option && passedGetOptionLabel) {
        return passedGetOptionLabel(option);
      }
      return option;
    },
    [passedGetOptionLabel],
  );

  return (
    <FieldControl
      strict={false}
      {...FieldControlProps}
      render={(renderProps) => {
        const {handler, pending} = renderProps;
        const visibleErrors = getVisibleErrors(renderProps);
        const hasVisibleErrors = Object.keys(visibleErrors).length > 0;

        const {onChange, ...restHandlers} = handler();

        return (
          <FormControl fullWidth error={hasVisibleErrors} hiddenLabel={true} className="MuiFormControl-hem">
            <InputLabel shrink required={required ?? !!renderProps.validator?.({} as AbstractControl)}>
              {label}
            </InputLabel>
            <Autocomplete
              disablePortal
              loading={isLoading || loading || pending}
              {...AutocompleteProps}
              {...(search ? {options: isLoading || loading || pending ? [] : options, filterOptions: (x) => x} : {})}
              {...restHandlers}
              disabled={disabled || restHandlers.disabled}
              onChange={(x, value) => onChange(value)}
              getOptionLabel={getOptionLabel}
              {...tid('input', FieldControlProps.name!)}
              onClose={() => {
                if (!AutocompleteProps['disableClearable']) {
                  setInputValue('');
                }
              }}
              className={clsx({
                emptySearch: !inputValue,
                emptyValue: !restHandlers.value,
                disableNoOptionsForEmptySearch,
              })}
              renderInput={(params) => {
                // @ts-ignore
                setInputRef(params.inputProps.ref);
                return (
                  <TextField
                    fullWidth
                    label=""
                    hiddenLabel={true}
                    margin="dense"
                    {...TextFieldProps}
                    variant="filled"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
                    error={hasVisibleErrors}
                    helperText={hasVisibleErrors && getErrorText(visibleErrors, String(label), plural)}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'no',
                      ...tid('input-field', FieldControlProps.name!),
                      disabled: disabled || restHandlers.disabled,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {(isLoading || loading || pending) && (
                            <CircularProgress
                              {...tid('input-loading', FieldControlProps.name!)}
                              color="inherit"
                              size={20}
                            />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                );
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};
