import React, {useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ModelDownloadAllSideBarModal from './ModelDownloadAllSideBarModal';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {canDownloadAllModels} from '../../../../../permissions/usePermissions';
import {Package16} from '@carbon/icons-react';

const ModelDownloadSideBarModalMenuButton: React.FC = () => {
  const {buttonRef, openModal, closeModal} = useSideBarModal();

  const Modal = useMemo(() => {
    return (
      <SideBarModal
        className="whiteTheme"
        isOpen={!!buttonRef}
        buttonRef={buttonRef}
        closeModal={closeModal}
        height={250}>
        <div style={{width: '40rem', paddingLeft: '3rem'}}>
          {!!buttonRef && (
            <ModelDownloadAllSideBarModal isOpen={!!buttonRef} closeModal={closeModal} buttonRef={buttonRef} />
          )}
        </div>
      </SideBarModal>
    );
  }, [buttonRef, closeModal]);

  return (
    <>
      <SideBarButtonItem
        {...tid(MODEL_ACTION_MENU_IDS.DOWNLOAD_ALL_ENTRIES)}
        permission={canDownloadAllModels()}
        onClick={openModal}
        icon={<Package16 />}
        text="Download All Entries"
      />
      {Modal}
    </>
  );
};

export default ModelDownloadSideBarModalMenuButton;
