import {DefaultApi} from 'hemwb-api';
import {configuration} from '../getBackendConfiguration';

const notificationApi = new DefaultApi(configuration);

export const requestNotificationCreation = (modelUuid: string, action: string, userNote: string) =>
  notificationApi.createNotification({
    notificationRequest: {
      modelUuid: modelUuid,
      action: action,
      userNote: userNote,
    },
  });
