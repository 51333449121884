import React, {useCallback, useEffect} from 'react';
import {SideBarModalProps} from '../../../../../layout/common/SideBarModal';
import {Button, Grid} from '@material-ui/core';
import {AutocompleteControl, Form, TextFieldControl, useForm} from '../../../../../core/Form';
import {FormControl, FormGroup} from 'react-reactive-form';
import CancelButton from '../../../../../core/Buttons/CancelButton';

import GridTitle from '../../../../../core/Form/GridTitle';
import {UserProfile} from '@carbon/pictograms-react';
import GridItem from '../../../../../core/Form/GridItem';
import {getCommonFieldProps, hardCodedOptions} from '../ModelEditMetadata/constants';
import {
  userExpertiseLoadList,
  userExpertiseLoadOptionsSet,
  useUserExpertise,
} from '../../../../../../store/userExpertise';

import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useDispatch, useSelector} from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {versionControlDiffSelectionSelector} from '../../../../../../store/versionControl';

type ExpertiseModalProps = SideBarModalProps & {
  user?: any;
};

const AddUserExpertiseLevelForm: React.FC<ExpertiseModalProps> = (props) => {
  const dispatch = useDispatch();

  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);
  const [modelUuid] = versionDiffSelection;

  const userid = props.user.id;

  const expertiseLevel = useUserExpertise(modelUuid, userid);

  const form = useForm(
    new FormGroup({
      firstName: new FormControl(props.user.firstName),
      lastName: new FormControl(props.user.lastName),
      email: new FormControl(props.user.email),
      id: new FormControl(props.user.id),
      expertiseLevel: new FormControl(null),
    }),
  );

  useEffect(() => {
    form.controls['expertiseLevel'].setValue(expertiseLevel?.expertiseTypeLevel);
  }, [form, expertiseLevel]);

  const handleClose = useCallback(() => {
    props.closeModal();
  }, [props]);

  const handleSubmit = useCallback(() => {
    return userExpertiseLoadOptionsSet(dispatch, modelUuid, {
      userId: props.user.id,
      expertiseTypeLevel: form.controls.expertiseLevel.value,
    }).then(() => {
      dispatch(messageAdd('Expertise Level updated', MessageTypes.SUCCESS));
      props.closeModal();
      return userExpertiseLoadList(dispatch, modelUuid, props.user.id);
    });
  }, [dispatch, modelUuid, props, form.controls]);

  return (
    <>
      <Form
        className=""
        group={form}
        render={() => {
          return (
            <>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                style={{paddingLeft: '3rem'}}>
                <Grid
                  container
                  item
                  direction="column"
                  sm={12}
                  md={12}
                  style={{paddingRight: '3rem', marginBottom: '3rem'}}>
                  <GridTitle Icon={<UserProfile className="icon32" />}>
                    User
                    <br />
                    <small style={{fontSize: 12}}>Assign Expertise Level</small>
                  </GridTitle>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  sm={12}
                  md={6}
                  style={{paddingRight: '3rem', marginBottom: '3rem'}}>
                  <Grid item>
                    <GridItem>
                      <TextFieldControl name="firstName" label="First Name" disabled={true} />
                    </GridItem>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  sm={12}
                  md={6}
                  style={{paddingRight: '3rem', marginBottom: '3rem'}}>
                  <Grid item>
                    <GridItem>
                      <TextFieldControl name="lastName" label="Last Name" disabled={true} />
                    </GridItem>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  sm={12}
                  md={6}
                  style={{paddingRight: '3rem', marginBottom: '3rem'}}>
                  <Grid item>
                    <GridItem>
                      <TextFieldControl name="email" label="Email" disabled={true} />
                    </GridItem>
                  </Grid>
                </Grid>
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <Grid item>
                    <GridItem>
                      <TextFieldControl name="id" label="ISID" disabled={true} />
                    </GridItem>
                  </Grid>
                </Grid>
                <Grid container item direction="column" sm={12} md={6} style={{paddingRight: '3rem'}}>
                  <Grid item>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('expertiseLevel')}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: hardCodedOptions['expertiseLevel'],
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Grid>
              </Grid>
              <div className="buttonsContainer">
                <br />
                <br />
                <CancelButton onClick={handleClose} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="button"
                  size="large"
                  endIcon={
                    <CheckCircleOutlineIcon style={{width: '1.6rem', height: '1.6rem'}}> </CheckCircleOutlineIcon>
                  }>
                  Update Settings
                </Button>
              </div>
            </>
          );
        }}
      />
    </>
  );
};
export default AddUserExpertiseLevelForm;
