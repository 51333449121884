import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export function useLocalStorage<T extends string>(
  storageKey: string,
  defaultValue: T,
  raw: true,
): [T, Dispatch<SetStateAction<T>>];
export function useLocalStorage<T>(storageKey: string, defaultValue: T, raw?: false): [T, Dispatch<SetStateAction<T>>];

export function useLocalStorage<T = any>(storageKey: string, defaultValue: T, raw = false) {
  const state = useState<T>(() => {
    const storageValue = localStorage.getItem(storageKey);
    if (storageValue !== null) {
      if (raw) {
        return storageValue;
      }
      try {
        return JSON.parse(storageValue);
      } catch (e) {}
    }
    return defaultValue;
  });

  const [currentState] = state;
  useEffect(() => {
    localStorage.setItem(storageKey, raw ? ((currentState as any) as string) : JSON.stringify(currentState));
  }, [currentState, storageKey, raw]);

  return state;
}
