import React, {useCallback, useMemo, useState} from 'react';
import {useUserList} from '../../../../../store/user';
import {UserDTO} from 'hemwb-api';
import TableWithSortAndPagination, {Column} from '../../../../core/TableWithSortAndPagination';
import {userListGridId, useStoredGridState} from '../../../../../store/grid';
import {Button, CircularProgress} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Routes} from '../../../../router/routes';
import {tid} from '../../../../../testUtils';
import {UserActionMenu} from '../common/UserActionMenu/UserActionMenu';
import {getUserTypeName} from '../../../../../store/user/utils';
import {Search} from '../../../../core/Search/Search';
import {Collaborate16} from '@carbon/icons-react';
import AdminSpaceHeader from '../../common/AdminSpaceHeader/AdminSpaceHeader';
import AdminPage from '../../common/AdminPage/AdminPage';
import {canEnrollUser} from '../../../../../permissions/usePermissions';

const stringComparator = (a: string, b: string) => {
  return !a ? -1 : b.localeCompare(a);
};
const emptyLastStringComparator = (a: string, b: string) => (!b ? 1 : stringComparator(a, b));
const buildUserComparator = (getter: (user: UserDTO) => string) => (a: UserDTO, b: UserDTO) =>
  emptyLastStringComparator(getter(a), getter(b));

const columns: Column<UserDTO>[] = [
  {id: 'id', label: 'ISID', sortable: true},
  {id: 'firstName', label: 'First Name', sortable: true, comparator: buildUserComparator((u) => u.firstName)},
  {id: 'lastName', label: 'Last Name', sortable: true, comparator: buildUserComparator((u) => u.lastName)},
  {id: 'email', label: 'Email', sortable: true, comparator: buildUserComparator((u) => u.email)},
  {id: 'active', label: 'Active', sortable: true, display: ({active}) => (active ? 'Yes' : 'No')},
  {
    id: 'userType',
    label: 'User Type',
    sortable: true,
    value: (user) => getUserTypeName(user.userType),
  },
];

const searchUsers = (users: UserDTO[], search: string) => {
  if (!search) {
    return users;
  }
  const lcSearch = search.toLocaleLowerCase();
  return users.filter(
    (user) =>
      (user.id || '').toLocaleLowerCase().includes(lcSearch) ||
      (user.email || '').toLocaleLowerCase().includes(lcSearch) ||
      (user.lastName || '').toLocaleLowerCase().includes(lcSearch) ||
      (user.firstName || '').toLocaleLowerCase().includes(lcSearch),
  );
};

const UserList: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<UserDTO['id']>();
  const gridState = useStoredGridState(userListGridId);
  const list = useUserList();
  const filteredList = useMemo(() => {
    return list && searchUsers(list, gridState.search);
  }, [list, gridState.search]);

  const handleSearchChange = useCallback(
    (search: string) => {
      if (gridState.page !== 0) {
        gridState.setPage(0);
      }
      gridState.setSearch(search);
    },
    [gridState],
  );

  const handleClickRow = (user: UserDTO) => {
    if (selectedUserId === user.id) {
      setSelectedUserId(undefined);
    } else {
      setSelectedUserId(user.id);
    }
  };

  const selectedUser = useMemo(() => {
    if (filteredList && selectedUserId) {
      return filteredList.find((user) => user.id === selectedUserId);
    } else {
      return null;
    }
  }, [filteredList, selectedUserId]);

  return (
    <>
      <AdminPage style={{paddingLeft: '2rem'}}>
        <AdminSpaceHeader Search={<Search value={gridState.search} onValueChange={handleSearchChange} />} />

        {canEnrollUser() && (
          <div className="listPanel">
            <Button
              component={Link}
              to={Routes.USER_ENROLL}
              variant="contained"
              color="primary"
              endIcon={<Collaborate16 />}
              {...tid('btn', 'enroll-user')}>
              Enroll new User
            </Button>
          </div>
        )}

        {filteredList ? (
          <TableWithSortAndPagination
            getRowId={(user) => user.id || ''}
            rows={filteredList}
            columns={columns}
            onClickRow={handleClickRow}
            isRowSelected={(user) => selectedUserId === user.id}
            gridState={gridState}
            noResultsMessage="No users were found"
          />
        ) : (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexGrow: 1,
            }}>
            <CircularProgress />
          </div>
        )}
      </AdminPage>
      <UserActionMenu selectedUser={selectedUser || null} />
    </>
  );
};

export default UserList;
