import React from 'react';
import clsx from 'clsx';
import {Card, CardHeader, IconButton} from '@material-ui/core';
import {getModelLanguageText, isModelRetired, stringifyCountry} from '../../../../../store/model';
import styles from './ModelCardCollapsed.module.scss';
import {tid} from '../../../../../testUtils';
import {Link} from 'react-router-dom';
import {Networking_03 as Networking03} from '@carbon/pictograms-react';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {Launch16, Maximize16} from '@carbon/icons-react';
import {SharedModelCardProps} from './types';

type ModelCardCollapsedProps = SharedModelCardProps & {
  onClickExpand?: () => void;
};

const joinTruthy = (tokens: any[], separator?: string) => tokens.filter(Boolean).join(separator);

const ModelCardCollapsed: React.FC<ModelCardCollapsedProps> = ({
  hideDetailLink = false,
  onClickExpand,
  model,
  selectable = true,
  className,
  current = false,

  ...restProps
}) => {
  return (
    <Card
      {...restProps}
      className={clsx('card', styles.container, className, {
        [styles.notSelectable]: !selectable,
        [styles.retired]: isModelRetired(model),
        [styles.selectable]: !!restProps.onClick,
        [styles.current]: current,
      })}>
      <CardHeader
        disableTypography
        avatar={<Networking03 className="icon32" />}
        title={
          <>
            <div className="label">
              {joinTruthy([model?.metadata.model_type, model && getModelLanguageText(model.metadata.type)], ' | ')}
            </div>
            <div className="primary">{model ? model.name : 'Restricted access'}</div>
          </>
        }
        action={
          <>
            {onClickExpand && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  onClickExpand();
                }}
                {...tid('model-card-expand', model?.uuid || '')}>
                <Maximize16 />
              </IconButton>
            )}
            {!hideDetailLink && model?.uuid && (
              <IconButton
                size="small"
                component={Link}
                to={getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}
                {...tid('model-card-detail', model.uuid)}>
                <Launch16 />
              </IconButton>
            )}
          </>
        }
        subheader={
          model && (
            <div className="secondary">
              {joinTruthy(
                [stringifyCountry(model), model.metadata.version_release_of_model, model.metadata.status],
                ' | ',
              )}
            </div>
          )
        }
      />
    </Card>
  );
};

export default ModelCardCollapsed;
