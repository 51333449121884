import {ReactNode} from 'react';
import {GridModifier, GridState, Order, RowId} from '../../../store/grid';

export type ValueReturnType = undefined | null | boolean | string | number | Date;
export type GetColumnValueReturnType = boolean | string | number | Date;
export type DisplayReturnType = null | boolean | string | number | ReactNode;

export type Column<R> = {
  id: string;
  label: string;
  customHeader?: any | JSX.Element;
  sortable?: boolean;
  value?: (row: R) => ValueReturnType;
  display?: (row: R) => DisplayReturnType;
  comparator?: (a: R, b: R) => number;
  classes?: Partial<{
    th: string;
    td: string;
  }>;
};

export type EnhancedRow<R> = {
  row: R;
  rowId: RowId;
};

export enum TableWithSortAndPaginationTheme {
  Default = 'defaultTheme',
  Lightweight = 'lightweightTheme',
}

export type TableWithSortAndPaginationProps<R> = {
  columns: Column<R>[];
  rows: R[];
  getRowId: (row: R) => RowId;
  getExpandedRow?: (row: R) => string | ReactNode;
  onClickRow?: (row: R) => void;
  rowRenderer?: (row: R) => ReactNode;
  isRowSelected?: (row: R) => boolean;
  hidePagination?: boolean;
  loading?: boolean;
  gridState: GridState<any> & GridModifier<any>;
  noResultsMessage?: string | false;
  theme?: TableWithSortAndPaginationTheme;
  externalModel?: boolean;
  filterStrategies?: {[id: string]: TableColumnFilterStrategy<any>};
} & (TableWithServerSortAndPaginationProps<R> | TableWithClientSortAndPaginationProps<R>);

export type TableWithServerSortAndPaginationProps<R> = {
  total: number;
  client: false;
};

export type TableWithClientSortAndPaginationProps<R> = {
  client?: true;
};

export const TO_LOCALE_DATE_STRING_OPTIONS = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const TO_LOCALE_DATE_TH_STRING_OPTIONS = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const TO_UTC_DATE_STRING_OPTIONS = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'UTC',
};

export type TableColumnFilterProps<R> = {
  column: Column<R>;
  onSetOrder: (order: Order) => void;
  onSetOrderBy: (orderBy: string) => void;
  order: Order;
  orderBy: string | null;
  filterStrategy: TableColumnFilterStrategy<R>;
};

export type TableColumnFilterStrategy<R> = {
  optionLabel: (option: R) => string;
  options: Array<R>;
  filter: Array<R | string>;
  onSetFilter: (filteredOptions: Array<R | string>) => void;
};
