import {
  ApiResponse,
  CreateModelItemRequest,
  ModelItemActionDTO,
  ModelTreeTHItemAuditedDTO,
  UpdateFileTagRequest,
  UpdateModelItemRequest,
  UploadSettingsDTO,
} from 'hemwb-api';
import {AsyncAction} from '../asyncUtilsTypes';
import {Action} from 'redux';

export enum ModelFileTags {
  DELETE,
  OVERWRITE,
  // SET_MAIN,
  EXTENSION_ERROR,
  SIZE_ERROR,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  DELETE_ERROR,
  MODEL_FILE,
  MODEL_FILE_MATHEMATICA,
  MODEL_FILE_EXCEL,
  MODEL_FILE_R,
  MODEL_FILE_PYTHON,
  DOCUMENTATION_FILE,
  PARSING,
  LOCKED,
  PARSING_ERROR,
  METADATA_FILE,
  PUBLISHED_MAIN_FILE,
  PUBLISHED_MODEL_FILE,
  BITBUCKET_FILE,
  FILE_TAG_OVERWRITE,
}

export type ModelParseInfo = {[key: string]: string};

export type ModelFileStateConfig = {
  maxBytes: number | null;
  modelExcelRegExp: RegExp | null;
  modelMathematicaRegExp: RegExp | null;
  modelRRegExp: RegExp | null;
  modelPythonRegExp: RegExp | null;
  documentationRegExp: RegExp | null;
  allowedExtensionRegExp: RegExp | null;
  modelFileExtensions: UploadSettingsDTO['modelFileExtensions'];
};

export type TreeNodeTags = Partial<{[tag in ModelFileTags]: boolean}>;

export type TreeNode = {
  uuid: string;
  path: string;
  name: string;
  folder: boolean;
  uploaded?: boolean;
  contentLoaded?: boolean;
  tags?: TreeNodeTags;
  button?: any;
  fileTag?: string;
};

export type ModelDirUploaded = TreeNode & {folder: true; uploaded: true};
export type ModelFileUploaded = TreeNode & {
  folder: false;
  uploaded: true;
  mainFile: boolean;
  parsed?: ModelParseInfo;
  new?: boolean;
};

export type ModelFileQueued = TreeNode & {
  file: File;
  folder: false;
  uploaded: false;
  parsed?: ModelParseInfo;
};
export type ModelTreeNode = ModelFileUploaded | ModelFileQueued | ModelDirUploaded;

export enum ModelFileActions {
  REQUEST_TREE = 'MODEL_FILE/REQUEST_TREE',
  REQUEST_TREE_TH = 'MODEL_FILE/REQUEST_TREE_TH',
  REQUEST_UPLOAD = 'MODEL_FILE/REQUEST_UPLOAD',
  REQUEST_UPDATE = 'MODEL_FILE/REQUEST_UPDATE',
  REQUEST_DELETE = 'MODEL_FILE/REQUEST_DELETE',
  REQUEST_ITEM_DOWNLOAD = 'MODEL_FILE/REQUEST_ITEM_DOWNLOAD',
  REQUEST_HELP_FILE_DOWNLOAD = 'MODEL_FILE/REQUEST_HELP_FILE_DOWNLOAD',
  REQUEST_ITEM_HISTORY = 'MODEL_FILE/REQUEST_ITEM_HISTORY',
  REQUEST_MARK_CHANGELOG = 'MODEL_FILE/REQUEST_MARK_CHANGELOG',
  REQUEST_UNMARK_CHANGELOG = 'MODEL_FILE/REQUEST_UNMARK_CHANGELOG',
  REQUEST_FILE_TAG_UPDATE = 'MODEL_FILE/REQUEST_FILE_TAG_UPDATE',

  INIT_UPLOADER = 'MODEL_FILE/INIT_UPLOADER',
  CLEAR_UPLOADER = 'MODEL_FILE/CLEAR_UPLOADER',
  MERGE_UPLOADER = 'MODEL_FILE/MERGE_UPLOADER',
  SUBMIT_START = 'MODEL_FILE/SUBMIT_START',
  SUBMIT_FINISH = 'MODEL_FILE/SUBMIT_FINISH',
  SUBMIT_ABORT = 'MODEL_FILE/SUBMIT_ABORT',

  ADD = 'MODEL_FILE/ADD',
  REMOVE = 'MODEL_FILE/REMOVE',
  MARK_DELETE = 'MODEL_FILE/MARK_DELETE',
  ADD_FILE_TAG = 'MODEL_FILE/ADD_FILE_TAG',
  UNMARK_FILE_TAG_CHANGE = 'MODEL_FILE/UNMARK_FILE_TAG_CHANGE',
  UNMARK_DELETE = 'MODEL_FILE/UNMARK_DELETE',
}

export type ModelFileNodeAction<T extends string> = {
  type: T;
  modelUuid: string;
  node: ModelTreeNode;
};

export type ModelFileNodesAction<T extends string> = {
  type: T;
  modelUuid: string;
  nodes: ModelTreeNode[];
};

export type ModelFileMergeUploaderAction = {
  type: ModelFileActions.MERGE_UPLOADER;
  modelUuid: string;
  hasFiles: boolean;
};

export type ModelFileAction =
  | AsyncAction<ModelFileActions.REQUEST_TREE, ModelTreeNode[], [string, string[], string[]]>
  | AsyncAction<ModelFileActions.REQUEST_TREE_TH, ModelTreeTHItemAuditedDTO, [string]>
  | AsyncAction<ModelFileActions.REQUEST_UPLOAD, string, [CreateModelItemRequest]>
  | AsyncAction<ModelFileActions.REQUEST_UPDATE, void, [UpdateModelItemRequest]>
  | AsyncAction<ModelFileActions.REQUEST_DELETE, any, [string, string]>
  | AsyncAction<ModelFileActions.REQUEST_FILE_TAG_UPDATE, any, [UpdateFileTagRequest]>
  | AsyncAction<ModelFileActions.REQUEST_ITEM_DOWNLOAD, ApiResponse<Blob>, [string, string]>
  | AsyncAction<ModelFileActions.REQUEST_HELP_FILE_DOWNLOAD, ApiResponse<Blob>, [string, string]>
  | AsyncAction<ModelFileActions.REQUEST_ITEM_HISTORY, ModelItemActionDTO[], [string, string]>
  | AsyncAction<ModelFileActions.REQUEST_MARK_CHANGELOG, void, [string, string]>
  | AsyncAction<ModelFileActions.REQUEST_UNMARK_CHANGELOG, void, [string, string]>
  | ModelFileNodesAction<ModelFileActions.INIT_UPLOADER>
  | ModelFileMergeUploaderAction
  | ModelFileNodeAction<ModelFileActions.ADD>
  | ModelFileNodeAction<ModelFileActions.REMOVE>
  | ModelFileNodeAction<ModelFileActions.MARK_DELETE>
  | ModelFileNodeAction<ModelFileActions.ADD_FILE_TAG>
  | ModelFileNodeAction<ModelFileActions.UNMARK_FILE_TAG_CHANGE>
  | ModelFileNodeAction<ModelFileActions.UNMARK_DELETE>
  | Action<ModelFileActions.SUBMIT_START>
  | Action<ModelFileActions.SUBMIT_FINISH>
  | Action<ModelFileActions.SUBMIT_ABORT>
  | Action<ModelFileActions.CLEAR_UPLOADER>;
