import React from 'react';
import {Button, ButtonProps} from '@material-ui/core';
import {Link} from 'react-router-dom';

export const defaultCancelButtonProps: ButtonProps & {'data-testid': string} = {
  variant: 'outlined',
  size: 'large',
  color: 'primary',
  children: 'Cancel',
  'data-testid': 'btn-cancel',
};

const CancelButton: React.FC<ButtonProps & {to?: string}> = (props) => {
  const linkProps = props?.to ? {component: Link} : {};
  return <Button {...defaultCancelButtonProps} {...linkProps} {...props} />;
};

export default CancelButton;
