import React, {RefObject, useLayoutEffect, useState} from 'react';
import * as d3 from 'd3';
import {InstanceGroupAssociation} from '../step1/types';

type ScenarioCurveProps = {
  canvasRef: RefObject<any>;
  scenariosRef: RefObject<any>;
  subModelsRef: RefObject<any>;
  groupsRef: RefObject<any>;
  instanceGroupAssociation: InstanceGroupAssociation;
  scenarioId: number;
  subModelId: number;
  color?: string;
};

const ScenarioCurve: React.FC<ScenarioCurveProps> = ({
  canvasRef,
  scenariosRef,
  subModelsRef,
  groupsRef,
  instanceGroupAssociation,
  scenarioId,
  subModelId,
  color,
}) => {
  const sourceRect = (instanceGroupAssociation[scenarioId]
    ? groupsRef?.current?.[instanceGroupAssociation[scenarioId].id]
    : subModelsRef?.current?.[subModelId]
  )?.getBoundingClientRect();
  const targetRect = scenariosRef?.current?.[scenarioId]?.getBoundingClientRect();
  const canvasRect = canvasRef?.current?.getBoundingClientRect();

  const [, setForceRender] = useState(0);
  useLayoutEffect(() => {
    setForceRender((f) => f++);
  }, [scenariosRef, subModelsRef, canvasRef]);

  if (!sourceRect || !targetRect || !canvasRect) {
    return null;
  }

  // bezier curve generator
  const curve = d3.linkHorizontal();
  // calculate positions
  const source = [10, sourceRect.top + sourceRect.height / 2 - canvasRect.top];
  const target = [60, targetRect.top + targetRect.height / 2 - canvasRect.top];

  return (
    <>
      <path
        // @ts-ignore
        d={curve({source: [0, source[1]], target: [10, source[1]]})}
        stroke={color || '#8D8D8D'}
        fill="none"
        strokeWidth={1}
      />
      {/* @ts-ignore */}
      <path d={curve({source, target})} stroke={color || '#8D8D8D'} fill="none" strokeWidth={1} />
    </>
  );
};

export default ScenarioCurve;
