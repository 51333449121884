import React from 'react';
import {ModelDTO, ScenarioDetailDTO} from 'hemwb-api';
import {useSharedSideBarToggle} from '../../../../store/customSettings';
import SideBar from '../../../layout/common/SideBar';
import {tid} from '../../../../testUtils';
import styles from '../../model/common/ModelActionMenu/ModelActionMenu.module.scss';
import {List} from '@material-ui/core';
import VisualizationFreshStartActionMenuButton from '../common/VisualizationFreshStart/VisualizationFreshStartActionMenuButton';
import VisualizationAnalysisProgressSaveActionMenuButton from '../common/VisualizationAnalysisProgressSave/VisualizationAnalysisProgressSaveActionMenuButton';
import {Routes} from '../../../router/routes';
import VisualizationCancelActionMenuButton from '../common/VisualizationCancel/VisualizationCancelActionMenuButton';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '22rem',
};

type VisualizationStep2ActionMenuProps = {
  modelUuid: string;
  model?: ModelDTO;
  scenario?: ScenarioDetailDTO;
  scenarioInProgress: boolean;
};

const VisualizationStep2ActionMenu: React.FC<VisualizationStep2ActionMenuProps> = ({
  modelUuid,
  model,
  scenario,
  scenarioInProgress,
}) => {
  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);

  return (
    <SideBar
      position="right"
      {...sharedSidebar}
      {...tid('visualization2-action-sidebar')}
      className={styles.modelActionMenu}>
      <List dense component="nav">
        <VisualizationFreshStartActionMenuButton modelUuid={modelUuid} scenarioInProgress={scenarioInProgress} />
        <VisualizationAnalysisProgressSaveActionMenuButton
          model={model}
          scenario={scenario}
          onSuccessRoute={Routes.VISUALIZATION_STEP_2}
          scenarioInProgress={scenarioInProgress}
        />
        <VisualizationCancelActionMenuButton scenario={scenario || undefined} model={model} />
      </List>
    </SideBar>
  );
};

export default VisualizationStep2ActionMenu;
