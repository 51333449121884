import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {UserBaseDTO} from 'hemwb-api';
import {ModelViewersAction, ModelViewersActions} from './types';
import {ModelAccessAction, ModelAccessActions} from '../modelAccess/types';

type FetchedViewers = AsyncActionState<UserBaseDTO[], [string]> & {timestamp: number};

export const modelViewersInitialState = {
  viewers: {} as {[modelUuid: string]: FetchedViewers},
};

export type ModelViewersState = typeof modelViewersInitialState;

export const modelViewersReducer = (
  state = modelViewersInitialState,
  action: ModelViewersAction | ModelAccessAction,
): ModelViewersState => {
  switch (action.type) {
    case ModelViewersActions.FETCH: {
      const [modelUuid] = action.params;
      return {
        ...state,
        viewers: {
          ...state.viewers,
          [modelUuid]: {...reduceAsyncAction(action), timestamp: Date.now()},
        },
      };
    }

    case ModelAccessActions.UPDATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const [modelUuid, {viewers}] = action.params;
        if (viewers && state.viewers[modelUuid]) {
          return {
            ...state,
            viewers: {
              ...state.viewers,
              [modelUuid]: {
                ...state.viewers[modelUuid],
                value: viewers,
                timestamp: Date.now(),
              },
            },
          };
        }
      }
      return state;
    }
  }

  return state;
};
