import React, {PropsWithChildren} from 'react';
import {CircularProgress, Fade} from '@material-ui/core';
import styles from './TextOrProgress.module.scss';

export type TextOrProgressProps = PropsWithChildren<{active: boolean}>;

export const TextOrProgress: React.FC<TextOrProgressProps> = ({active, children}) => (
  <div className={styles.wrapper}>
    <Fade in={!active}>
      <span>{children}</span>
    </Fade>
    <div className={styles.progressWrapper}>
      <Fade in={active}>
        <CircularProgress size={styles.spinnerSize} />
      </Fade>
    </div>
  </div>
);
