import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import useScenarioAddSideBarModal from './useScenarioAcceptSideBarModal';
import {canAcceptInstance} from '../../../../../permissions/usePermissions';
import {CheckmarkOutline16} from '@carbon/icons-react';

const ScenarioAcceptActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {ModalComponent, openModal} = useScenarioAddSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ACCEPT)}
        permission={canAcceptInstance(props.model, props.subModel, props.scenario)}
        onClick={openModal}
        icon={<CheckmarkOutline16 />}
        text="Accept"
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioAcceptActionMenuButton;
