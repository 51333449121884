import React, {useCallback, useRef, useState} from 'react';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {modelFileItemDownload, useModelFile} from '../../../../../store/modelFile';
import {useMounted} from '../../../../../hooks/useMounted';
import {useDispatch} from 'react-redux';
import {Download16, Download32} from '@carbon/icons-react';
import styles from './ModelDownload.module.scss';
import {InformationClassificationIcon} from '../../../../icons/classification/InformationClassificationIcon';
import {Button, Checkbox, Typography} from '@material-ui/core';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {tid} from '../../../../../testUtils';
import {TextOrProgress} from '../../../../core/textOrProgress/TextOrProgress';
import FileTree from '../../../../core/FileTree/FileTree';
import {ApiResponse} from 'hemwb-api';
import {downloadFromResponse} from '../../../../../utils/download';
import {isModelExternal, isModelWeb, modelDownload} from '../../../../../store/model';
import {trackModelDownloaded, trackModelItemDownloaded} from '../../../../../tracking/tracking';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {defaultLegalNotice} from '../../../../core/defaultLegalNotice';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';

type ModelDownloadProps = {
  model: ModelDTO;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const ModelDownload: React.FC<ModelDownloadProps> = ({model, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<string>();
  const [confirmed, setConfirmed] = useState(false);
  const {files} = useModelFile(model);
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  const mounted = useMounted();
  const cancelled = useRef(false);
  const downloadIfNotCancelled = useCallback(
    (response: ApiResponse<Blob>) => (cancelled.current ? Promise.reject() : downloadFromResponse(response.raw)),
    [],
  );

  const handleCancel = useCallback(() => {
    cancelled.current = true;
    onCancel?.();
  }, [onCancel]);

  const handleDownloadAll = useCallback(() => {
    setDownloading('all');
    cancelled.current = false;
    modelDownload(dispatch, model.uuid)
      .then(downloadIfNotCancelled)
      .then(() => {
        trackModelDownloaded(model);
        onSuccess?.();
      })
      .catch(() => null)
      .finally(() => {
        mounted.current && setDownloading(undefined);
      });
  }, [dispatch, downloadIfNotCancelled, model, mounted, onSuccess]);

  const handleDownloadItem = useCallback(
    (itemUuid: string, itemName: string) => {
      setDownloading(itemUuid);
      cancelled.current = false;
      modelFileItemDownload(dispatch, model.uuid, itemUuid)
        .then(downloadIfNotCancelled)
        .then(() => {
          trackModelItemDownloaded(model, itemName, itemUuid);
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setDownloading(undefined);
        });
    },
    [dispatch, downloadIfNotCancelled, model, mounted],
  );

  return (
    <>
      <InformationClassificationIcon
        classification={model.metadata.information_classification}
        className={styles.classificationIcon}
      />
      <ActionHeader Icon={<Download32 />}>Download model</ActionHeader>

      <SuspenseNull>
        {files && (
          <FileTree
            files={files}
            webModel={webModel}
            externalModel={externalModel}
            renderButtons={(node) => (
              <Button
                key={`download-item-file-${node.uuid}`}
                size="small"
                variant="contained"
                disabled={!!downloading || !confirmed}
                onClick={() => handleDownloadItem(node.uuid, node.name)}
                {...tid('download-item-file', node.name)}>
                <TextOrProgress active={downloading === node.uuid}>Download</TextOrProgress>
              </Button>
            )}
          />
        )}
      </SuspenseNull>

      <br />
      <br />
      <Typography variant="body2" style={{paddingLeft: 9}}>
        {model.metadata.legal_notice || defaultLegalNotice}
      </Typography>

      <label>
        <Checkbox
          {...tid('checkbox-confirm')}
          color="primary"
          checked={confirmed}
          onClick={() => setConfirmed(!confirmed)}
        />
        I have read the legal notice
      </label>

      <div className="buttonsContainer">
        <br />
        <br />

        <CancelButton onClick={handleCancel} />
        <SubmitButton
          onClick={handleDownloadAll}
          active={downloading === 'all'}
          disabled={!confirmed}
          endIcon={<Download16 />}>
          Download all
        </SubmitButton>
      </div>
    </>
  );
};

export default ModelDownload;
