import React, {useState, useCallback, useEffect} from 'react';
import SuspenseNull from '../../core/Suspense/SuspenseNull';
import DefaultPage from '../../layout/common/DefaultPage';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import {messageAdd, MessageTypes} from '../../../store/message';
import {Container, Grid, CircularProgress, Avatar} from '@material-ui/core';
import PageHeaderTitle from '../../core/Header/PageHeader/PageHeaderTitle';
import {useDispatch} from 'react-redux';
import TableWithSortAndPagination, {Column, TO_LOCALE_DATE_STRING_OPTIONS} from '../../core/TableWithSortAndPagination';
import {getUrlRoute, Routes} from '../../router/routes';
import {NotificationDTO} from 'hemwb-api';
import {notificationsListGridId, useStoredGridState} from '../../../store/grid';
import {requestLatestNotifications} from '../../../store/notifications';
import {useModelDetail} from '../../../store/model';

const AllNotifications: React.FC = () => {
  const dispatch = useDispatch();
  const gridState = useStoredGridState(notificationsListGridId);
  const [notificationList, setNotificationList] = useState<NotificationDTO[]>();

  const notificationTime = ({createdOn}: NotificationDTO) => {
    return <div style={{float: 'right'}}>{createdOn.toLocaleDateString('en-US', TO_LOCALE_DATE_STRING_OPTIONS)}</div>;
  };

  const notificationAvatar = ({createdBy}: NotificationDTO) => {
    return (
      <div style={{justifyContent: 'center', display: 'flex'}}>
        <Avatar style={{width: 48, height: 48}}>
          {createdBy[0]}
          {createdBy?.split(' ').pop()?.[0]}
        </Avatar>
      </div>
    );
  };

  const notificationContent = ({createdBy, modelUuid, userNote, notificationAction}: NotificationDTO) => {
    return (
      <>
        <div style={{fontWeight: 'bold'}}>
          {createdBy} {handleItemText(notificationAction)}{' '}
        </div>
        <div>{userNote}</div>
        <div style={{marginBottom: '20px'}}>
          <a style={{color: '#fff'}} href={handleItemLink(modelUuid, notificationAction)}>
            {ModelName(modelUuid)}
          </a>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!notificationList) {
      requestLatestNotifications()
        .then((response) => {
          setNotificationList(response);
        })
        .catch(() => {
          dispatch(messageAdd('Failed to load the notification list', MessageTypes.ERROR));
        });
    }
  }, [dispatch, notificationList]);

  const columns: Column<NotificationDTO>[] = [
    {
      id: 'createdBy',
      label: '',
      sortable: false,
      value: (notification) => notification.createdBy,
      display: notificationAvatar,
    },
    {
      id: 'notificationId',
      label: '',
      sortable: false,
      value: (notification) => notification.notificationId,
      display: notificationContent,
    },
    {
      id: 'createdOn',
      label: '',
      sortable: false,
      value: (notification) => notification.createdOn,
      display: notificationTime,
    },
  ];

  const handleItemText = useCallback((notificationAction: string) => {
    if (notificationAction === 'NEW_MODEL_VERSION') {
      return 'has added a new model version';
    } else {
      return 'has modified files in the model';
    }
  }, []);

  const ModelName = (item: string) => {
    const modelDetail = useModelDetail(item);
    if (modelDetail) {
      return modelDetail.name;
    }
  };

  const handleItemLink = useCallback((modelUuid: string, notificationAction: string) => {
    if (notificationAction === 'NEW_MODEL_VERSION') {
      return `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
        uuid: modelUuid,
      })}`;
    } else {
      return `${window.location.origin}/#${getUrlRoute(Routes.MODEL_DETAIL, {
        uuid: modelUuid,
      })}?tab=folder`;
    }
  }, []);

  return (
    <>
      <DefaultPage>
        <SuspenseNull>
          <div style={{padding: '30px  30px 30px 30px'}}>
            <Container maxWidth={false} className="pageHeader">
              <Grid container direction="row" justify="space-between" alignItems="center">
                <PageHeaderTitle
                  md={12}
                  lg={4}
                  title="Notifications"
                  label="See all notifications"
                  Icon={<NotificationsOutlinedIcon style={{fontSize: '32px'}} />}
                />
              </Grid>
            </Container>
            {notificationList ? (
              <div style={{paddingTop: '30px'}}>
                <TableWithSortAndPagination
                  getRowId={(notification) => notification.notificationId}
                  rows={notificationList}
                  columns={columns}
                  gridState={gridState}
                  noResultsMessage="No notifications were found"
                />
              </div>
            ) : (
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  flexGrow: 1,
                }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </SuspenseNull>
      </DefaultPage>
    </>
  );
};

export default AllNotifications;
