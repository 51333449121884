import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {FieldKey, metadataLabels} from '../../components/pages/model/common/ModelEdit/ModelEditMetadata/constants';
import {
  canBeParentOfModel,
  getMissingRequiredMetadata,
  getModelSteward,
  isModelCreating,
  isModelDTO,
  isModelGlobal,
} from './utils';
import {getErrorText} from '../../components/core/Form';
import {useMemo} from 'react';
import {OwnershipType, UserBaseDTO} from 'hemwb-api';
import {ModelTreeNode} from '../modelFile/types';
import {useModelFile} from '../modelFile/hooks';
import {useModelDetail, useModels} from './hooks';
import {ModelState} from './reducer';
import {useModelAccessGranular} from '../modelAccess';

export enum IssueSeverity {
  OK,
  WARNING,
  ERROR_PUBLISH,
  ERROR_CREATE,
}

export enum ModelEditSection {
  FILES,
  METADATA,
  LINEAGE,
  PERMISSIONS,
  SUFFIX,
}

export type ModelIssue = {
  property: FieldKey | string;
  message: string;
  severity: IssueSeverity;
  section: ModelEditSection;
};

export const sortBySection = (a: ModelIssue, b: ModelIssue): number => {
  if (a.section > b.section) return 1;
  if (a.section < b.section) return -1;
  return 0;
};

export const sortBySeverity = (a: ModelIssue, b: ModelIssue): number => {
  if (a.severity > b.severity) return -1;
  if (a.severity < b.severity) return 1;
  return 0;
};

const labels = {
  ...metadataLabels,
  changelog_file: 'Changelog document',
  executionEnvironment: 'Model Execution Environment',
  timeoutSeconds: 'Execution timeout (in minutes)',
  mainFile: 'Model main file',
  noFile: 'File',
} as {[key: string]: string};

const getLabel = (property: string): string => labels[property] || property;

const getMissingMessage = (property: string) => {
  return `${getLabel(property)} is missing`;
};

const pattern = /^\d*(\.\d*)?$/;
export const validateModelVersion = (model: ModelDTO, models: ModelState['models']) => {
  const version = model.metadata.version_release_of_model;
  const parentModel =
    models[model.metadata.tree_parent_id] && isModelDTO(models[model.metadata.tree_parent_id])
      ? (models[model.metadata.tree_parent_id] as ModelDTO)
      : null;

  if (!version) {
    return {
      required: true,
    };
  }

  if (!pattern.test(version || '')) {
    return {
      format: true,
    };
  }

  const parsedModelVersion = parseFloat(version);

  if (
    parentModel &&
    ((isModelGlobal(model) && isModelGlobal(parentModel)) || model.metadata.country === parentModel.metadata.country) &&
    parsedModelVersion <= parseFloat(parentModel.metadata.version_release_of_model)
  ) {
    return {
      min: {
        min: parentModel.metadata.version_release_of_model,
      },
    };
  }

  if (isModelCreating(model)) {
    return null;
  }

  const lowestVersionOfChild = Object.values(models)
    .filter(isModelDTO)
    .filter((m) => m.metadata.tree_parent_id === model.uuid)
    .filter((m) => (isModelGlobal(model) && isModelGlobal(m)) || model.metadata.country === m.metadata.country)
    .reduce((acc, val) => {
      if (val.metadata.version_release_of_model) {
        const parsedVersion = parseFloat(val.metadata.version_release_of_model);
        if (!acc || acc > parsedVersion) {
          return parsedVersion;
        }
      }
      return acc;
    }, undefined as number | undefined);

  if (lowestVersionOfChild && parsedModelVersion >= lowestVersionOfChild) {
    return {
      max: {
        max: lowestVersionOfChild.toString(),
      },
    };
  }

  return null;
};

export const validateModel = (
  model: ModelDTO,
  models: ModelState['models'],
  files?: ModelTreeNode[],
  owners?: UserBaseDTO[],
  vestLead?: UserBaseDTO,
): ModelIssue[] => {
  const output: ModelIssue[] = [];
  const parentModel =
    models[model.metadata.tree_parent_id] && isModelDTO(models[model.metadata.tree_parent_id])
      ? (models[model.metadata.tree_parent_id] as ModelDTO)
      : null;

  if (parentModel) {
    if (!canBeParentOfModel(parentModel, model)) {
      output.push({
        property: 'tree_parent_id',
        message: 'Selected parent model is not valid.',
        severity: IssueSeverity.ERROR_CREATE,
        section: ModelEditSection.LINEAGE,
      });
    }

    if (!model.metadata.version_log) {
      output.push({
        property: 'version_log',
        message: getMissingMessage('version_log'),
        severity: IssueSeverity.WARNING,
        section: ModelEditSection.LINEAGE,
      });
    }

    if (!model.metadata.changelog_file) {
      output.push({
        property: 'changelog_file',
        message: getMissingMessage('changelog_file'),
        severity: IssueSeverity.WARNING,
        section: ModelEditSection.LINEAGE,
      });
    }
  } else {
    if (model.metadata.tree_parent_id) {
      output.push({
        property: 'tree_parent_id',
        message: 'Parent Model is selected, but not provided for validation',
        severity: IssueSeverity.ERROR_CREATE,
        section: ModelEditSection.LINEAGE,
      });
    }

    if (model.metadata.tree_root_id && model.metadata.tree_root_id !== model.uuid) {
      output.push({
        property: 'tree_root_id',
        message: 'Model is not linked to the Lineage.',
        severity: IssueSeverity.ERROR_CREATE,
        section: ModelEditSection.LINEAGE,
      });
    }
  }

  const missingRequiredFields = getMissingRequiredMetadata(model);
  missingRequiredFields.forEach((property) => {
    output.push({
      property,
      message: getMissingMessage(property),
      severity: IssueSeverity.ERROR_CREATE,
      section: property === 'version_release_of_model' ? ModelEditSection.LINEAGE : ModelEditSection.METADATA,
    });
  });

  if (!model.metadata.veap_id) {
    output.push({
      property: 'veap_id',
      message: getMissingMessage('veap_id'),
      severity: IssueSeverity.WARNING,
      section: ModelEditSection.METADATA,
    });
  }

  if (!missingRequiredFields.includes('version_release_of_model')) {
    const versionError = validateModelVersion(model, models);
    if (versionError && Object.keys(versionError).length > 0) {
      output.push({
        property: 'version_release_of_model',
        message:
          getErrorText(versionError, getLabel('version_release_of_model'), false, {
            format: 'Version must match number format like "1" or "1.1"',
          }) || '',
        severity: IssueSeverity.ERROR_CREATE,
        section: ModelEditSection.LINEAGE,
      });
    }
  }

  if (!getModelSteward(model)) {
    output.push({
      property: 'modelSteward',
      message: getMissingMessage('modelSteward'),
      severity: IssueSeverity.ERROR_CREATE,
      section: ModelEditSection.PERMISSIONS,
    });
  }

  if (!vestLead) {
    output.push({
      property: 'vestLead',
      message: getMissingMessage('vestLead'),
      severity: IssueSeverity.ERROR_CREATE,
      section: ModelEditSection.PERMISSIONS,
    });
  }

  if (!files || files.length === 0) {
    output.push({
      property: 'noFile',
      message: getMissingMessage('noFile'),
      severity: IssueSeverity.ERROR_PUBLISH,
      section: ModelEditSection.FILES,
    });
  }

  return output;
};

export const getSectionStatus = (section: ModelEditSection, issues: ModelIssue[] = []): IssueSeverity => {
  return Math.max(IssueSeverity.OK, ...issues.filter((i) => i.section === section).map((i) => i.severity));
};

export const useValidateModel = (model: ModelDTO): ModelIssue[] | null => {
  const {files} = useModelFile(model);
  const detail = useModelDetail(model);
  const models = useModels();
  const vestLead = useModelAccessGranular(model.uuid, OwnershipType.VESTLEAD)?.[0];
  return useMemo(() => {
    if (detail && models && files && vestLead !== undefined) {
      return validateModel(model, models, files, detail.owners, vestLead);
    }
    return null;
  }, [model, models, files, detail, vestLead]);
};
