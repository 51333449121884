import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  ExecutionSetupDTO,
  ExecutionSetupType,
  ModelDTO,
  ModelInputDetailDTO,
  ScenarioDetailDTO,
  ScenarioDTO,
  ScenarioOrderBy,
  ScenarioStatus,
} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {AutocompleteControl, Form, TextFieldControl, useForm} from '../../../../core/Form';
import GridItem from '../../../../core/Form/GridItem';
import CancelButton from '../../../../core/Buttons/CancelButton';
import {tid} from '../../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {scenarioLoadList, scenarioNameExists} from '../../../../../store/scenario';
import debounce from 'debounce-promise';
import {Chip, Grid} from '@material-ui/core';
import FileInputButton from '../../../../core/Buttons/FileInputButton';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {useModelInput} from '../../../../../store/modelInputs';
import {parseScenarioParametersFile} from '../../parseScenarioParametersFile';
import {renderCalibrationInstanceOptionItem} from './renderCalibrationInstanceOptionItem';

export type ScenarioFormValues = {name: string; scenarioId?: number};

type ScenarioFormProps = {
  model: ModelDTO;
  subModel: ExecutionSetupDTO;
  input: ModelInputDetailDTO;
  scenario?: ScenarioDetailDTO;
  onSubmit?: (formValue: ScenarioFormValues, value?: object) => void;
  onCancel?: () => void;
  submitting: boolean;
};

const ScenarioForm: React.FC<ScenarioFormProps> = ({
  model,
  subModel,
  input,
  scenario,
  submitting,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File>();
  const calibrationInputDefinition = useModelInput(subModel.executionSetupId);
  const [creator, setCreator] = useState<ScenarioDTO>();

  const nameExistsCacheRef = useRef<any>({});

  const debouncedScenarioNameExists = debounce((name: string) => {
    if (!nameExistsCacheRef.current[name]) {
      nameExistsCacheRef.current[name] = scenarioNameExists(dispatch, {
        name,
        modelUuid: model.uuid,
        scenarioId: scenario?.id,
        executionSetupId: subModel.id,
      });
    }
    return nameExistsCacheRef.current[name];
  }, 1000);
  const validateNameUnique = async (control: AbstractControl) => {
    const name = control.value;
    return name !== scenario?.name && (await debouncedScenarioNameExists(name)) ? {isUnique: true} : null;
  };

  const form = useForm(
    new FormGroup({
      name: new FormControl(scenario?.name, [Validators.required, Validators.maxLength(200)], validateNameUnique),
      calibration: new FormControl(null),
      creator: new FormControl(null),
    }),
  );

  const [calibrations, setCalibrations] = useState<ScenarioDTO[]>();

  useEffect(() => {
    if (subModel.type === ExecutionSetupType.SCENARIO && subModel.executionSetupId && calibrationInputDefinition) {
      scenarioLoadList(dispatch, {
        modelUuid: model.uuid,
        modelInputId: calibrationInputDefinition.id,
        status: [ScenarioStatus.ACCEPTED],
        pageSize: 1000,
        pageIndex: 0,
        orderBy: ScenarioOrderBy.Name,
        orderDesc: false,
        all: true,
      }).then((response) => setCalibrations(response.list));
    }
  }, [dispatch, subModel, model, calibrationInputDefinition]);

  useEffect(() => {
    scenario && form.get('calibration').setValue(calibrations?.find((i) => i.id === scenario.scenarioId));
  }, [form, scenario, calibrations]);

  useEffect(() => {
    if (creator && creator.createdBy) {
      if (creator.createdBy.firstName && creator.createdBy.lastName) {
        form.get('creator').setValue(creator.createdBy.firstName + ', ' + creator.createdBy.lastName);
      } else {
        form.get('creator').setValue(creator.createdBy.id);
      }
    } else {
      form.get('creator').setValue('');
    }
  }, [form, creator]);

  const handleSubmit = useCallback(async () => {
    try {
      const parsedParameters = await parseScenarioParametersFile(input, file);
      onSubmit?.({name: form.value.name, scenarioId: form.value.calibration?.id}, parsedParameters);
    } catch (e) {
      dispatch(messageAdd(e.message, MessageTypes.ERROR));
    }
  }, [dispatch, onSubmit, input, form, file]);

  return (
    <Form
      FieldGroupProps={{strict: false}}
      style={{marginLeft: 0}}
      className=""
      group={form}
      onSubmit={handleSubmit}
      render={() => {
        return (
          <>
            <GridItem>
              <TextFieldControl label="Name" name="name" />
            </GridItem>

            {subModel.type === ExecutionSetupType.SCENARIO && subModel.executionSetupId && (
              <GridItem>
                <AutocompleteControl
                  // disabled={!!scenario}
                  name="calibration"
                  label="Calibration Instance"
                  loading={!calibrations}
                  AutocompleteProps={{
                    onInputChange: (_, v) => setCreator(calibrations?.find((i) => v === i.name)),
                    options: calibrations || [],
                    getOptionLabel: (i: ScenarioDTO) => i.name || '',
                    renderOption: renderCalibrationInstanceOptionItem,
                    disableOpenOnFocus: true,
                  }}
                />
                <GridItem>
                  <TextFieldControl label="Creator of Calibration Instance" name="creator" disabled={true} />
                </GridItem>
              </GridItem>
            )}

            <span>Instance parameters file (xml, json)</span>
            <br />
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <FileInputButton label="Add file" onChange={(files) => setFile(files[0])} accept=".json, .xml" />
              </Grid>
              <Grid item>
                &nbsp;&nbsp;&nbsp;
                {file && <Chip label={file.name} onDelete={() => setFile(undefined)} variant="outlined" />}
              </Grid>
            </Grid>

            <br />
            <div className="buttonsContainer">
              <br />
              <br />
              <CancelButton onClick={onCancel} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ADD, 'cancel')} />
              <SubmitButton active={submitting} {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_ADD, 'submit')}>
                Save
              </SubmitButton>
            </div>
          </>
        );
      }}
    />
  );
};
export default ScenarioForm;
