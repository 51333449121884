import React from 'react';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import {useScenarioCancelSideBarModal} from './useScenarioCancelSideBarModal';
import {canCancelInstanceExecution} from '../../../../../permissions/usePermissions';
import {StopOutline16} from '@carbon/icons-react';

const ScenarioCancelActionMenuButton: React.FC<Partial<ScenarioActionProps>> = (props) => {
  const {ModalComponent, openModal} = useScenarioCancelSideBarModal(props);

  return (
    <>
      <SideBarButtonItem
        {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_CANCEL)}
        permission={canCancelInstanceExecution(props.model, props.subModel, props.scenario)}
        onClick={openModal}
        icon={<StopOutline16 />}
        text="Cancel Execution"
      />
      {ModalComponent}
    </>
  );
};

export default ScenarioCancelActionMenuButton;
