import React, {useCallback} from 'react';
import {Form, TextFieldControl, useForm, DropDownControl} from '../../../../core/Form';
import {IncidentStatuses, mapValuesToIncident} from '../../../../../store/incident/utils';
import {tid} from '../../../../../testUtils';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {Edit16} from '@carbon/icons-react';
import {FormGroup, FormControl, Validators} from 'react-reactive-form';
import {IncidentStatus} from 'hemwb-api';

type UpdateIncidentFormProps = {
  status?: string;
  submitting: boolean;
  onSubmit?: (status: IncidentStatus, comments: string) => void;
  onCancel?: () => void;
};

const UpdateIncidentForm: React.FC<UpdateIncidentFormProps> = ({status, onCancel, submitting, onSubmit}) => {
  const form = useForm(
    new FormGroup({
      status: new FormControl(status),
      comments: new FormControl(null, [Validators.required]),
    }),
  );
  const handleSubmit = useCallback(() => {
    if (form.valid) {
      onSubmit?.(mapValuesToIncident(form.value), form.controls.comments.value);
    }
  }, [onSubmit, form]);

  return (
    <Form
      className=""
      group={form}
      render={() => {
        return (
          <>
            <div style={{width: '70%'}}>
              <DropDownControl name="status" label="Status" options={IncidentStatuses} />
            </div>
            <br />
            <br />
            <TextFieldControl
              name="comments"
              label="Add closing comment for the user"
              TextFieldProps={{
                multiline: true,
                rows: 6,
                inputProps: {
                  ...tid('input', 'value'),
                },
              }}
            />
            <div className="buttonsContainer">
              <br />
              <br />
              <CancelButton onClick={onCancel} disabled={submitting} />

              <SubmitButton
                onClick={handleSubmit}
                active={submitting}
                disabled={status === 'CLOSED' || status === form.controls.status.value}
                endIcon={<Edit16 />}>
                Update Incident
              </SubmitButton>
            </div>
          </>
        );
      }}
    />
  );
};

export default UpdateIncidentForm;
