import {
  ArraySpecificInputAttributes,
  BooleanSpecificInputAttributes,
  ColumnType,
  DateSpecificInputAttributes,
  DecimalSpecificInputAttributes,
  InputType,
  IntegerSpecificInputAttributes,
  LabelSpecificInputAttributes,
  MultiSelectInputAttributes,
  RangeSpecificInputAttributes,
  RealNumberSpecificInputAttributes,
  SingleSelectInputAttributes,
  StringSpecificInputAttributes,
} from './InputDefinition/types';

export const parseDataType = (data: any): any => {
  if (data.name !== 'Parameter') {
    return undefined;
  }

  let uniqueInputAttributes: any;
  const val = data.elements?.[0];
  switch (val?.name.toLowerCase()) {
    case 'string':
      uniqueInputAttributes = parseStringAttributes(val);
      break;
    case 'number':
      uniqueInputAttributes = parseNumberAttributes(val);
      break;
    case 'boolean':
      uniqueInputAttributes = parseBooleanAttributes(val);
      break;
    case 'select':
      uniqueInputAttributes = parseSelectAttributes(val);
      break;
    case 'range':
      uniqueInputAttributes = parseRangeAttributes(val);
      break;
    case 'array':
      uniqueInputAttributes = parseArrayAttributes(val);
      break;
    default:
      uniqueInputAttributes = undefined;
  }
  return uniqueInputAttributes;
};

export const parseValue = (val: any, type: InputType, data: any = undefined): any => {
  // eslint-disable-next-line
  if (type === undefined || val == undefined) return undefined;

  switch (type) {
    case InputType.STRING:
    case InputType.BOOLEAN:
    case InputType.INTEGER:
    case InputType.DECIMAL:
    case InputType.REAL_NUMBER:
    case InputType.DATE:
      return parseStringValue(val);
    case InputType.SINGLE_SELECT:
    case InputType.MULTI_SELECT:
      return parseSelectValue(val, type);
    case InputType.RANGE:
      return parseRangeValue(val);
    case InputType.ARRAY:
      return parseArrayValue(val, data?.elements?.[0]?.elements?.[1]?.elements);
    default:
      return undefined;
  }
};

export const parseStringAttributes = (data: any): typeof defaultStringAttributes => {
  const output = {...defaultStringAttributes};

  if (data.attributes?.length !== undefined) {
    output.length = data.attributes.length;
  }

  if (data.elements?.[0]?.type === 'text') {
    output.defaultValue = data.elements[0].text;
  }

  return output;
};

const parseStringValue = (text: string) => {
  if (text[0] !== '"' || text[text.length - 1] !== '"') {
    return text;
  }
  return text.substring(1, text.length - 1);
};

export const parseBooleanAttributes = (data: any): BooleanSpecificInputAttributes => {
  const output = {...defaultBooleanAttributes};
  if (data.elements?.[0]?.type === 'text') {
    output.defaultValue = data.elements[0].text;
  }
  return output;
};

export const parseNumberAttributes = (
  data: any,
):
  | IntegerSpecificInputAttributes
  | RealNumberSpecificInputAttributes
  | DecimalSpecificInputAttributes
  | DateSpecificInputAttributes
  | null => {
  const {attributes, elements} = data;
  const {type, min, max} = attributes;

  let assignedType;

  switch (type) {
    case 'integer':
      assignedType = InputType.INTEGER;
      break;
    case 'real':
      assignedType = InputType.REAL_NUMBER;
      break;
    case 'decimal':
      assignedType = InputType.DECIMAL;
      break;
    case 'date':
      assignedType = InputType.DATE;
      break;
  }

  if (assignedType) {
    const output = {
      type: assignedType,
    } as any;
    if (elements?.[0]?.type === 'text') {
      output.defaultValue = elements[0].text;
    }

    output.min = min;
    output.max = max;
    return output;
  }

  throw new Error('Failed to parse number: ' + JSON.stringify(data));
};

export const parseSelectAttributes = (data: any): SingleSelectInputAttributes | MultiSelectInputAttributes => {
  let output;

  if (data.attributes.isMultiselect === 'true' || data?.attributes?.type === 'multiselect') {
    output = {
      type: InputType.MULTI_SELECT,
      options: [],
    };
  } else {
    output = {
      type: InputType.SINGLE_SELECT,
      options: [],
    };
  }

  if (data.elements) {
    output.options = data.elements.map((e: any) => {
      const selected = e.attributes?.isSelected === 'true';
      let key, text;
      e.elements.forEach((i: any) => {
        if (i.name === 'key') {
          key = i.elements?.[0]?.text;
        } else if (i.name === 'value') {
          text = i.elements?.[0]?.text;
        }
      });

      return {
        key,
        text,
        selected,
      };
    });
  }

  return output as any;
};

const stringToArray = (text: string): any[] => {
  const textArr = new Array(text.length);
  let insideQuotes = false;
  for (let i = 0; i < text.length; ++i) {
    const c = text.charAt(i);
    if (c === '"') {
      insideQuotes = !insideQuotes;
    }
    if (c === '{' && !insideQuotes) {
      textArr[i] = '[';
    } else if (c === '}' && !insideQuotes) {
      textArr[i] = ']';
    } else {
      textArr[i] = c;
    }
  }
  text = textArr.join('');
  let output;
  try {
    output = JSON.parse(text);
  } catch (e) {
    return [];
  }
  return output;
};

const parseSelectValue = (text: string, type: InputType.SINGLE_SELECT | InputType.MULTI_SELECT) => {
  const output = stringToArray(text);
  if (output === undefined || output.length === 0) {
    return undefined;
  }

  if (type === InputType.SINGLE_SELECT) {
    return output[0] || null;
  }

  return output;
};

export const parseRangeAttributes = (data: any): RangeSpecificInputAttributes => {
  const output = {
    type: InputType.RANGE,
    dataType: InputType.INTEGER,
  } as RangeSpecificInputAttributes;

  data.elements.forEach((e: any) => {
    if (e.name === 'from' || e.name === 'to') {
      // @ts-ignore
      const {type, ...numberProps} = parseNumberAttributes(e.elements[0]);
      output[e.name as 'from' | 'to'] = numberProps;
      output.dataType = type;
    }
  });

  return output;
};

const parseRangeValue = (text: string) => {
  const output = stringToArray(text);
  if (output.length !== 2) return undefined;

  return [output[0] || null, output[1] || null];
};

export const parseArrayAttributes = (data: any): ArraySpecificInputAttributes => {
  const output = {
    type: InputType.ARRAY,
    columns: [],
    rows: [],
  } as ArraySpecificInputAttributes;

  data.elements.forEach((e: any) => {
    if (e.name === 'columns') {
      e.elements?.forEach((c: any) => {
        if (!c.attributes?.heading) {
          return;
        }
        const column: Partial<ColumnType> = {};
        column.text = c.attributes.heading;
        column.required = c.attributes.isRequired === 'true';
        const [definition, ...values] = c.elements;

        let parsedDefinition;
        switch (definition.name) {
          case 'number':
            parsedDefinition = parseNumberAttributes(definition);
            break;
          case 'string':
            parsedDefinition = parseStringAttributes(definition);
            break;
          default:
            parsedDefinition = null;
        }

        if (parsedDefinition) {
          const {type, ...rest} = parsedDefinition as any;
          column.dataType = type;
          Object.assign(column, rest);
          column.defaultValue = values.map((v: any) => v.elements?.[0]?.text);
          output.columns.push(column as ColumnType);
        }
      });
    } else if (e.name === 'rows') {
      if (e.attributes?.label) {
        output.rowDescription = e.attributes.label;
      }
      output.rows = e.elements?.map((r: any) => r?.attributes?.heading);
    }
  });

  return output;
};

const parseArrayValue = (text: string, columns: any) => {
  if (columns === undefined) return undefined;
  const columnTypes: InputType[] = [];
  columns.forEach((i: any) => {
    columnTypes.push((InputType as any)[i.attributes.type.toUpperCase()]);
  });
  const columnStringData = stringToArray(text);
  const columnValues: any[] = [];
  if (columnStringData.length !== columnTypes.length) return undefined;
  columnTypes.forEach((type, index) => {
    const col: any[] = [];
    columnStringData[index].forEach((val: any) => {
      col.push(parseValue(val, type));
    });
    columnValues.push(col);
  });

  return columnValues;
};

export const parseLabelAttributes = (data: any): LabelSpecificInputAttributes => {
  const output: LabelSpecificInputAttributes = {type: InputType.LABEL};

  if (data.elements?.[0]?.type === 'text') {
    output.defaultValue = data.elements[0].text;
  }

  return output;
};

const defaultStringAttributes: StringSpecificInputAttributes = {
  type: InputType.STRING,
  length: 255,
};
const defaultBooleanAttributes: BooleanSpecificInputAttributes = {
  type: InputType.BOOLEAN,
};
