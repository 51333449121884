import React, {useMemo} from 'react';
import styles from './LineageRoot.module.scss';
import {ModelCard} from '../../ModelCard/ModelCard';
import {useModelFlatHierarchy} from '../../../../../../store/modelLinks';
import {StoredModel} from '../../../../../../store/model';

type LineageRootProps = {
  model: StoredModel;
  activeModelUuid: string;
  onClick: () => void;
};

const LineageRoot: React.FC<LineageRootProps> = ({model, activeModelUuid, onClick}) => {
  const hierarchy = useModelFlatHierarchy(model.uuid);

  const flatHierarchy = useMemo(() => {
    return hierarchy ? hierarchy : [{model}];
  }, [hierarchy, model]);

  return (
    <div style={{marginBottom: '3rem', cursor: 'pointer'}} onClick={onClick}>
      {flatHierarchy.map((n, index) => (
        <div
          key={n.model.uuid}
          className={styles.cardWrapper}
          style={{
            zIndex: flatHierarchy.length - index,
          }}>
          <ModelCard model={n.model} current={n.model.uuid === activeModelUuid} />
        </div>
      ))}
    </div>
  );
};

export default LineageRoot;
