import React, {ReactNode, useContext} from 'react';
import styles from './ModelUploadPageHeader.module.scss';
import clsx from 'clsx';
import {Container, Grid} from '@material-ui/core';
import {ModelUploadContext} from '../ModelUploadContext';
import {ModelUploadFlow} from '../types';

type ModelUploadPageHeaderProps = {
  title: string;
  subTitle: ReactNode | string;
  aside?: ReactNode | string;
};

const ModelUploadPageHeader: React.FC<ModelUploadPageHeaderProps> = ({title, subTitle, aside}) => {
  const {flow} = useContext(ModelUploadContext);
  const manualModelFlow = () => {
    return flow === ModelUploadFlow.MANUAL_ENTRY ? 'Manual Entry' : metadataModelFlow();
  };
  const metadataModelFlow = () => {
    return flow === ModelUploadFlow.BASED_ON_METADATA ? 'Based on Metadata' : null;
  };
  return (
    <div className={styles.wrapper}>
      <Container maxWidth={false}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={styles.container}>
          <Grid item xs={2}>
            <span className={styles.aside}>{aside ? aside : manualModelFlow()}</span>
          </Grid>
          <Grid item xs={1} />
          <Grid item>
            <span className={styles.title}>{title}</span>
            <br />
            <span className={styles.subTitle}>{subTitle}</span>
          </Grid>
        </Grid>
      </Container>

      <div
        className={clsx(styles.colorLine, {
          [styles.manualEntry]: flow === ModelUploadFlow.MANUAL_ENTRY,
          [styles.basedOnMetadata]: flow === ModelUploadFlow.BASED_ON_METADATA,
        })}></div>
    </div>
  );
};

export default ModelUploadPageHeader;
