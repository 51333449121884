import React from 'react';
import styles from './ModelCurve.module.scss';

type ModelCurveProps = {};

const ModelCurve: React.FC<ModelCurveProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <div className={styles.arrowDown} />
    </div>
  );
};

export default ModelCurve;
