import {FindScenariosRequest, ScenarioDTO, ScenarioStatus} from 'hemwb-api';

export const getScenarioListKey = (p: FindScenariosRequest): string => {
  return JSON.stringify(
    (Object.keys(p) as (keyof FindScenariosRequest)[]).sort().reduce((acc, key) => {
      // @ts-ignore
      acc[key] = p[key];
      return acc;
    }, ({} as any) as FindScenariosRequest),
  );
  // return `${p.orderBy}-${p.modelUuid}-${p.orderDesc}-${p.pageIndex}-${p.pageSize}-${p.query}-${p.status?.join(',')}-${p.outdated}-${p.executionType}-${p.}-${}`
};

export const isScenarioFinished = (status?: ScenarioStatus) =>
  status === ScenarioStatus.ACCEPTED || status === ScenarioStatus.COMPLETED || status === ScenarioStatus.SUPERSEDED;

export const isScenarioRunning = (status?: ScenarioStatus) =>
  status === ScenarioStatus.INPROCESS || status === ScenarioStatus.INQUEUE;

export const isScenarioDTO = <T extends ScenarioDTO>(scenario?: T | null): scenario is T =>
  !!scenario && typeof scenario.id === 'number';

export function titleCase(value: string | undefined) {
  if (value) {
    if (value === 'VESTLEAD') {
      return 'Value and Implementation Lead';
    }
    return value
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(?: |\b)(\w)/g, function (key) {
        return key.toUpperCase();
      });
  } else {
    return ' ';
  }
}
