import {incidentListSelector, incidentSelector} from './selectors';
import {incidentLoadList, incidentLoadDetail} from './actions';
import {useSimpleReduxLoader, useReduxLoader} from '../useReduxLoader';
import {IncidentListDTO} from 'hemwb-api';
import {Dispatch} from 'redux';
import {RootState, useSelector} from '../rootReducer';
import {useMemo} from 'react';

export const useIncidentList = () => {
  return useSimpleReduxLoader(incidentListSelector, incidentLoadList);
};

const detailSelector = (state: RootState) => incidentSelector(state).detail;
const detailSelectorBuilder = (params: [string]) => (state: RootState) =>
  detailSelector(state)[params[0] || ''] || null;

const incidentLoadDetailIgnoreNull = (dispatch: Dispatch, incidentId: string | null) => {
  return incidentId ? incidentLoadDetail(dispatch, incidentId) : Promise.resolve();
};
incidentLoadDetailIgnoreNull.clear = incidentLoadDetail.clear;

export const useIncidentDetail = (incident: IncidentListDTO | string | null | undefined) => {
  const incidentId = typeof incident === 'string' ? incident : incident?.incidentId || null;
  const loaded = useReduxLoader(detailSelectorBuilder, incidentLoadDetailIgnoreNull, incidentId);
  const incidentInState = useSelector((state) => incidentSelector(state).incidents[incidentId || '']);

  return useMemo(() => {
    if (loaded && incidentInState) {
      return incidentInState;
    }
    return null;
  }, [loaded, incidentInState]);
};
