import React, {PropsWithChildren} from 'react';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {SelectItem} from '../types';
import {Grid} from '@material-ui/core';
import {SingleCheckboxControl, TextFieldControl} from '../../../Form';

type SelectItemPrimitiveProps = {
  formGroup: FormGroup;
};

export const SelectItemPrimitive: React.FC<PropsWithChildren<SelectItemPrimitiveProps>> = ({children, formGroup}) => {
  const {controls} = formGroup;

  return (
    <>
      <Grid item>
        <TextFieldControl label="Key" control={controls.key} name="key" />
      </Grid>
      <Grid item>
        <TextFieldControl label="Text" control={controls.text} name="text" />
      </Grid>
      <Grid item>
        <br />
        <SingleCheckboxControl name="selected" label="Selected" control={controls.selected} />
      </Grid>
      {children}
    </>
  );
};

const validateSiblingUnique = (inputName: string) => (control: AbstractControl) => {
  if (!control.parent || !control.parent.parent) {
    return null;
  }

  if (
    (control.parent.parent as FormArray).controls.some((fg) => {
      const c = fg.get(inputName);
      if (c !== control && c.value === control.value) {
        return true;
      } else if (c.value && c.hasError('isUnique')) {
        setTimeout(() => {
          c.updateValueAndValidity();
        }, 50);
      }
      return false;
    })
  ) {
    return {
      isUnique: true,
    };
  }

  return null;
};

export const selectItemPrimitiveControlBuilder = (initialValues: SelectItem, singleSelect = true) => {
  const controls = {
    key: new FormControl(initialValues?.key ?? '', [Validators.required, validateSiblingUnique('key')]),
    text: new FormControl(initialValues?.text ?? '', [Validators.required, validateSiblingUnique('text')]),
    selected: new FormControl(initialValues?.selected ?? false),
  };

  if (singleSelect) {
    controls.selected.valueChanges.subscribe((newValue: boolean) => {
      if (controls.selected?.parent?.parent?.controls) {
        (controls.selected.parent.parent as FormArray).controls.forEach((fg) => {
          const selectedControl = fg.get('selected');
          if (newValue && selectedControl !== controls.selected && selectedControl.value === true) {
            selectedControl.setValue(false);
          } else {
          }
        });
      }
    });
  }

  return controls;
};
