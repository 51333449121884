import React, {useCallback} from 'react';
import {UserEnrollForm, UserEnrollFormValues} from './UserEnrollForm';
import {useHistory} from 'react-router-dom';
import {userEnroll} from '../../../../../store/user';
import {useDispatch} from 'react-redux';
import {trackUserEnrolled} from '../../../../../tracking/tracking';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import {fullName} from '../../../../../store/user/utils';
import {getUrlRoute, Routes} from '../../../../router/routes';
import AdminSpaceHeader from '../../common/AdminSpaceHeader/AdminSpaceHeader';
import AdminPage from '../../common/AdminPage/AdminPage';

const UserEnroll: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reportIdError = useCallback((error: any) => {
    return error.body?.reportId
      ? `An error has occurred. Please try to reload the page. If this error persists, please contact support, mentioning the following report id: ${error.body.reportId}.`
      : `An unknown error has occurred. Please try to reload page.`;
  }, []);
  const errorMessage = useCallback(
    (error: any) => {
      return error.body?.message ? `An error has occurred: ${error.body.message}` : reportIdError(error);
    },
    [reportIdError],
  );

  const handleSubmit = useCallback(
    (values: UserEnrollFormValues) => {
      const {user, userType} = values;
      return userEnroll(dispatch, user.id, userType)
        .then(() => {
          trackUserEnrolled();
          dispatch(messageAdd(`${fullName(user)} has been enrolled`, MessageTypes.SUCCESS));
          history.push(getUrlRoute(Routes.USER_LIST));
        })
        .catch((error) => {
          if (error.status >= 400) {
            dispatch(
              messageAdd(
                error.status === 409 ? `${fullName(user)} already exists in database` : errorMessage(error),
                MessageTypes.ERROR,
              ),
            );
          }
        });
    },
    [dispatch, history, errorMessage],
  );

  return (
    <AdminPage style={{paddingLeft: '2rem'}}>
      <AdminSpaceHeader />
      <UserEnrollForm onSubmit={handleSubmit} />
    </AdminPage>
  );
};

export default UserEnroll;
