import {PERMISSION, Permission} from './types';
import {LoggedInUserDTO, UserDTO, UserType} from 'hemwb-api';

// MODELS
export const canCreateModel = (loggedInUser: LoggedInUserDTO): Permission => !!loggedInUser.permissions?.createModel;
export const canCreateExternalUser = (loggedInUser: LoggedInUserDTO): Permission =>
  !!loggedInUser.permissions?.externalizationTabEnabled;
export const canDownloadAllModels = (loggedInUser: LoggedInUserDTO): Permission =>
  loggedInUser.userType === UserType.SUPERUSER;

//MANAGE METADATA
// const ALLOWED_TYPE_METADATA = ['studyNumbers', 'therapeuticAreas', 'products', 'diseases'];
export const canManageMetadata = (loggedInUser: LoggedInUserDTO, property?: string): Permission => {
  if (loggedInUser.userType !== UserType.SUPERUSER) {
    return PERMISSION.FORBIDDEN;
  }
  if (property /* && ALLOWED_TYPE_METADATA.includes(property) */) {
    return PERMISSION.ALLOWED;
  }
  return PERMISSION.DISALLOWED;
};

// USER MANAGEMENT
const allowIfNotTheLoggedInUser = (loggedInUser: LoggedInUserDTO, user?: UserDTO): Permission => {
  if (user && user.id !== loggedInUser.id) {
    return PERMISSION.ALLOWED;
  }

  return PERMISSION.DISALLOWED;
};

export const canActivateUser = (loggedInUser: LoggedInUserDTO, user?: UserDTO): Permission => {
  if (!loggedInUser.permissions?.activateUser) {
    return PERMISSION.FORBIDDEN;
  }
  if (user?.active) {
    return PERMISSION.DISALLOWED;
  }
  return allowIfNotTheLoggedInUser(loggedInUser, user);
};

export const canDeactivateUser = (loggedInUser: LoggedInUserDTO, user?: UserDTO): Permission => {
  if (!loggedInUser.permissions?.deactivateUser) {
    return PERMISSION.FORBIDDEN;
  }
  if (!user?.active) {
    return PERMISSION.DISALLOWED;
  }
  return allowIfNotTheLoggedInUser(loggedInUser, user);
};

export const canEnrollUser = (loggedInUser: LoggedInUserDTO): Permission => {
  return !!loggedInUser.permissions?.enrollUser;
};

export const canChangeUserRole = (loggedInUser: LoggedInUserDTO, user?: UserDTO): Permission => {
  if (!loggedInUser.permissions?.changeUserRole) {
    return PERMISSION.FORBIDDEN;
  }
  return allowIfNotTheLoggedInUser(loggedInUser, user);
};

export const isExternalUser = (loggedInUser: LoggedInUserDTO): Permission =>
  loggedInUser.userType === UserType.EXTERNALUSER;
