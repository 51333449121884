import {
  AppComponentsActions,
  AppComponentsButtonWithPopperClearAction,
  AppComponentsButtonWithPopperSetAction,
  AppComponentsButtonWithPopperToggleAction,
  ButtonWithPopperType,
} from './types';

export const appComponentsButtonWithPopperClear = (): AppComponentsButtonWithPopperClearAction => ({
  type: AppComponentsActions.BUTTON_WITH_POPPER_CLEAR,
});

export const appComponentsButtonWithPopperSet = (
  button: ButtonWithPopperType,
): AppComponentsButtonWithPopperSetAction => ({
  type: AppComponentsActions.BUTTON_WITH_POPPER_SET,
  button,
});

export const appComponentsButtonWithPopperToggle = (
  button: ButtonWithPopperType,
): AppComponentsButtonWithPopperToggleAction => ({
  type: AppComponentsActions.BUTTON_WITH_POPPER_TOGGLE,
  button,
});
