import {ModelFileDTO, ModelFolderDTO, ModelTreeItemDTO} from 'hemwb-api';
import {
  ModelFileQueued,
  ModelFileStateConfig,
  ModelFileTags,
  ModelFileUploaded,
  ModelParseInfo,
  ModelTreeNode,
  TreeNode,
  TreeNodeTags,
} from './types';
import snakeCase from 'lodash/snakeCase';

export const modelFileFlagByType = (type: string) => {
  switch (type) {
    case 'Excel':
      return ModelFileTags.MODEL_FILE_EXCEL;
    case 'Mathematica':
      return ModelFileTags.MODEL_FILE_MATHEMATICA;
    case 'R':
      return ModelFileTags.MODEL_FILE_R;
    case 'Python':
      return ModelFileTags.MODEL_FILE_PYTHON;
  }
};

export const isModelFileUploaded = (node: ModelTreeNode): node is ModelFileUploaded => !node.folder && node.uploaded;
export const isModelFileQueued = (node: ModelTreeNode): node is ModelFileQueued => !node.folder && !node.uploaded;
export const canBeMainFile = (node: ModelTreeNode, type: string): node is ModelFileUploaded => {
  const flag = modelFileFlagByType(type);
  return flag ? nodeHasTag(node, flag) : false;
};
export const canBeChangeLogFile = (node: ModelTreeNode): node is ModelFileUploaded => {
  return nodeHasTag(node, ModelFileTags.DOCUMENTATION_FILE);
};

export const flattenTree = (nodes: ModelTreeItemDTO[], path = '', result: ModelTreeNode[] = []): ModelTreeNode[] => {
  nodes.forEach((node) => {
    const pathName = `${path}/`;
    if (node.folder) {
      result.push({
        ...node,
        uuid: node.uuid,
        name: node.name,
        path: `${path === '' ? '' : pathName}${node.name}`,
        fileTag: node.fileTag,
        folder: true,
        uploaded: true,
      });
      flattenTree((node as ModelFolderDTO).children, `${path === '' ? '' : pathName}${node.name}`, result);
    } else {
      result.push({
        ...node,
        mainFile: (node as ModelFileDTO).mainFile,
        uuid: node.uuid,
        path: `${path === '' ? '' : pathName}${node.name}`,
        name: node.name,
        folder: false,
        uploaded: true,
      });
    }
  });

  return result;
};

export function parseFileExtension(nameWithExtension: string) {
  return nameWithExtension.slice(((nameWithExtension.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
}

export const calculateTags = (
  node: ModelTreeNode,
  config: ModelFileStateConfig,
  isModelPublished: boolean,
  modelLanguage?: string,
  bitbucketPath?: string,
): ModelFileTags[] => {
  const {
    modelMathematicaRegExp,
    modelExcelRegExp,
    modelRRegExp,
    modelPythonRegExp,
    documentationRegExp,
    // allowedExtensionRegExp,
    maxBytes,
  } = config;
  if (node.folder) {
    return [];
  }

  const tags: ModelFileTags[] = [];

  if (modelMathematicaRegExp!.test(node.name)) {
    tags.push(ModelFileTags.MODEL_FILE, ModelFileTags.MODEL_FILE_MATHEMATICA);
  } else if (modelExcelRegExp!.test(node.name)) {
    tags.push(ModelFileTags.MODEL_FILE, ModelFileTags.MODEL_FILE_EXCEL);
  } else if (modelRRegExp!.test(node.name)) {
    tags.push(ModelFileTags.MODEL_FILE, ModelFileTags.MODEL_FILE_R);
  } else if (modelPythonRegExp!.test(node.name)) {
    tags.push(ModelFileTags.MODEL_FILE, ModelFileTags.MODEL_FILE_PYTHON);
  } else if (documentationRegExp!.test(node.name)) {
    tags.push(ModelFileTags.DOCUMENTATION_FILE);
  }

  if (isModelFileUploaded(node) && isModelPublished) {
    if (node.mainFile) {
      tags.push(ModelFileTags.PUBLISHED_MAIN_FILE);
    }
    if (modelLanguage && config.modelFileExtensions[modelLanguage]?.includes(parseFileExtension(node.name))) {
      tags.push(ModelFileTags.PUBLISHED_MODEL_FILE);
    }
  }

  // if (!allowedExtensionRegExp!.test(node.name)) {
  //   tags.push(ModelFileTags.EXTENSION_ERROR);
  // }

  // @ts-ignore
  if (!!node.file && node.file.size > maxBytes) {
    tags.push(ModelFileTags.SIZE_ERROR);
  }

  if (node.path === bitbucketPath) {
    tags.push(ModelFileTags.BITBUCKET_FILE);
  }

  return tags;
};

export const nodeHasTag = <T extends TreeNode>(node: T, tag: ModelFileTags): boolean => {
  return node.tags && node.tags[tag] && node.tags[tag] === true ? true : false;
};

export const nodeSetTags = <T extends TreeNode>(node: T, add: ModelFileTags[], remove: ModelFileTags[]): T => {
  return {
    ...node,
    tags: {
      ...node.tags,
      ...add.reduce((acc: TreeNodeTags, val: ModelFileTags) => {
        acc[val] = true;
        return acc;
      }, {}),
      ...remove.reduce((acc: TreeNodeTags, val: ModelFileTags) => {
        acc[val] = false;
        return acc;
      }, {}),
    },
  };
};

export const nodeSetFileTag = <T extends TreeNode>(node: T, fileTag: string): T => {
  if (node.fileTag !== fileTag) {
    return {
      ...node,
      fileTag: fileTag,
      tags: {
        ...node.tags,
        ...[ModelFileTags.FILE_TAG_OVERWRITE].reduce((acc: TreeNodeTags, val: ModelFileTags) => {
          acc[val] = true;
          return acc;
        }, {}),
      },
    };
  } else {
    return {
      ...node,
    };
  }
};

export const nodeUnMarkFileTag = <T extends TreeNode>(node: T, oldFileTag: string | undefined): T => {
  return {
    ...node,
    fileTag: oldFileTag,
    tags: {
      ...node.tags,
      ...[ModelFileTags.FILE_TAG_OVERWRITE].reduce((acc: TreeNodeTags, val: ModelFileTags) => {
        acc[val] = false;
        return acc;
      }, {}),
    },
  };
};

export const nodeAddTag = <T extends TreeNode>(node: T, tag: ModelFileTags | ModelFileTags[]): T => {
  const tags = Array.isArray(tag) ? tag : [tag];
  return nodeSetTags(node, tags, []);
};

export const nodeAddFileTag = <T extends TreeNode>(node: T, fileTag: string): T => {
  return nodeSetFileTag(node, fileTag);
};

export const nodeFileTagUpdateCancel = <T extends TreeNode>(node: T, oldFileTag: string | undefined): T => {
  return nodeUnMarkFileTag(node, oldFileTag);
};

export const nodeRemoveTag = <T extends TreeNode>(node: T, tag: ModelFileTags | ModelFileTags[]): T => {
  const tags = Array.isArray(tag) ? tag : [tag];
  return nodeSetTags(node, [], tags);
};

export const parseFileNameFromPath = (path: string): string => {
  const chunks = path.split('/');
  return chunks[chunks.length - 1];
};

export function calculateFileName(originalName: string): string {
  // this is done because of MLMP renaming
  return originalName.replace(/ /g, '_');
}

export const parseMetadata = (modelInfoSheet: any[]): ModelParseInfo => {
  if (!modelInfoSheet) {
    return {};
  }
  return modelInfoSheet.reduce((acc: any, row: any) => {
    if (!row['Data Element'] || !row['Value']) {
      return acc;
    }
    let key = snakeCase(row['Data Element']);
    let value = row.Value;
    if (key === 'type_of_model') {
      key = 'model_type';
    } else if (key === 'name_of_disease') {
      key = 'disease';
    } else if (key === 'name_of_product') {
      key = 'product';
    } else if (key === 'e_mail_for_model_steward') {
      key = 'email_for_model_steward';
    } else if (key === 'model_language') {
      acc['type'] = value === 'Excel' ? 'ExcelNative' : value;
      return acc;
    }

    if (value instanceof Date) {
      value = `${value.toLocaleDateString('en-US')} 12:00:00 AM`;
    }

    acc[key] = value;
    return acc;
  }, ({} as any) as ModelParseInfo);
};
