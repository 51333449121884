import React, {
  forwardRef,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {InputAttributes} from '../InputDefinition/types';
import {FormArray, FormGroup} from 'react-reactive-form';
import {Form, useForm} from '../../Form';
import {generators} from './generators';
import {associateFormValuesWithName} from './utils';
import {CalibrationScenarioInputs} from './types';
import {formatInput} from './formatInput';
import InputList from './InputList';
import {scenarioFormGenerator} from './scenarioFormGenerator';
import {useDialogConfirmation} from '../../dialog/useDialogConfirmation';
import {ExecutionSetupType} from 'hemwb-api';
import {getInputDefinitionCategories} from '../InputDefinition/utils';

type ScenarioParametersProps = {
  subModelType: ExecutionSetupType;
  definitions: InputAttributes[];
  values?: CalibrationScenarioInputs;
  submitting: boolean;
  onSubmit?: (value: CalibrationScenarioInputs) => void;
  inputListPortalRef?: RefObject<HTMLDivElement>;
  renderStatic?: boolean;
  formObjectRef?: MutableRefObject<FormGroup | undefined>;
  expertiseLevel: number;
  modelUuid: string;
};

export type ScenarioParametersRefType = {
  submit: () => void;
};

const ScenarioParameters: React.FC<ScenarioParametersProps> = (
  {
    subModelType,
    definitions,
    formObjectRef,
    values = {},
    onSubmit,
    inputListPortalRef,
    renderStatic,
    expertiseLevel,
    modelUuid,
  },
  ref,
) => {
  const inputCategories = getInputDefinitionCategories(subModelType);
  const form = useForm(scenarioFormGenerator(definitions, values));

  useEffect(() => {
    if (formObjectRef) {
      formObjectRef.current = form;
    }
  }, [formObjectRef, form]);

  const handleSubmit = useCallback(() => {
    onSubmit?.(formatInput(definitions, associateFormValuesWithName(definitions, form.value.parameters)));
  }, [form, onSubmit, definitions]);

  const {Component: InvalidParametersDialog, open: openInvalidParametersDialog} = useDialogConfirmation({
    title: 'Parameters are not valid',
    text: 'Do you want to save it anyway?',
    onClickYes: handleSubmit,
  });

  const handleFormSubmit = useCallback(() => {
    if (form.valid) {
      handleSubmit();
    } else {
      openInvalidParametersDialog();
    }
  }, [form, handleSubmit, openInvalidParametersDialog]);

  const categoryByInputId = useMemo(() => {
    let prevCategoryId = '';
    return definitions.reduce((acc, definition) => {
      if (prevCategoryId !== definition.categoryId) {
        prevCategoryId = definition.categoryId;
        acc[definition.id] = inputCategories[definition.categoryId];
      }

      return acc;
    }, ({} as any) as {[id: number]: string});
  }, [definitions, inputCategories]);

  const getInputsStatus = useCallback(() => {
    return definitions.reduce((acc, definition, index) => {
      acc[definition.id] = (form.get('parameters') as FormArray).controls[index]?.status === 'VALID' ?? true; //labels do not have control
      return acc;
    }, ({} as any) as {[key: number]: boolean});
  }, [definitions, form]);
  const [inputsStatus, setInputsStatus] = useState<ReturnType<typeof getInputsStatus>>(getInputsStatus());

  const getInputsControlStatus = useCallback(() => {
    return definitions.reduce((acc, definition) => {
      let resultControlType = null;

      if (definition.controlledBy) {
        const controllerIndex = definitions.findIndex((d) => d.id === definition.controlledBy!.id);
        if (controllerIndex >= -1) {
          resultControlType =
            (form.get('parameters') as FormArray).controls[controllerIndex].value === definition.controlledBy.value
              ? null
              : definitions[controllerIndex].controlType || null;
        }
      }

      acc[definition.id] = resultControlType;
      return acc;
    }, ({} as any) as {[key: number]: string | null});
  }, [definitions, form]);
  const [inputsControlStatus, setInputsControlStatus] = useState<ReturnType<typeof getInputsControlStatus>>(
    getInputsControlStatus(),
  );

  const handleStatusChange = useCallback(() => {
    setInputsStatus(getInputsStatus());
    setInputsControlStatus(getInputsControlStatus());
  }, [getInputsControlStatus, getInputsStatus]);

  useEffect(() => {
    if (form) {
      form.statusChanges.subscribe(handleStatusChange);
      return () => {
        form.statusChanges.unsubscribe(handleStatusChange);
      };
    }
  }, [form, handleStatusChange]);

  const handleInputClick = useCallback((id: number) => {
    const titleElement = document?.getElementById(`input-title-${id}`);
    const input = titleElement?.nextElementSibling?.querySelector('input');
    const inputListBox = titleElement?.nextElementSibling?.querySelector("[aria-haspopup='listbox']");
    const inputAndTitleElementFocus = () => {
      return input ? input.focus() : titleElement?.focus();
    };
    return inputListBox ? (inputListBox as HTMLElement)?.focus() : inputAndTitleElementFocus();
  }, []);

  useImperativeHandle(ref, () => ({
    submit() {
      handleFormSubmit();
    },
  }));

  return (
    <div style={{position: 'relative'}}>
      <Form
        FieldGroupProps={{strict: false}}
        group={form}
        className=""
        render={() => {
          return (
            <>
              <InputList
                expertiseLevelValue={expertiseLevel}
                subModelType={subModelType}
                definitions={definitions}
                inputsStatus={inputsStatus as any}
                inputsControlStatus={inputsControlStatus}
                onInputClick={handleInputClick}
                inputListPortalRef={inputListPortalRef}
              />
              {Object.keys(inputCategories).map((categoryId) => {
                const parameters = form.get('parameters') as FormArray;
                return parameters.controls.map((c, index) => {
                  const definition = definitions[index];
                  const parameterLevel = definition.expertiseLevel ? definition.expertiseLevel.split('-')[0] : 500;
                  if (definition.categoryId !== categoryId) {
                    return null;
                  }

                  const controlType = inputsControlStatus?.[definition.id] || null;

                  const Component = renderStatic
                    ? generators[definition.type].renderStatic
                    : generators[definition.type].render;
                  return (
                    <React.Fragment key={definition.id}>
                      {categoryByInputId[definition?.id] && <h2>{categoryByInputId[definition.id]}</h2>}
                      {controlType !== 'HS' && definition.visible && expertiseLevel >= Number(parameterLevel) && (
                        <Component
                          control={c}
                          definition={definition}
                          disabled={controlType === 'ED'}
                          modelUuid={modelUuid}
                          values={values}
                        />
                      )}
                    </React.Fragment>
                  );
                });
              })}
            </>
          );
        }}
      />

      {InvalidParametersDialog}
    </div>
  );
};

export default forwardRef(ScenarioParameters);
