import React from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {getUrlRoute, Routes, useRoute} from '../../../../router/routes';
import {fullName} from '../../../../../store/user/utils';
import {useUserFromList} from '../../../../../store/user';
import {messageAdd, MessageTypes} from '../../../../../store/message';
import AdminPage from '../../common/AdminPage/AdminPage';

const UserDetail: React.FC = () => {
  const {id} = useRoute<Routes.USER_DETAIL>();
  const dispatch = useDispatch();
  const history = useHistory();

  const {user, notFound} = useUserFromList(id);
  if (notFound) {
    dispatch(messageAdd('User not found', MessageTypes.ERROR));
    history.push(getUrlRoute(Routes.USER_LIST));
  }

  return (
    <AdminPage style={{paddingLeft: '2rem'}}>
      <h1>Detail of user with id: {id}</h1>
      {user && <h2>{fullName(user)}</h2>}
      <Link to={Routes.USER_LIST}>Back to user list</Link>
    </AdminPage>
  );
};

export default UserDetail;
