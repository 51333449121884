import {GridActions, GridModifier, GridState, GridStateUpdateAction, Order, RowId} from './types';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useState} from 'react';
import {buildGridStateActionCreator, buildInitialGridState} from './utils';
import {RootState} from '../rootReducer';
import {ModelDTO} from 'hemwb-api';
import {modelListGridId} from './gridIds';
import {ModelFilterState} from '../../components/pages/model/filter';

export type GridStateSelector = (appState: RootState) => GridState;

export const buildGridStateSelector = (gridId: string): GridStateSelector => (appState: RootState) =>
  appState.grid[gridId];

export const useStoredGridState = <F = null>(gridId: string): GridState<F> & GridModifier<F> => {
  const dispatch = useDispatch();
  const gridState = useSelector(buildGridStateSelector(gridId));
  const actionCreator = buildGridStateActionCreator<F>(gridId);
  return {
    ...gridState,
    setOrder: (order: Order) => dispatch(actionCreator.order(order)),
    setOrderBy: (orderBy: string | null) => dispatch(actionCreator.orderBy(orderBy)),
    setPage: (page: number) => dispatch(actionCreator.page(page)),
    setRowsPerPage: (rowsPerPage: number) => dispatch(actionCreator.rowsPerPage(rowsPerPage)),
    setExpandedRowId: (expandedRowId: RowId | null) => dispatch(actionCreator.expandedRowId(expandedRowId)),
    setFilter: (filter: F | null) => dispatch(actionCreator.filter(filter)),
    setSearch: (search: string) => dispatch(actionCreator.search(search)),
    setViewMyModels: (viewMyModels: boolean) => dispatch(actionCreator.viewMyModels(viewMyModels)),
    setDynamicPageViewMyModels: (dynamicPageViewMyModels: boolean) =>
      dispatch(actionCreator.dynamicPageViewMyModels(dynamicPageViewMyModels)),
  };
};

export const useGridState = <F = null>(
  customDefaultState: Partial<GridState<F>> = {},
): GridState<F> & GridModifier<F> => {
  // eslint-disable-next-line
  const initialState = buildInitialGridState(<F>customDefaultState);
  const [order, setOrder] = useState<Order>(initialState.order);
  const [orderBy, setOrderBy] = useState<string | null>(initialState.orderBy);
  const [expandedRowId, setExpandedRowId] = useState<null | RowId>(initialState.expandedRowId);
  const [page, setPage] = useState(initialState.page);
  const [rowsPerPage, setRowsPerPage] = useState(initialState.rowsPerPage);
  const [filter, setFilter] = useState<F | null>(initialState.filter);
  const [search, setSearch] = useState(initialState.search);
  const [viewMyModels, setViewMyModels] = useState(initialState.viewMyModels);
  const [dynamicPageViewMyModels, setDynamicPageViewMyModels] = useState(initialState.dynamicPageViewMyModels);

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    expandedRowId,
    setExpandedRowId,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    filter,
    setFilter,
    search,
    setSearch,
    viewMyModels,
    setViewMyModels,
    dynamicPageViewMyModels,
    setDynamicPageViewMyModels,
  };
};

const initialGridState = buildInitialGridState();

export const usePrefillModelListGridStateWithModel = () => {
  const dispatch = useDispatch();
  const modelListGridState = useStoredGridState(modelListGridId);

  return useCallback(
    (model: ModelDTO) => {
      const {uuid, metadata} = model;
      if (modelListGridState.expandedRowId !== uuid) {
        const {disease, product} = metadata;
        dispatch({
          type: GridActions.UPDATE_STATE,
          gridId: modelListGridId,
          update: {
            ...initialGridState,
            expandedRowId: uuid,
            filter: {
              products: product ? [product] : [],
              diseases: disease ? [disease] : [],
              modelTypes: [],
              stewards: [],
              creators: [],
              global: [],
              countries: [],
              type: [],
              therapeuticAreas: [],
              platforms: [],
              status: ['Draft', 'Published'],
            },
          },
        } as GridStateUpdateAction<ModelFilterState>);
      }
      return true;
    },
    [dispatch, modelListGridState],
  );
};
