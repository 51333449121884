import React, {CSSProperties, ReactNode} from 'react';
import {UserDTO} from 'hemwb-api';
import {Avatar, Card, CardHeader, Chip} from '@material-ui/core';
import UserCardDefaultActions from './UserCardDefaultActions';
import clsx from 'clsx';
import {fullNameWestern} from '../../../store/user/utils';
import {tid} from '../../../testUtils';

export type UserCardProps = {
  label: string;
  user: UserDTO;
  actions?: ReactNode;
  style?: CSSProperties;
  className?: string;
};

export const UserCard: React.FC<UserCardProps> = ({user, label, actions, style, className}) => {
  return (
    <Card className={clsx('card', className, {inactive: !user.active})} style={style} {...tid('user-card', user.id)}>
      <CardHeader
        avatar={
          <Avatar>
            {user.firstName && user.firstName[0]}
            {user.lastName && user.lastName[0]}
            {!user.firstName && !user.lastName && user.id[0]}
          </Avatar>
        }
        action={actions || <UserCardDefaultActions user={user} />}
        title={
          <>
            <div className="label">
              {label}
              {!user.active && ' (inactive)'}
            </div>
            <div className="primary" {...tid('user-card-name', user.id)}>
              {fullNameWestern(user)}
            </div>
          </>
        }
        subheader={
          <div className="secondary">
            {user.email}
            {user?.userType === 'EXTERNAL_USER' && (
              <div>
                {' '}
                <Chip
                  label="EXTERNAL"
                  size="small"
                  style={{backgroundColor: '#006400', color: 'white', fontSize: '10px'}}
                />
              </div>
            )}
          </div>
        }
      />
    </Card>
  );
};
