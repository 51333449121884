import {AnyAction, applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import {createHashHistory} from 'history';
import {appInitialState, appReducer} from './app/reducer';
import {authInitialState, authReducer} from './auth/reducer';
import {modelInitialState, modelReducer} from './model/reducer';
import {userInitialState, userReducer} from './user/reducer';
import {modelFileInitialState, modelFileReducer} from './modelFile/reducer';
import {gridInitialState, gridReducer} from './grid/reducer';
import {incidentManagementInitialState, incidentManagementReducer} from './incident/reducer';
import {messageInitialState, messageReducer} from './message/reducer';
import {appDataInitialState, appDataReducer} from './appData/reducer';
import {modelMetadataInitialState, modelMetadataReducer} from './modelMetadata/reducer';
import {TypedUseSelectorHook, useSelector as useReduxSelector} from 'react-redux';
import {authExpirationInitialState, authExpirationReducer} from './authExpiration/reducer';
import {setHistory} from './asyncUtils';
import {modelViewersInitialState, modelViewersReducer} from './modelViewers/reducer';
import {modelDisplayViewersInitialState, modelDisplayViewersReducer} from './modelDisplayViewers/reducer';
import {modelLinksInitialState, modelLinksReducer} from './modelLinks/reducer';
import {scenarioInitialState, scenarioReducer} from './scenario/reducer';
import {scenarioExecutionInitialState, scenarioExecutionReducer} from './scenarioExecution/reducer';
import {scenarioGroupInitialState, scenarioGroupReducer} from './scenarioGroup/reducer';
import {customSettingsInitialState, customSettingsReducer} from './customSettings/reducer';
import {AuthAction, AuthActions} from './auth/types';
import {AsyncActionPhase} from './asyncUtilsTypes';
import {bitbucketInitialState, bitbucketReducer} from './bitbucket/reducer';
import {versionControlInitialState, versionControlReducer} from './versionControl/reducer';
import {excelDiffInitialState, excelDiffReducer} from './excelDiff/reducer';
import {veevaInitialState, veevaReducer} from './veeva/reducer';
import {modelInputsInitialState, modelInputsReducer} from './modelInputs/reducer';
import {executionSetupInitialState, executionSetupReducer} from './executionSetup/reducer';
import {modelAccessInitialState, modelAccessReducer} from './modelAccess/reducer';
import {visualizationInitialState, visualizationReducer} from './visualization/reducer';
import {appComponentsInitialState, appComponentsReducer} from './appCompoments/reducer';
import {userExpertiseInitialState, userExpertiseReducer} from './userExpertise/reducer';
import {notificationsInitialState, notificationsReducer} from './notifications/reducer';
import {guidingTourInitialState, guidingTourReducer} from './guidingTour/reducer';

export const history = createHashHistory();
setHistory(history);

const combinedReducer = combineReducers({
  router: connectRouter(history),
  message: messageReducer,
  auth: authReducer,
  authExpiration: authExpirationReducer,
  bitbucket: bitbucketReducer,
  customSettings: customSettingsReducer,
  excelDiff: excelDiffReducer,
  executionSetup: executionSetupReducer,
  grid: gridReducer,
  incident: incidentManagementReducer,
  model: modelReducer,
  modelAccess: modelAccessReducer,
  modelFile: modelFileReducer,
  modelInputs: modelInputsReducer,
  modelMetadata: modelMetadataReducer,
  modelViewers: modelViewersReducer,
  modelDisplayViewers: modelDisplayViewersReducer,
  modelLinks: modelLinksReducer,
  scenario: scenarioReducer,
  scenarioExecution: scenarioExecutionReducer,
  scenarioGroup: scenarioGroupReducer,
  user: userReducer,
  veeva: veevaReducer,
  versionControl: versionControlReducer,
  visualization: visualizationReducer,
  appData: appDataReducer,
  app: appReducer,
  appComponents: appComponentsReducer,
  userExpertise: userExpertiseReducer,
  notifications: notificationsReducer,
  guidingTour: guidingTourReducer,
  //useUserExpertiseList: userExpertiseOptionsReducer,
});

export type RootState = Readonly<ReturnType<typeof combinedReducer>>;

export const rootInitialState: RootState = {
  router: history,
  appData: appDataInitialState,
  message: messageInitialState,
  auth: authInitialState,
  authExpiration: authExpirationInitialState,
  bitbucket: bitbucketInitialState,
  customSettings: customSettingsInitialState,
  excelDiff: excelDiffInitialState,
  executionSetup: executionSetupInitialState,
  grid: gridInitialState,
  incident: incidentManagementInitialState,
  model: modelInitialState,
  modelAccess: modelAccessInitialState,
  modelFile: modelFileInitialState,
  modelInputs: modelInputsInitialState,
  modelMetadata: modelMetadataInitialState,
  modelViewers: modelViewersInitialState,
  modelDisplayViewers: modelDisplayViewersInitialState,
  modelLinks: modelLinksInitialState,
  scenario: scenarioInitialState,
  scenarioExecution: scenarioExecutionInitialState,
  scenarioGroup: scenarioGroupInitialState,
  user: userInitialState,
  veeva: veevaInitialState,
  versionControl: versionControlInitialState,
  visualization: visualizationInitialState,
  app: appInitialState,
  appComponents: appComponentsInitialState,
  userExpertise: userExpertiseInitialState,
  notifications: notificationsInitialState,
  guidingTour: guidingTourInitialState,
  //useUserExpertiseList: userExpertiseOptionsState,
};

export const rootReducer: Reducer<RootState, AnyAction> = (
  state: RootState | undefined,
  action: AnyAction,
): RootState => {
  const authAction = action as AuthAction;
  const reducedState = combinedReducer(state, action);
  // on logout, wipe out all state that could cache user-specific data
  if (
    authAction.type === AuthActions.AUTH_LOGOUT &&
    authAction.phase === AsyncActionPhase.SUCCESS &&
    authAction.params[0]
  ) {
    return {
      ...rootInitialState,
      // keys to preserve even on logout
      ...(({app, appData, authExpiration, modelFile}) => ({
        app,
        appData,
        authExpiration,
        modelFile: {...modelFileInitialState, config: modelFile.config},
      }))(reducedState),
    };
  }
  return reducedState;
};

const composeEnhancers = composeWithDevTools || compose; // for Chrome Redux extension

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
);
export const reduxGetStateImperatively = store.getState;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
