import React from 'react';
import {useModelDownloadSideBarModal} from './useModelDownloadSideBarModal';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {tid} from '../../../../../testUtils';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {ModelDetailActionProps} from '../../../actionMenuTypes';
import {canDownloadModelFiles} from '../../../../../permissions/usePermissions';
import {Download16} from '@carbon/icons-react';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {IconButton} from '@material-ui/core';

const ModelDownloadActionMenuButton: React.FC<Partial<ModelDetailActionProps>> = (props) => {
  const {ModalComponent, openModal} = useModelDownloadSideBarModal(props);

  const renderDownloadButton = () => {
    return props.fromDynamicSearch === true ? (
      <IconButton
        aria-label="Download Model"
        size="small"
        className="icon32"
        onClick={openModal}
        {...tid('model-card-download')}>
        <Download16 />
      </IconButton>
    ) : (
      <SubmitButton
        onClick={openModal}
        type="button"
        endIcon={<Download16 />}
        {...tid(MODEL_ACTION_MENU_IDS.DOWNLOAD_OVERVIEW)}
        permission={canDownloadModelFiles(props.model)}>
        Download Model Entry
      </SubmitButton>
    );
  };
  return (
    <>
      {props.fromSideBar === true ? (
        <SideBarButtonItem
          {...tid(MODEL_ACTION_MENU_IDS.DOWNLOAD)}
          permission={canDownloadModelFiles(props.model)}
          onClick={openModal}
          icon={<Download16 />}
          text="Download Model Entry"
        />
      ) : (
        renderDownloadButton()
      )}
      {ModalComponent}
    </>
  );
};

export default ModelDownloadActionMenuButton;
