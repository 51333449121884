import {Grid} from '@material-ui/core';
import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {InformationClassificationIcon} from '../../../../icons/classification/InformationClassificationIcon';
import styles from './styles';
import ModelTextOverviewTile from './ModelTextOverviewTile';

import {
  EuropeAfrica,
  GlobeLocations,
  Microorganisms,
  CloudServices,
  FinancialGain,
  Transform_01 as Transform01,
  Target,
  Puzzle,
  Magnify,
  NetworkServices,
  StrategyMove,
  ChartBubbleLine,
  Assets,
  Research,
  ResetHybridCloud,
  Networking_03 as Networking03,
  MedicalCharts,
  Coronavirus,
  Tags,
  DataBackup,
  Prescription,
} from '@carbon/pictograms-react';
import {getModelGlobalAsTextValue, isModelExternal, isModelWeb} from '../../../../../store/model';
import {ModelTextOverviewUsers} from './ModelTextOverviewUsers';
import {getModelLanguageAndExecutionEnvironment} from './utils';

type ModelTextOverviewProps = {
  model: ModelDTO;
};

const ModelTextOverview: React.FC<ModelTextOverviewProps> = ({model}) => {
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);

  return (
    <>
      <Grid container spacing={0} direction="column" className={styles.wrapper}>
        <ModelTextOverviewTile
          label="Model Name"
          value={model.name}
          Icon={<ChartBubbleLine />}
          className={styles.tileModelName}
        />

        <ModelTextOverviewTile
          label="General Information"
          Icon={<Puzzle />}
          className={styles.tileHeadline}
          hideValue
        />

        <Grid container direction="row" justify="space-between">
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            {externalModel && (
              <ModelTextOverviewTile
                label="Trial Number"
                value={model.metadata.study_number}
                Icon={<MedicalCharts />}
              />
            )}
            <ModelTextOverviewTile
              label="Therapeutic Area"
              value={model.metadata.therapeutic_area}
              Icon={<Research />}
            />
            <ModelTextOverviewTile label="Name of Disease" value={model.metadata.disease} Icon={<Magnify />} />
            {externalModel && (
              <ModelTextOverviewTile
                label="THEOREM Indication"
                value={model.metadata.indication}
                Icon={<Coronavirus />}
              />
            )}
            <ModelTextOverviewTile label="Drug/Product Name" value={model.metadata.product} Icon={<Microorganisms />} />
            {externalModel && (
              <ModelTextOverviewTile label="MK Number" value={model.metadata.mk_number} Icon={<Prescription />} />
            )}
            <ModelTextOverviewTile
              label="Global/Adaptation"
              value={getModelGlobalAsTextValue(model)}
              Icon={<GlobeLocations />}
            />
            <ModelTextOverviewTile label="Country" value={model.metadata.country} Icon={<EuropeAfrica />} />
          </Grid>
          <ModelTextOverviewUsers modelId={model.uuid} />
          <Grid item container lg={4} md={12} direction="column" justify="flex-start">
            {externalModel && (
              <ModelTextOverviewTile label="Model Label" value={model.metadata.label} Icon={<Tags />} />
            )}
            <ModelTextOverviewTile label="Model Type" value={model.metadata.model_type} Icon={<FinancialGain />} />
            {externalModel && (
              <ModelTextOverviewTile
                externalModel={externalModel}
                label="Platform"
                platform={
                  model.metadata.platform === 'Pneumococcal Vaccines' ? 'PneuInsights' : model.metadata.platform
                }
                value={model.metadata?.ext_url}
                Icon={<DataBackup />}
              />
            )}
            {webModel && (
              <ModelTextOverviewTile
                label="Platform"
                platform={model.metadata.platform}
                value={model.metadata?.ext_url}
                Icon={<DataBackup />}
              />
            )}

            <ModelTextOverviewTile
              label="Model Language"
              value={getModelLanguageAndExecutionEnvironment(model.metadata)}
              Icon={<NetworkServices />}
            />
            <ModelTextOverviewTile label="Status" value={model.metadata.status} Icon={<CloudServices />} />
            <ModelTextOverviewTile
              label="Information Classification"
              value={model.metadata.information_classification}
              Icon={
                <InformationClassificationIcon classification={model.metadata.information_classification} markOnly />
              }
            />
          </Grid>
        </Grid>

        <ModelTextOverviewTile label="Version" Icon={<Networking03 />} className={styles.tileHeadline} hideValue />
        <Grid container direction="row" justify="space-between">
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            <ModelTextOverviewTile
              label="Version Release of Model"
              value={model.metadata.version_release_of_model}
              Icon={<ResetHybridCloud />}
            />
            <ModelTextOverviewTile
              label="Stage of Model Development"
              value={model.metadata.stage_of_model_development}
              Icon={<Transform01 />}
            />
          </Grid>
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            <ModelTextOverviewTile label="Version Log" value={model.metadata.version_log} Icon={<Networking03 />} />
          </Grid>
          <Grid item container lg={4} md={12} direction="column" justify="flex-start"></Grid>
        </Grid>

        <ModelTextOverviewTile label="Documentation" Icon={<Assets />} className={styles.tileHeadline} hideValue />
        <Grid container direction="row" justify="space-between">
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            <ModelTextOverviewTile
              label="Model Development Plan"
              value={model.metadata.model_development_plan}
              Icon={<StrategyMove />}
            />
          </Grid>
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start">
            <ModelTextOverviewTile
              label="Objective of Model"
              value={model.metadata.objective_of_model}
              Icon={<Target />}
            />
          </Grid>
          <Grid item container lg={4} md={12} direction="column" justify="flex-start"></Grid>
        </Grid>

        {/*<ModelTextOverviewTile*/}
        {/*  label="Additional Metadata"*/}
        {/*  Icon={<Digital />}*/}
        {/*  className={styles.tileHeadline}*/}
        {/*  hideValue*/}
        {/*/>*/}
        <Grid container spacing={10} direction="row" justify="space-between" style={{paddingBottom: '8rem'}}>
          <Grid item container lg={4} md={6} sm={12} direction="column" justify="flex-start"></Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ModelTextOverview;
