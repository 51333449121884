import {useReduxLoader, useReduxManualLoader, useReduxManualLoaderWithParams} from '../useReduxLoader';
import {
  excelDiffContentListSelectorBuilder,
  excelDiffMacroSelectorBuilder,
  excelDiffSheetSelectorBuilder,
} from './selectors';
import {excelDiffLoadContentList, excelDiffLoadMacroDiff, excelDiffLoadSheetDiff} from './actions';
import {GetMacroDiffRequest, GetSheetDiffRequest} from 'hemwb-api';
import {Dispatch} from 'redux';

export const useExcelDiffItemContentList = (itemUuid: string) => {
  return useReduxLoader(excelDiffContentListSelectorBuilder, excelDiffLoadContentList, [itemUuid]);
};
export const useExcelDiffItemContentListLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(excelDiffContentListSelectorBuilder, excelDiffLoadContentList);
};

const loadSheetDiffIgnoreInvalid = (dispatch: Dispatch, p: GetSheetDiffRequest) => {
  return p.itemId1 && p.itemId2 && p.itemUUID1 && p.itemUUID2 ? excelDiffLoadSheetDiff(dispatch, p) : Promise.resolve();
};
loadSheetDiffIgnoreInvalid.clear = excelDiffLoadSheetDiff.clear;
export const useExcelDiffSheet = (p: GetSheetDiffRequest) => {
  return useReduxLoader(excelDiffSheetSelectorBuilder, loadSheetDiffIgnoreInvalid, [p]);
};
export const useExcelDiffSheetLoader = (p: GetSheetDiffRequest) => {
  return useReduxManualLoader(excelDiffSheetSelectorBuilder, loadSheetDiffIgnoreInvalid, [p]);
};
export const useExcelDiffSheetLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(excelDiffSheetSelectorBuilder, loadSheetDiffIgnoreInvalid);
};

const loadMacroDiffIgnoreInvalid = (dispatch: Dispatch, p: GetMacroDiffRequest) => {
  return p.itemId1 && p.itemId2 && p.itemUUID1 && p.itemUUID2 ? excelDiffLoadMacroDiff(dispatch, p) : Promise.resolve();
};
loadMacroDiffIgnoreInvalid.clear = excelDiffLoadMacroDiff.clear;
export const useExcelDiffMacro = (p: GetMacroDiffRequest) => {
  return useReduxLoader(excelDiffMacroSelectorBuilder, loadMacroDiffIgnoreInvalid, [p]);
};
export const useExcelDiffMacroLoader = (p: GetMacroDiffRequest) => {
  return useReduxManualLoader(excelDiffMacroSelectorBuilder, loadMacroDiffIgnoreInvalid, [p]);
};
export const useExcelDiffMacroLoaderWithParams = () => {
  return useReduxManualLoaderWithParams(excelDiffMacroSelectorBuilder, loadMacroDiffIgnoreInvalid);
};
