import {BoxLayout, boxLayoutStyles as styles} from '../../layout/BoxLayout';
import React, {useCallback, useState} from 'react';
import {Link} from '@material-ui/core';
import {Collaborate16} from '@carbon/icons-react';
import SnackbarContainer from '../../layout/common/SnackbarContainer';
import {useSettings} from '../../../store/appData';
import {getUrlRoute, Routes} from '../../router/routes';
import {authLogout, authRequestAccess} from '../../../store/auth';
import SubmitButton from '../../core/Buttons/SubmitButton';
import {useDispatch} from 'react-redux';
import {AsyncActionButton} from '../../core/AsyncActionButton';
import {trackUserAccessRequested} from '../../../tracking/tracking';

export const AccessDeniedPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const settings = useSettings();
  const [reLogging, setReLogging] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [requested, setRequested] = useState(false);

  const handleReLogin = useCallback(() => {
    setReLogging(true);
    authLogout(dispatch, undefined).finally(() => {
      window.location.assign(`/#${getUrlRoute(Routes.ROOT)}`);
    });
  }, [dispatch]);

  const requestAccess = useCallback(() => {
    setSubmitting(true);
    authRequestAccess(dispatch)
      .then(() => {
        trackUserAccessRequested();
        setRequested(true);
      })
      .catch((error: Response) => {
        if (error.status === 401) {
          window.location.assign(`/#${getUrlRoute(Routes.ROOT)}`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [dispatch]);

  return (
    <BoxLayout>
      {requested ? (
        <>
          <div className={styles.heading}>
            <h2 className={styles.title}>Request sent successfully</h2>
            <div className={styles.subtitle}>
              Your request to access <strong>Model Repository</strong> has been sent successfully.
              <br />
              <br />
              You will be contacted as soon as your access to Model Repository will be granted.
            </div>
          </div>
          <div className={styles.links}>
            {settings && (
              <Link href={settings.privacyPolicyUrl} target="_blank">
                Privacy policy
              </Link>
            )}
            <AsyncActionButton
              className={styles.linkButton}
              active={reLogging}
              disabled={submitting || reLogging}
              onClick={handleReLogin}>
              Re-attempt login
            </AsyncActionButton>
          </div>
        </>
      ) : (
        <>
          <div className={styles.heading}>
            <h2 className={styles.title}>
              You don&apos;t have access to <strong>Model Repository</strong>
            </h2>
            <div className={styles.subtitle}>
              You haven&apos;t been granted access rights or your account is inactive.
              <br />
              <br />
              You may request the access or troubleshoot the problem by clicking Request access button.
            </div>
          </div>

          <div className={styles.links}>
            {settings && (
              <Link href={settings.privacyPolicyUrl} target="_blank">
                Privacy policy
              </Link>
            )}
            <AsyncActionButton
              className={styles.linkButton}
              active={reLogging}
              disabled={submitting || reLogging}
              onClick={handleReLogin}>
              Re-attempt login
            </AsyncActionButton>
          </div>

          <div className={styles.links}>
            <SubmitButton
              active={submitting}
              disabled={submitting || reLogging}
              onClick={requestAccess}
              endIcon={<Collaborate16 />}>
              Request access
            </SubmitButton>
            {/*<Button*/}
            {/*  onClick={requestHEMWBAccess}*/}
            {/*  variant="contained"*/}
            {/*  color="primary"*/}
            {/*  endIcon={<Collaborate16 style={{marginLeft: '3rem'}} />}*/}
            {/*  classes={{root: styles.loginButton}}></Button>*/}
          </div>
        </>
      )}

      <SnackbarContainer />
    </BoxLayout>
  );
};
