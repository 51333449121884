import {buildAsyncActionsCreator, createAsyncActionExecutor} from '../asyncUtils';
import {ExecutionSetupActions} from './types';
import {
  requestExecutionSetup,
  requestExecutionSetupCreate,
  requestExecutionSetupDelete,
  requestExecutionSetupList,
  requestExecutionSetupUpdate,
} from './api';

const executionSetupLoadListActionsCreator = buildAsyncActionsCreator(ExecutionSetupActions.REQUEST_LIST);
export const executionSetupLoadList = createAsyncActionExecutor(
  executionSetupLoadListActionsCreator,
  requestExecutionSetupList,
);

const executionSetupLoadActionsCreator = buildAsyncActionsCreator(ExecutionSetupActions.REQUEST_SINGLE);
export const executionSetupLoad = createAsyncActionExecutor(executionSetupLoadActionsCreator, requestExecutionSetup);

const executionSetupCreateActionsCreator = buildAsyncActionsCreator(ExecutionSetupActions.REQUEST_CREATE);
export const executionSetupCreate = createAsyncActionExecutor(
  executionSetupCreateActionsCreator,
  requestExecutionSetupCreate,
);

const executionSetupUpdateActionsCreator = buildAsyncActionsCreator(ExecutionSetupActions.REQUEST_UPDATE);
export const executionSetupUpdate = createAsyncActionExecutor(
  executionSetupUpdateActionsCreator,
  requestExecutionSetupUpdate,
);

const executionSetupDeleteActionsCreator = buildAsyncActionsCreator(ExecutionSetupActions.REQUEST_DELETE);
export const executionSetupDelete = createAsyncActionExecutor(
  executionSetupDeleteActionsCreator,
  requestExecutionSetupDelete,
);
