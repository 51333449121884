import React from 'react';
import {InputGenerator} from '../types';
import {FormArray} from 'react-reactive-form';
import {ArrayInputAttributes, InputType} from '../../InputDefinition/types';
import InputHeader from './InputHeader';
import {arrayColumnControlBuilder, ArraySingleInputPrimitive} from '../primitives/ArrayColumnPrimitive';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

type ArrayInputProps = {
  control: FormArray;
  definition: ArrayInputAttributes;
  disabled?: boolean;
  renderStatic?: boolean;
};

const ArrayInput: React.FC<ArrayInputProps> = ({control, definition, disabled, renderStatic}) => {
  if (!definition.visible) {
    return null;
  }

  const columnFormArrays = control;
  const {rowDescription, rows, columns} = definition;
  return (
    <>
      <InputHeader definition={definition} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell variant="head">{rowDescription}</TableCell>
            {columns.map((column) => (
              <TableCell key={column.text}>{column.text}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <Row
              key="Array_Input"
              columns={columns}
              columnFormArrays={columnFormArrays}
              row={row}
              rowIndex={rowIndex}
              disabled={disabled}
              renderStatic={renderStatic}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const _Row = (props: any) => {
  const {columns, columnFormArrays, rowIndex, row, disabled, renderStatic} = props;
  return (
    <TableRow key={rowIndex}>
      <TableCell variant="head">{row}</TableCell>
      {columns.map((column: any, columnIndex: number) => (
        <TableCell key="row_column">
          <ArraySingleInputPrimitive
            columnFormArray={columnFormArrays.controls[columnIndex] as FormArray}
            index={rowIndex}
            type={column.dataType}
            disabled={disabled}
            renderStatic={renderStatic}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

const Row = React.memo(_Row);

export const arrayInputGenerator: InputGenerator<InputType.ARRAY> = {
  type: InputType.ARRAY,
  buildControl: (definition, initialValues = []) => {
    return new FormArray(
      definition.columns.map((column, index) => arrayColumnControlBuilder(column, initialValues?.[index])),
    );
  },
  render: React.memo(ArrayInput),
  renderStatic: React.memo((p: any) => <ArrayInput {...p} renderStatic />),
};
