import {AsyncAction} from '../asyncUtilsTypes';
import {LoggedInUserDTO, LoginCredentialsDTO, UserDTO, RegistrationDetailsDTO} from 'hemwb-api';
import {Action} from 'redux';

export enum AuthActions {
  AUTH_LOGIN = 'AUTH/LOGIN',
  AUTH_REGISTRATION = 'AUTH/REGISTRATION',
  AUTH_LOGOUT = 'AUTH/LOGOUT',
  AUTH_SET_LOGOUT = 'AUTH/SET_LOGOUT',
  AUTH_GET_CURRENT_USER = 'AUTH/GET_CURRENT_USER',
  AUTH_REQUEST_ACCESS = 'AUTH/REQUEST_ACCESS',
  AUTH_REGISTER = 'AUTH/REGISTER',
}

export type AuthAction =
  | AsyncAction<AuthActions.AUTH_LOGIN, void, [LoginCredentialsDTO]>
  | AsyncAction<AuthActions.AUTH_REGISTRATION, void, [RegistrationDetailsDTO]>
  | AsyncAction<AuthActions.AUTH_LOGOUT, void, [boolean | undefined]>
  | AsyncAction<AuthActions.AUTH_GET_CURRENT_USER, UserDTO, []>
  | Action<AuthActions.AUTH_SET_LOGOUT>
  | AsyncAction<AuthActions.AUTH_REQUEST_ACCESS, void, []>
  | AsyncAction<AuthActions.AUTH_REGISTER, void, [LoggedInUserDTO]>;
