import React, {ReactNode, useState} from 'react';
import {styles} from './styles';
import {ModelTreeNode} from '../../../../../../store/modelFile';
import BitbucketManager from './BitbucketManager';
import FileManager from './FileManager';
import {getModelEditFilesMode, ModelEditFilesMode} from './utils';
import clsx from 'clsx';
import {ModelDTO} from 'hemwb-api';
import {isModelTheorem, isModelExternal} from '../../../../../../store/model';

export type ModelEditFilesProps = {
  model: ModelDTO;
  files?: ModelTreeNode[];
  onChange?: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  onSubmit: (updatedModel: ModelDTO, updatedFiles: ModelTreeNode[]) => void;
  buttons: () => string | ReactNode;
  createFlow?: boolean;
  uploadFlow?: boolean;
  unSelectAll?: boolean;
};

const ModelEditFiles: React.FC<ModelEditFilesProps> = ({model, files, unSelectAll, ...restProps}) => {
  const [workingModel, setWorkingModel] = useState<ModelDTO>({...model, metadata: {...model.metadata}});
  const [forcedMode, setForcedMode] = useState<ModelEditFilesMode>();
  const mode = forcedMode || getModelEditFilesMode(workingModel, files);
  const externalModel = isModelExternal(model);
  const theoremModel = isModelTheorem(model);
  return (
    <>
      <div
        className={clsx(
          theoremModel ? styles.containerTH : styles.container,
          {whiteTheme: mode !== ModelEditFilesMode.BITBUCKET, darkBlueTheme: mode === ModelEditFilesMode.BITBUCKET},
          styles[mode],
        )}>
        {mode !== ModelEditFilesMode.MLMP && !externalModel && (
          <BitbucketManager
            model={workingModel}
            updateModel={setWorkingModel}
            mode={mode}
            onEdit={() => !forcedMode && setForcedMode(ModelEditFilesMode.BITBUCKET)}
          />
        )}
        <FileManager
          model={workingModel}
          files={files}
          onEdit={() => mode === ModelEditFilesMode.NONE && setForcedMode(ModelEditFilesMode.MLMP)}
          mode={mode}
          {...restProps}
        />
      </div>
    </>
  );
};

export default ModelEditFiles;
