import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ModelTreeNode, TreeNode, useModelFileUploader} from '../../../../../store/modelFile';
import FileTree from '../../../../core/FileTree/FileTree';
import {Checkbox} from '@material-ui/core';
import {PropertySelection} from './types';
import WarningText from '../../../../core/WarningText/WarningText';
import {styles} from './styles';
import {ModelDTO} from 'hemwb-api';
import clsx from 'clsx';
import {Add16, DocumentAdd32, FolderAdd16, Task32} from '@carbon/icons-react';
import {tid} from '../../../../../testUtils';
import {isModelExternal, isModelWeb} from '../../../../../store/model';
import {calculateFilePath, onDocumentDragOver} from '../ModelEdit/ModelEditFiles/utils';
import {calculateFileName} from '../../../../../store/modelFile/utils';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';

type StepFilesProps = {
  model: ModelDTO;
  files: ModelTreeNode[];
  filesSelection: PropertySelection;
  toggleFile: (uuid: string) => void;
  loadNewSelection: (uuid: string[]) => void;
  cloneFlow?: boolean;
};

const allowedExtensions = ['.xls,.xlsx'];
const StepFiles: React.FC<StepFilesProps> = ({files, filesSelection, toggleFile, model, loadNewSelection}) => {
  const {add, uploader} = useModelFileUploader(model, files);
  const {nodes} = uploader;
  const [initialized, setInitialized] = useState(false);
  const [newFiles] = useState<string[]>([]);
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {}, [nodes, initialized]);

  useEffect(() => {
    document.addEventListener('dragover', onDocumentDragOver, false);
    document.addEventListener('drop', onDocumentDragOver, false);
    return () => {
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDragOver, false);
    };
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const isDirUploadAvailable = useMemo(() => {
    const input = document.createElement('input');
    // @ts-ignore
    return typeof input.webkitdirectory === 'boolean';
  }, []);

  const dispatchAddFile = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (file: File, node: TreeNode | string, fileUpload: boolean) => {
      let path;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let fileTag = '';
      if (typeof node === 'string') {
        path = node;
      } else {
        const nodePathName = `${node.path}/`;
        path = `${node.path === '' ? '' : nodePathName}${calculateFileName(file.name)}`;
        if (node.fileTag) fileTag = node?.fileTag;
      }

      add(file, path);
      // @ts-ignore
      // try {
      //   await modelFileUpload(dispatch, {
      //     modelUuid: model.uuid,
      //     type: ModelItemType.EXECUTION,
      //     file: file,
      //     path: path,
      //     fileTag: fileTag,
      //   }).then((response) => {
      //     dispatch(messageAdd('Files have been updated', MessageTypes.SUCCESS));
      //     if (fileUpload) setNewFiles((prevResponses) => [...prevResponses, response]);
      //   });
      // } catch (error) {
      //   if (error.status === 409) {
      //     dispatch(
      //       messageAdd(
      //         'File already exists; either choose a different file or rename the new file.',
      //         MessageTypes.ERROR,
      //       ),
      //     );
      //   } else {
      //     dispatch(messageAdd('Failed to update Files.', MessageTypes.ERROR));
      //   }
      // }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (folderInputRef.current) {
        folderInputRef.current.value = '';
      }
    },
    [add],
  );

  useEffect(() => {
    if (newFiles !== null) {
      loadNewSelection(newFiles);
    }
  }, [newFiles, loadNewSelection]);

  const handleAddFiles = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (event.currentTarget.files) {
        Array.from(event.currentTarget.files).forEach((file: File) => {
          // @ts-ignore
          const path = calculateFilePath(calculateFileName(file.name), file.webkitRelativePath);
          dispatchAddFile(file, path, event.currentTarget.getAttribute('data-testid') === 'input-file').then(() => {});
        });
      }
    },
    [dispatchAddFile],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent, node: TreeNode) => {
      if (!node || !node.folder || externalModel) {
        return;
      }

      function traverseFileTree(item: any, p?: string) {
        const path = p || '';
        if (item.isFile) {
          // Get file
          item.file(function (file: File) {
            const newPath = `${node.path}${node.path ? '/' : ''}${path}${file.name}`;
            dispatchAddFile(file, newPath, false);
          });
        } else if (item.isDirectory) {
          // Get folder contents
          const dirReader = item.createReader();
          dirReader.readEntries(function (entries: any[]) {
            for (const entry of entries) {
              traverseFileTree(entry, path + item.name + '/');
            }
          });
        }
      }

      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (const item of event.dataTransfer.items) {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry) {
              traverseFileTree(entry);
            } else {
              const file = item.getAsFile();
              if (file) {
                dispatchAddFile(file, node, false);
              }
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s) - Fallback for older browsers like IE11
        for (const file of event.dataTransfer.files) {
          dispatchAddFile(file, node, false);
        }
      }
    },
    [dispatchAddFile, externalModel],
  );
  const renderButtons = useCallback(
    (node: TreeNode) => {
      if (node.folder) {
        return null;
      }
      return [
        <Checkbox
          key={node.uuid}
          defaultChecked
          color="primary"
          checked={filesSelection[node.uuid]}
          onClick={() => toggleFile(node.uuid)}
        />,
      ];
    },
    [filesSelection, toggleFile],
  );

  return (
    <>
      <div className={clsx(styles.container)}>
        <WarningText text="Upload the new model file and select the supporting documents you want to copy to the new entry." />
        <br />
        <ActionHeader label="" Icon={<DocumentAdd32 />}>
          Upload new Model file
        </ActionHeader>
        <div className={clsx(styles.panel)}>
          <label
            id="upload-button"
            className={clsx(
              'MuiButtonBase-root MuiButton-root MuiButton-contained submitButton MuiButton-containedPrimary MuiButton-sizeLarge',
              // eslint-disable-next-line
              {['Mui-disabled']: externalModel},
            )}>
            Select files
            <Add16 />
            <input
              type="file"
              multiple
              onChange={handleAddFiles}
              ref={fileInputRef}
              {...tid('input-file')}
              accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
            />
          </label>
          {isDirUploadAvailable && (
            <label
              id="upload-button-folder"
              className={clsx(
                'MuiButtonBase-root  MuiButton-root MuiButton-contained submitButton MuiButton-containedPrimary MuiButton-sizeLarge',
                // eslint-disable-next-line
                {['Mui-disabled']: externalModel},
              )}>
              Select folder
              <FolderAdd16 />
              <input
                type="file"
                webkitrelativepath="webkitrelativepath"
                webkitdirectory="webkitdirectory"
                onChange={handleAddFiles}
                ref={folderInputRef}
              />
            </label>
          )}

          <div className={styles.dropInfo}>
            You can either select file or folder,
            <br />
            or drag and drop.
          </div>

          <div
            className={clsx(styles.dropZone, {[styles.dropDisabled]: externalModel})}
            onDrop={
              !externalModel
                ? (event: React.DragEvent) =>
                    handleDrop(event, {uuid: '', path: '', name: '', folder: true, uploaded: true})
                : undefined
            }>
            Drag and drop files
            <br /> here or upload
          </div>
        </div>
        <ActionHeader label="" Icon={<Task32 />}>
          Select files for new entry
        </ActionHeader>
        <div className={clsx(styles.fileTreePanel)}>
          <FileTree<ModelTreeNode>
            renderButtons={renderButtons}
            files={nodes}
            webModel={webModel}
            externalModel={externalModel}
            cloneFlow={true}
          />
        </div>
      </div>
    </>
  );
};

export default StepFiles;
