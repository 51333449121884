import React, {useCallback, useMemo} from 'react';
import {useSideBarModal} from '../../../../layout/common/useSideBarModal';
import SideBarModal from '../../../../layout/common/SideBarModal';
import ModelDeleteDraftSideBarModal from './ModelDeleteDraftSideBarModal';
import {tid} from '../../../../../testUtils';
import SideBarButtonItem from '../../../../layout/common/SideBarButtonItem';
import {MODEL_ACTION_MENU_IDS} from '../../../../../test/types';
import {canDeleteDraftModel} from '../../../../../permissions/usePermissions';
import {ModelActionProps} from '../../../actionMenuTypes';
import {TrashCan16} from '@carbon/icons-react';

const ModelDeleteDraftSideBarModalMenuButton: React.FC<Partial<ModelActionProps>> = ({model, onSuccess}) => {
  const {buttonRef, openModal, closeModal} = useSideBarModal(model?.uuid);

  const handleSuccess = useCallback(() => {
    closeModal();
    onSuccess?.();
  }, [closeModal, onSuccess]);

  const Modal = useMemo(() => {
    return (
      model && (
        <SideBarModal
          className="whiteTheme"
          isOpen={!!buttonRef}
          buttonRef={buttonRef}
          closeModal={closeModal}
          height={300}>
          <div style={{width: '40rem', paddingLeft: '3rem'}}>
            {!!buttonRef && (
              <ModelDeleteDraftSideBarModal model={model} onSuccess={handleSuccess} onCancel={closeModal} />
            )}
          </div>
        </SideBarModal>
      )
    );
  }, [buttonRef, closeModal, handleSuccess, model]);

  return (
    <>
      <SideBarButtonItem
        red
        {...tid(MODEL_ACTION_MENU_IDS.DELETE_DRAFT)}
        permission={canDeleteDraftModel(model)}
        onClick={openModal}
        icon={<TrashCan16 />}
        text="Delete Model Entry"
      />
      {Modal}
    </>
  );
};

export default ModelDeleteDraftSideBarModalMenuButton;
