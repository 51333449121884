import React, {useState, useCallback, useEffect} from 'react';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {InputGenerator, InputType, FileInputAttributes} from '../types';
import SharedInputParameters, {sharedInputParametersControlBuilder} from './SharedInputParameters';
import GridItem from '../../../Form/GridItem';
import {Button, Chip, FormControl as FormControlUI, Grid, Tooltip, InputLabel} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {requestFileTree, ModelTreeNode} from '../../../../../store/modelFile';
import {Upload16} from '@carbon/icons-react';
import {TextFieldControl, validateInteger} from '../../../Form';
import {FileAsInputDialog} from '../../../../core/dialog/FileAsInputDialog';
import {ModelDTO} from 'hemwb-api';
import {useDispatch} from 'react-redux';
import {messageAdd, MessageTypes} from '../../../../../store/message';

type ControlsMap = {[key in keyof Required<FileInputAttributes>]: AbstractControl};

type FileInputProps = {
  formGroup: FormGroup & {controls: ControlsMap};
  model: ModelDTO;
};

export const FileInput: React.FC<FileInputProps> = ({formGroup, model}) => {
  const [files, setFiles] = useState<ModelTreeNode[]>();
  const [isFileRemoved, setIsFileRemoved] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileUuid, setFileUuid] = useState<string>('');
  const sizeControl = formGroup.get('size');
  const fileControl = formGroup.get('files');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!files) {
      requestFileTree(model.uuid, undefined, ['Help File'])
        .then((response) => {
          setFiles(response);
        })
        .catch(() => {
          dispatch(messageAdd('Failed to load the help files', MessageTypes.ERROR));
        });
    }
  }, [dispatch, files, model.uuid]);

  const handleOpenMainFileDialog = () => {
    setOpenDialog(true);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleSubmit = useCallback(
    (uuid: string | null) => {
      fileControl.setValue(uuid);
      setFileUuid(uuid || '');
      setOpenDialog(false);
    },
    [fileControl],
  );

  const handleRemoveFileChange = (value: boolean) => {
    setIsFileRemoved(value);
  };

  const handleRemoveFile = useCallback(() => {
    fileControl.setValue('');
    setFileUuid('');
    setIsFileRemoved(true);
  }, [fileControl]);

  return (
    <>
      <SharedInputParameters formGroup={formGroup} />
      <Grid container direction="row" alignItems="flex-start" justify="space-between">
        <GridItem>
          <FormControlUI fullWidth hiddenLabel={true} className="MuiFormControl-hem">
            <InputLabel shrink required>
              Help File
            </InputLabel>
          </FormControlUI>
          <Button variant="text" color="primary" startIcon={<Upload16 />} onClick={handleOpenMainFileDialog}>
            Select Help File
          </Button>
          <br />
          {fileControl &&
            files &&
            files.map((file) => {
              return file.uuid === fileUuid ? (
                <Chip
                  key={file.uuid}
                  label={file.name}
                  style={{marginTop: 10}}
                  variant="outlined"
                  onDelete={() => handleRemoveFile()}
                />
              ) : (
                file.uuid === formGroup.value.files && (
                  <Chip
                    key={file.uuid}
                    label={file.name}
                    style={{marginTop: 10}}
                    variant="outlined"
                    onDelete={() => handleRemoveFile()}
                  />
                )
              );
            })}
        </GridItem>
        <GridItem>
          <div style={{marginBottom: '-0.7rem'}}>
            <span style={{fontSize: '12px'}}>Maximum File Size (MB)</span>
            <span style={{color: '#FF5656', fontSize: '18px'}}>*</span>
            <span>
              <Tooltip title="The maximum file size limit should not be set above 1 MB" placement="top" arrow>
                <InfoOutlinedIcon style={{marginLeft: '10px', fontSize: '20px', verticalAlign: 'middle'}} />
              </Tooltip>
            </span>
          </div>
          <TextFieldControl
            control={sizeControl}
            label="Maximum File Size(MB)"
            hideAsterisk={true}
            disabled
            name="size"
          />
        </GridItem>
      </Grid>
      <FileAsInputDialog
        open={openDialog}
        handleCancel={handleCancel}
        onSubmit={handleSubmit}
        files={files}
        formGroup={formGroup}
        isFileRemoved={isFileRemoved}
        onRemoveFileChange={handleRemoveFileChange}
      />
    </>
  );
};

export const fileInputGenerator: InputGenerator<InputType.FILE> = {
  type: InputType.FILE,
  buildControl: (initialValues = {}) =>
    new FormGroup({
      ...sharedInputParametersControlBuilder(initialValues),
      size: new FormControl(initialValues.size ?? 1, [Validators.required, validateInteger]),
      files: new FormControl(initialValues.files ?? null, [Validators.required]),
    } as ControlsMap),
  render: FileInput,
};
