import {ScenarioGroupAction, ScenarioGroupActions, ScenarioGroupState} from './types';
import {reduceAsyncAction} from '../asyncUtils';
import {AsyncActionPhase} from '../asyncUtilsTypes';
import {ScenarioGroupDetailDTO} from 'hemwb-api';

export const scenarioGroupInitialState: ScenarioGroupState = {
  list: {},
};

const updateList = (
  state: ScenarioGroupState,
  modelUuid: string,
  list: ScenarioGroupDetailDTO[],
): ScenarioGroupState => {
  if (state.list[modelUuid].state === AsyncActionPhase.SUCCESS) {
    const value = {list, total: list.length};
    return {
      ...state,
      list: {
        ...state.list,
        [modelUuid]: {
          ...state.list[modelUuid],
          value,
        },
      },
    };
  }
  return state;
};

const findKeyByGroupIdInList = (state: ScenarioGroupState, groupId: number): string | undefined => {
  return Object.keys(state.list).find((modelUuid) => state.list[modelUuid].value?.list?.some((g) => g.id === groupId));
};

export const scenarioGroupReducer = (
  state = scenarioGroupInitialState,
  action: ScenarioGroupAction,
): ScenarioGroupState => {
  switch (action.type) {
    case ScenarioGroupActions.LIST: {
      const [modelUuid] = action.params;
      return {...state, list: {...state.list, [modelUuid]: reduceAsyncAction(action)}};
    }

    case ScenarioGroupActions.CREATE: {
      if (action.phase === AsyncActionPhase.SUCCESS) {
        const modelUuid = action.params[0];
        return updateList(state, modelUuid, [...state.list[modelUuid].value!.list, action.value]);
      }
      return state;
    }

    case ScenarioGroupActions.UPDATE: {
      if (action.phase === AsyncActionPhase.START) {
        const [groupId, updateParams] = action.params;
        const modelUuid = findKeyByGroupIdInList(state, groupId);
        if (modelUuid) {
          return updateList(
            state,
            modelUuid,
            state.list[modelUuid].value!.list.map((g) => (g.id === groupId ? {...g, ...updateParams} : g)),
          );
        }
      }

      return state;
    }

    case ScenarioGroupActions.DELETE: {
      const [groupId] = action.params;

      if (action.phase === AsyncActionPhase.START) {
        const modelUuid = findKeyByGroupIdInList(state, groupId);
        if (modelUuid) {
          return updateList(
            state,
            modelUuid,
            state.list[modelUuid].value!.list.filter((g) => g.id !== groupId),
          );
        }
      }

      return state;
    }
  }
  return state;
};
