import {ExcelDiffAction, ExcelDiffActions} from './types';
import {AsyncActionPhase, AsyncActionState} from '../asyncUtilsTypes';
import {GetMacroDiffRequest, GetSheetDiffRequest, MacroDiffDTO, SheetDiffDTO, WorkbookItemDTO} from 'hemwb-api';
import {reduceAsyncAction} from '../asyncUtils';
import {buildKey, optimizeSheetDiff} from './utils';

export type ExcelDiffState = {
  contentLists: {[itemUuid: string]: AsyncActionState<WorkbookItemDTO[], [string]>};
  sheets: {
    [key: string]: AsyncActionState<SheetDiffDTO, [GetSheetDiffRequest]>;
  };
  macros: {
    [key: string]: AsyncActionState<MacroDiffDTO, [GetMacroDiffRequest]>;
  };
};

export const excelDiffInitialState: ExcelDiffState = {
  contentLists: {},
  sheets: {},
  macros: {},
};

export const excelDiffReducer = (state = excelDiffInitialState, action: ExcelDiffAction): ExcelDiffState => {
  switch (action.type) {
    case ExcelDiffActions.CLEAR: {
      return excelDiffInitialState;
    }

    case ExcelDiffActions.REQUEST_CONTENT_LIST: {
      const [itemUuid] = action.params;
      return {
        ...state,
        contentLists: {
          ...state.contentLists,
          [itemUuid]: reduceAsyncAction(action),
        },
      };
    }

    case ExcelDiffActions.REQUEST_SHEET_DIFF: {
      const key = buildKey(action.params[0]);

      if (action.phase === AsyncActionPhase.SUCCESS) {
        const value = optimizeSheetDiff(action.value);
        return {
          ...state,
          sheets: {
            ...state.sheets,
            [key]: {...reduceAsyncAction(action), value},
          },
        };
      }

      return {
        ...state,
        sheets: {
          ...state.sheets,
          [key]: reduceAsyncAction(action),
        },
      };
    }

    case ExcelDiffActions.REQUEST_MACRO_DIFF: {
      const key = buildKey(action.params[0]);
      return {
        ...state,
        macros: {
          ...state.macros,
          [key]: reduceAsyncAction(action),
        },
      };
    }
  }
  return state;
};
