import React, {useCallback, useMemo, useState} from 'react';
import styles from './ScenarioDetail.module.scss';
import {DialogGeneric} from '../../../core/dialog/DialogGeneric';
import {ScenarioActionProps} from '../../actionMenuTypes';
import SubModelSectionHeader from '../../subModel/common/SubModelSectionHeader/SubModelSectionHeader';
import clsx from 'clsx';
import buttonStyles from '../../model/common/ModelOverview/ModelOverview.module.scss';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../../router/routes';
import {Maximize16} from '@carbon/icons-react';
import ScenarioDetailContent from './ScenarioDetailContent';
import {typeToSection} from '../../../../store/executionSetup';
import DefaultPage from '../../../layout/common/DefaultPage';

export const useScenarioDetailDialog = () => {
  const [openProps, setOpenProps] = useState<ScenarioActionProps>();
  const handleClose = useCallback(() => {
    setOpenProps(undefined);
  }, []);

  const Component = useMemo(() => {
    return !openProps ? null : (
      <DialogGeneric
        open={true}
        title="Instance Detail"
        childrenAsText={false}
        includesDialogActions={true}
        fullScreen={true}
        onHeaderCloseButtonClick={handleClose}>
        <DefaultPage style={{overflow: 'hidden', minHeight: '100%'}}>
          <div className={styles.sectionHeader}>
            <SubModelSectionHeader
              model={openProps.model}
              section={typeToSection(openProps.subModel.type)}
              subModel={openProps.subModel}
              scenario={openProps.scenario}
              title="Instance Detail"
            />
            <div className={clsx(buttonStyles.expandCollapseButton, styles.expandCollapseButton)}>
              <Button
                variant="contained"
                component={Link}
                to={getUrlRoute(Routes.SCENARIO_DETAIL, {
                  modelUuid: openProps.model.uuid,
                  scenarioId: openProps.scenario.id,
                })}
                color="default"
                size="large"
                className={clsx('whiteButton noBoxShadow')}
                startIcon={<Maximize16 />}>
                Maximize
              </Button>
            </div>
          </div>
          <br />
          <ScenarioDetailContent model={openProps.model} subModel={openProps.subModel} scenario={openProps.scenario} />
        </DefaultPage>
      </DialogGeneric>
    );
  }, [openProps, handleClose]);

  return {
    open: setOpenProps,
    Component,
  };
};
