import React, {useCallback, useRef, useState, useEffect} from 'react';
import {ModelDTO} from 'hemwb-api/dist/models/ModelDTO';
import {modelFileItemDownload, useModelFileTH, useModelFileTreeLoader} from '../../../../../store/modelFile';
import {useMounted} from '../../../../../hooks/useMounted';
import {useDispatch} from 'react-redux';
import {Download16, Download32} from '@carbon/icons-react';
import styles from './ModelDownload.module.scss';
import {InformationClassificationIcon} from '../../../../icons/classification/InformationClassificationIcon';
import {Button, Checkbox, Typography, Tab, Tabs} from '@material-ui/core';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import {tid} from '../../../../../testUtils';
import {TextOrProgress} from '../../../../core/textOrProgress/TextOrProgress';
import {ApiResponse} from 'hemwb-api';
import {downloadFromResponse} from '../../../../../utils/download';
import {isModelExternal, isModelTheorem} from '../../../../../store/model';
import {trackModelItemDownloaded} from '../../../../../tracking/tracking';
import CancelButton from '../../../../core/Buttons/CancelButton';
import SubmitButton from '../../../../core/Buttons/SubmitButton';
import {defaultLegalNotice} from '../../../../core/defaultLegalNotice';
import ActionHeader from '../../../../core/Header/ActionHeader/ActionHeader';
import DownloadFileTreeTH from '../../../../core/FileTree/DownloadFileTreeTH';
import FileTree from '../../../../core/FileTree/FileTree';
import {useCurrentUser} from '../../../../../store/auth';
import style from '../../list/ModelList.module.scss';

type ModelDownloadTHProps = {
  model: ModelDTO;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const ModelDownloadTH: React.FC<ModelDownloadTHProps> = ({model, onSuccess, onCancel}) => {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<string>();
  const [confirmed, setConfirmed] = useState(false);
  const [validate, setValidate] = useState(false);
  const [tab, setTab] = useState('theorem');
  const {files} = useModelFileTH(model);
  const externalModel = isModelExternal(model);
  const theoremModel = isModelTheorem(model);
  const {id: currentUserId} = useCurrentUser();
  const mounted = useMounted();
  const cancelled = useRef(false);
  const {data: supportingDocs, load: loadFiles} = useModelFileTreeLoader(model.uuid);

  useEffect(() => {
    if (tab === 'theorem') {
      loadFiles();
    }
  }, [tab, loadFiles]);
  const downloadIfNotCancelled = useCallback(
    (response: ApiResponse<Blob>) => (cancelled.current ? Promise.reject() : downloadFromResponse(response.raw)),
    [],
  );
  const [downloadAll, setDownloadAll] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    cancelled.current = true;
    onCancel?.();
  }, [onCancel]);

  const handleDownloadAllButton = useCallback(() => {
    setConfirmed(!confirmed);
    let isAllowDownloadAll = true;
    if (files) {
      files.specification.forEach((file) => {
        if (!file.userAccess) {
          isAllowDownloadAll = false;
        }
        return downloadAll;
      });
    }
    setDownloadAll(isAllowDownloadAll);
  }, [files, confirmed, downloadAll]);

  const handleDownloadItem = useCallback(
    (itemUuid: string, itemName: string) => {
      setDownloading(itemUuid);
      cancelled.current = false;
      modelFileItemDownload(dispatch, model.uuid, itemUuid)
        .then(downloadIfNotCancelled)
        .then(() => {
          trackModelItemDownloaded(model, itemName, itemUuid);
        })
        .catch(() => null)
        .finally(() => {
          mounted.current && setDownloading(undefined);
        });
    },
    [dispatch, downloadIfNotCancelled, model, mounted],
  );

  const handleDownloadAllSupportingDocs = useCallback(() => {
    setDownloading('all');
    supportingDocs?.map((item) => {
      if (!item.folder) {
        return modelFileItemDownload(dispatch, model.uuid, item.uuid)
          .then(downloadIfNotCancelled)
          .then(() => {
            trackModelItemDownloaded(model, item.name, item.uuid);
            onSuccess?.();
          });
      }
    });
  }, [dispatch, downloadIfNotCancelled, model, onSuccess, supportingDocs]);

  const handleDownloadAllTheoremFiles = useCallback(() => {
    setDownloading('all');
    if (files && Array.isArray(files.specification)) {
      files.specification.forEach((item) => {
        modelFileItemDownload(dispatch, model.uuid, item.uuid)
          .then(downloadIfNotCancelled)
          .then(() => {
            trackModelItemDownloaded(model, item.fileName, item.uuid);
            onSuccess?.();
          });
      });
    }
  }, [dispatch, downloadIfNotCancelled, model, onSuccess, files]);

  return (
    <>
      <InformationClassificationIcon
        classification={model.metadata.information_classification}
        className={styles.classificationIcon}
      />
      <ActionHeader Icon={<Download32 />}>Download model</ActionHeader>

      <div style={{justifyContent: 'center', display: 'flex', margin: '5rem 0 3rem 0', backgroundColor: '#f4f4f4'}}>
        <Tabs
          value={tab}
          onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          centered
          classes={{indicator: style.indicator}}>
          <Tab value="theorem" label="THEOREM files" />
          <Tab value="supporting" label="Supporting Documents" />
        </Tabs>
      </div>
      <SuspenseNull>
        {files && tab === 'theorem' ? (
          <DownloadFileTreeTH
            files={files}
            externalModel={externalModel}
            handleDownload={handleDownloadItem}
            renderButtons={(file) => (
              <Button
                key={`download-item-${file.uuid}`}
                size="small"
                variant="contained"
                disabled={
                  (!!downloading || !confirmed) &&
                  (!file.isPrivate || (file.isPrivate && file.owner.isid === currentUserId))
                }
                onClick={() => handleDownloadItem(file.uuid, file.fileName)}
                {...tid('download-item', file.fileName)}>
                <TextOrProgress active={downloading === file.uuid}>Download</TextOrProgress>
              </Button>
            )}
          />
        ) : (
          supportingDocs && (
            <FileTree
              files={supportingDocs}
              theoremModel={theoremModel}
              renderButtons={(node) => (
                <Button
                  key={`download-item-file-${node.uuid}`}
                  size="small"
                  variant="contained"
                  disabled={!!downloading || !validate}
                  onClick={() => handleDownloadItem(node.uuid, node.name)}
                  {...tid('download-item-file', node.name)}>
                  <TextOrProgress active={downloading === node.uuid}>Download</TextOrProgress>
                </Button>
              )}
            />
          )
        )}
      </SuspenseNull>

      <br />
      <br />
      <Typography variant="body2" style={{paddingLeft: 9}}>
        {model.metadata.legal_notice || defaultLegalNotice}
      </Typography>

      {tab === 'theorem' ? (
        <label>
          <Checkbox
            {...tid('checkbox-confirm-file')}
            color="primary"
            checked={confirmed}
            onClick={handleDownloadAllButton}
          />
          I have read the legal notice
        </label>
      ) : (
        <label>
          <Checkbox
            {...tid('checkbox-confirm')}
            color="primary"
            checked={validate}
            onClick={() => setValidate(!validate)}
          />
          I have read the legal notice
        </label>
      )}

      <div className="buttonsContainer">
        <br />
        <br />
        <CancelButton onClick={handleCancel} />
        <SubmitButton
          onClick={tab === 'theorem' ? handleDownloadAllTheoremFiles : handleDownloadAllSupportingDocs}
          active={downloading === 'all'}
          disabled={tab === 'theorem' ? !downloadAll || !confirmed : !validate}
          endIcon={<Download16 />}>
          Download all
        </SubmitButton>
      </div>
    </>
  );
};

export default ModelDownloadTH;
