import {SheetDiffDTO, WorkbookItemDTO, WorkbookItemDTOTypeEnum} from 'hemwb-api';
import {ChangeItem, Section, SheetOrMacroDiffDTO, TabType} from './types';
import {buildKey} from '../../../../store/excelDiff/utils';

export const getColumnName = (index: number): string =>
  index < 0 ? '' : getColumnName(index / 26 - 1) + String.fromCharCode((index % 26) + 65);

export const getColumnsCount = (diff: SheetDiffDTO): number =>
  diff.cellDiff?.reduce((acc, row) => {
    if (row.length > acc) {
      return row.length;
    }
    return acc;
  }, 0);

export const formatLine = (text: string, tag: string): string => {
  return text
    .replaceAll(`<${tag}>`, `<span class="diff-code-${tag === 'old' ? 'delete' : 'insert'}">`)
    .replaceAll(`</${tag}>`, '</span>');
};

type WorkbookItemDTOTabUse = {name: WorkbookItemDTO['name']; type: WorkbookItemDTO['type']};
export const isSameTab = (t1: WorkbookItemDTOTabUse) => (t2: WorkbookItemDTOTabUse) =>
  t1.name === t2.name && t1.type === t2.type;

export const isTabFromSection = (s: Section) => (t: WorkbookItemDTOTabUse) => {
  if (s === Section.OVERVIEW) {
    return false;
  }
  if (s === Section.SHEETS) {
    return t.type === WorkbookItemDTOTypeEnum.SHEET;
  }
  return t.type !== WorkbookItemDTOTypeEnum.SHEET;
};

export const getSectionForTab = (tab: TabType): Section =>
  tab.type === WorkbookItemDTOTypeEnum.SHEET ? Section.SHEETS : Section.MACROS;

export const isSheetDiff = (diff: SheetOrMacroDiffDTO): diff is SheetDiffDTO => {
  return (diff as SheetDiffDTO).columnDiff !== undefined;
};

export const buildKeyFromTab = (tab: TabType, pageIndex = 0) =>
  buildKey({
    itemId1: tab.itemId1,
    itemId2: tab.itemId2,
    itemUUID1: tab.file1.uuid,
    itemUUID2: tab.file2.uuid,
    pageIndex,
  });

export const isRowMarkedChange = (
  changedRowIndex: number,
  tab: TabType,
  diff: SheetOrMacroDiffDTO,
  markedChange?: ChangeItem,
): boolean => {
  return (
    tab === markedChange?.tab &&
    markedChange?.diffIndex === changedRowIndex &&
    diff.firstDiffIndexInPage <= changedRowIndex &&
    changedRowIndex <= diff.lastDiffIndexInPage
  );
};
