import {useContext, useEffect, useRef} from 'react';
import {WebSocketContext} from './WebSocketContext';
import {useDispatch} from 'react-redux';
import {StompSubscription} from '@stomp/stompjs';
import {ExecutionStatus} from 'hemwb-api';
import {messageAdd, MessageTypes} from '../store/message';
import {formatScenarioExecutionStatusValue} from '../store/scenarioExecution/utils';
import {useCurrentUser} from '../store/auth';

export const useUserScenarioExecutionStatusUpdate = () => {
  const {client, connected} = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const subscription = useRef<StompSubscription>();
  const loggedInUser = useCurrentUser();

  useEffect(() => {
    if (client?.connected && connected) {
      //
      // Sends message each time status of a scenario execution created by the subscribed user is updated
      //
      subscription.current = client.subscribe(
        `/user/${loggedInUser.id}/queue/userScenarioExecutionStatusUpdates`,
        (message) => {
          try {
            const {scenario, execution} = JSON.parse(message.body);
            const {status} = execution;

            switch (status) {
              case ExecutionStatus.FAILED: {
                dispatch(messageAdd(`Execution of ${scenario.name} failed`, MessageTypes.WARNING));
                break;
              }
              case ExecutionStatus.COMPLETED: {
                dispatch(messageAdd(`Execution of ${scenario.name} completed`, MessageTypes.SUCCESS));
                break;
              }
              default: {
                dispatch(
                  messageAdd(
                    `Execution status of ${scenario.name} changed to ${formatScenarioExecutionStatusValue(status)}`,
                    MessageTypes.INFO,
                  ),
                );
              }
            }
          } catch (e) {
            dispatch(
              messageAdd(
                `Failed to parse WS response in /queue/userScenarioExecutionStatusUpdates: ${message.body}`,
                MessageTypes.WARNING,
              ),
            );
          }
        },
      );
    }

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [dispatch, client, connected, loggedInUser.id]);
};
