import {useCallback, useEffect, useRef} from 'react';

export const useVisited = <V extends string | number>(visitingPage: V) => {
  const visitedRef = useRef({[visitingPage]: true});

  useEffect(() => {
    if (!visitedRef.current[visitingPage]) {
      visitedRef.current[visitingPage] = true;
    }
  }, [visitingPage, visitedRef]);

  return useCallback((page: V) => !!visitedRef.current[page], [visitedRef]);
};
