import React from 'react';
import {ScenarioDTO, ScenarioExecutionDTO, ScenarioExecutionGroupDetailDTO} from 'hemwb-api';
import ExecutionStatusIcon from '../../icons/ExecutionStatusIcon';
import {formatScenarioExecutionStatusValue} from '../../../store/scenarioExecution/utils';
import styles from './ExecutionStatus.module.scss';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const ExecutionStatus: React.FC<ScenarioDTO | ScenarioExecutionDTO | ScenarioExecutionGroupDetailDTO> = ({status}) => {
  return (
    <TransitionGroup className={styles.animationWrapper}>
      <CSSTransition timeout={1300} classNames="execution-status" key={status}>
        <div className={styles.container}>
          <ExecutionStatusIcon status={status} />
          <span>{formatScenarioExecutionStatusValue(status)}</span>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default ExecutionStatus;
