import React from 'react';
import {SubModelActionProps} from '../../actionMenuTypes';
import {useLocation} from 'react-router';
import {getUrlRoute, Routes} from '../../../router/routes';
import SideBarLinkItem from '../../../layout/common/SideBarLinkItem';
import {tid} from '../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../test/types';
import {canEditInputDefinition} from '../../../../permissions/usePermissions';
import {RequestQuote16} from '@carbon/icons-react';

const ModelInputEditorActionMenuLink: React.FC<Partial<SubModelActionProps>> = ({model, subModel}) => {
  const location = useLocation();
  const link =
    model && subModel
      ? getUrlRoute(Routes.MODEL_INPUT_EDITOR, {
          modelUuid: model.uuid,
          subModelId: subModel.id,
        })
      : '#';

  return (
    <SideBarLinkItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.INPUT_DEFINITION_EDITOR)}
      selected={location.pathname === link}
      permission={canEditInputDefinition(model, subModel)}
      linkTo={link}
      icon={<RequestQuote16 />}
      text="Editor"
    />
  );
};

export default ModelInputEditorActionMenuLink;
