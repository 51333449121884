import React from 'react';
import clsx from 'clsx';
import {styles} from './styles';
import SubmitButton from '../../../../../core/Buttons/SubmitButton';
import BitbucketBrowser from '../../../../../core/BitbucketBrowser/BitbucketBrowser';
import {ModelEditFilesMode} from './utils';
import WarningText from '../../../../../core/WarningText/WarningText';
import {ModelDTO} from 'hemwb-api';
import {tid} from '../../../../../../testUtils';
import {HardDriveNetwork} from '@carbon/pictograms-react';

type BitbucketManagerProps = {
  model: ModelDTO;
  updateModel: (model: ModelDTO) => void;
  mode: ModelEditFilesMode;
  onEdit: () => void;
};

const BitbucketManager: React.FC<BitbucketManagerProps> = ({onEdit, mode, model, updateModel}) => {
  return (
    <div className={clsx('darkBlueTheme', styles.panel, styles.panelBitbucket)}>
      <div className={styles.title}>Upload model from Bitbucket</div>
      {mode === ModelEditFilesMode.BITBUCKET ? (
        <>
          <BitbucketBrowser model={model} updateModel={updateModel} />
        </>
      ) : (
        <>
          <SubmitButton className="whiteButton" onClick={onEdit} {...tid('btn-connect-bitbucket')}>
            Connect with Bitbucket
          </SubmitButton>
          <HardDriveNetwork style={{margin: '11rem 1rem', width: '11.6rem', height: '11.6rem', color: '#8981EF'}} />
          <WarningText text="Connecting with bitbucket will not do any changes to bitbucket repo." />
        </>
      )}
    </div>
  );
};

export default BitbucketManager;
