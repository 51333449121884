import React, {CSSProperties, ReactNode, useCallback, useMemo, useState} from 'react';
import {ClickAwayListener, Fade, IconButton, IconButtonProps, Paper, Popper} from '@material-ui/core';
import nanoid from 'nanoid';
import {MoreHoriz} from '@material-ui/icons';
import {PopperPlacementType} from '@material-ui/core/Popper/Popper';
import {useDispatch} from 'react-redux';
import {
  appComponentsButtonWithPopperSelectorBuilder,
  appComponentsButtonWithPopperSet,
  appComponentsButtonWithPopperToggle,
} from '../../../store/appCompoments';
import {useSelector} from '../../../store/rootReducer';
import clsx from 'clsx';
import styles from './ButtonWithPopper.module.scss';

type ThemeType = 'expandedButton';

type ButtonWithPopperProps = {
  popperButtonSize?: IconButtonProps['size'];
  group?: string;
  renderButton?: (p: any) => ReactNode;
  renderPopperContent: (p: any) => ReactNode;
  placement?: PopperPlacementType;
  style?: CSSProperties;
  theme?: ThemeType;
};
const ButtonWithPopper: React.FC<ButtonWithPopperProps> = ({
  popperButtonSize,
  group: _group,
  placement = 'bottom',
  renderButton,
  renderPopperContent,
  style,
  theme,
}) => {
  const dispatch = useDispatch();
  const [id] = useState(nanoid());
  const group = _group || id;
  const buttonState = useSelector(appComponentsButtonWithPopperSelectorBuilder(group));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(buttonState && buttonState.id === id && anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      dispatch(appComponentsButtonWithPopperToggle({id, group}));
    },
    [dispatch, id, group],
  );

  const handleClose = useCallback(() => {
    open && dispatch(appComponentsButtonWithPopperSet({group}));
  }, [dispatch, group, open]);

  const buttonProps = useMemo(() => {
    return {
      onClick: handleClick,
    };
  }, [handleClick]);
  return (
    <div style={{display: 'inline-block', ...style}} className={theme}>
      <div className={clsx(styles.buttonWrapper, {[styles.buttonWrapperOpen]: open})}>
        {renderButton ? (
          renderButton(buttonProps)
        ) : (
          <IconButton {...buttonProps} size={popperButtonSize}>
            <MoreHoriz />
          </IconButton>
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: 'scrollParent',
          },
        }}
        style={{zIndex: 1300}}>
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>{renderPopperContent({handleClose})}</div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default ButtonWithPopper;
