import React from 'react';
import {VisualizationResultsSectionElement} from '../../../../../store/visualization';

const SectionTitle: React.FC<VisualizationResultsSectionElement> = ({title, text}) => {
  return (
    <div>
      <h1>{title}</h1>
      {text && <p>{text}</p>}
    </div>
  );
};

export default SectionTitle;
