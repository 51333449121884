/* eslint-disable @typescript-eslint/camelcase */
import React, {MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AbstractControl, FormControl, FormGroup, Validators} from 'react-reactive-form';
import {
  ModelMetadataOptionSimplifyDTO,
  useModelMetadataActiveOptions,
  useModelMetadataAssociations,
} from '../../../../../../store/modelMetadata';
import {ModelDTO, VEAPActivity} from 'hemwb-api';
import {
  AutocompleteControl,
  Form,
  FormRenderProps,
  SwitchControl,
  TextFieldControl,
  useForm,
  validateOption,
} from '../../../../../core/Form';
import {FieldKey, fields, getCommonFieldProps, hardCodedOptions, requiredFields, simpleFields} from './constants';
import {getModelLanguageText, isModelExternal, isModelWeb} from '../../../../../../store/model';
import {buildHardcodedOptionsControls, mapValuesToModel, transformModelToValues} from './utils';
import {validateVersion} from './validators';
import {tid} from '../../../../../../testUtils';
import styles from './ModelEditMetadataForm.module.scss';
import clsx from 'clsx';
import formStyles from '../../../../../core/Form/Form.module.scss';
import {Grid, Tooltip, InputAdornment} from '@material-ui/core';
import GridTitle from '../../../../../core/Form/GridTitle';
import GridItem from '../../../../../core/Form/GridItem';
import {Assets, EuropeAfrica, Networking_03 as Networking03, Puzzle} from '@carbon/pictograms-react';
import MetadataTextFieldControl from './MetadataTextFieldControl';
import {modelFileMarkChangelog, modelFileSelector, ModelTreeNode} from '../../../../../../store/modelFile';
import FormInfoText from '../../../../../core/Form/FormInfoText';
import {useVeevaMdpLoaderWithParams, useVeevaVeapLoaderWithParams} from '../../../../../../store/veeva/hooks';
import {renderProductOptionItem} from './renderProductOptionItem';
import {renderVeapOptionItem} from './renderVeapOptionItem';
import {FolderAdd16, Information16} from '@carbon/icons-react';
import {canBeChangeLogFile} from '../../../../../../store/modelFile/utils';
import {useFilePickerDialog} from '../../../../../core/FilePickerDialog/FilePickerDialog';
import {store} from '../../../../../../store/rootReducer';
import {messageAdd, MessageTypes} from '../../../../../../store/message';
import {useDispatch} from 'react-redux';

export type ModelEditMetadataFormProps = {
  model: ModelDTO;
  files?: ModelTreeNode[];
  onSubmit: (updatedModel: ModelDTO) => void;
  onChange?: (model: ModelDTO) => void;
  buttons: (params: FormGroup & {submitting: boolean}) => string | ReactNode;
  formRef?: React.Ref<HTMLFormElement>;
  formObjectRef?: MutableRefObject<FormGroup | undefined>;
  formObjectFileRef?: MutableRefObject<FormGroup | undefined>;
  clonedFrom?: ModelDTO;
  validateOnInit?: boolean;
  isEdit?: boolean;
};

const ModelEditMetadataForm: React.FC<ModelEditMetadataFormProps> = ({
  model,
  buttons,
  onSubmit,
  formRef,
  formObjectRef,
  clonedFrom,
  validateOnInit = false,
  isEdit = false,
  files,
  onChange,
}) => {
  const options = useModelMetadataActiveOptions();
  const optionsRef = useRef<ModelMetadataOptionSimplifyDTO | null>(null);
  const associations = useModelMetadataAssociations();
  const formWithVersion = !!clonedFrom;
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  const originalWebModel = useRef(isModelWeb(model));
  const dispatch = useDispatch();

  const metadataOptionValidator = useCallback(
    (optionsGetter: (metadata: ModelMetadataOptionSimplifyDTO) => any[]) => (control: AbstractControl) =>
      optionsRef.current ? validateOption(control.value, optionsGetter(optionsRef.current)) : null,
    [optionsRef],
  );

  const loadVeap = useVeevaVeapLoaderWithParams();
  const loadMdp = useVeevaMdpLoaderWithParams();

  const [mdp, setMdp] = useState<any>();
  const formChangeLog = useForm(
    new FormGroup({
      changeLogFileName: new FormControl(null),
      changelog_file: new FormControl(null),
    }),
    transformModelToValues(model, files ? files : []),
  );

  const handleSelectedChangeLogFile = useCallback(
    async (uuid) => {
      const node = files?.find((n) => n.uuid === uuid);
      formChangeLog.controls.changeLogFileName.setValue(node?.name);
      formChangeLog.controls.changelog_file.setValue(node?.uuid);

      if (node) {
        const newNode = modelFileSelector(store.getState()).uploader.nodes.find((n) => n.path === node.path);
        if (newNode) {
          await modelFileMarkChangelog(dispatch, model.uuid, newNode.uuid).then(() => {
            dispatch(messageAdd('Saving changelog file', MessageTypes.SUCCESS));
          });
        }
      }
      if (onChange) {
        onChange(mapValuesToModel(formChangeLog.value, model));
      }
    },
    [formChangeLog, dispatch, onChange, files, model],
  );

  const changeLogInputRef = useRef<HTMLInputElement>();

  const changeLogFiles = files?.filter((node) => canBeChangeLogFile(node))
    ? files?.filter((node) => canBeChangeLogFile(node))
    : [];
  const {open: openChangeLogDialog, DialogComponent: ChangeLogDialog} = useFilePickerDialog(
    handleSelectedChangeLogFile,
    changeLogFiles,
    formChangeLog.controls.changelog_file.value,
    'Select Changelog document',
  );

  const loadMdpList = useCallback(
    (veapId?: string | VEAPActivity) => {
      if (veapId) {
        loadMdp([typeof veapId === 'object' ? veapId.id : veapId]).then((response) => {
          if (Array.isArray(response)) {
            setMdp(response.map((r) => r.url));
          }
        });
      } else {
        setMdp([]);
      }
    },
    [loadMdp],
  );

  useEffect(() => {
    loadMdpList(model.metadata['veap_id']);
  }, [model, loadMdpList]);

  const formInstance = useRef<FormGroup>();
  const defaultForm = useMemo(
    () =>
      new FormGroup({
        ...Object.keys(simpleFields).reduce((acc, val) => {
          acc[val] = new FormControl(null, requiredFields[val as FieldKey] ? Validators.required : []);
          return acc;
        }, ({} as any) as {[key: string]: FormControl}),
        ...buildHardcodedOptionsControls(),
        veap_id: new FormControl(null, null, (control: AbstractControl) => {
          if (typeof control.value !== 'string') {
            return Promise.resolve(null);
          }
          return loadVeap([control.value]).then((ids) => {
            if (!Array.isArray(ids)) {
              return null;
            }
            const foundId = (ids as VEAPActivity[]).find((i) => i.id === control.value);
            if (foundId) {
              control.setValue(foundId);
            }
            return null;
          });
        }),
        disease: new FormControl(null, [Validators.required, metadataOptionValidator((opt) => opt.diseases)]),
        product: new FormControl([], [Validators.required, metadataOptionValidator((opt) => opt.products)]),
        study_number: new FormControl(null, [metadataOptionValidator((opt) => opt.studyNumbers)]),
        therapeutic_area: new FormControl(null, [
          Validators.required,
          metadataOptionValidator((opt) => opt.therapeuticAreas),
        ]),
        model_type: new FormControl(null, [Validators.required, metadataOptionValidator((opt) => opt.modelTypes)]),
        country: new FormControl(null, [
          ({value}) =>
            !value && formInstance.current && !formInstance.current.controls.global.value ? {required: true} : null,
          metadataOptionValidator((opt) => opt.countries),
        ]),
        global: new FormControl(false),
        webmodel: new FormControl(false),
        stage_of_model_development: new FormControl(null, [
          Validators.required,
          metadataOptionValidator((opt) => opt.stagesOfModelDevelopment),
        ]),
        revops_id: new FormControl([]),
        version_release_of_model: new FormControl(
          null,
          formWithVersion ? [Validators.required, (control) => validateVersion(control, formInstance, clonedFrom)] : [],
        ),
        changeLogFileName: new FormControl(null),
        changelog_file: new FormControl(null),

        mk_number: new FormControl([]),
      }),
    [metadataOptionValidator, clonedFrom, formWithVersion, loadVeap],
  );

  const form = useForm(defaultForm, model, transformModelToValues);

  useEffect(() => {
    optionsRef.current = options;
    // forces re-validation
    if (externalModel) {
      form.disable();
    } else {
      form.enable();
    }
    if (validateOnInit) {
      Object.values(form.controls).forEach((control) => control.markAsTouched());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, form]);

  useEffect(() => {
    formInstance.current = form;
    if (formObjectRef) {
      formObjectRef.current = form;
      formObjectRef.current.enable();
    }

    // update country validity on global field change
    const updateCountryValidity = () => form.controls.country.updateValueAndValidity();
    const updateVersionValidity = () => form.controls.version_release_of_model.updateValueAndValidity();
    let country = form.controls.country.value;
    const updateGlobalOnCountrySet = (newCountry: any) => {
      if (!country && newCountry) {
        form.controls.global.setValue(false, {emitEvent: false});
      }
      updateVersionValidity();
      country = newCountry;
    };

    form.controls.global.valueChanges.subscribe(updateCountryValidity);
    form.controls.country.valueChanges.subscribe(updateGlobalOnCountrySet);

    form.controls.disease.valueChanges.subscribe(updateVersionValidity);
    form.controls.product.valueChanges.subscribe(updateVersionValidity);
    form.controls.therapeutic_area.valueChanges.subscribe(updateVersionValidity);
    form.controls.global.valueChanges.subscribe(updateVersionValidity);
    form.controls.country.valueChanges.subscribe(updateVersionValidity);
    form.controls.veap_id.valueChanges.subscribe(loadMdpList);

    return () => {
      form.controls.global.valueChanges.unsubscribe(updateCountryValidity);
      form.controls.country.valueChanges.unsubscribe(updateGlobalOnCountrySet);

      form.controls.disease.valueChanges.unsubscribe(updateVersionValidity);
      form.controls.product.valueChanges.unsubscribe(updateVersionValidity);
      form.controls.therapeutic_area.valueChanges.unsubscribe(updateVersionValidity);
      form.controls.global.valueChanges.unsubscribe(updateVersionValidity);
      form.controls.country.valueChanges.unsubscribe(updateVersionValidity);
    };
  }, [form, formObjectRef, loadMdpList, webModel]);

  useEffect(() => {
    if (form && associations) {
      const cascadeUpdateOnStudyNumberChange = (newStudyNumber: string) => {
        if (newStudyNumber && associations.studyNumbers[newStudyNumber]) {
          const values = transformModelToValues({metadata: associations.studyNumbers[newStudyNumber]});
          (Object.keys(associations.studyNumbers[newStudyNumber]) as (keyof typeof fields)[]).forEach((key) => {
            form.get(key).setValue(values[key]);
          });
        }
      };

      form.controls.study_number.valueChanges.subscribe(cascadeUpdateOnStudyNumberChange);
      return () => {
        form.controls.study_number.valueChanges.unsubscribe(cascadeUpdateOnStudyNumberChange);
      };
    }
  }, [associations, form]);

  const handleSubmit = useCallback(() => {
    return onSubmit(mapValuesToModel(form.value, model, formWithVersion));
  }, [form, onSubmit, model, formWithVersion]);

  const findVeapCallback = useCallback(
    (searchedTerm: string) => {
      return loadVeap([searchedTerm]).then((r) => (r || []) as VEAPActivity[]);
    },
    [loadVeap],
  );

  return (
    <>
      <Form
        ref={formRef}
        className=""
        FieldGroupProps={{strict: false}}
        group={form}
        onSubmit={handleSubmit}
        {...tid('metadata-form')}
        render={(renderParams) => {
          return (
            <>
              <div className={styles.contentContainer}>
                <div className={clsx(formStyles.form, styles.formWrapper)}>
                  <Grid container direction="row" alignItems="flex-start" justify="space-between">
                    <GridTitle Icon={<Puzzle className="icon32" />}>Model Identification</GridTitle>
                    {externalModel ? (
                      <>
                        <GridItem>
                          <MetadataTextFieldControl name="revops_id" />
                        </GridItem>

                        <GridItem>
                          {!form.controls['revops_id'].value && (
                            <FormInfoText
                              text={
                                <>
                                  We strongly recommend
                                  <br />
                                  to fill in REV/OPS ID
                                </>
                              }
                            />
                          )}
                        </GridItem>
                      </>
                    ) : (
                      <>
                        <GridItem>
                          <AutocompleteControl
                            {...getCommonFieldProps('veap_id')}
                            search={findVeapCallback}
                            wait={100}
                            loading={
                              form.controls['veap_id'].value && typeof form.controls['veap_id'].value === 'string'
                            }
                            AutocompleteProps={{
                              getOptionSelected: (option: VEAPActivity, value: string) => {
                                return option.id === value;
                              },
                              getOptionLabel: (option: VEAPActivity | string) => (option as VEAPActivity)?.name || '',

                              renderOption: renderVeapOptionItem,
                              disableOpenOnFocus: true,
                            }}
                          />
                        </GridItem>
                        <GridItem>
                          {!form.controls['veap_id'].value && (
                            <FormInfoText
                              text={
                                <>
                                  We strongly recommend
                                  <br />
                                  to fill in VEAP ID or REV/OPS ID
                                </>
                              }
                            />
                          )}
                        </GridItem>
                      </>
                    )}
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('study_number')}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.studyNumbers || [],
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('disease')}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.diseases || [],
                        }}
                      />
                    </GridItem>

                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('product')}
                        loading={options === null}
                        AutocompleteProps={{
                          disableCloseOnSelect: true,
                          multiple: true,
                          renderOption: renderProductOptionItem,
                          getOptionLabel: (option: string) => option || '',
                          options: options?.products || [],
                        }}
                      />
                    </GridItem>
                    {externalModel && (
                      <GridItem>
                        <AutocompleteControl
                          {...getCommonFieldProps('mk_number')}
                          required={externalModel}
                          AutocompleteProps={{
                            getOptionLabel: (option: string) => option || '',
                            multiple: true,
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('therapeutic_area')}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.therapeuticAreas || [],
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('type')}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => getModelLanguageText(option) || '',
                          options: hardCodedOptions['type'],
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('model_type')}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.modelTypes || [],
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('information_classification')}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: hardCodedOptions['information_classification'],
                        }}
                      />
                    </GridItem>

                    {!externalModel && (
                      <GridItem>
                        <Tooltip
                          style={{position: 'relative', right: '-150px', top: '16px'}}
                          title={
                            <div style={{fontSize: 12}}>
                              <strong>
                                WebModel 2.0 is a set of features in Model Repository that enables model deployment,
                                execution, and visualization for scripted language models (e.g., Mathematica).
                              </strong>
                            </div>
                          }
                          placement="top"
                          arrow>
                          <span>
                            <Information16 />
                          </span>
                        </Tooltip>

                        <SwitchControl
                          name="webmodel"
                          disabled={isEdit && originalWebModel.current}
                          label="Use WebModel 2.0 features"
                          inputProps={tid('input-webmodel') as {}}
                        />
                      </GridItem>
                    )}

                    <GridTitle Icon={<EuropeAfrica className="icon32" />}>Country Association</GridTitle>
                    <GridItem>
                      <SwitchControl
                        name="global"
                        disabled={externalModel}
                        label="Is this a Global Model?"
                        inputProps={tid('input-global') as {}}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('country')}
                        required={!form.controls.global.value}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.countries || [],
                        }}
                      />
                    </GridItem>

                    <GridTitle Icon={<Assets className="icon32" />}>Documentation</GridTitle>
                    <GridItem>
                      <MetadataTextFieldControl name="objective_of_model" />
                    </GridItem>

                    {/*<GridTitle Icon={<Assets className="icon32" />}>Model testing</GridTitle>*/}

                    <GridTitle Icon={<EuropeAfrica className="icon32" />}>Usage</GridTitle>
                    <GridItem className={styles.notes}>
                      <MetadataTextFieldControl name="notes" />
                    </GridItem>

                    <GridTitle Icon={<EuropeAfrica className="icon32" />}>Stage</GridTitle>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('model_development_plan')}
                        loading={mdp === undefined}
                        AutocompleteProps={{
                          // getOptionLabel: (option: string) => option || '',
                          options: mdp || [],
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <AutocompleteControl
                        {...getCommonFieldProps('stage_of_model_development')}
                        loading={options === null}
                        AutocompleteProps={{
                          getOptionLabel: (option: string) => option || '',
                          options: options?.stagesOfModelDevelopment || [],
                        }}
                      />
                    </GridItem>
                    {externalModel && (
                      <>
                        <GridTitle Icon={<EuropeAfrica className="icon32" />}>THEOREM Oncology Model Details</GridTitle>
                        <GridItem>
                          <MetadataTextFieldControl name="label" />
                        </GridItem>
                        <GridItem>
                          <MetadataTextFieldControl name="platform" />
                        </GridItem>
                        <GridItem>
                          <MetadataTextFieldControl name="indication" />
                        </GridItem>
                      </>
                    )}
                    {formWithVersion && (
                      <>
                        <GridTitle Icon={<Networking03 className="icon32" />}>Version Control</GridTitle>
                        <GridItem>
                          <MetadataTextFieldControl
                            name="version_release_of_model"
                            customErrorMessages={{format: 'Version must match number format like "1" or "1.1"'}}
                          />
                        </GridItem>

                        <GridItem>
                          <MetadataTextFieldControl name="version_log" />
                        </GridItem>
                        {!externalModel && (
                          <GridItem>
                            <Form
                              group={formChangeLog}
                              className=""
                              {...tid('version-form')}
                              onChange={() => (onChange ? onChange(mapValuesToModel(formChangeLog.value, model)) : [])}
                              render={() => {
                                return (
                                  <GridItem>
                                    <div
                                      // className={styles.inputFile}
                                      onClick={() => {
                                        changeLogInputRef.current?.blur();
                                        changeLogFiles.length > 0 && openChangeLogDialog();
                                      }}>
                                      <TextFieldControl
                                        TextFieldProps={{
                                          inputRef: changeLogInputRef,
                                          InputProps: {
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <FolderAdd16 />
                                              </InputAdornment>
                                            ),
                                          },
                                          inputProps: {
                                            ...tid('input', 'mainFile'),
                                          },
                                        }}
                                        disabled={changeLogFiles.length === 0}
                                        name="changeLogFileName"
                                        strict={false}
                                        label="Changelog document"
                                      />
                                    </div>
                                  </GridItem>
                                );
                              }}
                            />
                            {ChangeLogDialog}
                          </GridItem>
                        )}
                      </>
                    )}
                  </Grid>
                </div>
              </div>

              {buttons({...renderParams, valid: renderParams.valid} as FormRenderProps)}
            </>
          );
        }}
      />
    </>
  );
};

export default ModelEditMetadataForm;
