import React, {PropsWithChildren} from 'react';
import {Grid} from '@material-ui/core';

const GridItem: React.FC<PropsWithChildren<{className?: string}>> = ({children, ...props}) => {
  return (
    <Grid
      item
      style={{maxWidth: '28.8rem', width: '100%', marginTop: 0, marginBottom: '2rem'}}
      sm={12}
      md="auto"
      {...props}>
      {children}
    </Grid>
  );
};

export default GridItem;
