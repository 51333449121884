import {RootState} from '../rootReducer';
import {requestExecutionSetup, requestExecutionSetupList} from './api';
import {buildKey} from './utils';

export const executionSetupSelector = (state: RootState) => state.executionSetup;

export const executionSetupListSelectorBuilder = (params: Parameters<typeof requestExecutionSetupList>) => (
  state: RootState,
) => executionSetupSelector(state).list[buildKey(params[0], params[1])];

export const executionSetupSingleSelectorBuilder = (params: Parameters<typeof requestExecutionSetup>) => (
  state: RootState,
) => executionSetupSelector(state).single[params[0]];
