import {VersionControlActions, VersionControlPage} from './types';

export const versionControlToggleDiffSelection = (modelUuid: string) => ({
  type: VersionControlActions.TOGGLE_DIFF_SELECTION,
  modelUuid,
});

export const versionControlInit = (modelUuid: string) => ({
  type: VersionControlActions.INIT,
  modelUuid,
});

export const versionControlPage = (page: VersionControlPage) => ({type: VersionControlActions.PAGE, page});
