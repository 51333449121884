import {DiffDTOTagEnum, GetMacroDiffRequest, GetSheetDiffRequest, SheetDiffDTO} from 'hemwb-api';

export const buildKey = (p: GetMacroDiffRequest | GetSheetDiffRequest) =>
  `${p.itemUUID1}-${p.itemUUID2}-${p.itemId1}-${p.itemId2}-${p.pageIndex}`;

export const optimizeSheetDiff = (diff: SheetDiffDTO): SheetDiffDTO => {
  for (const row of diff.cellDiff) {
    if (row.length === 0) continue;
    for (let cellIndex = row.length - 1; cellIndex >= 0; cellIndex--) {
      const cell = row[cellIndex];
      if (cell && cell.tag === DiffDTOTagEnum.Equal && cell.oldLine.trim() === '') {
        row.pop();
      } else {
        break;
      }
    }
  }

  return diff;
};
