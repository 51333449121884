import React, {useCallback, useMemo, useState} from 'react';
import {DialogGeneric} from '../../../../core/dialog/DialogGeneric';
import styles from '../VisualizationScenarioAction.module.scss';
import {ScenarioActionProps} from '../../../actionMenuTypes';
import ScenarioClone from './ScenarioClone';

export const useScenarioCloneDialog = () => {
  const [openProps, setOpenProps] = useState<ScenarioActionProps & {sayCopyInsteadOfClone?: boolean}>();
  const handleOnCancel = useCallback(() => {
    setOpenProps(undefined);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setOpenProps(undefined);
    openProps?.onSuccess?.();
  }, [openProps]);

  const Component = useMemo(() => {
    return !openProps ? null : (
      <DialogGeneric
        open={true}
        title={`${openProps.sayCopyInsteadOfClone ? 'Copy' : 'Clone'} Scenario`}
        childrenAsText={false}
        includesDialogActions={true}>
        <div className={styles.dialog}>
          <ScenarioClone {...openProps} onSuccess={handleOnSuccess} onCancel={handleOnCancel} />
        </div>
      </DialogGeneric>
    );
  }, [openProps, handleOnCancel, handleOnSuccess]);

  return {
    open: setOpenProps,
    Component,
  };
};
