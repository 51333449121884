import React from 'react';
import {ScenarioActionProps} from '../../actionMenuTypes';
import {useLocation} from 'react-router';
import {getUrlRoute, Routes} from '../../../router/routes';
import SideBarLinkItem from '../../../layout/common/SideBarLinkItem';
import {tid} from '../../../../testUtils';
import {SUBMODEL_ACTION_MENU_IDS} from '../../../../test/types';
import {View16} from '@carbon/icons-react';

const ScenarioDetailActionMenuLink: React.FC<Partial<ScenarioActionProps>> = ({model, scenario}) => {
  const location = useLocation();
  const link =
    model && scenario
      ? getUrlRoute(Routes.SCENARIO_DETAIL, {
          modelUuid: model.uuid,
          scenarioId: scenario.id,
        })
      : '#';

  return (
    <SideBarLinkItem
      {...tid(SUBMODEL_ACTION_MENU_IDS.SCENARIO_DETAIL)}
      selected={location.pathname === link}
      disabled={!model || !scenario}
      linkTo={link}
      icon={<View16 />}
      text="Detail"
    />
  );
};

export default ScenarioDetailActionMenuLink;
