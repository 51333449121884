import {useCurrentUser} from '../store/auth';
import {ExecutionSetupDTO, LoggedInUserDTO, ModelDetailDTO, ModelDTO, ScenarioDTO, UserDTO, UserType} from 'hemwb-api';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';
import * as appPermissions from './checkAppLevelPermissions';
import * as modelPermissions from './checkModelLevelPermissions';
import * as subModelPermissions from './checkSubModelLevelPermissions';
import * as instancePermissions from './checkInstanceLevelPermissions';

export * from './types';

let loggedInUser = {} as LoggedInUserDTO;
let eligibleUserAtLeast = false;
let admin = false;

export const usePermissions = () => {
  const location = useLocation();
  const history = useHistory();
  const l = useCurrentUser();

  useEffect(() => {
    loggedInUser = l;
    eligibleUserAtLeast =
      l.userType === UserType.INTERNALUSER || l.userType === UserType.EXTERNALUSER || l.userType === UserType.SUPERUSER;
    admin = l.userType === UserType.SUPERUSER;
  }, [l]);

  useEffect(() => {
    if (l.id) {
      if (
        (location.pathname.startsWith('/admin') && l.userType !== UserType.SUPERUSER) ||
        (!eligibleUserAtLeast && location.pathname.startsWith('/model-upload'))
      ) {
        history.push('/error/unauthorised');
      }
    }
  }, [location, history, l]);
};

// APP LEVEL PERMISSIONS
export const canCreateModel = () => appPermissions.canCreateModel(loggedInUser);
export const canCreateExternalUser = () => appPermissions.canCreateExternalUser(loggedInUser);

export const canDownloadAllModels = () => appPermissions.canDownloadAllModels(loggedInUser);

export const isExternalUser = () => appPermissions.isExternalUser(loggedInUser);

//
// ADMIN SPACE
//
export const isAdmin = () => admin;

export const canActivateUser = (user?: UserDTO) => appPermissions.canActivateUser(loggedInUser, user);

export const canDeactivateUser = (user?: UserDTO) => appPermissions.canDeactivateUser(loggedInUser, user);

export const canEnrollUser = () => appPermissions.canEnrollUser(loggedInUser);

export const canChangeUserRole = (user?: UserDTO) => appPermissions.canChangeUserRole(loggedInUser, user);

export const canManageMetadata = (property?: string) => appPermissions.canManageMetadata(loggedInUser, property);

//
// MODEL
//

export const canViewModel = (model?: ModelDTO) => modelPermissions.canViewModel(loggedInUser, model);

export const canSaveAnalysis = (model?: ModelDTO) => modelPermissions.canSaveAnalysis(loggedInUser, model);

export const canCloneModel = (model?: ModelDTO) => modelPermissions.canCloneModel(loggedInUser, model);

export const canEditModel = (model?: ModelDTO) => modelPermissions.canEditModel(loggedInUser, model);

export const canDeleteDraftModel = (model?: ModelDTO) => modelPermissions.canDeleteDraftModel(loggedInUser, model);

export const canPublishModel = (model?: ModelDetailDTO) => modelPermissions.canPublishModel(loggedInUser, model);

export const canRetireModel = (model?: ModelDTO) => modelPermissions.canRetireModel(loggedInUser, model);

export const canDownloadModelFiles = (model?: ModelDetailDTO) =>
  modelPermissions.canDownloadModelFiles(loggedInUser, model);

export const canViewModelFiles = (model?: ModelDTO) => modelPermissions.canViewModelFiles(loggedInUser, model);

export const canViewModelFolders = (model?: ModelDTO) => modelPermissions.canViewModelFolders(loggedInUser, model);

export const canEditTHModelAccess = (model?: ModelDTO) => modelPermissions.canEditTHModelAccess(loggedInUser, model);

export const canAccessCalibrationManagement = (model?: ModelDTO) =>
  modelPermissions.canAccessCalibrationManagement(loggedInUser, model);

export const canAccessScenarioManagement = (model?: ModelDTO) =>
  modelPermissions.canAccessScenarioManagement(loggedInUser, model);

export const canAccessDisplayManagement = (model?: ModelDTO) =>
  modelPermissions.canAccessDisplayManagement(loggedInUser, model);

export const canAccessVisualization = (model?: ModelDTO) =>
  modelPermissions.canAccessVisualization(loggedInUser, model);

export const canTestVisualization = (model?: ModelDTO) => modelPermissions.canTestVisualization(loggedInUser, model);

//SUBMODELS
export const canAddSubModel = (model?: ModelDTO) => subModelPermissions.canAddSubModel(loggedInUser, model);

export const canEditSubModel = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  subModelPermissions.canEditSubModel(loggedInUser, model, subModel);

export const canDeleteSubModel = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  subModelPermissions.canDeleteSubModel(loggedInUser, model, subModel);

//INPUT DEFINITION
export const canAddInputDefinition = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  subModelPermissions.canAddInputDefinition(loggedInUser, model, subModel);

export const canEditInputDefinition = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  subModelPermissions.canEditInputDefinition(loggedInUser, model, subModel);

export const canExportInputDefinition = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  subModelPermissions.canExportInputDefinition(loggedInUser, model, subModel);

//INSTANCES
export const canAddInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO) =>
  instancePermissions.canAddInstance(loggedInUser, model, subModel);

export const canEditInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canEditInstance(loggedInUser, model, subModel, instance);

export const canCloneInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canCloneInstance(loggedInUser, model, subModel, instance);

export const canExecuteInstance = canEditInstance;

export const canCancelInstanceExecution = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canCancelInstanceExecution(loggedInUser, model, subModel, instance);

export const canViewInstanceResults = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canViewInstanceResults(loggedInUser, model, subModel, instance);

export const canDeleteInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canDeleteInstance(loggedInUser, model, subModel, instance);

export const canAcceptInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canAcceptInstance(loggedInUser, model, subModel, instance);

export const canUnacceptInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canUnacceptInstance(loggedInUser, model, subModel, instance);

export const canSupersedeInstance = (model?: ModelDTO, subModel?: ExecutionSetupDTO, instance?: ScenarioDTO) =>
  instancePermissions.canUnacceptInstance(loggedInUser, model, subModel, instance);
