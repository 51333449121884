import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const CardNodeSubtitle = ({children, className}: any) => {
  return <div className={clsx('subtitle', className)}>{children}</div>;
};

export {CardNodeSubtitle};

CardNodeSubtitle.propTypes = {
  /**
   * Pass in the children that will be rendered within the CardNodeSubtitle
   */
  children: PropTypes.node,

  /**
   * Provide an optional class to be applied on the outer element
   */
  className: PropTypes.string,
};
