import {useRef} from 'react';

export const useDebounce = <T extends (...args: any) => any>(
  fn: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  const handler = useRef<number>();
  return (...args) => {
    if (handler.current) {
      clearTimeout(handler.current);
    }
    handler.current = window.setTimeout(() => fn(...args), delay);
  };
};
