import React from 'react';
import {useSharedSideBarToggle} from '../../../../../store/customSettings';
import SideBar from '../../../../layout/common/SideBar';
import {tid} from '../../../../../testUtils';
import {List} from '@material-ui/core';
import styles from '../../../model/common/ModelActionMenu/ModelActionMenu.module.scss';
import SubModelAddActionMenuButton from '../SubModelAdd/SubModelAddActionMenuButton';
import {SubModelSection} from '../../../../router/routes';
import {ExecutionSetupDTO, ModelDTO, ScenarioDTO} from 'hemwb-api';
import SubModelListActionMenuLink from './SubModelListActionMenuLink';
import SubModelEditActionMenuButton from '../SubModelEdit/SubModelEditActionMenuButton';
import SubModelDeleteActionMenuButton from '../SubModelDelete/SubModelDeleteActionMenuButton';
import ModelInputEditorActionMenuLink from '../../../modelInput/editor/ModelInputEditorActionMenuLink';
import ModelInputExportActionMenuButton from '../../../modelInput/common/ModelInputExport/ModelInputExportActionMenuButton';
import ScenarioListActionMenuLink from '../../../scenario/list/ScenarilListActionMenuLink';
import ScenarioParametersEditorActionMenuLink from '../../../scenario/editor/ScenarioParametersEditorActionMenuLink';
import ScenarioAddActionMenuButton from '../../../scenario/common/ScenarioAdd/ScenarioAddActionMenuButton';
import ScenarioExecuteActionMenuButton from '../../../scenario/common/ScenarioExecute/ScenarioExecuteActionMenuButton';
import ScenarioEditActionMenuButton from '../../../scenario/common/ScenarioEdit/ScenarioEditActionMenuButton';
import ScenarioCancelActionMenuButton from '../../../scenario/common/ScenarioCancel/ScenarioCancelActionMenuButton';
import ScenarioDeleteActionMenuButton from '../../../scenario/common/ScenarioDelete/ScenarioDeleteActionMenuButton';
import ScenarioDownloadOutputActionMenuButton from '../../../scenario/common/ScenarioDownloadOutput/ScenarioDownloadOutputActionMenuButton';
import ScenarioParametersExportActionMenuButton from '../../../scenario/common/ScenarioParametersExport/ScenarioParametersExportActionMenuButton';
import ScenarioCloneActionMenuButton from '../../../scenario/common/ScenarioClone/ScenarioCloneActionMenuButton';
import ScenarioDetailActionMenuLink from '../../../scenario/detail/ScenarioDetailActionMenuLink';
import ScenarioAcceptActionMenuButton from '../../../scenario/common/ScenarioAccept/ScenarioAcceptActionMenuButton';
import ScenarioUnacceptActionMenuButton from '../../../scenario/common/ScenarioUnaccept/ScenarioUnacceptActionMenuButton';
import {Run16, Version16} from '@carbon/icons-react';
import VisualizationCreateActionMenuLink from '../../../visualization/common/VisualizationCreateActionMenuLink/VisualizationCreateActionMenuLink';
import ScenarioSupersedeActionMenuButton from '../../../scenario/common/ScenarioSupersede/ScenarioSupersedeActionMenuButton';
import ScenarioShareActionMenuButton from '../../../scenario/common/ScenarioShare/ScenarioShareActionMenuButton';
import {isModelWeb} from '../../../../../store/model';

const sidebarWidths = {
  collapsed: '4rem',
  expanded: '22rem',
};

type SubModelActionMenuProps = {
  section: SubModelSection;
  model?: ModelDTO | null;
  subModel?: ExecutionSetupDTO;
  scenario?: ScenarioDTO | null;
  onSuccess?: () => void;
};

const SubModelActionMenu: React.FC<SubModelActionMenuProps> = ({section, onSuccess, ...props}) => {
  let {model, subModel, scenario} = props;
  model = model || undefined;
  subModel = subModel || undefined;
  scenario = scenario || undefined;
  const webModel = isModelWeb(model);

  const sharedSidebar = useSharedSideBarToggle(sidebarWidths);

  return (
    <SideBar
      position="right"
      {...sharedSidebar}
      {...tid('calibration-list-action-sidebar')}
      className={styles.modelActionMenu}>
      <List dense component="nav">
        {webModel && (
          <>
            <div className={styles.title} style={{marginTop: 0}}>
              <Run16 />
              Execution
            </div>
            <SubModelListActionMenuLink model={model} section={SubModelSection.SCENARIO} foreignContext />
            <SubModelListActionMenuLink model={model} section={SubModelSection.CALIBRATION} foreignContext />
            <SubModelListActionMenuLink model={model} section={SubModelSection.DISPLAY} foreignContext />
            <VisualizationCreateActionMenuLink model={model} />
          </>
        )}

        <div className={styles.title} style={{marginTop: 0}}>
          <Run16 />
          Sub-models
        </div>
        <SubModelListActionMenuLink model={model} section={section} />
        <SubModelAddActionMenuButton model={model} section={section} />
        <SubModelEditActionMenuButton model={model} subModel={subModel} />

        <ModelInputEditorActionMenuLink model={model} subModel={subModel} />
        <ModelInputExportActionMenuButton model={model} subModel={subModel} />

        {/*<SideBarButtonItem onClick={() => null} disabled={true} icon={<Done />} text="Accept" />*/}
        {/*<SideBarButtonItem onClick={() => null} disabled={true} icon={<Block />} text="Unaccept" />*/}

        <div style={{height: '1rem'}} />
        <SubModelDeleteActionMenuButton model={model} subModel={subModel} />

        {/*<div className={styles.title}>*/}
        {/*  <EditIcon />*/}
        {/*  Input Definition*/}
        {/*</div>*/}
        {/*/!*<ModelInputListActionMenuLink model={model} subModel={subModel} />*!/*/}
        {/*<ModelInputAddActionMenuButton model={model} subModel={subModel} />*/}
        {/*<ModelInputEditActionMenuButton model={model} subModel={subModel} input={input} />*/}

        {/*<div style={{height: '1rem'}} />*/}
        {/*<ModelInputDeleteActionMenuButton model={model} subModel={subModel} input={input} />*/}

        {section === SubModelSection.DISPLAY ? (
          <></>
        ) : (
          <>
            <div className={styles.title}>
              <Version16 />
              Instances
            </div>
            <ScenarioListActionMenuLink model={model} subModel={subModel} />
            <ScenarioAddActionMenuButton model={model} subModel={subModel} onSuccess={onSuccess} />
            <ScenarioEditActionMenuButton model={model} subModel={subModel} scenario={scenario} onSuccess={onSuccess} />
            <ScenarioParametersEditorActionMenuLink model={model} subModel={subModel} scenario={scenario} />
            <ScenarioDetailActionMenuLink model={model} scenario={scenario} />
            <ScenarioCloneActionMenuButton
              model={model}
              subModel={subModel}
              scenario={scenario}
              onSuccess={onSuccess}
            />
            <ScenarioShareActionMenuButton
              model={model}
              subModel={subModel}
              scenario={scenario}
              section={section}
              onSuccess={onSuccess}
            />
            <ScenarioParametersExportActionMenuButton model={model} subModel={subModel} scenario={scenario} />
            <ScenarioExecuteActionMenuButton model={model} subModel={subModel} scenario={scenario} />
            <ScenarioCancelActionMenuButton model={model} subModel={subModel} scenario={scenario} />
            <ScenarioDownloadOutputActionMenuButton model={model} subModel={subModel} scenario={scenario} />
            {section === SubModelSection.CALIBRATION && (
              <>
                <ScenarioAcceptActionMenuButton
                  model={model}
                  subModel={subModel}
                  scenario={scenario}
                  onSuccess={onSuccess}
                />
                <ScenarioUnacceptActionMenuButton
                  model={model}
                  subModel={subModel}
                  scenario={scenario}
                  onSuccess={onSuccess}
                />
                <ScenarioSupersedeActionMenuButton
                  model={model}
                  subModel={subModel}
                  scenario={scenario}
                  onSuccess={onSuccess}
                />
              </>
            )}
            <div style={{height: '1rem'}} />
            <ScenarioDeleteActionMenuButton
              model={model}
              subModel={subModel}
              scenario={scenario}
              onSuccess={onSuccess}
            />
          </>
        )}
      </List>
    </SideBar>
  );
};

export default SubModelActionMenu;
