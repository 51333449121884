import {AuthActions} from './types';
import {
  buildAsyncActionsCreator,
  createAsyncActionExecutor,
  isAuthErrorResponse,
  setLogoutFunction,
} from '../asyncUtils';
import {requestCurrentUser, requestHEMWBAccess, requestLogin, requestLogout, requestRegister} from './api';
import {messageAdd, messageNetworkError, MessageTypes} from '../message';

const ACCESS_DENIED_PAGE_URL = '/errors#/error/access-denied';

export const authGetCurrentUserActionCreator = buildAsyncActionsCreator(AuthActions.AUTH_GET_CURRENT_USER);
export const loadCurrentUser = createAsyncActionExecutor(authGetCurrentUserActionCreator, requestCurrentUser, {
  handleAuthErrorEnabled: false,
  showError: false,
  failureFunction: (dispatch, error) => {
    dispatch(authGetCurrentUserActionCreator.failure(error));
    if (error.status === 403) {
      window.location.assign(ACCESS_DENIED_PAGE_URL);
    } else if (!isAuthErrorResponse(error)) {
      dispatch(messageNetworkError(error));
    }
  },
});

export const authLoginActionCreator = buildAsyncActionsCreator(AuthActions.AUTH_LOGIN);
export const authLogin = createAsyncActionExecutor(authLoginActionCreator, requestLogin, {
  handleAuthErrorEnabled: false,
  showError: false,
  failureFunction: (dispatch, error) => {
    if (error.status === 401) {
      dispatch(messageAdd('You have entered wrong username or password', MessageTypes.ERROR));
    } else if (error.status === 403) {
      window.location.assign(ACCESS_DENIED_PAGE_URL);
      return;
    }
    return dispatch(authLoginActionCreator.failure(error));
  },
});

export const authRegisterActionCreator = buildAsyncActionsCreator(AuthActions.AUTH_REGISTRATION);
export const authRegister = createAsyncActionExecutor(authRegisterActionCreator, requestRegister, {
  handleAuthErrorEnabled: false,
  showError: false,
  successFunction: (dispatch) => {
    dispatch(messageAdd('User Registered Successfully', MessageTypes.SUCCESS));
  },
  failureFunction: (dispatch, error, _response) => {
    if (error.status === 409) {
      dispatch(messageNetworkError(error));
    }
    if (error.status === 400) {
      dispatch(messageNetworkError(error));
    } else if (error.status === 403) {
      window.location.assign(ACCESS_DENIED_PAGE_URL);
      return;
    }
    return dispatch(authLoginActionCreator.failure(error));
  },
});

export const authLogoutActionCreator = buildAsyncActionsCreator(AuthActions.AUTH_LOGOUT);
export const authLogout = createAsyncActionExecutor(authLogoutActionCreator, requestLogout, {
  handleAuthErrorEnabled: false,
  showError: false,
  failureFunction: (dispatch) => {
    // logout even in case of failure
    dispatch(authLogoutActionCreator.success(undefined, [false]));
  },
});

const authRequestAccessActionsCreator = buildAsyncActionsCreator(AuthActions.AUTH_REQUEST_ACCESS);
export const authRequestAccess = createAsyncActionExecutor(authRequestAccessActionsCreator, requestHEMWBAccess);

export const authSetLogout = () => ({type: AuthActions.AUTH_SET_LOGOUT});

/**
 * This is to remove circular dependency between asyncUtils and auth/actions
 * Async api actions trigger logout in case of authorization errors and need to dispatch logout action, which is again async
 */
setLogoutFunction(authLogout);
