import React, {useMemo} from 'react';
import {DiffFile, DiffHunk} from './types';
import {Diff, Hunk} from 'react-diff-view';
import tokenize from './tokenize';

const EMPTY_HUNKS: DiffHunk[] = [];

type FileDiffProps = DiffFile & {
  viewType: 'unified' | 'split';
};

const renderToken = (token: any, defaultRender: any, i: string) => {
  if (token.type === 'space') {
    return (
      <span key={i} className="space">
        {token.children && token.children.map((token: any, i: string) => renderToken(token, defaultRender, i))}
      </span>
    );
  } else {
    return defaultRender(token, i);
  }
};

const FileDiff: React.FC<FileDiffProps> = ({viewType = 'split', type, hunks, newPath}) => {
  const tokens = useMemo(() => tokenize(hunks), [hunks]);

  return (
    <div>
      <h4>{newPath}</h4>
      <Diff viewType={viewType} diffType={type} hunks={hunks || EMPTY_HUNKS} tokens={tokens} renderToken={renderToken}>
        {(hunks: DiffHunk[]) => hunks.map((hunk) => <Hunk key={hunk.content} hunk={hunk} />)}
      </Diff>
    </div>
  );
};

export default FileDiff;
