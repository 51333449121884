/* eslint-disable @typescript-eslint/camelcase */
import {ModelLinksAction, ModelLinksActions} from './types';
import {reduceAsyncActionReduced} from '../asyncUtils';
import {ModelMetadataAction} from '../modelMetadata/types';
import {AsyncActionStateReduced} from '../asyncUtilsTypes';
import {ModelAction, ModelActions} from '../model/types';
import {ModelFileAction} from '../modelFile/types';

type FetchedHierarchy = AsyncActionStateReduced<[string]>;

export const modelLinksInitialState = {
  hierarchy: {} as {[modelUuid: string]: FetchedHierarchy},
};

export type ModelLinksState = typeof modelLinksInitialState;

export const modelLinksReducer = (
  state = modelLinksInitialState,
  action: ModelLinksAction | ModelMetadataAction | ModelAction | ModelFileAction,
): ModelLinksState => {
  switch (action.type) {
    case ModelLinksActions.FETCH_HIERARCHY: {
      const [modelUuid] = action.params;
      return {
        ...state,
        hierarchy: {
          ...state.hierarchy,
          [modelUuid]: reduceAsyncActionReduced(action),
        },
      };
    }

    case ModelActions.UPDATE: {
      return state;
    }

    // case ModelActions.PUBLISH:
    // case ModelActions.RETIRE:
    //   if (action.phase === AsyncActionPhase.SUCCESS) {
    //     return {
    //       ...state,
    //       rootsByMetadata: modelLinksInitialState.rootsByMetadata,
    //     };
    //   }
    //   return state;

    // case ModelLinksActions.SET_PARENT: {
    //   //action.params[2] is a flag for remaining this state for cases when metadata update is followed and will result double clear/requests
    //   if (action.phase === AsyncActionPhase.SUCCESS && !action.params[2]) {
    //     return {
    //       ...state,
    //       hierarchy: modelLinksInitialState.hierarchy,
    //       roots: modelLinksInitialState.roots,
    //       rootsByMetadata: modelLinksInitialState.rootsByMetadata,
    //     };
    //   }
    //   return state;
    // }

    default:
      return state;
  }
};
