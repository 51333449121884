import {configuration} from '../getBackendConfiguration';
// @ts-ignore
import {UserNotificationsApi} from 'hemwb-api/dist/apis/UserNotificationsApi';

const userNotificationsApi = new UserNotificationsApi(configuration);

export const requestLatestNotifications = (limit?: number) =>
  userNotificationsApi.getLatestNotifications(limit !== undefined ? {limit} : {});

export const deleteNotifications = (notificationIds: number[]) =>
  userNotificationsApi.clearNotifications({requestBody: notificationIds});
