import {ModelInputDetailDTO, ModelInputType} from 'hemwb-api';
import {scenarioFormGenerator} from '../scenarioFormGenerator';
import {formatInput} from '../formatInput';
import {associateFormValuesWithName} from '../utils';
import {getScenarioDefaultParameters} from '../getScenarioDefaultParameters';

export const stringifyScenarioParameters = (input: ModelInputDetailDTO, parameters?: object): string => {
  if (!parameters) {
    return JSON.stringify(getScenarioDefaultParameters(input));
  }

  if (input.type === ModelInputType.JSONTEMPLATE) {
    const definitions = JSON.parse(input.value)?.definitions || [];
    const form = scenarioFormGenerator(definitions, parameters, false);
    return JSON.stringify(formatInput(definitions, associateFormValuesWithName(definitions, form.value.parameters)));
  }

  return JSON.stringify(parameters || {});
};
