import React from 'react';
import {ModelDTO} from 'hemwb-api';
import {useDispatch, useSelector} from 'react-redux';
import {ModelFlatNode, useModelFlatHierarchy} from '../../../../../store/modelLinks';
import {
  versionControlDiffSelectionSelector,
  versionControlToggleDiffSelection,
} from '../../../../../store/versionControl';
import {Tree, TreeNode} from 'react-organizational-chart';
import SuspenseNull from '../../../../core/Suspense/SuspenseNull';
import styles from './ModelVersionControl.module.scss';
import {isModelDTO} from '../../../../../store/model';
import {ModelCardDynamicView} from '../ModelCard/ModelCardDynamicView';

type ModelVersionControlLineageDynamicViewProps = {
  model: ModelDTO;
  toggleVersions: () => void;
};

const primarySelectionStyle = {
  border: '2px solid #0062ff',
  // boxShadow: '0 4px 20px 0 #0062ff80',
};
const secondarySelectionStyle = {
  border: '2px solid #366fff',
  // boxShadow: '0 4px 20px 0 #366fff80',
};
const noSelectionStyle = {};

const ModelVersionControlLineageDynamicView: React.FC<ModelVersionControlLineageDynamicViewProps> = ({
  model,
  toggleVersions,
}) => {
  const dispatch = useDispatch();
  const flatHierarchy = useModelFlatHierarchy(model.uuid);
  const versionDiffSelection = useSelector(versionControlDiffSelectionSelector);

  const renderStyle = (node: ModelFlatNode) => {
    return node.model.uuid === versionDiffSelection[1] ? secondarySelectionStyle : noSelectionStyle;
  };
  const renderChild = (node: ModelFlatNode) => {
    if (!isModelDTO(node.model)) {
      return null;
    }

    return (
      <TreeNode
        key={node.model.uuid}
        label={
          <ModelCardDynamicView
            style={node.model.uuid === versionDiffSelection[0] ? primarySelectionStyle : renderStyle(node)}
            model={node.model}
            toggleVersioning={toggleVersions}
            onClick={() => dispatch(versionControlToggleDiffSelection(node.model.uuid))}
            expanded={node.model.uuid === model.uuid}
          />
        }>
        {flatHierarchy!.filter((n) => n.parentId === node.model.uuid)?.map(renderChild)}
      </TreeNode>
    );
  };

  const flatHierarchySecondaryStyle = () => {
    if (flatHierarchy)
      return flatHierarchy[0].model.uuid === versionDiffSelection[1] ? secondarySelectionStyle : noSelectionStyle;
  };

  return (
    <SuspenseNull>
      {flatHierarchy && flatHierarchy[0] && (
        <>
          <div className={styles.container}>
            <Tree
              lineWidth="3px"
              lineBorderRadius="10px"
              lineColor="#0062ff"
              label={
                <ModelCardDynamicView
                  style={
                    flatHierarchy[0].model.uuid === versionDiffSelection[0]
                      ? primarySelectionStyle
                      : flatHierarchySecondaryStyle()
                  }
                  model={flatHierarchy[0].model}
                  toggleVersioning={toggleVersions}
                  onClick={() =>
                    isModelDTO(flatHierarchy[0].model)
                      ? dispatch(versionControlToggleDiffSelection(flatHierarchy[0].model.uuid))
                      : null
                  }
                  expanded={flatHierarchy[0].model.uuid === model.uuid}
                />
              }>
              {flatHierarchy.filter((n) => n.parentId === flatHierarchy[0].model.uuid).map(renderChild)}
            </Tree>
          </div>
        </>
      )}
    </SuspenseNull>
  );
};

export default ModelVersionControlLineageDynamicView;
