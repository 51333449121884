import {AbstractControl, ValidationErrors} from 'react-reactive-form';

export const validateOption = (value: any, options: any[]): ValidationErrors | null => {
  if (value) {
    if (Array.isArray(value)) {
      if (value.some((v) => !options.includes(v))) {
        return {invalidOption: true};
      }
    } else if (!options.includes(value)) {
      return {invalidOption: true};
    }
  }

  return null;
};

export const optionValidator = (options: any[]) => (control: AbstractControl) => validateOption(control.value, options);

export const validateInteger = ({value}: AbstractControl) =>
  value && !Number.isInteger(Number(value)) ? {integer: true} : null;

const numberPattern = /^-?\d*(\.\d*)?$/;
export const validateNumber = ({value}: AbstractControl) =>
  value && !numberPattern.test(value || '') ? {number: true} : null;

export const validateHigherThan = (comparedTo: string | AbstractControl, allowEqualValue = false) => (
  control: AbstractControl,
) => {
  let comparedControl: AbstractControl;
  if (typeof comparedTo === 'string') {
    if (!control.parent) {
      return null;
    }
    comparedControl = control.parent.get(comparedTo);
  } else {
    comparedControl = comparedTo;
  }

  if (control.value === null || control.value === '' || comparedControl.value === '') {
    return null;
  }

  const comparedVal =
    comparedControl.value instanceof Date ? comparedControl.value.getTime() : Number(comparedControl.value);
  const currentVal = control.value instanceof Date ? control.value.getTime() : Number(control.value);

  if (
    Number.isNaN(currentVal) ||
    Number.isNaN(comparedVal) ||
    currentVal > comparedVal ||
    (allowEqualValue && currentVal === comparedVal)
  ) {
    return null;
  }

  const returnError = allowEqualValue ? 'minOrEqual' : 'min';

  return {
    [returnError]: {
      [returnError]: comparedControl.value,
    },
  };
};

export const validateLowerThan = (comparedTo: string | AbstractControl, allowEqualValue = false) => (
  control: AbstractControl,
) => {
  let comparedControl: AbstractControl;
  if (typeof comparedTo === 'string') {
    if (!control.parent) {
      return null;
    }
    comparedControl = control.parent.get(comparedTo);
  } else {
    comparedControl = comparedTo;
  }

  if (control.value === null || control.value === '' || comparedControl.value === '') {
    return null;
  }

  const comparedVal =
    comparedControl.value instanceof Date ? comparedControl.value.getTime() : Number(comparedControl.value);
  const currentVal = control.value instanceof Date ? control.value.getTime() : Number(control.value);

  if (
    Number.isNaN(currentVal) ||
    Number.isNaN(comparedVal) ||
    currentVal < comparedVal ||
    (allowEqualValue && currentVal === comparedVal)
  ) {
    return null;
  }

  const returnError = allowEqualValue ? 'maxOrEqual' : 'max';

  return {
    [returnError]: {
      [returnError]: comparedControl.value,
    },
  };
};
