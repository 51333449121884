import React, {useCallback, useState, useEffect} from 'react';
import {UserEnrollForm} from './UserEnrollForm';
import {useForm} from '../../../../core/Form';
import {FormControl, FormGroup, Validators} from 'react-reactive-form';
import {AddRolesForm} from './AddRolesForm';
import {ModelDTO} from 'hemwb-api';
import Header from '../spaceHeader/Header';
import DefaultPage from '../../../../layout/common/DefaultPage';
import {ExternalUserWithModel} from './types';

const ExternalUserEnroll: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedModels, setSelectedModels] = useState<ExternalUserWithModel[]>([]);
  const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
  const selectedFirstName = queryParams.get('firstName');
  const selectedLastName = queryParams.get('lastName');
  const selectedEmail = queryParams.get('email');
  const form = useForm(
    new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern(/^(?!.*@(merck|msd)\.com$).*$/),
      ]),
      models: new FormControl([], [Validators.required]),
    }),
  );

  useEffect(() => {
    form.controls.firstName.setValue(selectedFirstName);
    form.controls.lastName.setValue(selectedLastName);
    form.controls.email.setValue(selectedEmail);
  }, [selectedFirstName, selectedLastName, selectedEmail, form]);

  const handleDeleteRow = useCallback(
    (model: ExternalUserWithModel) => {
      const existingModel = selectedModels;
      existingModel.splice(existingModel.indexOf(model), 1);
      setSelectedModels([...existingModel]);
    },
    [selectedModels],
  );

  const handleSubmit = useCallback(() => {
    if (form.valid) {
      const modelList = form.value.models;
      const items: ExternalUserWithModel[] = [];
      modelList.map((v: ModelDTO) => items.push({name: v.name, uuid: v.uuid, role: '', platform: v.metadata.platform}));
      setSelectedModels(items);
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, form]);

  const handleCancel = useCallback(() => {
    setActiveStep(0);
  }, []);

  return (
    <DefaultPage>
      <Header />
      {activeStep === 0 && <UserEnrollForm form={form} onSubmit={handleSubmit} />}
      {activeStep === 1 && (
        <>
          <AddRolesForm
            handleCancel={handleCancel}
            values={form.value}
            selectedModels={selectedModels}
            handleDeleteRow={handleDeleteRow}
          />
        </>
      )}
    </DefaultPage>
  );
};

export default ExternalUserEnroll;
