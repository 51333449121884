import {useCallback} from 'react';
import {downloadBlob, sanitizeFilename} from '../../../../../utils/download';
import {SubModelActionProps} from '../../../actionMenuTypes';
import {trackSubModelInputExported} from '../../../../../tracking/tracking';
import {useModelInputsLoaderWithParams} from '../../../../../store/modelInputs';

const useModelInputExport = ({model, subModel}: Partial<SubModelActionProps>) => {
  const loadInputList = useModelInputsLoaderWithParams();

  return useCallback(async () => {
    if (model && subModel) {
      const inputList = (await loadInputList([subModel.id])) as any;
      if (inputList && inputList.list[0]) {
        const input = inputList.list[0];

        const fileName = `${sanitizeFilename(
          [subModel?.type, subModel?.name, input.name, 'Input Definition'].filter(Boolean).join('_'),
        )}.json`;

        const parsed = input.value
          ? JSON.parse(
              input.value
                .replaceAll('expertiseLevel', 'expertise')
                .replaceAll('250 - Basic', '250')
                .replaceAll('500 - Intermediate', '500')
                .replaceAll('750 - Advanced', '750')
                .replaceAll('1000 - Expert', '1000'),
            )
          : {};
        const blob = new Blob([JSON.stringify(parsed.definitions || [], null, 4)], {type: 'application/json'});
        trackSubModelInputExported(model, subModel);
        downloadBlob(blob, fileName);
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, subModel]);
};

export default useModelInputExport;
