import React, {useEffect, useState} from 'react';
import {ModelDTO} from 'hemwb-api';
import {Button, CircularProgress, Tab, Tabs} from '@material-ui/core';
import FileTree from '../../../../core/FileTree/FileTree';
import {useModelFileTreeLoader} from '../../../../../store/modelFile';
import styles from './ModelOverview.module.scss';
import {trackModelViewed} from '../../../../../tracking/tracking';
import ModelTextOverview from '../ModelTextOverview/ModelTextOverview';
import {Link} from 'react-router-dom';
import {getUrlRoute, Routes} from '../../../../router/routes';
import {usePrefillModelListGridStateWithModel} from '../../../../../store/grid';
import {Maximize16, Minimize16, PageFirst16} from '@carbon/icons-react';
import clsx from 'clsx';
import ModelTextOverviewCondensed from '../ModelTextOverview/ModelTextOverviewCondensed';
import ModelOverviewRequestAccess from './ModelOverviewRequestAccess';
import ModelVersionControl from '../ModelVersionControl/ModelVersionControl';
import {useDispatch, useSelector} from 'react-redux';
import {VersionControlPage, versionControlPage, versionControlPageSelector} from '../../../../../store/versionControl';
import {useModelDetail, isModelExternal, isModelWeb} from '../../../../../store/model';
import {tid} from '../../../../../testUtils';
import {canViewModelFolders} from '../../../../../permissions/usePermissions';
import ModelDownloadActionMenuButton from '../ModelDownload/ModelDownloadActionMenuButton';

export type ModelOverviewProps = {
  model: ModelDTO;
  expanded: boolean;
  selectedTab?: string;
};

const ModelOverview: React.FC<ModelOverviewProps> = ({model, expanded, selectedTab}) => {
  const dispatch = useDispatch();
  const modelDetail = useModelDetail(model) || undefined;

  useEffect(() => {
    trackModelViewed(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.uuid]);

  const [tab, setTab] = useState('text');
  const externalModel = isModelExternal(model);
  const webModel = isModelWeb(model);
  const versionControlCurrentPage = useSelector(versionControlPageSelector);

  useEffect(() => {
    if (selectedTab) {
      setTab(selectedTab);
    }
    if (versionControlCurrentPage !== VersionControlPage.LINEAGE) {
      setTab('version');
    }
  }, [versionControlCurrentPage, selectedTab]);

  const {data: files, load: loadFiles} = useModelFileTreeLoader(model.uuid, ['Parameter'], undefined);
  useEffect(() => {
    if (tab === 'folder') {
      loadFiles();
    }
  }, [tab, loadFiles]);

  const prefillModelGridState = usePrefillModelListGridStateWithModel();

  return (
    <div style={expanded ? {paddingLeft: '6vw', minHeight: '100%'} : {}} className="greyTheme">
      <ModelOverviewRequestAccess model={model} />

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ModelDownloadActionMenuButton model={modelDetail} fromSideBar={false} />
      </div>

      <div className={styles.navBar} {...tid('model-overview-nav')}>
        <Tabs
          value={tab}
          onChange={(event: React.ChangeEvent<{}>, newTab: string) => setTab(newTab)}
          indicatorColor="primary"
          textColor="inherit"
          className="tabs-secondary">
          <Tab value="text" label="Text View" />
          <Tab value="folder" label="Folder View" disabled={!canViewModelFolders(model)} />
          <Tab value="version" label="Version Control" />
        </Tabs>

        <div className={styles.expandCollapseButton}>
          {tab === 'version' && versionControlCurrentPage !== VersionControlPage.LINEAGE && (
            <Button
              variant="contained"
              onClick={() => dispatch(versionControlPage(VersionControlPage.LINEAGE))}
              color="default"
              size="large"
              className={clsx('whiteButton noBoxShadow')}
              startIcon={<PageFirst16 />}>
              Back to node view
            </Button>
          )}
          &nbsp;
          <Button
            variant="contained"
            component={Link}
            to={expanded ? Routes.MODEL_LIST : getUrlRoute(Routes.MODEL_DETAIL, {uuid: model.uuid})}
            onClick={() => !expanded || prefillModelGridState(model)}
            color="default"
            size="large"
            className={clsx('whiteButton noBoxShadow')}
            startIcon={expanded ? <Minimize16 /> : <Maximize16 />}>
            {expanded ? 'Minimize' : 'Maximize'}
          </Button>
        </div>
      </div>

      <div style={{minHeight: '55rem', paddingTop: '1rem'}} {...tid('model-overview-content')}>
        {tab === 'text' &&
          (expanded ? (
            <ModelTextOverview model={modelDetail || model} />
          ) : (
            <ModelTextOverviewCondensed model={modelDetail || model} />
          ))}
        {tab === 'folder' && (
          <div>
            {files ? (
              <>
                <FileTree files={files} externalModel={externalModel} webModel={webModel} />
                {model.metadata['model_development_plan'] && (
                  <>
                    <strong>MDP: </strong>
                    <a href={model.metadata['model_development_plan']} target="_blank" rel="noopener noreferrer">
                      {model.metadata['model_development_plan']}
                    </a>
                  </>
                )}
              </>
            ) : (
              <CircularProgress />
            )}
          </div>
        )}
        {tab === 'version' && <ModelVersionControl model={model} />}
      </div>
    </div>
  );
};

export default ModelOverview;
